<template lang="pug">
Page

  template(slot="header-right-dropdown")
    CustomDatepicker#ScheduledjobDatepicker(
      v-model="dates",
      :range="true"
      :disabled="isFetchingList"
    )
  template(slot="header-right-content")
    Button(
    size="medium"
    primary
    id="btn-schedule-new"
    type="button",
    iconName="icon-global-add",
    iconClass="icon-left icon-global-add",
    v-if="!isEmptyList"
    @click="addScheduledJob")
      span {{ $t('ScheduledJob.emptyStateNewButton') }}
  template(slot="toolbar-left")
    storeFilterComponent.store-filter(@submitFilter="getListWithStore") 
    CustomCheckbox.checkbox(
      id="checkbox-schedule-show-passive"
      :label="$t('ScheduledJob.Filter_Show_Passives')"
      :checked="showPassives",
      @change="onChangeShowPassives"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingList")
    #table(v-if="!isFetchingList && !isEmptyList")
      NestedTable(
        :fields="headerFields",
        :statusTooltipText="$t('ScheduledJob.StatusTooltipText')"
        :detailFields="detailFields",
        @showDetailAction="showDetailAction",
        :isDetailLoading="isDetailLoading",
        :actions="['inspect']",
        :readOnlyForDetail="true"
        :readOnly="true"
        @inspectHeaderAction="onInspect"
        :detailActions="['']"
        :data="data"
        :usePortalFooter="true"
        :dontUseScroll="true"
        :page="Page"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize")
      portal(to="isActive")
        .switch-button(slot-scope="header" v-if="header.row.canBeActivated")
          SwitchButton(
            :active="header.row.isActive"
            :id="`btn-schedule-job-switch-is-active-${header.rowIndex}`"
            @change="onChangeSwitchButton(header.row)")

      portal(to="situation")
        div.popup-container(slot-scope="detail")
          Button.situation-button(
            primary,
            size="small",
            iconName="icon-btn-success",
            @click="showDismissPopup(detail.row)"
            :disabled="isDetailLoading"
          )
            span {{$t('ScheduledJob.Detail_Col_Button_Complete')}}

          form.Schedule-center-MiniPopup(
            v-if="detail.row.showPopup"
            :data-vv-scope="detail.row.id"
            :id="`form-schedule-dismiss-reason-${detail.rowIndex}`"
            @submit.prevent ="dismissAllDetail(detail.row, isAll = false)"
          )
            label.Schedule-center-MiniPopup-label.required {{ $t('ScheduledJob.popupAdd_FormField_Dissmis_Reason_Label') }}
            input.txt.Schedule-center-MiniPopup-input(
              name="reason"
              v-model.trim="detail.row.reason"
              v-validate="'required|max:512'"
              autocomplete="off"
              :class="{ 'is-danger': veeErrors.has(`${detail.row.id}.reason`) }"
            )
            Button.m-top-10(
              primary
              size="medium"
              :form="`form-schedule-dismiss-reason-${detail.rowIndex}`"
              type="submit"
              :disabled="!detail.row.reason || isDismissAllDetailLoading"
              :isLoading="isDismissAllDetailLoading"
            )
              span {{$t('ScheduledJob.Detail_Col_Button_Agree')}}

    portal(to="footerPortal")
      div.popup-container(slot-scope="rowData" v-if="rowData.detailList.length > 0")
        Button(
          primary,
          size="medium",
          iconName="icon-btn-success",
          @click="showDismissPopup(rowData)"
          :disabled="isDetailLoading"
        )
          span {{$t('ScheduledJob.Detail_Col_Button_Complete_All')}}

        form.Schedule-right-MiniPopup(
          v-if="rowData.showPopup"
          :data-vv-scope="rowData.id"
          id="form-schedule-dismiss-reason-all"
          @submit.prevent ="dismissAllDetail(rowData)"
        )
          label.Schedule-right-MiniPopup-label.required {{ $t('ScheduledJob.popupAdd_FormField_Dissmis_Reason_Label') }}
          input.txt.Schedule-right-MiniPopup-input(
            name="reason"
            v-model.trim="rowData.reason"
            v-validate="'required|max:512'"
            autocomplete="off"
            :class="{ 'is-danger': veeErrors.has(`${rowData.id}.reason`) }"
          )
          Button.m-top-10(
            primary
            size="medium"
            form="form-schedule-dismiss-reason-all"
            type="submit"
            :disabled="!rowData.reason"
          )
            span {{$t('ScheduledJob.Detail_Col_Button_Agree_All')}}

    EmptyState(
      :title="$t('ScheduledJob.emptyStateTitle')",
      :description="$t('ScheduledJob.emptyStateDescription')",
      :buttonText="$t('ScheduledJob.emptyStateNewButton')",
      v-if="isEmptyList && !isFetchingList"
      @emptyAddButton="addScheduledJob")

    router-view(@getList="getList")

</template>

<script>
import { getHeaderFields, getDetailFields } from '@/view/pages/ScheduledJob/fields.js'
import {mapActions, mapGetters} from 'vuex'
import SwitchButton from '@/view/global/switch-button'
import moment from 'moment'
import ModelScheduleDetail from '@/view/pages/ScheduledJob/model/ModelScheduleDetail'
import ModelScheduleHeader from '@/view/pages/ScheduledJob/model/ModelScheduleHeader'
import storeFilterComponent from '@/view/global/business-filters/store.vue'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  components: {
    SwitchButton,
    storeFilterComponent
  },

  watch: {
    dates () {
      this.getList()
    }
  },

  data () {
    return {
      headerFields: getHeaderFields(this.$t),
      detailFields: getDetailFields(this.$t),
      data: [],
      dates: [ moment().startOf('month').hour(0).minute(0)._d, moment().hour(23).minute(59)._d ],
      showPassives: false,
      storeFilter: []
    }
  },

  beforeMount () {
    this.getList()
  },

  computed: {
    ...mapGetters('ScheduleJob', ['Page']),
    isFetchingList () {
      return this.$wait.is('getAllSchedule')
    },

    isEmptyList () {
      return this.data.length === 0
    },

    isDetailLoading () {
      return this.$wait.is('getScheduleDetail')
    },

    isDismissAllDetailLoading () {
      return this.$wait.is(['dismissAllDetail'])
    }
  },

  methods: {
    ...mapActions('ScheduleJob', [
      'getAllSchedule',
      'getScheduleDetail',
      'getSchedule',
      'activationSchedule',
      'dismissError'
    ]),

    getListWithStore: vueWaitLoader(async function ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.storeFilter = list
        await this.getList()
      }
    }, 'getListWithStore'),

    onInspect (item) {
      this.$router.push({
        name: 'ScheduledJobDetail',
        params: {
          id: item.id
        }
      })
    },

    onChangeShowPassives (isChecked) {
      this.showPassives = isChecked
      this.getList()
    },

    changeSwitchButtonValue (schedule) {
      schedule.isActive = !schedule.isActive
    },

    changeActivation (schedule) {
      this.changeSwitchButtonValue(schedule)
      this.activationSchedule({Id: schedule.id, isActive: schedule.isActive})
        .then(res => {
          if (res) {
            this.getAndModelSingleSchedule(schedule)
          }
        })
    },

    async getAndModelSingleSchedule (schedule) {
      await this.getSchedule({Id: schedule.id})
        .then(res => {
          const newSchedule = new ModelScheduleHeader(res.data.schedule, this.$t)
          // dont change schedule reference
          schedule = Object.assign(schedule, newSchedule)
        })
    },

    changePage (pageNumber) {
      this.getList(pageNumber)
    },

    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    async getList (pageNumber = 1, pageSize = this.Page.size) {
      this.data = []
      const params = {
        beginDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        isActive: !this.showPassives,
        pageSize,
        pageNumber,
        storeIds: this.storeFilter.map(store => store.id)
      }
      await this.getAllSchedule(params)
        .then(res => {
          this.data = res.data.scheduleList.map(sc => new ModelScheduleHeader(sc, this.$t))
        })
    },

    async getDetail (item) {
      await this.getScheduleDetail({ScheduleId: item.id, Status: 3})
        .then(res => {
          item.detailList = res.data.historyList.map(detail => new ModelScheduleDetail(detail, this.$t))
        })
    },

    addScheduledJob () {
      this.$router.push({
        name: 'newScheduledJob'
      })
    },
    onChangeSwitchButton (schedule) {
      this.changeActivation(schedule)
    },

    closeAllOtherHeadersAndOpenOne (id) {
      this.data.map(header => {
        if (header.id === id) header.showDetail = true
        else {
          header.showDetail = false
          header.detailList = []
        }
      })
    },

    showDetailAction (item) {
      if (!item.showDetail) {
        this.getDetail(item)
        this.closeAllOtherHeadersAndOpenOne(item.id)
      } else {
        item.detailList = []
        item.showDetail = false
      }
    },

    dismissAllDetail: vueWaitLoader(async function (schedule, isAll = true) {
      await this.$validator.validateAll(schedule.id)
        .then(async result => {
          if (!result) return
          await this.dismissErrorWithParams(schedule, isAll)
        })
    }, 'dismissAllDetail'),

    closeAllPopups (item) {
      this.data.map(header => {
        if (item.id !== header.id) header.showPopup = false
        header.detailList.map(detail => {
          if (item.id !== detail.id) detail.showPopup = false
        })
      })
    },

    findHeader (detail) {
      const result = this.data.find(header => header.detailList.find(item => detail.id === item.id))
      return result
    },

    closeDismissPopup (item) {
      item.showPopup = false
    },

    showDismissPopup (item) {
      item.showPopup = !item.showPopup
      this.closeAllPopups(item)
    },

    async dismissErrorWithParams (row, isAll = true) {
      let header

      const params = {
        idList: [row.id],
        dismissReason: row.reason
      }
      if (isAll) {
        header = row
        params.idList = row.detailList.map(detail => detail.id)
      } else {
        header = this.findHeader(row)
      }

      await this.dismissError(params)
        .then(async res => {
          if (!res) return
          isAll || (!isAll && header.detailList.length === 1)
            ? row = await this.getAndModelSingleSchedule(header)
            : await this.getDetail(header)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";
  .Schedule-center-MiniPopup {
    @include MiniPopupMixin(
      $width: 400px,
      $margin: 30px 0 0 0,
      $triangle-direction: 'center'
    );

  }
  .Schedule-right-MiniPopup {
    @include MiniPopupMixin(
      $width: 400px,
      $margin: 30px 0 0 0,
      $triangle-direction: 'right'
    );

  }

  .Schedule-center-MiniPopup,
  .Schedule-right-MiniPopup {

    &-input {
      width: 100%;
      margin-top: 10px;
    }

    &-label {
      max-width: 100%;
      margin-left: 10px;
      color: $color-light;
      font-size: $font-size-normal;
    }
  }
.situation-button {
  width: max-content;
}
.popup-container {
  position: relative;
  width: max-content;
}

.toolbar {
  display: flex;
  margin-bottom: 0px;
  .left {
    display: flex;
  }
}
</style>
