<template lang="pug">
Page
  template(slot="header-left-search")
    Search.search-box(
      componentName="Stock"
      :isSuggestionLoading="isLoadingSearch"
      :customSuggestionPlaceHolder="$t('Stocks.search_Placeholder')"
      @onChangeSearchValue="onChangeSearch"
      @clearSearch="clearSearch"
    )

  template(slot="header-right-content")

    Button(
      primary
      size="medium"
      id="btn-stock-new"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      v-show="!isEmpty"
      @click="itemNew"
    )
      span {{ $t('Stocks.addStockButton') }}

  // PAGE CONTENT /////////////////////////////

  template(slot="overgroups-left")
    ToggleOverGroups.toggle-over-groups(
        :list="typeList"
        :selectedItem="selectedStockType"
        @delegateChangeSelectedItem="changedStockType"
        size="small")

  template(slot="toolbar-right")
    Button.clear-filter-button.border-gray-1.p-0.width-auto.m-right-10(
      @click='clearFilterGlobal')
        Icon.icon-clear-filter(name="icon-clear-filter")

    Button.generate-barcode(
      size="small"
      secondary
      v-click-outside="closeBarcodeOperationsPopup"
      id="change-multiple-barcode-generation-button",
      :disabled="!selectedList.length"
      @click="toggleBarcodeOperations"
    )
      span {{ $t('Stocks.barcodeOperations') }}

      .barcode-selection-popup(:class="showBarcodeOperationsPopup ? 'show-barcode-operation-popup' : null")
        h2.barcode-selection-popup-header {{ $t('Stocks.multipleBarcode') }}
        .barcode-selection-popup-contents.m-top-10

          CustomCheckbox.change-price-type.m-top-5(
            id="stocks-weighted-barcode-generation"
            :label="$t('Stocks.weightedBarcode')"
            :checked="isWeightedBarcode"
            @change="changeBarcodeType")
              
          showValidateError(
            v-show="isWeightedBarcode"
            :errorName="$t('Stocks.warning_weightedBarcode')"
          )

          Button.m-top-15(
            primary 
            size="small"
            id="change-price-type-activation-status",
            :disabled="!selectedList.length"
            @click="generateBarcode"
          )
            span {{ $t('Stocks.toolbar_SubmitBarcode_ButtonText') }}

  template(slot="content-center")
    EmptyState(
      v-if="isEmpty && !isLoading"
      :title="$t('Stocks.emptyState_Title')"
      :description="$t('Stocks.emptyState_Description')"
      :buttonText="$t('Stocks.emptyState_Button')"
      @emptyAddButton="itemNew"
    )

    div.m-top-20
      TableLoading(v-if="isLoading")
      TableView(
      v-if="!isEmpty && !isLoading"
      uniqueIdentifier="id"
      :fields="fields"
      :data="StockItems"
      :componentName="'StockTable'"
      :page="Page"
      :pointer="true"
      :tableName="$t('Stocks.tableName')"
      :actions="['edit', 'remove']"
      :selectedRows="selectedList"
      :selectable="'checkbox'"
      :selectableAll="'checkbox'"
      @delegateOnItemEdit="itemEdit"
      @delegateOnItemDelete="itemDelete"
      @delegateOnItemShow="itemShow"
      @delegateOnChangePage="changePage"
      @delegateOnItemSelect="onSelect"
      @showAllSelectedItems="selectAll"
      @sort="sort"
      @submitFilter="submitFilter"
      @clearFilter="clearFilter"
      @changePopupFilterSearch="changePopupFilterSearch"
      @delegateOnChangePageSize="changePageSize")

    router-view(@getList="refreshStockItems")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import getFields from './fields'
import { debouncer } from '@/utils/baseOperations'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import popupFilter from '@/view/global/popup-filter'
import Search from '@/view/global/search'

export default {
  name: 'Stock',

  data () {
    return {
      search: '',
      fields: getFields(this.$t),
      selectedStockType: {},
      selectedList: [],
      showBarcodeOperationsPopup: false,
      isWeightedBarcode: false,
      typeList: [
        {
          name: this.$t('Stocks.Item_Type_All'),
          id: null
        },
        {
          name: this.$t('Stocks.Item_Type_Stock'),
          id: 1
        },
        {
          name: this.$t('Stocks.Item_Type_Production'),
          id: 2
        },
        {
          name: this.$t('Stocks.Item_Type_Purchase'),
          id: 3
        }
      ],
      sortExpressionList: [],
      isPopupOpen: false,
      stockItemList: [],
      itemGroupList: [],
      selectedItemGroups: [],
      selectedStockItems: []
    }
  },

  components: {
    ToggleOverGroups,
    popupFilter
  },

  async created () {
    await this.getTaxes()
    await this.getItemGroups()
    this.changedStockType(this.typeList[0])
    this.getAllergenListAll({ pageNumber: 1, pageSize: 99999999 })
  },

  watch: {
    search: debouncer(function (val) {
      if (val.length === 1) return
      this.getStockItems({
        name: val,
        to: 1,
        type: this.selectedStockType.id
      })
    }, 500)
  },

  computed: {
    ...mapGetters('Stock', ['StockItems', 'isEmpty', 'Page']),

    ...mapGetters('Group', ['allItemGroups']),

    isLoadingSearch () {
      return this.$wait.is(['Stock/getStockItems'])
    },

    isLoading () {
      return (
        this.$wait.is(['Tax/getTaxes']) ||
        this.$wait.is(['Group/getItemGroupAll']) ||
        this.$wait.is(['Stock/getStockItems'])
      )
    }
  },

  methods: {
    ...mapActions('Tax', ['getTaxes']),

    ...mapActions('Group', ['getItemGroupAll']),

    ...mapActions('Stock', ['getStockItems', 'searchStockItem', 'generateBarcodeList']),

    ...mapActions('Allergen', ['getAllergenListAll']),

    ...mapActions('Notify', ['notifyShow']),

    async getStockList (page = 1, pageSize = this.Page.size) {
      const payload = {
        name: this.search,
        type: this.selectedStockType.id,
        to: page,
        pageSize,
        sortExpressionList: !this.sortExpressionList.length ? [{columnName: "ItemGroupName", sortType: 0}, {columnName: "Name", sortType: 0}] : this.sortExpressionList,
        itemGroupIds: this.selectedItemGroups ? this.selectedItemGroups.map(item => item.id) : null,
        stockItemIds: this.selectedStockItems ? this.selectedStockItems.map(stockItem => stockItem.id) : null
      }
      this.getStockItems(payload)
    },

    toggleBarcodeOperations () {
      this.showBarcodeOperationsPopup = !this.showBarcodeOperationsPopup
    },

    closeBarcodeOperationsPopup () {
      this.showBarcodeOperationsPopup = false
    },

    changeBarcodeType () {
      this.isWeightedBarcode = !this.isWeightedBarcode
    },

    onSelect (item) {
      const isChecked = !this.selectedList.some(i => i.id === item.id)
      if (isChecked) this.selectedList.push(item)
      else this.selectedList.splice(this.selectedList.findIndex(i => i.id === item.id), 1)
    },
    selectAll (isChecked) {
      if (isChecked) {
        this.selectedList = [...this.StockItems]
      } else this.selectedList = []
    },

    async getItemGroups () {
      const itemGroupFilterList = this.fields.find(i => i.name === 'itemGroupName')
      await this.getItemGroupAll()
      itemGroupFilterList.filter.list = [...this.allItemGroups]
      itemGroupFilterList.filter.selectedList = [...this.allItemGroups]
      this.selectedItemGroups = [...this.itemGroupList]
    },
    onChangeSearch(text) {
      this.search = text
      if (!this.search || this.search.length < 2) return 
      else {
        this.searchStockItem({ Text: this.search, types: [1, 2, 3] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },
    changedStockType (item) {
      this.clearSearch()
      this.selectedStockType = item
      this.getStockList(1)
    },

    onChangePopupFilterCheckbox ({ item, isChecked, field }) {
      if (isChecked) {
        this[field.filter.selectedList].push(item)
      } else {
        this[field.filter.selectedList].splice(this[field.filter.selectedList].findIndex(i => i.id === item.id), 1)
      }
    },

    async submitFilter (field) {
      this[field.filter.selectedListName] = field.filter.selectedList
      await this.getStockList()
    },

    async clearFilterGlobal () {
      this.selectedItemGroups = []
      this.stockItemList = []
      this.itemGroupList = []
      this.selectedStockItems = []
      await this.getStockList()
    },

    async clearFilter (field) {
      this[field.filter.selectedListName] = field.filter.selectedList
      await this.getStockList()
    },

    changePopupFilterSearch ({search, field}) {
      if (field.filter.backendSearch) {
        switch (field.filter.backendSearch) {
          case 'stockItem':
            if (search.length > 1) {
              this.searchStockItem({Text: search, types: [1, 2, 3]})
                .then(res => {
                  field.filter.list = res.data.items
                })
            } else {
              field.filter.list = []
            }
            break
        }
      }
    },

    refreshStockItems (page) {
      this.getStockList(page)
    },

    clearSearch () {
      this.search = ''
    },

    generateBarcode () {
      const payload = {
        barcodeType: this.isWeightedBarcode ? 1 : 2,
        stockItemIds: this.selectedList.map(i => i.id)
      }
      this.generateBarcodeList(payload).then(res => {
        if (res) {
          const message = this.$t('Stock.notification_BarcodesGenerated')
          this.notifyShow({ message })
          this.selectedList = []
          this.isWeightedBarcode = false
          this.getStockList(this.Page.number)
        }
      })
    },

    action (routeName, item) {
      this.$router.push({
        name: routeName,
        params: {
          id: item.id,
          item
        }
      })
    },

    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      await this.getStockList()
    },

    itemNew () {
      this.$router.push({
        name: 'StockNew'
      })
    },

    itemEdit (item) {
      this.action('StockEdit', item)
    },

    itemDelete (item) {
      this.action('StockRemove', item)
    },

    itemShow (item) {
      this.action('StockView', item)
    },

    changePageSize (pageSize) {
      this.getStockList(1, pageSize)
    },

    changePage (page) {
      this.getStockList(page)
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

:deep() .Tableee-main {
  overflow: auto;
}
:deep() .Tableee-head .Tableee-col {
  position: static !important;
}
.barcode-selection {
  position: relative;
    &-popup {
    @include MiniPopupMixin(
      $width: 252px,
      $margin: 40px 0 0 0,
      $triangle-direction: 'right',
      $padding-hor: 15px,
      $padding-ver: 15px,
      $display: none
    );

    &.show-barcode-operation-popup {
      display: flex;
      flex-direction: column;
      width: 100px;
    }

    &-header {
      color: $color-dark;
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
    }
    &-contents {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.MiniPopup-right {
  @include MiniPopupMixin(
    $width: 150px,
    $triangle-direction: 'right',
    $padding-hor: 10px,
    $padding-ver: 10px,
  );
  height: auto;
  top: calc(50% - 25px);
  left: 80px;
  border-color: $color-warning;
  z-index: 999999999999;
}
</style>
