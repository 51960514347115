var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.PageLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-dropdown" },
            [
              _c("CustomDatepicker", {
                attrs: {
                  id: "ProductionDatepicker",
                  isLoading: _vm.$wait.is("getDateFilterSettings"),
                  range: true,
                  disabled: _vm.isFetchingData,
                },
                model: {
                  value: _vm.dates,
                  callback: function ($$v) {
                    _vm.dates = $$v
                  },
                  expression: "dates",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    iconName: "icon-global-add",
                  },
                  on: { click: _vm.addRawMaterialPlan },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.addData", {
                          field: _vm.$t("Dictionary.rawMaterialPlanningTitle"),
                        })
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeFilterComponent", {
            ref: "rawMaterialStoreFilter",
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c(
            "div",
            { staticClass: "show-drafts m-left-15" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-raw-material-planning-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                { staticClass: "RawMaterialPlanning" },
                [
                  _vm.isFetchingData ? _c("TableLoading") : _vm._e(),
                  !_vm.isFetchingData && _vm.isEmptyRawMaterialList
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t("Global.emptyState_Title", {
                            field: _vm.$t(
                              "Dictionary.rawMaterialPlanningTitle"
                            ),
                          }),
                          description: _vm.$t("Global.emptyState_Description", {
                            field: _vm.$t(
                              "Dictionary.rawMaterialPlanningTitle"
                            ),
                          }),
                          buttonText: _vm.$t("Global.addData", {
                            field: _vm.$t(
                              "Dictionary.rawMaterialPlanningTitle"
                            ),
                          }),
                        },
                        on: { emptyAddButton: _vm.addRawMaterialPlan },
                      })
                    : _vm._e(),
                  !_vm.isFetchingData && !_vm.isEmptyRawMaterialList
                    ? _c(
                        "div",
                        [
                          _c("NestedTable", {
                            attrs: {
                              data: _vm.RawMaterialHeaderList,
                              fields: _vm.rawMaterialHeaderFields,
                              detailFields: _vm.rawMaterialDetailFields,
                              isDetailLoading: _vm.isDetailFetching,
                              finalizeButtonText: _vm.$t("Global.Save"),
                              actions: ["edit", "delete", "export"],
                              detailActions: ["edit"],
                              readOnlyForDetail: true,
                              usePortalAction: true,
                              usePortalFooter: true,
                            },
                            on: {
                              editHeaderAction:
                                _vm.editHeaderRawMaterialPlanning,
                              deleteHeaderAction: _vm.removeHeader,
                              detailChangeAction: _vm.updateQuantity,
                              showDetailAction: _vm.showDetailAction,
                              showExportPopup: _vm.showExportPopup,
                            },
                          }),
                          _c("portal", {
                            attrs: { to: "description" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (header) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        header.row.information
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass:
                                                  "show-MiniPopup relative width-max-content",
                                                attrs: {
                                                  size: "medium",
                                                  variant: "info",
                                                  id: `raw-material-planning-header-info-button-${header.rowIndex}`,
                                                  iconName: "icon-global-info",
                                                  justIcon: true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "MiniPopup-right-primary m-top-15 c-dark",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          header.row.information
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              123889202
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "requestType" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (detail) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c("customSelectInput", {
                                          staticClass: "custom-select",
                                          attrs: {
                                            name: "requestTypes",
                                            optionData: _vm.requestTypes,
                                            optionKey: "value",
                                            optionIdKey: "value",
                                            isValueNumber: true,
                                            id: "raw-material-request-store-types",
                                            extraDataEmit: detail,
                                            isChangeValue: false,
                                            disabled: detail.row.status === 2,
                                            selectedDataRequired: true,
                                            hideDefault: true,
                                            optionIdName:
                                              "raw-material-request-store-types",
                                          },
                                          on: { change: _vm.updateRawMaterial },
                                          model: {
                                            value: detail.row.requestType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                detail.row,
                                                "requestType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detail.row.requestType",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3101746957
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "requestedStoreId" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (detail) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c("customSelectInput", {
                                          staticClass: "custom-select-store",
                                          attrs: {
                                            name: "requestedStoreId",
                                            optionData: _vm.storeList,
                                            disabled:
                                              detail.row.requestType !== 2 ||
                                              detail.row.status === 2,
                                            id: "raw-material-request-store-list",
                                            extraDataEmit: detail,
                                            isChangeValue: false,
                                            selectedDataRequired: true,
                                            optionIdName:
                                              "raw-material-request-store-list",
                                          },
                                          on: {
                                            change: _vm.updateRequestedStore,
                                          },
                                          model: {
                                            value: detail.row.requestedStoreId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                detail.row,
                                                "requestedStoreId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detail.row.requestedStoreId",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3891623838
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "quantity" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (detail) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c("custom-number-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                decimal: _vm.$getConst(
                                                  "DECIMAL_FOR_QUANTITY"
                                                ),
                                                max: _vm.$getConst(
                                                  "MAX_DIGITS_FOR_QUANTITY"
                                                ),
                                              },
                                              expression:
                                                "{decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                                            },
                                          ],
                                          staticClass:
                                            "custom-select m-bottom-15",
                                          attrs: {
                                            id: "raw--material-detail-form-quantity",
                                            name: "quantity",
                                            "data-vv-as": _vm.$t(
                                              "Dictionary.Quantity"
                                            ),
                                            extraDataEmit: detail,
                                            isChangeValue: false,
                                            disabled: detail.row.status === 2,
                                            error:
                                              _vm.veeErrors.has("quantity"),
                                          },
                                          on: { blur: _vm.updateRawMaterial },
                                          model: {
                                            value: detail.row.quantity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                detail.row,
                                                "quantity",
                                                $$v
                                              )
                                            },
                                            expression: "detail.row.quantity",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3595225546
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "actionPortal" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (rows) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "popup-container flexbox",
                                      },
                                      [
                                        rows.rowData.status === 1 ||
                                        rows.rowData.status === 4
                                          ? _c("ActionButton", {
                                              attrs: {
                                                data: !rows.rowData
                                                  .isRawMaterial
                                                  ? ["edit", "remove"]
                                                  : ["remove"],
                                                item: rows,
                                              },
                                              on: {
                                                onItemDelete: function (
                                                  $event
                                                ) {
                                                  return _vm.removeData(
                                                    rows.row,
                                                    rows.rowData.isRawMaterial,
                                                    rows.rowData.id
                                                  )
                                                },
                                                onItemEdit: function ($event) {
                                                  return _vm.editDetailItem(
                                                    rows.row
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              342532509
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "footerPortal" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (rowData) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "popup-container flexbox",
                                      },
                                      [
                                        !rowData.isRawMaterial &&
                                        rowData.status !== 2
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass: "m-right-20",
                                                attrs: {
                                                  id: "nested-detail-add--new-raw-material-detail",
                                                  inline: "",
                                                  primary: "",
                                                  size: "small",
                                                  iconName: "icon-global-add",
                                                  iconClass: "icon-global-add",
                                                  disabled:
                                                    _vm.isDetailFetching ||
                                                    _vm.isOnlyListUsage,
                                                  isLoading:
                                                    _vm.isDetailFetching,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addNewDetail(
                                                      rowData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Global.addProductionItem"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        rowData.isRawMaterial
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass: "m-right-20",
                                                attrs: {
                                                  id: "nested-detail-planning-change",
                                                  inline: "",
                                                  primary: "",
                                                  size: "small",
                                                  iconName: "icon-arrow-left",
                                                  disabled:
                                                    _vm.isDetailFetching,
                                                  isLoading:
                                                    _vm.isDetailFetching,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeToPlanningList(
                                                      rowData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "ProductionPlaning.returnToPlanning"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        rowData.isRawMaterial &&
                                        rowData.detailList.length > 0 &&
                                        rowData.status !== 2
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass: "m-right-20",
                                                attrs: {
                                                  id: "nested-detail-planning-change",
                                                  inline: "",
                                                  primary: "",
                                                  size: "small",
                                                  disabled: _vm.isSavingData,
                                                  isLoading: _vm.isSavingData,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.saveAndCreate(
                                                      rowData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Dictionary.saveAnd"
                                                      )
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Dictionary.createRequest"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        !rowData.isRawMaterial &&
                                        rowData.detailList.length > 0
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass: "m-right-20",
                                                attrs: {
                                                  id: "nested-detail-raw-material-change",
                                                  inline: "",
                                                  primary: "",
                                                  size: "small",
                                                  disabled:
                                                    _vm.isDetailFetching,
                                                  isLoading:
                                                    _vm.isDetailFetching,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeToRawMaterialList(
                                                      rowData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "ProductionPlaning.showRawMaterials"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        rowData.isRawMaterial &&
                                        _vm.zeroQuantityList.length > 0
                                          ? _c("InfoAlert", {
                                              attrs: {
                                                description: _vm.$t(
                                                  "ProductionPlaning.askForDeletingProductionItems"
                                                ),
                                                isProductItemWarning: true,
                                                popupData: _vm.zeroQuantityList,
                                                closePopupText: _vm.$t(
                                                  "ProductionPlaning.returnToPlanning"
                                                ),
                                              },
                                              on: {
                                                closePopupAction: function (
                                                  $event
                                                ) {
                                                  return _vm.changeToPlanningList(
                                                    rowData
                                                  )
                                                },
                                                deleteAction: function (
                                                  $event
                                                ) {
                                                  return _vm.deleteZeroProductItems(
                                                    rowData
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.showZeroStockItemList
                                          ? _c("InfoAlert", {
                                              attrs: {
                                                description: _vm.$t(
                                                  "ProductionPlaning.askForDeletingStockItems"
                                                ),
                                                isStockItemWarning: true,
                                                popupData:
                                                  _vm.zeroQuantityStockItemList,
                                                closePopupText: _vm.$t(
                                                  "ProductionPlaning.returnToPlanning"
                                                ),
                                              },
                                              on: {
                                                closePopupAction: function (
                                                  $event
                                                ) {
                                                  return _vm.changeToRawMaterialList(
                                                    rowData
                                                  )
                                                },
                                                deleteAction: function (
                                                  $event
                                                ) {
                                                  return _vm.deleteZeroStockItems(
                                                    rowData
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              1600371717
                            ),
                          }),
                          !_vm.isFetchingData
                            ? _c("TableFoot", {
                                attrs: { page: _vm.Page },
                                on: {
                                  delegateOnChangePage: _vm.changePage,
                                  delegateOnChangePageSize: _vm.changePageSize,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
          _c("router-view", {
            on: {
              getRawMaterialList: _vm.getRawMaterialList,
              showDrafts: function ($event) {
                _vm.showDrafts = true
              },
            },
          }),
          _vm.isFinalize
            ? _c("InfoAlert", {
                attrs: { popupData: _vm.resData },
                on: { closePopupAction: _vm.closePopup },
              })
            : _vm._e(),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }