var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "register-page" }, [
    _vm.showCheckMail
      ? _c("div", { staticClass: "check-email" }, [
          _c("h1", { staticClass: "success-message" }, [
            _vm._v(_vm._s(_vm.$t("Register.confirmationLink"))),
          ]),
          _c("p", { staticClass: "m-bottom-20" }, [
            _vm._v(_vm._s(_vm.$t("Register.checkEmail"))),
          ]),
        ])
      : _c(
          "form",
          {
            staticClass: "Auth-form Register-form Form",
            on: {
              submit: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item-group Form-item-header" }, [
              _c("h1", { staticClass: "Auth-form-title" }, [
                _vm._v(_vm._s(_vm.$t("Register.formHeaderTitle"))),
              ]),
              _c("div", { staticClass: "log-out" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("Register.logOutDesc")))]),
                _c(
                  "button",
                  { attrs: { type: "button" }, on: { click: _vm.logOut } },
                  [_vm._v(_vm._s(_vm.$t("Register.logOut")))]
                ),
              ]),
            ]),
            _c("div", { staticClass: "Form-item-group" }, [
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Register.formFieldFirstName"))),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.firstname,
                        expression: "form.firstname",
                      },
                    ],
                    staticClass: "txt",
                    class: { "is-danger": _vm.veeErrors.has("firstname") },
                    attrs: {
                      id: "input-auth-register-firstname",
                      name: "firstname",
                      autocomplete: "off",
                      placeholder: _vm.$t(
                        "Register.formFieldFirstNamePlaceholder"
                      ),
                      type: "text",
                      disabled: "",
                    },
                    domProps: { value: _vm.form.firstname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "firstname", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "Form-item required m-left-15" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Register.formFieldLastName"))),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.surname,
                        expression: "form.surname",
                      },
                    ],
                    staticClass: "txt",
                    class: { "is-danger": _vm.veeErrors.has("surname") },
                    attrs: {
                      id: "input-auth-register-surname",
                      name: "surname",
                      autocomplete: "off",
                      placeholder: _vm.$t(
                        "Register.formFieldLastNamePlaceholder"
                      ),
                      type: "text",
                      disabled: "",
                    },
                    domProps: { value: _vm.form.surname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "surname", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "Form-item-group" }, [
              _c("div", { staticClass: "Form-item email required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Register.formFieldEmail"))),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email",
                      },
                    ],
                    staticClass: "txt txt-right-icon",
                    class: { "is-danger": _vm.veeErrors.has("email") },
                    attrs: {
                      id: "input-auth-register-email",
                      name: "email",
                      autocomplete: "new-email",
                      placeholder: _vm.$t("Register.formFieldEmailPlaceholder"),
                      type: "email",
                      disabled: "",
                    },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "email", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "Form-item m-left-15" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Register.phoneNumber"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("PhoneNumber", {
                      attrs: {
                        isDisabled: _vm.isPhoneNumberDisabled,
                        existingPhoneNumber: _vm.form.phoneNumber,
                        selectedCountry: _vm.country,
                      },
                      on: {
                        getCountryCode: _vm.getCountryCode,
                        getPhoneNumber: _vm.getPhoneNumber,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "Form-item-group" }, [
              _c("div", { staticClass: "Form-item name required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Register.formFieldTenantName"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.companyName,
                          expression: "form.companyName",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value:
                            "required|min:2|max:128|very_singularity:Tenant/check/name,Name",
                          expression:
                            "'required|min:2|max:128|very_singularity:Tenant/check/name,Name'",
                        },
                      ],
                      staticClass: "txt txt-right-icon",
                      class: { "is-danger": _vm.veeErrors.has("companyName") },
                      attrs: {
                        id: "input-auth-tenant-name",
                        name: "companyName",
                        autocomplete: "off",
                        placeholder: _vm.$t(
                          "Register.formFieldCompanyNamePlaceholder"
                        ),
                        "data-vv-as": _vm.$t("Register.formFieldTenantName"),
                        type: "text",
                      },
                      domProps: { value: _vm.form.companyName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "companyName", $event.target.value)
                        },
                      },
                    }),
                    _c(
                      "Button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPendingCompanyName,
                            expression: "isPendingCompanyName",
                          },
                        ],
                        attrs: {
                          id: "btn-auth-tenant-loading",
                          type: "button",
                          variant: "icon loading",
                          tabindex: "-1",
                        },
                      },
                      [_c("Loading", { attrs: { theme: "disable" } })],
                      1
                    ),
                    _c(
                      "Button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isValidateCompanyName,
                            expression: "isValidateCompanyName",
                          },
                        ],
                        attrs: {
                          id: "btn-auth-tenant-check",
                          type: "button",
                          variant: "icon check",
                          tabindex: "-1",
                        },
                      },
                      [
                        _c("svg", { staticClass: "icon icon-check" }, [
                          _c("use", { attrs: { "xlink:href": "#icon-check" } }),
                        ]),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("companyName"),
                            expression: "veeErrors.has('companyName')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("companyName")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "Form-item required m-left-15" },
                [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Register.formFieldCurrency"))),
                  ]),
                  _c("customSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "custom-select",
                    attrs: {
                      name: "currency",
                      optionData: _vm.CurrencyList,
                      optionTitle: "isoCode",
                      selectedEmitValue: "id",
                      componentName: "currency-select",
                      isDefaultTextActive: false,
                      defaultTitle: _vm.$t("Register.formFieldCurrencySelect"),
                      "data-vv-as": _vm.$t("Register.formFieldCurrency"),
                      isSearchActive: true,
                      error: _vm.veeErrors.has("currency"),
                    },
                    model: {
                      value: _vm.form.currency,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "currency", $$v)
                      },
                      expression: "form.currency",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("currency"),
                        expression: "veeErrors.has('currency')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("currency") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item-group" }, [
              _c(
                "div",
                { staticClass: "Form-item name required" },
                [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Global.timeZone"))),
                  ]),
                  _c("customSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "custom-select",
                    attrs: {
                      name: "timeZone",
                      optionData: _vm.TimeZoneList,
                      optionTitle: "value",
                      selectedEmitValue: "id",
                      componentName: "time-zone-select",
                      isDefaultTextActive: false,
                      defaultTitle: _vm.$t("Register.formFieldTimeZoneSelect"),
                      "data-vv-as": _vm.$t("Global.timeZone"),
                      isSearchActive: true,
                      searchIn: true,
                      error: _vm.veeErrors.has("timeZone"),
                    },
                    model: {
                      value: _vm.form.timeZone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "timeZone", $$v)
                      },
                      expression: "form.timeZone",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("timeZone"),
                        expression: "veeErrors.has('timeZone')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("timeZone") },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "Form-item required m-left-15" },
                [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Settings.User_FormField_language"))),
                  ]),
                  _c("customSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "custom-select",
                    attrs: {
                      name: "languageId",
                      optionData: _vm.clearLanguages,
                      optionTitle: "name",
                      selectedEmitValue: "id",
                      componentName: "language-select",
                      isDefaultTextActive: false,
                      defaultTitle: _vm.$t("Global.select_language"),
                      "data-vv-as": _vm.$t("Settings.User_FormField_language"),
                      isSearchActive: true,
                      error: _vm.veeErrors.has("languageId"),
                    },
                    model: {
                      value: _vm.form.languageId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "languageId", $$v)
                      },
                      expression: "form.languageId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("languageId"),
                        expression: "veeErrors.has('languageId')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("languageId") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item-group" }, [
              _c("div", { staticClass: "Form-item" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Register.formFieldTcknVkn"))),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.taxNumber,
                        expression: "form.taxNumber",
                      },
                    ],
                    staticClass: "txt",
                    attrs: {
                      id: "input-auth-register-tax-number",
                      name: "taxNumber",
                      type: "number",
                      autocomplete: "off",
                      placeholder: _vm.$t(
                        "Register.formFieldTcknVknPlaceholder"
                      ),
                    },
                    domProps: { value: _vm.form.taxNumber },
                    on: {
                      keydown: _vm.preventNotNumberValues,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "taxNumber", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "Form-item m-left-15" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Register.formFieldTaxOffice"))),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.taxOfficeName,
                        expression: "form.taxOfficeName",
                      },
                    ],
                    staticClass: "txt",
                    attrs: {
                      id: "input-auth-register-tax-office",
                      name: "taxOffice",
                      type: "text",
                      autocomplete: "off",
                      placeholder: _vm.$t(
                        "Register.formFieldTaxOfficePlaceholder"
                      ),
                    },
                    domProps: { value: _vm.form.taxOfficeName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "taxOfficeName", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "Form-item x2 m-top-40 half" },
              [
                _c(
                  "div",
                  { staticClass: "userAgreement" },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        id: "checkbox-register-open-user-agreement",
                        label: "",
                      },
                      model: {
                        value: _vm.userAgreement,
                        callback: function ($$v) {
                          _vm.userAgreement = $$v
                        },
                        expression: "userAgreement",
                      },
                    }),
                    _c(
                      "a",
                      { staticClass: "link", on: { click: _vm.openAgreement } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Register.formFieldUserAgreement"))
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Register.formFieldUserAgreementText"))
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    class:
                      _vm.isFormReady && !_vm.isLoading
                        ? "btn-success"
                        : "btn-gray",
                    attrs: {
                      type: "submit",
                      primary: "",
                      size: "medium",
                      id: "signup-button",
                      variant: "full",
                      disabled: !_vm.isFormReady || _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.$t("Register.formFieldSubmit")))]
                    ),
                    _c("Loading", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { theme: "disable" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "Modal",
              {
                attrs: { isOpen: _vm.isOpenAgreementModal },
                on: { closeModal: _vm.closeAgreementModal },
              },
              [
                _c("template", { slot: "title" }, [
                  _vm._v(_vm._s(_vm.$t("UserAgreement.title"))),
                ]),
                _c("template", { slot: "content" }, [
                  _c("div", {
                    staticClass: "Register-agreementText",
                    domProps: {
                      innerHTML: _vm._s(_vm.agreement.agreementText),
                    },
                  }),
                ]),
                _c(
                  "template",
                  { slot: "footer" },
                  [
                    _c(
                      "Button",
                      {
                        attrs: { size: "xsmall", primary: "" },
                        on: { click: _vm.confirmAgreement },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("UserAgreement.confirmationButton"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }