<template lang="pug">
Page
  template(slot="header-right-content")
    div
      Button(
      v-if="hasPermissionToCreateInventory"
      primary
      size="medium"
      id="btn-inventories-new"
      type="button",
      iconName="icon-global-add"
      iconClass="icon-global-add"
      v-show="!isEmpty && !isLoading",
      @click="popupOpen")
        span {{ $t('Inventories.pageHeader_CreateInventoryButton') }}
  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="types"
      :selectedItem="selectedType"
      :useUpperCase="false"
      @delegateChangeSelectedItem="onChangeType"
      fieldKey="name"
    )
  template(slot="toolbar-left")
    CustomMultiselect.inventory-toolbar-filters__item(
      id="inventory-store-filter"
      :items="storeList"
      componentName="store"
      :selected="storeFilter"
      :label="$t('Inventories.toolbar_Stores_Label')"
      :unselectedText="$t('Inventories.toolbar_Stores_Unselected')"
      :popupHeader="$t('Inventories.toolbar_Stores_PopupHeader')"
      :allLabel="$t('Inventories.toolbar_Stores_AllLabel')"
      :buttonText="$t('Inventories.toolbar_Stores_ButtonText')"
      buttonIconLeft="btn-success"
      @delegateCheckAll="onChangeStoreFilter"
      @delegateCheckItem="onChangeStoreFilter"
      @delegateSubmitItems="getInventoryList")

    .closed-inventory-filter.m-left-15
      CustomCheckbox(
      id="checkbox-inventories-include-closed"
      :checked="showClosed"
      :label="$t('Inventories.filter_includeClosed')"
      @change="changeShowClosedStatus")

  template(slot="toolbar-right")
    ViewType.view-type(
      firstNodeIcon="icon-view-type-list"
      secondNodeIcon="icon-view-type-group"
      :firstSelected="viewTypeList"
      @firstNodeClick="listViewType(true)"
      @secondNodeClick="listViewType(false)"
    )
  template(slot="content-center")

    TableLoading(v-if="isLoading")

    EmptyState(
    v-if="isEmpty && !isLoading",
    :title="$t('Inventories.emptyState_Title')",
    :description="$t('Inventories.emptyState_Description')",
    :buttonText="$t('Inventories.emptyState_Button')",
    @emptyAddButton="popupOpen")

    TableView(
      v-if="!isEmpty && !isLoading && viewTypeList"
      :fields="fields"
      :data="inventories_List"
      :page="inventoriesListPage"
      :usePortalAction="true"
      :readOnly="true"
      :componentName="'InventoriesTable'"
      @delegateOnItemShow="gotoDetail"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
    )
    portal(to="status" v-if="!isEmpty && !isLoading && viewTypeList")
      .status-type(slot-scope = 'row' :class="getInventoryStatusName(row.status, 2)")
        span {{ getInventoryStatusName(row.status,1) }}
    portal(to="type" v-if="!isEmpty && !isLoading && viewTypeList")
      .inventory-type(slot-scope = 'row')
        span {{ row.type === 1 ? $t('Inventories.inventoryTypePeriodic') : $t('Inventories.inventoryTypeIntermediate') }}
    portal(to="inventoryClosingMethod" v-if="!isEmpty && !isLoading && viewTypeList")
      div(slot-scope = 'row')
        span {{ row.inventoryClosingMethod === 1 ? $t('Stores.popupForm_resetUncounted') : $t('Stores.popupForm_handOverUncounted') }}
    portal(to="actionPortal" v-if="!isEmpty && !isLoading && viewTypeList")
      div(slot-scope = 'row')
        ActionButton(
          v-if="row.status !== 4 && row.status !== 3 && !(row.status == 2 && !row.isLastClosed)"
          id="inventories-list-action-button"
          :data="row.status === 1 ? (hasPermissionToCloseInventory ? ['remove','customAction'] : ['remove']) : ['edit']"
          :item="row"
          :customActionIconClass="'icon-global-passive-recipe'"
          :customActionIconName="'icon-global-passive-recipe'"
          :customActionName="$t('Inventories.closeInventory_CloseButton')"
          @onItemDelete="remove(row)"
          @onItemEdit="editPopupOpen(row)"
          @onItemCustomAction="close(row)"
        )
      // BOX 

    BoxView(
      v-if="!isEmpty && !isLoading && !viewTypeList"
      :fields="fields"
      :data="inventories_List"
      :extraClass="{forContent: 'box-flex-row'}"
      :page="inventoriesListPage"
      :componentName="'InventoryBoxView'"
      :usePortalAction="true"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemShow="gotoDetail"
    )
    portal(to="status" v-if="!isEmpty && !isLoading && !viewTypeList")
      .status-type(slot-scope = 'row' :class="getInventoryStatusName(row.status, 2)")
        span {{ getInventoryStatusName(row.status,1) }}
    portal(to="type" v-if="!isEmpty && !isLoading && !viewTypeList")
      .inventory-type(slot-scope = 'row')
        span {{ row.type === 1 ? $t('Inventories.inventoryTypePeriodic') : $t('Inventories.inventoryTypeIntermediate') }}
    portal(to="inventoryClosingMethod" v-if="!isEmpty && !isLoading && !viewTypeList")
      div(slot-scope = 'row')
        span {{ row.inventoryClosingMethod === 1 ? $t('Stores.popupForm_resetUncounted') : $t('Stores.popupForm_handOverUncounted') }}
    portal(to="actionPortal-Box" v-if="!isEmpty && !isLoading && !viewTypeList")
      div(slot-scope = 'row')
        ActionButton(
          v-if="row.status !== 4 && row.status !== 3 && !(row.status == 2 && !row.isLastClosed) && !viewTypeList"
          id="inventories-list-action-button"
          :data="row.status === 1 ? ['remove','customAction'] : ['edit']"
          :item="row"
          :customActionIconClass="'icon-global-passive-recipe'"
          :customActionIconName="'icon-global-passive-recipe'"
          :customActionName="$t('Inventories.closeInventory_CloseButton')"
          @onItemDelete="remove(row)"
          @onItemEdit="editPopupOpen(row)"
          @onItemCustomAction="close(row)"
        )
    // POPUPS
    InventoryEditPopup(
      :inventory="editPopup.inventory"
      :status="editPopup.status"
      @closePopup="editPopupClose"
      @submit="onSubmitEditCountingForm"
    )
    Popup(
      :status="popup.status",
      @buttonClosePopup="popupClose"
    )

      template(slot="title") {{ $t('Inventories.popupAdd_Title') }}
      template(slot="detail")
        .Popup-info-text
          Icon(name="icon-tavsan")
          p {{ $t('Inventories.popupAdd_Info') }}

      template(slot="content")
        form.Form(
        id="form-create-inventory"
        @submit.prevent="onSubmitForm")

          .Form-item
            MultiselectRadio(
              v-model="form.type",
              :data="[{text: $t('ListsInventory.form_type_1'), value: 1 }, { text: $t('ListsInventory.form_type_2'), value: 2 }]")
          .Form-item.required
            label.Form-item-label {{ $t('Inventories.popupAdd_FormField_Date') }}
            .control
              .form-item-select.date-start
                CustomDatepicker(
                  id="dp-inventories-popup-date-start"
                  name="inventoryDate",
                  :data-vv-as="$t('Inventories.popupAdd_FormField_Date') ",
                  v-model="form.inventoryDate"
                  :class="{ 'is-danger': veeErrors.has(`inventoryDate`) }"
                  v-validate="'required'"
                  pickerType="manuel",
                  inputIconRight="icon-datepicker-big"
                  inputIcon="")

              showValidateError(
                v-show="veeErrors.has('despatchDate')"
                :errorName="veeErrors.first('despatchDate')"
              )

          .Form-item.required
            label.Form-item-label(:id="`inventory-popup-form-label-store-name`")
              | {{ $t('Inventories.popupAdd_FormField_Store') }}
            .control.form-item-select
              custom-search(
                ref="secondaryInput"
                component-name="inventory-popup-store-select"
                name="storeName",
                v-model="form.store",
                :data-vv-as="$t('Despatches.detail_Popup_FormField_StoreName')",
                v-validate="'required|is_not:-1'"
                :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
                :on-search="getOptionsStore",
                :options="searchStoreList",
                label="name",
                :searchAll="true"
                :withDebounce="false"
                :placeholder="$t('Despatches.detail_Popup_FormField_StoreName_Placeholder')"
                :loading="isSearchStoreLists"
                @input="inputSelection"
                @resetOptions="resetOptions"
              )

              showValidateError(
                v-show="veeErrors.has('storeName')"
                :errorName="veeErrors.first('storeName')"
              )

          div.Form-item.required

            label.Form-item-label {{ $t('Stores.popupForm_Field_inventoryClosingMethod') }}

            .control.form-item-select
              Icon(name="icon-down-arrow")

              customSelectInput(
                name="inventoryClosingMethod",
                :optionData="inventoryClosingMethods"
                optionKey="value"
                optionIdKey="value"
                :isValueNumber="true"
                :disabled="currentStore?.type === 3 || currentStore?.expirationDateControlIsEnabled"
                v-model="form.inventoryClosingMethod"
                :data-vv-as="$t('Stores.popupForm_Field_inventoryClosingMethod')"
                :error="veeErrors.has('inventoryClosingMethod')"
                v-validate="'required|is_not:-1'"
                id="select-inventory-closing-method"
                :defaultText="$t('Stores.popupForm_Field_inventoryClosingMethodSelect')"
              )

              showValidateError(
                v-show="veeErrors.has('inventoryClosingMethod')"
                :errorName="veeErrors.first('inventoryClosingMethod')"
              )

          .Form-item(v-if="form.type === 2")
            label.Form-item-label {{ $t('Inventories.popupAdd_FormField_List') }}
            .control
              custom-search(
              component-name="inventory-form-list"
              name="inventory-form-list"
              v-model="form.list"
              :data-vv-as="$t('Inventories.popupAdd_FormField_List')"
              :on-search="getOptionsList"
              :options="selectOptionsList"
              label="name"
              :loading="isSearchSpotLists"
              :searchAll="true"
              :placeholder="$t('Inventories.Form_List_Placeholder')"
              )
                template(slot="no-options") {{ $t('Inventories.Form_List_NoResults') }}

            .selected-items
              Button(
                size="medium"
                v-for="(item,index) in lists",
                :key="index",
                seashell,
                :id="`selected-stock-item-${index}`"
                @click="removeListItem(item)"
              )
                span {{ item.name }}
                Icon.m-left-5(
                  name="icon-popup-close"
                  :id="`selected-stock-item-delete-${index}`"
                )
      template(slot="footer")
        Button(
        primary
        size="medium"
        variant="full"
        id="btn-inventory-popup-submit"
        type="submit",
        form="form-create-inventory"
        :disabled="isCreating || (form.type === 2 && lists.length === 0)")
          span(v-show="!isCreating") {{ $t('Inventories.popupAdd_CreateButton') }}
          Loading(theme="disable", v-show="isCreating")

    router-view(
      @getList="getInventoryList"
    )

</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import alertbox from '@/mixins/alertbox'
import getFields from './fields/inventories.js'

import Popup from './popup'
import InventoryEditPopup from './popup/edit'
import ViewType from '@/view/global/view-type'
import MultiselectRadio from '@/view/global/multiselect-radio'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'Inventories',

  components: {
    Popup,
    MultiselectRadio,
    ToggleOverGroups,
    InventoryEditPopup,
    ViewType,
  },

  mixins: [
    alertbox()
  ],

  data () {
    return {
      popup: {
        status: false
      },
      editPopup: {
        status: false,
        inventory: null
      },
      currentStore: {},
      selectedType: {},
      viewTypeList: true,
      fields: getFields(this.$t),
      types: [
        {
          id: null,
          name: this.$t('ListsInventory.filterTypeAll')
        },
        {
          id: 1,
          name: this.$t('ListsInventory.filterTypeInventory')
        },
        {
          id: 2,
          name: this.$t('ListsInventory.filterTypeIntermediateInventory')
        }
      ],

      form: {
        inventoryDate: moment()._d,
        store: {
          id: null,
          name: null
        },
        list: null,
        type: 1,
        inventoryClosingMethod: '-1'
      },

      inventoryClosingMethods: [
        {
          value: 1,
          name: this.$t('Stores.popupForm_resetUncounted')
        },
        {
          value: 2,
          name: this.$t('Stores.popupForm_handOverUncounted')
        }
      ],
      lists: [],
      storeFilter: [],
      selectOptionsList: [],
      showClosed: false
    }
  },

  async mounted () {
    this.fetchStoreByName({name: ''})
    this.UPDATE_LIST_TYPE_FILTER([])
    this.UPDATE_LIST_FILTER([])
    this.selectedType = this.types[0]
    this.getInventoryList()
    this.getStoreList({pageNumber: 1, pageSize: 9999}).then(() => {
      this.storeFilter = [...this.storeList]
    })
    this.setDefaultStore()
  },

  computed: {
    ...mapGetters('Inventories', [
      'inventories_List',
      'inventories_IsEmpty',
      'selectedList',
      'selectedListType',
      'inventoriesListPage'
    ]),

    ...mapGetters('Stores', ['storeList', 'searchStoreList']),

    ...mapGetters('Settings', ['checkPermission']),

    hasPermissionToCreateInventory () {
      return this.checkPermission('InsertInventory')
    },

    hasPermissionToCloseInventory () {
      return this.checkPermission('CloseInventory')
    },

    isEmpty () {
      return this.inventories_IsEmpty
    },

    isLoading () {
      return this.$wait.is('get_INVENTORY_LIST')
    },

    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    isCreating () {
      return this.$wait.is(['create_INVENTORY_HEADER', 'onSubmitCreateInventoryForm'])
    },

    isSearchSpotLists () {
      return this.$wait.is(['searchSpotLists'])
    },

    storeListItems () {
      return this.form.type !== 2 ? this.storeList : this.storeList.filter(i => i.type !== 3)
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Inventories', [
      'get_INVENTORY_LIST',
      'create_INVENTORY_HEADER',
      'close_INVENTORY',
      'upsertClosedInventoryCount'
    ]),

    ...mapMutations('Inventories', [
      'UPDATE_LIST_TYPE_FILTER',
      'UPDATE_LIST_FILTER'
    ]),

    ...mapActions('Settings', [
      'fetchUser'
    ]),

    ...mapActions('ListsInventory', ['searchSpotLists']),

    ...mapActions('Stores', ['getStoreList', 'fetchStoreByName']),


    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
    },

    inputSelection (data) {
      if (data.length !== 0) {
        this.form.store.id = data.id
        this.form.store.name = data.name
      }
    },

    resetOptions () {
      this.getOptionsStore('')
    },

    setDefaultStore () {
      this.fetchUser().then(res => {
        this.form.store.id = res ? res.data.user.defaultStoreId : -1
        this.form.store.name = res ? res.data.user.defaultStoreName : null
      })
    },

    getInventoryStatusName (value, isClass) {
      switch (value) {
        case 1: return isClass === 1 ? this.$t('Inventories.inventoryStatusOpen') : 'status-type-open'
        case 2: return isClass === 1 ? this.$t('Inventories.closedStatusText') : 'status-type-closed'
        case 3: return isClass === 1 ? this.$t('Inventories.inventoryStatusClosing') : 'status-type-closing'
        case 4: return isClass === 1 ? this.$t('Inventories.errorStatusText') : 'status-type-error'
        default: break
      }
    },

    async changeShowClosedStatus () {
      this.showClosed = !this.showClosed
      await this.getInventoryList()
    },

    onChangeStoreFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.storeFilter = [...this.storeList]
        } else {
          this.storeFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.storeFilter = []
        } else {
          this.storeFilter.splice(this.storeFilter.indexOf(event.item), 1)
        }
      }
    },

    popupClose () {
      this.popup.status = false
      this.form.inventoryDate = new Date()
      this.selectOptionsList = []
      this.form.inventoryClosingMethod = '-1'
      this.lists = []
      this.form.type = 1
      this.form.list = null
    },

    popupOpen () {
      this.popup.status = true
      this.setDefaultStore()
    },

    editPopupOpen (Inventory) {
      this.editPopup.status = true
      this.editPopup.inventory = Inventory
    },

    editPopupClose () {
      this.editPopup.status = false
    },

    getInventoryList (page = this.inventoriesListPage.number, pageSize = this.inventoriesListPage.size) {
      this.get_INVENTORY_LIST({
        payload: { 
          page,
          pageSize,
          storeList: this.storeFilter,
          type: this.selectedType.id,
          includeClosed: this.showClosed,
        },
      })
    },

    storeAlertType () {
      const title = this.$t('Inventories.costCenterRemoveTitle')
      const message = this.$t('Inventories.costCenterRemoveDesc')
      const confirmButtonText = this.$t('Inventories.costCenterRemoveConfirmButton')

      this.alert({
        title,
        message,
        confirm: {
          text: confirmButtonText,
          action: async (close) => {
            close()
            await this.onSubmitCreateInventoryForm()
          }
        }
      })
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        const checkStoreType = this.searchStoreList.find(i => i.id === this.form.store.id)

        if (checkStoreType.type === 3) await this.storeAlertType()
        else await this.onSubmitCreateInventoryForm()
      })
    }, 'onSubmitCreateInventoryForm'),

    async onSubmitCreateInventoryForm () {
      const inventory = {
        inventoryDate: moment(this.form.inventoryDate).format('YYYY-MM-DD'),
        storeId: this.form.store.id,
        type: this.form.type,
        inventoryClosingMethod: this.form.inventoryClosingMethod
      }
      if (this.form.type === 2) {
        inventory.inventorySpotCheckList = this.lists.map(_ => _.id)
      }
      await this.create_INVENTORY_HEADER(inventory).then(res => {
        if (res) {
          const { inventoryHeader } = res.data
          const currentStore = this.searchStoreList.find(store => store.id === inventoryHeader.storeId)
          const inventoryNumber = currentStore.name + '-' + moment(inventoryHeader.inventoryDate).format('DD/MM/YYYY')
          const message = this.$t('Global.notification_Created', {
            name: inventoryNumber
          })
          this.notifyShow({ message })
          // this.getInventoryList()
          // this.popupClose()
          this.gotoDetail({
            ...inventoryHeader,
            storeName: currentStore.name
          })
        }
      })
    },

    onSubmitEditCountingForm (value) {
      this.upsertClosedInventoryCount(value).then(result => {
        if (result) {
          this.editPopupClose()
        }
      })
    },

    changePage (page) {
      this.getInventoryList(page)
    },
    changePageSize (pageSize) {
      this.getInventoryList(1, pageSize)
    },
    
    listViewType (type) {
      this.viewTypeList = type
    },

    close (inventory) {
      this.$router.push({
        name: 'InventoryClose',
        params: {
          id: inventory.id,
          status: inventory.status,
          storeName: inventory.storeName,
          inventoryDate: inventory.inventoryDate,
          inventoryClosingMethod: inventory.inventoryClosingMethod
        }
      })
    },

    remove (item) {
      this.$router.push({
        name: 'InventoryRemove',
        params: {
          id: item.id,
          includeClosed: this.showClosed,
          item

        }
      })
    },

    edit (item) {
      this.$router.push({
        name: 'InventoryEdit',
        params: { }
      })
    },
    getOptionsList (search) {
      this.searchSpotLists(search)
        .then(res => {
          if (res) {
            this.selectOptionsList = res.data.inventoryListHeaderList
          }
        })
    },
    removeListItem (item) {
      this.lists.splice(this.lists.indexOf(item), 1)
    },
    async onChangeType (type) {
      this.selectedType = type
      await this.getInventoryList()
    },
    gotoDetail (Inventory) {
      if (Inventory.status === 3 || Inventory.status === 4 ) return
      const pathName = Inventory.type === 1 || Inventory.status !== 1 ? 'Inventory' : 'InventorySpotList'
      this.$router.push({
        name: pathName,
        params: {
          id: Inventory.id,
          status: Inventory.status,
          storeName: Inventory.storeName,
          inventoryType: Inventory.type,
          inventoryDate: Inventory.inventoryDate,
          inventoryClosingMethod: Inventory.inventoryClosingMethod
        }
      })
    }
  },
  watch: {
    async 'form.list' (value) {
      if (!value || this.lists.find(_ => _.id === value.id)) return
      this.lists.push(value)
    },
    lists (val) {
      if (val.length === 0) {
        this.form.list = null
      }
    },
    'form.store.id' (val) {
      this.currentStore = this.searchStoreList.find(store => store.id === this.form.store.id)
      this.form.inventoryClosingMethod = this.currentStore.inventoryClosingMethod
      if (this.currentStore.type === 3 || this.currentStore.expirationDateControlIsEnabled) this.form.inventoryClosingMethod = 1
    }
  }
}
</script>

<style lang="scss" scoped>

  .PageHeader {
    .right {
      margin-bottom: 9px;
    }

  }

  :deep(.Boxlist-actions) {
    top: 10px !important;
    right: 10px !important;
  }
  :deep(.boxLink) {
    cursor: pointer !important;
  }

  :deep(.box-header) {
    min-height: 55px !important;
    padding: 15px 15px !important
  }
  :deep(.Tableee-body .Tableee-col.portal-action) {
    div {
      margin-bottom: 0px !important;
    }
  }

  :deep(.Tableee-row) {
    cursor: pointer !important;
  }

  :deep(.Tableee-col) {
    &.status {
      width: 5%;
    }
    &.storeName {
      width: 20%;
    }
    &.inventoryDate {
      width: 12%;
    }
    &.type {
      width: 10%;
    }
    &.inventoryClosingMethod {
      width: 20%;
    }
  }

  .status-type {
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    padding: 5px 5px;
    border-radius: 4px;
    height: auto;
    &-open{
      background: rgba(($color-success), 0.2);
      color: $color-success;
    }
    &-closed{
      background: rgba(($color-light), 0.2);
      color: $color-light;
    }
    &-closing{
      background: rgba(($color-primary), 0.2);
      color: $color-primary;
    }
    &-error{
      background: rgba(($color-rejection), 0.2);
      color: $color-rejection
    }
  }

  .Popup-info-text {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    .icon {
      color: $color-success;
      flex-shrink: 0;
    }
    p {
      margin-left: 10px;
    }
  }

  .Form {
    &-item {
      .selected-items {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .btn {
          width: auto;
          height: 34px;
          margin-right: 10px;
          margin-top: 10px;
        }
      }
    }
  }

  :deep() .OverGroups{
    flex-wrap: unset !important;
  }
  .inventory-closing-method {
    font-size: 14px;
  }
</style>
