<template lang="pug">
form.Auth-form.ForgotPassword-form.Form(
  @submit.stop.prevent="onSubmit")

    h1.Auth-form-title {{ $t('ResetPassword.formHeaderTitle') }}

    .Form-item
      p.description {{ $t('ResetPassword.formDescription') }}

    .Form-item.password(:class="{ 'is-show': isShowPassword }")
      label.Form-item-label {{ $t('ResetPassword.newPassword') }}
      .control
        input.txt.txt-right-icon(
        id="input-reset-password-password"
        name="password",
        :placeholder="$t('ResetPassword.newPasswordPlaceholder')"
        :data-vv-as="$t('ResetPassword.newPassword')",
        v-model="form.newPassword",
        v-validate="'required|min:6'",
        autocomplete="off"
        :class="{ 'is-danger': veeErrors.has('password') }",
        :type="isPasswordType")
        Button(
        id="btn-reset-password-show-password"
        type="button",
        variant="icon switch"
        :iconName="isShowPassword ? 'icon-login-eye' : 'icon-login-eye-closed'"
        :iconClass="isShowPassword ? 'icon-login-eye' : 'icon-login-eye-closed'"
        @click="showPassword")
      p.Form-item-help.is-danger(
      v-show="veeErrors.has('password')")
        | {{ veeErrors.first('password') }}

      .Form-item.x2
        Button(
        size="medium"
        :class="isFormReady && !isLoading ? 'btn-success' : 'btn-gray'",
        type="submit",
        id="btn-reset-password-button"
        :disabled="!isFormReady || isLoading")
          span(v-show="isFormReady || !isLoading") {{ $t('ForgotPassword.formItemSubmit') }}
          Loading(theme="disable", v-show="isLoading")
</template>

<script>
import { mapFields } from 'vee-validate'
import { mapActions } from 'vuex'

export default {
  name: 'ResetPassword',
  data () {
    return {
      form: {
        emailAddress: '',
        resetToken: '',
        newPassword: ''
      },
      typePassword: true
    }
  },

  computed: {

    ...mapFields({
      flagsPassword: 'password'
    }),

    isPasswordType () {
      return this.typePassword ? 'text' : 'password'
    },

    isShowPassword () {
      return this.typePassword
    },

    isLoading () {
      return this.$wait.is(['ResetPassword'])
    },

    isFormReady () {
      return this.flagsPassword.valid
    }
  },

  created () {
    if (!this.$route.query.token) {
      this.$router.push({
        name: 'Login'
      })
    }
    this.form.resetToken = this.$route.query.token
    // Replacing spaces with plus sign, because url decoding plus sign with 20% which equivalent of space. And emails does not contain space
    this.form.emailAddress = this.$route.query.email.replace(/ /g, '+')
  },

  methods: {

    ...mapActions('Auth', [
      'ResetPassword'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    showPassword () {
      this.typePassword = !this.typePassword
    },

    async onSubmit () {
      const message = this.$t('ResetPassword.successMessage')
      const res = await this.ResetPassword(this.form)
      if (res) {
        this.notifyShow({ message })
        this.$router.push({
          name: 'Login'
        })
      }
    }
  }
}
</script>
