var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard" }, [
    _vm.selectedType === "incomeStats"
      ? _c(
          "div",
          { staticClass: "reports" },
          _vm._l(_vm.reportBoxList, function (report, index) {
            return _c(
              "div",
              { staticClass: "col col-ld col-sd col-tb col-mb" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      id: `link-reports-summary-${report.routerName}`,
                      to: { name: report.routerName },
                    },
                  },
                  [
                    _c("ReportBox", {
                      attrs: {
                        infoText: report.infoText,
                        percent: report.percent,
                        value: report.value,
                        summary: report.summary,
                        revertStatus: report.revertStatus,
                        rowId: index,
                        periodType: _vm.periodType,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm.selectedType !== "incomeStats"
      ? _c("div", { staticClass: "title m-bottom-10" }, [
          _c("div", { staticClass: "title-col" }, [
            _vm._v(_vm._s(this.$t("Dashboard.forecastSales"))),
          ]),
          _c("div", { staticClass: "title-col" }, [
            _vm._v(_vm._s(this.$t("Reports.realSales"))),
          ]),
        ])
      : _vm._e(),
    _vm.selectedType !== "incomeStats"
      ? _c(
          "div",
          { staticClass: "forecast" },
          _vm._l(_vm.forecastBoxList, function (forecastReport, index) {
            return _c(
              "div",
              { staticClass: "col col-ld col-sd col-tb col-mb" },
              [
                _c("ReportBox", {
                  attrs: {
                    infoText: forecastReport.infoText,
                    percent: forecastReport.percent,
                    value: forecastReport.value,
                    useSecondInfoText: forecastReport.useSecondInfoText,
                    secondInfoText: forecastReport.secondInfoText,
                    summary: null,
                    secondValue: forecastReport.secondValue,
                    rowId: index,
                    date: forecastReport.date,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.goToForecastReports(forecastReport)
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }