var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.requestHeaderLinks,
              translationGroup: "Request",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "approvedOrder",
              suggestionValue: "name",
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.selectedStockItem,
              customSuggestionPlaceHolder: _vm.$t(
                "Orders.orders_search_Placeholder"
              ),
              customSuggestionTitle: _vm.$t(
                "Orders.orders_search_SuggestionTitle"
              ),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.setStockItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _vm.hasTabPermission
          ? _c("div", { staticClass: "right" }, [
              _c(
                "div",
                {
                  staticClass: "form-item-select date-start",
                  attrs: { id: "date-start-request-order-list" },
                },
                [
                  _c("CustomDatepicker", {
                    attrs: {
                      isLoading: _vm.$wait.is("getDateFilterSettings"),
                      id: "custom-datepicker-request-order-list",
                      range: true,
                    },
                    model: {
                      value: _vm.dates,
                      callback: function ($$v) {
                        _vm.dates = $$v
                      },
                      expression: "dates",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeFilter", {
            ref: "approvedOrderStoreFilter",
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c("supplierFilter", {
            ref: "approvedOrderSupplierFilter",
            on: { submitFilter: _vm.getListWithSupplierFilter },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c("CustomCheckbox", {
            staticClass: "m-left-15",
            attrs: {
              id: "checkbox-approver-order-show-integration-messages",
              checked: _vm.showIntegrationMessages,
              label: _vm.$t("Orders.showIntegrationMessages"),
            },
            on: { change: _vm.changeShowIntegrationMessagesStatus },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _vm.hasTabPermission
            ? _c("ImportExport", {
                ref: "wrapper",
                attrs: {
                  componentName: "approvedOrder",
                  isDisabled: _vm.isFetchingPageData,
                  activeImportExports: ["export"],
                  downloadUrl: "Order/orderdetail/export",
                  downloadFileType: "xlsx",
                  downloadParams: _vm.downloadParams,
                  downloadFileName: _vm.downloadFileName,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission ? _c("NoPermission") : _vm._e(),
          _vm.isFetchingPageData || _vm.isFetchingOrderList
            ? _c("TableLoading", { staticClass: "sticky" })
            : _vm._e(),
          _vm.isEmptyApproveOrderList &&
          !_vm.isFetchingPageData &&
          !_vm.isFetchingOrderList
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Orders.orders_emptyState_NotFound_Title"),
                  description: _vm.$t(
                    "Orders.orders_emptyState_NotFound_Description"
                  ),
                },
              })
            : _vm._e(),
          _vm.isEmptyApproveOrderList ? _c("span") : _vm._e(),
          !_vm.isEmptyApproveOrderList
            ? _c("NestedTable", {
                ref: "ordersNestedTable",
                attrs: {
                  "status-icon": false,
                  fields: _vm.ordersHeaderFields,
                  detailFields: _vm.ordersDetailFields,
                  data: _vm.approveOrders,
                  addButtonText: _vm.$t("Global.addNewStock"),
                  actions: _vm.headerActions,
                  detailActions: _vm.detailActions,
                  canAddNewDetail: _vm.hasOrderEdit,
                  isFinalizeAction: false,
                  page: _vm.Page,
                  isDetailLoading: _vm.$wait.is("getOrderDetail"),
                },
                on: {
                  changeRowData: _vm.changeOrderType,
                  addDetailAction: _vm.addNewDetail,
                  editHeaderAction: _vm.editAction,
                  editDetailAction: _vm.editDetailAction,
                  showExportPopup: _vm.showExportPopup,
                  showDetailAction: _vm.showOrderDetail,
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "single-action-portal-start" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (header) {
                    return header.row.totalUnitQuantityList.length > 0
                      ? _c("TotalPreview", {
                          attrs: {
                            rowIndex: header.rowIndex,
                            list: header.row.totalUnitQuantityList,
                          },
                        })
                      : _vm._e()
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c(
            "portal",
            { attrs: { to: "checkboxAll" } },
            [
              _c("custom-checkbox", {
                attrs: {
                  label: "",
                  checked: _vm.isSelectedAll,
                  disabled: !_vm.selectedSupplierId,
                },
                on: { change: _vm.toggleAll },
              }),
            ],
            1
          ),
          _c("portal", {
            attrs: { to: "orderDate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (header) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("CustomDatepicker", {
                        class: {
                          "is-danger": _vm.veeErrors.has(
                            `date-${header.rowIndex}`
                          ),
                        },
                        attrs: {
                          name: `date-${header.rowIndex}`,
                          id: `date-input-table-row-${header.rowIndex}`,
                          value: header.row.orderDate,
                          disabledStartDate: _vm.disableDate,
                          pickerType: "manuel",
                          inputIconRight: "",
                          disabled: header.row.status === 2,
                          inputIcon: "",
                        },
                        on: { change: (e) => _vm.changeOrderDate(header, e) },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "resendButton" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (header) {
                  return _c(
                    "div",
                    {},
                    [
                      header.row.accountingStatus === 3 ||
                      header.row.wmsStatus === 3
                        ? _c("Button", {
                            staticClass: "icon-global-warning",
                            attrs: {
                              id: `orders-header-resend-button-${header.rowIndex}`,
                              iconName: "icon-global-warning",
                              justIcon: true,
                              clearVariant: true,
                              size: "medium",
                              isLoading: _vm.resendLoading,
                              disabled: _vm.resendLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleErrorPopup(header.row)
                              },
                            },
                          })
                        : _vm._e(),
                      header.row.showAccountingStatus
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "shortkey",
                                  rawName: "v-shortkey",
                                  value: ["esc"],
                                  expression: "['esc']",
                                },
                                {
                                  name: "click-outside",
                                  rawName: "v-click-outside",
                                  value: () => _vm.closeErrorPopup(header.row),
                                  expression:
                                    "() => closeErrorPopup(header.row)",
                                },
                              ],
                              staticClass: "MiniPopup right",
                            },
                            [
                              _c("div", { staticClass: "resendButton-Popup" }, [
                                header.row.accountingStatus === 3
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "resendButton-Popup-content",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(header.row.accountingMessage)
                                          ),
                                        ]),
                                        _c(
                                          "Button",
                                          {
                                            staticClass: "m-left-10",
                                            attrs: {
                                              id: "approved-orders-header-resend-data",
                                              iconName: "icon-global-refresh",
                                              iconClass: "icon-global-refresh",
                                              secondary: "",
                                              size: "small",
                                              disabled: _vm.resendLoading,
                                              isLoading: _vm.resendLoading,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.requestOrderResend(
                                                  header.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "Orders.Resend_Button_Text"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                header.row.wmsStatus === 3
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "resendButton-Popup-content m-top-10",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(header.row.wmsMessage)),
                                        ]),
                                        _c(
                                          "Button",
                                          {
                                            staticClass: "m-left-10",
                                            attrs: {
                                              id: "approved-orders-header-resend-data-wms",
                                              iconName: "icon-global-refresh",
                                              iconClass: "icon-global-refresh",
                                              secondary: "",
                                              size: "small",
                                              disabled: _vm.isLoadingWMSReset,
                                              isLoading: _vm.isLoadingWMSReset,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resendOrderForWMS(
                                                  header.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "Orders.Resend_Button_Text"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "description" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (header) {
                  return _c(
                    "div",
                    {},
                    [
                      header.row.description
                        ? _c(
                            "Button",
                            {
                              staticClass:
                                "show-MiniPopup relative width-max-content btn-info",
                              attrs: {
                                id: `orders-header-info-button-${header.rowIndex}`,
                                iconName: "icon-global-info",
                                clearVariant: true,
                                justIcon: true,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "MiniPopup-right-primary m-top-15 c-dark",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(header.row.description)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "detailDescription" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    {},
                    [
                      detail.row.description
                        ? _c(
                            "Button",
                            {
                              staticClass:
                                "show-MiniPopup relative width-max-content btn-info",
                              attrs: {
                                iconName: "icon-global-info",
                                justIcon: true,
                                id: `orders-detail-info-button-${detail.rowIndex}`,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "MiniPopup-leftCenter-primary m-top-15 c-dark",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(detail.row.description)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("router-view", {
            on: {
              getOrdersHeaderAll: _vm.getOrdersHeaderAll,
              showOrderWithDetail: _vm.showOrderWithDetail,
            },
          }),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }