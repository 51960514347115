<template lang="pug">
Page
  template(slot="header-right-content" v-if="hasTabPermission")
      Button(
        size="medium",
        primary 
        id="btn-other-expense-new"
        iconName="icon-global-add"
        iconClass="icon-global-add"
        @click="itemNew"
      )
        span {{ $t('OtherExpenses.addExpense') }}

  template(slot="header-left-search")
    Search.search-box(
      componentName="OtherExpenses"
      @onChangeSearchValue="onChangeSearch"
    )
  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    div(v-else)
      TableLoading(v-if="isLoading")

      EmptyState(
      v-if="isOtherExpenseEmpty && !isLoading",
      :title="$t('OtherExpenses.emptyState_Title')",
      :description="$t('OtherExpenses.emptyState_Description')",
      :buttonText="$t('OtherExpenses.emptyState_ButtonText')",
      @emptyAddButton="itemNew")

      BoxView(
        v-if="!isOtherExpenseEmpty && !isLoading"
        :fields="fields"
        :actions="['edit', 'remove']"
        :data="OtherExpenseList"
        :page="Page"
        :componentName="'OtherExpensesBox'"
        @delegateOnItemEdit="editExpense"
        @delegateOnItemRemove="removeExpense"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
      )

      portal(to="box-header-info")
        div(slot-scope="row")
          Button.show-MiniPopup.relative.width-max-content.m-left-15(
            v-if="row.description",
            :id="`other-expense-info-button-${row.rowIndex}`"
            iconName="icon-global-info",
            :justIcon="true",
            class="btn-info"
          )
            .MiniPopup-left-primary.m-top-15.c-dark
              span {{ row.description }}

    router-view(@getList="getOtherExpenses")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import getFields from './fields/other-expenses'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'

export default {
  name: 'OtherExpenses',
  mixins: [hasTabPermission],

  components: {
    NoPermission
  },

  data () {
    return {
      fields: getFields(this.$t),
      text: null
    }
  },

  computed: {
    ...mapGetters('OtherExpenses', [
      'OtherExpenseList',
      'Page'
    ]),

    isOtherExpenseEmpty () {
      return this.OtherExpenseList.length === 0
    },

    isLoading () {
      return this.$wait.is(['getOtherExpenseList'])
    }
  },

  created () {
    this.getOtherExpenses()
  },

  methods: {
    ...mapActions('OtherExpenses', [
      'getOtherExpenseList'
    ]),

    onChangeSearch (search) {
      if (search.length === 1) return
      this.text = search
      this.getOtherExpenses()
    },

    changePage (page) {
      this.getOtherExpenses(page)
    },
    changePageSize (pageSize) {
      this.getOtherExpenses(1, pageSize)
    },

    itemNew () {
      this.$router.push({
        name: 'OtherExpenseNew'
      })
    },

    editExpense (item) {
      this.$router.push({
        name: 'OtherExpenseEdit',
        params: {
          id: item.id
        }
      })
    },

    removeExpense (item) {
      this.$router.push({
        name: 'OtherExpenseRemove',
        params: {
          id: item.id
        }
      })
    },

    getOtherExpenses (page, pageSize = this.Page.size) {
      const payload = {
        pageSize: pageSize,
        pageNumber: page || 1,
        name: this.text
      }
      this.getOtherExpenseList(payload)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
