var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search",
            attrs: {
              componentName: "Despatch",
              isSuggestionLoading: false,
              customSuggestionPlaceHolder: _vm.$t(
                "Despatches.invoiceSearch_Placeholder"
              ),
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select right-date" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "InvoiceDatepicker",
                isLoading: _vm.$wait.is("getDateFilterSettings"),
                disabled: _vm.isLoading || _vm.$wait.is("pageLoading"),
                range: true,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("SupplierFilter", {
            ref: "despatchInvoiceSupplierFilter",
            on: { submitFilter: _vm.getListWithSupplierFilter },
          }),
          _c("businessMultiselect", {
            ref: "businessDespatchInvoiceFilter",
            attrs: {
              componentName: "despatch-accounting-filter",
              label: _vm.$t("Despatches.toolbar_Accounting_Label"),
              popupHeader: _vm.$t("Despatches.toolbar_Accounting_PopupHeader"),
              multiselectItems: _vm.accountingList,
              uniqKey: "value",
            },
            on: { submitFilter: _vm.getFilteredInvoice },
          }),
          _c("businessMultiselect", {
            ref: "businessDespatchInvoiceTypeFilter",
            attrs: {
              componentName: "despatch-invoice-type-filter",
              label: _vm.$t("Despatches.toolbar_Invoice_Type_Label"),
              popupHeader: _vm.$t("Despatches.toolbar_Invoice_Type_Label"),
              multiselectItems: _vm.invoiceTypes,
              uniqKey: "value",
            },
            on: { submitFilter: _vm.getInvoiceType },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c(
            "Button",
            {
              staticClass: "send-selected",
              attrs: {
                id: "btn-invoice-selected-send-to-accounting",
                type: "button",
                primary: "",
                size: "small",
                iconName: "icon-add-circle",
                iconClass: "icon-add-circle",
                disabled: _vm.isLoadingSendSelected || _vm.isSelectedEmpty,
              },
              on: { click: _vm.sendSelected },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Despatches.send_Selected_Invoices_To_Accounting")
                  )
                ),
              ]),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoadingSendSelected,
                    expression: "isLoadingSendSelected",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading || _vm.$wait.is("pageLoading")
            ? _c("TableLoading")
            : _vm._e(),
          _vm.invoice_IsEmpty && !_vm.isLoading && !_vm.$wait.is("pageLoading")
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Despatches.emptyInvoiceState_Title"),
                  description: _vm.$t(
                    "Despatches.emptyInvoiceState_Description"
                  ),
                },
              })
            : _vm._e(),
          !_vm.invoice_IsEmpty && !_vm.isLoading
            ? _c("invoiceTable", {
                attrs: {
                  tableData: _vm.invoice_List,
                  showDetailAction: _vm.invoiceDetail,
                  selectedList: _vm.selectedList,
                  accountingList: _vm.accountingList,
                  showExportPopup: _vm.showExportPopup,
                },
                on: {
                  delegateCheckItem: _vm.handleCheckbox,
                  delegateCheckAllItem: _vm.toggleAll,
                  getList: _vm.getInvoiceListWithParams,
                },
              })
            : _vm._e(),
          _c("router-view"),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("TableFoot", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.invoice_IsEmpty && !_vm.isLoading,
                expression: "!invoice_IsEmpty && !isLoading",
              },
            ],
            attrs: { page: _vm.InvoicePage, numberData: [20, 50] },
            on: {
              delegateOnChangePage: _vm.changePage,
              delegateOnChangePageSize: _vm.changePageSize,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }