var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isEmptySuspendedSalesDetailList && !_vm.isLoading
        ? _c("EmptyState", {
            attrs: {
              title: _vm.$t("MenuItems.SuspendSalesNoyFoundTitle"),
              description: _vm.$t("MenuItems.SuspendSalesNoyFoundDescription"),
            },
          })
        : _vm._e(),
      !_vm.isEmptySuspendedSalesDetailList && !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "header-title",
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "SuspendSalesHeaders" })
                },
              },
            },
            [
              _c("Icon", { attrs: { name: "header-back-icon" } }),
              _c("p", { staticClass: "m-left-10" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      `MenuItems.suspendErrorType${_vm.$route.params.errorType}`
                    )
                  )
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.isEmptySuspendedSalesDetailList && !_vm.isLoading
        ? _c("TableView", {
            attrs: {
              fields: _vm.fields,
              componentName: "SuspendSalesTable",
              data: _vm.suspendedSalesDetailList,
              readOnly: true,
              page: _vm.Page,
              selectable: "checkbox",
              selectableAll: "checkbox",
              selectedRows: _vm.selectedList,
            },
            on: {
              delegateOnChangePage: _vm.changePage,
              delegateOnChangePageSize: _vm.changePageSize,
              delegateOnItemSelect: _vm.onSelect,
              showAllSelectedItems: _vm.selectAll,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }