<template lang="pug">
Page.orderIntegrationStatus
  template(slot="header-right-content")
    Button(
    size="medium"
    primary
    id="btn-order-integration-status-new"
    type="button",
    v-show="!isEmptyList"
    @click="addItem")
      Icon(name="icon-global-add")
      span {{ $t('OrderIntegrationStatus.emptyStateNewButton') }}

  template(slot="content-center")
    .Boxlist(v-if="!isEmptyList && !isFetchingList")
      .Boxlist-item(
        v-for="status in OrderIntegrationStatusList"
      )
        .Boxlist-actions
          ActionButton(
            :data="['edit', 'remove']"
            :item="status"
            @onItemEdit="editItem(status)"
            @onItemDelete="removeItem(status)"
          )
        .Boxlist-body
          h3.title.tooltip-relative.max-content {{ status.name }}
            Tooltip.table-mini-popup(
              v-if="status.isClosureStatus"
              white,
              :bottomLeft="true",
              :text="$t('OrderIntegrationStatus.Popup_Status_Integration_Type_Close')"
            )
    TableFoot(
      :page="Page"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
    )

    TableLoading(v-if="isFetchingList")
    EmptyState(
      :title="$t('OrderIntegrationStatus.emptyStateTitle')",
      :description="$t('OrderIntegrationStatus.emptyStateDescription')",
      :buttonText="$t('OrderIntegrationStatus.emptyStateNewButton')",
      v-if="isEmptyList && !isFetchingList"
      @emptyAddButton="addItem")

    router-view(@getList="getList")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TableFoot from '@/view/global/table/foot.vue'

export default {
  name: 'OrderIntegrationStatusIndex',
  components: {
    TableFoot
  },

  beforeMount () {
    this.getList()
  },
  computed: {
    ...mapGetters('OrderIntegrationStatus', [
      'OrderIntegrationStatusList',
      'Page'

    ]),
    isEmptyList () {
      return this.OrderIntegrationStatusList.length === 0
    },
    isFetchingList () {
      return this.$wait.is('getOrderIntegrationStatusList')
    }
  },
  methods: {
    ...mapActions('OrderIntegrationStatus', [
      'getOrderIntegrationStatusList'
    ]),
    addItem () {
      this.$router.push({
        name: 'OrderIntegrationStatusForm',
        params: {
          status: 'new',
          id: 0
        }
      })
    },
    editItem (status) {
      this.$router.push({
        name: 'OrderIntegrationStatusForm',
        params: {
          status: 'edit',
          id: status.id
        }
      })
    },

    changePage (page) {
      this.getList(page)
    },

    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    getList (page = 1, pageSize = this.Page.size) {
      const payload = {
        pageNumber: page,
        pageSize
      }
      return this.getOrderIntegrationStatusList(payload)
    },

    removeItem (status) {
      this.$router.push({
        name: 'DeleteOrderIntegrationStatus',
        params: {
          status: 'detele',
          id: status.id,
          item: status
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
  :deep() .appTooltip {
    font-size: 12px !important;
  }

  :deep() .Boxlist {
    &-body {
      width: 85% !important;
    }
  }
</style>
