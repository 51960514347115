<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-left-search")
    Search.search-box(
      componentName="targetStore",
      :isInputDisable="isTargetStoresEmpty"
      :isSuggestionLoading="isSuggestionLoading"
      :suggestions="menuItems"
      :customSuggestionPlaceHolder="$t('DirectOrders.searchPlaceholder')"
      :customSuggestionTitle="$t('DirectOrders.orders_search_SuggestionTitle')"
      @onChangeSearchValue="onChangeSearch"
      @onChangeQueryItem="getTargetStoreByMenuItem"
      @clearSearch="resetTargetStore"
    )

  template(slot="header-right-content")
    Button(
      v-if="!isTargetStoresEmpty"
      primary,
      size="medium"
      variant ="full"
      id="btn-target-stores-add"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="editTargetStore()"
      :disabled="selectedTargetStoreItems.length === 0"
    )
      span {{ $t('MenuItemsSettings.target_store_save') }}

  template(slot="toolbar-left")
    .toolbar-selection
      span.dropdown-label {{ $t('MenuItemsSettings.store_group_list') }}
      customSelect(
        v-model="selectedStoreGroup"
        :optionData="storeGroupList"
        optionTitle="name"
        selectedEmitValue="id"
        componentName="matched-stock-store-group-select"
        :defaultTitle="$t('Global.Multiselect_Unselected_Text')"
        :isSearchActive="true"
        @inputSelected="filterRvcItems"
      )
    .toolbar-selection.m-left-10
      span.dropdown-label {{ $t('MenuItemsSettings.rvc_list') }}
      customSelect(
        v-model="selectedRvcItem"
        :optionData="baseRvcGroupList"
        optionTitle="name"
        selectedEmitValue="id"
        componentName="matched-stock-rvc-select"
        :defaultTitle="$t('Global.Multiselect_Unselected_Text')"
        :isSearchActive="true"
        :isDisabled="!selectedStoreGroup"
      )
      showValidateError.toolbar-validation(
        v-show="isRvcListEmpty"
        :errorName="$t('MenuItemsSettings.rvc_list_empty')"
      )
    .toolbar-selection.m-left-10
      span.dropdown-label {{ $t('MenuItemsSettings.store_list') }}
      customSelect(
        v-model="selectedStoreItem"
        :optionData="storeListItems"
        optionTitle="name"
        selectedEmitValue="id"
        componentName="matched-stock-store-select"
        :defaultTitle="$t('Global.Multiselect_Unselected_Text')"
        :isSearchActive="true"
        :isDisabled="!selectedStoreGroup"
      )
      showValidateError.toolbar-validation(
        v-show="isStoreListEmpty"
        :errorName="$t('MenuItemsSettings.store_list_empty')"
      )
    .toolbar-selection.m-left-10
      span.dropdown-label {{ $t('MenuItemsSettings.menu_group_items') }}
      businessMultiselect.menu-group-selection(
        componentName="multiselect-store-filter"
        :popupHeader="$t('MenuItemsSettings.menu_group_items_selection')"
        :multiselectItems="menuItemGroupList"
        ref="businessMenuGroupItems"
        :isDisabled="!selectedStoreGroup"
        :dontDisableButton="true"
        @submitFilter="submitFilter"
      )

  template(slot="toolbar-right")
    Button(
      primary,
      size="small",
      id="btn-target-stores"
      :disabled="!validateGettingTargetStore || isFilterDataLoading"
      :isLoading="isFilterDataLoading"
      iconName="icon-btn-success"
      iconClass="icon-btn-success"
      @click="getTargetStores()"
    )
      span {{ $t('MenuItemsSettings.get_target_stores') }}

  template(slot="content-center")
    .toolbar.margin-zero
      CustomCheckbox.popup-item-check(
        id="checkbox-despatch-show-drafts"
        :checked="showNoGroups"
        :label="$t('MenuItemsSettings.showNoGroups')"
        @change="changeShowNoGroupStatus")
    TableLoading(v-if="isLoadingPage")
    .TargetStorePageContent(v-else)


      TableLoading(v-if="isLoadingPageData")

      EmptyState(
        v-if="!isLoadingPageData && isTargetStoresEmpty"
        :title="$t('MenuItemsSettings.emptyState_NotFound_Title')"
        :description="$t('MenuItemsSettings.emptyState_NotFound_Description')")

      TableView(
        v-if="!isLoadingPageData && !isTargetStoresEmpty"
        :fields="targetStoreFields"
        componentName="TargetStore"
        :data="TargetStoreItems.list"
        :page="pageTargetStores"
        :readOnly="true"
        :selectable="'checkbox'"
        :selectableAll="'checkbox'"
        :selectedRows="selectedTargetStoreItems"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemSelect="onSelectTargetStore"
        @showAllSelectedItems="onSelectTargetStoreAll")

    router-view(
      :selectedData="selectedTargetStoreItems"
      :selectedStore="selectedStoreItem"
      @getList="getTargetStores()"
    )

</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import { targetStoreFields } from './fields/targetStores'
import customSelect from '@/view/global/custom-select'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {
  name: 'stock',
  data () {
    return {
      storeListItems: [],
      menuItemGroupList: [],
      menuItemGroupSelection: [],
      selectedStoreGroup: null,
      selectedRvcItem: null,
      selectedStoreItem: null,
      selectedTargetStoreItems: [],
      search: '',
      menuItems: [],
      menuItemId: null,
      targetStoreFields: targetStoreFields(this.$t),
      isValActive: false,
      showNoGroups: true
    }
  },

  components: {
    customSelect,
    businessMultiselect,
  },

  computed: {
    ...mapGetters('MenuItemsSettings', [
      'pageHeaderLinks'
    ]),

    ...mapGetters('MenuItems', {
      rvcList: 'RVCList',
      filteredSearchItems: 'FilteredSearchItems'
    }),

    ...mapGetters('StoreGroups', [
      'storeGroupList',
      'baseRvcGroupList'
    ]),

    ...mapGetters('Stores', [
      'storeList'
    ]),

    ...mapGetters('MenuItems', [
      'TargetStoreItems',
      'pageTargetStores',
      'isTargetStoresEmpty'
    ]),

    isLoadingPage () {
      return this.$wait.is(['pageLoading'])
    },

    isLoadingPageData () {
      return this.$wait.is(['getTargetStore'])
    },

    isRvcListEmpty () {
      return this.selectedStoreGroup && this.isValActive ? this.baseRvcGroupList.length === 0 : false
    },

    isStoreListEmpty () {
      return this.selectedStoreGroup ? this.storeListItems.length === 0 : false
    },

    validateGettingTargetStore () {
      return this.selectedStoreGroup && this.selectedRvcItem && this.selectedStoreItem
    },

    isSuggestionLoading () {
      return this.$wait.is(['MenuItems/getFilteredTargetStore'])
    },

    isFilterDataLoading () {
      return this.$wait.is(['getStoreRvcGroupList', 'posMenuItemGroups'])
    }
  },

  methods: {
    ...mapActions('StoreGroups', [
      'getStoreGroupList',
      'getStoreRvcGroupList'
    ]),

    ...mapActions('MenuItems', [
      'getRVCList',
      'getFilteredTargetStore'
    ]),

    ...mapActions('Stores', [
      'getStoreList'
    ]),

    ...mapActions('MenuItems', [
      'posMenuItemGroups',
      'getTargetStore'
    ]),

    ...mapMutations('MenuItems', ['RESET']),

    editTargetStore () {
      this.$router.push({
        name: 'TargetStoreEdit'
      })
    },

    async getStoreListItems () {
      await this.getStoreList({pageNumber: 1, pageSize: 9999})
    },

    async changeShowNoGroupStatus () {
      this.showNoGroups = !this.showNoGroups
      await this.getTargetStores()
    },

    filterItemsByStoreGroup () {
      this.storeListItems = this.storeList.filter(item => item.type === 1)
    },

    async getRvcWithStoreGroup () {
      await this.getStoreRvcGroupList({ storeGroupIds: [this.selectedStoreGroup] })
      this.isValActive = true
    },

    onChangeSearch (search) {
      this.search = search
      const payload = {
        menuItemGroupIdList: this.menuItemGroupSelection.map(i => i.id),
        revenueCenterId: this.selectedRvcItem,
        storeId: this.selectedStoreItem,
        text: this.search
      }
      if (!this.search) {
        this.resetTargetStore()
      }
      if (this.search.length >= 2) {
        this.getFilteredTargetStore(payload)
          .then(res => {
            this.menuItems = res.data.menuItemList
          })
      }
    },

    async filterRvcItems (data) {
      this.selectedRvcItem = null
      this.selectedStoreItem = null
      if (data === null) return
      this.filterItemsByStoreGroup(data)
      await this.getRvcWithStoreGroup()
      await this.getPostMenuItems()
    },

    async getPostMenuItems () {
      const payload = {
        'storeGroupIds': [this.selectedStoreGroup]
      }

      await this.posMenuItemGroups(payload)
        .then(res => {
          this.menuItemGroupList = res.data.menuItemGroupList
          this.$refs.businessMenuGroupItems.clearAllFilter()
        })
    },

    submitFilter ({dontSubmit, list}) {
      this.menuItemGroupSelection = list
    },

    changePage (page) {
      this.getTargetStores(page)
    },

    changePageSize (pageSize) {
      this.getTargetStores(1, pageSize)
    },

    async getSearchResult () {
      const payload = {
        menuItemGroupIdList: this.menuItemGroupSelection.map(i => i.id),
        revenueCenterId: this.selectedRvcItem,
        storeId: this.selectedStoreItem,
        text: this.search
      }
      await this.getFilteredTargetStore(payload).then(res => {
        this.menuItems = res.data.menuItemList
      })
    },

    getTargetStoreByMenuItem (item) {
      this.menuItemId = item.id
      this.search = item.name
      this.menuItems = []
      this.getTargetStores()
    },

    resetTargetStore () {
      this.search = ''
      this.menuItems = []
      if (!this.menuItemId) return false
      this.menuItemId = null
      this.getTargetStores()
    },



    async getTargetStores (pageNumber = 1, pageSize = this.pageTargetStores.size) {
      if (!this.validateGettingTargetStore) return
      this.selectedTargetStoreItems = [] // reset selected checkbox items with page changing or new data request etc.

      const payload = {
        menuItemGroupIdList: this.menuItemGroupSelection.map(i => i.id),
        revenueCenterId: this.selectedRvcItem,
        storeId: this.selectedStoreItem,
        pageNumber,
        pageSize,
        menuItemId: this.menuItemId,
        showCondiments: this.showNoGroups
      }

      await this.getTargetStore(payload)
    },

    onSelectTargetStore (item) {
      const index = this.selectedTargetStoreItems.indexOf(item)
      if (index > -1) {
        this.selectedTargetStoreItems.splice(index, 1)
      } else {
        this.selectedTargetStoreItems.push(item)
      }
    },

    onSelectTargetStoreAll (isChecked) {
      this.selectedTargetStoreItems = []
      if (isChecked) this.selectedTargetStoreItems.push(...this.TargetStoreItems.list)
    }
  },

  async mounted () {
    this.RESET()
    this.$wait.start('pageLoading')
    await this.getStoreGroupList({pageNumber: 1, pageSize: 9999})
    await this.getStoreListItems()
    this.$wait.end('pageLoading')
  }
}
</script>

<style scoped lang="scss">
  .margin-zero {
    margin: unset;
    margin-bottom: 10px;
  }
  :deep() .dropdown-handle-button {
    height: 44px !important;
  }
  :deep() .dropdown-handle-button-values {
    line-height: 42px!important;
  }
  .show-no-groups {
    margin-top: 32px
  }
  :deep() .select-dropdown-custom-selected {
    width: 150px;
  }
  :deep() .select-dropdown-custom-options {
    width: 150px;
  }
  :deep() .dropdown-handle {
    height: 44px;
  }
  .menu-group-selection {
    width: 150px;
  }
</style>
