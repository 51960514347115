<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "requestHeaderLinks"
      translationGroup="Request"
    )
  template(slot="header-left-search")
    Search.search-box(
        componentName="approvedOrder",
        suggestionValue="name"
        :isSuggestionLoading="isSuggestionLoading"
        :suggestions="selectedStockItem"
        :customSuggestionPlaceHolder="$t('Orders.orders_search_Placeholder')"
        :customSuggestionTitle="$t('Orders.orders_search_SuggestionTitle')"
        @onChangeSearchValue="onChangeSearch"
        @onChangeQueryItem="setStockItem"
        @clearSearch="clearSearch"
      )
  template(slot="header-right-dropdown")
    .right(v-if="hasTabPermission")
      .form-item-select.date-start(
        id="date-start-request-order-list"
      )
        CustomDatepicker(
          v-model="dates",
          :isLoading="$wait.is('getDateFilterSettings')"
          id="custom-datepicker-request-order-list"
          :range="true")
  template(slot="toolbar-left")
    storeFilter(
      @submitFilter="getListWithStoreFilter"
      ref="approvedOrderStoreFilter")

    supplierFilter(
      @submitFilter="getListWithSupplierFilter"
      ref="approvedOrderSupplierFilter")

    Button.clear-filter-button.border-gray-1.p-0.width-auto(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")
    
    CustomCheckbox.m-left-15(
      id="checkbox-approver-order-show-integration-messages"
      :checked="showIntegrationMessages"
      :label="$t('Orders.showIntegrationMessages')"
      @change="changeShowIntegrationMessagesStatus")

  template(slot="toolbar-right")
    ImportExport(
      v-if="hasTabPermission"
      componentName="approvedOrder"
      ref="wrapper"
      :isDisabled="isFetchingPageData"
      :activeImportExports=['export']
      downloadUrl="Order/orderdetail/export"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="downloadFileName"
    )
  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
      // TOOLBAR

    TableLoading.sticky(v-if="isFetchingPageData || isFetchingOrderList")
    EmptyState(
    v-if="isEmptyApproveOrderList && !isFetchingPageData && !isFetchingOrderList"
    :title="$t('Orders.orders_emptyState_NotFound_Title')"
    :description="$t('Orders.orders_emptyState_NotFound_Description')")

    span(v-if="isEmptyApproveOrderList")

    NestedTable(
      v-if="!isEmptyApproveOrderList",
      :status-icon="false",
      ref="ordersNestedTable"
      :fields="ordersHeaderFields",
      :detailFields="ordersDetailFields",
      :data="approveOrders",
      :addButtonText="$t('Global.addNewStock')",
      :actions="headerActions",
      :detailActions="detailActions",
      @changeRowData="changeOrderType",
      @addDetailAction="addNewDetail",
      :canAddNewDetail="hasOrderEdit"
      @editHeaderAction="editAction",
      @editDetailAction="editDetailAction",
      :isFinalizeAction="false",
      :page="Page"
      :isDetailLoading="$wait.is('getOrderDetail')"
      @showExportPopup="showExportPopup"
      @showDetailAction="showOrderDetail"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize")

    portal(to="single-action-portal-start")
      TotalPreview(
        slot-scope="header"
        v-if="header.row.totalUnitQuantityList.length > 0"
        :rowIndex="header.rowIndex"
        :list="header.row.totalUnitQuantityList"
      )

    portal(to="checkboxAll")
      custom-checkbox(
        label=""
        :checked="isSelectedAll"
        @change="toggleAll"
        :disabled="!selectedSupplierId"
      )

    portal(to="orderDate")
      div(slot-scope="header")
        CustomDatepicker(
          :name="`date-${header.rowIndex}`"
          :id="`date-input-table-row-${header.rowIndex}`"
          :value="header.row.orderDate"
          :class="{ 'is-danger': veeErrors.has(`date-${header.rowIndex}`) }"
          :disabledStartDate="disableDate"
          pickerType="manuel",
          @change="e => changeOrderDate(header, e)",
          inputIconRight=""
          :disabled="(header.row.status === 2)"
          inputIcon="")


    portal(to="resendButton")
      div(slot-scope="header")
        Button(
          v-if="header.row.accountingStatus === 3 || header.row.wmsStatus === 3",
          :id="`orders-header-resend-button-${header.rowIndex}`"
          iconName="icon-global-warning",
          class="icon-global-warning"
          :justIcon="true",
          :clearVariant="true"
          size="medium"
          :isLoading="resendLoading",
          :disabled="resendLoading"
          @click="toggleErrorPopup(header.row)"
        )           

        .MiniPopup.right(
          v-if="header.row.showAccountingStatus"
          v-shortkey="['esc']"
          v-click-outside="() => closeErrorPopup(header.row)"
        )
          .resendButton-Popup
            .resendButton-Popup-content(v-if="header.row.accountingStatus === 3")
              span {{ header.row.accountingMessage }}
              Button.m-left-10(id="approved-orders-header-resend-data" iconName="icon-global-refresh" iconClass="icon-global-refresh" secondary size="small" :disabled="resendLoading" :isLoading="resendLoading" @click="requestOrderResend(header.row)")
                span {{ $t('Orders.Resend_Button_Text') }}
            
            .resendButton-Popup-content.m-top-10(v-if="header.row.wmsStatus === 3")
              span {{ header.row.wmsMessage }}
              Button.m-left-10(id="approved-orders-header-resend-data-wms" iconName="icon-global-refresh" iconClass="icon-global-refresh" secondary size="small" :disabled="isLoadingWMSReset" :isLoading="isLoadingWMSReset" @click="resendOrderForWMS(header.row)")
                span {{ $t('Orders.Resend_Button_Text') }}

            //- CustomCheckbox.resendButton-Popup-item(
            //-   id="checkbox-e-invoice-show-fails"
            //-   :checked="showFailed"
            //-   :label="$t('EInvoice.showFailedOnly')"
            //-   @change="changeShowFailStatus")

            //- CustomCheckbox.resendButton-Popup-item(
            //-   id="checkbox-eInvoices-show-passive"
            //-   :checked="showPassive"
            //-   :label="$t('EInvoice.showPassiveInvoices')"
            //-   @change="showPassiveInvoices")

    portal(to="description")
      div(slot-scope="header")
        Button.show-MiniPopup.relative.width-max-content(
          v-if="header.row.description",
          :id="`orders-header-info-button-${header.rowIndex}`"
          iconName="icon-global-info",
          :clearVariant="true"
          :justIcon="true",
          class="btn-info"
        )
          .MiniPopup-right-primary.m-top-15.c-dark
            span {{ header.row.description }}

    portal(to="detailDescription")
      div(slot-scope="detail")
        Button.show-MiniPopup.relative.width-max-content(
          iconName="icon-global-info",
          :justIcon="true",
          :id="`orders-detail-info-button-${detail.rowIndex}`"
          v-if="detail.row.description",
          class="btn-info"
        )
          .MiniPopup-leftCenter-primary.m-top-15.c-dark
            span {{ detail.row.description }}

    router-view(@getOrdersHeaderAll="getOrdersHeaderAll", @showOrderWithDetail="showOrderWithDetail")

    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )
</template>

<script>
// mixin
import alertbox from '@/mixins/alertbox'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getOrdersHeaderFields, getOrdersDetailFields } from './fields/approvedOrders'
import moment from 'moment'
//
import TableFoot from '@/view/global/table/foot.vue'
import supplierFilter from '@/view/global/business-filters/supplier.vue'
import storeFilter from '@/view/global/business-filters/store.vue'
import ImportExport from '@/view/global/import-export'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import StatusTooltip from '@/view/global/alert-status-tooltip'
import TotalPreview from '@/view/pages/Requests/components/total-preview'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import exportPopup from '@/view/global/export-popup'
import settingsExportPopup from '@/mixins/settingsExportPopup'

export default {
  name: 'Orders',
  mixins: [
    alertbox(),
    hasTabPermission,
    settingsExportPopup
  ],
  data () {
    return {
      dates: [],
      storeFilter: [],
      stockItem: null,
      search: '',
      selectedStockItem: [],
      supplierFilter: [],
      ordersDetailFields: getOrdersDetailFields(this.$t),
      selectedHeaders: [],
      selectedDetails: [],
      disableDate: {
        to: new Date()
      },
      hasOrderEdit: false,
      showAccountingStatus: false,
      showIntegrationMessages: false
    }
  },
  components: {
    TableFoot,
    supplierFilter,
    storeFilter,
    ImportExport,
    NoPermission,
    StatusTooltip,
    TotalPreview,
    exportPopup
  },
  computed: {
    ...mapGetters('Request', ['requestHeaderLinks']),

    ...mapGetters('Orders', [
      'approveOrders',
      'Page',
      'isEmptyApproveOrderList'
    ]),

    ...mapGetters('Settings', ['allPermissionList', 'roleId', 'permissions']),
    ...mapGetters('OrderIntegrationStatus', [
      'OrderIntegrationStatusList'
    ]),

    isEmptySelectedHeaders () {
      return this.selectedHeaders.length === 0
    },
    isEmptySelectedDetaild () {
      return this.selectedDetails.length === 0
    },
    selectedSupplierId () {
      if (this.isEmptySelectedHeaders && this.isEmptySelectedDetaild) {
        return null
      } else {
        return !this.isEmptySelectedHeaders ? this.selectedHeaders[0].supplierId : this.selectedDetails[0].supplierId
      }
    },

    headerActions () {
      return this.hasOrderEdit ? ['edit', 'single-action-portal-start', 'export'] : ['single-action-portal-start', 'export']
    },

    detailActions () {
      return this.hasOrderEdit ? ['edit'] : []
    },

    ordersHeaderFields () {
      return getOrdersHeaderFields(this.$t, this.OrderIntegrationStatusList, this.hasOrderEdit)
    },
    isFetchingStoreList () {
      return this.$wait.is(['getMiniStoreList'])
    },
    isLoadingWMSReset () {
      return this.$wait.is(['resetWMSStatus'])
    },
    isFetchingPageData () {
      return this.$wait.is(['pageLoading'])
    },
    isFetchingOrderList () {
      return this.$wait.is('fetchingOrderList')
    },
    isSuggestionLoading () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    isSuggestionResultEmpty () {
      return this.selectedStockItem.length === 0
    },

    resendLoading () {
      return this.$wait.is('resendLoading')
    },

    downloadParams () {
      const params = {
        StartDate: this.dateFormat(this.dates[0]),
        EndDate: this.dateFormat(this.dates[1]),
        storeIdList: this.storeFilter.map(store => store.id),
        supplierIdList: this.supplierFilter.map(supplier => supplier.id),
        stockItemId: this.stockItem ? this.stockItem.id : null
      }
      return params
    },
    downloadFileName () {
      const fileName = `Orders_Report_${this.dateFormat(this.dateStart)}_${this.dateFormat(this.dateEnd)}`
      return fileName
    },

    isSelectedAll () {
      return this.selectedHeaders.length ? this.selectedHeaders.length === this.selectableHeaders.length : false
    },

    selectableHeaders () {
      return this.selectedHeaders.length || this.selectedDetails.length
        ? this.approveOrders.filter(header => this.selectedHeaders.length ? header.supplierId === this.selectedHeaders[0].supplierId : this.selectedDetails[0].supplierId === header.supplierId)
        : []
    },

    selectableDetails () {
      return this.selectableHeaders.map(header => header.detailList).flat()
    }
  },
  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Orders', ['getOrdersHeader', 'getOrderDetail', 'setOrderStatus', 'orderResend', 'resetWMSStatus', 'updateApprovedOrder']),

    ...mapActions('Stock', ['searchStockItem']),

    ...mapActions('Settings', ['fetchPermissionRoleList']),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('OrderIntegrationStatus', [
      'getOrderIntegrationStatusList'
    ]),

    ...mapMutations('Orders', ['HIDE_DETAIL', 'SHOW_DETAIL']),

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    hasOrderEditPermission () {
      this.permissions.find(i => {
        if (i.permission === 211) this.hasOrderEdit = true
      })
    },

    toggleAll () {
      if (this.isSelectedAll) {
        this.selectedHeaders = []
        this.selectedDetails = []
      } else {
        this.selectedHeaders = [...this.selectableHeaders]
        this.selectedDetails = [...this.selectableDetails]
      }
    },

    async changePage (page) {
      await this.getOrdersHeaderAll(page)
    },

    async changePageSize (pageSize) {
      await this.getOrdersHeaderAll(1, pageSize)
    },


    isSelectedAllDetails (header) {
      return header.detailList.length === this.selectedDetails.filter(detail => detail.headerId === header.id).length
    },

    isCheckedDetail (detailId) {
      return this.selectedDetails.some(item => item.id === detailId)
    },

    async requestOrderResend (order) {
      this.$wait.start('resendLoading')
      const res = await this.orderResend({orderId: order.id})
      await this.getOrdersHeaderAll(this.Page.number)
      this.$wait.end('resendLoading')
    },

    async resendOrderForWMS (order) {
      const res = await this.resetWMSStatus({orderId: order.id})
      await this.getOrdersHeaderAll(this.Page.number)
    },

    changeOrderDate (item, date) {
      const dontUpdate = !date || !item.row.orderDate || date === item.row.orderDate

      if (dontUpdate) return
      const payload = {
        ...item.row,
        orderDate: date
      }
      this.updateApprovedOrder(payload)
        .then(res => {
          if (res.status !== 200) return
          const message = this.$t('Global.successUpdateMessage', { field: this.$t('Orders.moduleName') })
          this.notifyShow({ message })
        })
    },

    async changeOrderType (item) {
      const selectedStatusItem = this.OrderIntegrationStatusList.find(s => s.id === item.integrationStatusId)
      if (selectedStatusItem && selectedStatusItem.isClosureStatus) {
        this.alert({
          title: this.$t('Orders.closeConfirmMessageTitle'),
          message: this.$t('Orders.closeConfirmMessage'),
          cancel: {
            text: this.$t('Orders.deleteAlert_CancelButton'),
            action: () => {
              this.$refs.ordersNestedTable.makeForms()
            }
          },
          confirm: {
            text: this.$t('Orders.confirmButtonText'),
            action: async (close) => {
              const res = await this.changeOrderStatus(item)
              if (res) {
                close()
              }
            }
          }
        })
      } else {
        await this.changeOrderStatus(item)
      }
    },
    async changeOrderStatus (item) {
      const res = await this.setOrderStatus({
        orderId: item.id,
        integrationStatusId: item.integrationStatusId
      })
      if (res) {
        const message = this.$t('Global.successUpdateMessage', { field: this.$t('Orders.moduleName') })
        await this.notifyShow({ message })
        await this.getOrdersHeaderAll(this.Page.number)
      } else {
        this.$refs.ordersNestedTable.makeForms()
      }
      return res
    },
    showOrderDetail (header) {
      const item = this.approveOrders.find(i => i.id === header.id)
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
        return
      }
      this.getOrderDetail(item)
        .then(() => {
          if (!this.selectedHeaders.some(h => h.id === item.id)) return
          item.detailList.forEach(d => !this.isCheckedDetail(d.id) ? this.selectedDetails.push(d) : false)
        })
    },

    async getListWithSupplierFilter ({list, dontSubmit}) {
      if (dontSubmit) this.supplierFilter = list
      else {
        this.$wait.start('fetchingOrderList')
        this.supplierFilter = list
        await this.getOrdersHeaderAll()
        this.$wait.end('fetchingOrderList')
      }
    },

    async getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.$wait.start('fetchingOrderList')
        this.storeFilter = list
        await this.getOrdersHeaderAll()
        this.$wait.end('fetchingOrderList')
      }
    },

    changeShowIntegrationMessagesStatus () {
      this.showIntegrationMessages = !this.showIntegrationMessages
      this.getOrdersHeaderAll()
    },

    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.selectedStockItem = []
      this.getOrdersHeaderAll()
    },

    clearSearch () {
      this.search = ''
      this.stockItem = []
      this.selectedStockItem = []
      this.getOrdersHeaderAll()
    },

    clearFilter () {
      this.$refs.approvedOrderStoreFilter.clearStoreFilter()
      this.$refs.approvedOrderSupplierFilter.clearSupplierFilter(false)
    },

    async getOrdersHeaderAll (page = 1, pageSize = this.Page.size) {
      this.selectedHeaders = []
      this.selectedDetails = []
      const payload = {
        storeIdList: this.storeFilter ? this.storeFilter.map(s => s.id) : [],
        supplierIdList: this.supplierFilter ? this.supplierFilter.map(t => t.id) : [],
        stockItemId: this.stockItem ? this.stockItem.id : null,
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        FilterType: 1,
        isIntegrationFailed: this.showIntegrationMessages,
        pageNumber: page,
        pageSize
      }
      const res = await this.getOrdersHeader(payload)
      return res
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) {
        this.clearSearch()
      } else {
        this.searchStockItem({ Text: search, types: [1, 2] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },

    editAction (item) {
      this.$router.push({
        name: 'OrdersEdit',
        params: {
          id: item.id,
          item
        }
      })
    },
    addNewDetail (order) {
      this.$router.push({
        name: 'orderAddStock',
        params: {
          id: order.id
        }
      })
    },
    editDetailAction (item) {
      this.$router.push({
        name: 'OrderDetailEdit',
        params: {
          id: item.headerId,
          detailId: item.id
        }
      })
    },

    async showOrderWithDetail (item) {
      const header = await this.getOrdersHeaderAll()
      if (header) {
        this.showOrderDetail(item)
      }
    },

  
    showExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 6, isFinalizeAction, 'Order')
    },

    toggleErrorPopup (item) {
      item.showAccountingStatus = !item.showAccountingStatus
    },

    closeErrorPopup (item) {
      item.showAccountingStatus = false
    }
  },

  mounted () {
    this.hasOrderEditPermission()
  },

  async beforeMount () {
    if (!this.hasTabPermission) return

    this.$wait.start('pageLoading')
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_Order')
    await this.getOrderIntegrationStatusList()
    await this.fetchPermissionRoleList(this.roleId)
    await this.getOrdersHeaderAll()
    this.$wait.end('pageLoading')
  },

  watch: {
    async dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.$wait.start('fetchingOrderList')
      await this.getOrdersHeaderAll()
      this.$wait.end('fetchingOrderList')
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

.resendButton-Popup {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  box-shadow: $color-warning 1px 1px 1px 2px;
  &-item{
    margin: 5px;
  }
  &-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}

.MiniPopup {
  right: calc(26px);
  width: max-content;
}


.PageHeader {
  padding: 0;
  .right {
    align-self: center;
    display: flex;
    .form-item-select {
      min-width: 200px;
      flex-shrink: 0;
    }
  }
}
.pageLoading {
  top: inherit;
}

.btn-info {
  .icon {
    width: 24px;
    height: 24px;
  }
}

:deep() .status-title{
  width: 500px;
}

.sticky {
  z-index: $z-index-md;
  position: sticky !important;
  left: 50%;
  top: 50%;
}


:deep() .resendButton {
  right: 37px !important;
  position: relative;
}

:deep() .NestedTable{
  &-row{
    height: unset !important;
    padding-right: 10px !important;
    //padding-left: 15px !important;
    // new     grid-template-columns: 160px 1fr 1fr 1.5fr 1.1fr repeat(5,1fr) repeat(3,0.50fr) !important;
    //         grid-template-columns: 160px 1fr 1fr 1.5fr 1.1fr 1.6fr 1.2fr repeat(3,1fr) repeat(3,0.50fr) !important
    // old grid-template-columns: 2fr 1fr 1fr 125px repeat(3, 1fr) 110px 65px 50px 50px 10px  !important;
    //grid-template-columns: 150px 1fr 1fr 1.5fr 1.1fr repeat(5,1fr) repeat(3,0.50fr) !important;
    grid-template-columns: 130px 1fr 1fr 1.5fr 2fr 2fr 1.5fr 1.8fr repeat(4,57px) !important;
    &-col {
      word-break: break-word !important ;
    }
  }
  &-head{
    grid-template-columns: 130px 2fr 2fr repeat(7, 2fr) repeat(1, 1fr) !important;
    //grid-template-columns: 160px 2fr 2fr repeat(7,2fr) repeat(3,0.50fr) !important;
    //grid-template-columns: 2fr 1fr 2fr 125px repeat(3, 1fr) 110px 65px 50px 50px 10px !important;
  }
  &-item{
    height: auto !important;
  }
}
  :deep(.datepicker-ui .txt) {
    padding: unset !important;
  }
  :deep(.form-item-select .icon-down-arrow) {
    right: 6px !important;
  }
</style>