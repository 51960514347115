<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )

  template(slot="header-left-search")
    Search.search(
      componentName="Despatch",
      :isSuggestionLoading="false",
      :customSuggestionPlaceHolder="$t('Despatches.invoiceSearch_Placeholder')"
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-right-dropdown")

    .form-item-select.right-date

      CustomDatepicker#InvoiceDatepicker(
        v-model="dates",
        :isLoading="$wait.is('getDateFilterSettings')"
        :disabled="isLoading || $wait.is('pageLoading')"
        :range="true")
  template(slot="toolbar-left")
    SupplierFilter(@submitFilter="getListWithSupplierFilter"
      ref="despatchInvoiceSupplierFilter")

    businessMultiselect(
      componentName="despatch-accounting-filter"
      :label="$t('Despatches.toolbar_Accounting_Label')"
      :popupHeader="$t('Despatches.toolbar_Accounting_PopupHeader')"
      :multiselectItems="accountingList"
      @submitFilter="getFilteredInvoice"
      uniqKey="value"
      ref="businessDespatchInvoiceFilter"
    )

    businessMultiselect(
      componentName="despatch-invoice-type-filter"
      :label="$t('Despatches.toolbar_Invoice_Type_Label')"
      :popupHeader="$t('Despatches.toolbar_Invoice_Type_Label')"
      :multiselectItems="invoiceTypes"
      @submitFilter="getInvoiceType"
      uniqKey="value"
      ref="businessDespatchInvoiceTypeFilter"
    )

    Button.clear-filter-button.border-gray-1.p-0.width-auto(@click='clearFilter')
        Icon.icon-clear-filter(name="icon-clear-filter")
    
  template(slot="toolbar-right")
    Button.send-selected(
      id="btn-invoice-selected-send-to-accounting"
      type="button",
      primary
      size="small"
      @click="sendSelected"
      iconName="icon-add-circle"
      iconClass="icon-add-circle"
      :disabled="isLoadingSendSelected || isSelectedEmpty")
        span {{ $t('Despatches.send_Selected_Invoices_To_Accounting') }}
        Loading(theme="disable", v-show="isLoadingSendSelected")
    
  template(slot="content-center")
    TableLoading(v-if="isLoading || $wait.is('pageLoading')")

    EmptyState(
    v-if="invoice_IsEmpty && !isLoading && !$wait.is('pageLoading')",
    :title="$t('Despatches.emptyInvoiceState_Title')",
    :description="$t('Despatches.emptyInvoiceState_Description')")

    invoiceTable(
      v-if="!invoice_IsEmpty && !isLoading"
      :tableData ="invoice_List",
      :showDetailAction = "invoiceDetail"
      :selectedList="selectedList"
      :accountingList="accountingList"
      @delegateCheckItem="handleCheckbox"
      @delegateCheckAllItem="toggleAll"
      @getList="getInvoiceListWithParams"
      :showExportPopup="showExportPopup"
    )

    router-view

    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )
  template(slot="footer")
    TableFoot(
      :page="InvoicePage"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      v-show="!invoice_IsEmpty && !isLoading"
      :numberData="[20, 50]"
    )

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'

//
import invoiceTable from './invoice-table'
import { getAccountingList, invoiceTypeFilter } from './despatchConstant.js'
import TableFoot from '@/view/global/table/foot.vue'
import Search from '@/view/global/search'
import SupplierFilter from '@/view/global/business-filters/supplier.vue'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import exportPopup from '@/view/global/export-popup'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'
import settingsExportPopup from '@/mixins/settingsExportPopup'

export default {
  name: 'Invoice',

  components: {
    invoiceTable,
    TableFoot,
    SupplierFilter,
    exportPopup,
    businessMultiselect,
    Search
  },

  mixins: [settingsExportPopup],

  data () {
    return {
      dates: [],
      supplierFilter: [],
      selectedList: [],
      accountingFilter: [],
      accountingList: [],
      invoiceTypes: [],
      invoiceTypeFilter: [],
      searchText: null
    }
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.resetSelectedList()
      this.getInvoiceListWithParams()
    }
  },

  async created () {
    this.$wait.start('pageLoading')
    await this.initInitialValues()
    await this.getInvoiceListWithParams()
    if (this.$route.params.invoiceId) this.showExportPopup(this.$route.params.invoiceId, true)
    this.$wait.end('pageLoading')
  },

  computed: {

    ...mapGetters('Despatch', [
      'invoice_List',
      'invoice_IsEmpty',
      'InvoicePage',
      'pageHeaderLinks'
    ]),

    isLoading () {
      return this.$wait.is(['get_INVOICE_LIST'])
    },

    isLoadingSendSelected () {
      return this.$wait.is(['postSelectedInvoices', 'sendSelectedInvoice'])
    },

    selectableList () {
      return this.invoice_List.filter(item => item.accountingStatus === 0 || item.accountingStatus === 3)
    },

    isSelectedEmpty () {
      return this.selectedList.length === 0
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Despatch', [
      'get_INVOICE_LIST',
      'get_INVOICE',
      'postSelectedInvoices'
    ]),

    ...mapMutations('Despatch', [
      'HIDE_INVOICE_DETAIL',
      'SHOW_INVOICE_DETAIL',
      'UPDATE_SUPPLIER_IDS'
    ]),

    async initInitialValues () {
      await this.getDateFilterSettings()
      this.dates = getDateFilterSetting('Date_Invoice')
      this.accountingList = getAccountingList(this.$t)
      this.accountingFilter = [...this.accountingList]
      this.invoiceTypes = invoiceTypeFilter(this.$t)
    },

    getListWithSupplierFilter ({list, dontSubmit}) {
      if (dontSubmit) this.supplierFilter = list
      else {
        this.supplierFilter = list
        this.getInvoiceListWithParams()
      }
    },

    onChangeSearch (search) {
      this.searchText = search
      if (this.searchText.length === 1) return
      this.getInvoiceListWithParams()
    },

    resetSelectedList () {
      this.selectedList = []
    },

    handleCheckbox ({ isChecked, invoice }) {
      if (isChecked) {
        this.selectedList.push(invoice)
      } else {
        this.selectedList.splice(this.selectedList.indexOf(invoice), 1)
      }
    },

    toggleAll (isChecked) {
      if (isChecked) {
        this.selectedList = this.selectableList
      } else {
        this.selectedList = []
      }
    },

    sendSelected: vueWaitLoader(async function () {
      const ids = this.selectedList.map(item => item.id)
      await this.postSelectedInvoices({invoiceList: ids})
      await this.getInvoiceListWithParams()
      this.resetSelectedList()
    }, 'sendSelectedInvoice'),

    async invoiceDetail (invoice) {
      if (invoice.showDetail) {
        this.HIDE_INVOICE_DETAIL(invoice)
        return
      }
      await this.get_INVOICE(invoice.id).then(res => {
        if (res.status === 200) {
          this.SHOW_INVOICE_DETAIL(res.data)
        }
      })
    },

    getInvoiceListWithParams (page = 1, pageSize = this.InvoicePage.size) {
      let supList = []
      this.resetSelectedList()
      this.supplierFilter.map(sup => supList.push(sup.id))
      this.get_INVOICE_LIST({
        supplierIds: supList,
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        accountingStatusList: this.accountingFilter.map(accounting => accounting.value),
        invoiceType: this.invoiceTypeFilter.length > 1 ? null : this.invoiceTypeFilter[0].value, // TODO FIX
        pageNumber: page || 1,
        pageSize,
        invoiceNumber: this.searchText
      })
    },

    getFilteredInvoice ({ list, dontSubmit }) {
      this.accountingFilter = list
      if (!dontSubmit) {
        this.getInvoiceListWithParams()
      }
    },

    getInvoiceType ({ list, dontSubmit }) {
      this.invoiceTypeFilter = list
      if (!dontSubmit) {
        this.getInvoiceListWithParams()
      }
    },

    changePage (page) {
      this.getInvoiceListWithParams(page)
    },

    changePageSize (pageSize) {
      this.getInvoiceListWithParams(1, pageSize)
    },

    clearFilter () {
      this.$refs.businessDespatchInvoiceFilter.clearAllFilter()
      this.$refs.businessDespatchInvoiceTypeFilter.clearAllFilter()
      this.$refs.despatchInvoiceSupplierFilter.clearSupplierFilter(false)
    },

    showExportPopup (Id, isFinalizeAction = false) {
      this.exportFrame(Id, 3, isFinalizeAction)
    }
  }
}
</script>

<style scoped lang="scss">

.PageHeader {
  .right {
    display: flex;

    .form-item-select {
      margin-left: 10px;
    }
  }
}
:deep() .Search-input {
  margin-left: 0 !important;
}
</style>
