export default function getFields($t) {
  return [
    {
      name: 'stockItemCode',
      is_visible: true,
      get short_title() {
        return $t('Massec.stockItemCode')
      },
      get title() {
        return $t('Massec.stockItemCode')
      }
    },
    {
      name: 'barcode',
      is_visible: true,
      get short_title() {
        return $t('Massec.barcode')
      },
      get title() {
        return $t('Massec.barcode')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('Massec.stockItemName')
      },
      get title() {
        return $t('Massec.stockItemName')
      }
    },
    {
      name: 'actualQuantity',
      is_visible: true,
      get short_title() {
        return $t('Massec.actualQuantity')
      },
      get title() {
        return $t('Massec.actualQuantity')
      }
    },
    {
      name: 'unitCode',
      is_visible: true,
      get short_title() {
        return $t('Massec.unitCode')
      },
      get title() {
        return $t('Massec.unitCode')
      }
    }
  ]
}
