<template lang="pug">
section.httpError
    .httpError-text
      h2.m-bottom-20 {{ $t('httpErrors.title' + httpCode) }}
        span.m-left-10 {{ httpCode }}
      p.m-bottom-20 {{ $t('httpErrors.description' + httpCode) }}
      p.m-bottom-20 {{ $t('httpErrors.countdownText') }}
        span {{ timer }}
      Button.btn.btn-success.m-top-10#backHomeLink(@click="redirectOldPage")
        span {{ $t('httpErrors.buttonText' + httpCode) }}
    .httpError-cloud
      Icon(name="icon-http-error")
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    to: {
      type: Object,
      required: false
    },
    buttonText: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      timer: 5,
      timeout: null
    }
  },
  computed: {
    httpCode () {
      return this.$route.params.httpCode || 503
    }
  },
  methods: {
    redirectOldPage () {
      if (this.$route.params.oldRoutePath) this.$router.push({ path: this.$route.params.oldRoutePath })
      else this.$router.push({ name: 'Dashboard' })
    },
    countdown () {
      if (this.timer === 0) this.redirectOldPage()
      if (this.timer > 0) {
        this.timeout = setTimeout(() => {
          this.timer--
          this.countdown()
        }, 1000)
      }
    }
  },
  mounted () {
    // Stops All waits and starts countdown
    const waiters = this.$store.state.wait.waitingFor
    waiters.forEach(waitItem => this.$wait.end(waitItem))
    this.countdown()
  },
  beforeRouteLeave (to, from, next) {
    clearTimeout(this.timeout)
    next()
  }
}
</script>

<style lang="scss" scoped>

  .httpError {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
    &-text {
      max-width: 500px;
      margin-right: 90px;
      h2 {
        font-size: $font-size-bigger;
        color: $color-light;
        font-weight: $font-weight-normal;
        span {
          color: $color-dark;
          font-size: $font-size-HHbigger;
          font-weight: $font-weight-bolder;
        }
      }
      p {
        font-size: $font-size-big;
        color: $color-light;
        span {
          color: $color-dark;
          font-weight: $font-weight-bolder;
        }
      }
      .btn {
        max-width: 150px;
      }
    }
  }
</style>
