export default function getFields($t) {
  return [
    {
      name: 'isPreferred',
      is_visible: true,
      type: 'portal',
      fixedColumnHeader: true,
      get short_title() {
        return $t('PriceQuota.tableColumn_isPreferred_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_isPreferred_Short')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      fixedColumnHeader: true,
      get short_title() {
        return $t('PriceQuota.tableColumn_StockItemName_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_StockItemName_Short')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('PriceQuota.tableColumn_UnitName_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_UnitName')
      }
    },
    {
      name: 'supplierName',
      is_visible: true,
      get short_title() {
        return $t('PriceQuota.tableColumn_SupplierName_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_SupplierName')
      }
    },
    {
      name: 'priceType',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('PriceQuota.tableColumn_PriceType')
      },
      get title() {
        return $t('PriceQuota.tableColumn_PriceType')
      }
    },
    {
      name: 'unitPrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('PriceQuota.tableColumn_UnitPrice_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_UnitPrice')
      }
    },
    {
      name: 'baseUnitPrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('PriceQuota.tableColumn_BaseUnitPrice_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_BaseUnitPrice')
      }
    },
    {
      name: 'discount1',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('PriceQuota.tableColumn_Discount1_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_Discount1')
      }
    },
    {
      name: 'discount2',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('PriceQuota.tableColumn_Discount2_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_Discount2')
      }
    },
    {
      name: 'discountedUnitPrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('PriceQuota.tableColumn_DiscountedUnitPrice_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_DiscountedUnitPrice')
      }
    },
    {
      name: 'discountedBaseUnitPrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('PriceQuota.tableColumn_DiscountedBaseUnitPrice_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_DiscountedBaseUnitPrice')
      }
    },
    {
      name: 'validityDateRange',
      is_visible: true,
      get short_title() {
        return $t('PriceQuota.tableColumn_ValidityDateRange_Short')
      },
      get title() {
        return $t('PriceQuota.tableColumn_ValidityDateRange')
      }
    },
    {
      name: 'leadTime',
      is_visible: true,
      type: 'number',
      get short_title() {
        return $t('Global.leadTime')
      },
      get title() {
        return $t('Global.leadTime')
      }
    },
    {
      name: 'lastPurchaseDate',
      is_visible: true,
      type: 'date',
      get short_title() {
        return $t('Global.lastPurchaseDate')
      },
      get title() {
        return $t('Global.lastPurchaseDate')
      }
    }
  ]
}
