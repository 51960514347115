<template lang="pug">
Page
  template(slot="header-right-dropdown")
    CustomDatepicker#TransferDatepicker(
      :isLoading="$wait.is('getDateFilterSettings')"
      v-model="dates",
      :range="true"
      :disabled="isLoading"
    )
  template(slot="header-right-content")
    Button(
    size="medium"
    primary
    id="btn-transfer-new"
    type="button",
    v-show="!isEmptyTransferList && !isLoading",
    iconName="icon-global-add"
    iconClass="icon-global-add"
    @click="addNewTransfer")
      span {{ $t('Transfer.addTransferButton') }}

  template(slot="toolbar-left")
    CustomMultiselect.toolbar-filters__item(
      id="multiselect-sender-store-filter"
      :items="fromStores"
      :selected="selectedFromStores"
      :label="$t('Transfer.senderStore')"
      :popupHeader="$t('Transfer.senderStoreSelector')"
      :unselectedText="$t('Global.toolbar_Filters_AllLabel')"
      :allLabel="$t('Global.toolbar_Filters_AllLabel')"
      :buttonText="$t('Global.toolbar_Filters_ButtonText')"
      buttonIconLeft="btn-success"
      @delegateCheckAll="fromStoreOnChangeFilter"
      @delegateCheckItem="fromStoreOnChangeFilter"
      @delegateSubmitItems="submitFilter"
    )

    CustomMultiselect.toolbar-filters__item(
      id="multiselect-reciever-store-filter"
      :items="toStores"
      :selected="selectedToStores"
      :label="$t('Transfer.buyerStore')"
      :popupHeader="$t('Transfer.buyerStoreSelector')"
      :unselectedText="$t('Global.toolbar_Filters_AllLabel')"
      :allLabel="$t('Global.toolbar_Filters_AllLabel')"
      :buttonText="$t('Global.toolbar_Filters_ButtonText')"
      buttonIconLeft="btn-success"
      @delegateCheckAll="toStoreOnChangeFilter"
      @delegateCheckItem="toStoreOnChangeFilter"
      @delegateSubmitItems="submitFilter"
    )

    .show-drafts.m-left-15
      CustomCheckbox.popup-item-check(
        id="checkbox-transfer-show-drafts"
        :checked="showDrafts"
        :label="$t('Global.showDrafts')"
        @change="changeShowDraftsStatus")
  template(slot="content-center")
      .TransferPageContent.m-top-20

        TableLoading(v-if="isLoading")

        // EMPTY PAGE

        EmptyState(
        v-if="isEmptyTransferList && !isLoading",
        :title="$t('Transfer.emptyState_Title')",
        :description="$t('Transfer.emptyState_Description')",
        :buttonText="$t('Transfer.addTransferButton')",
        @emptyAddButton="addNewTransfer")

        // LIST
        div(v-if="!isEmptyTransferList && !isLoading")
          NestedTable(
            tableKey="transfer",
            :fields="transferHeaderFields",
            :detailFields="transferDetailFields",
            :data="TransferList",
            :isDetailLoading="isDetailLoading"
            :actions="['edit','delete', 'export']",
            :detailActions="['edit','delete']"
            :selectable="'checkbox'"
            :selectableAll="'checkbox'"
            :selectedRows="selectedList"
            :addButtonText="$t('Transfer.addNewStock')",
            :finalizeButtonText="$t('Transfer.transferSave')",
            :usePortalFooter="true"
            :isActionButtonsVisible="true"
            :isLoadingFinalize="isFinalize"
            @finalizeAction="transferSaved",
            @addDetailAction="transferDetailNew",
            @showDetailAction="showDetailAction",
            @deleteDetailAction="transferDetailRemove",
            @detailChangeAction="updateDetailItem"
            @showExportPopup="showExportPopup"
            @editHeaderAction="editAction"
            @deleteHeaderAction="removeItemAction",
            @selectItem="onSelectTransfer"
            @selectAllItems="onSelectTransferAll"
            @editDetailAction="transferDetailEdit")
          
          portal(to="code")
            div(slot-scope="header")
              .code
                span {{header.row.code}}
                Button.tooltip-relative(
                  size="small",
                  v-if="header.row.despatchNumber"
                  iconName="icon-menu-transfer"
                  iconClass="icon-menu-transfer"
                  :clearVariant="true"
                  :justIcon="true"  
                  @click="goToDespatchDetailDocument(header.row)"                                  
                )
                  Tooltip.small(
                    white,
                    right,
                    :text="header.row.despatchNumber")


          portal(to="info")
            div(slot-scope="header")
              Button.show-MiniPopup.relative.width-max-content(
                v-if="header.row.info",
                size="small",
                :id="`tranfer-header-info-button-${header.rowIndex}`"
                iconName="icon-global-info",
                :justIcon="true",
                class="btn-info"
              )
                .MiniPopup-right-primary.m-top-15.c-dark
                  span {{ header.row.info }}

          portal(to="maxApprovalLevel")
            .approval-levels(slot-scope="header")
              ApprovalLevels(
                v-if="header.row.approvalStatus > 0"
                :maxApprovalLevel="header.row.maxApprovalLevel",
                :currentApprovalLevel="header.row.currentApprovalLevel",
                :approvalStatus="header.row.approvalStatus"
                :historyList="historyListForTransfer"
                :isLoading="isHistoryLoading"
                @showHistoryPopup="showHistoryPopup(header.row)"
              )

          portal(to="approvalStatus")
            div(slot-scope="header")
              span {{ getApprovalStatus(header.row.approvalStatus) }}

          portal(to="description")
            div(slot-scope="detail")
              Button.show-MiniPopup.relative.width-max-content(
                size="small",
                v-if="detail.row.description",
                :id="`tranfer-detail-info-button-${detail.rowIndex}`"
                iconName="icon-global-info",
                :justIcon="true",
                class="btn-info"
              )
                .MiniPopup-leftCenter-primary.m-top-15.c-dark
                  span {{ detail.row.description }}

          portal(to="rejectionReason")
            div(slot-scope="header")
              Button.show-MiniPopup.relative.width-max-content(
                v-if="header.row.rejectionReason"
                size="small"
                :id="`transfer-approval-header-info-button-${header.rowIndex}`"
                iconName="icon-global-info"
                :justIcon="true"
                class="btn-info rejection-info"
              )
                .MiniPopup-right-primary.m-top-15.c-dark
                  span {{ header.row.rejectionReason }}

          portal(to="footerPortal")
            div.popup-container.flexbox(slot-scope="rowData")
              Button.m-right-20(
                v-if="isExpireDateControlEnabled(rowData)"
                id="btn-transfer-expire-date-edit"
                type="button"
                size="small"
                secondary
                @click="editExpireDate(rowData)")
                  span {{ $t('Transfer.editExpireDate') }}
          
              div(
                v-if="rowData.status === 1 || rowData.status === 4"
              )
                Button.m-right-20(
                  id="nested-detail-add-button",
                  size="small",
                  inline,
                  primary,
                  iconName="icon-global-add",
                  iconClass="icon-global-add",
                  :disabled="(rowData.approvalStatus !== 0  && rowData.approvalStatus !== 3) || isOnlyListUsage"
                  @click="transferDetailNew(rowData)")
                    span {{ $t('Transfer.addNewStock') }}

              div(v-if="Tenant.approvalTransferPermission && (rowData.approvalStatus === 0 && rowData.maxApprovalLevel > 0 && rowData.detailList.length > 0 || rowData.status === 4 || (rowData.approvalStatus === 3 && rowData.maxApprovalLevel > 0))")
                Button.m-right-20(
                id="transfer-nested-detail-send-to-approve",
                inline,
                size="small"
                primary,
                iconName="icon-btn-success",
                iconClass="icon-btn-success",
                @click="sendToApprove(rowData)")
                  span {{ $t('DirectOrders.sendToApprove') }}
              div(
                v-if="rowData.status === 3 && checkStorePermission(rowData)"
              )
                miniPopupButton(
                  :data="rowData"
                  :buttonText="$t('Transfer.Button_Reject')"
                  :descText="$t('Transfer.Reason_Description_Input_Label')"
                  :agreeButtonText="$t('Transfer.Detail_Col_Button_Agree')"
                  :isDisable="isFinalize"
                  @onSubmit="transferReject"
                )
                            
              div(
                v-if="rowData.status === 3 && checkStorePermission(rowData)")
                Button(
                  id="nested-detail-approve-transfer-button",
                  size="small"
                  primary,
                  inline,
                  iconName="icon-btn-success",
                  iconClass="icon-left",
                  :disabled="isFinalize"
                  @click="transferSaved(rowData)")
                    span {{ $t('Transfer.transferApprove') }}
                    
              div(v-if="Tenant.approvalTransferPermission ? (rowData.maxApprovalLevel > 0 ? rowData.approvalStatus === 2 && rowData.detailList.length > 0 && (rowData.status === 1 || rowData.status === 4) : rowData.detailList.length > 0 && rowData.status === 1 ) : rowData.detailList.length > 0 && (rowData.status === 1 && rowData.approvalStatus === 0) || rowData.status === 4")
                Button(
                  id="nested-detail-finalize-button",
                  size="small"
                  primary,
                  inline,
                  iconName="icon-btn-success",
                  iconClass="icon-left",
                  :disabled="isFinalize"
                  @click="transferSaved(rowData)")
                    span {{ rowData.status === 4 ? $t('Transfer.repeatTransferApprove') : $t('Transfer.transferSave') }}

          // pagination
          TableFoot(
            :page="Page",
            @delegateOnChangePage="changePage"
            @delegateOnChangePageSize="changePageSize")

        // POPUPS

        div 
          Modal(:isOpen="showBatchDetail" :showCloseButton="false")
            template(slot="title")
              .modal-header
                .modal-header-left
                  .transfer
                    .transfer-code {{ $t('Transfer.transferNumber') }} : {{ transferHeader?.code }}
                    .transfer-date {{ $t('Transfer.transferDate') }} : {{ getFormattedDate(transferHeader)}}
                
                .modal-header-right
                  ImportExport.ImportExport(
                    componentName="TransferExpireModalExport"
                    ref="wrapper"
                    :activeImportExports=['export']
                    :downloadCustomPopup="true"
                    @downloadClick="showBatchHeaderExportPopup"
                  ) 
                  Button.m-right-10(
                    secondary,
                    size="small"
                    v-if="BatchList.length"
                    @click="getBatchQuantity",
                    id="transfer-batch-automatic-quantity"
                  ) {{ $t('Transfer.autoExpireDate') }}
            
            template(slot="content")
              EmptyState(
                v-if="!BatchList.length"
                :title="$t('Despatches.emptyState_Title_Batch', { field: $t('Dictionary.Batch') })"
                :description="$t('Global.emptyState_Description_Batch', { field: $t('Dictionary.Batch') })"
              )
              NestedTable.custom-nested-table(
                v-else
                :fields="transferBatchHeaderFields",
                :detailFields="transferBatchDetailFields",
                :data="BatchList",
                :actions="['export']"
                @showExportPopup="showBatchExportPopup"
                @showDetailAction="showBatchDetailAction",
                :usePortalFooter="true"
              )

              portal(to="givenQuantity")
                div(slot-scope="detail")
                  .control.quantity-input
                    input.txt.no-spinners(
                      name="givenQuantity"
                      :data-vv-as="$t('Despatches.detail_TableColumn_Quantity')"
                      :class="{ 'is-danger': veeErrors.has(`transfer${detail.rowIndex}.givenQuantity`) }"
                      v-validate="{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}"
                      type="number"
                      step="any"
                      v-model="detail.row.givenQuantity"
                      @blur="updateGivenBatchQuantity($event, detail.row)"
                    )

              portal(to="unitCode")
                div(slot-scope="detail")
                  span {{ detail.row.unitCode }}

              portal(to="warehouseTime")
                div(slot-scope="detail")
                  span {{ detail.row.warehouseTime?.substr(0,5) || '-' }}
              
              portal(to="shipmentTime")
                div(slot-scope="detail")
                  span {{ detail.row.shipmentTime?.substr(0,5) || '-' }}


            template(slot="footer")
              .batch-footer
                Button.m-right-10(
                  id="transfer-batch-detail-cancel"
                  size="small"
                  secondary
                  inline
                  @click="closeModal")
                  span {{ $t('Global.Cancel') }}
                
                Button(
                  id="transfer-batch-detail-save"
                  size="small"
                  primary
                  inline
                  @click="saveBatch")
                  span {{ $t('Global.Save') }}
        router-view(
          @getList="getTransferListWithParams"
          @showDrafts="showDrafts = true"
        )
        exportPopup(
          v-if="isExportPopupVisible"
          :templateId="templateId"
          :templateType="templateType"
          ref="exportPopupTemplate"
        )

        Alert(
          v-if="showBatchAlert"
          :title="$t('Transfer.warning_batch')"
          :hasCancelButton="false"
          :description="$t('Despatches.description_batch_warning')"
          :okButton="$t('Global.okay')"
          icon="icon-global-notify"
          loading="alertForm"
          @delegateOnAlertOk="showBatchAlert = false"
        )


      

</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import { getTransferHeaderFields, getTransferDetailFields } from './fields/transfer'
import { getTransferBatchHeaderFields, getTransferBatchDetailFields } from './fields/transfer-batch'
import TableFoot from '@/view/global/table/foot'
import miniPopupButton from '@/view/global/mini-popup-button'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import exportPopup from '@/view/global/export-popup'
import store from '@/view/global/business-filters/store'
import settingsExportPopup from '@/mixins/settingsExportPopup'
import ApprovalLevels from '@/view/global/approval-levels'
import ImportExport from '@/view/global/import-export'

export default {
  name: 'Transfer',

  data () {
    return {
      transferHeaderFields: getTransferHeaderFields(this.$t),
      transferDetailFields: getTransferDetailFields(this.$t, this.$getConst),
      transferBatchHeaderFields: getTransferBatchHeaderFields(this.$t),
      transferBatchDetailFields: getTransferBatchDetailFields(this.$t),
      selectedFromStores: [],
      selectedToStores: [],
      dates: [],
      historyListForTransfer: [],
      isOnlyListUsage: false,
      showDrafts: true,
      showBatchDetail: false,
      transferHeader: null,
      transferDetailList: [],
      showBatchQuantityValidateError: false,
      tempBatchQuantity: null,
      showBatchAlert: false,
      selectedList: []
    }
  },
  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getTransferListWithParams()
    }
  },
  mixins: [settingsExportPopup],
  components: {
    TableFoot,
    exportPopup,
    miniPopupButton,
    store,
    ApprovalLevels,
    ImportExport
  },

  computed: {
    ...mapGetters('Transfer', [
      'TransferList',
      'isEmptyTransferList',
      'Page',
      'BatchList'
    ]),

    ...mapGetters('Stores', {
      fromStores: 'miniStoreList',
      toStores: 'sortedStoreList'
    }),

    ...mapGetters('Settings', [
      'storePermissionList',
      'permissions'
    ]),

    ...mapGetters('Auth', ['Tenant']),

    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    isLoading () {
      return this.$wait.is(['get_transfer_list_header', 'getMiniStoreList', 'getStoreList', 'getDateFilterSettings'])
    },
    isHistoryLoading () {
      return this.$wait.is('showHistory')
    },
    isDetailLoading () {
      return this.$wait.is(['get_transfer_info'])
    },
    isFinalize () {
      return this.$wait.is(['transferSavedfinalize', 'transferReject'])
    }
  },

  async mounted () {
    await this.getTenant({id: this.Tenant.id})
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    await this.setShowDraftStatus()
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_Transfer')
    await this.getTransferListWithParams()
    this.getFromStores().then(() => {
      this.fromStoreOnChangeFilter({isChecked: true, item: {all: true}})
    })
    this.getToStores({pageNumber: 1, pageSize: 9999, ignorePermission: true}).then(() => {
      this.toStoreOnChangeFilter({isChecked: true, item: {all: true}})
    })
    if (this.$route.params && this.$route.params.showTransferDetail) {
      this.showDetailAction(this.TransferList[0])
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Transfer', [
      'getTransferList',
      'getTransfer',
      'makeTransferFinalize',
      'checkInventoryForStore',
      'updateTransferDetail',
      'rejectTransferHeader',
      'sendTransferToApprove',
      'showHistory',
      'getTransferBatchData',
      'getTransferBatchDetail',
      'updateBatchQuantity',
      'validateBatchDetails',
      'getTransferBatchQuantities'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapMutations('Global', ['TOGGLE_MENU_MINIMALIZE','SET_SHOW_ACTION_STATUS']),

    ...mapActions('Auth', ['getTenant']),

    ...mapMutations('Transfer', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'SHOW_BATCH_DETAIL',
      'HIDE_BATCH_DETAIL',
      'HIDE_OTHERS_DETAILS',
      'SET_EXPIRATION_DATE_CONTROL'
    ]),

    ...mapActions('Stores', {
      getFromStores: 'getMiniStoreList',
      getToStores: 'getStoreList',
      getStore: 'getStore'
    }),

    isExpireDateControlEnabled (item) {
      const selectedsWithExpireDate = this.selectedList.filter(s => s.expirationDateControlIsEnabled && s.transferHeaderId === item.id)
      return selectedsWithExpireDate.length > 0
    },

    onSelectTransfer (item) {
      if (item.isChecked) this.selectedList.push(item)
      else {
        this.selectedList = this.selectedList.filter(s => s.id !== item.id)
      }
    },

    onSelectTransferAll (detailData, isChecked) {
      if (isChecked) {
        detailData.forEach(detail => {
          this.selectedList.push(detail)
        })
      } else {
        detailData.forEach(detail => {
          const index = this.selectedList.findIndex(s => s.id === detail.id)
          this.selectedList.splice(index, 1)
        })
      }
    },


    getFormattedDate (header) {
      if (header) return moment(header.date).format('DD-MM-YYYY')
    },

    ...mapActions('DocumentSettings', ['getDocumentFilterSettings']),

    getBatchQuantity () {
      this.getTransferBatchQuantities({transferDetailIds: this.transferDetailList}).then(res => {
        if (res) this.getTransferBatchData({transferDetailIds: this.transferDetailList})
      })
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getTransferListWithParams()
    },

    async updateGivenBatchQuantity (event, row) {  
      const payload = {
        id: row.id,
        transferDetailId: row.transferDetailId,
        quantity: Number(event.target.value)
      }
      await this.updateBatchQuantity(payload).then(res => {
        if (res) {
          const message = this.$t('Transfer.updated_transferBatchQuantity')
          this.notifyShow({ message })
          this.getTransferBatchData({transferDetailIds: this.transferDetailList})
          this.getTransferBatchDetail({transferDetailId: row.transferDetailId}).then(result => {
            if (result) this.SHOW_BATCH_DETAIL({batchList: result.data.batchList, id: row.transferDetailId})
          })
        }
      })
    },

    saveBatch () {
      this.validateBatchDetails({transferDetailIds: this.transferDetailList}).then(res => {
        !res.data.isValid ? this.showBatchAlert = true : this.closeModal()
      })
    },
  
    editExpireDate (item) {
      this.transferHeader = item
      this.TOGGLE_MENU_MINIMALIZE(true)
      this.showBatchDetail = true
      const filteredList = this.selectedList.filter(s => s.transferHeaderId === item.id)
      this.transferDetailList = filteredList.map(i => i.id)
      this.getTransferBatchData({transferDetailIds: this.transferDetailList})
    },

    closeModal () {
      this.showBatchDetail = false
    },

    getTransferListWithParams (pageNumber = 1, pageSize = this.Page.size) {
      this.getTransferList({
        pageNumber,
        pageSize,
        fromStoreIds: this.selectedFromStores.map(store => store.id),
        toStoreIds: this.selectedToStores.map(store => store.id),
        startDate: this.dates[0],
        endDate: this.dates[1],
        showDrafts: this.showDrafts
      })
    },

    goToDespatchDetailDocument(item) {
      const routeData = this.$router.resolve({
        name: 'DespatchDocumentDetail', 
        params: { 
          id: item.despatchHeaderId, 
          documentNumber: item.despatchNumber 
        }})
      window.open(routeData.href, '_blank')
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_Transfer') this.showDrafts = i.isChecked
      })
    },

    async sendToApprove (item) {
      const payload = {
        transferHeaderId: item.id
      }
      const res = await this.sendTransferToApprove(payload)
      if (res) {
        const message = this.$t('Request.requestSentToApprove')
        this.notifyShow({ message })
        this.getTransferListWithParams()
      }
    },

    submitFilter () {
      this.getTransferListWithParams()
    },

    async showHistoryPopup (header) {
      const payload = {
        headerId: header.id,
        approvalHistoryType: 2
      }
      const res = await this.showHistory(payload)
      this.historyListForTransfer = res.data.approvalHistoryList
      this.historyListForTransfer.map(i => {
        i.isApproved === true ? i.isApproved = this.$t('Global.approved') : i.isApproved = this.$t('Global.declined')
        i.isOutOfOffice === true ? i.isOutOfOffice = i.isOutOfOffice = this.$t('Global.outOfOffice') : i.isOutOfOffice = this.$t('Global.working')
      })
    },
    getApprovalStatus (status) {
      switch (status) {
        case 0:
          return '-'
        case 1:
          return this.$t('Request.table_Header_field_StatusWaitingForApproval')
        case 2:
          return this.$t('ApproveOperations.approval_Notification_message')
        case 3:
          return this.$t('Global.declined')
      }
    },

    toStoreOnChangeFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.selectedToStores = [...this.toStores]
        } else {
          this.selectedToStores.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.selectedToStores = []
        } else {
          this.selectedToStores.splice(this.selectedToStores.indexOf(event.item), 1)
        }
      }
    },

    fromStoreOnChangeFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.selectedFromStores = [...this.fromStores]
        } else {
          this.selectedFromStores.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.selectedFromStores = []
        } else {
          this.selectedFromStores.splice(this.selectedFromStores.indexOf(event.item), 1)
        }
      }
    },

    async showDetailAction (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
      } else {
        await this.checkExpireDateStatus(item)
        await this.getTransfer(item.id)
      }
    },

    checkExpireDateStatus (data) {
      this.getStore(data.fromStoreId).then(res => {
        if (res) {
          this.SET_EXPIRATION_DATE_CONTROL({transfer: data, expirationDateControl: res.data.store.expirationDateControlIsEnabled})
        }
      })
    },

    async showBatchDetailAction (item) {
      if (item.showDetail) {
        this.HIDE_BATCH_DETAIL(item)
      } else {
        await this.getTransferBatchDetail({transferDetailId: item.id}).then(res => {
          if (res) {
            this.SHOW_BATCH_DETAIL({batchList: res.data.batchList, id: item.id})
          }
        })
      }
    },

    editAction (item) {
      this.$router.push({
        params: {
          id: item.id,
          headerInfo: item
        },
        name: 'EditTransfer'
      })
    },

    removeItemAction (item) {
      this.$router.push({
        params: {
          id: item.id,
          item
        },
        name: 'RemoveTransfer'
      })
    },

    addNewTransfer () {
      this.$router.push({
        name: 'NewTransfer'
      })
    },

    transferDetailNew (transfer) {
      this.HIDE_OTHERS_DETAILS(transfer)
      this.$router.push({
        name: 'NewTransferDetail',
        params: {
          id: transfer.id
        }
      })
    },

    transferDetailEdit (detail) {
      this.$router.push({
        name: 'EditTransferDetail',
        params: {
          id: detail.transferHeaderId,
          detailId: detail.id
        }
      })
    },

    transferSaved: vueWaitLoader(async function (transfer) {
      const stores = [transfer.fromStoreId, transfer.toStoreId]
      const message = this.$t('Transfer.finalize_Notification_message')
      const params = {
        InventoryDate: moment(transfer.transferDate).format('YYYY-MM-DD'),
        SearchType: 2,
        StoreList: stores
      }
      const res = await this.checkInventoryForStore(params)
      if (res) {
        const payload = {
          id: transfer.id,
          startDate: this.dates[0],
          endDate: this.dates[1]
        }
        const finalizeAction = await this.makeTransferFinalize(payload)
        if (finalizeAction) {
          this.notifyShow({ message })
          this.showExportPopup(transfer, true)
        }
      }
    }, 'transferSavedfinalize'),

    transferDetailRemove (detail) {
      this.$router.push({
        name: 'RemoveTransferDetail',
        params: {
          id: detail.transferHeaderId,
          detailId: detail.id
        }
      })
    },
    changePage (page) {
      this.getTransferListWithParams(page)
    },
    changePageSize (pageSize) {
      this.getTransferListWithParams(1, pageSize)
    },
    async updateDetailItem (item) {
      const requestItem = {
        TransferDetailId: item.id,
        quantity: (item.quantity === 0 || !item.quantity) ? 0 : item.quantity,
        unitId: item.unitId
      }
      const res = await this.updateTransferDetail(requestItem)
      const message = this.$t('Transfer.detail_update_Notification_message')
      if (res) {
        this.notifyShow({ message })
        const resList = await this.getTransfer(item.transferHeaderId)
        this.SHOW_DETAIL(resList.data)
      }
    },
    showExportPopup (row, isFinalizeAction = false) {
      this.SET_SHOW_ACTION_STATUS({prevActionValue:row, value: false})
      this.exportFrame(row.id, 9, isFinalizeAction)
    },

    showBatchExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 21, isFinalizeAction)
    },

    showBatchHeaderExportPopup (isFinalizeAction = false) {
      this.exportFrame(this.transferHeader.id, 24, isFinalizeAction)
    },

    transferReject: vueWaitLoader(async function (rowData) {
      const payload = {
        id: rowData.id,
        rejectionReason: rowData.reasonDescription
      }
      await this.rejectTransferHeader(payload)
        .then(response => {
          if (!response) return
          const message = this.$t('Transfer.header_rejected_Notification_message')
          this.notifyShow({ message })
          this.getTransferListWithParams()
        })
    }, 'transferReject'),
    checkStorePermission (listData) {
      return this.storePermissionList.some(item => {
        return item.storeId === listData.toStoreId
      })
    }
  }
}
</script>

<style lang="scss" scoped>

:deep() .NestedTable-head-col.approvalStatus{
  margin-left: 20px !important;

}

:deep() .NestedTable-row-col.approvalStatus{
  margin-left: 20px !important;

}

:deep() .icon-menu-transfer {
  width: 20px;
  height: 20px;
}
.code {
  display: flex;
  position: relative;
  Button {
    position: absolute;
    right: -37px;
    top: -10px;
    padding: none !important;
  }
}
:deep(.Modal) {
  &-Body {
    z-index: $z-index-xxxl !important;
    &-content {
      width: 80vw !important;
    }
  }
  &-Overlay[data-v-b9dcd536] {
    z-index: $z-index-xxl !important;
  }
}
:deep(.Header) {
  z-index: 400 !important
}

.validateError {
  position: absolute;
  top: 10px;
}

.transfer {
  display: flex;
  flex-direction: column;
}
:deep(.custom-nested-table) {
  .NestedTable {
    &-head, &-row {
      grid-template-columns: repeat(8, 1fr) 35px !important;
    }
  }
}
.batch-footer {
  display: flex;
  justify-content: end;
}
:deep(.quantity-input .txt) {
  text-align: right !important;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  &-right {
    display: flex;
    align-items: center;
    .ImportExport {
      margin-right: 10px;
    }
  }
}
:deep([data-v-0363559a] .EmptyState) {
  height: unset !important;
}

:deep(.NestedDetail-row) {
  grid-template-columns: repeat(14, 1fr) 120px !important;
}


</style>

