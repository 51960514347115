<template lang="pug">
Page
  template(slot="header-left-dropdown")
    div

  template(slot="header-left-search")
    Search.search-box(
      componentName="HelpCenter",
      :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
      :customSuggestionTitle="$t('Global.search_SuggestionTitle')"
      @onChangeSearchValue="onChangeSearch"
      @clearSearch="clearSearch"
    )
  template(slot="content-center")
    TableLoading(v-if="isPageLoading")
    .help-center(v-else)
      .help-center-container
        .row
          .row-left
            .page-list.p-left-10
              Accordion
                .accordion-list(v-for="mainCategory in listItems" :key="mainCategory.id")
                  AccordionItem.m-top-15(
                    v-if="mainCategory.helpSubCategoryList.length > 0"
                    :title="mainCategory.name"
                    :isActive="selectedCategory.id === mainCategory.id"
                  )
                    template(slot="accordion-body")
                      ul.list-accordion
                        li.list-accordion-item(
                          v-for="subCategory in mainCategory.helpSubCategoryList"
                          :key="subCategory.id"
                          @click="getDocuments(subCategory)"
                        )
                          span.data-link(
                            :class="[subCategory.id === selectedSubCategory.id  ? 'active' : '']"
                          ) {{subCategory.name}}
          .row-right
            TableLoading(v-if="isDataFetching")
            template(v-else)
              EmptyState(
                v-if="!isDataFetching && isEmptyDocumentList",
                :title="$t('Global.helpCenter_emptyState_NotFound_Title')",
                :description="$t('Global.helpCenter_emptyState_NotFound_Description')")
              Accordion(v-if="!isEmptyDocumentList")
                AccordionItem.accordion-desc(
                  v-for="document in allDocumentList"
                  :key="document.id"
                  :title="document.subject"
                )
                  template(slot="accordion-body")
                    p(v-html="document.description")
</template>

<script>
import Accordion from '@/view/global/accordion'
import AccordionItem from '@/view/global/accordion/components/accordion-item'
import matchingSearch from '@/utils/matchingSearch'
import PageTypeList from '@/constant/page-list'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HelpCenter',

  components: {
    Accordion,
    AccordionItem
  },

  data () {
    return {
      mainCategories: [],
      subCategories: [],
      listItems: [],
      currentRouteData: {},
      selectedCategory: {
        id: null
      },
      selectedSubCategory: {
        id: null
      }
    }
  },

  methods: {
    ...mapActions('HelpCenter', ['getAllCategories', 'getAllDocuments']),

    ...mapActions('Settings', ['fetchUser']),

    onChangeSearch (search) {
      let deepValue = JSON.parse(JSON.stringify(this.allCategoryList))
      deepValue.map(category => {
        if (category.helpSubCategoryList.length > 0) {
          category.helpSubCategoryList = category.helpSubCategoryList.filter(item => {
            return matchingSearch((item.name), search)
          })
        }
      })
      this.listItems = deepValue
    },

    checkAccordionOpen (data) {
      if (Object.keys(this.currentRouteData).length === 0) return false
      return data.helpSubCategoryList.some(el => el.pageType === this.currentRouteData.value)
    },

    checkRouteData () {
      this.currentRouteData = PageTypeList.find(i => i.name === this.$route.params.routeName) || {}
      if (Object.keys(this.currentRouteData).length === 0) return

      const category = this.allCategoryList.find(i => {
        const subCategory = i.helpSubCategoryList.find(el => el.pageType === this.currentRouteData.value)
        if (subCategory) this.selectedSubCategory = subCategory
        return subCategory
      })

      if (category) {
        this.selectedCategory = category
        this.getDocuments(this.selectedSubCategory)
      }
    },

    clearSearch () {
      this.onChangeSearch('')
    },

    async getCategories () {
      await this.getAllCategories()
      if (this.allCategoryList) {
        this.listItems = JSON.parse(JSON.stringify(this.allCategoryList))
      }
    },

    async getDocuments (subCategory) {
      if (this.selectedSubCategory.id === subCategory.id) return
      this.selectedSubCategory = subCategory
      const payload = {
        HelpSubCategoryId: subCategory.id,
        LanguageId: this.userLanguage
      }
      this.getAllDocuments(payload)
    }
  },

  async mounted () {
    this.$wait.start('pageLoading')
    await this.fetchUser()
    await this.getCategories()
    if (this.$route.params && this.$route.params.routeName) this.checkRouteData()
    this.$wait.end('pageLoading')
  },

  computed: {
    ...mapGetters('HelpCenter', ['allCategoryList', 'allDocumentList', 'isEmptyDocumentList']),

    ...mapGetters('Settings', [
      'userLanguage'
    ]),

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    isDataFetching () {
      return this.$wait.is('getAllDocuments')
    }
  }

}
</script>

<style lang="scss" scoped>

  .help-center {
    &-container {
      width: 100%;
      min-height: 75vh;
      padding: 30px 50px 30px 20px;
      background-color: $color-white;
      border-radius: $border-radius;

      .row {
        display: flex;
        flex-wrap: wrap;

        &-left {
          flex: 0 0 33.3333333%;
          max-width: 33.3333333%;
          padding-right: 20px;
          border-right: 1px solid $color-gray;
          .search {
            &-box {
              border-left: 0!important;
              margin-left: 0;
            }
          }

          .list-accordion {
            padding: 10px 15px;
            background-color: $color-light-gray;
            &-item {
              &:not(:first-child) {
                margin-top: 10px;
              }
              color: $color-light;
              &:before {
                content: '-';
                margin-right: 5px;
              }
              .data-link {
                cursor: pointer;
                &.active {
                  color: $color-primary;
                  border-bottom: 1px solid $color-primary;
                }
              }
            }
          }
        }

        &-right {
          flex: 0 0 66.6666667%;
          max-width: 66.6666667%;
          padding-left: 40px;

          .accordion-desc {
            &:not(:first-child) {
              margin-top: 20px;
            }
          }

          .helpful {
            display: flex;
            align-items: center;
            padding-left: 20px;
            border-top: 1px solid $color-gray;
            &-button {
              width: 70px;
              min-width: 70px;
            }
          }
        }
      }
    }

  }
</style>
