<template lang="pug">
Page
  template(v-show="InventoryInfo.status === 1" slot="header-left-search")
    Search.search-box(
      componentName="Inventory",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-breadcrumb-left")
    .Breadcrumb.tooltip-relative
      router-link.c-light(
      id="link-inventory-breadcrumb"
      :to="{ name: 'Inventories' }")
        | {{ $t('Inventory.breadCrumb_Inventory') }}
      span {{ ">" }}
      a(href="" id="link-inventory-breadcrumb-active").isSelected {{ InventoryInfo.status === 1 ? getName(inventoryBreadCrumb) : inventoryBreadCrumb }}
      Tooltip(
        v-if="inventoryBreadCrumb.length > 50 && InventoryInfo.status === 1"
        white
        bottom
        :text="inventoryBreadCrumb"
      )
    span.inventory-type.c-light {{ inventory_Header?.type == 1 ? $t('Inventories.inventoryTypePeriodic') : $t('Inventories.inventoryTypeIntermediate') }}
  
  template(v-if="InventoryInfo.status === 1" slot="header-right-dropdown" )
    Button(
      v-if="hasPermissionToCloseInventory"
      size="medium"
      primary
      type="button"
      id="btn-inventory-close"
      @click="close(inventory_Detail)"
    )
      span {{ $t('Inventory.closeInventoryButton') }}

  template(v-if="InventoryInfo.status === 1" slot="header-right-content" )
    Button(
      v-if="!isStoreTypeCostCenter"
      :disabled="isOnlyListUsage"
      size="medium"
      secondary
      type="button"
      id="btn-add-stock-item"
      @click="addStockItem(inventory_Detail)"
    )
      span {{ $t('Inventory.addStockItemButton') }}


  template(v-if="InventoryInfo.status == 1" slot="toolbar-left" )
    router-link.tabLink.isSelected#toggle-group-0(v-if="!isOnlyListUsage" :to="{name: 'Inventory', params: { id: $route.params.id, status: $route.params.status, storeName: $route.params.storeName, inventoryDate: $route.params.inventoryDate }}") {{ $t('Inventory.view_mode_all') }}
    router-link.tabLink#toggle-group-1(:to="{ name: 'Inventorylists',  params: { id: $route.params.id, status: $route.params.status, storeName: $route.params.storeName, inventoryDate: $route.params.inventoryDate }}") {{ $t('Inventory.view_mode_list') }}
    router-link.tabLink#toggle-group-2(v-if="!isOnlyListUsage" :to="{ name: 'InventoryRecipe', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_recipe') }}
    router-link.tabLink#toggle-group-3(v-if="!isOnlyListUsage" :to="{ name: 'InventoryShelf', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_shelf') }}
    router-link.tabLink#toggle-group-4(v-if="!isOnlyListUsage && inventory_Header?.expirationDateControlIsEnabled" :to="{ name: 'InventoryExpireDate', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_expire_date') }}

  template(v-if="InventoryInfo.status == 1" slot="toolbar-right" )
    ImportExport(
        ref="wrapper"
        componentName="Inventory"
        :isDisabled="isLoading"
        :activeImportExports=['export','template']
        :templateUrl="downloadExcelUrl"
        :templateFileName="$t('Inventory.downloadTemplate')"
        :templateDisabled="!InventoryInfo.status === 1"
        templateFileType="xlsx"
        templateMethod = "GET"
        :downloadUrl="downloadUrl"
        :downloadDisabled="!(InventoryInfo.status === 1 && !isStoreTypeCostCenter)"
        downloadFileType="xlsx"
        :downloadParams="downloadParams"
        :downloadFileName="inventoryBreadCrumb"
        :massecInventoryInfo="InventoryInfo",
        massecPage="inventory"
    )
    ImportMassec.m-left-10(
      v-if="!isStoreTypeCostCenter"
      :inventoryInfo="InventoryInfo",
      page="inventory"
    )

  template(v-if="InventoryInfo.status !== 1" slot="no-content" )
    ClosedInventory(
      @itemSorting = 'sortItem'
      @includeWasteInConsumption = 'includeWasteInConsumption'
    )

  template(v-else slot="content-center" )
    TableLoading(v-if="isLoading")

    template(v-if="!isEmpty && !isLoading")
      .InventoryDetailList(v-if="inventory_Detail.list.length > 0")
        .InventoryDetailList-table#table-inventory-detail
          .head#table-inventory-detail-head
            .row#table-inventory-detail-head-row
              .col#table-inventory-detail-head-col-0.largeCol.sortable-title  {{ $t('Inventory.detailList_TableColumn_ItemGroupName') }}
                ToggleSorting.p-left-5(
                  :item="sortingItems[0]"
                  @sort="sort"
                )
              .col#table-inventory-detail-head-col-1.largeCol.sortable-title {{ $t('Inventory.detailList_TableColumn_StockItemName') }}
                ToggleSorting.p-left-5(
                  :item="sortingItems[1]"
                  @sort="sort"
                )
              .col.u-textRight#table-inventory-detail-head-col-1 {{ $t('Inventory.detailList_TableColumn_ExpectedQuantity') }}
              .col.u-textRight#table-inventory-detail-head-col-2 {{ $t('Inventory.detailList_TableColumn_Unit') }}
              .col.u-textRight#table-inventory-detail-head-col-3 {{ $t('Inventory.detailList_TableColumn_UnitQuantityInput') }}
              .col.u-textRight#table-inventory-detail-head-col-4 {{ $t('Inventory.detailList_TableColumn_UnitQuantity') }}
              .col.u-textRight#table-inventory-detail-head-col-5 {{ $t('Inventory.detailList_TableColumn_ActualQuantityTotal') }}
              .col.u-textRight#table-inventory-detail-head-col-6 {{ $t('Inventory.detailList_TableColumn_ActualQuantityDifference') }}
              .col.u-textRight#table-inventory-detail-head-col-7 {{ $t('Inventory.detailList_TableColumn_ActualQuantityDifferenceRatio') }}
          .body#table-inventory-detail-body
            row(
            v-for="(detail, index) in inventory_Detail.list",
            v-if="inventory_Detail.list.length > 0",
            :id="`table-inventory-detail-body-row-${index}`"
            :key="detail.stockItemName",
            :detail="detail"
            :index="index"
            @changeUnitCount="onChangeDetailUnitCount")

        TableFoot(
          :page="inventoryPage",
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize")

    router-view(
      @getList="getInventory",
      @successImportAction="getInventory"
    )

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//
import Popup from './popup'
import row from './inventory-detail'
import ClosedInventory from './inventory-closed'
import TableFoot from '@/view/global/table/foot.vue'
import moment from 'moment'
import ImportMassec from './components/import-massec'
import ToggleSorting from '@/view/global/toggle-sorting'
import ImportExport from '@/view/global/import-export'

export default {
  name: 'Inventory',

  components: {
    Popup,
    row,
    ClosedInventory,
    ImportExport,
    ImportMassec,
    TableFoot,
    ToggleSorting,
  },

  data () {
    return {
      popup: {
        status: false
      },
      search: '',
      sortingItems: [
        { columnName: 'itemGroupName', sortType: null },
        { columnName: 'stockItemName', sortType: null }
      ],
      sortingItemList: [],
      isOnlyListUsage: false
    }
  },
  async mounted () {
    if (this.checkPermission('ListOnlyUsage')) {
      this.$router.push({
        name: 'Inventorylists',
        params: {
          id: this.$route.params.id,
          status: this.$route.params.status,
          storeName: this.$route.params.storeName,
          inventoryType: this.$route.params.inventoryType,
          inventoryDate: this.$route.params.inventoryDate
        }
      })
    }
    await this.getInventory()
  },

  computed: {
    ...mapGetters('Inventories', [
      'inventory_Detail',
      'inventory_IsEmpty',
      'inventory_Header',
      'inventoryPage'
    ]),
    ...mapGetters('Settings', ['permissions', 'checkPermission']),

    hasPermissionToCloseInventory () {
      return this.checkPermission('CloseInventory')
    },

    InventoryInfo () {
      return {
        ...this.$route.params,
        status: parseInt(this.$route.params.status)
      }
    },

    isEmpty () {
      return this.inventory_IsEmpty
    },

    isLoading () {
      return this.$wait.is(['get_INVENTORY'])
    },

    inventoryBreadCrumb () {
      return this.InventoryInfo.storeName + '-' + moment(this.InventoryInfo.inventoryDate).format('DD/MM/YYYY')
    },

    isCreating () {
      return (
        this.$wait.is(['create_RECIPE_DETAIL_STOCK']) ||
          this.$wait.is(['create_RECIPE_DETAIL_RECIPE'])
      )
    },
    downloadUrl () {
      return 'Inventory/inventorycount/export'
    },
    downloadExcelUrl () {
      return 'Inventory/inventorycount/template/export'
    },
    downloadParams () {
      return {
        inventoryHeaderId: this.InventoryInfo.id,
        text: this.search || null,
        sortExpressionList: this.sortingItemList
      }
    },

    isClosedInventory () {
      return this.InventoryInfo.status !== 1
    },

    isStoreTypeCostCenter () {
      return this.inventory_Header ? this.inventory_Header.storeType === 3 : false
    }
  },

  methods: {
    ...mapActions('Inventories', [
      'get_INVENTORY',
      'close_INVENTORY',
      'create_INVENTORY_UNIT_COUNT',
      'update_INVENTORY_UNIT_COUNT'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    sortItem (item) {
      this.sortingItemList = item
      this.getInventory()
    },

    includeWasteInConsumption (includeWasteInConsumption) {
      this.getInventory(1, this.inventoryPage.size, includeWasteInConsumption)
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) return
      this.getInventory()
    },

    async changePage (page) {
      await this.getInventory(page)
    },

    async changePageSize (pageSize) {
      await this.getInventory(1, pageSize)
    },

    sort (item) {
      switch (item.sortType) {
        case null:
          item.sortType = 0
          this.sortingItemList.push(item)
          break
        case 0:
          item.sortType++
          break
        case 1:
          item.sortType = null
          this.sortingItemList.splice(this.sortingItemList.indexOf(item), 1)
          break
      }
      this.getInventory()
    },

    getInventory (page, pageSize = this.inventoryPage.size, includeWasteInConsumption = false) {
      const params = {
        inventoryHeaderId: this.InventoryInfo.id,
        text: this.search,
        pageNumber: this.isClosedInventory ? 0 : page || 1,
        pageSize,
        sortExpressionList: this.sortingItemList,
        includeWasteInConsumption
      }
      this.get_INVENTORY(params)
    },

    onChangeDetailUnitCount (options) {
      const {
        stockItemId,
        unitId,
        inventoryCountId,
        quantity
      } = options

      if (inventoryCountId) {
        // PUT
        const params = {
          stockItemId,
          unitId,
          params: {
            id: inventoryCountId,
            unitQuantity: quantity
          }
        }
        this.update_INVENTORY_UNIT_COUNT(params).then(result => {
          const message = this.$t('Inventory.inventoryCount_update_successfully')
          if (result) this.notifyShow({ message })
        })
      } else {
        // POST
        const params = {
          stockItemId,
          unitId,
          params: {
            inventoryHeaderId: this.inventory_Detail.id,
            stockItemId: stockItemId,
            unitId: unitId,
            actualQuantity: quantity
          }
        }
        this.create_INVENTORY_UNIT_COUNT(params).then(res => {
          const message = this.$t('Inventory.inventoryCount_created_successfully')
          if (res) this.notifyShow({ message })
        })
      }
    },

    addStockItem (inventoryDetail) {
      this.$router.push({
        name: 'InventoryAddStockItem',
        params: {
          id: inventoryDetail.id
        }
      })
    },

    close (inventoryDetail) {
      this.$router.push({
        name: 'InventoryDetailClose',
        params: {
          id: inventoryDetail.id,
          inventoryClosingMethod: inventoryDetail.inventoryClosingMethod
        }
      })
    },
    getName (name) {
      return (name && name.length > 50 ) ? name.substr(0, 50).concat('...') : name
    }
  }
}
</script>

<style lang="scss" scoped>
  .Search{
    position: relative;
    & .icon-search{
      width: 48px;
      color: $color-light;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    & .txt{
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .inventory-type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: $font-size-small;
  }
  .sortable-title {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  :deep() .report-normal-button {
    box-shadow: 0px 0px 0px 1px $color-gray !important;
    &:hover {
      box-shadow: 0px 0px 0px 1px $color-success-dark !important;
    }
  }
</style>

<style lang="scss">

  .largeCol{
    min-width: 255px;
    white-space: normal !important;
    text-overflow: clip !important;
  }
  .Submenu .right{
    display: flex;
    align-items: center;
  }
  
  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  .toolbar {
    .tabLink {
      padding: 11px 27px;
      font-weight: $font-weight-normal;
      margin-right: 10px;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      user-select: none;
      background: $color-white;
      &.isSelected {
        color: $color-primary;
        font-weight: $font-weight-bold;
        border-color: $color-primary;
      }
    }
  }

  .InventoryDetailList {

    &-baslik {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 5px;
        color: $color-primary;
      }
    }

    &-table {
      height: 70vh;
      overflow-y: scroll;
      .row {
        display: flex;
        align-items: center;
        border-radius: $border-radius;
        border: 1px solid $color-gray;
      }
      .col {
        flex: 1;
        font-size: $font-size-small;
      }

      // head ------------------

      .head {
        font-size: $font-size-micro;
        font-weight: $font-weight-bold;
        color: $color-success;
        z-index: 400;
        position: sticky;
        top: 0;

        .row {
          background-color: $color-lighter-blue;
        }
        .col {
          padding: 20px 15px;
        }
        .col.unitCount {
          flex: 2;
        }
      }

      // body ------------------

      .body {
        .row {
          font-size: $font-size-small;
          background: $color-snow;
          &:nth-child(odd) {
            background-color: $color-white;
          }
          &:focus-within {
            border: 2px dashed $color-primary;
            background: $color-ocean;
          }
          &:hover:not(:focus-within) {
            border: 1px solid $color-primary;
            background: $color-ocean;
            box-shadow: rgba($color-ocean, 0.28)
          }
        }

        .col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 15px !important;
          padding-right: 0px !important;
        }

        .action {
          display: flex;
          justify-content: flex-end;

          .sil {
            margin-left: 10px;
          }
        }
      }
    }
  }
</style>
