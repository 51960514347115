<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
      template(slot="linkExtra", slot-scope="link")
        Icon.icon-suspended-status.m-right-5(
        v-if="suspendSalesExist && link.linkName === 'SuspendedSales'",
        name="icon-row-status")
  template(slot="header-left-search")
      Search.search-box(
      componentName="MenuItems",
      :isSuggestionLoading="isLoadingFilterMenuItems",
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-right-dropdown")
    .submenu-right
      businessMultiselect.m-right-10(
        componentName="menu-items-group-filter"
        ref="permittedStoreGroupFilter"
        :label="$t('Global.authorizedStoreGroups')"
        :popupHeader="$t('MenuItems.permittedStoreGroups')"
        :multiselectItems="PermittedStoreGroupList"
        @submitFilter="saveStoreGroupList"
      )
  template(slot="header-right-content")
      Button(
        id="btn-menu-items-import"
        primary
        size="medium"
        :disabled="isLoadingImportMenuItems || isLoadingImport || !isImportButtonActive"
        @click="onClickImportMenuItems"
        iconName="icon-add-circle",
        iconClass="icon-add-circle"
      )
        span {{ $t('MenuItems.importMenuItemsButton') }}
        Loading(theme="disable", v-show="isLoadingImport")
  template(slot="toolbar-left" v-show="shouldShowToolbar")
    RVCFilter(
      @submitFilter="updateRVCFilterItems"
      :showPassives="false"
      ref="rvcFilter")

    businessMultiselect(
      componentName="imported-menu-items-group-filter"
      :label="$t('MenuItems.toolbar_FilterGroup')"
      :popupHeader="$t('MenuItems.toolbar_filterGroup_PopupHeader')"
      :multiselectItems="groupList.list"
      ref="importedGroupItems"
      @submitFilter="submitFilteredMenuItems")

    storeGroup.m-right-10(
      @submitFilter="submitFilteredStoreGroup"
      ref="storeGroupFilter"
    )

    Button.clear-filter-button.border-gray-1.p-0.width-auto.m-right-10(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")

    TotalViewList(:totalCountArray="totalCountData")
    
    CustomCheckbox(
    id="checkbox-sales-show-condiments"
    :checked="showCondiments"
    :label="$t('MenuItems.show_Condiment_Label')"
    @change="changeShowCondiments")
  template(slot="toolbar-right")
    Button.m-right-10(
      @click="init"
      id="get-sales-with-button",
      :isLoading="isLoadingImportMenuItems"
      :disabled="isLoadingImportMenuItems"
      primary
      size="small"
    )
      span  {{ $t('MenuItems.getMenuItems') }}
    
    Button(
      primary
      size="small"
      variant="match-items-button"
      id="btn-menu-items-match"
      type="button"
      iconName="icon-btn-success"
      iconClass="icon-btn-success"
      v-if="shouldShowMatchMenuItemsButton && isDataChecked"
      :disabled="selectedMenuItems.length === 0"
      @click="onClickMatchItemsButton"
    )
      span {{ $t('MenuItems.matchItemsButton') }}
  


  template(slot="content-center")
    TableLoading(v-if="isLoadingImportMenuItems")
    // EMPTY PAGE
    .div(v-if="!isLoadingImportMenuItems")
      EmptyState(
      v-if="shouldShowNotFound || shouldShowEmptyState || !isDataChecked"
      :title="isDataChecked ? $t('MenuItems.notFoundTitle') : $t('MenuItems.makeSelection')"
      :description="isDataChecked ? $t('MenuItems.notFoundDescription') : $t('MenuItems.selectGet_SuspendContinue')")

    // LIST

    TableView(
    v-if="!isImportedMenuItemsEmpty && !isLoadingImportMenuItems && isDataChecked"
    :fields="fields"
    :componentName="'MenuItemsTable'"
    :readOnly="true"
    :pointer="true"
    :data="importedMenuItems.list"
    :selectable="'checkbox'"
    :selectableAll="'checkbox'"
    :selectedRows="selectedMenuItems"
    :page="pageMenuItems"
    @delegateOnItemSelect="onSelectMenuItem"
    @delegateOnItemShow="onSelectMenuItem"
    @delegateOnChangePage="changePage"
    @delegateOnChangePageSize="changePageSize"
    @showAllSelectedItems="onSelectMenuItemAll"
    )


  template(slot="footer")
    .footer
      Button.footer-right(
        variant="match-items-button"
        primary
        size="medium"
        id="btn-menu-items-match-footer"
        v-if="shouldShowMatchMenuItemsButton && isDataChecked"
        type="button"
        :disabled="selectedMenuItems.length === 0"
        iconName="icon-btn-success"
        iconClass="icon-btn-success"
        @click="onClickMatchItemsButton"
      )
        span {{ $t('MenuItems.matchItemsButton') }}
    // POPUPS
    match-popup(
    v-if="isMatchPopupOpen"
    :data="selectedMenuItemsDecorated"
    @close="closeMatchPopup"
    @submit="submitMatchPopup")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//
import MatchPopup from './popup/match'
import getFields from './fields'
// import GelirAktarimi from '@/view/pages/MenuItems/gelir-aktarimi'
import RVCFilter from '@/view/global/business-filters/rvc'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'
import storeGroup from '@/view/global/business-filters/storeGroup'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'MenuItems',

  components: {
    RVCFilter,
    MatchPopup,
    // GelirAktarimi,
    businessMultiselect,
    storeGroup
  },

  async mounted () {
    await this.getPermittedStoreGroups()
  },

  data () {
    return {
      fields: getFields(this.$t),
      selectedMenuItems: [],
      filters: {
        rvc: [],
        group: [],
        storeGroupIds: []
      },
      search: null,
      storeGroupFilter: [],
      isMatchPopupOpen: false,
      showCondiments: true,
      isImportButtonActive: false,
      isDataChecked: false
    }
  },

  computed: {
    ...mapGetters('Global', [
      'suspendSalesExist'
    ]),

    ...mapGetters('MenuItems', [
      'importedMenuItems',
      'isImportedMenuItemsEmpty',
      'groupList',
      'pageMenuItems',
      'pageHeaderLinks',
      'PermittedStoreGroupList'
    ]),

    ...mapGetters('StoreGroups', [
      'storeGroupList'
    ]),

    totalCountData () {
      return [
        {
          totalCountLabel: this.$t('MenuItems.toolbar_totalMenuItemsCount'),
          totalCountValue: this.pageMenuItems.total,
          extraClass: 'with-bg'
        }
      ]
    },

    isLoadingImportMenuItems () {
      return (
        this.$wait.is(['importMenuItems', 'filterImportedMenuItems'])
      )
    },

    isLoadingFilterMenuItems () {
      return this.$wait.is(['filterImportedMenuItems'])
    },

    selectedMenuItemsDecorated () {
      return this.selectedMenuItems.map(menuItem => ({
        id: menuItem.id,
        name: menuItem.name,
        group: menuItem.menuItemGroupName,
        rvc: menuItem.revenueCenterName
      }))
    },

    shouldShowToolbar () {
      return (
        !this.isImportedMenuItemsEmpty ||
            this.filters.rvc.length > 0 ||
            this.filters.group.length > 0
      )
    },

    shouldShowEmptyState () {
      return (
        this.isDataChecked &&
          this.isImportedMenuItemsEmpty &&
          !this.isLoadingImportMenuItems &&
          this.filters.rvc.length === 0 &&
          this.filters.group.length === 0
      )
    },

    shouldShowNotFound () {
      return this.shouldShowToolbar && this.isImportedMenuItemsEmpty && this.isDataChecked
    },

    shouldShowMatchMenuItemsButton () {
      return !this.isImportedMenuItemsEmpty && !this.isLoadingImportMenuItems
    },

    isLoadingImport () {
      return this.$wait.is(['onClickImportMenuItems'])
    }
  },

  methods: {
    ...mapActions('Global', [
      'checkSuspendSalesExist'
    ]),

    ...mapActions('MenuItems', [
      'importMenuItems',
      'filterImportedMenuItems',
      'getRVCList',
      'getGroupList',
      'getPermittedStoreGroups'
    ]),

    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),

    changeShowCondiments () {
      this.showCondiments = !this.showCondiments
      this.submitFilter()
    },

    changePage (page) {
      this.selectedMenuItems = []
      this.submitFilter(page)
    },

    changePageSize (pageSize) {
      this.selectedMenuItems = []
      this.submitFilter(1, pageSize)
    },

    saveStoreGroupList ({ list, dontSubmit }) {
      this.filters.storeGroupIds = list
      if (this.filters.storeGroupIds.length > 0) this.isImportButtonActive = true
    },

    async init () {
      const loadingName = 'filterImportedMenuItems'
      this.isDataChecked = true
      this.$wait.start(loadingName)
      await this.getGroupList().then(() => { this.filters.group = [...this.groupList.list] })
      await this.submitFilter(1)
      this.$wait.end(loadingName)
    },

    initialData () {
      this.selectedMenuItems = []
      this.submitFilter(1)
    },

    closeMatchPopup (whichOne) {
      this.isMatchPopupOpen = false
      if (whichOne !== 'closePopup') this.initialData()
    },

    submitMatchPopup () {
      this.selectedMenuItems = []
      this.closeMatchPopup()
    },

    clearFilter () {
      this.$refs.importedGroupItems.clearAllFilter()
      this.$refs.storeGroupFilter.clearStoreGroupFilter()
      this.$refs.rvcFilter.clearRVCFilter(false)
    },

    onClickMatchItemsButton () {
      this.isMatchPopupOpen = true
    },

    onClickImportMenuItems: vueWaitLoader(async function () {
      const payload = {
        storeGroupIds: this.filters.storeGroupIds.map(i => i.id)
      }
      await this.importMenuItems(payload)
      this.isImportButtonActive = false
      this.init()
    }, 'onClickImportMenuItems'),

    onSelectMenuItem (item) {
      const index = this.selectedMenuItems.indexOf(item)
      if (index > -1) {
        this.selectedMenuItems.splice(index, 1)
      } else {
        this.selectedMenuItems.push(item)
      }
    },

    onSelectMenuItemAll (isChecked) {
      this.selectedMenuItems = []
      if (isChecked) this.selectedMenuItems.push(...this.importedMenuItems.list)
    },

    updateRVCFilterItems ({ list, dontSubmit }) {
      this.filters.rvc = list
      if (!dontSubmit) this.submitFilter()
    },

    async submitFilter (page = 1, pageSize = this.pageMenuItems.size) {
      this.selectedMenuItems = []
      await this.filterImportedMenuItems({
        rvc: this.filters.rvc,
        group: this.filters.group,
        storeGroups: this.storeGroupFilter,
        showCondiments: this.showCondiments,
        storeGroupIds: this.filters.storeGroupIds,
        text: this.search,
        page: page,
        pageSize
      })
    },

    submitFilteredMenuItems ({ list, dontSubmit }) {
      this.filters.group = list
      if (!dontSubmit) this.submitFilter()
    },

    submitFilteredStoreGroup ({ list, dontSubmit }) {
      this.storeGroupFilter = list
      if (!dontSubmit) this.submitFilter()
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) {
        return
      }
      this.initialData()
    }
  }
}
</script>

<style lang="scss" scoped>

  .toolbar-filters{
    align-items: center;
  }

  .submenu-right {
    display: flex;
    align-items: center;
  }

  .match-items-button {
    padding-left: 40px;
    padding-right: 40px;
  }

  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    &-right {
      margin-right: 40px;
    }
  }
</style>
