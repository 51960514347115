<template lang="pug">
.stock(:id="`stock`")
  .stock-top
    .title
      h4.supplier-title(:id="`stock-title`") {{ $t('Dashboard.onHandTitle') }}
  .stock-main
    .stock-amount(
      v-for="(stocks, index) in OnHand"
      :id="`stock-amount-${index}`"
    )
      .amount-left
        .amount-icons
          Icon(:name="stocks.iconName || 'simpra-logo-dark'")
        p.amount-text(:id="`stock-amount-text-${index}`") {{ stocks.overGroupName }}
      .amount-right
        p.amount-text(:id="`stock-amount-currency-${index}`") {{ stocks.amount | formatCurrency2Digits }}
    .stock-amount
      .amount-left
        .amount-icons.simpra
          Icon(name="simpra-logo-dark")
        p.amount-text-bottom(:id="`stock-total-amount-text`") {{ $t('Dashboard.onHandTotalTitle') }}
      .amount-right
        p.amount-text-bottom-success(:id="`stock-total-amount`") {{ totalStockAmount | formatCurrency2Digits }}
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'stock',
  computed: {
    ...mapGetters('Dashboard', [
      'OnHand'
    ]),
    totalStockAmount () {
      let sumStockAmount = 0
      this.OnHand.forEach(stock => {
        sumStockAmount += stock.amount
      })
      return this.isEmptyData ? 0 : sumStockAmount
    },
    isEmptyData () {
      return this.OnHand.length === 0
    }
  }
}
</script>

<style scoped lang="scss">

.stock {
  &-top {
    .title {
      font-size: $font-size-big;
      color: $color-sidebar-dark;
    }
  }
  &-main {
    margin-top: 22px;
    .stock-amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: solid 1px $color-gray;
      background-color: $color-light-gray;
      border-radius: $border-radius;
      padding: 10px 30px;
      margin-top: 10px;
      @media (max-width: 600px) {
        padding: 17px 10px;
      }
      .amount-left {
        display: flex;
        align-items: center;
        .amount-icons {
          background: $color-white;
          border: 1px solid $color-gray;
          border-radius: $border-circle-radius;
          padding: 6px;
          .icon {
            height: 20px;
          }
          .simpra-logo-dark {
            width: 20px;
            height: 20px;
            margin: -3px 3px 0 3px;
          }
        }
      }
      .amount-text {
        font-size: $font-size-small;
        color: $color-op-gray;
        margin-left: 12px;
      }
      .amount-text-bottom {
        margin-left: 12px;
        &-success {
          color: $color-success;
          font-size: $font-size-big;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}
</style>
