var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search",
            attrs: {
              componentName: "StoreGroup",
              isSuggestionLoading: false,
              customSuggestionPlaceHolder: _vm.$t(
                "StoreGroups.search_Placeholder"
              ),
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("pageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isStoreGroupsEmpty && !_vm.isLoading,
                  expression: "!isStoreGroupsEmpty && !isLoading",
                },
              ],
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-store-groups-add",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.itemNew },
            },
            [
              _c("span", { attrs: { id: "btn-store-groups-add-title" } }, [
                _vm._v(_vm._s(_vm.$t("StoreGroups.addStoreGroupButton"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ViewType", {
            staticClass: "view-type",
            attrs: {
              firstNodeIcon: "icon-view-type-list",
              secondNodeIcon: "icon-view-type-group",
              firstSelected: _vm.viewTypeList,
            },
            on: {
              firstNodeClick: function ($event) {
                return _vm.listViewType(true)
              },
              secondNodeClick: function ($event) {
                return _vm.listViewType(false)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.isStoreGroupsEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("StoreGroups.emptyState_Title"),
                  description: _vm.$t("StoreGroups.emptyState_Description"),
                  buttonText: _vm.$t("StoreGroups.emptyState_Button"),
                },
                on: { emptyAddButton: _vm.itemNew },
              })
            : _vm._e(),
          !_vm.isStoreGroupsEmpty && !_vm.isLoading && _vm.viewTypeList
            ? _c("TableView", {
                attrs: {
                  page: _vm.Page,
                  fields: _vm.storeGroupItemFields,
                  actions: ["edit", "remove"],
                  data: _vm.storeGroupList,
                  componentName: "StoreGroupsTable",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemEdit: _vm.itemEdit,
                  delegateOnItemDelete: _vm.itemRemove,
                },
              })
            : _vm._e(),
          !_vm.isStoreGroupsEmpty && !_vm.isLoading && !_vm.viewTypeList
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.storeGroupItemFields,
                  actions: ["edit", "remove"],
                  data: _vm.storeGroupList,
                  page: _vm.Page,
                  extraClass: { forContent: "m-auto" },
                  componentName: "StoreGroupsBox",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemEdit: _vm.itemEdit,
                  delegateOnItemRemove: _vm.itemRemove,
                },
              })
            : _vm._e(),
          _c("router-view"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }