// DESPATCH
import despatchList from './Despatch/index'
import despatchListForm from './Despatch/popup/form'
import despatchListRemove from './Despatch/popup/remove'
// detail
import despatchListDetail from './Despatch/detail'
import despatchListFormDetail from './Despatch/popup/detail-form'
import despatchListRemoveDetail from './Despatch/popup/remove-detail'

/**
**
*/

// INVENTORY

// header
import inventoryList from './Inventory/index'
import inventoryListForm from './Inventory/popup/form'
import inventoryListRemove from './Inventory/popup/remove'
// detail
import inventoryListDetail from './Inventory/detail'
import inventoryListFormDetail from './Inventory/popup/detail-form'
import inventoryListRemoveDetail from './Inventory/popup/remove-detail'

/**
**
*/

// TRANSFER REQUEST

 // header
import requestTransferList from './RequestTransfer/index'
import requestTransferListForm from './RequestTransfer/popup/form'
import requestTransferListRemove from './RequestTransfer/popup/remove'

  // detail
import requestTransferListDetail from './RequestTransfer/detail'
import requestTransferListDetailForm from './RequestTransfer/popup/detail-form'
import requestTransferListDetailRemove from './RequestTransfer/popup/remove-detail'

/**
**
*/

// ORDER REQUEST

  // header
import requestOrderList from './RequestOrder/index'
import requestOrderListForm from './RequestOrder/popup/form'
import requestOrderListFormRemove from './RequestOrder/popup/remove'

  // detail
import requestOrderListDetail from './RequestOrder/detail'
import requestOrderListDetailForm from './RequestOrder/popup/detail-form'
import requestOrderListDetailRemove from './RequestOrder/popup/remove-detail'

/**
**
*/

// WASTE

import wasteList from './Waste/index'
import wasteListForm from './Waste/popup/form'
import wasteListRemove from './Waste/popup/remove'

  // detail
import wasteListDetail from './Waste/detail'
import wasteListDetailForm from './Waste/popup/detail-form'
import wasteListDetailRemove from './Waste/popup/remove-detail'

/**
**
*/

// PRODUCT STOCK

import production from './Production/index'
import productionForm from './Production/popup/header-form'
import productionRemove from './Production/popup/header-remove'

import productionDetail from './Production/detail'
import productionDetailForm from './Production/popup/detail-form'
import productionDetailRemove from './Production/popup/detail-remove'
// TransferSalesPrice
import transferSalesPrice from './TransferSalesPrice/route.js'
// Yield
import yieldLists from './Yield/route/index'

// Shelf Storage Locations

import ShelfStorageLocation from './ShelfStorageLocation/index'
import ShelfStorageLocationShelfList from './ShelfStorageLocation/shelf-list'
import ShelfStorageLocationForm from './ShelfStorageLocation/popup/form'
import ShelfStorageLocationRemove from './ShelfStorageLocation/popup/remove'

import ShelfStorageLocationShelfListDetail from './ShelfStorageLocation/shelf-list-detail'
import ShelfStorageLocationFormDetail from './ShelfStorageLocation/popup/detail-form'
import ShelfStorageLocationRemoveDetail from './ShelfStorageLocation/popup/detail-remove'

// Expense List

import Expense from './Expense/index'
import ExpenseForm from './Expense/popup/form'
import ExpenseRemove from './Expense/popup/remove'
import ExpenseDetail from './Expense/detail'
import ExpenseDetailForm from './Expense/popup/detail-form'
import ExpenseDetailRemove from './Expense/popup/detail-remove'


export default [
  // DESPATCH LIST
  {
    name: 'ListsDespatch',
    path: '/lists',
    component: despatchList,
    children: [
      {
        name: 'NewListDespatchHeader',
        path: '/lists/despatch/new',
        component: despatchListForm
      },
      {
        name: 'EditListDespatchHeader',
        path: '/lists/despatch/edit/:id',
        component: despatchListForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveListDespatchHeader',
        path: '/lists/despatch/remove/:id',
        component: despatchListRemove
      }
    ],
    meta: {
      main_menu: true,
      slug: 'lists',
      category: 'Main_data',
      page: 'lists',
      permissionKey: 'ListMenu'
    }
  },

  {
    name: 'ListsDespatchDetail',
    path: '/lists/despatch/item/:id',
    component: despatchListDetail,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsDespatchDetailNew',
        path: '/lists/despatch/item/:id/new',
        component: despatchListFormDetail
      },
      {
        name: 'ListsDespatchDetailEdit',
        path: '/lists/despatch/item/:id/edit/:detailId',
        component: despatchListFormDetail,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveListDespatchDetail',
        path: '/lists/despatch/remove/:id/item/:detailId',
        component: despatchListRemoveDetail
      }
    ]
  },
  // INVETORY LIST
  {
    name: 'ListsInventory',
    path: '/lists/inventory',
    component: inventoryList,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'NewListInventoryHeader',
        path: '/lists/inventory/:type/new',
        component: inventoryListForm
      },
      {
        name: 'EditListInventoryHeader',
        path: '/lists/inventory/:type/edit/:id',
        component: inventoryListForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveListInventoryHeader',
        path: '/lists/inventory/:type/remove/:id',
        component: inventoryListRemove
      }
    ]
  },
  {
    name: 'ListsInventoryDetail',
    path: '/lists/inventory/item/:id',
    component: inventoryListDetail,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsInventoryDetailNew',
        path: '/lists/inventory/item/:id/new',
        component: inventoryListFormDetail
      },
      {
        name: 'RemoveListInventoryDetail',
        path: '/lists/inventory/remove/:id/item/:detailId',
        component: inventoryListRemoveDetail
      }
    ]
  },
  // TRANSFER LIST
  {
    name: 'ListsRequestTransfer',
    path: '/lists/requesttransfer',
    component: requestTransferList,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsRequestTransferNew',
        path: '/lists/requesttransfer/new',
        component: requestTransferListForm
      },
      {
        name: 'ListsRequestTransferEdit',
        path: '/lists/requesttransfer/edit/:id',
        component: requestTransferListForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsRequestTransferRemove',
        path: '/lists/requesttransfer/remove/:id',
        component: requestTransferListRemove
      }
    ]
  },
  //
  {
    name: 'ListsRequestTransferDetail',
    path: '/lists/requesttransfer/item/:id',
    component: requestTransferListDetail,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsRequestTransferDetailNew',
        path: '/lists/requesttransfer/item/:id/new',
        component: requestTransferListDetailForm
      },
      {
        name: 'ListsRequestTransferDetailEdit',
        path: '/lists/requesttransfer/item/:id/edit/:detailId',
        component: requestTransferListDetailForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsRequestTransferDetailRemove',
        path: '/lists/requesttransfer/remove/:id/item/:detailId',
        component: requestTransferListDetailRemove
      }
    ]
  },

  // REQUEST ORDER LIST
  {
    name: 'ListsRequestOrder',
    path: '/lists/requestorder',
    component: requestOrderList,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsRequestOrderNew',
        path: '/lists/requestorder/new',
        component: requestOrderListForm
      },
      {
        name: 'ListsRequestOrderEdit',
        path: '/lists/requestorder/edit/:id',
        component: requestOrderListForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsRequestOrderRemove',
        path: '/lists/requestorder/remove/:id',
        component: requestOrderListFormRemove
      }
    ]
  },
    //
  {
    name: 'ListsRequestOrderDetail',
    path: '/lists/requestorder/item/:id',
    component: requestOrderListDetail,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsRequestOrderDetailNew',
        path: '/lists/requestorder/item/:id/new',
        component: requestOrderListDetailForm
      },
      {
        name: 'ListsRequestOrderDetailEdit',
        path: '/lists/requestorder/item/:id/edit/:detailId',
        component: requestOrderListDetailForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsRequestOrderDetailRemove',
        path: '/lists/requestorder/remove/:id/item/:detailId',
        component: requestOrderListDetailRemove
      }
    ]
  },

  // WASTE LIST
  {
    name: 'ListsWaste',
    path: '/lists/wastes',
    component: wasteList,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsWasteNew',
        path: '/lists/wastes/new',
        component: wasteListForm
      },
      {
        name: 'ListsWasteEdit',
        path: '/lists/wastes/edit/:id',
        component: wasteListForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsWasteRemove',
        path: '/lists/wastes/remove/:id',
        component: wasteListRemove
      }
    ]
  },
  {
    name: 'ListsWasteDetail',
    path: '/lists/waste/item/:id',
    component: wasteListDetail,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsWasteDetailNew',
        path: '/lists/waste/item/:id/new',
        component: wasteListDetailForm
      },
      {
        name: 'ListsWasteDetailEdit',
        path: '/lists/waste/item/:id/edit/:detailId',
        component: wasteListDetailForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsWasteDetailRemove',
        path: '/lists/waste/item/:id/remove/:detailId',
        component: wasteListDetailRemove
      }
    ]
  },

  // PRODUCT STOCK

  {
    name: 'ListsProduction',
    path: '/lists/production',
    component: production,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsProductionNew',
        path: '/lists/production/new',
        component: productionForm
      },
      {
        name: 'ListsProductionEdit',
        path: '/lists/production/edit/:id',
        component: productionForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsProductionRemove',
        path: '/lists/production/remove/:id',
        component: productionRemove
      }
    ]
  },
   //
  {
    name: 'ListsProductionDetail',
    path: '/lists/production/item/:id',
    component: productionDetail,
    meta: {
      page: 'lists',
      permissionKey: 'ListMenu'
    },
    children: [
      {
        name: 'ListsProductionDetailNew',
        path: '/lists/production/item/:id/new',
        component: productionDetailForm
      },
      {
        name: 'ListsProductionDetailRemove',
        path: '/lists/production/item/:id/remove/:detailId',
        component: productionDetailRemove
      }
    ]
  },
  {
    name: 'ListsShelfStorageLocation',
    path: '/lists/shelf-storage-location',
    component: ShelfStorageLocation,
    meta: {
      page: 'lists',
      tabPermissionKey: 'ShelfStorageLocationEdit'
    },
    children: [
      {
        name: 'ListsShelfStorageLocationNew',
        path: '/lists/shelf-storage-location/new',
        component: ShelfStorageLocationForm
      }
    ]
  },
  {
    name: 'ListsShelfStorageLocationShelfList',
    path: '/lists/shelf-storage-location/shelf-list/:storeId/:storeName',
    component: ShelfStorageLocationShelfList,
    meta: {
      page: 'lists',
      tabPermissionKey: 'ShelfStorageLocationEdit'
    },
    children: [
      {
        name: 'ListsShelfStorageNew',
        path: '/lists/shelf-storage-location/shelf-list/new',
        component: ShelfStorageLocationForm
      },
      {
        name: 'ListsShelfStorageLocationEdit',
        path: '/lists/shelf-storage-location/shelf-list/:id/:storeId/:storeName/edit',
        component: ShelfStorageLocationForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsShelfStorageLocationRemove',
        path: '/lists/shelf-storage-location/shelf-list/:id/:storeId/:storeName/remove',
        component: ShelfStorageLocationRemove
      }
    ]
  },
  {
    name: 'ListsShelfStorageLocationShelfDetail',
    path: '/lists/shelf-storage-location/shelf-list/:shelfStorageLocationListHeaderId/:storeId/detail',
    component: ShelfStorageLocationShelfListDetail,
    meta: {
      page: 'lists',
      tabPermissionKey: 'ShelfStorageLocationEdit'
    },
    children: [
      {
        name: 'ListsShelfStorageDetailNew',
        path: '/lists/shelf-storage-location/shelf-list/:shelfStorageLocationListHeaderId/detail/new',
        component: ShelfStorageLocationFormDetail
      },
      {
        name: 'ListsShelfStorageLocationDetailRemove',
        path: '/lists/shelf-storage-location/shelf-list/:id/detail/remove',
        component: ShelfStorageLocationRemoveDetail
      }
    ]
  },
  {
    name: 'ListsExpense',
    path: '/lists/expenses',
    component: Expense,
    meta: {
      page: 'lists'
    },
    children: [
      {
        name: 'ListsExpenseNew',
        path: '/lists/expenses/new',
        component: ExpenseForm
      },
      {
        name: 'ListsExpenseEdit',
        path: '/lists/expenses/:id/edit',
        component: ExpenseForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsExpenseRemove',
        path: '/lists/expenses/:id/remove',
        component: ExpenseRemove
      }
    ]
  },
  {
    name: 'ListsExpenseDetail',
    path: '/lists/expenses/detail/:otherExpenseListHeaderId',
    component: ExpenseDetail,
    meta: {
      page: 'lists'
    },
    children: [
      {
        name: 'ListsExpenseDetailNew',
        path: '/lists/expenses/detail/:otherExpenseListHeaderId/new',
        component: ExpenseDetailForm
      },
      {
        name: 'ListsExpenseDetailEdit',
        path: '/lists/expenses/detail/:otherExpenseListHeaderId/edit/:id',
        component: ExpenseDetailForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ListsExpenseDetailRemove',
        path: '/lists/expenses/detail/:otherExpenseListHeaderId/remove/:id',
        component: ExpenseDetailRemove
      }
    ]
  },
  ...transferSalesPrice,
  ...yieldLists
]
