<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    CustomDatepicker#WasteDatepicker.m-right-15(
      v-model="dates"
      :disabled="isLoading"
      :range="true"
    )
  template(slot="header-right-content")
    Button(
    size="medium"
    primary
    id="btn-waste-new"
    type="button"
    iconName="icon-global-add"
    iconClass="icon-global-add"
    @click="addWaste")
      span {{ $t('Waste.waste_form_title') }}

  template(slot="toolbar-left")
    StoreFilterComponent(
      @submitFilter="getListWithStoreFilter"
      ref="orderStoreFilter")
    BusinessMultiselect(
      componentName="waste-groups-filter"
      :label="$t('Waste.wasteGroups')"
      :popupHeader="$t('Waste.select_WasteGroup')"
      :multiselectItems="WasteGroupList"
      @submitFilter="getListWithWasteGroupFilter"
    )
    .show-drafts
      CustomCheckbox.popup-item-check(
      id="checkbox-wastes-show-drafts"
      :checked="showDrafts"
      :label="$t('Global.showDrafts')"
      @change="changeShowDraftsStatus")
  template(slot="content-center")
    // LOADING
    TableLoading(v-if="isLoading")

    // empty state

    EmptyState(
    :title="$t('Waste.emptyTitle')",
    :description="$t('Waste.empty_description')",
    :buttonText="$t('Waste.waste_form_title')"
    v-if="isEmptyWasteList && !isLoading"
    @emptyAddButton="addWaste")

    // LIST
    div
      NestedTable(
        tableKey="waste",
        v-if="!isEmptyWasteList && !isLoading",
        :data = "Waste.list",
        :fields="wasteHeaderFields",
        :detailFields="wasteDetailFields",
        :actions="['edit','delete', 'export']",
        :detailActions="['edit','delete']",
        :usePortalFooter="true"
        :isDetailLoading="$wait.is('Get_Waste_Header')",
        :isLoadingFinalize="$wait.is('wasteSaved')"
        :disableNewDetailButton="isOnlyListUsage"
        :page="Page",
        :selectable="'checkbox'"
        :selectableAll="'checkbox'"
        :selectedRows="selectedList"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @detailChangeAction="updateDetailItem"
        @showExportPopup="showExportPopup"
        @showDetailAction="showWasteDetail",
        @deleteHeaderAction="DeleteWaste",
        @editHeaderAction="EditWasteHeader",
        @editDetailAction="WasteDetailEdit",
        @deleteDetailAction="wasteDetailRemove"
        @selectItem="onSelectWaste"
        @selectAllItems="onSelectWasteAll")

      portal(to="description")
        div(slot-scope="header")
          Button.show-MiniPopup.relative.width-max-content(
            v-if="header.row.description",
            size="small"
            :id="`wastes-header-info-button-${header.rowIndex}`"
            iconName="icon-global-info",
            :justIcon="true",
            class="btn-info"
          )
            .MiniPopup-right-primary.m-top-15.c-dark
              span {{ header.row.description }}
      
      portal(to="detailDescription")
        div(slot-scope="detail")
          Button.show-MiniPopup.relative.width-max-content(
            v-if="detail.row.description",
            size="small"
            :id="`wastes-detail-info-button`"
            iconName="icon-global-info",
            :justIcon="true",
            class="btn-info"
          )
            .MiniPopup-right-primary.m-top-15.c-dark
              span {{ detail.row.description }}
      
      portal(to="footerPortal")
        div.popup-container.flexbox(slot-scope="rowData")
          Button.m-right-20(
            v-if="isExpireDateControlEnabled(rowData)"
            id="btn-waste-expire-date-edit"
            type="button"
            size="small"
            secondary
            @click="editExpireDate(rowData)")
            span {{ $t('Transfer.editExpireDate') }}

          Button.m-right-20(
            v-if="rowData.status === 1"
            id="btn-waste-add-new-data"
            type="button"
            size="small"
            secondary
            @click="wasteDetailNew(rowData)")
              span {{ $t('Waste.addNewStock') }}
          
          Button.m-right-20(
            v-if="rowData.status === 1"
            :disabled="!rowData.detailList.length"
            id="btn-waste-save-waste"
            type="button"
            size="small"
            secondary
            @click="wasteSaved(rowData)")
              span {{ $t('Waste.wasteSaved') }}
        
    div 
      Modal(:isOpen="showExpireDateControl" :showCloseButton="false")
        template(slot="title")
          .modal-header
            .modal-header-left
              .waste
                h3 {{ $t('Waste.editWasteExpireDates') }}


        template(slot="content")
          div
            EmptyState(
              v-if="!Batch.list.length"
              :title="$t('Despatches.emptyState_Title_Batch', { field: $t('Dictionary.Batch') })"
              :description="$t('Global.emptyState_Description_Batch', { field: $t('Dictionary.Batch') })"
            )
            NestedTable.custom-nested-table(
              v-else
              :fields="wasteBatchHeaderFields"
              :detailFields="wasteBatchDetailFields"
              :data="Batch.list"
              :usePortalFooter="true"
              :isActionButtonsVisible="true"
              :actions="['export']"
              @showExportPopup="showBatchExportPopup"
              @showDetailAction="showBatchDetailAction"
            )

            portal(to="givenQuantity")
              div(slot-scope="detail")
                .control.quantity-input
                  input.txt.no-spinners(
                    name="givenQuantity"
                    :data-vv-as="$t('Despatches.detail_TableColumn_Quantity')"
                    :class="{ 'is-danger': veeErrors.has(`refund${detail.rowIndex}.givenQuantity`) }"
                    v-validate="{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}"
                    type="number"
                    step="any"
                    v-model="detail.row.givenQuantity"
                    @blur="updateGivenBatchQuantity($event, detail.row)"
                  )
            
            portal(to="unitCode")
              div(slot-scope="detail")
                span {{ detail.row.unitCode }}

            portal(to="warehouseTime")
              div(slot-scope="detail")
                span {{ detail.row.warehouseTime?.substr(0,5) || '-' }}
            
            portal(to="shipmentTime")
              div(slot-scope="detail")
                span {{ detail.row.shipmentTime?.substr(0,5) || '-' }}


        template(slot="footer")
          .batch-footer
            
            Button(
              id="waste-batch-detail-save"
              size="small"
              primary
              inline
              @click="closeModal"
            )
              span {{ $t('Global.Cancel') }}

    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )

    router-view(
      @getList="getList"
      @showDrafts="showDrafts = true"
    )

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getWasteHeaderFields, getWasteDetailFields } from './waste-fields'
import { getWasteBatchHeaderFields, getWasteBatchDetailFields } from './fields/waste-batch'
//
import moment from 'moment'
import TableFoot from '@/view/global/table/foot'
import exportPopup from '@/view/global/export-popup'
import { vueWaitLoader } from '@/utils/baseOperations'
import settingsExportPopup from '@/mixins/settingsExportPopup'
import StoreFilterComponent from '@/view/global/business-filters/store'
import BusinessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {
  name: 'Wastes',
  data () {
    return {
      wasteHeaderFields: getWasteHeaderFields(this.$t),
      wasteDetailFields: getWasteDetailFields(this.$t, this.$getConst),
      wasteBatchHeaderFields: getWasteBatchHeaderFields(this.$t),
      wasteBatchDetailFields: getWasteBatchDetailFields(this.$t),
      isOnlyListUsage: false,
      showDrafts: true,
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ],
      wasteGroupFilterList: [],
      storeList: [],
      wasteDetailList: [],
      wasteHeader: null,
      showExpireDateControl: false,
      selectedList: []
    }
  },
  components: {
    TableFoot,
    exportPopup,
    StoreFilterComponent,
    BusinessMultiselect
  },
  mixins: [settingsExportPopup],
  computed: {
    ...mapGetters('Waste', [
      'Waste',
      'WasteGroupList',
      'isEmptyWasteList',
      'Page',
      'pageHeaderLinks',
      'Batch'
    ]),

    ...mapGetters('Settings', [
      'permissions'
    ]),

    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    isLoading () {
      return this.$wait.is(['Get_Waste_Header_All'])
    }
  },
  watch: {
    dates (newVal, oldVal) {
      if (oldVal === newVal) return
      this.getList(this.Page.number)
    }
  },
  async mounted () {
    await this.setShowDraftStatus()
    await this.getList()
    await this.getWasteGroups()
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
  },
  methods: {
    ...mapActions('Waste', [
      'getWasteHeaderAll',
      'getWasteHeader',
      'updateFinalize',
      'getWasteGroupAll',
      'updateWasteHeaderDetail',
      'getWasteBatchDetail',
      'updateBatchQuantity',
      'getWasteBatchData',
      'validateBatchDetails'
    ]),
    ...mapMutations('Waste', [
      'SHOW_DETAIL',
      'HIDE_DETAIL',
      'HIDE_OTHERS_DETAILS',
      'UPDATE_WASTE_HEADER',
      'SHOW_BATCH_DETAIL',
      'HIDE_BATCH_DETAIL',
      'SET_EXPIRATION_DATE_CONTROL'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stores', ['getStore']),

    ...mapMutations('Global', ['TOGGLE_MENU_MINIMALIZE']),

    ...mapActions('DocumentSettings', ['getDocumentFilterSettings']),

    isExpireDateControlEnabled (item) {
      const selectedsWithExpireDate = this.selectedList.filter(s => s.expirationDateControlIsEnabled && s.wasteHeaderId === item.id)
      return selectedsWithExpireDate.length > 0
    },

    onSelectWaste (item) {
      if (item.isChecked) this.selectedList.push(item)
      else {
        this.selectedList = this.selectedList.filter(s => s.id !== item.id)
      }
    },

    onSelectWasteAll (detailData, isChecked) {
      if (isChecked) {
        detailData.forEach(detail => {
          this.selectedList.push(detail)
        })
      } else {
        detailData.forEach(detail => {
          const index = this.selectedList.findIndex(s => s.id === detail.id)
          this.selectedList.splice(index, 1)
        })
      }
    },

    editExpireDate (item) {
      this.wasteHeader = item
      this.TOGGLE_MENU_MINIMALIZE(true)
      this.showExpireDateControl = true
      this.wasteDetailList = this.wasteHeader.detailList.map(i => i.id)
      const filteredList = this.selectedList.filter(s => s.wasteHeaderId === item.id)
      this.getWasteBatchData({wasteDetailIds: filteredList.map(i => i.id)})
    },

    closeModal () {
      this.showExpireDateControl = false
    },

    checkExpireDateStatus (data) {
      this.getStore(data.storeId).then(res => {
        if (res) {
          this.SET_EXPIRATION_DATE_CONTROL({waste: data, expirationDateControl: res.data.store.expirationDateControlIsEnabled})
        }
      })
    },

    async updateGivenBatchQuantity (event, row) {
      const payload = {
        id: row.id,
        wasteDetailId: row.wasteDetailId,
        quantity: Number(event.target.value)
      }
      await this.updateBatchQuantity(payload).then(res => {
        if (res) {
          const message = this.$t('Waste.updated_wasteBatchQuantity')
          this.notifyShow({ message })
          this.getWasteBatchData({wasteDetailIds: this.wasteDetailList})
          this.getWasteBatchDetail({wasteDetailId: row.wasteDetailId}).then(result => {
            if (result) this.SHOW_BATCH_DETAIL({batchList: result.data.batchList, id: row.wasteDetailId})
          })
        }
      })
    },

    async showBatchDetailAction (item) {
      if (item.showDetail) {
        this.HIDE_BATCH_DETAIL(item)
      } else {
        await this.getWasteBatchDetail({wasteDetailId: item.id}).then(res => {
          if (res) {
            this.SHOW_BATCH_DETAIL({batchList: res.data.batchList, id: item.id})
          }
        })
      }
    },

    addWaste () {
      this.$router.push({
        name: 'NewWaste'
      })
    },

    EditWasteHeader (waste) {
      this.$router.push({
        name: 'EditWaste',
        params: {
          id: waste.id
        }
      })
    },

    wasteDetailNew (waste) {
      this.HIDE_OTHERS_DETAILS(waste)
      this.$router.push({
        name: 'NewWasteDetail',
        params: {
          id: waste.id
        }
      })
    },

    WasteDetailEdit (detail) {
      this.$router.push({
        name: 'EditWasteDetail',
        params: {
          id: detail.wasteHeaderId,
          detailId: detail.id
        }
      })
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_Waste') this.showDrafts = i.isChecked
      })
    },

    wasteSaved: vueWaitLoader(async function (waste) {
      const message = this.$t('Waste.finalize_Notification_message')
      const res = await this.updateFinalize({
        Id: waste.id
      })
      if (res) {
        this.notifyShow({ message })
        this.showExportPopup(waste, true)
        this.getList(this.Page.number, this.Page.size)
      }
    }, 'wasteSaved'),

    wasteDetailRemove (detail) {
      this.$router.push({
        name: 'DeleteWasteDetail',
        params: {
          id: detail.wasteHeaderId,
          detailId: detail.id
        }
      })
    },

    showBatchExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 20, isFinalizeAction)
    },

    DeleteWaste (waste) {
      this.$router.push({
        name: 'DeleteWaste',
        params: {
          id: waste.id
        }
      })
    },

    async showWasteDetail (data) {
      if (data.showDetail) {
        this.HIDE_DETAIL(data)
      } else {
        const res = await this.getWasteHeader(data.id)
        this.checkExpireDateStatus(data)
        this.SHOW_DETAIL(res.data)
      }
    },
    async updateDetailItem (item) {
      const updated = await this.updateWasteHeaderDetail({
        wasteDetailId: item.id,
        quantity: item.quantity
      })
      if (updated) {
        const res = await this.getWasteHeader(item.wasteHeaderId)
        if (res) {
          await this.UPDATE_WASTE_HEADER(res.data)
          const message = this.$t('ListsWaste.successDetailUpdateMessage')
          this.notifyShow({ message })
        }
      }
    },
    changePage (page) {
      this.getList(page)
    },
    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },
    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getList()
    },

    async getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeList = []
      else {
        this.storeList = list
        await this.getList()
      }
    },

    async getListWithWasteGroupFilter ({list, dontSubmit}) {
      if (dontSubmit) this.wasteGroupFilterList = list
      else {
        this.wasteGroupFilterList = list
        await this.getList()
      }
    },

    getWasteGroups () {
      const payload = {
        pageSize: 999999999,
        storeGroupList: []
      }
      this.getWasteGroupAll(payload)
    },

    getList (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        pageNumber,
        pageSize,
        storeIdList: this.storeList ? this.storeList.map(item => item.id) : [],
        wasteGroupIdList: this.wasteGroupFilterList ? this.wasteGroupFilterList.map(item => item.id) : [],
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        showDrafts: this.showDrafts
      }
      this.getWasteHeaderAll(payload)
    },
    showExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 8, isFinalizeAction, 'Waste')
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.custom-nested-table) {
    .NestedTable {
      &-head, &-row {
        grid-template-columns: repeat(8, 1fr) 35px !important;
      }
    }
  }
  :deep(.NestedDetail-row) {
    grid-template-columns: repeat(10, 1fr) 120px !important;
  }
  .batch-footer {
    display: flex;
    justify-content: end;
  }
  :deep(.quantity-input .txt) {
    text-align: right !important;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
  }
  :deep(.Modal) {
    &-Body {
      z-index: $z-index-xxxl !important;
      &-content {
        width: 80vw !important;
      }
    }
    &-Overlay[data-v-b9dcd536] {
      z-index: $z-index-xxl !important;
    }
  }
  :deep(.Header) {
    z-index: 400 !important
  }
  :deep([data-v-2e54a9da] .EmptyState) {
    height: unset !important;
  }
</style>
