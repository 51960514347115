var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("Transfer.pageHeader_Transfer")))]),
      ]),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _c("div", { staticClass: "Result m-bottom-15" }, [
            _c("div", { staticClass: "Result-top-header-title" }, [
              _c("div", { staticClass: "doc-number" }, [
                _vm._v(_vm._s(_vm.documentNumber)),
              ]),
              _c("span", { staticClass: "m-left-5" }, [
                _vm._v(_vm._s(_vm.$t("Despatches.document"))),
              ]),
            ]),
          ]),
          _c("TableView", {
            staticClass: "m-top-15",
            attrs: {
              componentName: "transferDocumentDetail",
              fields: _vm.transferDetailFields,
              actions: [""],
              data: _vm.transferDetail,
              readOnly: true,
            },
          }),
          _c("portal", {
            attrs: { to: "unitName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c("div", {}, [
                    _c("span", [_vm._v(_vm._s(_vm.findUnit(row.unitId).name))]),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }