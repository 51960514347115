var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "Auth-form ForgotPassword-form Form",
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c("h1", { staticClass: "Auth-form-title" }, [
        _vm._v(_vm._s(_vm.$t("ForgotPassword.formHeaderTitle"))),
      ]),
      _c("div", { staticClass: "Form-item" }, [
        _c("p", { staticClass: "description" }, [
          _vm._v(_vm._s(_vm.$t("ForgotPassword.formDescription"))),
        ]),
      ]),
      _c("div", { staticClass: "Form-item" }, [
        _c("label", { staticClass: "Form-item-label" }, [
          _vm._v(_vm._s(_vm.$t("ForgotPassword.formItemEmail"))),
        ]),
        _c("div", { staticClass: "control" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.emailAddress,
                expression: "form.emailAddress",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|email|max:128",
                expression: "'required|email|max:128'",
              },
            ],
            staticClass: "txt",
            class: { "is-danger": _vm.veeErrors.has("email") },
            attrs: {
              id: "input-forgot-password-email",
              name: "email",
              "data-vv-as": _vm.$t("ForgotPassword.formItemEmail"),
              type: "text",
              placeholder: _vm.$t("ForgotPassword.formItemEmailPlaceholder"),
            },
            domProps: { value: _vm.form.emailAddress },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "emailAddress", $event.target.value)
              },
            },
          }),
        ]),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.veeErrors.has("email"),
                expression: "veeErrors.has('email')",
              },
            ],
            staticClass: "Form-item-help is-danger",
          },
          [_vm._v(_vm._s(_vm.veeErrors.first("email")))]
        ),
      ]),
      _c(
        "div",
        { staticClass: "Form-item x2" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                type: "submit",
                size: "medium",
                id: "btn-forgot-password-button",
                variant: "full",
                disabled: !_vm.isFormReady || _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFormReady || !_vm.isLoading,
                      expression: "isFormReady || !isLoading",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.$t("ForgotPassword.formItemSubmit")))]
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.authAlert,
              expression: "authAlert",
            },
          ],
          staticClass: "Form-item alert x2",
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.errorStatusMessage
                  ? _vm.$t(`ApiErrorCode.${_vm.errorStatusMessage}`)
                  : _vm.$t("Login.formAlert")
              )
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }