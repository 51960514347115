var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-breadcrumb-left" }, [
        _c("div", { staticClass: "Breadcrumb m-top-5" }, [
          _c("span", [_vm._v(_vm._s(_vm.selectedHeader.name))]),
        ]),
      ]),
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("pageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _vm.selectedType.id === null
            ? _c("Search", {
                staticClass: "search",
                attrs: {
                  componentName: "Supplier",
                  isSuggestionLoading: false,
                },
                on: { onChangeSearchValue: _vm.onChangeSearch },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmpty && !_vm.isLoading,
                  expression: "!isEmpty && !isLoading",
                },
              ],
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-supplier-new",
                iconName: "icon-global-add",
                iconClass: "icon-global-add icon-left",
              },
              on: { click: _vm.itemNew },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Supplier.headerNewButton")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            class: !_vm.selectedHeader.isChecked ? "disabled-tabs" : "",
            attrs: {
              list: _vm.typeList,
              selectedItem: _vm.selectedType,
              useUpperCase: true,
            },
            on: { delegateChangeSelectedItem: _vm.changeTab },
          }),
        ],
        1
      ),
      _vm.selectedType.id === null
        ? _c("template", { slot: "toolbar-left" }, [
            _c(
              "div",
              [
                _c("CustomCheckbox", {
                  staticClass: "show-passive-suppliers m-left-5",
                  attrs: {
                    id: "checkbox-supplier-show-passive",
                    checked: _vm.showPassive,
                    label: _vm.$t("Supplier.showPassives"),
                  },
                  on: { change: _vm.showPassiveSuppliers },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.isEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Supplier.emptyStateTitle"),
                  description: _vm.$t("Supplier.emptyStateDescription"),
                  buttonText: _vm.$t("Supplier.emptyStateNewButton"),
                },
                on: { emptyAddButton: _vm.itemNew },
              })
            : _vm._e(),
          _c("div", { staticClass: "content" }, [
            _vm.selectedType.id === 1
              ? _c(
                  "div",
                  { staticClass: "store-code" },
                  [
                    _c("TableView", {
                      attrs: {
                        fields: _vm.storeCodeFields,
                        data: _vm.storeCodes,
                        readOnly: true,
                      },
                    }),
                    _c("portal", {
                      attrs: { to: "storeCode" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return _c(
                                "div",
                                {},
                                [
                                  _c("customTextInput", {
                                    attrs: {
                                      id: "supplier-store-code-input",
                                      name: "storeCode",
                                      placeholder: _vm.$t("Stores.storeCode"),
                                    },
                                    on: {
                                      input: _vm.getStoreCodeText,
                                      blur: function ($event) {
                                        return _vm.updateStoreCode(row)
                                      },
                                    },
                                    model: {
                                      value: row.storeCode,
                                      callback: function ($$v) {
                                        _vm.$set(row, "storeCode", $$v)
                                      },
                                      expression: "row.storeCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        1436528977
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedType.id === 2
              ? _c(
                  "div",
                  { staticClass: "store-code" },
                  [
                    _c("TableView", {
                      attrs: {
                        fields: _vm.unitCodeFields,
                        data: _vm.unitCodes,
                        readOnly: true,
                      },
                    }),
                    _c("portal", {
                      attrs: { to: "unitCode" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return _c(
                                "div",
                                {},
                                [
                                  _c("customTextInput", {
                                    attrs: {
                                      id: "supplier-unit-code-input",
                                      name: "unitCode",
                                      placeholder: _vm.$t("Stores.unitCode"),
                                    },
                                    on: {
                                      input: _vm.getUnitCodeText,
                                      blur: function ($event) {
                                        return _vm.updateUnitCode(row)
                                      },
                                    },
                                    model: {
                                      value: row.unitCode,
                                      callback: function ($$v) {
                                        _vm.$set(row, "unitCode", $$v)
                                      },
                                      expression: "row.unitCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        3773153393
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedType.id === 3
              ? _c(
                  "div",
                  [
                    _vm.isLoadingConfig
                      ? _c("Loading")
                      : _c(
                          "div",
                          { staticClass: "invoice-config" },
                          [
                            _c("CustomCheckbox", {
                              staticClass: "show-passive-suppliers",
                              attrs: {
                                id: "checkbox-supplier-show-passive",
                                label: _vm.$t("Supplier.isServiceSupplier"),
                              },
                              on: { change: _vm.updateEInvoiceConfiguration },
                              model: {
                                value: _vm.isServiceSupplier,
                                callback: function ($$v) {
                                  _vm.isServiceSupplier = $$v
                                },
                                expression: "isServiceSupplier",
                              },
                            }),
                            _c("CustomCheckbox", {
                              staticClass: "show-passive-suppliers m-top-15",
                              attrs: {
                                id: "checkbox-supplier-show-passive",
                                label: _vm.$t(
                                  "Supplier.isEInvoiceStockItemEasyMatchingEnabled"
                                ),
                              },
                              on: { change: _vm.updateEInvoiceConfiguration },
                              model: {
                                value:
                                  _vm.isEInvoiceStockItemEasyMatchingEnabled,
                                callback: function ($$v) {
                                  _vm.isEInvoiceStockItemEasyMatchingEnabled =
                                    $$v
                                },
                                expression:
                                  "isEInvoiceStockItemEasyMatchingEnabled",
                              },
                            }),
                            _c("CustomCheckbox", {
                              staticClass: "show-passive-suppliers m-top-15",
                              attrs: {
                                id: "checkbox-supplier-show-passive",
                                label: _vm.$t(
                                  "Supplier.isTaxIncludedInInvoices"
                                ),
                              },
                              on: { change: _vm.updateEInvoiceConfiguration },
                              model: {
                                value: _vm.isTaxIncludedInInvoices,
                                callback: function ($$v) {
                                  _vm.isTaxIncludedInInvoices = $$v
                                },
                                expression: "isTaxIncludedInInvoices",
                              },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          !_vm.isEmpty &&
          !_vm.isLoading &&
          !_vm.isSuppliersLoading &&
          _vm.selectedType.id === null
            ? _c("div", { staticClass: "Tableee-container" }, [
                _c("div", { staticClass: "Tableee-main" }, [
                  _c("table", { staticClass: "Tableee-root" }, [
                    _c("thead", { staticClass: "Tableee-head" }, [
                      _c("tr", { staticClass: "Tableee-row" }, [
                        _c("th", { staticClass: "Tableee-col" }),
                        _c("th", { staticClass: "Tableee-col fixed-width" }, [
                          _vm._v(
                            _vm._s(_vm.$t("Supplier.tableColumn_Name_Short"))
                          ),
                        ]),
                        _c("th", { staticClass: "Tableee-col fixed-width" }, [
                          _vm._v(
                            _vm._s(_vm.$t("Supplier.tableColumn_Currency"))
                          ),
                        ]),
                        _c("th", { staticClass: "Tableee-col u-textRight" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Supplier.tableColumn_EmailAddress_Short")
                            )
                          ),
                        ]),
                        _c("th", { staticClass: "Tableee-col mono" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Supplier.tableColumn_PhoneNumber_Short")
                            )
                          ),
                        ]),
                        _c("th", { staticClass: "Tableee-col mono" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Supplier.tableColumn_ContactName_Short")
                            )
                          ),
                        ]),
                        _c("th", { staticClass: "Tableee-col mono" }, [
                          _vm._v(_vm._s(_vm.$t("Supplier.isEInvoicePayer"))),
                        ]),
                        _c("th", { staticClass: "Tableee-col mono" }),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      { staticClass: "Tableee-body" },
                      _vm._l(_vm.Suppliers, function (supplier, index) {
                        return _c(
                          "tr",
                          {
                            staticClass: "Tableee-row",
                            class: !supplier.isActive
                              ? "row-TableViewPassiveItemsNoBorder"
                              : "",
                          },
                          [
                            _c(
                              "td",
                              { staticClass: "Tableee-col" },
                              [
                                supplier.isActive
                                  ? _c("CustomRadio", {
                                      attrs: {
                                        id: `radio-despatch-supplier`,
                                        label: "",
                                        checked: supplier.isChecked,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.selectHeader(supplier)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "Tableee-col tooltip-relative" },
                              [
                                _vm._v(_vm._s(_vm.getName(supplier.name))),
                                supplier.name.length > 30
                                  ? _c("Tooltip", {
                                      attrs: {
                                        white: "",
                                        bottom: "",
                                        text: supplier.name,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "Tableee-col" }, [
                              _vm._v(_vm._s(supplier.currencyIsoCode)),
                            ]),
                            _c(
                              "td",
                              { staticClass: "Tableee-col u-textRight" },
                              [_vm._v(_vm._s(supplier.emailAddress))]
                            ),
                            _c("td", { staticClass: "Tableee-col mono" }, [
                              _vm._v(_vm._s(supplier.phoneNumber)),
                            ]),
                            _c("td", { staticClass: "Tableee-col mono" }, [
                              _vm._v(_vm._s(supplier.contactName)),
                            ]),
                            _c(
                              "td",
                              { staticClass: "Tableee-col mono" },
                              [
                                _c("ShowBox", {
                                  attrs: { control: supplier.isEInvoicePayer },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "Tableee-col action" },
                              [
                                supplier.isGlobal === _vm.thisUserisGlobal
                                  ? _c("ActionButton", {
                                      attrs: {
                                        data: ["edit", "remove"],
                                        item: supplier,
                                      },
                                      on: {
                                        onItemDelete: function ($event) {
                                          return _vm.itemDelete(supplier)
                                        },
                                        onItemEdit: function ($event) {
                                          return _vm.itemEdit(supplier)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("TableFoot", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedType.id === null,
                expression: "selectedType.id === null",
              },
            ],
            attrs: { page: _vm.suppliersListPagination },
            on: {
              delegateOnChangePageSize: _vm.changePageSize,
              delegateOnChangePage: _vm.getList,
            },
          }),
          _c("router-view"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }