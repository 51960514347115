var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-left-search" },
            [
              _c("Search", {
                staticClass: "search",
                attrs: {
                  componentName: "LanguageTranslations",
                  isSuggestionLoading: false,
                  isInputDisable: !_vm.activateSearch,
                },
                on: { onChangeSearchValue: _vm.onChangeSearch },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hasTabPermission
        ? _c("template", { slot: "header-right-content" }, [
            _c("div", { staticClass: "default-lang" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("Global.defaultLanguage")))]),
              _c("span", [_vm._v(_vm._s(_vm.defaultLanguage.name))]),
            ]),
          ])
        : _vm._e(),
      _vm.hasTabPermission
        ? _c("template", { slot: "toolbar-left" }, [
            _c(
              "div",
              { staticClass: "translation-filters" },
              [
                _c("CustomSelect", {
                  staticClass: "custom-select",
                  attrs: {
                    id: "language-translations-from-lang",
                    componentName: "select-from-language",
                    defaultTitle: _vm.$t("LanguageTranslations.fromLanguage"),
                    optionData: _vm.fromLanguageList,
                    selectedEmitValue: "id",
                    optionTitle: "name",
                    isDefaultTextActive: false,
                  },
                  model: {
                    value: _vm.fromLanguage.id,
                    callback: function ($$v) {
                      _vm.$set(_vm.fromLanguage, "id", $$v)
                    },
                    expression: "fromLanguage.id",
                  },
                }),
                _c("Button", {
                  staticClass: "toggle-language m-left-15",
                  class:
                    !_vm.fromLanguage.id ||
                    !_vm.toLanguage.id ||
                    _vm.fromLanguage.id === _vm.defaultLanguage.id
                      ? "disabled"
                      : "",
                  attrs: {
                    iconName: "icon-global-toggle",
                    iconClass: "icon-global-toggle",
                    justIcon: true,
                    disabled:
                      !_vm.fromLanguage.id ||
                      !_vm.toLanguage.id ||
                      _vm.fromLanguage.id === _vm.defaultLanguage.id,
                  },
                  on: { click: _vm.toggleLanguages },
                }),
                _c("CustomSelect", {
                  staticClass: "custom-select m-left-15",
                  attrs: {
                    id: "language-translations-to-lang",
                    componentName: "select-to-language",
                    defaultTitle: _vm.$t("LanguageTranslations.toLanguage"),
                    optionData: _vm.toLanguageList,
                    selectedEmitValue: "id",
                    optionTitle: "name",
                    isDisabled: !_vm.fromLanguage.id,
                    isDefaultTextActive: false,
                  },
                  model: {
                    value: _vm.toLanguage.id,
                    callback: function ($$v) {
                      _vm.$set(_vm.toLanguage, "id", $$v)
                    },
                    expression: "toLanguage.id",
                  },
                }),
                _c("CustomSelect", {
                  staticClass: "custom-select m-left-15",
                  attrs: {
                    id: "language-translation-type",
                    componentName: "select-translation-type",
                    defaultTitle: _vm.$t(
                      "LanguageTranslations.selectTranslationGroup"
                    ),
                    optionData: _vm.translationTypes,
                    selectedEmitValue: "id",
                    optionTitle: "name",
                    isDisabled: !_vm.toLanguage.id,
                    isDefaultTextActive: false,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.selectTranslationType(
                        _vm.selectedTranslationType
                      )
                    },
                  },
                  model: {
                    value: _vm.selectedTranslationType,
                    callback: function ($$v) {
                      _vm.selectedTranslationType = $$v
                    },
                    expression: "selectedTranslationType",
                  },
                }),
                _vm.renderComponent
                  ? _c(
                      "div",
                      [
                        _vm.selectedGroupType
                          ? _c("itemGroupList", {
                              staticClass: "item-group-filter m-left-15",
                              attrs: { groupType: _vm.selectedGroupType },
                              on: { submitFilter: _vm.getListWithGroupFilter },
                            })
                          : _vm._e(),
                        _vm.selectedTranslationType === 7
                          ? _c("RecipeGroupsFilter", {
                              ref: "recipeGroupsLanguageTranslationsFilter",
                              staticClass: "m-left-15",
                              attrs: {
                                customSelectedData: _vm.recipeGroupFilter,
                              },
                              on: { submitFilter: _vm.getListWithGroupFilter },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "Button",
                  {
                    staticClass: "m-left-15 m-right-15",
                    attrs: {
                      primary: "",
                      size: "small",
                      disabled: !_vm.selectedTranslationType,
                    },
                    on: { click: _vm.getTranslations },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("LanguageTranslations.translateLanguages")
                        )
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.hasTabPermission
        ? _c("template", { slot: "toolbar-right" }, [
            _c(
              "div",
              { staticClass: "translation-filters" },
              [
                _vm.selectedTranslationType === 7
                  ? _c("CustomCheckbox", {
                      staticClass: "checkbox-show-ungrouped m-right-15",
                      attrs: {
                        id: "language-translations-recipe-checkbox-show-ungroup",
                        label: _vm.$t("Recipes.Checkbox_Show_ungrouped"),
                        checked: _vm.showAllRecipes,
                      },
                      on: { change: _vm.showUngroupedRecipes },
                    })
                  : _vm._e(),
                _c("CustomCheckbox", {
                  attrs: {
                    id: "checkbox-language-translations-show-empty-data",
                    checked: _vm.showOnlyEmptyData,
                    label: _vm.$t(
                      "LanguageTranslations.showOnlyEmptyTranslations"
                    ),
                  },
                  on: { change: _vm.showOnlyEmptyTranslations },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                [
                  !_vm.TranslationList.length
                    ? _c("EmptyState", {
                        attrs: {
                          iconName: "empty-state-translations",
                          title: _vm.$t(
                            "LanguageTranslations.emptyState_Title"
                          ),
                          description: _vm.$t(
                            "LanguageTranslations.emptyState_Description"
                          ),
                        },
                      })
                    : _c("TableView", {
                        attrs: {
                          componentName: "languageTranslationsTable",
                          fields: _vm.fields,
                          page: _vm.Page,
                          readOnly: true,
                          data: _vm.TranslationList,
                        },
                        on: {
                          delegateOnChangePage: _vm.changePage,
                          delegateOnChangePageSize: _vm.changePageSize,
                        },
                      }),
                  _c("portal", {
                    attrs: { to: "fromValue" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.isWaitingTranslations ||
                              _vm.isWaitingTranslationUpdate
                                ? _c("div", { staticClass: "loading-box" })
                                : _c("customTextInput", {
                                    attrs: {
                                      id: "input-language-translations-from-value",
                                      value: row.fromValue,
                                      name: "fromValue",
                                      placeholder: row.isEnabled
                                        ? _vm.$t(
                                            "LanguageTranslations.changeSettings_EnterTranslation"
                                          )
                                        : _vm.$t(
                                            "LanguageTranslations.cannotChangeValue"
                                          ),
                                      "data-vv-as": _vm.$t(
                                        "LanguageTranslations.fromValue"
                                      ),
                                      disabled: true,
                                    },
                                  }),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("portal", {
                    attrs: { to: "toValue" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.isWaitingTranslations ||
                              _vm.isWaitingTranslationUpdate
                                ? _c("div", { staticClass: "loading-box" })
                                : _c("customTextInput", {
                                    attrs: {
                                      id: "input-language-translations-to-value",
                                      name: "toValue",
                                      disabled: !row.isEnabled,
                                      placeholder: row.isEnabled
                                        ? _vm.$t(
                                            "LanguageTranslations.toValue_Placeholder"
                                          )
                                        : _vm.$t(
                                            "LanguageTranslations.cannotChangeValue"
                                          ),
                                      "data-vv-as": _vm.$t(
                                        "LanguageTranslations.toValue"
                                      ),
                                    },
                                    on: {
                                      selectInput: function ($event) {
                                        return _vm.getPreviousValue($event, row)
                                      },
                                      blur: function ($event) {
                                        return _vm.updateSelectedTranslation(
                                          $event,
                                          row
                                        )
                                      },
                                    },
                                    model: {
                                      value: row.toValue,
                                      callback: function ($$v) {
                                        _vm.$set(row, "toValue", $$v)
                                      },
                                      expression: "row.toValue",
                                    },
                                  }),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }