<template lang="pug">
Page
  template(slot="content-center")
    .ScheduleJobDetail
      .PageHeaderEkstra.m-bottom-20
        Loading(v-if="isLoadingHeader")
        .Breadcrumb(v-else)
          router-link(
            id="link-schedule-detail-breadcrumb"
            :to="{ name: 'ScheduledJob' }"
          )
            | {{ $t('ScheduledJob.breadcrumb_Schedule_Detail') }}

          span {{ '>' }}

          a(
            href=""
            id="link-recipe-breadcrumb-active"
          ).isSelected
            | {{ breadCrumbActivePath }}

      TableLoading(v-if="isLoadingDetail")
      TableView(
        v-if="!isEmpty && !isLoadingDetail"
        :fields="fields"
        :data="details"
        :componentName="'ScheduledJobTable'"
        :pointer="true"
        :actions=[""]
        :readOnly="true"
        :page="detailPage"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize")

      EmptyState(
      v-if="isEmpty && !isLoadingDetail"
      :title="$t('ScheduledJob.emptyState_NotFound_Title')"
      :description="$t('ScheduledJob.emptyState_NotFound_Description')")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import getFields from './detailFields'
import ModelScheduleDetail from '@/view/pages/ScheduledJob/model/ModelScheduleDetail'
import ModelScheduleHeader from '@/view/pages/ScheduledJob/model/ModelScheduleHeader'

export default {
  data () {
    return {
      header: {},
      details: [],
      fields: getFields(this.$t)
    }
  },
  async beforeMount () {
    this.header = this.getHeaderWithParams()
    this.details = this.getScheduleDetailWithParams()
  },

  computed: {
    ...mapGetters('ScheduleJob', [
      'detailPage'
    ]),
    isEmpty () {
      return !this.details || this.details.length === 0
    },
    breadCrumbActivePath () {
      const header = this.header
      return header.scheduleTypeName + ' & ' + header.period + ' & ' + header.operation + ' & ' + (header.beginDate || '-') + ' / ' + (header.endDate || '-')
    },
    isLoadingDetail () {
      return this.$wait.is('getScheduleDetailWithParams')
    },
    isLoadingHeader () {
      return this.$wait.is('getHeaderWithParams')
    }
  },
  methods: {
    ...mapActions('ScheduleJob', [
      'getScheduleDetail',
      'getSchedule'
    ]),

    getHeaderWithParams () {
      this.$wait.start('getHeaderWithParams')
      this.getSchedule({Id: this.$route.params.id})
        .then(res => {
          this.header = new ModelScheduleHeader(res.data.schedule, this.$t)
          this.$wait.end('getHeaderWithParams')
        })
        .then(() => this.$wait.end('getHeaderWithParams'))
    },

    changePage (page) {
      this.getScheduleDetailWithParams(page)
    },

    changePageSize (pageSize) {
      this.getScheduleDetailWithParams(1, pageSize)
    },

    getScheduleDetailWithParams (pageNumber = 1, pageSize = this.detailPage.size) {
      this.$wait.start('getScheduleDetailWithParams')
      const params = {
        pageSize,
        pageNumber,
        ScheduleId: this.$route.params.id
      }
      this.getScheduleDetail(params)
        .then(res => {
          this.details = res.data.historyList.map(detail => new ModelScheduleDetail(detail, this.$t))
        })
        .then(() => this.$wait.end('getScheduleDetailWithParams'))
    }
  }
}
</script>

<style lang="scss" scoped>

  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;
    padding: 15px 0;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }
</style>
