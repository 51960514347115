var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "Auth-form ForgotPassword-form Form",
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c("h1", { staticClass: "Auth-form-title" }, [
        _vm._v(_vm._s(_vm.$t("ResetPassword.formHeaderTitle"))),
      ]),
      _c("div", { staticClass: "Form-item" }, [
        _c("p", { staticClass: "description" }, [
          _vm._v(_vm._s(_vm.$t("ResetPassword.formDescription"))),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "Form-item password",
          class: { "is-show": _vm.isShowPassword },
        },
        [
          _c("label", { staticClass: "Form-item-label" }, [
            _vm._v(_vm._s(_vm.$t("ResetPassword.newPassword"))),
          ]),
          _c(
            "div",
            { staticClass: "control" },
            [
              _vm.isPasswordType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.newPassword,
                        expression: "form.newPassword",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6",
                        expression: "'required|min:6'",
                      },
                    ],
                    staticClass: "txt txt-right-icon",
                    class: { "is-danger": _vm.veeErrors.has("password") },
                    attrs: {
                      id: "input-reset-password-password",
                      name: "password",
                      placeholder: _vm.$t(
                        "ResetPassword.newPasswordPlaceholder"
                      ),
                      "data-vv-as": _vm.$t("ResetPassword.newPassword"),
                      autocomplete: "off",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.form.newPassword)
                        ? _vm._i(_vm.form.newPassword, null) > -1
                        : _vm.form.newPassword,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.form.newPassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.form,
                                "newPassword",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.form,
                                "newPassword",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.form, "newPassword", $$c)
                        }
                      },
                    },
                  })
                : _vm.isPasswordType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.newPassword,
                        expression: "form.newPassword",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6",
                        expression: "'required|min:6'",
                      },
                    ],
                    staticClass: "txt txt-right-icon",
                    class: { "is-danger": _vm.veeErrors.has("password") },
                    attrs: {
                      id: "input-reset-password-password",
                      name: "password",
                      placeholder: _vm.$t(
                        "ResetPassword.newPasswordPlaceholder"
                      ),
                      "data-vv-as": _vm.$t("ResetPassword.newPassword"),
                      autocomplete: "off",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.form.newPassword, null) },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.form, "newPassword", null)
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.newPassword,
                        expression: "form.newPassword",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6",
                        expression: "'required|min:6'",
                      },
                    ],
                    staticClass: "txt txt-right-icon",
                    class: { "is-danger": _vm.veeErrors.has("password") },
                    attrs: {
                      id: "input-reset-password-password",
                      name: "password",
                      placeholder: _vm.$t(
                        "ResetPassword.newPasswordPlaceholder"
                      ),
                      "data-vv-as": _vm.$t("ResetPassword.newPassword"),
                      autocomplete: "off",
                      type: _vm.isPasswordType,
                    },
                    domProps: { value: _vm.form.newPassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "newPassword", $event.target.value)
                      },
                    },
                  }),
              _c("Button", {
                attrs: {
                  id: "btn-reset-password-show-password",
                  type: "button",
                  variant: "icon switch",
                  iconName: _vm.isShowPassword
                    ? "icon-login-eye"
                    : "icon-login-eye-closed",
                  iconClass: _vm.isShowPassword
                    ? "icon-login-eye"
                    : "icon-login-eye-closed",
                },
                on: { click: _vm.showPassword },
              }),
            ],
            1
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.veeErrors.has("password"),
                  expression: "veeErrors.has('password')",
                },
              ],
              staticClass: "Form-item-help is-danger",
            },
            [_vm._v(_vm._s(_vm.veeErrors.first("password")))]
          ),
          _c(
            "div",
            { staticClass: "Form-item x2" },
            [
              _c(
                "Button",
                {
                  class:
                    _vm.isFormReady && !_vm.isLoading
                      ? "btn-success"
                      : "btn-gray",
                  attrs: {
                    size: "medium",
                    type: "submit",
                    id: "btn-reset-password-button",
                    disabled: !_vm.isFormReady || _vm.isLoading,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isFormReady || !_vm.isLoading,
                          expression: "isFormReady || !isLoading",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(_vm.$t("ForgotPassword.formItemSubmit")))]
                  ),
                  _c("Loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading,
                        expression: "isLoading",
                      },
                    ],
                    attrs: { theme: "disable" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }