<template lang="pug">
Page
  template(slot="header-title")
    span {{ $t('Waste.pageHeader_Waste') }}
  template(slot="content-center")
    TableLoading(v-if="isLoading")
    .Result.m-bottom-15
      .Result-top-header-title
        .doc-number {{documentNumber}}
        span.m-left-5 {{$t('Despatches.document')}}
    TableView.m-top-15(
      :componentName="'wasteDocumentDetail'"
      :fields="wasteDetailFields"
      :actions="['']"
      :data="wasteDetail"
      :readOnly="true"
    )
    portal(to="unitName")
      div(slot-scope="row")
        span {{ findUnit(row.unitId).name }}

    portal(to="baseUnitName")
      div(slot-scope="row")
        span {{ findUnit(row.baseUnitId).name }}

</template>

<script>

import getWasteDocumentDetailFields from './fields/wasteDetail'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WasteDocumentDetail',

  data () {
    return {
      wasteDetailFields: getWasteDocumentDetailFields(this.$t),
      wasteDetail: []
    }
  },

  computed: {
    ...mapGetters('Units', ['findUnit']),

    isLoading () {
      return this.$wait.is(['getWasteHeader'])
    },
    documentNumber () {
      return this.$route.params.documentNumber
    }
  },
  async mounted () {
    await this.getWaste(this.$route.params.id)
  },
  methods: {
    ...mapActions('Waste', ['getWasteHeader']),

    async getWaste (item) {
      await this.getWasteHeader(item).then(res => {
        if (res) {
          this.wasteDetail = res.data.wasteDetailList
        }
      })
    }
  }
}
</script>

<style lang="scss">

.Result{
  &-top-header-title{
    color: $color-primary;
    display: flex;
  }
}
.doc-number {
  font-weight: bold;
  text-decoration: underline;
}
</style>
