var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyWasteGroups
            ? _c(
                "Button",
                {
                  attrs: {
                    size: "medium",
                    primary: "",
                    id: "btn-recipes-new",
                    type: "button",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.addNewWasterGroup },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("WasteGroup.addWasteGroup"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeGroup", {
            ref: "storeGroupFilter",
            staticClass: "m-right-10",
            attrs: { isPermitted: true },
            on: { submitFilter: _vm.submitFilteredStoreGroup },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "WasteGroupPageContent" },
          [
            _vm.isLoading ? _c("TableLoading") : _vm._e(),
            _vm.isEmptyWasteGroups && !_vm.isLoading
              ? _c("EmptyState", {
                  attrs: {
                    title: _vm.$t("WasteGroup.emptyTitle"),
                    description: _vm.$t("WasteGroup.empty_description"),
                    buttonText: _vm.$t("WasteGroup.addWasteGroup"),
                  },
                  on: { emptyAddButton: _vm.addNewWasterGroup },
                })
              : _vm._e(),
            !_vm.isEmptyWasteGroups && !_vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "Boxlist" },
                  _vm._l(_vm.WasteGroupList, function (list, index) {
                    return _c("div", { staticClass: "Boxlist-item" }, [
                      _c(
                        "div",
                        { staticClass: "Boxlist-actions" },
                        [
                          _c("ActionButton", {
                            attrs: { data: ["edit", "remove"], item: list },
                            on: {
                              onItemEdit: function ($event) {
                                return _vm.editWasteGroup(list)
                              },
                              onItemDelete: function ($event) {
                                return _vm.deleteWasteGroup(list)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "Boxlist-body tooltip-relative" },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "title",
                              attrs: {
                                id: `box-list-waste-groups-title-${index}`,
                              },
                            },
                            [_vm._v(_vm._s(_vm.getName(list.name)))]
                          ),
                          list.name.length > 30
                            ? _c("Tooltip", {
                                attrs: {
                                  white: "",
                                  bottom: "",
                                  text: list.name,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c("router-view", { on: { getList: _vm.submitFilter } }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }