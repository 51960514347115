<template lang="pug">
.row(:class="{ 'is-multiple': detail.unitCountList.length > 1 }" :id="id")
  .col.largeCol(:id="`table-inventory-detail-body-col-0-${index}`") {{ detail.itemGroupName }}
  .col.largeCol(:id="`table-inventory-detail-body-col-1-${index}`") {{ detail.stockItemName }}
  .col.u-textRight(:class="{ 'text-is-danger': detail.expectedQuantity < 0 }" :id="`table-inventory-detail-body-col-2-${index}`") {{ detail.expectedQuantity }} {{ detail.baseUnitCode }}

  .col.unitCountLabels.u-textRight(:id="`table-inventory-detail-body-col-3-${index}`")
    label.unitCountLabels-item(
    v-for="unitCountItem in detail.unitCountList"
    :for="`${detail.stockItemId}-unitCount-${unitCountItem.unitId}`")
      | {{ unitCountItem.unitName }}

  .col.unitCountValues(:id="`table-inventory-detail-body-col-4-${index}`")
    .unitCountValues-row(v-for="(unitCountItem, index) in detail.unitCountList")
      input.txt.u-textRight(
      :name="`${detail.stockItemId}-unitCount-${unitCountItem.unitId}`",
      :id="`input-inventory-unitCount-${index}`",
      autocomplete="off",
      v-model="unitCounts[unitCountItem.unitId]",
      v-validate="{min_value:0, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
      @change="onChangeUnitCount(unitCountItem.unitId)"
      :class="{ 'is-danger': veeErrors.has(`${detail.stockItemId}-unitCount-${unitCountItem.unitId}`), 'first-input': index === 0 , 'last-input': detail.unitCountList.length - 1 === index}",
      :disabled="isStoreTypeCostCenter"
      type="text")

  .col.unitCountValues(:id="`table-inventory-detail-body-col-5-${index}`")
    .unitCountValues-row.is--text(v-for="(unitCountItem, index) in detail.unitCountList")
      .unitCountValues-baseUnit.u-textRight(v-if="unitCounts[unitCountItem.unitId] > 0")
        | {{ actualQuantityIndividual(unitCountItem).toFixed(3) }} {{ detail.baseUnitCode }}

  .col.u-textRight(:id="`table-inventory-detail-body-col-6-${index}`")
    template(v-if="hasActualQuantityValue") {{ actualQuantityTotal.toFixed(3) }} {{ detail.baseUnitCode }}
    template(v-else) -

  .col.u-textRight(:id="`table-inventory-detail-body-col-7-${index}`")
    template(v-if="hasActualQuantityValue") {{ formattedActualQuantityDifference }}
    template(v-else) -

  .col.u-textRight(:id="`table-inventory-detail-body-col-8-${index}`")
    template(v-if="hasActualQuantityValue && differenceQuantityPercent !== '!'") %
      | {{ differenceQuantityPercent }}
    template(v-else) -
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'InventoryDetailRow',

  props: {
    id: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default () {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },

  inject: ['$validator'],

  data () {
    return {
      /* {
          [unitId]: Int,
          // ...
        } */
    }
  },
  computed: {
    ...mapGetters('Inventories', ['inventory_Header']),
    formattedActualQuantityDifference () {
      return this.differenceQuantity.toFixed(3)
    },

    unitCounts () {
      return this.$props.detail.unitCountList.reduce((counts, item) => {
        return Object.assign({}, counts, {
          [item.unitId]: item.unitQuantity
        })
      }, {})
    },

    actualQuantityIndividual () {
      return unitCountItem => this.calculateActualBaseQuantityOfUnit(unitCountItem)
    },

    actualQuantityTotal () {
      return this.detail.unitCountList.reduce((total, unitCountItem, index) => {
        return total + this.calculateActualBaseQuantityOfUnit(unitCountItem)
      }, 0) + this.detail.quantityByList
    },

    hasActualQuantityValue () {
      return Object.keys(this.unitCounts).some(unitCountKey => {
        const value = parseInt(this.unitCounts[unitCountKey], 10)
        return typeof value === 'number' && !Number.isNaN(value)
      }) || this.detail.quantityByList > 0
    },

    differenceQuantity () {
      return this.detail.expectedQuantity - this.actualQuantityTotal
    },

    differenceQuantityPercent () {
      const percent = (this.differenceQuantity / this.detail.expectedQuantity) * 100
      if (isNaN(percent) || percent === -Infinity) {
        return '!'
      }
      return parseFloat(percent).toFixed(2)
    },

    isStoreTypeCostCenter () {
      return this.inventory_Header ? this.inventory_Header.storeType === 3 : false
    }
  },

  methods: {
    onChangeUnitCount (unitId) {
      this.$validator.validateAll().then(result => {
        if (!result) return
        const unitCountData = this.detail.unitCountList.find(u => u.unitId === unitId)
        this.$emit('changeUnitCount', {
          stockItemId: this.detail.stockItemId,
          unitId: unitId,
          inventoryCountId: unitCountData.inventoryCountId,
          quantity: parseFloat(this.unitCounts[unitId]) || 0
        })
      })
    },

    calculateActualBaseQuantityOfUnit (unitCountItem) {
      const baseQuantity = unitCountItem.baseQuantity
      const unitCount = parseFloat(this.unitCounts[unitCountItem.unitId] || 0) || 0
      return baseQuantity * unitCount
    }
  }
}
</script>

<style lang="scss">

  .unitCountValues {
    position: relative;

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      &.is--text {
        min-height: 41px;
      }
    }

    &-baseUnit {
      flex: 1;
      margin-left: 10px;
    }

    .txt {
      width: 77px;
      box-shadow: none;
    }

    .btn {
      width: auto;
      position: absolute;
      right: 11px;
      top: 11px;
      height: $finger-size - 2;
    }
  }

  .row.is-multiple {
    align-items: flex-start !important;

    .col {
      padding-top: 27px !important;
      padding-bottom: 13px !important;
    }

    .unitCountLabels {
      margin-top: -14px;

      &-item {
        display: block;
        line-height: 41px; // same as .txt input on the right side
      }
    }

    .unitCountValues {
      padding-top: 14px !important;

      .txt:not(.first-input):not(.last-input){
        border-radius: 0;
      }
      .txt:not(:focus):not(.first-input) {
        border-top: transparent;
      }

      .txt.first-input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .txt.last-input {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
</style>
