<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "requestHeaderLinks"
      translationGroup="Request"
    )
  template(slot="header-left-search")
    Search.search-box(
        componentName="requestsTransfer",
        suggestionValue="name"
        :isSuggestionLoading="isSuggestionLoading"
        :suggestions="selectedStockItem"
        :customSuggestionPlaceHolder="$t('DirectOrders.searchPlaceholder')"
        :customSuggestionTitle="$t('DirectOrders.orders_search_SuggestionTitle')"
        @onChangeSearchValue="onChangeSearch"
        @onChangeQueryItem="setStockItem"
        @clearSearch="clearSearch"
      )
  template(slot="header-right-dropdown")
    CustomDatepicker.m-right-15(
      v-model="date"
      :range="false"
      :useFilterReset="true"
      pickerType="manuel"
      @resetDateFilter="resetDateFilter"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium",
      id="btn-add-new-transfer-request-detail",
      success,
      @click="addNewDetail"
    )
      span {{$t('Global.addDetail')}}
  
  template(slot="toolbar-left")
    storeFilter(
      :label="$t('RequestedTransfer.tableFieldSenderStore')"
      @submitFilter="getListWithFromStoreFilter"
      ref="requestedTransferFromStoreList")

    BusinessMultiselect(
      componentName="requested-transfer-to-store-filter"
      :multiselectItems="sortedStoreList"
      :label="$t('RequestedTransfer.tableFieldStore')"
      :popupHeader="$t('Global.toolbar_Stores_PopupHeader')"
      ref="requestedTransferStoreListFilter"
      @submitFilter="getListWithStoreFilter")

    itemGroupList(
      groupType="itemGroup"
      @submitFilter="getListWithGroupFilter"
      ref="requestedTransferItemGroup"
    )

    Button.clear-filter-button.border-gray-1.p-0.width-auto(
    @click='clearFilter'
    )
      Icon.icon-clear-filter(name="icon-clear-filter")
  template(slot="toolbar-right")
    .popup-container(
      v-click-outside="hideReasonPopup"
    )
      Button(
        secondary,
        size="small",
        variant="full"
        id="btn-requests-passive-transfer",
        @click="showPassiveReasonForm",
        :disabled="selectedItems.length === 0"
        v-show="!isEmptyTransferList")
        span {{ $t('Orders.makePassive') }}
      form.order-passive-description-right-MiniPopup(
        v-if="showReasonButtonPopup"
        id="order-description-reason-form"
        @submit.prevent="makeTransfersPassive"
      )
        label.order-description-MiniPopup-label.required(
          id="order-description-reason-label"
        ) {{ $t('Orders.Reason_Description_Input_Label') }}

        customTextareaInput(
          id="transfer-description-reason-input"
          name="reason"
          v-model="reasonDescription"
          :data-vv-as="$t('Orders.reasonDescription')"
          :error="veeErrors.has('reason')"
          v-validate="{required: true, max: 128}"
        )
        showValidateError(
          v-show="veeErrors.has('reason')"
          :errorName="veeErrors.first('reason')"
        )
        Button.m-top-15.m-left-0(
          primary
          size="medium"
          type="submit"
          id="transfer-description-reason-submit"
          :disabled="selectedItems.length === 0 || isMakingPassive"
        )
          span {{$t('Orders.makePassive')}}
          Loading(v-if="isMakingPassive", theme="disable")

    Button.m-left-10(
      primary,
      id="btn-turn-to-transfer"
      type="submit"
      size="small"
      form="form-approve-transfer"
      iconName="icon-btn-success"
      v-if="!isEmptyTransferList"
      :disabled="isFetchingList || !isFormValid || selectedItems.length === 0 || isActionSavedAction"
      :isLoading="isActionSavedAction")
      span {{ $t('RequestedTransfer.createTransferRequest') }}

  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    template(v-else)

      TableLoading(v-if="isFetchingList || isPageLoading")
      EmptyState(
      v-if="isEmptyTransferList && !isFetchingList && !isPageLoading"
      :title="$t('RequestedTransfer.emptyState_NotFound_Title')"
      :description="$t('RequestedTransfer.emptyState_NotFound_Description')")
      form.RequestTransferForm(
        v-if="!isEmptyTransferList && !isFetchingList && !isPageLoading",
        id="form-approve-transfer"
        @submit.prevent="createRequests")
        .row.header#table-header-row-request-transfer.fixed-header
          .col.ch#table-header-col-request-transfer-select-all.fixed-title
            CustomCheckbox(
              id="checkbox-request-order-select-all"
              :checked="isCheckedAll"
              label=""
              @change="toggleAll"
            )
          .col.required#table-header-col-request-transfer-sender-store.fixed-title {{ $t('RequestedTransfer.tableFieldSenderStore') }}
          .col#table-header-col-request-transfer-store.fixed-title {{ $t('RequestedTransfer.tableFieldStore') }}
          .col#table-header-col-request-transfer-stock-item.fixed-title {{ $t('RequestedTransfer.tableFieldStockItem') }}
          .col.required#table-header-col-request-transfer-date {{ $t('RequestedTransfer.tableFieldDate') }}
          .col.u-textRight#table-header-col-request-transfer-onhand {{ $t('RequestedTransfer.tableFieldOnHand') }}
          .col#table-header-col-request-transfer-unit {{ $t('RequestedTransfer.tableFieldUnit') }}
          .col.u-textRight#table-header-col-request-transfer-unit {{ $t('RequestedTransfer.tableFieldRQuantity') }}
          .col.u-textRight.required#table-header-col-request-transfer-quantity {{ $t('RequestedTransfer.tableFieldQuantity') }}
          .col.u-textRight#table-header-col-request-transfer-diff-quantity {{ $t('RequestedTransfer.tableFieldDiffQuantity') }}
          .col.u-textRight#table-header-col-request-transfer-unit-price {{ $t('RequestedTransfer.tableFieldUnitPrice') }}
          .col.base-unit-quantity.u-textRight#table-header-col-request-transfer-base-unit-quantity  {{ $t('RequestedTransfer.tableFieldBaseUnitQuantity') }}
          .col.m-left-20#table-header-col-request-transfer-base-unit {{ $t('RequestedTransfer.tableFieldBaseUnit') }}
          .col.u-textRight#table-header-col-request-transfer-total  {{ $t('RequestedTransfer.tableFieldTotal') }}
          .col.u-textRight#table-header-col-request-transfer-itemGroupName  {{ $t('RequestedTransfer.tableFielditemGroupName') }}
          .col
        .row(v-for="(item,index) in items" :key="item.id" :id="`table-body-row-request-transfer-${index}`")
          .col.ch.fixed
            CustomCheckbox(
              :id="`checkbox-requested-transfer-${index}`"
              label="",
              :checked="selectedItems.indexOf(item) > -1",
              @change="isChecked => toogleSelectItem(isChecked, item)"
            )

          .col.fixed(:id="`from-store-name-requested-transfer-${index}`") {{ item.fromStoreName }}
          .col.fixed(:id="`store-name-requested-transfer-${index}`") {{ item.storeName }}
          .col.fixed(:id="`stock-name-requested-transfer-${index}`") {{ item.stockItemName }}
          .col(:id="`date-picker-container-requested-transfer-${index}`")
            .field
              CustomDatepicker.custom-picker(
                :id="`date-picker-requested-transfer-${index}`"
                :name="`requestDate-${index}`",
                v-model="items[index].requestDate",
                :class="{ 'is-danger': veeErrors.has(`requestDate-${index}`) }",
                position="left"
                v-validate="{ required: true, date_format:'YYYY-MM-DD', before: dateLimit }",
                pickerType="manuel",
                inputIconRight="icon-arrow-down",
                :disabledStartDate="disabledDate"
                @change="getStockItemInfo(item)"
                :isCheckingPosition="false"
                inputIcon="")
          .col.u-textRight(:id="`onhand-requested-transfer-${index}`") {{ item.stockOnHandQuantity }}
          .col(:id="`unit-name-requested-transfer-${index}`") {{ findUnit(item.unitId) ? findUnit(item.unitId).name : ''}}
          .col.u-textRight(:id="`quantity-requested-transfer-${index}`") {{ item.sourceType === 2 ? 0 : item.quantity}}
          .col.u-textRight(:id="`transferred-quantity-requested-transfer-${index}`")
            custom-number-input(
              :name="`quantity${item.id}`",
              :id="`input-transfer-request-quantity-${index}`"
              v-model.number="items[index].transferedQuantity",
              :disabled="Tenant.approvalRequestTransferPermission",
              :data-vv-as="$t('RequestedTransfer.tableFieldQuantity')"
              v-validate="{required: true, greater_than:-1, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), multiplyTwoField: item.unitPrice}",
              :class="{ 'is-danger': veeErrors.has(`quantity${item.id}`)}"
            )
          .col.u-textRight(:id="`quantity-some-requested-transfer-${index}`")  {{ item.transferedQuantity - item.quantity }}
          .col.u-textRight(:id="`unit-price-requested-transfer-${index}`") {{ item.unitPrice | formatCurrency }}
          .col.base-unit-quantity.u-textRight(:id="`quantity-2-requested-transfer-${index}`")  {{ (item.baseQuantity / item.quantity) * item.transferedQuantity }}
          .col.m-left-20(:id="`base-unit-name-requested-transfer-${index}`") {{ findUnit(item.baseUnitId) ? findUnit(item.baseUnitId).name : ''}}
          .col.u-textRight(:id="`total-quantity-requested-transfer-${index}`")  {{ item.unitPrice * item.transferedQuantity | formatCurrency }}
          .col.u-textRight(:id="`total-quantity-requested-transfer-${index}`")  {{ item.itemGroupName }}
          .col
            Button.show-MiniPopup.relative.width-max-content(
              id="transfer-approval-header-info-button"
              iconName="icon-global-info"
              v-if="item.description"
              :justIcon="true",
              class="btn-info"
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ item.description }}
        
    router-view(@getList="getFilteredRequestOrders")
    TableFoot(
      v-if="!isEmptyTransferList && !isFetchingList && !isPageLoading"
      :page="Page"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
    )

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//
import moment from 'moment'
import storeFilter from '@/view/global/business-filters/store.vue'
import { vueWaitLoader } from '@/utils/baseOperations'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import itemGroupList from '@/view/global/business-filters/groups.vue'
import TableFoot from '@/view/global/table/foot.vue'
import BusinessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {
  name: 'TransferRequest',

  mixins: [hasTabPermission],

  data () {
    return {
      items: {},
      selectedItems: [],
      stockItem: {},
      selectedStockItem: [],
      search: null,
      storeFilter: [],
      fromStoreList: [],
      groupFilter: [],
      isPageReady: false,
      showReasonButtonPopup: false,
      reasonDescription: null,
      date: null,
      disabledDate: {
        from: new Date()
      },
      dateLimit: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
    }
  },

  components: {
    storeFilter,
    NoPermission,
    itemGroupList,
    TableFoot,
    BusinessMultiselect
  },

  computed: {
    ...mapGetters('Request', ['requestHeaderLinks']),
    ...mapGetters('RequestedTransfers', ['transfers', 'Page']),
    ...mapGetters('Units', ['findUnit']),
    ...mapGetters('Stores', ['miniStoreList', 'sortedStoreList']),
    ...mapGetters('Auth', ['Tenant']),

    isFetchingList () {
      return this.$wait.is('getAllRequestedTransfer')
    },
    isEmptyTransferList () {
      return this.transfers.length === 0
    },
    isCheckedAll () {
      return this.selectedItems.length === this.items.length
    },
    isSuggestionLoading () {
      return this.$wait.is(['Order/requested/all'])
    },
    isMakingPassive () {
      return this.$wait.is(['makeTransfersPassive'])
    },
    isFormValid () {
      const validFail = this.selectedItems.filter(item => {
        return (item.transferedQuantity === '' || parseFloat(item.transferedQuantity) < 0 || item.requestDate === null || item.fromStoreId === '-1')
      })
      return validFail.length === 0
    },
    isActionSavedAction () {
      return this.$wait.is('approvedTransferRequest')
    },
    isPageLoading () {
      return this.$wait.is('pageLoading')
    }
  },

  methods: {
    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    ...mapActions('RequestedTransfers', [
      'getAllRequestedTransfer',
      'approvedTransferRequest'
    ]),
    ...mapActions('Orders', ['makeOrderRequestPassive'] ),
    ...mapActions('Stores', ['getMiniStoreList', 'getStoreList']),
    ...mapActions('Transfer', ['getStockInfo']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Auth', ['getTenant']),

    makeTransfersPassive: vueWaitLoader(async function () {
      const payload = {
        ids: this.selectedItems.map(item => { return item.id }),
        statusReason: this.reasonDescription
      }
      await this.makeOrderRequestPassive(payload)
        .then(async res => {
          const message = this.$t('Orders.make_transfer_Passive')
          if (res) {
            this.notifyShow({ message })
            this.hideReasonPopup()
            this.reasonDescription = null
            this.selectedItems = []
            this.$validator.reset()
            this.getFilteredRequestOrders() 
          }
        })
    }, 'makeTransfersPassive'),

    showPassiveReasonForm () {
      this.showReasonButtonPopup = !this.showReasonButtonPopup
    },
    hideReasonPopup () {
      this.showReasonButtonPopup = false
    },

    resetDateFilter () {
      this.date = null
    },

    addNewDetail () {
      this.$router.push({
        name: 'TransferRequestDetailForm'
      })
    },

    toggleAll () {
      if (this.isCheckedAll) {
        this.selectedItems = []
      } else {
        this.selectedItems = [...this.items]
      }
    },

    toogleSelectItem (isChecked, item) {
      if (isChecked) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
      }
    },

    async getListWithFromStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.fromStoreList = list
      else {
        this.fromStoreList = list
        await this.getFilteredRequestOrders()
      }
    },

    async getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.storeFilter = list
        await this.getFilteredRequestOrders()
      }
    },
    getListWithGroupFilter ({list, dontSubmit}) {
      if (dontSubmit) this.groupFilter = list
      else {
        this.groupFilter = list
        this.getFilteredRequestOrders()
      }
    },
    changePage (page) {
      this.selectedItems = []
      this.getFilteredRequestOrders(page)
    },
    changePageSize (pageSize) {
      this.selectedItems = []
      this.getFilteredRequestOrders(1, pageSize)
    },
    async getFilteredRequestOrders (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        stockItemId: this.stockItem ? this.stockItem.id : null,
        fromStoreList: this.fromStoreList ? this.fromStoreList.map(item => item.id) : [],
        toStoreList: this.storeFilter ? this.storeFilter.map(s => s.id) : [],
        itemGroupIds: this.groupFilter ? this.groupFilter.map(item => item.id) : [],
        date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
        pageNumber: pageNumber,
        pageSize
      }
      const response = await this.getAllRequestedTransfer(payload)
      this.items = [
        ...this.transfers.map(item => ({
          ...item,
          requestDate: moment(item.requestDate).format('YYYY-MM-DD'),
          transferedQuantity: item.quantity
        }))
      ]
      if (response) {
        this.$nextTick(() => {
          this.makeValidation()
        })
      }
      return response
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) {
        this.resetStockItemFilter()
        this.clearSearch()
      } else {
        this.searchStockItem({ Text: search, types: [1, 2, 3] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },

    sortItemsByDate () {
      this.items = this.items.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate))
    },

    clearSearch () {
      this.search = null
      this.stockItem = {}
      this.resetStockItemFilter()
    },

    clearFilter () {
      this.$refs.requestedTransferFromStoreList.clearStoreFilter()
      this.$refs.requestedTransferStoreListFilter.clearAllFilter()
      this.$refs.requestedTransferItemGroup.clearGroupFilter(false)
    },

    resetStockItemFilter () {
      this.selectedStockItem = []
      this.getFilteredRequestOrders()
    },

    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.resetStockItemFilter()
    },

    async getStockItemInfo (item) {
      const transferItem = this.items.find(_ => _.id === item.id)
      if (item.fromStoreId === '-1') {
        transferItem.onHandQuantity = null
        return false
      }
      const requestData = {
        StoreId: item.fromStoreId,
        ToStoreId: item.storeId,
        StockItemId: item.stockItemId,
        UnitId: item.unitId,
        ForDate: moment(item.requestDate).format('YYYY-MM-DD')
      }
      const { data } = await this.getStockInfo(requestData)
      Object.assign(transferItem, {
        onHandQuantity: data.unitQuantity,
        unitPrice: data.unitPrice
      })

    },

    createRequests: vueWaitLoader(async function () {
      const approvedRequestList = this.selectedItems.map(item => {
        return {
          requestDetailId: item.id,
          requestDate: moment(item.requestDate).format('YYYY-MM-DD'),
          fromStoreId: item.fromStoreId,
          quantity: item.transferedQuantity
        }
      })

      const res = await this.approvedTransferRequest(approvedRequestList)
      const message = this.$t('RequestedTransfer.create_success_message')
      if (res) {
        this.$router.push({
          name: 'Transfer',
          params: {
            showTransferDetail: true
          }
        })
        this.notifyShow({ message })
      }
    }, 'createRequest'),
    async makeValidation () {
      const isValid = await this.$validator.validateAll()
      return isValid
    }
  },

  watch: {
    date (newVal, oldVal) {
      if (oldVal === newVal) return
      this.getFilteredRequestOrders()
    }
  },

  async beforeMount () {
    if (!this.hasTabPermission) return

    this.$wait.start('pageLoading')
    await this.getStoreList({pageNumber: 1, pageSize: 9999, ignorePermission: true})
    await this.getFilteredRequestOrders()
    await this.getTenant({id: this.Tenant.id})
    this.$wait.end('pageLoading')
    this.$nextTick(() => {
      this.makeValidation()
    })
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";

  .toolbar {
    &.footer {
      position: absolute;
      right: 0;
      margin-top: 80px;
    }
    .order-passive-description-right-MiniPopup {
      @include MiniPopupMixin(
        $width: 296px,
        $margin: 45px 95px 0 0,
        $triangle-direction: 'right',
        $border-color: $color-light-blue,
        $padding-hor: 25px,
        $z-index: $z-index-xl,
      );
    }
  }

  .pageLoading {
    top: inherit;
    left: inherit;
  }
 .PageHeader {
    padding: 0;
  }
  .RequestTransferForm {
    overflow-x: scroll;
    background: $color-white;
    min-height: 300px;
    max-height: 700px;
    .custom-picker {
      .icon-arrow-down {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 16px;
        z-index: 2;
        color: $color-text-light;
      }
    }
    .row {
      min-width: 2200px;
      display: grid;
      grid-template-columns: 60px 1fr 1fr 210px 1fr repeat(10, 130px) 1fr;
      height: $finger-size-big;
      align-items: center;
      border-bottom: 1px solid $color-gray;
      border-left: 1px solid $color-gray;
      border-right: 1px solid $color-gray;
      font-size: $font-size-small;
      &.header {
        background: $color-lighter-blue;
        color: $color-success;
        font-size: $font-size-small;
        font-weight: bold;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border-top: 1px solid $color-gray;
      }
      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
      .col {
        flex-shrink: 0;
        margin-right: 20px;
        word-break: break-all;
        &:nth-child(1) {
          margin-right: 0 !important;
          width: 80px !important;
        }
        &.required {
          &::after {
            content: '*';
            color: $color-warning;
            margin-left: 4px;
            font-size: 18px;
          }
        }
        .field {
          position: relative;
          .icon-arrow-down {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 16px;
          }
          select.txt {
            appearance: none;
          }
        }
      }
    }
  }

  .fixed {
    position: sticky;
    left: 0;
    background: white;
    z-index: $z-index-sm;
    padding: 20px 0;
    &:nth-child(2) {
      left: 80px;
    }
    &:nth-child(3) {
      left: 220px;
    }
    &:nth-child(4) {
      left: 350px;
    }
    &-title {
      background: $color-lighter-blue !important;
      position: sticky;
      left: 0;
      z-index: $z-index-sm;
      &:nth-child(2) {
        left: 80px;
      }
      &:nth-child(3) {
        left: 220px;
      }
      &:nth-child(4) {
        left: 350px;
      }
    }
    &-header {
      position: sticky;
      top: 0;
      z-index: $z-index-lg;
    }
  }
  .base-unit-quantity {
    width: 125px;
  }
</style>
