<template lang="pug">
Page
  template(slot="header-left-dropdown")
    div
  template(slot="content-center")
    .Reportings
      TableLoading(v-if="isFetchingReportGroups")
      .reports(v-else)
        .report(
          v-for="group in ReportGroups",
          :key="group.id"
        )
          .title
            h4 {{ group.name }}
          .items
            .item(v-if="group.reports.length === 0") {{ $t('Reports.Reporting_Group_Empty_Message') }}
            router-link.item(
              v-else
              v-for="report in group.reports"
              :key="report.id"
              :to="{ name: 'ReportItem', params: { id: report.id, name: report.name }}"
            )
              .reportname {{ report.name }}
              .symbol
                Icon(name="icon-arrow-right")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//

export default {
  name: 'Reportings',

  computed: {
    ...mapGetters('Reportings', ['ReportGroups']),

    isFetchingReportGroups () {
      return this.$wait.is('fetchReportGroupsPermitted')
    }
  },

  methods: {
    ...mapActions('Reportings', ['fetchReportGroupsPermitted'])
  },

  async mounted () {
    await this.fetchReportGroupsPermitted()
  }
}
</script>

<style lang="scss" scoped>

  .reports {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 275px);
    .report {
      border: 1px solid $color-gray;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      height: max-content;
      .title {
        padding-left: 20px;
        padding-right: 20px;
        background: #244551;
        color: $color-white;
        min-height: 54px;
        display: flex;
        align-items: center;
        border-radius: inherit;
      }

      .items {
        background: $color-white;
        .item {
          display: flex;
          min-height: 54px;
          justify-content: space-between;
          padding: 17px 20px;
          &:not(:last-child) {
            border-bottom: 1px solid $color-gray;
          }
          .symbol {
            background: rgba($color-orange, .13);
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              color: $color-orange;
              width: 6px;
              height: 10px;
            }
          }
        }
      }
    }
  }
</style>
