<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
        template(slot="linkExtra", slot-scope="link")
          Icon.icon-suspended-status.m-right-5(
          v-if="suspendSalesExist && link.linkName === 'SuspendedSales'",
        name="icon-row-status")
  template(slot="header-left-search")
    Search.search-box(
      componentName="RevenueCenters"
      @onChangeSearchValue="onChangeSearch"
    )
  template(slot="header-right-content")
    Button.top-button.m-right-10(
        size="medium"
        primary
        id="btn-revenue-centers-add"
        type="button"
        @click="onClickCreateRevenueCenterButton")
          Icon.m-right-5(name="icon-global-add")
          span {{ $t('RevenueCenters.addNewRevenueCenterButton') }}
  template(slot="toolbar-right")
    ViewType(
      firstNodeIcon="icon-view-type-list"
      secondNodeIcon="icon-view-type-group"
      :firstSelected="viewTypeList"
      @firstNodeClick="listViewType(true)"
      @secondNodeClick="listViewType(false)"
    )
  template(slot="content-center")
    TableLoading(v-if="isLoading")

    // EMPTY PAGE

    EmptyState(
    v-if="isEmpty && !isLoading"
    :title="$t('RevenueCenters.emptyStateTitle')"
    :description="$t('RevenueCenters.emptyStateDescription')"
    :buttonText="$t('RevenueCenters.emptyStateNewButton')"
    @emptyAddButton="onClickCreateRevenueCenterButton")

    // LIST

    TableView(
    v-if="!isEmpty && !isLoading && viewTypeList"
    :fields="fields"
    :actions="['edit']"
    :data="revenueCenterList.list"
    :page="rvcListPagination"
    :componentName="'RevenueCentersTable'"
    :addScrollToNoFixedColumns="true"
    @delegateOnChangePage="changePage"
    @delegateOnChangePageSize="changePageSize"
    @delegateOnItemEdit="onClickEditRevenueCenterButton"
    @sort="sort"
    )

    // BOX

    BoxView(
      v-if="!isEmpty && !isLoading && !viewTypeList"
      :fields="fields"
      :actions="['edit']"
      :data="revenueCenterList.list"
      :page="rvcListPagination"
      :showPassiveBox="true"
      :componentName="'RevenueCentersBox'"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemEdit="onClickEditRevenueCenterButton"
    )

    portal(to="box-header-left")
      div(slot-scope="row")
        Icon.portal-icon(v-if="isTypeSimpra(row.integrationProductName)"
        name="simpra-logo-dark")

    // POPUPS

    router-view(@submit="getRVCListWithParams")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//
import getFields from './fields/fields-revenue-center'
import FormPopup from './popup/revenue'
import ViewType from '@/view/global/view-type'

export default {
  name: 'RevenueCenters',

  components: {
    FormPopup,
    ViewType,
  },

  data () {
    return {
      fields: getFields(this.$t),
      isPopupOpen: false,
      activeStoreId: null,
      rvcHasPopover: null,
      viewTypeList: true,
      sortExpressionList: [],
      search: ''
    }
  },

  created () {
    this.init()
    this.getStoreList({pageNumber: 1, pageSize: 9999})
  },

  computed: {
    ...mapGetters('MenuItems', {
      revenueCenterList: 'RVCList',
      isEmpty: 'isRVCListEmpty',
      pageHeaderLinks: 'pageHeaderLinks',
      rvcListPagination: 'rvcListPagination'
    }),

    ...mapGetters('Global', [
      'suspendSalesExist'
    ]),

    isLoading () {
      return this.$wait.is(['getMenuItemsRVCList'])
    },

    isLoadingFilter () {
      return this.$wait.is(['submitFilterRevenueCenter'])
    },

    haveSearch () {
      return !(this.search.length)
    }
  },

  methods: {
    ...mapActions('MenuItems', [
      'getRVCList',
      'mapRVCtoStore'
    ]),

    ...mapActions('Stores', [
      'getStoreList'
    ]),

    sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      this.getRVCListWithParams()
    },

    init () {
      this.getRVCListWithParams()
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) {
        return
      }
      this.getRVCListWithParams()
    },

    listViewType (type) {
      if (this.viewTypeList !== type) {
        this.sortExpressionList = []
        this.search = ''
        this.getRVCListWithParams()
        this.viewTypeList = type
      }
    },

    onClickCreateRevenueCenterButton () {
      this.$router.push({
        name: 'RVCNew'
      })
    },

    changePage (page) {
      this.getRVCListWithParams(page)
    },

    changePageSize (pageSize) {
      this.getRVCListWithParams(1, pageSize)
    },

    async getRVCListWithParams (pageNumber = 1, pageSize = this.rvcListPagination.size) {
      const params = {
        sortExpressionList: this.sortExpressionList,
        pageNumber: pageNumber,
        pageSize,
        propertyName: this.search
      }
      await this.getRVCList(params)
    },

    isTypeSimpra (integrationProductName) {
      return integrationProductName === 'Simpra'
    },

    onClickEditRevenueCenterButton (revenueCenter) {
      this.$router.push({
        name: 'RVCEdit',
        params: {
          id: revenueCenter.id,
          item: revenueCenter
        }
      })
    },

    onChangeActiveStore (store) {
      this.activeStoreId = store.id
    },

    showMatchRVCPopover (rvc) {
      this.rvcHasPopover = rvc.id
    },

    hideMatchRVCPopover () {
      this.rvcHasPopover = null
      this.activeStoreId = null
    },

    matchRVCWithStore (rvc) {
      Promise.resolve()
        .then(() => this.mapRVCtoStore({
          revenueCenterId: rvc.id,
          storeId: this.activeStoreId
        }))
        .then(this.hideMatchRVCPopover)
        .then(this.getRVCListWithParams())
    },

    closePopup () {
      this.isPopupOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

:deep() .vue-portal-target{
  display: flex;
  align-items: center;
}

.portal-icon{
  width: 24px;
  height: 24px;
  margin: 5px;
}

.icon-global-settings{
  color: #b6b6b9;
}

.match-store-name {
    display: flex;
    align-items: center;
    position: relative;
    &-logo {
      width: 21px;
      height: 16px;
      margin-right: 8px;
    }
  }

</style>
