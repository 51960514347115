var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SettingsContainer" },
    [
      !_vm.hasTabPermission
        ? _c("NoPermission")
        : _c(
            "form",
            {
              staticClass: "Form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "Form-row" }, [
                _c("div", { staticClass: "Form-col" }, [
                  _c("div", { staticClass: "Form-item name" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Settings.Tenant_FormField_name"))),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          disabled: "",
                          name: "name",
                          type: "text",
                          id: "input-settings-form-field-name",
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "Form-col" }, [
                  _c("div", { staticClass: "Form-item address" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Settings.Tenant_FormField_address"))
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.address,
                            expression: "form.address",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:128",
                            expression: "'max:128'",
                          },
                        ],
                        staticClass: "txt",
                        class: { "is-danger": _vm.veeErrors.has("address") },
                        attrs: {
                          name: "address",
                          rows: "2",
                          "data-vv-as": _vm.$t(
                            "Settings.Tenant_FormField_address"
                          ),
                          autocomplete: "address",
                        },
                        domProps: { value: _vm.form.address },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "address", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("address"),
                            expression: "veeErrors.has('address')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("address")))]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "Form-row" }, [
                _c("div", { staticClass: "Form-col" }, [
                  _c("div", { staticClass: "Form-item phoneNumber" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Settings.Tenant_FormField_phoneNumber"))
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:50",
                            expression: "'max:50'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phoneNumber,
                            expression: "form.phoneNumber",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          name: "phoneNumber",
                          "data-vv-as": _vm.$t(
                            "Settings.Tenant_FormField_phoneNumber"
                          ),
                        },
                        domProps: { value: _vm.form.phoneNumber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "phoneNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("phoneNumber"),
                            expression: "veeErrors.has('phoneNumber')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("phoneNumber")))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "Form-col" }, [
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Settings.Tenant_FormField_baseCurrency"))
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.currency,
                            expression: "form.currency",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          disabled: "",
                          name: "currency",
                          type: "text",
                          id: "input-settings-form-base-currency",
                        },
                        domProps: { value: _vm.form.currency },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "currency", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "Form-row" }, [
                _c("div", { staticClass: "Form-col" }, [
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Register.formFieldTcknVkn"))),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.taxNumber,
                            expression: "form.taxNumber",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          id: "input-settings-tax-number",
                          name: "taxNumber",
                          type: "number",
                          autocomplete: "off",
                          placeholder: _vm.$t(
                            "Register.formFieldTcknVknPlaceholder"
                          ),
                        },
                        domProps: { value: _vm.form.taxNumber },
                        on: {
                          keydown: _vm.preventNotNumberValues,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "taxNumber", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "Form-col" }, [
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Register.formFieldTaxOffice"))),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.taxOfficeName,
                            expression: "form.taxOfficeName",
                          },
                        ],
                        staticClass: "txt",
                        attrs: {
                          id: "input-settings-tax-office",
                          name: "taxOffice",
                          type: "text",
                          autocomplete: "off",
                          placeholder: _vm.$t(
                            "Register.formFieldTaxOfficePlaceholder"
                          ),
                        },
                        domProps: { value: _vm.form.taxOfficeName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "taxOfficeName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "Form-row" }, [
                _c(
                  "div",
                  { staticClass: "Form-col align-self-flex-end" },
                  _vm._l(_vm.tenantPermissions, function (permission, index) {
                    return _c("CustomCheckbox", {
                      key: index,
                      staticClass: "width-max-content m-bottom-10",
                      attrs: {
                        id: `checkbox-multiselect-list-item-${index}`,
                        checked: permission.checked,
                        disabled: permission.disabled,
                        label: _vm.$t(
                          `Settings.Tenant_Permission_Label_${permission.name}`
                        ),
                      },
                      on: {
                        change: (isChecked) =>
                          _vm.selectItem(permission, isChecked),
                      },
                    })
                  }),
                  1
                ),
                _c("div", { staticClass: "Form-col not-label" }, [
                  _c(
                    "div",
                    { staticClass: "Form-item submit" },
                    [
                      _c(
                        "Button",
                        {
                          attrs: {
                            primary: "",
                            variant: "full",
                            size: "medium",
                            id: "btn-settings-submit-tenant",
                            disabled: _vm.shouldDisableSubmit,
                            type: "submit",
                          },
                        },
                        [
                          _c("Loading", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isLoading,
                                expression: "isLoading",
                              },
                            ],
                            attrs: { theme: "disable" },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isLoading,
                                  expression: "!isLoading",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("Settings.Tenant_FormField_submit")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }