var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c("CustomDatepicker", {
            attrs: {
              id: "PaymentDatepicker",
              isLoading: _vm.$wait.is("getDateFilterSettings"),
              disabled: _vm.isLoading,
              range: true,
            },
            model: {
              value: _vm.dates,
              callback: function ($$v) {
                _vm.dates = $$v
              },
              expression: "dates",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              staticClass: "m-left-15",
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-payment-follow-new",
                type: "button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.newItem },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("Payments.pageHeaderPaymentAdd"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c(
            "div",
            { staticClass: "toolbar-filters" },
            [
              _c("SupplierFilter", {
                ref: "paymentsSupplierFilter",
                on: { submitFilter: _vm.getListWithSupplierFilter },
              }),
              _c("businessMultiselect", {
                ref: "paymentsBusinessMultiselect",
                attrs: {
                  componentName: "payment-types-filter",
                  label: _vm.$t("Payments.toolbar_FilterPayment"),
                  popupHeader: _vm.$t(
                    "Payments.toolbar_FilterPayment_PopupHeader"
                  ),
                  multiselectItems: _vm.filterData.paymentOptionList,
                },
                on: { submitFilter: _vm.getFilteredPayments },
              }),
              _c(
                "Button",
                {
                  staticClass:
                    "clear-filter-button border-gray-1 p-0 width-auto",
                  on: { click: _vm.clearFilter },
                },
                [
                  _c("Icon", {
                    staticClass: "icon-clear-filter",
                    attrs: { name: "icon-clear-filter" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "toolbar__separator" }),
          _c("CustomCheckbox", {
            attrs: {
              id: "checkbox-payment-reports-drafts",
              checked: _vm.isShowDelete,
              label: _vm.$t("Payments.showIsDeleted"),
            },
            on: { change: _vm.showDeleted },
          }),
          _c("div", { staticClass: "toolbar__separator" }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("TotalViewList", {
            attrs: { totalCountArray: _vm.totalCountData },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.isEmptyPayments && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Payments.emptyState_Title"),
                  description: _vm.$t("Payments.emptyState_Description"),
                  buttonText: _vm.$t("Payments.emptyState_Button"),
                },
                on: { emptyAddButton: _vm.newItem },
              })
            : _vm._e(),
          !_vm.isLoading && !_vm.isEmptyPayments
            ? _c("TableView", {
                attrs: {
                  fields: _vm.paymentFields,
                  componentName: "PaymentTable",
                  data: _vm.payments,
                  page: _vm.page,
                  actions: ["edit", "remove"],
                },
                on: {
                  delegateOnItemEdit: _vm.editItem,
                  delegateOnItemDelete: _vm.deleteItem,
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
          _c("router-view"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }