<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    CustomDatepicker#PaymentDatepicker(
      :isLoading="$wait.is('getDateFilterSettings')"
      v-model="dates"
      :disabled="isLoading"
      :range="true"
    )
  template(slot="header-right-content")
    Button.m-left-15(
      primary
      size="medium"
      id="btn-payment-follow-new"
      type="button"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="newItem")
        span {{ $t('Payments.pageHeaderPaymentAdd') }}

  template(slot="toolbar-left")
    .toolbar-filters
      SupplierFilter(
        @submitFilter="getListWithSupplierFilter"
        ref="paymentsSupplierFilter")

      businessMultiselect(
      componentName="payment-types-filter"
      :label="$t('Payments.toolbar_FilterPayment')"
      :popupHeader="$t('Payments.toolbar_FilterPayment_PopupHeader')"
      :multiselectItems="filterData.paymentOptionList"
      ref="paymentsBusinessMultiselect"
      @submitFilter="getFilteredPayments")

      Button.clear-filter-button.border-gray-1.p-0.width-auto(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")

    .toolbar__separator

    CustomCheckbox(
      id="checkbox-payment-reports-drafts"
      :checked="isShowDelete"
      :label="$t('Payments.showIsDeleted')"
      @change="showDeleted"
    )

    .toolbar__separator
  template(slot="toolbar-right")
    TotalViewList(:totalCountArray="totalCountData")


  template(slot="content-center")
    TableLoading(v-if="isLoading")
      // EMPTY PAGE
    EmptyState(
    v-if="isEmptyPayments && !isLoading",
    :title="$t('Payments.emptyState_Title')",
    :description="$t('Payments.emptyState_Description')",
    :buttonText="$t('Payments.emptyState_Button')",
    @emptyAddButton="newItem")

    // LİST

    TableView(
    v-if="!isLoading && !isEmptyPayments"
    :fields="paymentFields"
    :componentName="'PaymentTable'"
    :data="payments"
    :page="page"
    :actions="['edit', 'remove']"
    @delegateOnItemEdit="editItem"
    @delegateOnItemDelete="deleteItem"
    @delegateOnChangePage="changePage"
    @delegateOnChangePageSize="changePageSize")

    router-view
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import getPaymentFields from './payment-fields'

//
import businessMultiselect from '@/view/global/business-filters/business-multiselect'
import SupplierFilter from '@/view/global/business-filters/supplier.vue'
import getDateFilterSetting from '@/utils/getDateFilterSetting'

export default {
  name: 'Payments',
  data () {
    return {
      paymentOptionList: [],
      dates: [],
      filterData: {
        supplier: [],
        paymentOptionList: []
      },
      filters: {
        supplier: [],
        paymentOptionList: []
      },
      paymentFields: getPaymentFields(this.$t),
      updateLabels: false
    }
  },
  components: {
    SupplierFilter,
    businessMultiselect
  },
  computed: {
    totalCountData () {
      return [
        {
          totalCountLabel: this.$t('Payments.totalPurchase'),
          totalCountValue: this.$options.filters.formatCurrency2Digits(this.totalPurchase),
          extraClass: 'simple'
        },
        {
          totalCountLabel: this.$t('Payments.totalPayment'),
          totalCountValue: this.$options.filters.formatCurrency2Digits(this.totalPayment),
          extraClass: 'simple'
        },
        {
          totalCountLabel: this.$t('Payments.totalDebt'),
          totalCountValue: this.$options.filters.formatCurrency2Digits(this.debt),
          extraClass: 'simple'
        }
      ]
    },
    isLoading () {
      return this.$wait.is(['getPaymentsList'])
    },
    isAllSelectedSupplier () {
      return this.filters.supplier.length === this.MiniSuppliers.length
    },
    isAllSelectedPaymentTypes () {
      return this.$refs.paymentsBusinessMultiselect.$refs.businessMultiselect.isAllSelected
    },
    itemActions (item) {
      if (item.isDeleted) {
        return []
      } else {
        return ['edit', 'delete']
      }
    },
    ...mapGetters('Payments', [
      'payments',
      'page',
      'isEmptyPayments',
      'isShowDelete',
      'totalPayment',
      'totalPurchase',
      'debt',
      'pageHeaderLinks'
    ]),
    ...mapGetters('Supplier', [
      'MiniSuppliers'
    ])
  },
  methods: {
    ...mapActions('Payments', [
      'getPaymentsList'
    ]),

    ...mapActions('Despatch', [
      'get_PaymentList'
    ]),

    ...mapMutations('Payments', [
      'TOGGLE_IS_SHOW',
      'ACTIVE_IS_SHOW',
      'PASSIVE_IS_SHOW'
    ]),

    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    getListWithSupplierFilter ({list, dontSubmit}) {
      if (dontSubmit) this.filters.supplier = list
      else {
        this.filters.supplier = list
        this.submitFilter()
      }
    },

    getFilteredPayments ({list, dontSubmit}) {
      this.filters.paymentOptionList = list
      if (!dontSubmit) this.getPayments()
    },

    clearFilter () {
      this.$refs.paymentsSupplierFilter.clearSupplierFilter()
      this.$refs.paymentsBusinessMultiselect.clearAllFilter(false)
    },

    showDeleted () {
      this.TOGGLE_IS_SHOW()
    },
    editItem (item) {
      this.$router.push({
        name: 'PaymentEdit',
        params: {
          id: item.id,
          filterData: {
            supplierList: this.isAllSelectedSupplier ? [] : this.filters.supplier.map(item => item.id),
            paymentOptionList: this.isAllSelectedPaymentTypes ? [] : this.filters.paymentOptionList.map(item => item.id),
            startDate: this.dates[0],
            endDate: this.dates[1]
          }
        }
      })
    },

    newItem () {
      this.$router.push({
        name: 'PaymentNew',
        params: {
          filterData: {
            supplierList: this.isAllSelectedSupplier ? [] : this.filters.supplier.map(item => item.id),
            paymentOptionList: this.isAllSelectedPaymentTypes ? [] : this.filters.paymentOptionList.map(item => item.id),
            startDate: this.dates[0],
            endDate: this.dates[1]
          }
        }
      })
    },

    deleteItem (item) {
      this.$router.push({
        name: 'PaymentsRemove',
        params: {
          id: item.id,
          filterData: {
            supplierList: this.isAllSelectedSupplier ? [] : this.filters.supplier.map(item => item.id),
            paymentOptionList: this.isAllSelectedPaymentTypes ? [] : this.filters.paymentOptionList.map(item => item.id),
            startDate: this.dates[0],
            endDate: this.dates[1]
          }
        }
      })
    },

    submitFilter () {
      this.getPayments()
    },

    getPayments (page = 1, pageSize = this.page.size) {
      this.getPaymentsList({
        page: page,
        supplierList: this.isAllSelectedSupplier ? [] : this.filters.supplier.map(item => item.id),
        paymentOptionList: this.isAllSelectedPaymentTypes ? [] : this.filters.paymentOptionList.map(item => item.id),
        startDate: this.dates[0],
        endDate: this.dates[1],
        pageSize
      })
    },
    changePage (page) {
      this.getPayments(page)
    },
    changePageSize (pageSize) {
      this.getPayments(1, pageSize)
    }
  },

  async mounted () {
    this.PASSIVE_IS_SHOW()
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_Payment')
    await this.getPayments()
    await this.get_PaymentList().then(res => {
      this.paymentOptionList = res.data.paymentTypeList
      this.filterData = {
        supplier: [...this.MiniSuppliers],
        paymentOptionList: [...this.paymentOptionList]
      }
    })
  },

  watch: {
    filterData () {
      this.updateLabels = !this.updateLabels
      this.filters = {
        supplier: [...this.filterData.supplier],
        paymentOptionList: [...this.filterData.paymentOptionList]
      }
    },
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getPayments()
    },
    isShowDelete () {
      this.getPayments()
    }
  }
}
</script>

<style lang="scss" scoped>

  .toolbar {
    &-left {
      width: 100%;
      flex-wrap: wrap;
    }

    &-total-counts {
      display: flex;
      align-self: center;
    }
  }

  .supplier-switch {
    width: auto;
    height: 27px;
    font-weight: $font-weight-normal;
    color: $color-light;

    .icon {
      width: 27px;
      height: 27px;
      padding: 7px 4.5px;
      margin-right: 10px;
      background: $color-seashell;
      color: $color-success;
      border: 1px solid;
      border-radius: $border-high-radius;

      &.eye-closed {
        padding: 5px 3px;
        background: transparent;
        color: $color-light;
      }
    }
  }
</style>
