<template lang="pug">
Page
  template(slot="header-right-dropdown")
    CustomDatepicker.m-right-15(
      v-model="dates"
      name="integrationLog"
      :range="true"
      id="custom-datepicker-integration-logs"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium"
      @click="getList(Page.number, Page.size)"
    )
      span {{ $t('IntegrationLogs.getLogList') }}
  template(slot="content-center")
    TableLoading(v-if="isLoading")

    TableView(
      v-if="IntegrationLogs.length && !isLoading"
      :data="IntegrationLogs",
      :fields="fields",
      :page="Page"
      :readOnly="true"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
    )

    EmptyState(
      v-if="!IntegrationLogs.length && !isLoading"
      :title="mountedState ? $t('IntegrationLogs.makeSelection') : $t('IntegrationLogs.logList_empty_title')"
      :description="mountedState ? $t('IntegrationLogs.selectDate_and_continue') : $t('IntegrationLogs.logList_empty_description')")
    
    portal(to="processName")
      div(slot-scope="row")
        span {{ $t('IntegrationLogs.processDespatch') }}

</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { getFields } from './fields/index'

export default {
  name: 'IntegrationLogs',

  data () {
    return {
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ],
      fields: getFields(this.$t),
      mountedState: true
    }
  },

  mounted() {
  },

  computed: {
    ...mapGetters('IntegrationLogs', ['IntegrationLogs', 'Page']),

    isLoading() {
      return this.$wait.is('getIntegrationLogs')
    }
  },
  methods: {

  ...mapActions('IntegrationLogs', ['getIntegrationLogs']),

  dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },
  
  changePage (page) {
    this.getList(page)
  },

  changePageSize (pageSize) {
    this.getList(1, pageSize)
  },

  getList(pageNumber = page, pageSize = this.Page.size) {
    const payload = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      startDate: this.dateFormat(this.dates[0]),
      endDate: this.dateFormat(this.dates[1])
    }
    this.getIntegrationLogs(payload).then(res => {
      if (res) this.mountedState = false
    })
  }
  }
}
</script>