export default function getFields($t) {
  return [
    {
      name: 'storeGroupName',
      is_visible: true,
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_StoreGroup')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_StoreGroup')
      }
    },
    {
      name: 'revenueCenterName',
      is_visible: true,
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_RVC_Short')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_RVC')
      }
    },
    {
      name: 'menuItemGroupName',
      is_visible: true,
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_SalesGroup_Short')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_SalesGroup')
      }
    },
    {
      name: 'matchedItemGroupName',
      is_visible: true,
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_stockItemGroup')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_stockItemGroup')
      }
    },
    {
      name: 'menuItemName',
      is_visible: true,
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_salesItemName')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_salesItemName')
      },
    },
    {
      name: 'matchedItemName',
      is_visible: true,
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_stockItemName')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_stockItemName')
      }
    },
    {
      name: 'type',
      is_visible: true,
      type: 'with-badge',
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_Type')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_Type')
      },
      typeData(item) {
        const typeData = {
          1: {
            className: 'row-badge--primary',
            badgeText: $t('MatchedMenuItems.tableColumn_MatchedName_BadgeStockItem')
          },
          2: {
            className: 'row-badge--success',
            badgeText: $t('MatchedMenuItems.tableColumn_MatchedName_BadgeRecipe')
          }
        }
        return typeData[item.type]
      }
    },
    {
      name: 'price',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_SalesPrice_Short')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_SalesPrice')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_storeName')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_storeName')
      }
    },
    {
      name: 'cost',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_Cost_Short')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_Cost')
      }
    },
    {
      name: 'costPercentage',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_CostPercentage')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_CostPercentage')
      }
    }
  ]
}
