var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
              matchedRoute: "SuspendedSales",
            },
            scopedSlots: _vm._u([
              {
                key: "linkExtra",
                fn: function (link) {
                  return [
                    _vm.suspendSalesExist && link.linkName === "SuspendedSales"
                      ? _c("Icon", {
                          staticClass: "icon-suspended-status m-right-5",
                          attrs: { name: "icon-row-status" },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box m-right-20",
            attrs: { componentName: "MenuItems", isSuggestionLoading: false },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c("CustomDatepicker", {
            staticClass: "m-right-15",
            attrs: {
              id: "suspend-sales-header-datepicker",
              isLoading: _vm.$wait.is("getDateFilterSettings"),
              range: true,
            },
            model: {
              value: _vm.dates,
              callback: function ($$v) {
                _vm.dates = $$v
              },
              expression: "dates",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              staticClass: "m-right-5",
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-suspened-sales-stop",
                type: "button",
                disabled: _vm.isUpdateStatus || _vm.isLoading,
              },
              on: { click: _vm.updateStatus },
            },
            [
              _vm.isUpdateStatus
                ? _c("Loading", { attrs: { theme: "disabled" } })
                : [
                    _c("Icon", {
                      staticClass: "m-right-10",
                      attrs: { name: "icon-filled-right" },
                    }),
                  ],
              _vm.isSuspendedActive
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("MenuItems.SuspendButtonStop"))),
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("MenuItems.SuspendButtonStart"))),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("businessMultiselect", {
            ref: "suspendSalesFilter",
            attrs: {
              componentName: "multiselect-storeGroup-filter",
              label: _vm.$t("Global.toolbar_storeGroups_Label"),
              popupHeader: _vm.$t("Global.toolbar_storeGroups_PopupHeader"),
              multiselectItems: _vm.storeGroupListItems,
            },
            on: { submitFilter: _vm.updateStoreGroupFilterItems },
          }),
          _c("CustomMultiselect", {
            ref: "suspendSalesRVCFilter",
            staticClass: "toolbar-filters__item",
            attrs: {
              withBadgedText: true,
              badgedTextField: "storeGroupName",
              id: "multiselect-rvc-filter",
              items: _vm.filteredRvcItems,
              selected: _vm.selectedRvcFilterItems,
              label: _vm.$t("Global.toolbar_RVC_Label"),
              unselectedText: _vm.$t("Global.toolbar_Filters_Unselected"),
              popupHeader: _vm.$t("Global.toolbar_RVC_PopupHeader"),
              allLabel: _vm.$t("Global.toolbar_Filters_AllLabel"),
              buttonText: _vm.$t("Global.toolbar_Filters_ButtonText"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckAll: _vm.onChangeFilter,
              delegateCheckItem: _vm.onChangeFilter,
              delegateSubmitItems: _vm.updateRVCFilterItems,
            },
          }),
          _c("TransactionTypeFilter", {
            ref: "businessTransactionTypeFilter",
            on: { submitFilter: _vm.updateTransactionTypeFilter },
          }),
          _c(
            "Button",
            {
              staticClass:
                "clear-filter-button border-gray-1 p-0 width-auto m-right-10",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c("CustomCheckbox", {
            attrs: {
              label: _vm.$t("MenuItems.Checkbox_Suspend_Sales_Show_Passives"),
            },
            model: {
              value: _vm.showPassiveItems,
              callback: function ($$v) {
                _vm.showPassiveItems = $$v
              },
              expression: "showPassiveItems",
            },
          }),
          _c("TotalViewList", {
            attrs: { totalCountArray: _vm.totalCountData },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c(
            "Button",
            {
              staticClass: "m-right-5",
              attrs: {
                primary: "",
                size: "small",
                id: "btn-suspened-sales-with-stock",
                type: "button",
                disabled:
                  _vm.isCheckSuspendSales ||
                  _vm.isLoadingRetrySuspendedSales ||
                  _vm.isLoading ||
                  _vm.isSuspendTransactionInProgress,
              },
              on: {
                click: function ($event) {
                  return _vm.onClickRetrySuspendedSalesButton(false)
                },
              },
            },
            [
              _vm.isLoadingRetrySuspendedSales
                ? _c("Loading", { attrs: { theme: "disabled" } })
                : [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("MenuItems.suspendedSalesDownStock"))
                      ),
                    ]),
                  ],
            ],
            2
          ),
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "small",
                id: "btn-suspened-sales-with-stock-filter",
                type: "button",
                disabled:
                  _vm.isCheckSuspendSales ||
                  _vm.isLoadingRetrySuspendedSales ||
                  _vm.isLoading ||
                  _vm.isSuspendTransactionInProgress,
              },
              on: {
                click: function ($event) {
                  return _vm.onClickRetrySuspendedSalesButton(true)
                },
              },
            },
            [
              _vm.isLoadingRetrySuspendedSales
                ? _c("Loading", { attrs: { theme: "disabled" } })
                : [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("MenuItems.suspendedSalesDownStockWithFilter")
                        )
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "SuspendSalesPageContent" },
          [
            (
              _vm.isListingHeader
                ? !_vm.isEmptySuspendedSalesHeaderList
                : !_vm.isEmptySuspendedSalesDetailList
            )
              ? _c("div", { staticClass: "toolbar suspend-sales" }, [
                  _c("div", { staticClass: "toolbar-right" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.hideReasonPopup,
                            expression: "hideReasonPopup",
                          },
                        ],
                        staticClass: "popup-container",
                      },
                      [
                        _c(
                          "Button",
                          {
                            class: _vm.activationStatus,
                            attrs: {
                              primary: "",
                              size: "small",
                              disabled:
                                _vm.$refs.table.selectedList.length === 0,
                              id:
                                "suspend-sales-description-reason-show-" +
                                _vm.activationStatus,
                            },
                            on: { click: _vm.showReasonPopup },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "MenuItems.Button_Suspend_Sales_" +
                                    _vm.activationStatus
                                )
                              )
                            ),
                          ]
                        ),
                        _vm.showReasonButtonPopup
                          ? _c(
                              "form",
                              {
                                staticClass:
                                  "suspend-sales-description-right-MiniPopup",
                                attrs: {
                                  id: "suspend-sales-description-reason-form",
                                },
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.changeActivationStatus.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "suspend-sales-description-MiniPopup-label required",
                                    attrs: {
                                      id: "suspend-sales-description-reason-label",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "MenuItems.Reason_Description_Input_Label"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: true, max: 512 },
                                      expression: "{required: true, max: 512}",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reasonDescription,
                                      expression: "reasonDescription",
                                    },
                                  ],
                                  ref: "reason",
                                  staticClass:
                                    "txt suspend-sales-description-MiniPopup-input",
                                  class: {
                                    "is-danger": _vm.veeErrors.has(`reason`),
                                  },
                                  attrs: {
                                    name: "reason",
                                    autocomplete: "off",
                                    id: "suspend-sales-description-reason-input",
                                  },
                                  domProps: { value: _vm.reasonDescription },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.reasonDescription =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _c(
                                  "Button",
                                  {
                                    staticClass: "m-top-15 m-left-0",
                                    attrs: {
                                      primary: "",
                                      size: "small",
                                      type: "submit",
                                      id: "suspend-sales-description-reason-submit",
                                      disabled:
                                        _vm.$refs.table.selectedList.length ===
                                          0 || _vm.isActivationStatusLoading,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "MenuItems.Detail_Col_Button_Agree"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("Loading", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isActivationStatusLoading,
                                          expression:
                                            "isActivationStatusLoading",
                                        },
                                      ],
                                      attrs: { theme: "disable" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.isLoading ? _c("TableLoading") : _vm._e(),
            _c("router-view", {
              ref: "table",
              attrs: { isLoading: _vm.isLoading },
              on: {
                getList: _vm.getList,
                changePage: _vm.changePage,
                changePageSize: _vm.changePageSize,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }