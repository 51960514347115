<template lang="pug">
.Despatches-details
    .table-scroll
      table
        thead.Despatches-details-head(v-if="!despatch.detailIsEmpty")
          tr.Despatches-details-head-row
            th.Despatches-details-head-col.stockItemName
              h6 {{ $t('Despatches.detail_TableColumn_StockItemName') }}
            th.Despatches-details-head-col.sotckOnHand.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_StockOnHandQuantity') }}
            th.Despatches-details-head-col.quantity.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_Quantity') }}
            th.Despatches-details-head-col.unitCode.u-textCenter
              h6 {{ $t('Despatches.detail_TableColumn_UnitCode') }}
            th.Despatches-details-head-col.netUnitPrice.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_NetUnitPrice') }}
            th.Despatches-details-head-col.baseQuantity.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_BaseQuantity') }}
            th.Despatches-details-head-col.baseUnit
              h6 {{ $t('Despatches.detail_TableColumn_BaseUnit') }}
            th.Despatches-details-head-col.basePrice.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_BasePrice') }}
            th.Despatches-details-head-col.discount1.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_Discount1') }}
            th.Despatches-details-head-col.discount2.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_Discount2') }}
            th.Despatches-details-head-col.discountedUnitPrice.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_DiscountedUnitPrice') }}
            th.Despatches-details-head-col.discountedBaseUnitPrice.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_DiscountedBaseUnitPrice') }}
            th.Despatches-details-head-col.taxRatio.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_TaxRatio') }}
            th.Despatches-details-head-col.discountedNetUnitPrice.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_DiscountedNetUnitPrice') }}
            th.Despatches-details-head-col.netAmount.u-textRight
              h6 {{ $t('Despatches.detail_TableColumn_NetAmount') }}
            th.Despatches-details-head-col.storeName
              h6 {{ $t('Despatches.detail_TableColumn_StoreName') }}
            th.Despatches-details-head-col.action

        tbody.Despatches-details-body(
        v-if="!despatch.detailIsEmpty && renderComponent")
          tr.Despatches-details-body-row(
          v-for="detail in despatch.despatchDetailList"
          tabindex="0")

            td.Despatches-details-body-col.stockItemName.tooltip-relative(:class="despatch.status !== 1 ? 'p-top-15 p-bottom-15' : ''")
              | {{ getFieldName(detail.stockItem.name) }}
              Tooltip.tooltip(

                v-if="detail.stockItem.name.length > 30"
                white
                bottom
                :text="detail.stockItem.name"
              )
            td.Despatches-details-body-col.stockOnHandQuantity.ff-mono.u-textRight
              | {{ detail.stockOnHandQuantity || '-' }} 
            td.Despatches-details-body-col.quantity.ff-mono.u-textRight
              .control
                customNumberInput.number-input(
                  v-if="despatch.status === 1"
                  id="refund-quantity"
                  name="quantity"
                  :class="{'is-danger': veeErrors.has('quantity')}"
                  :value="detail.quantity"
                  :data-vv-as="$t('Despatches.detail_TableColumn_Quantity')"
                  v-validate="{min_value: 0}"
                  @blur="changeQuantity(detail, $event)"
                )
            td.Despatches-details-body-col.baseUnitCode.u-textCenter
              | {{ findUnit(detail.unitId).name }}
            td.Despatches-details-body-col.netUnitPrice.ff-mono.u-textRight.currency
              span(v-if="showCurrencySymbol && detail.unitPrice") {{detail.currencyDefinitionSymbol}} {{ detail.unitPrice | formatCurrency }}
              span(v-if="!showCurrencySymbol && detail.unitPrice") {{ detail.unitPrice | formatCurrency }}
            td.Despatches-details-body-col.baseQuantity.ff-mono.u-textRight
              | {{ detail.baseQuantity }}
            td.Despatches-details-body-col.baseUnitCode
              | {{ detail.stockItem.baseUnitCode }}
            td.Despatches-details-body-col.basePrice.ff-mono.u-textRight.currency
              span(v-if="showCurrencySymbol && detail.netBaseUnitPrice") {{detail.currencyDefinitionSymbol}} {{ detail.netBaseUnitPrice | formatCurrency }}
              span(v-if="!showCurrencySymbol && detail.netBaseUnitPrice") {{ detail.netBaseUnitPrice | formatCurrency }}
            td.Despatches-details-body-col.discount1.ff-mono.u-textRight
              | % {{ detail.discount1 | percentFixed }}
            td.Despatches-details-body-col.discount2.ff-mono.u-textRight
              | % {{ detail.discount2 | percentFixed }}
            td.Despatches-details-body-col.discountedUnitPrice.ff-mono.u-textRight.currency
              span(v-if="showCurrencySymbol && detail.discountedUnitPrice") {{detail.currencyDefinitionSymbol}} {{ detail.discountedUnitPrice | formatCurrency }}
              span(v-if="!showCurrencySymbol && detail.discountedUnitPrice") {{ detail.discountedUnitPrice | formatCurrency }}
            td.Despatches-details-body-col.DiscountedBaseUnitPrice.ff-mono.u-textRight.currency
              span(v-if="showCurrencySymbol && detail.discountedBaseUnitPrice") {{detail.currencyDefinitionSymbol}} {{ detail.discountedBaseUnitPrice | formatCurrency }}
              span(v-if="!showCurrencySymbol && detail.discountedBaseUnitPrice") {{ detail.discountedBaseUnitPrice | formatCurrency }}
            td.Despatches-details-body-col.taxRatio.ff-mono.u-textRight
              | % {{ detail.taxRatio | percentFixed }}
            td.Despatches-details-body-col.discountedNetUnitPrice.ff-mono.u-textRight.currency
              span(v-if="showCurrencySymbol && detail.discountedNetUnitPrice") {{detail.currencyDefinitionSymbol}} {{ detail.discountedNetUnitPrice | formatCurrency }}
              span(v-if="!showCurrencySymbol && detail.discountedNetUnitPrice") {{ detail.discountedNetUnitPrice | formatCurrency }}
            td.Despatches-details-body-col.netAmount.ff-mono.u-textRight.currency
              span(v-if="showCurrencySymbol && detail.netAmount") {{detail.currencyDefinitionSymbol}} {{ detail.netAmount | formatCurrency }}
              span(v-if="!showCurrencySymbol && detail.netAmount") {{ detail.netAmount | formatCurrency }}
            td.Despatches-details-body-col.storeName
              | {{ detail.storeName }}

            td.Despatches-details-body-col.action
              ActionButton(
                id="refund-detail-table-action-button"
                :data="despatch.status === 1 ? ['edit', 'remove'] : ['edit'] "
                :item="despatch"
                @onItemEdit="despatchDetailEdit(detail)"
                @onItemDelete="despatchDetailRemove(detail)"
              )

    .Despatches-details-foot
      Button.m-right-10(
      id="btn-refund-expire-date-edit"
      :disabled="!despatch.hasAnyDetail"
      type="button"
      size="small"
      secondary
      @click="editExpireDate(despatch)")
        span {{ $t('Transfer.editExpireDate') }}

      Button.m-right-10(
      id="btn-despatch-detail-new"
      size="small"
      secondary
      inline
      type="button"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      :disabled="isOnlyListUsage"
      @click="despatchDetailNew(despatch)")
        span {{ $t('Despatches.detail_NewStockItemButton') }}
      Button.m-right-10(
      id="btn-despatch-detail-save"
      size="small"
      primary
      inline
      type="button",
      iconName="icon-btn-success"
      iconClass="icon-btn-success"
      @click="refundSaved(despatch)",
      v-if="despatch.status === 1")
        span {{ $t('Despatches.detail_SaveRefundButton') }}

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'RefundTable',

  props: {
    despatch: {
      type: Object,
      require: true
    }
  },

  computed: {
    ...mapGetters('Units', [
      'findUnit'
    ]),
    ...mapGetters('Settings', ['permissions', 'UserInfo'])
  },

  data () {
    return {
      isOnlyListUsage: false,
      renderComponent: true,
      showCurrencySymbol: null
    }
  },

  async mounted () {
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    this.showCurrencySymbol = this.UserInfo.showCurrencySymbol
  },

  methods: {

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Refund', [
      'update_REFUND_DETAIL',
      'get_REFUND'
    ]),

    ...mapMutations('Refund', [
      'HIDE_OTHERS_DETAILS',
      'UPDATE_REFUND_ITEM',
      'SHOW_DETAIL'
    ]),

    editExpireDate (despatch) {
      this.$emit('editExpireDate', despatch)
    },

    changeQuantity (despatch, value) {
      despatch.quantity = value
      this.update_REFUND_DETAIL(despatch).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', { name: despatch.stockItem.name })
          this.notifyShow({ message })
          this.get_REFUND(despatch.despatchHeaderId).then(res => {
            if (res.status === 200) {
              this.UPDATE_REFUND_ITEM(res.data.despatchHeader)
              this.SHOW_DETAIL(res.data)
            }
          })
        }
      })
    },

    despatchDetailNew (despatch) {
      this.HIDE_OTHERS_DETAILS(despatch)
      this.$router.push({
        name: 'RefundDetailNew',
        params: {
          id: despatch.id,
          despatchHeader: despatch,
          isDetailNew: true
        }
      })
    },

    despatchDetailEdit (detail) {
      this.$router.push({
        name: 'RefundDetailEdit',
        params: {
          detailId: detail.id,
          despatchDetail: detail,
          despatchHeader: this.despatch
        }
      })
    },

    refundSaved (refund) {
      this.$router.push({
        name: 'RefundFinalizeForm',
        params: {
          id: refund.id,
          refundHeader: refund
        }
      })
    },

    despatchDetailRemove (detail) {
      this.$router.push({
        name: 'RefundDetailRemove',
        params: {
          detailId: detail.id,
          item: detail
        }
      })
    },
    showActionBar (item) {
      item.showActions = !item.showActions
      this.forceRerender()
    },
    closeActionBar (item) {
      item.showActions = false
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    getFieldName (name) {
      return (name && name.length > 30 ) ? name.substr(0, 30).concat('...') : name
    },
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";

  .Despatches {
    &-details {
    background: $color-lighter-blue;
    border-top: 1px solid $color-gray;
    padding: 15px 0;

      &-head,
      &-body {
        &-row {
          cursor: ew-resize;
        }
        &-col {
          background: $color-lighter-blue;
          &:first-child {
            position: sticky;
            left: -1px;
            z-index: $z-index-xxs;
          }
        }
      }

      &-head {
        &-col {
          padding: 7px 10px;
          h6 {
            font-size: $font-size-micro;
            color: $color-success;
            margin-bottom: 6px;
          }
        }
      }

      &-body {
        margin-top: 10px;
        margin-bottom: 20px;

        &-row {
          border-top: 1px solid $color-gray;
          line-height: 1;
          align-items: center;

          & + & {
            margin-top: 10px;
          }
        }

        &-col {
          padding: 7px 10px;
          text-overflow: ellipsis;
          white-space: nowrap;
          min-width: 100px;
          position: relative;

          &.action {
            justify-content: flex-end;
            opacity: 1;
            transition: opacity $transition;

            .body {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      &-foot {
        display: flex;
        justify-content: flex-end;
      }

      &-body-row:focus-within > .action,
      &-body-row:hover > .action {
        opacity: 1;
      }
    }
  }
  .table-scroll {
    overflow-y: scroll;
    margin-bottom: 20px;
  }

  .tooltip{
    top: unset !important;
    padding-left: 30px;
  }
</style>
