var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "onhand",
              suggestionValue: "name",
              showSuggestionType: true,
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.suggestions,
              customSuggestionPlaceHolder: _vm.$t("OnHand.search_Placeholder"),
              customSuggestionTitle: _vm.$t("OnHand.search_SuggestionTitle"),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.onChangeQueryItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      !_vm.isPageLoading
        ? _c(
            "template",
            { slot: "toolbar-left" },
            [
              _c("storeFilterComponent", {
                ref: "transferFromStoreFilter",
                attrs: { label: _vm.$t("ApproveOperations.fromStore") },
                on: { submitFilter: _vm.getListWithFromStoreFilter },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "StockOnHand" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              !_vm.stockItemsWithExpDate.length
                ? _c("EmptyState", {
                    staticClass: "batch-empty-state",
                    attrs: {
                      title: _vm.$t("OnHand.emptyPage_BatchTitle"),
                      description: _vm.$t("OnHand.emptyPage_BatchDescription"),
                    },
                  })
                : _c("TableView", {
                    attrs: {
                      data: _vm.stockItemsWithExpDate,
                      fields: _vm.batchListFields,
                      readOnly: true,
                    },
                  }),
              _c("portal", {
                attrs: { to: "daysLeft" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return _c("div", {}, [
                        _c(
                          "div",
                          {
                            staticClass: "days",
                            class: _vm.getClassForDaysLeft(row.daysLeft),
                          },
                          [_vm._v(_vm._s(row.daysLeft))]
                        ),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }