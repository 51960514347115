var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.confirmSuccess
      ? _c("div", [
          _c("h1", { staticClass: "success-message" }, [
            _vm._v(_vm._s(_vm.$t("Register.confirmSuccess"))),
          ]),
          _c("p", { staticClass: "m-bottom-20" }, [
            _vm._v(_vm._s(_vm.$t("Register.redirectLogin"))),
          ]),
          _c("p", { staticClass: "m-bottom-20" }, [
            _vm._v(
              _vm._s(_vm.$t("httpErrors.countdownText")) +
                " " +
                _vm._s(_vm.timer)
            ),
          ]),
        ])
      : _c("div", [
          _c("span", { staticClass: "fail-message" }, [
            _vm._v(_vm._s(_vm.$t("Register.confirmFailed"))),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }