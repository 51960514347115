var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "Stock",
              isSuggestionLoading: _vm.isLoadingSearch,
              customSuggestionPlaceHolder: _vm.$t("Stocks.search_Placeholder"),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmpty,
                  expression: "!isEmpty",
                },
              ],
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-stock-new",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.itemNew },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Stocks.addStockButton")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            staticClass: "toggle-over-groups",
            attrs: {
              list: _vm.typeList,
              selectedItem: _vm.selectedStockType,
              size: "small",
            },
            on: { delegateChangeSelectedItem: _vm.changedStockType },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c(
            "Button",
            {
              staticClass:
                "clear-filter-button border-gray-1 p-0 width-auto m-right-10",
              on: { click: _vm.clearFilterGlobal },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeBarcodeOperationsPopup,
                  expression: "closeBarcodeOperationsPopup",
                },
              ],
              staticClass: "generate-barcode",
              attrs: {
                size: "small",
                secondary: "",
                id: "change-multiple-barcode-generation-button",
                disabled: !_vm.selectedList.length,
              },
              on: { click: _vm.toggleBarcodeOperations },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("Stocks.barcodeOperations")))]),
              _c(
                "div",
                {
                  staticClass: "barcode-selection-popup",
                  class: _vm.showBarcodeOperationsPopup
                    ? "show-barcode-operation-popup"
                    : null,
                },
                [
                  _c("h2", { staticClass: "barcode-selection-popup-header" }, [
                    _vm._v(_vm._s(_vm.$t("Stocks.multipleBarcode"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "barcode-selection-popup-contents m-top-10",
                    },
                    [
                      _c("CustomCheckbox", {
                        staticClass: "change-price-type m-top-5",
                        attrs: {
                          id: "stocks-weighted-barcode-generation",
                          label: _vm.$t("Stocks.weightedBarcode"),
                          checked: _vm.isWeightedBarcode,
                        },
                        on: { change: _vm.changeBarcodeType },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isWeightedBarcode,
                            expression: "isWeightedBarcode",
                          },
                        ],
                        attrs: {
                          errorName: _vm.$t("Stocks.warning_weightedBarcode"),
                        },
                      }),
                      _c(
                        "Button",
                        {
                          staticClass: "m-top-15",
                          attrs: {
                            primary: "",
                            size: "small",
                            id: "change-price-type-activation-status",
                            disabled: !_vm.selectedList.length,
                          },
                          on: { click: _vm.generateBarcode },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Stocks.toolbar_SubmitBarcode_ButtonText"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Stocks.emptyState_Title"),
                  description: _vm.$t("Stocks.emptyState_Description"),
                  buttonText: _vm.$t("Stocks.emptyState_Button"),
                },
                on: { emptyAddButton: _vm.itemNew },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "m-top-20" },
            [
              _vm.isLoading ? _c("TableLoading") : _vm._e(),
              !_vm.isEmpty && !_vm.isLoading
                ? _c("TableView", {
                    attrs: {
                      uniqueIdentifier: "id",
                      fields: _vm.fields,
                      data: _vm.StockItems,
                      componentName: "StockTable",
                      page: _vm.Page,
                      pointer: true,
                      tableName: _vm.$t("Stocks.tableName"),
                      actions: ["edit", "remove"],
                      selectedRows: _vm.selectedList,
                      selectable: "checkbox",
                      selectableAll: "checkbox",
                    },
                    on: {
                      delegateOnItemEdit: _vm.itemEdit,
                      delegateOnItemDelete: _vm.itemDelete,
                      delegateOnItemShow: _vm.itemShow,
                      delegateOnChangePage: _vm.changePage,
                      delegateOnItemSelect: _vm.onSelect,
                      showAllSelectedItems: _vm.selectAll,
                      sort: _vm.sort,
                      submitFilter: _vm.submitFilter,
                      clearFilter: _vm.clearFilter,
                      changePopupFilterSearch: _vm.changePopupFilterSearch,
                      delegateOnChangePageSize: _vm.changePageSize,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("router-view", { on: { getList: _vm.refreshStockItems } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }