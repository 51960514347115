<template lang="pug">
.Invoices#Invoices
    .single-head#single-head-invoice
      .single-head-col.checkbox#invoice-head-col-0
        CustomCheckbox(
          id="checkbox-despatch-header-select-all"
          :checked="isCheckedAll && selectableList.length > 0"
          label=""
          @change="toggleAll"
          :disabled="selectableList.length === 0"
        )
      .single-head-col.name#invoice-head-col-1
        h6#invoice-head-col-text-1 {{ $t('Despatches.tableColumn_Header_SupplierName') }}

      .single-head-col.integration-status#invoice-head-col-2
        h6#invoice-head-col-text-2 {{ $t('Despatches.tableColumn_Header_Integration_Status') }}

      .single-head-col.date#invoice-head-col-3
        h6#invoice-head-col-text-3 {{ $t('Invoices.tableHeader_InvoiceDate') }}

      .single-head-col.number#invoice-head-col-4
        h6#invoice-head-col-text-4 {{ $t('Invoices.tableHeader_InvoiceNumber') }}

      .single-head-col.net#invoice-head-col-5
        h6.u-textRight#invoice-head-col-text-5 {{ $t('Despatches.tableColumn_NetTotalAmount') }}
      .single-head-col.tax#invoice-head-col-6
        h6.u-textRight#invoice-head-col-text-6 {{ $t('Despatches.tableColumn_TaxTotalAmount') }}
      .single-head-col.gross#invoice-head-col-7
        h6.u-textRight#invoice-head-col-text-7 {{ $t('Despatches.tableColumn_GrossTotalAmount') }}
      .single-head-col.roundingTolerance#invoice-head-col-8
        h6.u-textRight#invoice-head-col-text-8 {{ $t('Despatches.tableColumn_RoundingTolerance  ') }}
      .single-head-col.isEInvoice#invoice-head-col-9
        h6.u-textRight#invoice-head-col-text-9 {{ $t('Despatches.eInvoice') }}
      .single-head-col.invoice-partition.align-center#invoice-head-col-9
      .single-head-col.invoice-partition.align-center#invoice-head-col-10
      

    .Invoices-item(
      v-for="(invoice, index) in tableData",
      :id="`invoice-item-${index}`"
      :class="{ 'isOpened': invoice.showDetail , 'small-size': true, 'border-blue': invoice.showDetail }")

      // HEADER
      //
      .Invoices-header(:id="`invoice-item-header-${index}`")
        .NestedTableHeaderLink(
            id="btn-invoices-open-header"
            @click="showDetailAction(invoice)")
        .Invoices-header-col.checkbox(:id="`invoice-item-header-tooltip-${index}`")
          StatusTooltip(
            :id="`invoice-item-header-status-tooltip-${index}`"
            :title="invoice.accountingMessage"
            :defaultMessage="$t('Despatches.Invoice_Integration_Message')"
            icon="icon-row-status"
            v-if="invoice.accountingStatus === 3"
          )
          CustomCheckbox(
            :id="`checkbox-invoice-header-${invoice.id}`"
            :checked="selectedList.indexOf(invoice) > -1"
            label=""
            :disabled="selectableList.indexOf(invoice) === -1"
            @change="isChecked => clickCheckbox(isChecked, invoice)"
          )
        // alert
        .Invoices-header-col.status(:id="`invoice-item-header-status-${index}`")
        // header info
        .Invoices-header-col.name(:id="`invoice-item-header-supplier-name-${index}`")
          .Invoices-header-row.ellipsis
            span.tooltip-relative(:id="`invoice-item-header-supplier-name-text-${index}`") {{ invoice.supplier.name }}
              Tooltip(
                :id="`invoice-item-header-supplier-name-tooltip-${index}`"
                dark,
                bottom,
                :text="getStoreGroupsCode(invoice.storeGroups)"
              )
        .Invoices-header-col.integration-status(:id="`invoice-item-header-integration-status-${index}`")
          .Invoices-header-row.ellipsis(:id="`invoice-item-header-integration-status-text-${index}`") {{ getAccountingStatus(invoice.accountingStatus) }}
        .Invoices-header-col.date(:id="`invoice-item-header-date-${index}`") {{ invoice.invoiceDate | dateFormat }}
        .Invoices-header-col.number(:id="`invoice-item-header-number-${index}`") {{ invoice.invoiceNumber }}
        .Invoices-header-col.net.ff-mono.u-textRight(:id="`invoice-item-header-net-${index}`") {{ invoice.netTotalAmount | formatCurrency2Digits }}
        .Invoices-header-col.tax.ff-mono.u-textRight(:id="`invoice-item-header-tax-${index}`") {{ invoice.taxTotalAmount | formatCurrency2Digits }}
        .Invoices-header-col.gross.ff-mono.u-textRight(:id="`invoice-item-header-gross-${index}`") {{ invoice.grossTotalAmount | formatCurrency2Digits }}
        .Invoices-header-col.roundingTolerance.ff-mono.u-textRight(:id="`invoice-item-header-rounding-tolerance-${index}`") {{ invoice.roundingTolerance }}
        .Invoices-header-col.isEInvoice(:id="`invoice-item-header-isEInvoice-${index}`") {{ isElectronicInvoice(invoice.isEInvoice) }}

        .Invoices-header-col.description
          Button.show-MiniPopup.relative.width-max-content(
            v-if="invoice.description",
            size="small"
            variant="info"
            :id="`invoice-header-info-button-${invoice.index}`"
            iconName="icon-global-info"
            :justIcon="true",
          )
            .MiniPopup-right-primary.m-top-15.c-dark
              span {{ invoice.description }}

        ActionButton(
          id="despatches-invoice-action-button"
          :data="(invoice.accountingStatus === 3 || invoice.accountingStatus === 0 && !invoice.integrationProductId) ? ['export', 'separateInvoice'] : ['export']"
          :item="invoice"
          @onItemExport="showExportPopup(invoice.id)"
          @onItemSeparateInvoice="invoicePartition(invoice)"
        )

      InvoiceDetail(
        :id="`invoice-item-details-${index}`"
        v-if="invoice.showDetail"
        :tableData = "invoice.invoiceDetailList"
        :headerIndex="index")

    Alert(
        v-if="selectedInvoice.id"
        :title="$t('Despatches.confirm_Inovice_Partition')"
        :description="$t('Despatches.confirm_Inovice_Partition_Message', { invoiceNumber: selectedInvoice.invoiceNumber })"
        :okButton="$t('Despatches.confirm_Inovice_Partition_OK')"
        icon="icon-global-notify"
        loading="alertForm"
        @delegateOnAlertOk="invoiceDecompose"
        @delegateOnAlertCancel="closePopup"
      )
</template>

<script>
import InvoiceDetail from './invoices-detail'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import StatusTooltip from '@/view/global/alert-status-tooltip'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'InvoicesTable',

  data () {
    return {
      selectedInvoice: {}
    }
  },

  components: {
    InvoiceDetail,
    StatusTooltip
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    showDetailAction: {
      type: Function,
      required: true
    },
    selectedList: {
      type: Array,
      required: true
    },
    accountingList: {
      type: Array,
      default: []
    },
    showExportPopup: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapGetters('Despatch', [
      'despatch_List'
    ]),

    isEmptySelectedList () {
      return this.selectedList.length === 0
    },

    selectableList () {
      return this.tableData.filter(item => item.accountingStatus === 0 || item.accountingStatus === 3)
    },

    isCheckedAll () {
      return this.selectedList.length === this.selectableList.length
    }
  },
  methods: {
    ...mapMutations('Despatch', [
      'HIDE_INVOICE_DETAIL',
      'SHOW_INVOICE_DETAIL'
    ]),

    ...mapActions('Despatch', [
      'get_DESPATCH',
      'postInvoicePartition'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    invoiceDecompose: vueWaitLoader(async function () {
      await this.postInvoicePartition(this.selectedInvoice.id)
        .then(res => {
          const message = this.$t(
            'Despatches.notification_InventoryDecompose',
            { invoiceNumber: this.selectedInvoice.invoiceNumber }
          )
          this.$emit('getList')
          this.closePopup()
          this.notifyShow({ message })
        })
    }, 'alertForm'),

    closePopup () {
      this.selectedInvoice = {}
    },

    invoicePartition (invoice) {
      this.selectedInvoice = invoice
    },

    clickCheckbox (isChecked, invoice) {
      this.$emit('delegateCheckItem', { isChecked, invoice })
    },

    toggleAll (isChecked) {
      this.$emit('delegateCheckAllItem', isChecked)
    },

    getAccountingStatus (value) {
      return this.accountingList.find(item => item.value === value).name
    },

    getStoreGroupsCode (storeGroupList) {
      if (storeGroupList.length === 0) return ''
      return storeGroupList.map(item => !item.code ? item.name : item.code).join(', ')
    },
    isElectronicInvoice (type) {
      return type === true ? this.$t('Dictionary.Yes') : this.$t('Dictionary.No')
    }
  }
}
</script>

<style lang="scss" scoped>

  .Invoices {
    border: solid 1px $color-gray;
    border-radius: $border-radius;

    .btn-edit {
      color: $color-light;
      background-color: $color-gray;
    }

    .btn-remove {
      color: $color-warning;
      background-color: #fcedec;
    }

    .btn-switch {
      color: $color-success;
    }

    &-item.isOpened .btn-switch {
      transform: rotate(180deg);
    }

    &-item {
      line-height: 1;
      background: $color-white;
      &:nth-child(odd) {
        background-color: $color-snow;
      }
      &.border-blue{
        border: solid 1px $color-primary;
      }
      &:hover {
        &:not(.isOpened) {
          background: $color-ocean;
          border:1px solid $color-light-blue;
        }
      }
    }

    &-header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 60px;
      border-radius: $border-high-radius;
      .Invoices-item.isOpened & {
        background-color: $color-ocean;
      }
      &-row {
        padding: 0;
      }
      &-col {
        padding: 12px 12px;
        display: flex;
        flex-direction: column;
        word-break: break-word;

        .title {
          font-size: $font-size-micro;
          color: $color-success;
          margin-bottom: 6px;
        }

        .body.ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.status {
          padding: 0;
          width: 15px;
          position: absolute;

          div {
            display: none;
            z-index: 2;
            position: relative;
            left: 0;
            min-width: 150px;
            margin-top: 10px;
            justify-content: center;
            font-size: $font-size-small;
            transform: translateX(-50%) translateY(100%);
            background-color: white;
            box-shadow: 0 0 0 2px rgba($color-warning, 1);
            border-radius: $border-radius;
            padding: 10px;
            &::after, &::before {
              content: '';
              position: absolute;
              bottom: 100%;
              transform: translateX(calc(50% - 10px));
              pointer-events: none;
            }
            &::before {
              border: 8px solid;
              border-color: transparent transparent $color-warning transparent;
            }
          }
        }

        &.name {
          width: 11%;
          font-weight: $font-weight-bold;
          margin-left: 15px;
          flex-shrink: 0;
        }

        &.integration-status {
          width: 11%;
          flex-shrink: 0;
        }

        &.date {
          width: 11%;
          flex-shrink: 0;
        }

        &.number {
          width: 11%;
          flex-shrink: 0;
        }

        &.net {
          width: 8%;
          flex-shrink: 0;
        }

        &.tax {
          width: 11%;
          flex-shrink: 0;
        }

        &.gross {
          width: 10%;
          flex-shrink: 0;
        }

        &.roundingTolerance {
          width: 8%;
        }

        &.isEInvoice {
          width: 6%;
          padding-left: 2%;
          flex-shrink: 0;
        }

        &.description {
          width: 4%;
        }

        &.invoice-partition {
          margin-left: 40px;
          color: $color-success;
          text-decoration: underline;
          cursor: pointer;
          position: relative;
        }

        &.action {
          margin-left: auto;

          .body {
            display: flex;
            align-items: center;
          }

          .btn + .btn {
            margin-left: 10px;
          }
        }
      }
    }

    &-details {
      padding: 20px;
    }

    &-header .action {
      opacity: 0;
      transition: opacity $transition;
    }

    &-header:hover > .action,
    &-header:focus-within > .action {
      opacity: 1;
    }
  }
.Invoices-head-table {
  display: flex;
  width: 100%;
}

.single-head {
  background-color: $color-lighter-blue;
  display: flex;
  align-items: center;
  height: $finger-size-big;
  border-radius: $border-high-radius;

    .name {
      width: 11%;
      margin-left: 15px;
    }
    .integration-status {
      width: 11%;
    }

    .date {
      width: 11%;
    }

    .number {
      width: 11%;
    }

    .net {
      width: 8%;
    }

    .tax {
      width: 11%;
    }

    .gross {
      width: 10%;
    }

    .roundingTolerance {
      width: 8%;
    }

    .invoice-partition {
      width: 7%;
    }

  &-col {
    font-family: $font-family;
    font-size: $font-size-big;
    font-weight: bold;
    flex-shrink: 0;
    padding: 15px 10px;

    h6 {
      font-size: $font-size-small;
      color: $color-success;
    }
  }
}
.checkbox {
  margin-left: 15px;
}

.tooltip-relative {
  cursor: pointer;
}

.Alert{
  :deep()  & > .Alert-body {
    & > .Alert-description {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
    & > .Alert-item {
      margin-top: 0px;
    }
  }
}
.MiniPopup-right-primary {
  word-break: break-word;
}
</style>
