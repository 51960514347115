// import requestHandler from '@/utils/requestHandler'
// import HTTP from '@/utils/http'

export default {
  namespaced: true,

  state: {
    HeaderLinks: [
      'ListsDespatch',
      'ListsInventory',
      'ListsRequestTransfer',
      'ListsRequestOrder',
      'ListsWaste',
      'ListsProduction',
      'TransferSalesPrice',
      'ListsYield',
      'ListsShelfStorageLocation',
      'ListsExpense'
    ]
  },

  getters: {
    HeaderLinks: state => state.HeaderLinks
  },

  mutations: {}
}
