<template lang="pug">
 Page
   template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "PageLinks"
      translationGroup="TabItems"
    )

   template(slot="toolbar-right")
      ViewType.view-type(
        firstNodeIcon="icon-view-type-list"
        secondNodeIcon="icon-view-type-group"
        :firstSelected="viewTypeList"
        @firstNodeClick="listViewType(true)"
        @secondNodeClick="listViewType(false)"
      )
   template(slot="content-center")
     TableLoading(v-if="isFetchingList")
     .boxContainer
      TitleBox.allergen-title-box(
        v-if="!isFetchingList && !viewTypeList"
        v-for="(item, index) in AllergenList"
        :key="index"
        :data="item"
        :title="item.name"
        :icon = "item.iconName"
        :boxIndex="index"
        :showTooltip="true"
        :tooltipCharacterSize="30"
        :description="item.description || $t('Allergen.table_DefaultDescription')"
        :page="Page"
        :componentName="'AllergenBox'"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
      )

     TableView(
       v-if="!isFetchingList && viewTypeList"
       :fields="fields"
       :data="AllergenList"
       :page="Page"
       :read-only="true"
       :extraClass="{forContent: 'm-auto box-flex-row'}"
       :componentName="'AllergenTable'"
       @delegateOnChangePage="changePage"
       @delegateOnChangePageSize="changePageSize"
     )

     portal(to="symbol")
      .allergens.width-auto(slot-scope="detail")
        Icon(:name="detail.iconName")

     router-view(@getAllergenList="getAllergenList")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ViewType from '@/view/global/view-type'
import TitleBox from '@/view/global/title-box'
import getFields from './fields/allergen'

export default {
  name: 'allergen',
  data () {
    return {
      viewTypeList: true,
      fields: getFields(this.$t)
    }
  },

  components: {
    ViewType,
    TitleBox
  },

  mounted () {
    this.getAllergenList()
  },
  computed: {
    ...mapGetters('OptionalFields', ['PageLinks']),

    ...mapGetters('Allergen', ['Page', 'AllergenList', 'isAllergenDataEmpty']),

    isFetchingList () {
      return this.$wait.is('getAllergenListAll')
    }
  },
  methods: {
    ...mapActions('Allergen', ['getAllergenListAll']),

    changePage (page) {
      this.getAllergenList(page)
    },

    changePageSize (pageSize) {
      this.getAllergenList(1, pageSize)
    },

    getAllergenList (pageNumber = this.Page.number, pageSize = this.Page.size) {
      const payload = {
        pageNumber,
        pageSize
      }
      this.getAllergenListAll(payload)
    },

    listViewType (type) {
      this.viewTypeList = type
    }
  }
}
</script>

<style lang="scss" scoped>
  .allergens{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: solid 1px $color-gray;
    background-color: $color-light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .boxContainer{
    display: flex;
    flex-wrap: wrap;
  }

  .allergen-title-box {
    width: 280px !important;
    margin-right: 20px;
    margin-top: 20px;
  }
</style>
