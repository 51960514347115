<template lang="pug">
Page
  template(slot="header-left-dropdown")
    div
  template(slot="content-center")
    TableLoading(v-if="isFetchingData && isFetchingNutrition")
    template(v-else)
      div(v-for="(integration, index) in integrationProducts")
        IntegrationItems(
          v-if="renderComponent && !isFetchingData && !isFetchingNutrition"
          :list="integration.list",
          :integrationTitle="integration.title || $t('Integration.nutritionalValues')"
          @activateNutrition="changeWarningPopupStatus"
          @activateProduct="toggleProductPermission"
          @deActivateIBar="deActivateIBar"
          @openActionPopup="openPopup"
          @closeActionPopup="closeActionPopup"
          @activateModal="activateModal"
          @closeModal="closeModal"
        )

      .nutrition-container
        Alert(
          v-if="showChangingWarning"
          :title="$t('Integration.Warning_changingNutritionData')"
          :description="$t('Integration.description_changingNutritionData')"
          :okButton="$t('Global.change')"
          icon="icon-global-notify"
          @delegateOnAlertOk="showWarning"
          @delegateOnAlertCancel="closePopup"
        )
        Alert(
          v-if="showPassiveWarning"
          :title="$t('Integration.Warning_passiveNutritionData')"
          :description="$t('Integration.description_passiveNutritionData')"
          :okButton="$t('Global.passive')"
          icon="icon-global-notify"
          @delegateOnAlertOk="showWarning"
          @delegateOnAlertCancel="closePopup"
        )
        Alert.last-alert(
          v-if="showLastWarning"
          :title="$t('Integration.Warning')"
          :description="$t('Integration.warning_DeleteAll')"
          :okButton="$t('Global.deleteAlert_DeleteButton')"
          icon="icon-global-notify"
          @delegateOnAlertOk="updateNutritionSettings"
          @delegateOnAlertCancel="closePopup"
        )
        Alert.first-alert(
          v-if="showFirstSelectWarning"
          :title="$t('Integration.Warning')"
          :description="$t('Integration.selectDataSet')"
          :okButton="$t('Global.alert_OkButton')"
          icon="icon-global-notify"
          @delegateOnAlertOk="updateNutritionSettings"
          @delegateOnAlertCancel="closePopup"
        )

</template>

<script>
//
import IntegrationItems from './components/IntegrationItems'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Integration',

  components: {
    IntegrationItems
  },

  data () {
    return {
      nutritionList: [],
      showChangingWarning: false,
      showPassiveWarning: false,
      selectedSet: [],
      showLastWarning: false,
      showFirstSelectWarning: false,
      renderComponent: true,
      integrationProducts: []
    }
  },

  /*
  */

  computed: {

    ...mapGetters('Integration', ['IntegrationProductList']),

    isFetchingData () {
      return this.$wait.is(['fetchIntegrationProductList'])
    },

    isFetchingNutrition () {
      return this.$wait.is(['getAllNutritionList'])
    },

    IntegrationTypes () {
      return [
        {
          value: 1,
          label: 'POS',
          prefix: 'Pos',
          sort: 1
        },
        {
          value: 2,
          label: this.$t('Integration.accounting'),
          prefix: 'Accounting',
          sort: 5
        },
        {
          value: 3,
          prefix: 'B2b',
          label: 'B2B',
          sort: 4
        },
        {
          value: 4,
          prefix: 'Other',
          label: this.$t('Integration.other'),
          sort: 6
        },
        {
          value: 6,
          prefix: 'Protel',
          label: this.$t('Integration.eInvoice'),
          sort: 3
        },
        {
          value: 7,
          prefix: 'WarehouseManagement',
          label: this.$t('Integration.warehouseManagement'),
          sort: 5
        }
      ]
    }
  },

  /*
  */

  methods: {
    ...mapActions('Integration', ['fetchIntegrationProductList', 'getAllNutritionList', 'updateNutritionSet', 'updateIntegrationStatus', 'updateIBarIntegrationStatus']),

    async setData () {
      await this.fetchIntegrationProductList()
      await this.getAllNutritionList()
        .then(res => {
          this.nutritionList = res.data.nutritionDataList
          this.nutritionList.forEach(i => {
            i.img = `${i.name}.png`
            i.isTypeNutrition = true
          })
        })

      await this.setIntegrationProducts()
    },

    setIntegrationProducts () {
      this.integrationProducts = []
      this.IntegrationTypes.forEach(type => {
        const list = this.IntegrationProductList
          .filter(_ => _.type === type.value)
          .map(product => {
            let to
            if (product.name === 'Protel') to = `ProtelIntegratorStoreGroupConfig`
            else if (product.name === 'Sovos Digital Planet') to = `SovosDigitalPlanet`
            else if (product.name === 'QNB eFinans') to = `IntegrationProtelEFinans`
            else if (product.name === 'Sales Forecasting') to = `SalesForecasting`
            else if (product.name === 'axata') to= `WarehouseManagementAxata`
            else to = `Integration${type.prefix}${product.name}`
            return {
              ...product,
              img: `${type.prefix.toLowerCase(this.$i18n.locale)}_${product.name.toLowerCase(this.$i18n.locale)}.png`,
              to
            }
          })
        this.integrationProducts.push({
          sort: type.sort,
          title: type.label,
          list
        })
      })
      this.integrationProducts.splice(1, 0, {
        list: this.nutritionList,
        sort: 1,
        isTypeNutrition: true,
        title: ''
      })
      return this.integrationProducts.sort((a, b) => {
        return a.sort - b.sort
      })
    },
    showWarning () {
      this.showLastWarning = true
    },
    openPopup (item) {
      item.showActionBar = !item.showActionBar
      //this.forceRerender()
    },
    deActivateIBar () {
      const payload = {
        isActive: false
      }
      this.updateIBarIntegrationStatus(payload)
      this.setData()
    },
    closeActionPopup (item) {
      item.showActionBar = false
      this.forceRerender()
    },
    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    changeWarningPopupStatus (item) {
      this.selectedSet = item
      const control = this.nutritionList.find(i => i.isUsed)
      if (!control) {
        this.showFirstSelectWarning = true
      }
      if (item.isUsed === true) {
        this.showPassiveWarning = true
        this.selectedSet = []
      } else {
        this.showChangingWarning = true
      }
    },
    closePopup () {
      this.showChangingWarning = false
      this.showPassiveWarning = false
      this.showLastWarning = false
      this.showFirstSelectWarning = false
    },
    async updateNutritionSettings () {
      const payload = {
        nutritionId: this.selectedSet.id
      }
      await this.updateNutritionSet(payload)
      await this.setData()
      this.showChangingWarning = false
      this.showPassiveWarning = false
      this.showLastWarning = false
      this.showFirstSelectWarning = false
    },
    async toggleProductPermission (item) {
      const params = {
        id: item.id,
        isActive: !item.isActive
      }
      await this.updateIntegrationStatus(params)
      await this.setData()
    },
    activateModal (item) {
      item.isModalActive = true
    },
    closeModal (item) {
      item.isModalActive = false
      this.forceRerender()
    }
  },

  /*
  */
  async beforeMount () {
    await this.setData()
  }
}
</script>

<style lang="scss" scoped>

  :deep() .Alert{
    z-index: $z-index-xxxl !important;
  }
  .nutrition {
    &-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    &-title {
      color: #7b8187;
      font-size: 14px;
      font-weight: $font-weight-bold;
    }
    &-box {
      width: 275px;
      margin-right: 27px;
      height: 188px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.747);
      border-radius: 6px;
      margin-top: 20px;
      box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.09);
      &:hover{
        border: 1px solid $color-success;
        box-shadow: 0 1px 14px 0 rgba(3, 116, 37, 0.411);
      }
      &-selected {
        box-shadow: 0 1px 14px 0 rgba(3, 116, 37, 0.411);
        border: 4px solid $color-success;
      }
    }
  }
  .description {
    font-weight: $font-weight-normal;
  }
  .region-name {
    font-size: $font-size-bigger;
    color: $color-dark;
    font-weight: $font-weight-bold;
  }
  :deep() .Alert-actions .btn[data-v-40b85558] {
    &:nth-child(2){
      background: $color-light-warning;
      color: $color-warning;
      border: 1px solid $color-warning;
    }
  }
  :deep() .Alert-description {
    font-size: $font-size-bigger !important;
    width: 600px !important;
  }
</style>
