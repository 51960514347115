<template lang="pug">
div
  EmptyState(
    v-if="suspendedSalesHeaderList.length === 0 && !isLoading"
    :title="$t('MenuItems.SuspendSalesNoyFoundTitle')"
    :description="$t('MenuItems.SuspendSalesNoyFoundDescription')")
  .ss
    .ss-box(v-if="suspendedSalesHeaderList.length !== 0 && !isLoading"
            v-for="row in suspendedSalesHeaderList")
      .ss-box-clicker(@click="goToDetail(row.errorType)")
      .ss-content
        .ss-left
          .ss-checkbox
            CustomCheckbox.checkbox(
              @change="ic => toggleCheckbox(ic, row)"
              :checked="selectedList.some(item => item.errorType === row.errorType)"
              label=""
            )
          .ss-container
            .ss-head
              p.font-size-small.c-light.m-bottom-5 {{ $t('MenuItems.suspendErrorType') }}
              p.font-weight-600 {{ $t(`MenuItems.suspendErrorType${row.errorType}`) }}
            .ss-body
              p.font-size-small.c-light.m-bottom- {{ $t('MenuItems.suspendedSaleTableAmount') }}
              p.c-warning.ff-mono {{ row.totalAmount | formatCurrency }}
        .ss-right
          Icon(name="icon-arrow-down")


</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SuspendSalesHeaders',
  props: ['isLoading'],
  data () {
    return {
      selectedList: []
    }
  },
  mounted () {
    this.$emit('getList')
  },
  computed: {
    ...mapGetters('SuspendedSales', ['suspendedSalesHeaderList'])
  },
  methods: {
    ...mapActions('SuspendedSales', [
      'getSuspendedSalesHeaderList'
    ]),
    toggleCheckbox (isChecked, item) {
      if (isChecked) this.selectedList.push(item)
      else this.selectedList.splice(this.selectedList.indexOf(item), 1)
    },
    goToDetail (errorType) {
      this.$router.push({
        name: 'SuspendSalesDetail',
        params: { errorType }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .ss{
    display: flex;
    flex-wrap: wrap;
    &-box{
      position: relative;
      padding: 15px;
      width: 272px;
      height: 135px;
      border-radius: 4px;
      margin-right: 25px;
      margin-bottom: 20px;
      border: solid 1px $color-gray;
      background-color: $color-white;
      display: flex;
      &:hover{
        background-color: $color-ocean;
        border: 1px solid $color-primary
      }
      &-clicker{
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    &-left{
      display: flex;
    }
    &-checkbox{
      margin-right: 10px;
    }
    &-container{
      display: grid;
    }
    &-right{
      align-self: center;
      transform: rotate(-90deg);
      color: $color-success;
    }
    &-body{
      align-self: end;
    }
    &-content{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  :deep() .indicator:focus{
      outline: none !important
    }

</style>
