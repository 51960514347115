var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.PageLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ViewType", {
            staticClass: "view-type",
            attrs: {
              firstNodeIcon: "icon-view-type-list",
              secondNodeIcon: "icon-view-type-group",
              firstSelected: _vm.viewTypeList,
            },
            on: {
              firstNodeClick: function ($event) {
                return _vm.listViewType(true)
              },
              secondNodeClick: function ($event) {
                return _vm.listViewType(false)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList ? _c("TableLoading") : _vm._e(),
          _c(
            "div",
            { staticClass: "boxContainer" },
            _vm._l(_vm.AllergenList, function (item, index) {
              return !_vm.isFetchingList && !_vm.viewTypeList
                ? _c("TitleBox", {
                    key: index,
                    staticClass: "allergen-title-box",
                    attrs: {
                      data: item,
                      title: item.name,
                      icon: item.iconName,
                      boxIndex: index,
                      showTooltip: true,
                      tooltipCharacterSize: 30,
                      description:
                        item.description ||
                        _vm.$t("Allergen.table_DefaultDescription"),
                      page: _vm.Page,
                      componentName: "AllergenBox",
                    },
                    on: {
                      delegateOnChangePage: _vm.changePage,
                      delegateOnChangePageSize: _vm.changePageSize,
                    },
                  })
                : _vm._e()
            }),
            1
          ),
          !_vm.isFetchingList && _vm.viewTypeList
            ? _c("TableView", {
                attrs: {
                  fields: _vm.fields,
                  data: _vm.AllergenList,
                  page: _vm.Page,
                  "read-only": true,
                  extraClass: { forContent: "m-auto box-flex-row" },
                  componentName: "AllergenTable",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "symbol" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    { staticClass: "allergens width-auto" },
                    [_c("Icon", { attrs: { name: detail.iconName } })],
                    1
                  )
                },
              },
            ]),
          }),
          _c("router-view", { on: { getAllergenList: _vm.getAllergenList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }