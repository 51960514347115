<template lang="pug">
.container
  .row  
  .row
    .col-6  
    .col-6
      | User..........
      input#userInput(type='text' v-model="form.user")
      br
      |             Message...
      input#messageInput(type='text' v-model="form.message")
      input#sendButton(type='button', value='Send Message' @click="sendMessage")
  .row
    .col-12
      hr
  .row
    .col-6  
    .col-6
      ul#messagesList
        li(v-for="log in messageList" v-if="log.user && log.message") {{ log.user + " diyor ki: " + log.message }}
</template>
<script>
/* eslint-disable */
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'

export default {

  data() {
    return {
      connection: null,
      form: {
        user: '',
        message: ''
      },
      messageList: []
    }
  },
  async created() {
    const ls = JSON.parse(localStorage.getItem(window.VUE_APP_VUEX_NAME || process.env.VUE_APP_VUEX_NAME))
    // await this.connection.Headers.Add(ls.Auth.Token.type, ls.Auth.Token.value)

    this.connection = new HubConnectionBuilder()
      .withUrl(`https://api-test-inventory.simprasuite.com/mainhub?token=${ls.Auth.Token.value}`, {
          headers: {"authorization": `Bearer ${ls.Auth.Token.value}`},
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Information)
      .build()

    await this.connection.start().catch(function(err) {
      return console.error(err.toString())
    })
    this.getMessageList()
  },
  methods: {
    sendMessage() {
      this.connection.invoke('SendMessage', this.form.user, this.form.message).catch(function(err) {
        return console.error(err.toString())
      })
    },
    getMessageList() {
      const self = this
      this.connection.on('ReceiveMessage', function(user, message) {
        self.messageList.push({user, message})
      })
    }

  }
}
</script>
