var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-left-dropdown" }, [_c("div")]),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "Reportings" },
          [
            _vm.isFetchingReportGroups
              ? _c("TableLoading")
              : _c(
                  "div",
                  { staticClass: "reports" },
                  _vm._l(_vm.ReportGroups, function (group) {
                    return _c("div", { key: group.id, staticClass: "report" }, [
                      _c("div", { staticClass: "title" }, [
                        _c("h4", [_vm._v(_vm._s(group.name))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "items" },
                        [
                          group.reports.length === 0
                            ? _c("div", { staticClass: "item" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Reports.Reporting_Group_Empty_Message"
                                    )
                                  )
                                ),
                              ])
                            : _vm._l(group.reports, function (report) {
                                return _c(
                                  "router-link",
                                  {
                                    key: report.id,
                                    staticClass: "item",
                                    attrs: {
                                      to: {
                                        name: "ReportItem",
                                        params: {
                                          id: report.id,
                                          name: report.name,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "reportname" }, [
                                      _vm._v(_vm._s(report.name)),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "symbol" },
                                      [
                                        _c("Icon", {
                                          attrs: { name: "icon-arrow-right" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                        ],
                        2
                      ),
                    ])
                  }),
                  0
                ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }