<template lang="pug">
div
  EmptyState(
    v-if="isEmptySuspendedSalesDetailList && !isLoading"
    :title="$t('MenuItems.SuspendSalesNoyFoundTitle')"
    :description="$t('MenuItems.SuspendSalesNoyFoundDescription')")
  div.header-title(
    @click="$router.push({ name: 'SuspendSalesHeaders' })"
    v-if="!isEmptySuspendedSalesDetailList && !isLoading"
    )
    Icon(name="header-back-icon")
    p.m-left-10 {{ $t(`MenuItems.suspendErrorType${$route.params.errorType}`) }}
  TableView(
    v-if="!isEmptySuspendedSalesDetailList && !isLoading"
    :fields="fields"
    :componentName="'SuspendSalesTable'"
    :data="suspendedSalesDetailList"
    :readOnly="true"
    :page="Page"
    @delegateOnChangePage="changePage"
    @delegateOnChangePageSize="changePageSize"
    :selectable="'checkbox'"
    :selectableAll="'checkbox'"
    :selectedRows="selectedList"
    @delegateOnItemSelect="onSelect"
    @showAllSelectedItems="selectAll"
    )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getFields from './fields-suspendedSales'

export default {
  name: 'SuspendSalesDetail',
  props: ['isLoading'],
  data () {
    return {
      fields: getFields(this.$t),
      selectedList: []
    }
  },
  created () {
    this.$emit('getList')
  },
  computed: {
    ...mapGetters('SuspendedSales', [
      'isEmptySuspendedSalesDetailList',
      'suspendedSalesDetailList',
      'Page'
    ])
  },
  methods: {
    ...mapActions('SuspendedSales', [
      'getSuspendedSalesDetailList'
    ]),
    changePage (page) {
      this.$emit('changePage', page)
    },
    changePageSize (pageSize) {
      this.$emit('changePageSize', pageSize)
    },
    onSelect (row) {
      const isChecked = !this.selectedList.some(item => item === row)
      if (isChecked) this.selectedList.push(row)
      else this.selectedList.splice(this.selectedList.indexOf(row), 1)
    },
    selectAll (isChecked) {
      if (isChecked) this.selectedList = [...this.suspendedSalesDetailList]
      else this.selectedList = []
    }
  }
}
</script>

<style lang="scss" scoped>

.header-title {
  display: inline-flex;
  color: $color-dark;
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  cursor: pointer;
  margin-bottom: 10px;
}
</style>
