var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.paymentType_IsEmpty && !_vm.isLoading,
                  expression: "!paymentType_IsEmpty && !isLoading",
                },
              ],
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-payment-type-add",
                type: "button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.itemNew },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("PaymentType.addPaymentTypeButtonLabel"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.paymentType_IsEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("PaymentType.emptyState_Title"),
                  description: _vm.$t("PaymentType.emptyState_Description"),
                  buttonText: _vm.$t("PaymentType.emptyState_Button"),
                },
                on: { emptyAddButton: _vm.itemNew },
              })
            : _vm._e(),
          !_vm.paymentType_IsEmpty && !_vm.isLoading
            ? _c(
                "div",
                { staticClass: "Boxlist" },
                _vm._l(_vm.paymentType_List, function (paymentType) {
                  return _c("div", { staticClass: "Boxlist-item" }, [
                    _c(
                      "div",
                      { staticClass: "Boxlist-actions" },
                      [
                        _c("ActionButton", {
                          attrs: {
                            data: ["edit", "remove"],
                            item: paymentType,
                          },
                          on: {
                            onItemEdit: function ($event) {
                              return _vm.itemEdit(paymentType.id)
                            },
                            onItemDelete: function ($event) {
                              return _vm.itemRemove(paymentType)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "Boxlist-body" }, [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(_vm._s(paymentType.name)),
                      ]),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
          _c("router-view"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }