<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="Settings"
    )

  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="headerFields"
      :useUpperCase="false"
      :selectedItem="selectedHeader"
      @delegateChangeSelectedItem="changeHeader"
      selectedKey="headerValue"
    )
  template(slot="content-center")
    TableLoading(v-if="!details.length")

    section.settings-date(v-else)
      .date-filter
        .date-filter-header
          .date-filter-header-columns
          .date-filter-header-columns
            span.date-filter-header-columns-title {{ $t('DateFilter.Type_Field_Daily') }}
          .date-filter-header-columns
            span.date-filter-header-columns-title {{ $t('DateFilter.Type_Field_Yesterday') }}
          .date-filter-header-columns
            span.date-filter-header-columns-title {{ $t('DateFilter.Type_Field_Today') }}
          .date-filter-header-columns
            span.date-filter-header-columns-title {{ $t('DateFilter.Type_Field_ThisWeek') }}
          .date-filter-header-columns
            span.date-filter-header-columns-title {{ $t('DateFilter.Type_Field_LastWeek') }}
          .date-filter-header-columns
            span.date-filter-header-columns-title {{ $t('DateFilter.Type_Field_ThisMonthAll') }}
          .date-filter-header-columns
        .date-filter-body
          .date-filter-body-row(
            v-for="(detail, detailIndex) in filteredDetails"
          )
            .date-filter-body-row-columns
              span.date-filter-body-row-columns-title {{ $t('DateFilter.Detail_Field_' + detail.key) }}
            .date-filter-body-row-columns(
              v-for="dataType in types"
              v-if="dataType.value < 220"
            )
              CustomRadio(
                :checked="detail.value  < 100 && dataType.value < 100 || detail.value === dataType.value"
                label=""
                @change="changeDetail(detail, dataType)"
              )
              form(:data-vv-scope="detail.key")
                input.txt.date-filter-input.no-spinners(
                  v-if="detail.value < 100 && dataType.value < 100"
                  :disabled="!(detail.value < 100 && dataType.value < 100)"
                  name="input"
                  v-default-value="detail.value"
                  :class="{ 'is-danger': veeErrors.has(detail.key + '.input') }"
                  type="number"
                  v-validate="'required|integer|min_value:-6|max_value:6|not_in:0'"
                  @input="e => changeInput(e, detail, dataType)"
                )
            .date-filter-body-row-columns
              CustomRadio(
                :checked="detail.value  >= 220"
                :disabled="true"
                label=""
              )
              customSelectOption.m-left-10(
                :optionData="monthTypes"
                :detailData="detail"
                @inputSelected="changeDetailWitDropdown"
                ref="customSelect"
              )
            .date-filter-body-row-columns
              Button.date-filter-body-row-columns-remove(@click="setDefaultValue(detail)" size="small") {{ $t('DateFilter.Button_Set_Defaul_Type') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import dateFilterTypes from '@/constant/date-filter-types'
import dateFilterFn from '@/utils/get-date-filter-fn'
import getHeaders from './constant/get-headers'
import customSelectOption from './components/select-component'

export default {
  name: 'SettingsFilterDate',
  components: {
    ToggleOverGroups,
    customSelectOption
  },
  data () {
    return {
      headerList: getHeaders(),
      types: dateFilterTypes,
      details: [],
      selectedHeader: null
    }
  },
  async mounted () {
    this.changeHeader(this.headerFields[0])
    this.getDateFilterSettings()
      .then(res => {
        this.details = res.data.dateSettings.map(i => { return { ...i } })
      })
  },
  computed: {
    ...mapGetters('Settings', ['pageHeaderLinks']),
    headerFields () {
      return Object.keys(this.headerList).map(headerValue => {
        return {
          name: this.$t('DateFilter.Header_Field_' + headerValue),
          headerValue
        }
      })
    },
    filteredDetails () {
      return this.details.filter(detail => this.headerList[this.selectedHeader.headerValue].some(h => h === detail.key))
    },
    monthTypes () {
      return this.types.filter(type => type.value >= 220)
    }
  },
  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),
    ...mapActions('Settings', ['setDefaultSettings']),
    getDateExample (detail) {
      const dates = dateFilterFn(detail, 'LL')
      return dates[0] + ' - ' + dates[1]
    },

    changeDetailWitDropdown (option, detail) {
      this.changeDetail(detail, option)
    },

    changeHeader (header) {
      this.selectedHeader = header
    },
    changeDetail (detail, type) {
      const custom = this.$refs.customSelect.filter(item => item.detailData.key === detail.key)
      detail.value = type.value
      const params = {
        settings: {
          [detail.key]: detail.value
        }
      }
      this.setDefaultSettings(params)
      custom[0].selectedValue()
    },
    changeInput (event, detail, type) {
      this.$validator.validateAll(detail.key)
        .then(res => {
          if (!res) return
          type.value = event.target.value
          this.changeDetail(detail, type)
        })
    },
    setDefaultValue (detail) {
      const type = this.types.find(t => t.value === detail.defaultValue)
      this.changeDetail(detail, type)
    }
  }
}
</script>

<style lang="scss" scoped>

.date-filter {
  margin-top: 23px;
  border: 1px solid $color-gray;
  &-header {
    display: grid;
    grid-template-columns: 10% repeat(5, 10%) 17% 23%;
    background-color: $color-lighter-blue;
    height: 60px;
    &-columns {
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:first-child) {
        border-left: 1px solid $color-gray;
      }
      &-title {
        font-size: $font-size-small;
        color: $color-light;
      }
    }
  }
  &-body {
    &-row {
      display: grid;
      grid-template-columns: 10% repeat(5, 10%) 17% 23%;
      height: 60px;
      background-color: $color-white;
      &:nth-child(even) {
        background-color: $color-snow;
      }
      &-columns {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          border-left: 1px solid $color-gray;
          justify-content: center;
        }
        &:last-child {
          justify-content: left;
        }
        &-title {
          font-size: $font-size-small;
          color: $color-dark;
          margin-left: 20px;
        }
        .date-filter-input {
          width: 46px;
          min-height: 36px;
          margin-left: 10px;
          font-size: $font-size-micro;
        }
        .date-select {
          width: 131px;
          height: 40px;
          margin-left: 10px;
        }
        &-remove {
          color: $color-warning;
          border: 1px solid $color-warning;
          border-radius: $border-radius;
          background-color: $color-white;
          padding: 12px 25px;
          transition: all ease 0.3s;
          margin-left: 15px;
          opacity: 0;
          visibility: hidden;

          &:hover {
            background-color: lighten($color-warning, 30%);;
          }
        }
      }
      &:hover {
        .date-filter-body-row-columns-remove {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
</style>
