var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("pageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search",
            attrs: {
              componentName: "Stores",
              isSuggestionLoading: false,
              customSuggestionPlaceHolder: _vm.$t("Stores.search_Placeholder"),
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isStoresEmpty && !_vm.isLoading,
                  expression: "!isStoresEmpty && !isLoading",
                },
              ],
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-store-add",
                iconName: "icon-global-add",
                iconClass: "icon-global-add icon-left",
              },
              on: { click: _vm.itemNew },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Stores.addStoreButton")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            attrs: {
              id: "stock-reports-recipe-cost-calculation-filter",
              items: _vm.storeTypeList,
              selected: _vm.selectedTypeList,
              label: _vm.$t("Stores.form_StoreType"),
              popupHeader: _vm.$t("Stores.filter_StoreTypeFilter"),
              showAll: false,
              buttonText: _vm.$t("Stores.filter_SubmitFilter"),
              type: "radio",
              buttonIconLeft: "btn-success",
              updateDropDownLabelFromParent: _vm.updateDropDownLabelFromParent,
            },
            on: {
              delegateCheckItem: _vm.onChangeStoreTypeFilter,
              delegateCheckAll: _vm.onChangeStoreTypeFilter,
              delegateSubmitItems: _vm.getFilteredStoreType,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ViewType", {
            staticClass: "view-type",
            attrs: {
              firstNodeIcon: "icon-view-type-list",
              secondNodeIcon: "icon-view-type-group",
              firstSelected: _vm.viewTypeList,
            },
            on: {
              firstNodeClick: function ($event) {
                return _vm.listViewType(true)
              },
              secondNodeClick: function ($event) {
                return _vm.listViewType(false)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.isStoresEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Stores.emptyState_Title"),
                  description: _vm.$t("Stores.emptyState_Description"),
                  buttonText: _vm.$t("Stores.emptyState_Button"),
                },
                on: { emptyAddButton: _vm.itemNew },
              })
            : _vm._e(),
          !_vm.isStoresEmpty && !_vm.isLoading && _vm.viewTypeList
            ? _c("TableView", {
                attrs: {
                  fields: _vm.fields,
                  page: _vm.Page,
                  actions: ["edit", "remove"],
                  data: _vm.storeListItem,
                  componentName: "StoresTable",
                },
                on: {
                  delegateOnItemShow: _vm.showStore,
                  delegateOnItemEdit: _vm.editStore,
                  delegateOnItemDelete: _vm.deleteStore,
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "typeName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {
                      staticClass: "store-type",
                      class: _vm.getNameOfType(row.type, 2),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.getNameOfType(row.type, 1))),
                      ]),
                    ]
                  )
                },
              },
            ]),
          }),
          _vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "hasTransit" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c("div", { staticClass: "has-transit" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getHasTransit(row.hasTransit))),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  3994123913
                ),
              })
            : _vm._e(),
          !_vm.isStoresEmpty && !_vm.isLoading && !_vm.viewTypeList
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.fields,
                  actions: ["edit", "remove"],
                  page: _vm.Page,
                  data: _vm.storeListItem,
                  componentName: "StoresBox",
                  extraClass: { forContent: "m-auto box-flex-row" },
                },
                on: {
                  delegateOnItemShow: _vm.showStore,
                  delegateOnItemEdit: _vm.editStore,
                  delegateOnItemRemove: _vm.deleteStore,
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
          _c("router-view", { on: { getList: _vm.getStoreListItems } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }