<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "PageLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown" v-if="hasTabPermission")
    CustomDatepicker#ProductionDatepicker(
      :isLoading="$wait.is('getDateFilterSettings')"
      v-model="dates",
      :range="true"
      :disabled="isFetchingData"
    )
  template(slot="header-right-content" v-if="hasTabPermission")
    Button(
      primary
      size="medium"
      iconName="icon-global-add"
      @click="addRawMaterialPlan"
    )
      span {{ $t('Global.addData', { field: $t('Dictionary.rawMaterialPlanningTitle') }) }}
  template(slot="toolbar-left")
    storeFilterComponent(
    @submitFilter="getListWithStoreFilter"
    ref="rawMaterialStoreFilter")

    .show-drafts.m-left-15
      CustomCheckbox.popup-item-check(
      id="checkbox-raw-material-planning-show-drafts"
      :checked="showDrafts"
      :label="$t('Global.showDrafts')"
      @change="changeShowDraftsStatus")
  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    .RawMaterialPlanning(v-else)

      TableLoading(v-if="isFetchingData")

      EmptyState(
        v-if="!isFetchingData && isEmptyRawMaterialList",
        :title="$t('Global.emptyState_Title', { field: $t('Dictionary.rawMaterialPlanningTitle') })",
        :description="$t('Global.emptyState_Description', { field: $t('Dictionary.rawMaterialPlanningTitle') })",
        :buttonText="$t('Global.addData', { field: $t('Dictionary.rawMaterialPlanningTitle') })",
        @emptyAddButton="addRawMaterialPlan")

      div(v-if="!isFetchingData && !isEmptyRawMaterialList")
        NestedTable(
          :data="RawMaterialHeaderList"
          :fields="rawMaterialHeaderFields",
          :detailFields="rawMaterialDetailFields",
          :isDetailLoading="isDetailFetching",
          :finalizeButtonText="$t('Global.Save')",
          :actions="['edit', 'delete', 'export']",
          :detailActions="['edit']"
          :readOnlyForDetail="true"
          :usePortalAction="true"
          :usePortalFooter="true"
          @editHeaderAction="editHeaderRawMaterialPlanning"
          @deleteHeaderAction="removeHeader",
          @detailChangeAction="updateQuantity"
          @showDetailAction="showDetailAction",
          @showExportPopup="showExportPopup")

        portal(to="description")
          div(slot-scope="header")
            Button.show-MiniPopup.relative.width-max-content(
              size="medium"
              variant="info"
              v-if="header.row.information",
              :id="`raw-material-planning-header-info-button-${header.rowIndex}`"
              iconName="icon-global-info",
              :justIcon="true",
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ header.row.information }}

        portal(to="requestType")
          div(slot-scope="detail")
            customSelectInput.custom-select(
              name="requestTypes",
              :optionData="requestTypes"
              optionKey="value"
              optionIdKey="value"
              v-model="detail.row.requestType",
              :isValueNumber="true"
              id="raw-material-request-store-types"
              :extraDataEmit="detail"
              :isChangeValue="false"
              :disabled="detail.row.status === 2"
              :selectedDataRequired="true"
              :hideDefault="true"
              @change="updateRawMaterial"
              optionIdName="raw-material-request-store-types"
            )

        portal(to="requestedStoreId")
          div(slot-scope="detail")
            customSelectInput.custom-select-store(
              name="requestedStoreId",
              :optionData="storeList"
              v-model="detail.row.requestedStoreId",
              :disabled="detail.row.requestType !== 2 || detail.row.status === 2"
              id="raw-material-request-store-list"
              :extraDataEmit="detail"
              :isChangeValue="false"
              :selectedDataRequired="true"
              @change="updateRequestedStore"
              optionIdName="raw-material-request-store-list"
            )

        portal(to="quantity")
          div(slot-scope="detail")
            custom-number-input.custom-select.m-bottom-15(
              id="raw--material-detail-form-quantity"
              name="quantity"
              :data-vv-as="$t('Dictionary.Quantity')"
              v-model="detail.row.quantity"
              v-validate="{decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
              :extraDataEmit="detail"
              :isChangeValue="false"
              :disabled="detail.row.status === 2"
              @blur="updateRawMaterial"
              :error="veeErrors.has('quantity')"
            )

        portal(to="actionPortal")
          div.popup-container.flexbox(slot-scope="rows")
            ActionButton(
              :data="!rows.rowData.isRawMaterial ? ['edit', 'remove'] : ['remove'] "
              v-if="rows.rowData.status === 1 || rows.rowData.status === 4"
              :item="rows"
              @onItemDelete="removeData(rows.row, rows.rowData.isRawMaterial, rows.rowData.id)"
              @onItemEdit="editDetailItem(rows.row)"
            )

        portal(to="footerPortal")
          div.popup-container.flexbox(slot-scope="rowData")
            Button.m-right-20(
              v-if="!rowData.isRawMaterial && rowData.status !== 2"
              id="nested-detail-add--new-raw-material-detail",
              inline,
              primary,
              size="small"
              iconName="icon-global-add",
              iconClass="icon-global-add",
              :disabled="isDetailFetching || isOnlyListUsage"
              :isLoading="isDetailFetching"
              @click="addNewDetail(rowData)")
                span {{ $t('Global.addProductionItem') }}
            Button.m-right-20(
              v-if="rowData.isRawMaterial"
              id="nested-detail-planning-change",
              inline,
              primary,
              size="small"
              iconName="icon-arrow-left",
              :disabled="isDetailFetching"
              :isLoading="isDetailFetching"
              @click="changeToPlanningList(rowData)")
                span {{ $t('ProductionPlaning.returnToPlanning') }}

            Button.m-right-20(
              v-if="rowData.isRawMaterial && rowData.detailList.length > 0 && rowData.status !== 2"
              id="nested-detail-planning-change",
              inline,
              primary,
              size="small"
              :disabled="isSavingData"
              :isLoading="isSavingData"
              @click="saveAndCreate(rowData)")
                span {{ $t('Dictionary.saveAnd') }} {{$t('Dictionary.createRequest') }}

            Button.m-right-20(
              v-if="!rowData.isRawMaterial && rowData.detailList.length > 0"
              id="nested-detail-raw-material-change",
              inline,
              primary,
              size="small"
              :disabled="isDetailFetching"
              :isLoading="isDetailFetching"
              @click="changeToRawMaterialList(rowData)")
                span {{ $t('ProductionPlaning.showRawMaterials') }}

            InfoAlert(
              v-if="rowData.isRawMaterial && zeroQuantityList.length>0"
              :description="$t('ProductionPlaning.askForDeletingProductionItems')"
              :isProductItemWarning="true"
              :popupData="zeroQuantityList"
              :closePopupText="$t('ProductionPlaning.returnToPlanning')"
              @closePopupAction="changeToPlanningList(rowData)"
              @deleteAction="deleteZeroProductItems(rowData)"
            )
            InfoAlert(
              v-if="showZeroStockItemList"
              :description="$t('ProductionPlaning.askForDeletingStockItems')"
              :isStockItemWarning="true"
              :popupData="zeroQuantityStockItemList"
              :closePopupText="$t('ProductionPlaning.returnToPlanning')"
              @closePopupAction="changeToRawMaterialList(rowData)"
              @deleteAction="deleteZeroStockItems(rowData)"
            )

        TableFoot(
          v-if="!isFetchingData"
          :page="Page",
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize")

    router-view(
      @getRawMaterialList = 'getRawMaterialList'
      @showDrafts="showDrafts = true"
    )

    InfoAlert(
      v-if="isFinalize"
      :popupData="resData",
      @closePopupAction="closePopup"
    )

    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )

</template>

<script>
import { getRawMaterialPlanningHeaderFields, getRawMaterialPlanningDetailFields } from './fields/raw-material-planning.js'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import moment from 'moment'
import InfoAlert from './components/info-alert'
import TableFoot from '@/view/global/table/foot'
import storeFilterComponent from '@/view/global/business-filters/store'
import exportPopup from '@/view/global/export-popup'
import settingsExportPopup from '@/mixins/settingsExportPopup'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import CustomSelect from '@/view/global/custom-select'  

export default {
  name: 'RawMaterialPlanning',

  mixins: [settingsExportPopup, hasTabPermission],

  data () {
    return {
      rawMaterialHeaderFields: getRawMaterialPlanningHeaderFields(this.$t),
      rawMaterialDetailFields: getRawMaterialPlanningDetailFields(this.$t),
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ],
      storeFilter: [],
      requestTypes: [
        {
          name: this.$t('Global.Multiselect_Unselected_Text'),
          value: 0
        },
        {
          name: this.$t('OnHand.requestType_order'),
          value: 1
        },
        {
          name: this.$t('OnHand.requestType_transfer'),
          value: 2
        }
      ],
      isFinalize: false,
      resData: {},
      zeroQuantityList: {},
      zeroQuantityStockItemList: {},
      showZeroStockItemList: false,
      isOnlyListUsage: false,
      showDrafts: true
    }
  },

  components: {
    TableFoot,
    storeFilterComponent,
    exportPopup,
    InfoAlert,
    NoPermission,
    CustomSelect
  },

  computed: {
    ...mapGetters('Production', [
      'PageLinks'
    ]),

    ...mapGetters('RawMaterialPlanning', [
      'Page',
      'RawMaterialHeaderList',
      'isEmptyRawMaterialList'
    ]),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList'
    }),

    ...mapGetters('Settings', ['permissions']),

    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    isDetailFetching () {
      return this.$wait.is(['fetchRawMaterialPlanningDetailList', 'fetchRawMaterialDetailList'])
    },

    isFetchingData () {
      return this.$wait.is('fetchRawMaterialPlanningHeaderList')
    },

    isSavingData () {
      return this.$wait.is('saveAndCreate')
    }
  },

  methods: {
    ...mapActions('RawMaterialPlanning', [
      'fetchRawMaterialPlanningHeaderList',
      'fetchRawMaterialPlanningDetailList',
      'fetchRawMaterialDetailList',
      'finalizeRawMaterialPlanning',
      'updateRawMaterialDetail',
      'updateRawMaterialPlanningDetail',
      'deleteZeroQuantityProductItems',
      'deleteZeroQuantityStockItems'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('RawMaterialPlanning', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'SHOW_DETAIL_RAW_LIST',
      'HIDE_OTHERS_DETAILS',
      'UPDATE_RAW_DETAIL_LIST'
    ]),

    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('DocumentSettings', ['getDocumentFilterSettings']),

    closePopup () {
      this.isFinalize = false
      this.getRawMaterialList()
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_RawMaterialPlanning') this.showDrafts = i.isChecked
      })
    },

    addRawMaterialPlan () {
      this.$router.push({
        name: 'NewRawMaterialPlanningHeader'
      })
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getRawMaterialList()
    },

    saveAndCreate: vueWaitLoader(async function (item) {
      const payload = {
        id: item.id
      }
      await this.finalizeRawMaterialPlanning(payload).then(res => {
        if (res) {
          this.resData = res.data.requestHeaderList || {}
          this.zeroQuantityStockItemList = res.data.rawMaterialDetailZeroQuantityList
          if (this.zeroQuantityStockItemList.length > 0) {
            this.showZeroStockItemList = true
            this.isFinalize = false
          } else this.isFinalize = true
        }
      })
    }, 'saveAndCreate'),

    updateQuantity (item) {
      const payload = {
        id: item.id,
        quantity: item.quantity
      }
      this.updateRawMaterialPlanningDetail(payload)
    },

    async getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.storeFilter = list
        await this.getRawMaterialList()
      }
    },

    async changePage (page) {
      await this.getRawMaterialList(page)
    },

    async changePageSize (pageSize) {
      await this.getRawMaterialList(1, pageSize)
    },

    async getRawMaterialList (pageNumber = 1, pageSize = this.Page.size) {
      const params = {
        storeIds: this.storeFilter.map(_ => _.id),
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        pageSize,
        pageNumber,
        showDrafts: this.showDrafts
      }
      await this.fetchRawMaterialPlanningHeaderList(params)
    },

    async showDetailAction (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
      } else {
        if (item.isRawMaterial) {
          this.changeToRawMaterialList(item)
        } else {
          this.changeToPlanningList(item)
        }
      }
    },

    async changeToPlanningList (item) {
      const res = await this.fetchRawMaterialPlanningDetailList(item.id)
      this.SHOW_DETAIL({
        RawMaterialPlaningHeader: item,
        RawMaterialPlanningDetailList: res.data.rawMaterialPlanningDetailList
      })
    },

    async changeToRawMaterialList (item) {
      this.showZeroStockItemList = false
      const res = await this.fetchRawMaterialDetailList(item.id)
      this.zeroQuantityList = res.data.rawMaterialDetailZeroQuantityList
      await this.SHOW_DETAIL_RAW_LIST({
        RawMaterialPlaningHeader: item,
        RawMaterialPlanningDetailList: res.data.rawMaterialDetailList
      })
    },

    async deleteZeroProductItems (item) {
      const res = await this.deleteZeroQuantityProductItems(item.id)
      const message = this.$t('Global.successRemoveMessage', { field: this.$t('Dictionary.ProductItem') })
      if (res) {
        await this.notifyShow({ message })
        this.changeToRawMaterialList(item)
      }
    },

    async deleteZeroStockItems (item) {
      const res = await this.deleteZeroQuantityStockItems(item.id)
      const message = this.$t('Global.successRemoveMessage', { field: this.$t('Dictionary.StockItem') })
      if (res) {
        await this.notifyShow({ message })
        this.changeToRawMaterialList(item)
      }
    },

    editHeaderRawMaterialPlanning (item) {
      this.$router.push({
        params: {
          id: item.id,
          item
        },
        name: 'EditRawMaterialPlanning'
      })
    },

    removeHeader (item) {
      this.$router.push({
        name: 'RemoveRawMaterialPlanningHeader',
        params: {
          id: item.id,
          item
        }
      })
    },

    addNewDetail (item) {
      this.$router.push({
        params: {
          headerId: item.id,
          header: {
            ...item
          }
        },
        name: 'NewRawMaterialPlanningDetail'
      })
    },

    editDetailItem (item) {
      const params = {
        header: {
          id: item.rawMaterialPlanningHeaderId,
          code: item.headerCode,
          storeName: item.storeName,
          storeId: item.storeId,
          planningDate: item.planningDate
        },
        detailId: item.id
      }
      this.$router.push({
        name: 'EditRawMaterialPlanningDetail',
        params: {
          ...params
        }
      })
    },

    removeData (item, isRawMaterial, headerId) {
      isRawMaterial ? this.removeRawMaterial(item, headerId) : this.removeDetail(item, headerId)
    },

    removeDetail (item) {
      this.$router.push({
        params: {
          id: item.rawMaterialPlanningHeaderId,
          detailId: item.id
        },
        name: 'RemoveRawMaterialPlanningDetail'
      })
    },

    removeRawMaterial (item, headerId) {
      this.$router.push({
        params: {
          detailData: { ...item },
          headerId
        },
        name: 'RemoveRawMaterialDetail'
      })
    },

    updateRequestedStore (item) {
      this.updateRawMaterial(item, 'updateStore')
    },

    updateRawMaterial (item, type = null) {
      const detailData = item.extraDataEmit.row
      const changeData = item.selectedItem || item.inputData
      let requestedStoreId = null
      if (type  === 'updateStore') requestedStoreId = item.selectedItem.id
      if (item.selectedItem && item.selectedItem.value === 2) {
        changeData.id ? requestedStoreId = changeData.id : requestedStoreId = detailData.requestedStoreId
      }
      const payload = {
        rawMaterialPlanningHeaderId: detailData.rawMaterialPlanningHeaderId,
        requestType: changeData.value || detailData.requestType,
        stockItemId: detailData.stockItemId,
        quantity: typeof changeData === 'number' ? changeData : detailData.quantity,
        requestedStoreId: requestedStoreId
      }
      this.UPDATE_RAW_DETAIL_LIST(payload)
      if (item.selectedItem && item.selectedItem.value === 2 && !item.extraDataEmit.row.requestedStoreId) return
      this.updateRawMaterialDetail(payload).then(res => {
        if (res) {
          const message = this.$t('ProductionPlaning.RawMaterial_Updated')
          this.notifyShow({ message })
        } 
      })
    },

    showExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 13, isFinalizeAction)
    }
  },

  async mounted () {
    if (!this.hasTabPermission) return
    await this.setShowDraftStatus()
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_RawMaterialPlanning')
    await this.getRawMaterialList()
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
  },

  watch: {
    dates () {
      this.getRawMaterialList()
    }
  }
}
</script>

<style lang="scss" scoped>

.custom-select {
  height: 40px;
  min-height: 40px;
}
:deep() .NestedDetail-row-col.actions{
  display: contents !important;
}
:deep() .custom-select-store .select-dropdown-custom-selected {
  width: unset !important;
}
</style>
