<template lang="pug">
Page
    template(slot="header-left-dropdown")
      PageTabButtons(
        :selectedList = "pageHeaderLinks"
        translationGroup="TabItems"
      )
    template(slot="header-right-content")

      Button(
      size="medium"
      primary
      id="btn-recipes-new"
      type="button"
      v-if="!isEmptyWasteGroups"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="addNewWasterGroup")
        span {{ $t('WasteGroup.addWasteGroup') }}

      // PAGE CONTENT /////////////////////////////


    template(slot="toolbar-left")
      storeGroup.m-right-10(
        :isPermitted = "true"
        ref="storeGroupFilter"
        @submitFilter="submitFilteredStoreGroup"
      )

    template(slot="content-center")
      .WasteGroupPageContent
        // LOADING

        TableLoading(v-if="isLoading")

          // empty state

        EmptyState(
        :title="$t('WasteGroup.emptyTitle')",
        :description="$t('WasteGroup.empty_description')",
        :buttonText="$t('WasteGroup.addWasteGroup')",
        v-if="isEmptyWasteGroups && !isLoading"
        @emptyAddButton="addNewWasterGroup")

          // LIST

        .Boxlist(v-if="!isEmptyWasteGroups && !isLoading")
          .Boxlist-item(
          v-for="(list, index) in WasteGroupList")
            .Boxlist-actions
              ActionButton(
                :data="['edit', 'remove']"
                :item="list"
                @onItemEdit="editWasteGroup(list)"
                @onItemDelete="deleteWasteGroup(list)"
              )
            .Boxlist-body.tooltip-relative
              h3.title(
                :id="`box-list-waste-groups-title-${index}`"
              ) {{ getName(list.name) }}
              Tooltip(
                v-if="list.name.length > 30"
                white
                bottom
                :text="list.name"
              )
        router-view(
          @getList = "submitFilter"
        )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//
import storeGroup from '@/view/global/business-filters/storeGroup'

export default {
  name: 'WasteGroups',
  components: {
    storeGroup
  },

  computed: {
    ...mapGetters('Waste', [
      'WasteGroupList',
      'isEmptyWasteGroups',
      'pageHeaderLinks'
    ]),

    isLoading () {
      return this.$wait.is(['Get_Waste_Group_All'])
    }
  },

  async mounted () {
    await this.submitFilter()
  },

  data () {
    return {
      storeGroupList: []
    }
  },

  methods: {
    ...mapActions('Waste', ['getWasteGroupAll']),
    addNewWasterGroup () {
      this.$router.push({
        name: 'NewWasterGroup'
      })
    },
    editWasteGroup (list) {
      this.$router.push({
        name: 'EditWasterGroup',
        params: {
          id: list.id
        }
      })
    },

    deleteWasteGroup (list) {
      this.$router.push({
        name: 'DeleteWasterGroup',
        params: {
          id: list.id
        }
      })
    },

    submitFilter () {
      const payload = {
        pageSize: 999999999,
        storeGroupList: this.storeGroupList.map(storeGroup => storeGroup.id)
      }
      this.getWasteGroupAll(payload)
    },
    submitFilteredStoreGroup ({list, dontSubmit}) {
      this.storeGroupList = list
      if (!dontSubmit) this.submitFilter()
    },
    getName (item) {
      return item?.length > 30 ? item.substr(0, 30).concat('...') : item
    }

  }
}
</script>


<style lang="scss" scoped>
:deep(.Boxlist-body){
  margin-right: 30px !important;
}
</style>
