var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.PageLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                id: "nutritional-values-create-new",
              },
              on: { click: _vm.createCustomNutritionFood },
            },
            [_vm._v(_vm._s(_vm.$t("NutritionalValues.createNewFood")))]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList ? _c("TableLoading") : _vm._e(),
          !_vm.isFetchingList
            ? _c("TableView", {
                attrs: {
                  page: _vm.Page,
                  data: _vm.NutritionFoodValues,
                  fields: _vm.fields,
                  usePortalAction: true,
                  pointer: true,
                  readOnly: true,
                  componentName: "NutritionTable",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  sort: _vm.sort,
                  submitFilter: _vm.submitFilter,
                  clearFilter: _vm.clearFilter,
                  changePopupFilterSearch: (search) => _vm.getOptions(search),
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "foodGroupName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.getNutritionDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.foodGroupName))]
                    ),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "nutritionFoodName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.getNutritionDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.nutritionFoodName))]
                    ),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "stockItemList" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("custom-search", {
                        staticClass: "form-item-select m-top-10",
                        attrs: {
                          "component-name": "stockItem-list",
                          name: "stockItemList",
                          label: "name",
                          searchAll: true,
                          placeholder: _vm.$t(
                            "NutritionalValues.search_stockItem"
                          ),
                          "on-search": _vm.getStockListOptions,
                          customEmit: row,
                          options: _vm.selectOptionsStockList,
                          isCustomSituation: true,
                        },
                        on: {
                          input: _vm.selectStockItem,
                          afterSelected: function ($event) {
                            return _vm.actionAfterSelectStockItem(row)
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "selected-items m-top-10 m-bottom-10" },
                        [
                          _vm.isMatchingLoading ? _c("Loading") : _vm._e(),
                          _vm._l(row.stockItemList, function (item, index) {
                            return _c(
                              "Button",
                              {
                                key: index,
                                attrs: {
                                  seashell: "",
                                  size: "xsmall",
                                  id: `selected-despatch-list-item-${index}`,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.removeStockItem({ item, row })
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                                _c("Icon", {
                                  staticClass: "m-left-5",
                                  attrs: {
                                    name: "icon-popup-close",
                                    id: `selected-despatch-list-item-delete-${index}`,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "actionPortal" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    { staticClass: "popup-container flexbox-custom" },
                    [
                      _c("ActionButton", {
                        attrs: {
                          id: "request-order-list-action-button",
                          data: row.isCustomFood
                            ? ["edit", "remove", "copy"]
                            : ["copy"],
                          item: row,
                        },
                        on: {
                          onItemDelete: function ($event) {
                            return _vm.deleteCustomNutrition(row)
                          },
                          onItemEdit: function ($event) {
                            return _vm.editCustomNutrition(row)
                          },
                          onItemCopy: function ($event) {
                            return _vm.copyNutrition(row)
                          },
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("router-view"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }