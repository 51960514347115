<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList="pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    .form-item-select.date-start
      CustomDatepicker#ForecastSalesDatepicker(
        v-model="dates",
        :range="true"
        :disabled="isPageLoading",
        :isLoading="isGettingDateFilterSettings")

  template(slot="toolbar-left")
    .toolbar-filters__item
      CustomMultiselect.toolbar-filters__item(
        id="reports-income-stats-storegroups-filter"
        :items="storeGroupList"
        :selected="storeGroupFilter"
        :label="$t('StoreGroups.popupForm_Name')"
        :popupHeader="$t('StoreGroups.storeGroupFilterLabel')"
        :unselectedText="$t('IncomeStats.toolbar_Stores_Unselected')"
        :allLabel="$t('IncomeStats.toolbar_Stores_AllLabel')"
        :buttonText="$t('IncomeStats.toolbar_Stores_ButtonText')"
        buttonIconLeft="btn-success"
        @delegateCheckAll="onChangeStoreGroupFilter"
        @delegateCheckItem="onChangeStoreGroupFilter"
        @delegateSubmitItems="getForecasts")

    .toolbar-filters__item
      RVCFilter(@submitFilter="updateRVCFilterItems")
  template(slot="toolbar-right")
    ImportExport(
      componentName="reports-forecast-sales"
      ref="wrapper"
      :isDisabled="isPageLoading"
      :activeImportExports=['export']
      downloadUrl="IncomeStats/Forecast/export"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="downloadFileName"
    )
  template(slot="content-center")
    .IncomeStatsReport
      .results
        TableLoading(v-if="isPageLoading")

        EmptyState(
        v-if="!ForecastSales.length"
        :title="$t('IncomeStats.forecastEmptyState_Title')"
        :description="$t('IncomeStats.emptyState_Description')")

        TableView(
          v-if="ForecastSales.length"
          :page="ForecastPage"
          :fields="fields"
          :componentName="'ReportsForecastTable'"
          :data="ForecastSales"
          :read-only="true",
          :useInfiniteScroll="true"
          :isLoadingInfiniteScrollRequest="isPageLoading"
          @delegateOnChangePage="page => getForecasts"
          class="reports-forecast-table"
        )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import getFields from './fields/forecast-sales'
import ImportExport from '@/view/global/import-export'
import RVCFilter from '@/view/global/business-filters/rvc'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import totalCounts from '@/view/global/total-counts'
import getDateFilterSetting from '@/utils/getDateFilterSetting'

export default {
  name: 'IncomeStats',

  components: {
    ImportExport,
    ToggleOverGroups,
    RVCFilter,
    totalCounts
  },

  data () {
    return {
      pageHeaderLinks: [
        'SalesReports',
        'IncomeStats',
        'ForecastSales'
      ],
      fields: getFields(this.$t),
      dates: [],
      RVCFilter: [],
      storeGroupFilter: [],
      isDataChecked: false
    }
  },

  async mounted () {
    if (this.$route.params.startDate && this.$route.params.endDate) {
      this.dates = [this.$route.params.startDate, this.$route.params.endDate]
    } else {
      await this.getDateFilterSettings()
      this.dates = getDateFilterSetting('Date_IncomeStatsReport')
    }
    this.getStoreGroupList({pageNumber: 1, pageSize: 9999}).then(() => {
      this.storeGroupFilter = [...this.storeGroupList]
    })
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getForecasts()
    }
  },

  computed: {
    ...mapGetters('Reports', [
      'ForecastPage',
      'ForecastSales'
    ]),

    ...mapGetters('StoreGroups', [
      'storeGroupList'
    ]),

    isGettingDateFilterSettings () {
      return this.$wait.is('getDateFilterSettings')
    },

    isPageLoading () {
      return this.$wait.is('getForecastSales')
    },

    downloadParams () {
      return {
        pageNumber: this.ForecastPage.number,
        pageSize: this.ForecastPage.size,
        startDate: this.dates[0],
        endDate: this.dates[1],
        revenueCenterList: this.RVCFilter.map(item => item.id),
        storeGroupList: this.storeGroupFilter.map(item => item.id)
      }
    },
    downloadFileName () {
      const fileName = `ForecastSalesReport_${this.dateFormat(this.dates[0])}_${this.dateFormat(this.dates[1])}`
      return fileName
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Reports', [
      'getForecastSales'
    ]),

    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),

    getForecasts() {
      const payload = {
        pageSize: this.ForecastPage.size,
        pageNumber: this.ForecastPage.number,
        startDate: this.dateFormat(this.dates[0]),
        endDate: this.dateFormat(this.dates[1]),
        revenueCenterList: this.RVCFilter.map(i => i.id),
        storeGroupList: this.storeGroupFilter.map(i => i.id)
      }
      this.getForecastSales(payload)
    },

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    updateRVCFilterItems ({ list, dontSubmit }) {
      this.RVCFilter = list
      if (!dontSubmit) this.getForecasts()
    },

    onChangeStoreGroupFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.storeGroupFilter = [...this.storeGroupList]
        } else {
          this.storeGroupFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.storeGroupFilter = []
        } else {
          this.storeGroupFilter.splice(this.storeGroupFilter.indexOf(event.item), 1)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .download-csv {
    margin-right: 10px;
  }
  .results {
    position: relative;
  }

</style>
