<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "PageLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-content" v-if="hasTabPermission")
    Button(
    primary,
    size="medium"
    iconName="icon-global-add",
    v-if="!isEmptyPlaningList",
    @click="addNewProductionPlaning"
    id="add-production-planing-header-button")
      span {{ $t('Global.addData', { field: $t('ProductionPlaning.ProductionPlaning') }) }}

  template(slot="toolbar-left")
    CustomCheckbox.popup-item-check(
      id="checkbox-production-planning-show-drafts"
      :checked="showDrafts"
      :label="$t('Global.showDrafts')"
      @change="changeShowDraftsStatus"
    )
  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    .ProductionPlaning(v-else)

      // LOADING

      TableLoading(v-if="isFetchingData")

      div(v-else)

        // EMPTY PAGE

        EmptyState(
        v-if="isEmptyPlaningList",
        :title="$t('Global.emptyState_Title', { field: $t('ProductionPlaning.ProductionPlaning') })",
        :description="$t('Global.emptyState_Description', { field: $t('ProductionPlaning.ProductionPlaning') })",
        :buttonText="$t('Global.addData', { field: $t('ProductionPlaning.ProductionPlaning') })",
        @emptyAddButton="addNewProductionPlaning")

        NestedTable(
          v-else,
          :fields="productionPlanningHeaderFields",
          :detailFields="productionPlanningDetailFields",
          :data="ProductionPlaning",
          :addButtonText="$t('Global.addProductionItem')",
          :finalizeButtonText="$t('Global.Save')",
          :actions="['edit', 'delete', 'export']",
          :detailActions="['edit','delete']",
          @editHeaderAction="editHeaderProductionPlanning"
          :isDetailLoading="isDetailFetching",
          :disableNewDetailButton="isOnlyListUsage"
          @addDetailAction="addNewDetail",
          @editDetailAction="editDetailItem",
          @showDetailAction="showDetailAction",
          @detailChangeAction="updateDetailItem",
          @deleteHeaderAction="removeHeader",
          @deleteDetailAction="removeDetail",
          :page="Page",
          @delegateOnChangePage="changePage",
          @delegateOnChangePageSize="changePageSize",
          @showExportPopup="showExportPopup"
          @finalizeAction="productionPlanningSaved")

      portal(to="description")
        div(slot-scope="header")
          Button.show-MiniPopup.relative.width-max-content(
            variant="info"
            size="small"
            v-if="header.row.description",
            :id="`production-planning-header-info-button-${header.rowIndex}`"
            iconName="icon-global-info",
            :justIcon="true",
          )
            .MiniPopup-right-primary.m-top-15.c-dark
              span {{ header.row.description }}

    router-view(
      @getProductionPlaningList="getProductionPlaningList"
      @showDrafts="showDrafts = true"
    )

    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getProductionPlanningHeaderFields, getProductionPlanningDetailFields } from '@/view/pages/Production/fields/production-planning.js'
import { vueWaitLoader } from '@/utils/baseOperations'

//
import exportPopup from '@/view/global/export-popup'
import settingsExportPopup from '@/mixins/settingsExportPopup'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'

export default {
  name: 'ProductionPlaning',

  components: {
    exportPopup,
    NoPermission
  },

  mixins: [settingsExportPopup, hasTabPermission],

  data () {
    return {
      productionPlanningHeaderFields: getProductionPlanningHeaderFields(this.$t),
      productionPlanningDetailFields: getProductionPlanningDetailFields(this.$t),
      isOnlyListUsage: false,
      showDrafts: true
    }
  },

  computed: {

    ...mapGetters('Production', [
      'PageLinks'
    ]),
    ...mapGetters('Settings', [
      'permissions'
    ]),
    ...mapGetters('ProductionPlaning', [
      'ProductionPlaning',
      'isEmptyPlaningList',
      'Page'
    ]),
    ...mapGetters('DocumentSettings', ['DocumentSettingList']),
    isDetailFetching () {
      return this.$wait.is('fetchProductPlanningDetail')
    },
    isFetchingData () {
      return this.$wait.is('fetchProductionPlanningHeader')
    }
  },

  /**
  *
  **/

  methods: {

    ...mapMutations('ProductionPlaning', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'HIDE_OTHERS_DETAILS'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('ProductionPlaning', [
      'fetchProductionPlanningHeader',
      'fetchProductPlanningDetail',
      'updateProductionPlanningDetail',
      'makeFinalizeProductionPlanning'
    ]),

    ...mapActions('DocumentSettings', [
      'getDocumentFilterSettings'
    ]),

    addNewProductionPlaning () {
      this.$router.push({
        name: 'NewProductionPlaning'
      })
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getProductionPlaningList()
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_ProductionPlanning') this.showDrafts = i.isChecked
      })
    },

    editHeaderProductionPlanning (item) {
      this.$router.push({
        params: {
          id: item.id,
          item
        },
        name: 'EditProductionPlaning'
      })
    },

    changePage (page) {
      this.getProductionPlaningList(page)
    },

    changePageSize (pageSize) {
      this.getProductionPlaningList(1, pageSize)
    },

    async getProductionPlaningList (page = 1, pageSize = this.Page.size) {
      const payload = {
        page,
        pageSize,
        showDrafts: this.showDrafts
      }
      const res = await this.fetchProductionPlanningHeader(payload)
      return res
    },

    async showDetailAction (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
      } else {
        const res = await this.fetchProductPlanningDetail(item.id)
        this.SHOW_DETAIL({
          productionPlaningHeader: item,
          productionPlanningDetailList: res.data.productionPlanningDetailList
        })
      }
    },

    addNewDetail (item) {
      this.$router.push({
        params: {
          headerId: item.id,
          header: {
            ...item
          }
        },
        name: 'NewProductionPlaningDetail'
      })
    },

    editDetailItem (item) {
      const params = {
        header: {
          id: item.productionPlanningHeaderId,
          code: item.headerCode,
          storeName: item.storeName,
          storeId: item.storeId,
          planningDate: item.planningDate
        },
        id: item.id
      }
      this.$router.push({
        params: {
          ...params
        },
        name: 'EditProductionPlaningDetail'
      })
    },

    async updateDetailItem (item) {
      const payload = {
        quantity: item.plannedQuantity,
        id: item.id
      }

      const header = {
        id: item.productionPlanningHeaderId,
        code: item.headerCode,
        storeId: item.storeId,
        storeName: item.storeName,
        planningDate: item.planningDate
      }

      const res = await this.updateProductionPlanningDetail(payload)
      if (res) {
        const productionList = await this.fetchProductPlanningDetail(header.id)
        if (productionList) {
          this.SHOW_DETAIL({
            productionPlaningHeader: header,
            productionPlanningDetailList: productionList.data.productionPlanningDetailList
          })
        }
        const message = this.$t('Global.successUpdateMessage', { field: this.$t('Dictionary.ProductItem') })
        this.notifyShow({ message })
      }
    },

    productionPlanningSaved: vueWaitLoader(async function (item) {
      const res = await this.makeFinalizeProductionPlanning(item.id)
      if (res) {
        this.getProductionPlaningList()
        this.showExportPopup(item, true)
      }
    }, 'productionPlanningSaved'),

    removeHeader (item) {
      this.$router.push({
        params: {
          id: item.id,
          item
        },
        name: 'RemoveProductionPlaningHeader'
      })
    },

    removeDetail (item) {
      this.$router.push({
        params: {
          id: item.productionPlanningHeaderId,
          detailId: item.id
        },
        name: 'RemoveProductionPlaningDetail'
      })
    },

    showExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 12, isFinalizeAction, 'ProductionPlanning')
    }

  },

  async beforeMount () {
    if (!this.hasTabPermission) return
    await this.setShowDraftStatus()
    await this.getProductionPlaningList()
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
  }
}
</script>
