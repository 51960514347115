var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stock", attrs: { id: `stock` } }, [
    _c("div", { staticClass: "stock-top" }, [
      _c("div", { staticClass: "title" }, [
        _c(
          "h4",
          { staticClass: "supplier-title", attrs: { id: `stock-title` } },
          [_vm._v(_vm._s(_vm.$t("Dashboard.onHandTitle")))]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "stock-main" },
      [
        _vm._l(_vm.OnHand, function (stocks, index) {
          return _c(
            "div",
            {
              staticClass: "stock-amount",
              attrs: { id: `stock-amount-${index}` },
            },
            [
              _c("div", { staticClass: "amount-left" }, [
                _c(
                  "div",
                  { staticClass: "amount-icons" },
                  [
                    _c("Icon", {
                      attrs: { name: stocks.iconName || "simpra-logo-dark" },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    staticClass: "amount-text",
                    attrs: { id: `stock-amount-text-${index}` },
                  },
                  [_vm._v(_vm._s(stocks.overGroupName))]
                ),
              ]),
              _c("div", { staticClass: "amount-right" }, [
                _c(
                  "p",
                  {
                    staticClass: "amount-text",
                    attrs: { id: `stock-amount-currency-${index}` },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("formatCurrency2Digits")(stocks.amount))
                    ),
                  ]
                ),
              ]),
            ]
          )
        }),
        _c("div", { staticClass: "stock-amount" }, [
          _c("div", { staticClass: "amount-left" }, [
            _c(
              "div",
              { staticClass: "amount-icons simpra" },
              [_c("Icon", { attrs: { name: "simpra-logo-dark" } })],
              1
            ),
            _c(
              "p",
              {
                staticClass: "amount-text-bottom",
                attrs: { id: `stock-total-amount-text` },
              },
              [_vm._v(_vm._s(_vm.$t("Dashboard.onHandTotalTitle")))]
            ),
          ]),
          _c("div", { staticClass: "amount-right" }, [
            _c(
              "p",
              {
                staticClass: "amount-text-bottom-success",
                attrs: { id: `stock-total-amount` },
              },
              [
                _vm._v(
                  _vm._s(_vm._f("formatCurrency2Digits")(_vm.totalStockAmount))
                ),
              ]
            ),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }