var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-breadcrumb-left" }, [
        _c(
          "div",
          { staticClass: "Breadcrumb tooltip-relative" },
          [
            _c(
              "router-link",
              {
                staticClass: "c-light",
                attrs: {
                  id: "link-inventory-breadcrumb",
                  to: { name: "Inventories" },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Inventory.breadCrumb_Inventory")))]
            ),
            _c("span", [_vm._v(_vm._s(">"))]),
            _c(
              "a",
              {
                staticClass: "isSelected",
                attrs: { href: "", id: "link-inventory-breadcrumb-active" },
              },
              [_vm._v(_vm._s(_vm.inventoryBreadCrumb))]
            ),
            _vm.inventoryBreadCrumb.length > 50
              ? _c("Tooltip", {
                  attrs: {
                    white: "",
                    bottom: "",
                    text: _vm.inventoryBreadCrumb,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("span", { staticClass: "inventory-type c-light" }, [
          _vm._v(
            _vm._s(
              _vm.InventoryInfo.inventoryType == 1
                ? _vm.$t("Inventories.inventoryTypePeriodic")
                : _vm.$t("Inventories.inventoryTypeIntermediate")
            )
          ),
        ]),
      ]),
      _vm.inventoryHeader && _vm.inventoryHeader?.status === 1
        ? _c(
            "template",
            { slot: "header-left-search" },
            [
              _c("Search", {
                staticClass: "search-box",
                attrs: {
                  componentName: "Inventory",
                  isSuggestionLoading: false,
                },
                on: { onChangeSearchValue: _vm.onChangeSearch },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _vm.hasPermissionToCloseInventory
            ? _c(
                "Button",
                {
                  staticClass: "m-right-15",
                  attrs: {
                    size: "medium",
                    primary: "",
                    type: "button",
                    id: "btn-inventory-close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.close(_vm.inventoryHeader.id)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Inventory.closeInventoryButton"))),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                disabled: _vm.isOnlyListUsage,
                size: "medium",
                secondary: "",
                type: "button",
                id: "btn-add-stock-item",
              },
              on: { click: _vm.createBatch },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("Inventory.addStockItemButton"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          !_vm.isOnlyListUsage
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink",
                  attrs: {
                    id: "toggle-group-0",
                    to: {
                      name: "Inventory",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_all")))]
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              staticClass: "tabLink",
              attrs: {
                id: "toggle-group-1",
                to: {
                  name: "Inventorylists",
                  params: { id: _vm.$route.params.id },
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_list")))]
          ),
          !_vm.isOnlyListUsage
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink",
                  attrs: {
                    id: "toggle-group-2",
                    to: {
                      name: "InventoryRecipe",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_recipe")))]
              )
            : _vm._e(),
          !_vm.isOnlyListUsage
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink",
                  attrs: {
                    id: "toggle-group-3",
                    to: {
                      name: "InventoryShelf",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_shelf")))]
              )
            : _vm._e(),
          !_vm.isOnlyListUsage
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink isSelected",
                  attrs: {
                    id: "toggle-group-4",
                    to: {
                      name: "InventoryExpireDate",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_expire_date")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _vm.inventoryHeader && _vm.inventoryHeader.status === 1
            ? _c("ImportExport", {
                ref: "wrapper",
                attrs: {
                  componentName: "InventoryBatchList",
                  isDisabled: _vm.isFetchingList,
                  activeImportExports: ["export"],
                  downloadUrl: _vm.downloadUrl,
                  downloadFileType: "xlsx",
                  downloadParams: _vm.downloadParams,
                  downloadFileName: _vm.inventoryBreadCrumb + "batch-list",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList ? _c("TableLoading") : _vm._e(),
          !_vm.isFetchingList &&
          _vm.InventoryBatchList.length &&
          _vm.renderComponent
            ? _c("NestedTable", {
                attrs: {
                  data: _vm.InventoryBatchList,
                  fields: _vm.fields,
                  page: _vm.InventoryBatchPage,
                  detailFields: _vm.detailFields,
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  showDetailAction: _vm.showDetail,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "count" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c("div", {}, [
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            greater_than: 0,
                          },
                          expression:
                            "{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: detail.row.count,
                          expression: "detail.row.count",
                        },
                      ],
                      staticClass: "txt no-spinners quantity",
                      class: {
                        "is-danger": _vm.veeErrors.has(
                          `refund${detail.rowIndex}.count`
                        ),
                      },
                      attrs: {
                        name: "count",
                        "data-vv-as": _vm.$t("Inventory.count"),
                        type: "number",
                        step: "any",
                      },
                      domProps: { value: detail.row.count },
                      on: {
                        blur: function ($event) {
                          return _vm.updateActualQuantiy($event, detail.row)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(detail.row, "count", $event.target.value)
                        },
                      },
                    }),
                  ])
                },
              },
            ]),
          }),
          !_vm.InventoryBatchList.length
            ? _c("EmptyState", {
                staticClass: "emptyState",
                attrs: {
                  title: _vm.$t(
                    "Inventories.batchList_headerList_emptyState_NotFound_Title"
                  ),
                  description: _vm.$t(
                    "Inventories.detailList_Batch_emptyState_NotFound_Description"
                  ),
                },
              })
            : _vm._e(),
          _c("router-view", { on: { getList: _vm.getBatchList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }