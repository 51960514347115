var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-left-dropdown" }, [_c("div")]),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "server-side-reporting-content" },
          [
            _vm.loadIframe ? _c("TableLoading") : _vm._e(),
            _c("iframe", {
              ref: "reportIframe",
              staticClass: "server-side-reporting-tool",
              attrs: { id: "reportIframe" },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }