var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { staticClass: "orderIntegrationStatus" },
    [
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmptyList,
                  expression: "!isEmptyList",
                },
              ],
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-order-integration-status-new",
                type: "button",
              },
              on: { click: _vm.addItem },
            },
            [
              _c("Icon", { attrs: { name: "icon-global-add" } }),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("OrderIntegrationStatus.emptyStateNewButton"))
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.isEmptyList && !_vm.isFetchingList
            ? _c(
                "div",
                { staticClass: "Boxlist" },
                _vm._l(_vm.OrderIntegrationStatusList, function (status) {
                  return _c("div", { staticClass: "Boxlist-item" }, [
                    _c(
                      "div",
                      { staticClass: "Boxlist-actions" },
                      [
                        _c("ActionButton", {
                          attrs: { data: ["edit", "remove"], item: status },
                          on: {
                            onItemEdit: function ($event) {
                              return _vm.editItem(status)
                            },
                            onItemDelete: function ($event) {
                              return _vm.removeItem(status)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "Boxlist-body" }, [
                      _c(
                        "h3",
                        { staticClass: "title tooltip-relative max-content" },
                        [
                          _vm._v(_vm._s(status.name)),
                          status.isClosureStatus
                            ? _c("Tooltip", {
                                staticClass: "table-mini-popup",
                                attrs: {
                                  white: "",
                                  bottomLeft: true,
                                  text: _vm.$t(
                                    "OrderIntegrationStatus.Popup_Status_Integration_Type_Close"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
          _c("TableFoot", {
            attrs: { page: _vm.Page },
            on: {
              delegateOnChangePage: _vm.changePage,
              delegateOnChangePageSize: _vm.changePageSize,
            },
          }),
          _vm.isFetchingList ? _c("TableLoading") : _vm._e(),
          _vm.isEmptyList && !_vm.isFetchingList
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("OrderIntegrationStatus.emptyStateTitle"),
                  description: _vm.$t(
                    "OrderIntegrationStatus.emptyStateDescription"
                  ),
                  buttonText: _vm.$t(
                    "OrderIntegrationStatus.emptyStateNewButton"
                  ),
                },
                on: { emptyAddButton: _vm.addItem },
              })
            : _vm._e(),
          _c("router-view", { on: { getList: _vm.getList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }