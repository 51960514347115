var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              _c(
                "Button",
                {
                  attrs: {
                    size: "medium",
                    primary: "",
                    id: "btn-other-expense-new",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.itemNew },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("OtherExpenses.addExpense"))),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: { componentName: "OtherExpenses" },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                [
                  _vm.isLoading ? _c("TableLoading") : _vm._e(),
                  _vm.isOtherExpenseEmpty && !_vm.isLoading
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t("OtherExpenses.emptyState_Title"),
                          description: _vm.$t(
                            "OtherExpenses.emptyState_Description"
                          ),
                          buttonText: _vm.$t(
                            "OtherExpenses.emptyState_ButtonText"
                          ),
                        },
                        on: { emptyAddButton: _vm.itemNew },
                      })
                    : _vm._e(),
                  !_vm.isOtherExpenseEmpty && !_vm.isLoading
                    ? _c("BoxView", {
                        attrs: {
                          fields: _vm.fields,
                          actions: ["edit", "remove"],
                          data: _vm.OtherExpenseList,
                          page: _vm.Page,
                          componentName: "OtherExpensesBox",
                        },
                        on: {
                          delegateOnItemEdit: _vm.editExpense,
                          delegateOnItemRemove: _vm.removeExpense,
                          delegateOnChangePage: _vm.changePage,
                          delegateOnChangePageSize: _vm.changePageSize,
                        },
                      })
                    : _vm._e(),
                  _c("portal", {
                    attrs: { to: "box-header-info" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            {},
                            [
                              row.description
                                ? _c(
                                    "Button",
                                    {
                                      staticClass:
                                        "show-MiniPopup relative width-max-content m-left-15 btn-info",
                                      attrs: {
                                        id: `other-expense-info-button-${row.rowIndex}`,
                                        iconName: "icon-global-info",
                                        justIcon: true,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "MiniPopup-left-primary m-top-15 c-dark",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(row.description)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
          _c("router-view", { on: { getList: _vm.getOtherExpenses } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }