var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Despatches-details" }, [
    _c("div", { staticClass: "table-scroll" }, [
      _c("table", [
        !_vm.despatch.detailIsEmpty
          ? _c("thead", { staticClass: "Despatches-details-head" }, [
              _c("tr", { staticClass: "Despatches-details-head-row" }, [
                _c(
                  "th",
                  { staticClass: "Despatches-details-head-col stockItemName" },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_StockItemName")
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col sotckOnHand u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.detail_TableColumn_StockOnHandQuantity"
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col quantity u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(_vm.$t("Despatches.detail_TableColumn_Quantity"))
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col unitCode u-textCenter",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(_vm.$t("Despatches.detail_TableColumn_UnitCode"))
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col netUnitPrice u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_NetUnitPrice")
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col baseQuantity u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_BaseQuantity")
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  { staticClass: "Despatches-details-head-col baseUnit" },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(_vm.$t("Despatches.detail_TableColumn_BaseUnit"))
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col basePrice u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_BasePrice")
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col discount1 u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_Discount1")
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col discount2 u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_Discount2")
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col discountedUnitPrice u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.detail_TableColumn_DiscountedUnitPrice"
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col discountedBaseUnitPrice u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.detail_TableColumn_DiscountedBaseUnitPrice"
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col taxRatio u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(_vm.$t("Despatches.detail_TableColumn_TaxRatio"))
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col discountedNetUnitPrice u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.detail_TableColumn_DiscountedNetUnitPrice"
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "Despatches-details-head-col netAmount u-textRight",
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_NetAmount")
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "th",
                  { staticClass: "Despatches-details-head-col storeName" },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_TableColumn_StoreName")
                        )
                      ),
                    ]),
                  ]
                ),
                _c("th", { staticClass: "Despatches-details-head-col action" }),
              ]),
            ])
          : _vm._e(),
        !_vm.despatch.detailIsEmpty && _vm.renderComponent
          ? _c(
              "tbody",
              { staticClass: "Despatches-details-body" },
              _vm._l(_vm.despatch.despatchDetailList, function (detail) {
                return _c(
                  "tr",
                  {
                    staticClass: "Despatches-details-body-row",
                    attrs: { tabindex: "0" },
                  },
                  [
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col stockItemName tooltip-relative",
                        class:
                          _vm.despatch.status !== 1
                            ? "p-top-15 p-bottom-15"
                            : "",
                      },
                      [
                        _vm._v(_vm._s(_vm.getFieldName(detail.stockItem.name))),
                        detail.stockItem.name.length > 30
                          ? _c("Tooltip", {
                              staticClass: "tooltip",
                              attrs: {
                                white: "",
                                bottom: "",
                                text: detail.stockItem.name,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col stockOnHandQuantity ff-mono u-textRight",
                      },
                      [_vm._v(_vm._s(detail.stockOnHandQuantity || "-") + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col quantity ff-mono u-textRight",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _vm.despatch.status === 1
                              ? _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { min_value: 0 },
                                      expression: "{min_value: 0}",
                                    },
                                  ],
                                  staticClass: "number-input",
                                  class: {
                                    "is-danger": _vm.veeErrors.has("quantity"),
                                  },
                                  attrs: {
                                    id: "refund-quantity",
                                    name: "quantity",
                                    value: detail.quantity,
                                    "data-vv-as": _vm.$t(
                                      "Despatches.detail_TableColumn_Quantity"
                                    ),
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.changeQuantity(detail, $event)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col baseUnitCode u-textCenter",
                      },
                      [_vm._v(_vm._s(_vm.findUnit(detail.unitId).name))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col netUnitPrice ff-mono u-textRight currency",
                      },
                      [
                        _vm.showCurrencySymbol && detail.unitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(detail.currencyDefinitionSymbol) +
                                  " " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(detail.unitPrice)
                                  )
                              ),
                            ])
                          : _vm._e(),
                        !_vm.showCurrencySymbol && detail.unitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(detail.unitPrice)
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col baseQuantity ff-mono u-textRight",
                      },
                      [_vm._v(_vm._s(detail.baseQuantity))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "Despatches-details-body-col baseUnitCode",
                      },
                      [_vm._v(_vm._s(detail.stockItem.baseUnitCode))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col basePrice ff-mono u-textRight currency",
                      },
                      [
                        _vm.showCurrencySymbol && detail.netBaseUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(detail.currencyDefinitionSymbol) +
                                  " " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      detail.netBaseUnitPrice
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        !_vm.showCurrencySymbol && detail.netBaseUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    detail.netBaseUnitPrice
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col discount1 ff-mono u-textRight",
                      },
                      [
                        _vm._v(
                          "% " +
                            _vm._s(_vm._f("percentFixed")(detail.discount1))
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col discount2 ff-mono u-textRight",
                      },
                      [
                        _vm._v(
                          "% " +
                            _vm._s(_vm._f("percentFixed")(detail.discount2))
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col discountedUnitPrice ff-mono u-textRight currency",
                      },
                      [
                        _vm.showCurrencySymbol && detail.discountedUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(detail.currencyDefinitionSymbol) +
                                  " " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      detail.discountedUnitPrice
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        !_vm.showCurrencySymbol && detail.discountedUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    detail.discountedUnitPrice
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col DiscountedBaseUnitPrice ff-mono u-textRight currency",
                      },
                      [
                        _vm.showCurrencySymbol && detail.discountedBaseUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(detail.currencyDefinitionSymbol) +
                                  " " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      detail.discountedBaseUnitPrice
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        !_vm.showCurrencySymbol &&
                        detail.discountedBaseUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    detail.discountedBaseUnitPrice
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col taxRatio ff-mono u-textRight",
                      },
                      [
                        _vm._v(
                          "% " + _vm._s(_vm._f("percentFixed")(detail.taxRatio))
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col discountedNetUnitPrice ff-mono u-textRight currency",
                      },
                      [
                        _vm.showCurrencySymbol && detail.discountedNetUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(detail.currencyDefinitionSymbol) +
                                  " " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      detail.discountedNetUnitPrice
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        !_vm.showCurrencySymbol && detail.discountedNetUnitPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    detail.discountedNetUnitPrice
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "Despatches-details-body-col netAmount ff-mono u-textRight currency",
                      },
                      [
                        _vm.showCurrencySymbol && detail.netAmount
                          ? _c("span", [
                              _vm._v(
                                _vm._s(detail.currencyDefinitionSymbol) +
                                  " " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(detail.netAmount)
                                  )
                              ),
                            ])
                          : _vm._e(),
                        !_vm.showCurrencySymbol && detail.netAmount
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(detail.netAmount)
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "td",
                      { staticClass: "Despatches-details-body-col storeName" },
                      [_vm._v(_vm._s(detail.storeName))]
                    ),
                    _c(
                      "td",
                      { staticClass: "Despatches-details-body-col action" },
                      [
                        _c("ActionButton", {
                          attrs: {
                            id: "refund-detail-table-action-button",
                            data:
                              _vm.despatch.status === 1
                                ? ["edit", "remove"]
                                : ["edit"],
                            item: _vm.despatch,
                          },
                          on: {
                            onItemEdit: function ($event) {
                              return _vm.despatchDetailEdit(detail)
                            },
                            onItemDelete: function ($event) {
                              return _vm.despatchDetailRemove(detail)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "Despatches-details-foot" },
      [
        _c(
          "Button",
          {
            staticClass: "m-right-10",
            attrs: {
              id: "btn-refund-expire-date-edit",
              disabled: !_vm.despatch.hasAnyDetail,
              type: "button",
              size: "small",
              secondary: "",
            },
            on: {
              click: function ($event) {
                return _vm.editExpireDate(_vm.despatch)
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("Transfer.editExpireDate")))])]
        ),
        _c(
          "Button",
          {
            staticClass: "m-right-10",
            attrs: {
              id: "btn-despatch-detail-new",
              size: "small",
              secondary: "",
              inline: "",
              type: "button",
              iconName: "icon-global-add",
              iconClass: "icon-global-add",
              disabled: _vm.isOnlyListUsage,
            },
            on: {
              click: function ($event) {
                return _vm.despatchDetailNew(_vm.despatch)
              },
            },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("Despatches.detail_NewStockItemButton"))),
            ]),
          ]
        ),
        _vm.despatch.status === 1
          ? _c(
              "Button",
              {
                staticClass: "m-right-10",
                attrs: {
                  id: "btn-despatch-detail-save",
                  size: "small",
                  primary: "",
                  inline: "",
                  type: "button",
                  iconName: "icon-btn-success",
                  iconClass: "icon-btn-success",
                },
                on: {
                  click: function ($event) {
                    return _vm.refundSaved(_vm.despatch)
                  },
                },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("Despatches.detail_SaveRefundButton"))),
                ]),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }