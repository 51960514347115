<template lang="pug">
Page
  template(slot="header-title")
    span {{ $t('Production.pageHeader_Production') }}
  template(slot="content-center")
    TableLoading(v-if="isLoading")
    .Result.m-bottom-15
      .Result-top-header-title
        .doc-number {{documentNumber}}
        span.m-left-5 {{$t('Despatches.document')}}
    TableView.m-top-15(
      :componentName="'productionDocumentDetail'"
      :fields="productionDetailFields"
      :actions="['']"
      :data="productionDetail"
      :readOnly="true"
    )
    portal(to="unitName")
      div(slot-scope="row")
        span {{ findUnit(row.unitId).name }}

</template>

<script>

import { getProductionsDetailFields } from './fields/production.js'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductionDocumentDetail',

  data () {
    return {
      productionDetailFields: getProductionsDetailFields(this.$t, this.$getConst),
      productionDetail: []
    }
  },

  computed: {

    ...mapGetters('Units', ['findUnit']),

    isLoading () {
      return this.$wait.is(['getProductionDocument'])
    },
    documentNumber () {
      return this.$route.params.documentNumber
    }
  },
  async mounted () {
    await this.getProductionDetail(this.$route.params.id)
  },
  methods: {
    ...mapActions('Production', ['getProductionDocument']),

    async getProductionDetail (item) {
      await this.getProductionDocument(item).then(res => {
        if (res) {
          this.productionDetail = res.data.productionDetailList
        }
      })
    }
  }
}
</script>

<style lang="scss">

.Result{
  &-top-header-title{
    color: $color-primary;
    display: flex;
  }
}
.doc-number {
  font-weight: bold;
  text-decoration: underline;
}
</style>
