<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    .form-item-select.date-start
      CustomDatepicker#ABCReportDatepicker(
        v-model="dates",
        :range="true"
        :disabled="isABCAnalysisLoading || isPageLoading"
        :isLoading="$wait.is('getDateFilterSettings')"
      )
  template(slot="toolbar-left")
    .toolbar-filters
      CustomMultiselect.toolbar-filters__item(
      id="abc-analysis-store-filter"
      :items="storeList"
      :selected="storeFilter"
      :label="$t('ABCAnalysis.toolbar_Stores_Label')"
      :unselectedText="$t('ABCAnalysis.toolbar_Stores_Unselected')"
      :popupHeader="$t('ABCAnalysis.toolbar_Stores_PopupHeader')"
      :allLabel="$t('ABCAnalysis.toolbar_Stores_AllLabel')"
      :buttonText="$t('ABCAnalysis.toolbar_Stores_ButtonText')"
      buttonIconLeft="btn-success"
      @delegateCheckAll="onChangeStoreFilter"
      @delegateCheckItem="onChangeStoreFilter"
      @delegateSubmitItems="getABCAnalysisWithSelectedParams")

  template(slot="toolbar-right")
    ImportExport(
      componentName="reports-abc-analysis"
      ref="wrapper"
      :isDisabled="isPageLoading"
      :activeImportExports=['export']
      downloadUrl="Report/abcanalysis/export"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="downloadFileName"
    )
  template(slot="content-center")
    .ABCReports
      .results
        TableLoading(v-if="isABCAnalysisLoading || isPageLoading")

        TableView(
        v-if="!isABCAnalysisLoading && !isABCAnalysisEmpty && !isPageLoading"
        :fields="ABCFields"
        :page="abcPage"
        :componentName="'ReportsAbcTable'"
        :readOnly="true"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        :data="ABCAnalysis")

        portal(to="headerCategory")
          .category
            span.category-text {{ $t('ABCAnalysis.tableColumn_Category_Short') }}
            span.category-info
              Icon.show-MiniPopup.category-info-icon(name="icon-global-info-filled")
              ul.category-popup
                li.category-popup-row(
                  v-for="item in popupInfoObject"
                )
                  span.category-popup-value {{ item.value }}
                  span.category-popup-text {{ item.text }}

        EmptyState(
        v-if="!isABCAnalysisLoading && isABCAnalysisEmpty"
        :title="$t('ABCAnalysis.emptyState_Title')"
        :description="$t('ABCAnalysis.emptyState_Description')")
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import getABCFields from './fields/abc'
//
import ImportExport from '@/view/global/import-export'
import getDateFilterSetting from '@/utils/getDateFilterSetting'

export default {
  name: 'ABCReports',

  components: {
    ImportExport
  },

  data () {
    return {
      pageHeaderLinks: [
        'PurchaseReports',
        'ABCAnalysis'
      ],
      dates: [],
      storeFilter: [],
      ABCFields: getABCFields(this.$t),
      popupInfoObject: [
        {
          value: 'A',
          text: this.$t('ABCAnalysis.Popup_Info_A')
        },
        {
          value: 'B',
          text: this.$t('ABCAnalysis.Popup_Info_B')
        },
        {
          value: 'C',
          text: this.$t('ABCAnalysis.Popup_Info_C')
        }
      ]
    }
  },

  async mounted () {
    this.$wait.start('pageLoading')
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_ABCAnalysisReport')
    await this.getMiniStoreList().then(async () => {
      this.storeFilter = await [...this.storeList]
      await this.getABCAnalysisWithSelectedParams()
    })
    this.$wait.end('pageLoading')
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getABCAnalysisWithSelectedParams()
    }
  },

  computed: {
    ...mapGetters('Reports', [
      'ABCAnalysis',
      'isABCAnalysisEmpty',
      'abcPage'
    ]),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList'
    }),

    isABCAnalysisLoading () {
      return this.$wait.is(['getABCAnalysis'])
    },
    isPageLoading () {
      return this.$wait.is('pageLoading')
    },
    downloadParams () {
      const params = {
        StartDate: this.dateFormat(this.dates[0]),
        EndDate: this.dateFormat(this.dates[1]),
        StoreList: this.storeFilter.map(store => store.id)
      }
      return params
    },
    downloadFileName () {
      const fileName = `AbcAnalysisReport_${this.dateFormat(this.dates[0])}_${this.dateFormat(this.dates[1])}`
      return fileName
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),
    ...mapActions('Reports', [
      'getABCAnalysis'
    ]),

    ...mapActions('Stores', [
      'getMiniStoreList'
    ]),

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    onChangeStoreFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.storeFilter = [...this.storeList]
        } else {
          this.storeFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.storeFilter = []
        } else {
          this.storeFilter.splice(this.storeFilter.indexOf(event.item), 1)
        }
      }
    },

    changePage (page) {
      this.getABCAnalysisWithSelectedParams(page)
    },

    changePageSize (pageSize) {
      this.getABCAnalysisWithSelectedParams(1, pageSize)
    },

    getABCAnalysisWithSelectedParams (page = 1, pageSize = this.abcPage.size) {
      const payload = {
        start: this.dateFormat(this.dates[0]),
        end: this.dateFormat(this.dates[1]),
        storeList: this.storeFilter,
        pageNumber: page,
        pageSize
      }
      this.getABCAnalysis(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

  .PageHeader {
    .right {
      display: flex;
      .form-item-select {
        margin-left: 10px;
      }
    }
  }

  .PageHeaderExtra {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-gray;
  }

  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  .PageContent {
    .results {
      position: relative;
    }
  }

  $info-icon-size: 15px;
  .category {
    display: flex;
    justify-content: center;
    max-height: 100%;

    &-info {
      position: relative;
      height: auto;

      &-icon {
        margin-left: 2.4px;
        margin-right: 10px;
        width: $info-icon-size;
        height: $info-icon-size;
        color: $color-primary;
        cursor: pointer;
      }
    }

    &-popup {
      @include MiniPopupMixin(
        $display: none,
        $width: 316px,
        $max-width: 316px,
        $triangle-direction: 'right',
        $padding-hor: 0,
        $padding-ver: 0,
      );

      color: $color-dark;

      &-row {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
      }

      &-row:not(:last-child) {
        border-bottom: 1px solid $color-gray;
      }

      &-value {
        font-size: $font-size-bigger;
        font-weight: $font-weight-bold;
      }

      &-text {
        margin-left: 11px;
      }
    }
  }
  :deep() .Tableee-col.vue-portal-target{
    display: flex;
    align-items: center;
  }
</style>
