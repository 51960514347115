export default function getPaymentFields($t) {
  return [
    {
      name: 'supplierName',
      is_visible: true,
      get short_title() {
        return $t('Payments.dataFieldSupplierName')
      },
      get title() {
        return $t('Payments.dataFieldSupplierName')
      }
    },
    {
      name: 'paymentDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Payments.dataFieldPaymentDate')
      },
      get title() {
        return $t('Payments.dataFieldPaymentDate')
      }
    },
    {
      name: 'paymentOption',
      is_visible: true,
      get short_title() {
        return $t('Payments.dataFieldPaymentOption')
      },
      get title() {
        return $t('Payments.dataFieldPaymentOption')
      }
    },
    {
      name: 'amount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Payments.dataFieldAmount')
      },
      get title() {
        return $t('Payments.dataFieldAmount')
      }
    }
  ]
}
