<template lang="pug">
Modal.Confirm(:isOpen="isOpenAgreementModal", :showCloseButton="false")
      template(slot="title")
        | {{ $t('UserAgreement.title') }}
      template(slot="content")
        div.Confirm-agreementText(v-html="agreement.agreementText")
      template(slot="footer")
        Button(
          type="button"
          primary,
          size="medium"
          @click="confirmAgreementAction") {{ $t('UserAgreement.confirmationButton') }}
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AgreeConfirm',
  data () {
    return {
      isOpenAgreementModal: true
    }
  },
  computed: {
    ...mapGetters('Auth', [
      'agreement'
    ]),
    ...mapGetters('Global', ['appLanguageInfo'])
  },
  methods: {
    ...mapActions('Auth', [
      'getAgreement',
      'confirmAgreement'
    ]),

    ...mapMutations('Auth', ['ControlAgreement']),

    async confirmAgreementAction () {
      const res = await this.confirmAgreement(this.agreement.id)
      if (res) {
        this.ControlAgreement(false)
        this.$router.push({
          name: 'Dashboard'
        })
      }
    }
  },
  async mounted () {
    await this.getAgreement(this.appLanguageInfo.id)
  }
}
</script>

<style lang="scss" scoped>

.Confirm {
  &-agreementText {
    p {
      color: $color-light;
      line-height: 1.5;
    }
  }
}
</style>
