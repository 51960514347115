<template lang="pug">
.SettingsContainer
  NoPermission(v-if="!hasTabPermission")
  form.Form(v-else, @submit.prevent="submit")
    .Form-row
      .Form-col
        .Form-item.name
          label.Form-item-label
            | {{ $t('Settings.Tenant_FormField_name') }}
          .control
            input.txt(
            disabled
            name="name"
            v-model="form.name"
            type="text"
            id="input-settings-form-field-name")

      .Form-col
        .Form-item.address
          label.Form-item-label
            | {{ $t('Settings.Tenant_FormField_address') }}
          .control
            textarea.txt(
            name="address"
            rows="2"
            :data-vv-as="$t('Settings.Tenant_FormField_address')"
            v-model="form.address"
            v-validate="'max:128'"
            :class="{ 'is-danger': veeErrors.has('address') }"
            autocomplete="address")
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('address')")
            | {{ veeErrors.first('address') }}

    .Form-row
      .Form-col
        .Form-item.phoneNumber
          label.Form-item-label
            | {{ $t('Settings.Tenant_FormField_phoneNumber') }}
          .control
            input.txt(
            name="phoneNumber",
            :data-vv-as="$t('Settings.Tenant_FormField_phoneNumber')",
            v-validate="'max:50'",
            v-model="form.phoneNumber"
            )
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('phoneNumber')")
            | {{ veeErrors.first('phoneNumber') }}

      .Form-col
        .Form-item
          label.Form-item-label
            | {{ $t('Settings.Tenant_FormField_baseCurrency') }}
          .control
            input.txt(
            disabled
            name="currency"
            v-model="form.currency"
            type="text"
            id="input-settings-form-base-currency")

    .Form-row
      .Form-col
        .Form-item
          label.Form-item-label {{ $t('Register.formFieldTcknVkn') }}
          .control
            input.txt(
              id="input-settings-tax-number"
              name="taxNumber",
              type="number",
              autocomplete="off",
              :placeholder="$t('Register.formFieldTcknVknPlaceholder')",
              v-model="form.taxNumber",
              @keydown="preventNotNumberValues")

      .Form-col
        .Form-item
          label.Form-item-label {{ $t('Register.formFieldTaxOffice') }}
          .control
            input.txt(
              id="input-settings-tax-office"
              name="taxOffice",
              type="text"
              autocomplete="off",
              :placeholder="$t('Register.formFieldTaxOfficePlaceholder')",
              v-model="form.taxOfficeName")

    .Form-row
      .Form-col.align-self-flex-end
        CustomCheckbox.width-max-content.m-bottom-10(
          v-for="(permission, index) in tenantPermissions"
          :id="`checkbox-multiselect-list-item-${index}`"
          :key="index"
          :checked="permission.checked"
          :disabled="permission.disabled"
          :label="$t(`Settings.Tenant_Permission_Label_${permission.name}`)"
          @change="isChecked => selectItem(permission, isChecked)")
      .Form-col.not-label
        .Form-item.submit
          Button(
          primary
          variant="full"
          size="medium"
          id="btn-settings-submit-tenant"
          :disabled="shouldDisableSubmit"
          type="submit")
            Loading(theme="disable", v-show="isLoading")
            span(
            v-show="!isLoading"
            ) {{ $t('Settings.Tenant_FormField_submit') }}

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vee-validate'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import alertbox from '@/mixins/alertbox'

export default {
  name: 'SettingsTenant',
  mixins: [hasTabPermission,
  alertbox()],

  components: {
    NoPermission
  },

  data () {
    return {
      form: {
        name: '',
        address: '',
        phoneNumber: '',
        currency: '',
        taxNumber: null,
        taxOfficeName: ''
      },
      tenantPermissions: []
    }
  },

  async created () {
    if (!this.hasTabPermission) return
    await this.getTenants()
    this.form.id = this.Tenant.id
    this.form.name = this.Tenant.name
    this.form.address = this.Tenant.address
    this.form.phoneNumber = this.Tenant.phoneNumber
    this.form.currency = this.baseCurrency.isoCode
    this.form.taxNumber = this.Tenant.taxNumber
    this.form.taxOfficeName = this.Tenant.taxOfficeName
  },

  computed: {
    ...mapFields({
      field_address: 'address',
      field_phoneNumber: 'phoneNumber'
    }),

    ...mapGetters('Auth', [
      'Tenant'
    ]),

    ...mapGetters('Global', [
      'CurrencyList'
    ]),

    shouldDisableSubmit () {
      let hasChanged
      if (
        this.form.id === this.Tenant.id &&
        this.form.name === this.Tenant.name &&
        this.form.address === this.Tenant.address &&
        this.form.phoneNumber === this.Tenant.phoneNumber &&
        this.form.taxNumber === this.Tenant.taxNumber &&
        this.form.taxOfficeName === this.Tenant.taxOfficeName &&
        !this.tenantPermissions.some(tp => this.Tenant[tp.name] !== tp.checked && this.Tenant['isMultiCurrencyActive'] == tp.disabled)
      ) {
        hasChanged = false
      } else {
        hasChanged = true
      }
      return this.isLoading ||
          !this.field_address.valid ||
          !this.field_phoneNumber.valid ||
          !hasChanged
    },

    baseCurrency () {
      return this.CurrencyList.find(item => item.id === this.Tenant.baseCurrencyId)
    },

    isLoading () {
      return this.$wait.is(['Auth/updateTenant'])
    } 
  },

  methods: {

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Auth', [
      'updateTenant',
      'getTenant'
    ]),

    preventNotNumberValues(e) {
      if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
        e.preventDefault();
      }
    },

    selectItem (item, isChecked) {
      this.tenantPermissions.find(tp => tp.name === item.name).checked = isChecked
      if (this.tenantPermissions.find(tp => tp.name === 'isMultiCurrencyActive').checked && (item.name === 'isMultiCurrencyActive' && !item.disabled)) {
        this.multiCurrencyAlert(item)
      }
    },

    multiCurrencyAlert (item) {
      this.alert({
        title: this.$t('Settings.Tenant_MultiCurrency_Alert_Title'),
        message: this.$t('Settings.Tenant_MultiCurrency_Alert_Description'),
        cancel: {
          text: this.$t('Settings.Tenant_MultiCurrencyAlert_cancelButton'),
          action: () => {
            this.selectItem(item,false)
            close()
          }
        },
        confirm: {
          text: this.$t('Settings.Tenant_MultiCurrencyAlert_confirmButtonText'),
          action: async (close) => {
            await this.changeMultiCurrency()
            close()
          }
        }
      })
    },

    changeMultiCurrency () {
      const payload = {
        ...this.form,
        isMultiCurrencyActive: this.tenantPermissions.find(tp => tp.name === 'isMultiCurrencyActive').checked,
        mustHaveSingleStoreInSameDespatch: true // Connected to isMultiCurrencyActive must be true
      }
      this.updateTenant(payload).then(res => {
        if (res) {
          const message = this.$t('Settings.Tenant_FormMessage_success')
          this.notifyShow({ message })
          this.getTenants()
        }
      })
    },

    async getTenants (){
      this.tenantPermissions = []
      const res = await this.getTenant({ id: this.Tenant.id })
      this.tenantPermissions.push(
        {
          name: 'approvalRequestOrderPermission',
          checked: res.data.tenant.approvalRequestOrderPermission,
          disabled: false,
          hasPopup: false
        },
        {
          name: 'approvalRequestTransferPermission',
          checked: res.data.tenant.approvalRequestTransferPermission,
          disabled: false,
          hasPopup: false
        },
        {
          name: 'approvalOrderPermission',
          checked: res.data.tenant.approvalOrderPermission,
          disabled: false,
          hasPopup: true
        },
        {
          name: 'approvalTransferPermission',
          checked: res.data.tenant.approvalTransferPermission,
          disabled: false,
          hasPopup: true
        },
        {
          name: 'isMultiCurrencyActive',
          checked: res.data.tenant.isMultiCurrencyActive,
          disabled: res.data.tenant.isMultiCurrencyActive,
          hasPopup: false,
        },
        {
          name: 'mustHaveSingleStoreInSameDespatch',
          checked: res.data.tenant.isMultiCurrencyActive ? true : res.data.tenant.mustHaveSingleStoreInSameDespatch,
          disabled: res.data.tenant.isMultiCurrencyActive,
          hasPopup: true
        },
      )
    },

    submit () {
      this.$validator.validateAll().then(result => {
        if (!result) return
        const payload = {
          ...this.form,
          approvalRequestOrderPermission: this.tenantPermissions.find(tp => tp.name === 'approvalRequestOrderPermission').checked,
          approvalRequestTransferPermission: this.tenantPermissions.find(tp => tp.name === 'approvalRequestTransferPermission').checked,
          approvalOrderPermission: this.tenantPermissions.find(tp => tp.name === 'approvalOrderPermission').checked,
          approvalTransferPermission: this.tenantPermissions.find(tp => tp.name === 'approvalTransferPermission').checked,
          mustHaveSingleStoreInSameDespatch: this.tenantPermissions.find(tp => tp.name === 'mustHaveSingleStoreInSameDespatch').checked,
        }
        this.updateTenant(payload).then(res => {
          if (res) {
            // show notify
            const message = this.$t('Settings.Tenant_FormMessage_success')
            this.notifyShow({ message })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .not-label{
    max-width: 240px;
    padding-bottom: 2px;
  }
  :deep() .Alert-actions {
    margin-top: 0 !important;
  }
</style>
