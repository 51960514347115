<template lang="pug">
Page
  template( slot="header-right-content" )

    Button(
        primary,
        size      ="medium",
        id        ="btn-tax-new",
        v-show    ="!isEmpty && !isLoading",
        @click    ="newTax",
        iconName  ="icon-global-add",
        iconClass ="icon-global-add icon-left",
      )
        span {{ $t('Tax.headerNewButton') }}

  template(slot="content-center" )
    TableLoading( v-if="isLoading" )
    EmptyState(
      v-if            ="isEmpty && !isLoading",
      :title          ="$t('Tax.emptyStateTitle')",
      :description    ="$t('Tax.emptyStateDescription')",
      :buttonText     ="$t('Tax.emptyStateNewButton')",
      @emptyAddButton ="newTax"
    )
    BoxView(
      v-if="!isLoading"
      :fields="taxItemFields"
      :actions="['edit']"
      :data="Taxes"
      :extraClass="{forContent: 'box-tax'}"
      :componentName="'TaxBox'"
      @delegateOnItemEdit="editTax"
    )
    router-view(@getList="getTaxes")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Tax',

  created () {
    this.getTaxes()
  },

  computed: {
    ...mapGetters('Tax', [
      'Taxes',
      'isTaxEmpty'
    ]),

    isLoading () {
      return this.$wait.is(['Tax/getTaxes'])
    },

    isEmpty () {
      return this.isTaxEmpty
    },

    taxItemFields () {
      return [{
        name: 'name',
        is_visible: true,
        headerTitle: true
      }, {
        name: 'ratio',
        is_visible: true,
        defaultValue: '0'
      }]
    }
  },

  methods: {
    ...mapActions('Tax', [
      'getTaxes'
    ]),

    newTax () {
      this.$router.push({
        name: 'TaxNew'
      })
    },

    editTax (tax) {
      this.$router.push({
        name: 'TaxEdit',
        params: {
          id: tax.id,
          tax
        }
      })
    }
  }
}
</script>
