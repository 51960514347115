var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select date-start" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "salesReportDatepicker",
                range: true,
                disabled: _vm.isLoading || _vm.isPageLoading,
                isLoading: _vm.$wait.is("getDateFilterSettings"),
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.groupTypes,
              selectedItem: _vm.activeGroupType,
              fieldKey: "name",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeGroupType },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            staticClass: "toolbar-filters__item",
            attrs: {
              id: "reports-sales-store-filter",
              items: _vm.storeList,
              selected: _vm.storeFilter,
              label: _vm.$t("Reports.toolbar_Stores_Label"),
              unselectedText: _vm.$t("Reports.toolbar_Stores_Unselected"),
              popupHeader: _vm.$t("Reports.toolbar_Stores_PopupHeader"),
              allLabel: _vm.$t("Reports.toolbar_Stores_AllLabel"),
              buttonText: _vm.$t("Reports.toolbar_Stores_ButtonText"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckAll: _vm.onChangeStoreFilter,
              delegateCheckItem: _vm.onChangeStoreFilter,
              delegateSubmitItems: _vm.getSalesReportsWithParams,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            attrs: {
              componentName: "reports-sales",
              isDisabled: _vm.isLoading,
              activeImportExports: ["export"],
              downloadUrl: "Report/sales/export",
              downloadFileType: "xlsx",
              downloadParams: _vm.downloadParams,
              downloadFileName: _vm.downloadFileName,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "result" },
          [
            !_vm.isLoading && !_vm.isSalesReportsEmpty
              ? _c("TableView", {
                  attrs: {
                    fields: _vm.fieldsByType,
                    componentName: "ReportsSalesTable",
                    readOnly: true,
                    data: _vm.salesReports,
                  },
                })
              : _vm._e(),
            _vm.isLoading || _vm.isPageLoading ? _c("TableLoading") : _vm._e(),
            !_vm.isLoading && !_vm.isPageLoading && _vm.isSalesReportsEmpty
              ? _c("EmptyState", {
                  attrs: {
                    title: _vm.$t("SalesReports.emptyState_Title"),
                    description: _vm.$t("SalesReports.emptyState_Description"),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }