var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.requestHeaderLinks,
              translationGroup: "Request",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _vm.hasTabPermission
            ? _c("Search", {
                staticClass: "search-box",
                attrs: {
                  componentName: "requestedOrdersIndex",
                  suggestionValue: "name",
                  isSuggestionLoading: _vm.isSuggestionLoading,
                  suggestions: _vm.selectedStockItem,
                  customSuggestionPlaceHolder: _vm.$t(
                    "DirectOrders.searchPlaceholder"
                  ),
                  customSuggestionTitle: _vm.$t(
                    "DirectOrders.orders_search_SuggestionTitle"
                  ),
                },
                on: {
                  onChangeSearchValue: _vm.onChangeSearch,
                  onChangeQueryItem: _vm.setStockItem,
                  clearSearch: _vm.clearSearch,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c("template", { slot: "header-right-dropdown" }, [
            _c(
              "div",
              {
                staticClass: "form-item-select date-start m-right-10 m-left-10",
              },
              [
                _c("CustomDatepicker", {
                  attrs: {
                    id: "DirectOrderDatepicker",
                    isLoading: _vm.$wait.is("getDateFilterSettings"),
                    range: true,
                    disabled: _vm.isFetchingList,
                  },
                  model: {
                    value: _vm.dates,
                    callback: function ($$v) {
                      _vm.dates = $$v
                    },
                    expression: "dates",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              !_vm.isEmptyDirectOrders
                ? _c(
                    "Button",
                    {
                      attrs: {
                        id: "btn-directOrder-header-create-header",
                        primary: "",
                        size: "medium",
                        iconName: "icon-global-add",
                        iconClass: "icon-global-add",
                      },
                      on: { click: _vm.newHeader },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("DirectOrders.add_DirectOrder"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeFilter", {
            ref: "requestsStoreFilter",
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "show-drafts m-left-15" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-direct-requests-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : [
                !_vm.isEmptyDirectOrders && _vm.isFetchingList
                  ? _c("TableLoading")
                  : _vm._e(),
                _vm.isEmptyDirectOrders && !_vm.isFetchingList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t("DirectOrders.emptyState_Title"),
                        description: _vm.$t(
                          "DirectOrders.emptyState_Description"
                        ),
                        buttonText: _vm.$t("DirectOrders.add_DirectOrder"),
                      },
                      on: { emptyAddButton: _vm.newHeader },
                    })
                  : _vm._e(),
                !_vm.isEmptyDirectOrders && !_vm.isFetchingList
                  ? _c(
                      "div",
                      [
                        _c("NestedTable", {
                          attrs: {
                            data: _vm.directOrders,
                            actions: [
                              "edit",
                              "delete",
                              "single-action-portal-start",
                              "export",
                            ],
                            detailActions: ["edit", "delete"],
                            fields: _vm.directOrdersHeaderFields,
                            isActionButtonsVisible: true,
                            detailFields: _vm.directOrdersDetailFields,
                            disableFinalizeButton: _vm.disableFinalize,
                            isDetailLoading: _vm.$wait.is(
                              "fetchDirectOrderDetail"
                            ),
                            usePortalFooter: true,
                            usePortalAction: true,
                            readOnlyForDetail: true,
                          },
                          on: {
                            deleteHeaderAction: _vm.removeItemAction,
                            editHeaderAction: _vm.editHeader,
                            showDetailAction: _vm.showDetail,
                            detailChangeAction: _vm.updateDetailItems,
                            showExportPopup: _vm.showExportPopup,
                            finalizeAction: _vm.itemSaved,
                          },
                        }),
                        _c("portal", {
                          attrs: { to: "actionPortal" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (detail) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "popup-container flexbox-custom",
                                    },
                                    [
                                      detail.row.status !== 2
                                        ? _c("ActionButton", {
                                            attrs: {
                                              id: "request-order-list-action-button",
                                              data:
                                                detail.row.approvalStatus ===
                                                  3 &&
                                                detail.row.headerType === 1
                                                  ? ["edit"]
                                                  : ["edit", "delete"],
                                              item: detail.row,
                                            },
                                            on: {
                                              onItemDelete: function ($event) {
                                                return _vm.removeItemDetailAction(
                                                  detail.row
                                                )
                                              },
                                              onItemEdit: function ($event) {
                                                return _vm.directOrdersDetailEdit(
                                                  detail.row
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1984154339
                          ),
                        }),
                        _c("portal", {
                          attrs: { to: "maxApprovalLevel" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (header) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      header.row.approvalStatus > 0
                                        ? _c("ApprovalLevels", {
                                            attrs: {
                                              maxApprovalLevel:
                                                header.row.maxApprovalLevel,
                                              currentApprovalLevel:
                                                header.row.currentApprovalLevel,
                                              approvalStatus:
                                                header.row.approvalStatus,
                                              historyList:
                                                _vm.historyListForOrders,
                                              isLoading: _vm.isHistoryLoading,
                                            },
                                            on: {
                                              showHistoryPopup: function (
                                                $event
                                              ) {
                                                return _vm.showHistoryPopup(
                                                  header.row
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            2792418035
                          ),
                        }),
                        _c("portal", {
                          attrs: { to: "approvalStatus" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (header) {
                                  return _c("div", {}, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getApprovalStatus(
                                            header.row.approvalStatus
                                          )
                                        )
                                      ),
                                    ]),
                                  ])
                                },
                              },
                            ],
                            null,
                            false,
                            4057542353
                          ),
                        }),
                        _c("portal", {
                          attrs: { to: "description" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (header) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      header.row.description
                                        ? _c(
                                            "Button",
                                            {
                                              staticClass:
                                                "show-MiniPopup relative width-max-conten",
                                              attrs: {
                                                size: "small",
                                                variant: "info",
                                                id: `orders-header-info-button-${header.rowIndex}`,
                                                iconName: "icon-global-info",
                                                justIcon: true,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "MiniPopup-right-primary m-top-15 c-dark",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        header.row.description
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1504105771
                          ),
                        }),
                        _c("portal", {
                          attrs: { to: "description" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (detail) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      detail.row.description
                                        ? _c(
                                            "Button",
                                            {
                                              staticClass:
                                                "show-MiniPopup relative width-max-content",
                                              attrs: {
                                                size: "small",
                                                variant: "info",
                                                id: `orders-detail-info-button-${detail.rowIndex}`,
                                                iconName: "icon-global-info",
                                                justIcon: true,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "MiniPopup-right-primary m-top-15 c-dark",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        detail.row.description
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            2951204497
                          ),
                        }),
                        _c("portal", {
                          attrs: { to: "single-action-portal-start" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (header) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      header.row.totalUnitQuantityList.length >
                                      0
                                        ? _c("TotalPreview", {
                                            attrs: {
                                              rowIndex: header.rowIndex,
                                              list: header.row
                                                .totalUnitQuantityList,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            802798767
                          ),
                        }),
                        _c("portal", {
                          attrs: { to: "footerPortal" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (rowData) {
                                  return _c(
                                    "div",
                                    { staticClass: "popup-container flexbox" },
                                    [
                                      rowData.status !== 2 &&
                                      rowData.headerType !== 1
                                        ? _c(
                                            "Button",
                                            {
                                              staticClass: "m-right-20",
                                              attrs: {
                                                id: "nested-detail-add-stock-items",
                                                inline: "",
                                                primary: "",
                                                size: "small",
                                                disabled:
                                                  (rowData.approvalStatus !==
                                                    0 &&
                                                    rowData.approvalStatus !==
                                                      3) ||
                                                  _vm.isOnlyListUsage,
                                                iconName: "icon-global-add",
                                                iconClass: "icon-global-add",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.directOrdersDetailNew(
                                                    rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "DirectOrders.addStockItemButton"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      (
                                        !_vm.Tenant.approvalOrderPermission
                                          ? rowData.detailList.length &&
                                            rowData.status !== 2 &&
                                            rowData.headerType === 0
                                          : (rowData.maxApprovalLevel > 0
                                              ? rowData.approvalStatus === 2
                                              : rowData.approvalStatus === 0) &&
                                            rowData.detailList.length &&
                                            rowData.status !== 2 &&
                                            rowData.headerType === 0
                                      )
                                        ? _c(
                                            "Button",
                                            {
                                              staticClass: "m-right-20",
                                              attrs: {
                                                id: "nested-detail-finalize-order",
                                                inline: "",
                                                primary: "",
                                                size: "small",
                                                isLoading: _vm.isDetailFetching,
                                                iconName: "icon-btn-success",
                                                iconClass: "icon-btn-success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.itemSaved(rowData)
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "DirectOrders.createDirectOrder"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.Tenant.approvalOrderPermission &&
                                      ((rowData.approvalStatus === 0 &&
                                        rowData.maxApprovalLevel > 0) ||
                                        (rowData.approvalStatus === 3 &&
                                          rowData.maxApprovalLevel > 0))
                                        ? _c(
                                            "Button",
                                            {
                                              staticClass: "m-right-20",
                                              attrs: {
                                                size: "small",
                                                id: "nested-detail-send-to-approve",
                                                inline: "",
                                                primary: "",
                                                iconName: "icon-btn-success",
                                                iconClass: "icon-btn-success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sendToApprove(
                                                    rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "DirectOrders.sendToApprove"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      rowData.status !== 2 &&
                                      rowData.approvalStatus === 2 &&
                                      rowData.headerType === 1 &&
                                      rowData.maxApprovalLevel > 0
                                        ? _c(
                                            "Button",
                                            {
                                              staticClass: "m-right-20",
                                              attrs: {
                                                id: "nested-detail-send-to-approve",
                                                inline: "",
                                                primary: "",
                                                size: "small",
                                                iconName: "icon-btn-success",
                                                iconClass: "icon-btn-success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.completeOrder(
                                                    rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "DirectOrders.completeOrder"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1725013444
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("router-view", {
                  on: {
                    getDirectOrdersList: _vm.getDirectOrdersList,
                    showDrafts: function ($event) {
                      _vm.showDrafts = true
                    },
                  },
                }),
                _vm.isExportPopupVisible
                  ? _c("exportPopup", {
                      ref: "exportPopupTemplate",
                      attrs: {
                        templateId: _vm.templateId,
                        templateType: _vm.templateType,
                      },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEmptyDirectOrders && !_vm.isFetchingList
            ? _c("TableFoot", {
                attrs: { page: _vm.Page },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }