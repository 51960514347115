var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { staticClass: "Page-margin" },
    [
      _c("template", { slot: "header-left-dropdown" }, [
        _c("div", { staticClass: "PageUserGroups border-none" }, [
          _vm.userGroupList.length > 3
            ? _c("div", { staticClass: "PageUserGroups-lists" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("customSelect", {
                      attrs: {
                        selectedEmitValue: "id",
                        optionData: _vm.userGroupList,
                        optionTitle: "name",
                        componentName: "authorization-select",
                        actions: ["edit", "remove"],
                      },
                      on: {
                        inputSelected: _vm.onInputSelected,
                        onItemActionEdit: _vm.openEditUserGroupPopup,
                        onItemActionRemove: _vm.openRemoveUserGroupPopup,
                      },
                      model: {
                        value: _vm.selectedGroup,
                        callback: function ($$v) {
                          _vm.selectedGroup = $$v
                        },
                        expression: "selectedGroup",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                { staticClass: "PageUserGroups-lists m-top-10" },
                [
                  _c("TitleBox", {
                    staticClass: "item-node",
                    attrs: {
                      title: this.$t("Global.Select_Default_Text"),
                      componentName: "authorization-title-box-all",
                      isArrowIconVisible: false,
                      actions: ["view"],
                    },
                    on: { delegateOnItemView: _vm.onInputSelected },
                  }),
                  _vm._l(_vm.userGroupList, function (userGroup, index) {
                    return _c("TitleBox", {
                      key: index,
                      staticClass: "item-node",
                      attrs: {
                        data: userGroup,
                        title: userGroup.name,
                        boxIndex: index,
                        componentName: "authorization-title-box",
                        actions: ["edit", "remove", "view"],
                        isArrowIconVisible: false,
                      },
                      on: {
                        delegateOnItemEdit: _vm.openEditUserGroupPopup,
                        delegateOnItemRemove: _vm.openRemoveUserGroupPopup,
                        delegateOnItemView: _vm.onInputSelected,
                      },
                    })
                  }),
                ],
                2
              ),
        ]),
      ]),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isUserGroupsEmpty && !_vm.isLoading,
                  expression: "!isUserGroupsEmpty && !isLoading",
                },
              ],
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-user-groups-add",
                type: "button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.openNewUserGroupPopup },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("Authorization.addUserGroupButton"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
              attrs: {
                id: "btn-user-add",
                primary: "",
                type: "button",
                size: "small",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.openNewUserPopup },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("Authorization.addUsersButton"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading
            ? _c("TableLoading")
            : _c(
                "div",
                { staticClass: "PageTable" },
                [
                  _vm.filteredUserList.length === 0
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t("Global.emptyState_Title", {
                            field: _vm.$t("Authorization.Users_not_found"),
                          }),
                          description: _vm.$t("Global.emptyState_Description", {
                            field: _vm.$t("Authorization.Users_not_found"),
                          }),
                          buttonText: _vm.$t(
                            "Authorization.popupUserForm_Title_Create"
                          ),
                        },
                        on: { emptyAddButton: _vm.openNewUserPopup },
                      })
                    : _c("TableView", {
                        attrs: {
                          componentName: "authorization",
                          fields: _vm.fields,
                          data: _vm.filteredUserList,
                          actions: ["edit"],
                        },
                        on: {
                          delegateOnItemEdit: _vm.openEditUsersPopup,
                          delegateOnItemRemove: _vm.openEditUsersPopup,
                        },
                      }),
                  _c("portal", {
                    attrs: { to: "status" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            { staticClass: "switch-button" },
                            [
                              row.type !== 3
                                ? _c("SwitchButton", {
                                    attrs: {
                                      active: row.isActive,
                                      id: `btn-authorization-switch-status`,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeActivationStatus(row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("portal", {
                    attrs: { to: "emailAddress" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            { staticClass: "email-confirmation" },
                            [
                              row.emailConfirmed
                                ? _c("Icon", {
                                    attrs: { name: "icon-success" },
                                  })
                                : _c("Icon", {
                                    staticClass: "tooltip-relative",
                                    attrs: { name: "icon-pending" },
                                  }),
                              _c("span", { staticClass: "m-left-10" }, [
                                _vm._v(_vm._s(row.emailAddress) + " "),
                              ]),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("portal", {
                    attrs: { to: "role" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c("span", {}, [
                            _vm._v(_vm._s(row.roleList[0].name)),
                          ])
                        },
                      },
                    ]),
                  }),
                  _c("router-view", { on: { getUserList: _vm.getLists } }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }