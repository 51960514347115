var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.recipeGroup_IsEmpty && !_vm.isLoading,
                  expression: "!recipeGroup_IsEmpty && !isLoading",
                },
              ],
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-recipe-groups-add",
                type: "button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.itemNew },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("RecipeGroups.addRecipeGroupButton"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "RecipeGroups" },
          [
            _vm.isLoading ? _c("TableLoading") : _vm._e(),
            _vm.recipeGroup_IsEmpty && !_vm.isLoading
              ? _c("EmptyState", {
                  attrs: {
                    title: _vm.$t("RecipeGroups.emptyState_Title"),
                    description: _vm.$t("RecipeGroups.emptyState_Description"),
                    buttonText: _vm.$t("RecipeGroups.emptyState_Button"),
                  },
                  on: { emptyAddButton: _vm.itemNew },
                })
              : _vm._e(),
            !_vm.recipeGroup_IsEmpty && !_vm.isLoading
              ? _c(
                  "div",
                  {
                    staticClass: "Boxlist",
                    attrs: { id: "recipe-groups-box-list" },
                  },
                  _vm._l(_vm.recipeGroup_List, function (recipeGroup, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "Boxlist-item",
                        attrs: { id: "recipe-groups-box-list-item-" + index },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "Boxlist-actions",
                            attrs: {
                              id:
                                "recipe-groups-box-list-item-actions-" + index,
                            },
                          },
                          [
                            _c("ActionButton", {
                              attrs: {
                                data: ["edit", "remove"],
                                item: recipeGroup,
                              },
                              on: {
                                onItemEdit: function ($event) {
                                  return _vm.itemEdit(recipeGroup)
                                },
                                onItemDelete: function ($event) {
                                  return _vm.itemRemove(recipeGroup)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "Boxlist-body",
                            attrs: {
                              id: "recipe-groups-box-list-item-body-" + index,
                            },
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass: "title",
                                attrs: {
                                  id:
                                    "recipe-groups-box-list-item-title-" +
                                    index,
                                },
                              },
                              [_vm._v(_vm._s(recipeGroup.name))]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("router-view"),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }