<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons.z-index-xs(
      :selectedList = "PageLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-content")
    Button(
      primary
      size="medium"
      v-show="canBeAdded && !IsEmptyFieldsList",
      @click="addNewOptionalFields",
      iconName="icon-global-add"
    )
      span {{ $t('OptionalFields.newOptionalField') }}
  template(slot="content-center")
    EmptyState(
    :title="$t('OptionalFields.emptyState_Title')",
    :description="$t('OptionalFields.emptyState_Description')",
    :buttonText="$t('OptionalFields.newOptionalField')",
    v-if="!isFetchingList && IsEmptyFieldsList"
    @emptyAddButton="addNewOptionalFields")

    TableLoading(v-if="isFetchingList")
    .list(v-if="!isFetchingList && !IsEmptyFieldsList")
      .custom-table
        .row.header#OptionalFieldRowHeader
          .col {{ $t('OptionalFields.field_name') }}
          .col {{ $t('OptionalFields.filed_type_short') }}
          .col {{ $t('OptionalFields.field_module_name') }}
          .col {{ $t('OptionalFields.field_validate_required') }}
          .col {{ $t('OptionalFields.field_validate_unique') }}
          .col.actions
        .row(
          v-for="(item,index) in OptionalFields",
          :key="item.id",
          :id="`OptionalFieldRow${index}`"
        )
          .col {{ item.columnName }}
          .col {{ customInput(item.columnType) }}
          .col {{ getModuleName(item.tableType) }}
          .col
            span(v-if="item.columnType === 1 || item.columnType === 3",
              :class="item.isRequired ? 'c-success' : 'c-warning' ")
              Icon(:name="validateIcon(item.isRequired)")
            span(v-else) -
          .col
            span(v-if="item.columnType === 1",
              :class="item.isUnique ? 'c-success' : 'c-warning' ")
              Icon(:name="validateIcon(item.isUnique)")
            span(v-else) -

          .col.actions
            ActionButton(
              id="request-order-list-action-button"
              :data="['edit', 'remove']"
              :item="item"
              @onItemDelete="onItemDelete(item)"
              @onItemEdit="onItemEdit(item)"
            )
    router-view
  
  template(slot="footer")
    TableFoot(
    :page="Page"
    @delegateOnChangePage="changePage"
    @delegateOnChangePageSize="changePageSize")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getModules, getInputTypes } from './data/index'
//
import TableFoot from '@/view/global/table/foot.vue'

export default {
  name: 'OptionalFields',
  data () {
    return {
      inputTypes: getInputTypes(this.$t),
      ModuleTypes: getModules(this.$t),
    }
  },
  components: {
    TableFoot
  },
  computed: {
    ...mapGetters('OptionalFields', [
      'OptionalFields',
      'IsEmptyFieldsList',
      'Page',
      'PageLinks'
    ]),
    isFetchingList () {
      return this.$wait.is('fetchOptionsFields')
    },
    canBeAdded () {
      return this.OptionalFields.length < 10
    },
    getModuleName () {
      return (type) => {
        return this.ModuleTypes.find(table => table.value === type).label
      }
    },
    validateIcon () {
      return (status) => {
        return status ? 'icon-custom-checkbox-tick' : 'icon-notify-close'
      }
    },
    customInput () {
      return (type) => {
        return this.inputTypes.find(input => input.value === type).label
      }
    }
  },
  methods: {
    ...mapActions('OptionalFields', ['fetchOptionsFields']),

    addNewOptionalFields () {
      this.$router.push({
        name: 'NewOptionalField'
      })
    },

    onItemDelete (item) {
      this.$router.push({
        name: 'DeleteOptionalField',
        params: { id: item.id }
      })
    },

    onItemEdit (item) {
      this.$router.push({
        name: 'EditOptionalField',
        params: { id: item.id }
      })
    },

    changePage (pageNumber) {
      this.getList(pageNumber)
    },

    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    async getList (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        pageNumber,
        pageSize
      }
      await this.fetchOptionsFields(payload)
    }
  },
  beforeMount () {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>

  .custom-table {
    .row {
      grid-template-columns: repeat(6, 1fr);
      background-color: $color-white;
      &:nth-child(odd) {
        background-color: $color-snow;
      }
      &:hover {
        background-color: $color-ocean;
      }
      &.header {
        background-color: $color-ocean;
      }
    }
  }
</style>
