var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { staticClass: "production" },
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.PageLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-dropdown" },
            [
              _c("CustomDatepicker", {
                staticClass: "m-right-10",
                attrs: {
                  id: "ProductionDatepicker",
                  isLoading: _vm.$wait.is("getDateFilterSettings"),
                  range: true,
                  disabled: _vm.isFetchingData,
                },
                model: {
                  value: _vm.dates,
                  callback: function ($$v) {
                    _vm.dates = $$v
                  },
                  expression: "dates",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyProductionList
            ? _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    iconName: "icon-global-add",
                    id: "add-production-header-button",
                  },
                  on: { click: _vm.addNewProduction },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.addData", {
                          field: _vm.$t("Dictionary.Production"),
                        })
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeFilterComponent", {
            ref: "productionStoreFilter",
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "show-drafts m-left-15" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-production-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                { staticClass: "Production" },
                [
                  _vm.isFetchingData ? _c("TableLoading") : _vm._e(),
                  !_vm.isFetchingData && _vm.isEmptyProductionList
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t("Global.emptyState_Title", {
                            field: _vm.$t("Dictionary.Production"),
                          }),
                          description: _vm.$t("Global.emptyState_Description", {
                            field: _vm.$t("Dictionary.Production"),
                          }),
                          buttonText: _vm.$t("Global.addData", {
                            field: _vm.$t("Dictionary.Production"),
                          }),
                        },
                        on: { emptyAddButton: _vm.addNewProduction },
                      })
                    : _vm._e(),
                  !_vm.isFetchingData && !_vm.isEmptyProductionList
                    ? _c(
                        "div",
                        [
                          _c("NestedTable", {
                            staticClass: "production",
                            attrs: {
                              tableKey: "production",
                              fields: _vm.productionHeaderFields,
                              detailFields: _vm.productionDetailFields,
                              isDetailLoading: _vm.isDetailLoading,
                              actions: ["edit", "delete", "export"],
                              detailActions: ["edit", "delete"],
                              usePortalFooter: true,
                              selectable: "checkbox",
                              selectableAll: "checkbox",
                              selectedRows: _vm.selectedList,
                              isLoadingFinalize: _vm.isFinalizeLoading,
                              data: _vm.Productions,
                            },
                            on: {
                              showDetailAction: _vm.showDetailAction,
                              editHeaderAction: _vm.editHeaderProduction,
                              deleteHeaderAction: _vm.removeHeader,
                              deleteDetailAction: _vm.removeDetail,
                              editDetailAction: _vm.editDetailItem,
                              detailChangeAction: _vm.updateDetailItem,
                              showExportPopup: _vm.showExportPopup,
                              selectItem: _vm.onSelectProduction,
                              selectAllItems: _vm.onSelectProductionAll,
                            },
                          }),
                          _c("portal", {
                            attrs: { to: "description" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (header) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        header.row.description
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass:
                                                  "show-MiniPopup relative width-max-content",
                                                attrs: {
                                                  size: "medium",
                                                  variant: "info",
                                                  id: `production-header-info-button-${header.rowIndex}`,
                                                  iconName: "icon-global-info",
                                                  justIcon: true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "MiniPopup-right-primary m-top-15 c-dark",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          header.row.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              4209351555
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "unitName" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (detail) {
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.findUnit(detail.row.unitId).name
                                          )
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              2502919526
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "footerPortal" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (rowData) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "popup-container flexbox",
                                      },
                                      [
                                        _vm.isExpireDateControlEnabled(rowData)
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass: "m-right-20",
                                                attrs: {
                                                  id: "btn-production-expire-date-edit",
                                                  type: "button",
                                                  size: "small",
                                                  secondary: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editExpireDate(
                                                      rowData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Transfer.editExpireDate"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          [
                                            rowData.status === 1
                                              ? _c(
                                                  "Button",
                                                  {
                                                    staticClass: "m-right-20",
                                                    attrs: {
                                                      id: "nested-detail-production-add-button",
                                                      size: "small",
                                                      inline: "",
                                                      primary: "",
                                                      iconName:
                                                        "icon-global-add",
                                                      iconClass:
                                                        "icon-global-add",
                                                      disabled:
                                                        _vm.isOnlyListUsage,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addNewDetail(
                                                          rowData
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Global.addNewStock"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            rowData.status === 1
                                              ? _c(
                                                  "Button",
                                                  {
                                                    staticClass: "m-right-20",
                                                    attrs: {
                                                      id: "production-nested-detail-finalize",
                                                      inline: "",
                                                      size: "small",
                                                      primary: "",
                                                      iconName:
                                                        "icon-btn-success",
                                                      iconClass:
                                                        "icon-btn-success",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.productionSaved(
                                                          rowData
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Global.Save")
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              2758567293
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
          _c("router-view", {
            on: {
              getProductionsList: _vm.getProductionsList,
              showDrafts: function ($event) {
                _vm.showDrafts = true
              },
            },
          }),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
          _c("ExpireDate", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showExpireDateEdit,
                expression: "showExpireDateEdit",
              },
            ],
            attrs: {
              data: _vm.BatchList,
              productionHeader: _vm.productionHeader,
            },
            on: {
              editExpireDate: _vm.editExpireDate,
              getUpdatedBatch: _vm.editExpireDate,
              closeModal: _vm.closeExpireDateScreen,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isFetchingData
            ? _c("TableFoot", {
                attrs: { page: _vm.Page },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }