import { render, staticRenderFns } from "./inventory-recipe.vue?vue&type=template&id=a7b996ba&scoped=true&lang=pug&"
import script from "./inventory-recipe.vue?vue&type=script&lang=js&"
export * from "./inventory-recipe.vue?vue&type=script&lang=js&"
import style0 from "./inventory-recipe.vue?vue&type=style&index=0&id=a7b996ba&prod&lang=scss&scoped=true&"
import style1 from "./inventory-recipe.vue?vue&type=style&index=1&id=a7b996ba&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7b996ba",
  null
  
)

export default component.exports