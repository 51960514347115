<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    .form-item-select.date-start
      CustomDatepicker#salesReportDatepicker(
        v-model="dates",
        :range="true"
        :disabled="isLoading || isPageLoading"
        :isLoading="$wait.is('getDateFilterSettings')"
      )

  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="groupTypes"
      :selectedItem="activeGroupType"
      @delegateChangeSelectedItem="onChangeGroupType"
      fieldKey="name"
    )

  template(slot="toolbar-left")
    CustomMultiselect.toolbar-filters__item(
      id="reports-sales-store-filter"
      :items="storeList"
      :selected="storeFilter"
      :label="$t('Reports.toolbar_Stores_Label')"
      :unselectedText="$t('Reports.toolbar_Stores_Unselected')"
      :popupHeader="$t('Reports.toolbar_Stores_PopupHeader')"
      :allLabel="$t('Reports.toolbar_Stores_AllLabel')"
      :buttonText="$t('Reports.toolbar_Stores_ButtonText')"
      buttonIconLeft="btn-success"
      @delegateCheckAll="onChangeStoreFilter"
      @delegateCheckItem="onChangeStoreFilter"
      @delegateSubmitItems="getSalesReportsWithParams")

  template(slot="toolbar-right")
    ImportExport(
      componentName="reports-sales"
      ref="wrapper"
      :isDisabled="isLoading"
      :activeImportExports=['export']
      downloadUrl="Report/sales/export"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="downloadFileName"
    )
  


  template(slot="content-center")
    .result
      TableView(
      v-if="!isLoading && !isSalesReportsEmpty"
      :fields="fieldsByType"
      :componentName="'ReportsSalesTable'"
      :readOnly="true"
      :data="salesReports")

      TableLoading(v-if="isLoading || isPageLoading")

      EmptyState(
      v-if="!isLoading  && !isPageLoading && isSalesReportsEmpty"
      :title="$t('SalesReports.emptyState_Title')"
      :description="$t('SalesReports.emptyState_Description')")
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import getSalesFields from './fields/sales'
//
import ImportExport from '@/view/global/import-export'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
export default {
  name: 'SalesReports',

  components: {
    ImportExport,
    ToggleOverGroups
  },

  data () {
    return {
      pageHeaderLinks: [
        'SalesReports',
        'IncomeStats',
        'ForecastSales'
      ],
      dates: [],
      salesFields: getSalesFields(this.$t),
      activeGroupType: {},
      groupTypes: [{
        name: this.$t('SalesReports.groupTypes_OverGroup'),
        id: 1
      }, {
        name: this.$t('SalesReports.groupTypes_MajorGroup'),
        id: 2
      }, {
        name: this.$t('SalesReports.groupTypes_ItemGroup'),
        id: 3
      }],
      storeFilter: []
    }
  },

  async mounted () {
    this.$wait.start('pageLoading')
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_SalesReport')
    this.onChangeGroupType(this.groupTypes[0])
    await this.getStores()
    this.storeFilter = [...this.storeList]
    await this.getSalesReportsWithParams()
    this.$wait.end('pageLoading')
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getSalesReportsWithParams()
    }
  },

  computed: {
    ...mapGetters('Reports', [
      'salesReports',
      'isSalesReportsEmpty'
    ]),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList'
    }),

    fieldsByType () {
      return this.salesFields[this.activeGroupType.id]
    },
    isPageLoading () {
      return this.$wait.is('pageLoading')
    },
    isLoading () {
      return this.$wait.is(['getMiniStoreList', 'getSalesReports', 'getDateFilterSettings'])
    },

    downloadParams () {
      return {
        StartDate: this.dateFormat(this.dates[0]),
        EndDate: this.dateFormat(this.dates[1]),
        ReportType: this.activeGroupType.id,
        StoreList: this.storeFilter.map(store => store.id)
      }
    },
    downloadFileName () {
      const fileName = `SalesReport_${this.dateFormat(this.dates[0])}_${this.dateFormat(this.dates[1])}`
      return fileName
    }
  },

  methods: {
    ...mapActions('Reports', [
      'getSalesReports'
    ]),
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions({
      getStores: 'Stores/getMiniStoreList'
    }),

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    onChangeGroupType (groupType) {
      this.activeGroupType = groupType
      this.getSalesReportsWithParams()
    },

    getSalesReportsWithParams () {
      this.getSalesReports({
        type: this.activeGroupType.id,
        start: this.dateFormat(this.dates[0]),
        end: this.dateFormat(this.dates[1]),
        storeList: this.storeFilter
      })
    },

    onChangeStoreFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.storeFilter = [...this.storeList]
        } else {
          this.storeFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.storeFilter = []
        } else {
          this.storeFilter.splice(this.storeFilter.indexOf(event.item), 1)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .PageHeader {
    .right {
      display: flex;
      .form-item-select {
        width: 200px;
        margin-left: 10px;
      }
    }
  }

  .PageHeaderExtra {
    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-gray;
  }

  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  .group-types {
    padding: 14px 0;
    margin-bottom: -5px;

    &-item {
      padding: 8px 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      display: inline-block;
      font-weight: $font-weight-bold;
      font-size: $font-size-micro;
      line-height: 1;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;
      user-select: none;

      &.active {
        background: $color-success;
        color: #fff;
        border-color: transparent;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .result {
    position: relative;
  }

  .PageContent {
    display: flex;
    flex-direction: column;
  }

</style>
