<template lang="pug">
Page
  template(slot="header-left-search")
    Search.search(
        componentName="Group",
        :isSuggestionLoading="false",
        @onChangeSearchValue="onChangeSearch"
      )
  template(slot="content-left")
    .group-container
      OverGroupList(
        :data="overGroupList"
        :overGroup="selectedOverGroup"
        @changeSelect="changeOverGroup($event)"
      )
      

    router-view(@getOverGroupList="getAllOverGroupList" @getMajorGroupList="getMajorGroupList")
  template(slot="content-right")
    TableLoading(v-if="isLoading")
    .major-container(v-else)
      MajorGroupList(
        :data="filteredMajorGroupList"
        :overGroups="overGroupList"
        :overGroup="selectedOverGroup"
      )
</template>

<script>
import { mapActions } from 'vuex'
import OverGroupList from './components/overgroup'
import MajorGroupList from './components/majorgroup'
import matchingSearch from '@/utils/matchingSearch'

export default {
  name: 'Group',
  components: {
    OverGroupList,
    MajorGroupList,
  },
  data () {
    return {
      overGroupList: [],
      majorGroupList: [],
      selectedOverGroup: null,
      search: ''
    }
  },
  watch: {
    selectedOverGroup () {
      this.getMajorGroupList()
    }
  },
  async created () {
    this.getTaxes()
    this.getAllOverGroupList()
  },
  computed: {
    filteredMajorGroupList () {
      return this.majorGroupList.filter(item => {
        return matchingSearch(item.name, this.search) || matchingSearch(item.itemGroupList.map(i => i.name), this.search)
      })
    },
    isLoading () {
      return this.$wait.is(['getGroupTree', 'getOverGroupAll'])
    }
  },
  methods: {
    ...mapActions('Group', ['getGroupTree', 'getOverGroupAll']),
    ...mapActions('Tax', ['getTaxes']),

    changeOverGroup (event) {
      this.selectedOverGroup = event
    },
    onChangeSearch (search) {
      this.search = search
    },
    getAllOverGroupList () {
      this.getOverGroupAll().then(res => {
        this.overGroupList = !res ? null : res.data.overGroupList
        this.selectedOverGroup = this.overGroupList[0]
      }
      )
    },
    getMajorGroupList () {
      this.getGroupTree(this.selectedOverGroup.id).then(res => {
        if (!res) return
        this.majorGroupList = res.data.overGroupList[0].majorGroupList
        this.majorGroupList.length === 0
          ? this.selectedOverGroup.hasMajorGroup = false
          : this.selectedOverGroup.hasMajorGroup = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep() .title-box{
    flex-wrap: nowrap !important;
  }
  :deep() .Header{
    margin-bottom: 0px !important;
  }
  .group-container {
    display: flex;
    & .major-container{
      width: 100%;
      padding-left: 25px;
      padding-top: 20px;
    }
  }
  :deep() .wrap{
    position: unset !important;
    & > .loading {
      top: 40px;
      left: calc(60% - 150px);
    }
  }
  :deep(.Content){
    display: flex;
  } 
</style>
