/**
* @param accountingList
 status =    {
       None = 0,
       InQueue = 1,
       Sent = 2,
       Failed = 3,
       InProgress = 4,
   }
*/

/**
* @param invoiceTypeFilter
  InvoiceType = {
    Purchase = 1,
    Return = 2,
  }
*/

export const getAccountingList = ($t) => {
  return [
    {
      value: 0,
      name: $t('Despatches.Accounting_value_0')
    },
    {
      value: 1,
      name: $t('Despatches.Accounting_value_1')
    },
    {
      value: 2,
      name: $t('Despatches.Accounting_value_2')
    },
    {
      value: 3,
      name: $t('Despatches.Accounting_value_3')
    },
    {
      value: 4,
      name: $t('Despatches.Accounting_value_4')
    }
  ]
}

export const invoiceTypeFilter = ($t) => {
  return [
    {
      value: 1,
      name: $t('Despatches.Type_Purchase')
    },
    {
      value: 2,
      name: $t('Despatches.Type_Return')
    }
  ]
}
