var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select date-start" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "WasteReportDatepicker",
                range: true,
                isLoading: _vm.$wait.is("getDateFilterSettings"),
                disabled: _vm.isWasteReportsLoading || _vm.isPageLoading,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.groupTypes,
              selectedItem: _vm.activeGroupType,
              isNotActiveDisabled: false,
              fieldKey: "name",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeGroupType },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            staticClass: "toolbar-filters__item",
            attrs: {
              id: "waste-reports-store-filter",
              items: _vm.storeList,
              selected: _vm.storeFilter,
              label: _vm.$t("Global.toolbar_Store_Label"),
              unselectedText: _vm.$t("Global.toolbar_Stores_AllLabel"),
              popupHeader: _vm.$t("Global.toolbar_Stores_PopupHeader"),
              allLabel: _vm.$t("Global.toolbar_Stores_AllLabel"),
              buttonText: _vm.$t("Global.toolbar_Stores_ButtonText"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckAll: _vm.onChangeStoreFilter,
              delegateCheckItem: _vm.onChangeStoreFilter,
              delegateSubmitItems: _vm.getWasteReportsWithParams,
            },
          }),
          _c("div", { staticClass: "toolbar__separator" }),
          _c(
            "Button",
            {
              staticClass: "wasteGroup-switch",
              attrs: {
                variant: "icon switch",
                id: "btn-waste-reports-wasteGroup-switch",
                type: "button",
              },
              on: { click: _vm.onSwitchIncludeWasteGroup },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "icon",
                  class: _vm.includeWasteGroup ? "eye-open" : "eye-closed",
                },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href": _vm.includeWasteGroup
                        ? "#icon-login-eye"
                        : "#icon-login-eye-closed",
                    },
                  }),
                ]
              ),
              _vm._v(_vm._s(_vm.$t("WasteReports.toolbar_WasteGroup"))),
            ]
          ),
          _vm.activeGroupType && _vm.includeWasteGroup
            ? _c("div", { staticClass: "toolbar__separator" })
            : _vm._e(),
          _vm.activeGroupType && _vm.includeWasteGroup
            ? _c("CustomCheckbox", {
                staticClass: "wasteGroup-primary-checkbox",
                attrs: {
                  size: "large",
                  id: "checkbox-waste-reports-wasteGroup-primary",
                  label: _vm.$t("WasteReports.toolbar_WasteGroupPrimary"),
                },
                on: { change: _vm.onChangeWasteGroupPrimary },
                model: {
                  value: _vm.isWasteGroupPrimary,
                  callback: function ($$v) {
                    _vm.isWasteGroupPrimary = $$v
                  },
                  expression: "isWasteGroupPrimary",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            attrs: {
              componentName: "waste-reports",
              isDisabled: _vm.isPageLoading,
              activeImportExports: ["export"],
              downloadParams: _vm.downloadParams,
              downloadUrl: "Report/waste/export",
              downloadFileName: _vm.downloadFileName,
              downloadFileType: "xlsx",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "wasteReports" }, [
          _c(
            "div",
            { staticClass: "results" },
            [
              _vm.isWasteReportsLoading || _vm.isPageLoading
                ? _c("TableLoading")
                : _vm._e(),
              !_vm.isWasteReportsLoading &&
              !_vm.isWasteReportsEmpty &&
              !_vm.isPageLoading
                ? _c("TableView", {
                    attrs: {
                      fields: _vm.wasteFields,
                      readOnly: true,
                      componentName: "WasteTable",
                      data: _vm.wasteReports,
                    },
                  })
                : _vm._e(),
              !_vm.isWasteReportsLoading &&
              _vm.isWasteReportsEmpty &&
              !_vm.isPageLoading
                ? _c("EmptyState", {
                    attrs: {
                      title: _vm.$t("WasteReports.Waste_EmptyState_Title"),
                      description: _vm.$t(
                        "WasteReports.Waste_EmptyState_Description"
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }