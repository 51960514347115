<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-content")

    Button(
    primary
    size="medium"
    id="btn-recipe-groups-add"
    type="button"
    v-show="!recipeGroup_IsEmpty && !isLoading"
    iconName="icon-global-add"
    iconClass="icon-global-add"
    @click="itemNew")
      span {{ $t('RecipeGroups.addRecipeGroupButton') }}

    // PAGE CONTENT /////////////////////////////

  template(slot="content-center")

    .RecipeGroups
      // LOADING

      TableLoading(v-if="isLoading")

      // EMPTY PAGE

      EmptyState(
      v-if="recipeGroup_IsEmpty && !isLoading",
      :title="$t('RecipeGroups.emptyState_Title')",
      :description="$t('RecipeGroups.emptyState_Description')",
      :buttonText="$t('RecipeGroups.emptyState_Button')",
      @emptyAddButton="itemNew")

      // LIST

      .Boxlist(v-if="!recipeGroup_IsEmpty && !isLoading" id="recipe-groups-box-list")
        .Boxlist-item(
          v-for="(recipeGroup, index) in recipeGroup_List"
          :id="'recipe-groups-box-list-item-' + index")
          .Boxlist-actions(
            :id="'recipe-groups-box-list-item-actions-' + index"
          )
            ActionButton(
              :data="['edit', 'remove']"
              :item="recipeGroup"
              @onItemEdit="itemEdit(recipeGroup)"
              @onItemDelete="itemRemove(recipeGroup)"
            )
          .Boxlist-body(:id="'recipe-groups-box-list-item-body-' + index")
            h3.title(:id="'recipe-groups-box-list-item-title-' + index") {{ recipeGroup.name }}

      // POPUPS

      router-view

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//

export default {
  name: 'RecipeGroups',
  async mounted () {
    await this.get_RECIPEGROUP_LIST()
  },

  computed: {
    ...mapGetters('RecipeGroups', ['recipeGroup_List', 'recipeGroup_IsEmpty']),
    ...mapGetters('Recipes', ['pageHeaderLinks']),
    isLoading () {
      return this.$wait.is(['get_RECIPEGROUP_LIST'])
    }
  },

  methods: {
    ...mapActions('RecipeGroups', [
      'get_RECIPEGROUP_LIST'
    ]),

    itemNew () {
      this.$router.push({
        name: 'RecipeGroupNew'
      })
    },

    itemEdit (item) {
      this.$router.push({
        name: 'RecipeGroupEdit',
        params: {
          id: item.id,
          item
        }
      })
    },

    itemRemove (item) {
      this.$router.push({
        name: 'RecipeGroupRemove',
        params: {
          item: item,
          id: item.id
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

  .title {
    width: 80%;
  }
</style>
