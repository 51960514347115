<template lang="pug">
Page
  template(slot="header-title")
    span {{ $t('Yield.pageHeader_Yield') }}
  template(slot="content-center")
    TableLoading(v-if="isLoading")
    .Result.m-bottom-15
      .Result-top-header-title
        .doc-number {{documentNumber}}
        span.m-left-5 {{$t('Despatches.document')}}

      TableView.m-top-15(
        :componentName="'yieldDocumentDetail'"
        :fields="yieldDetailFields"
        :actions="['']"
        :data="yieldDetail"
        :readOnly="true"
      )

</template>

<script>

import { getYieldDetailFields } from './fields/index'
import { mapActions } from 'vuex'

export default {
  name: 'YieldDocumentDetail',

  data () {
    return {
      yieldDetailFields: getYieldDetailFields(this.$t),
      yieldDetail: []
    }
  },

  computed: {
    isLoading () {
      return this.$wait.is(['getYieldDocumentDetail'])
    },
    documentNumber () {
      return this.$route.params.documentNumber
    }
  },
  async mounted () {
    await this.getYieldDetail(this.$route.params.id)
  },
  methods: {
    ...mapActions('Yield', ['getYieldDocumentDetail']),

    async getYieldDetail (item) {
      await this.getYieldDocumentDetail(item).then(res => {
        if (res) {
          this.yieldDetail = res.data.yieldDetailList
        }
      })
    }
  }
}
</script>

<style lang="scss">

.Result{
  &-top-header-title{
    color: $color-primary;
    display: flex;
  }
}
.doc-number {
  font-weight: bold;
  text-decoration: underline;
}
</style>
