var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-title" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t("NutritionalValues.breadcrumb_NutritionalValues"))
          ),
        ]),
      ]),
      _c("template", { slot: "header-breadcrumb-left" }, [
        _c(
          "div",
          { staticClass: "Breadcrumb m-top-5" },
          [
            _c(
              "router-link",
              {
                staticClass: "m-right-5",
                attrs: {
                  id: "link-recipe-breadcrumb",
                  to: { name: "NutritionalValues" },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("NutritionalValues.breadcrumb_NutritionalValues")
                  )
                ),
              ]
            ),
            _c("span", { staticClass: "nutrition-name" }, [
              _vm._v(_vm._s(" > " + _vm.selectedNutrition)),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.PageLinks,
              translationGroup: "TabItems",
              matchedRoute: "NutritionalValues",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList
            ? _c("TableLoading")
            : _c("TableView", {
                attrs: {
                  data: _vm.NutritionDetails,
                  fields: _vm.fields,
                  readOnly: true,
                  componentName: "NutritionDetailTable",
                },
              }),
          _c("portal", {
            attrs: { to: "value" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c("div", {}, [
                    !_vm.isCustom
                      ? _c("div", [_vm._v(_vm._s(row.value))])
                      : _c(
                          "div",
                          [
                            _c("customNumberInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "greater_than:-1",
                                  expression: "'greater_than:-1'",
                                },
                              ],
                              attrs: {
                                name: "nutritionValue",
                                id: "nutrition-detail-value",
                                "data-vv-as": _vm.$t(
                                  "NutritionalValues.placeholder_unitValue"
                                ),
                                placeholder: _vm.$t(
                                  "NutritionalValues.placeholder_unitValue"
                                ),
                                error: _vm.veeErrors.has("nutritionValue"),
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.updateCustomValue(row)
                                },
                              },
                              model: {
                                value: row.value,
                                callback: function ($$v) {
                                  _vm.$set(row, "value", $$v)
                                },
                                expression: "row.value",
                              },
                            }),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("nutritionValue"),
                                  expression: "veeErrors.has('nutritionValue')",
                                },
                              ],
                              attrs: {
                                errorName:
                                  _vm.veeErrors.first("nutritionValue"),
                              },
                            }),
                          ],
                          1
                        ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }