<template lang="pug">
Page
  template(slot="header-right-dropdown" v-if="hasTabPermission")
    .right
      CustomDatepicker#YieldDatepicker.m-right-10(
        v-model="dates",
        :range="true"
        :disabled="isFetchingData"
      )
  template(slot="header-right-content" v-if="hasTabPermission")
    Button(
      primary,
      size="medium"
      iconName="icon-global-add",
      @click="addNewYieldHeader"
      id="add-yield-header-button")
      span {{ $t('Yield.add_yield_header') }}

  template(slot="toolbar-left")
    storeFilterComponent.store-filter(
    @submitFilter="getListWithStoreFilter"
    ref="yieldStoreFilter")
    Button.clear-filter-button.border-gray-1.p-0.width-auto(
    @click='clearFilter'
      )
      Icon.icon-clear-filter(name="icon-clear-filter")
    .show-drafts.m-left-15
      CustomCheckbox.popup-item-check(
      id="checkbox-yield-show-drafts"
      :checked="showDrafts"
      :label="$t('Global.showDrafts')"
      @change="changeShowDraftsStatus")

  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    .Yield(v-else)
      TableLoading(v-if="isFetchingData")

      EmptyState(
        v-if="!isFetchingData && isFetchDataEmpty",
        :title="$t('Yield.inventories_emptyState_NotFound_Title')",
        :description="$t('Yield.inventories_emptyState_NotFound_Description')",
        :buttonText="$t('Yield.add_new_header')",
        @emptyAddButton="addNewYieldHeader")

      div(v-if="!isFetchingData && !isFetchDataEmpty")
        NestedTable(
          tableKey="yield",
          :fields="yieldHeaderFields",
          :detailFields="yieldDetailFields",
          :data="Yields",
          :isDetailLoading="isDetailLoading"
          :actions="['edit','delete','customAction']",
          :detailActions="['edit','delete']"
          :addButtonText="$t('Yield.detail_NewStockItemButton')",
          :finalizeButtonText="$t('Yield.yieldFinalizeButton')",
          :isLoadingFinalize="$wait.is('yieldSaved')"
          :disableNewDetailButton="isOnlyListUsage"
          :useHeaderPortalAction="true"
          :usePortalFooter="true"
          :selectable="'checkbox'"
          :selectableAll="'checkbox'"
          :selectedRows="selectedList"
          @finalizeAction="yieldSaved",
          @editHeaderAction="editYieldHeader",
          @deleteHeaderAction="removeItemAction",
          @addDetailAction="addNewYieldDetail",
          @showDetailAction="showYieldDetail",
          @editDetailAction="yieldDetailEdit"
          @selectItem="onSelectYield"
          @selectAllItems="onSelectYieldAll"
          @deleteDetailAction="yieldDetailRemove",
        )

        portal(to="actionPortal")
          div.popup-container.flexbox(slot-scope="rows")
            ActionButton(
              :data="rows.row.expirationDateControlIsEnabled ? ['edit','delete','customAction'] : ['edit','delete'] "
              :item="rows.row"
              :customActionName="$t('Transfer.editExpirationDateShort')"
              :customActionIconName="'icon-global-edit'"
              :customActionIconClass="'icon-global-edit'"
              @onItemCustomAction="editCustomYieldHeader(rows.row)"
              @onItemDelete="removeItemAction(rows.row)"
              @onItemEdit="editYieldHeader(rows.row)"
            )

        portal(to="footerPortal")
          div.popup-container.flexbox(slot-scope="rowData")
            Button.m-right-20(
              v-if="isExpireDateControlEnabled(rowData) && rowData.status === 1"
              :disabled="!rowData.detailList.length"
              id="btn-yield-expire-date-edit"
              type="button"
              size="small"
              secondary
              @click="editExpireDate(rowData)")
                span {{ $t('Transfer.editExpireDate') }}
        
            div
              Button.m-right-20(
                id="nested-detail-yield-add-button",
                v-if="rowData.status === 1"
                size="small",
                inline,
                primary,
                iconName="icon-global-add",
                iconClass="icon-global-add",
                :disabled="isOnlyListUsage"
                @click="addNewYieldDetail(rowData)")
                  span {{ $t('Global.addNewStock') }}

            div
              Button.m-right-20(
              v-if="rowData.status === 1"
              id="yield-nested-detail-finalize",
              inline,
              size="small"
              primary,
              iconName="icon-btn-success",
              iconClass="icon-btn-success",
              @click="yieldSaved(rowData)")
                span {{ $t('Global.Save') }}

        // pagination
        TableFoot(
          :page="Page",
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize")

      // Header Modal
      div 
        Modal(:isOpen="showBatchHeader" :showCloseButton="false")
          template(slot="title")
            .modal-header
              .modal-header-left
                .transfer
                  .transfer-code {{ $t('Yield.documentNumber') }}: {{ yieldHeader?.code }}
                  .transfer-date {{ $t('Yield.documentDate') }}: {{ getFormattedDate(yieldHeader)}}

          template(slot="content")
            NestedTable.custom-nested-table(
              :fields="yieldBatchHeaderFields",
              :detailFields="yieldBatchDetailFields",
              :data="BatchHeaderList",
              :readOnly="true"
              @showDetailAction="showBatchHeaderDetailAction",
            )

            portal(to="givenQuantity")
              div(slot-scope="detail")
                .control.quantity-input
                  input.txt.no-spinners(
                    name="givenQuantity"
                    :data-vv-as="$t('Despatches.detail_TableColumn_Quantity')"
                    :class="{ 'is-danger': veeErrors.has(`transfer${detail.rowIndex}.givenQuantity`) }"
                    v-validate="{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}"
                    type="number"
                    step="any"
                    v-model="detail.row.givenQuantity"
                    @blur="updateGivenBatchQuantity($event, detail.row)"
                  )
            portal(to="warehouseTime")
              div(slot-scope="detail")
                span {{ detail.row.warehouseTime?.substr(0,5) || '-' }}
            
            portal(to="shipmentTime")
              div(slot-scope="detail")
                span {{ detail.row.shipmentTime?.substr(0,5) || '-' }}

          template(slot="footer")
            .batch-footer
              Button.m-right-10(
                id="transfer-batch-detail-cancel"
                size="small"
                secondary
                inline
                @click="closeModal")
                span {{ $t('Global.Cancel') }}
              
              Button(
                id="transfer-batch-detail-save"
                size="small"
                primary
                inline
                @click="saveBatchHeader")
                span {{ $t('Global.Save') }}


    router-view(
      @getYieldHeaderList="getYieldList"
      @showDrafts="showDrafts = true"
    )
    ExpireDate(
      v-show="showExpireDateEdit"
      :data="BatchList"
      :yieldHeader="yieldHeader"
      @editExpireDate="editExpireDate"
      @getUpdatedBatch="editExpireDate"
      @closeModal="closeExpireDateScreen"
    )
    Alert(
      v-if="showBatchAlert"
      :title="$t('Transfer.warning_batch')"
      :hasCancelButton="false"
      :description="$t('Despatches.description_batch_warning')"
      :okButton="$t('Global.okay')"
      icon="icon-global-notify"
      loading="alertForm"
      @delegateOnAlertOk="showBatchAlert = false"
    )

</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import moment from 'moment'

import { getYieldHeaderFields, getYieldDetailFields } from './fields/index'
import hasTabPermission from '@/mixins/hasTabPermission'
import { vueWaitLoader } from '@/utils/baseOperations'

import storeFilterComponent from '@/view/global/business-filters/store'
import TableFoot from '@/view/global/table/foot'
import NoPermission from '@/view/global/has-not-permission'
import ExpireDate from './components/expire-date-table'

import { getYieldBatchHeaderFields, getYieldBatchDetailFields } from './fields/yield-batch'



export default {
  name: 'yield',
  mixins: [hasTabPermission],
  data () {
    return {
      yieldHeaderFields: getYieldHeaderFields(this.$t),
      yieldDetailFields: getYieldDetailFields(this.$t),
      yieldBatchHeaderFields: getYieldBatchHeaderFields(this.$t),
      yieldBatchDetailFields: getYieldBatchDetailFields(this.$t),
      storeList: [],
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ],
      isOnlyListUsage: false,
      showDrafts: true,
      showBatchHeader: false,
      showExpireDateEdit: false,
      expireDateData: {},
      yieldHeader: {},
      yieldHeaderList: [],
      yieldDetailList: [],
      showBatchDetail: false,
      showBatchAlert: false,
      selectedList: []
    }
  },

  components: {
    storeFilterComponent,
    TableFoot,
    NoPermission,
    ExpireDate
  },

  async mounted () {
    if (!this.hasTabPermission) return
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    await this.setShowDraftStatus()
    await this.getStoreList({pageNumber: 1, pageSize: 9999})
    await this.getYieldList()
  },

  computed: {
    ...mapGetters('Yield', [
      'Yields',
      'isFetchDataEmpty',
      'Page',
      'BatchList',
      'BatchHeaderList'
    ]),

    ...mapGetters('Settings', [
      'permissions'
    ]),

    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    isFetchingData () {
      return this.$wait.is(['fetchYieldHeaderAll', 'getStoreList'])
    },

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    isDetailLoading () {
      return this.$wait.is(['fetchYieldDetailAll'])
    }
  },

  methods: {
    ...mapActions('Yield', ['fetchYieldHeaderAll', 
    'fetchYieldDetailAll', 
    'finalizeYield',
    'getExpireDateData',
    'getBatchYieldHeaderData',
    'fetchBatchYieldHeaderAll',
    'updateBatchHeaderQuantity',
    'validateBatchHeader'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('Yield', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'SHOW_BATCH_HEADER_DETAIL',
      'HIDE_BATCH_HEADER_DETAIL',
      'RESET_BATCH_HEADER_LIST'
    ]),

    ...mapMutations('Global', ['TOGGLE_MENU_MINIMALIZE','SET_SHOW_ACTION_STATUS']),

    ...mapActions('Stores', [
      'getStoreList'
    ]),
    ...mapActions('DocumentSettings', ['getDocumentFilterSettings']),

    getFormattedDate (header) {
      if (header) return moment(header.date).format('DD-MM-YYYY')
    },

    isExpireDateControlEnabled (item) {
      const selectedsWithExpireDate = this.selectedList.filter(s => s.expirationDateControlIsEnabled && s.yieldHeaderId === item.id)
      return selectedsWithExpireDate.length > 0
    },

    onSelectYield (item) {
      if (item.isChecked) this.selectedList.push(item)
      else {
        this.selectedList = this.selectedList.filter(s => s.id !== item.id)
      }
    },

    onSelectYieldAll (detailData, isChecked) {
      if (isChecked) {
        detailData.forEach(detail => {
          this.selectedList.push(detail)
        })
      } else {
        detailData.forEach(detail => {
          const index = this.selectedList.findIndex(s => s.id === detail.id)
          this.selectedList.splice(index, 1)
        })
      }
    },
    
    getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeList = list.map(item => item.id)
      else {
        this.storeList = list.map(item => item.id)
        this.getYieldList()
      }
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getYieldList()
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_Yield') this.showDrafts = i.isChecked
      })
    },

    addNewYieldHeader () {
      this.$router.push({
        name: 'NewYieldHeader'
      })
    },

    clearFilter () {
      this.$refs.yieldStoreFilter.clearStoreFilter(false)
    },

    editYieldHeader (item) {
      this.$router.push({
        name: 'EditYieldHeader',
        params: {
          headerId: item.id,
          item: item
        }
      })
    },

    removeItemAction (item) {
      this.$router.push({
        name: 'deleteYieldHeader',
        params: {
          id: item.id,
          item: item
        }
      })
    },

    addNewYieldDetail (item) {
      this.$router.push({
        name: 'NewYieldDetail',
        params: {
          headerId: item.id,
          headerItem: item
        }
      })
    },

    yieldDetailEdit (item) {
      this.$router.push({
        name: 'EditYieldDetail',
        params: {
          headerId: item.yieldHeaderId,
          id: item.id,
          item: item
        }
      })
    },

    yieldDetailRemove (item) {
      this.$router.push({
        name: 'deleteYieldDetail',
        params: {
          id: item.id,
          item: item
        }
      })
    },

    showYieldDetail (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
      } else {
        this.fetchYieldDetailAll(item.id)
      }
    },

    async editExpireDate (yieldData) {
      if (yieldData) this.yieldHeader = yieldData
      const filteredList = this.selectedList.filter(s => s.yieldHeaderId === yieldData.id)
       const payload = {
        yieldDetailIds: filteredList.map(i => i.id)
      }
      await this.getExpireDateData(payload)
      this.showExpireDateEdit = true
      this.TOGGLE_MENU_MINIMALIZE(true)
    },

    closeExpireDateScreen () {
      this.showExpireDateEdit = false
    },

    editCustomYieldHeader (item) {
      this.SET_SHOW_ACTION_STATUS({prevActionValue: item, value: false})
      this.yieldHeader = item
      this.TOGGLE_MENU_MINIMALIZE(true)
      this.showBatchHeader = true
      this.getBatchYieldHeaderData({yieldHeaderId: item.id})
    },

    changePage (page) {
      this.getYieldList(null, page)
    },

    changePageSize (pageSize) {
      this.getYieldList(null, 1, pageSize)
    },

    closeModal () {
      this.showBatchHeader = false
    },

    async getYieldList (headerId = null, page = 1, pageSize = this.Page.size) {
      const payload = {
        StoreIds: this.storeList || [],
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        pageNumber: page,
        pageSize,
        showDrafts: this.showDrafts
      }
      await this.fetchYieldHeaderAll(payload)
      if (headerId) this.fetchYieldDetailAll(headerId)
    },

    yieldSaved: vueWaitLoader(async function (item) {
      const message = this.$t('Yield.finalize_Notification_message')
      await this.finalizeYield({ id: item.id })
        .then(res => {
          if (!res) return
          this.notifyShow({ message })
          this.getYieldList()
        })
    }, 'yieldSaved'),


    saveBatchHeader () {
      this.validateBatchHeader({yieldHeaderId: this.yieldHeader.id}).then(res => {
        !res.data.isValid ? this.showBatchAlert = true : this.closeModal()
      })
    },

    async updateGivenBatchQuantity (event, row) {  
      const payload = {
        id: row.id,
        yieldHeaderId: row.yieldHeaderId,
        quantity: Number(event.target.value)
      }
      await this.updateBatchHeaderQuantity(payload).then(res => {
        if (res) {
          const message = this.$t('Transfer.updated_transferBatchQuantity')
          this.notifyShow({ message })
          this.getBatchYieldHeaderData({yieldHeaderId: row.yieldHeaderId})
          this.fetchBatchYieldHeaderAll({yieldHeaderId: row.yieldHeaderId}).then(result => {
            if (result) this.SHOW_BATCH_HEADER_DETAIL({batchList: result.data.batchList, id: row.yieldHeaderId})
          })
        }
      })
    },

    async showBatchHeaderDetailAction (item) {
      if (item.showDetail) {
        this.HIDE_BATCH_HEADER_DETAIL(item)
      } else {
        await this.fetchBatchYieldHeaderAll({yieldHeaderId: item.id}).then(res => {
          if (res) {
            this.SHOW_BATCH_HEADER_DETAIL({batchList: res.data.batchList, id: item.id})
          }
        })
      }
    }

  },
  watch: {
    dates () {
      this.getYieldList()
    }
  }
}
</script>

<style lang="scss" scoped>
.batch-footer {
  display: flex;
  justify-content: end;
}
:deep(.Modal) {
  &-Body {
    z-index: $z-index-xxxl !important;
    &-content {
      width: 80vw !important;
    }
  }
  &-Overlay[data-v-b9dcd536] {
    z-index: $z-index-xxl !important;
  }
}
:deep(.Header) {
  z-index: $z-index-md  !important
}

:deep(.NestedDetail-row) {
  grid-template-columns: repeat(10, 1fr) 120px !important;
}
</style>
