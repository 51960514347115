var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "MatchedMenuItems",
              isSuggestionLoading: _vm.isLoading,
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("StoreGroupFilter", {
            on: { submitFilter: _vm.updateStoreGroupFilterItems },
          }),
          _c("CustomMultiselect", {
            staticClass: "m-right-10",
            attrs: {
              id: "custom-multiselect-match-copy-base-store",
              items: _vm.rvcList,
              selected: _vm.filters.rvc,
              updateDropDownLabelFromParent: _vm.updateLabel.rvc,
              "popup-header": _vm.$t("MenuItemsSettings.Revenue_Center"),
              allLabel: _vm.$t("Global.toolbar_Filters_AllLabel"),
              label: _vm.$t("MenuItemsSettings.Revenue_Center_Label"),
              buttonText: _vm.$t(
                "MenuItemsSettings.Revenue_Center_Button_Text"
              ),
              buttonIconLeft: "btn-success",
              unselectedText: _vm.$t(
                "MenuItemsSettings.Revenue_Center_PlaceHolder"
              ),
            },
            on: {
              delegateCheckAll: function ($event) {
                return _vm.onChangeFilter($event, "rvc")
              },
              delegateCheckItem: function ($event) {
                return _vm.onChangeFilter($event, "rvc")
              },
              delegateSubmitItems: _vm.submitFilter,
            },
          }),
          _c("CustomMultiselect", {
            attrs: {
              id: "custom-multiselect-match-copy-base-store",
              items: _vm.rvcGroupList,
              selected: _vm.filters.rvcGroup,
              updateDropDownLabelFromParent: _vm.updateLabel.rvcGroup,
              "popup-header": _vm.$t("MenuItemsSettings.Group_Popup_Header"),
              allLabel: _vm.$t("Global.toolbar_Filters_AllLabel"),
              label: _vm.$t("MenuItemsSettings.Group_Label"),
              buttonText: _vm.$t("MenuItemsSettings.Group_Button_Text"),
              buttonIconLeft: "btn-success",
              unselectedText: _vm.$t(
                "MenuItemsSettings.Group_Center_PlaceHolder"
              ),
            },
            on: {
              delegateCheckAll: function ($event) {
                return _vm.onChangeFilter($event, "rvcGroup")
              },
              delegateCheckItem: function ($event) {
                return _vm.onChangeFilter($event, "rvcGroup")
              },
              delegateSubmitItems: _vm.submitFilter,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "MenuItemsSettings" },
          [
            _vm.isLoading ? _c("TableLoading") : _vm._e(),
            _vm.isEmptyMatchedList && !_vm.isLoading
              ? _c("EmptyState", {
                  attrs: {
                    title: _vm.$t("MenuItemsSettings.emptyStateTitle"),
                    description: _vm.$t(
                      "MenuItemsSettings.emptyStateDescription"
                    ),
                  },
                })
              : _vm._e(),
            !_vm.isEmptyMatchedList
              ? _c("TableView", {
                  attrs: {
                    fields: _vm.fields,
                    data: _vm.tableData,
                    componentName: "MatchedListTable",
                    page: _vm.matchedListPagination,
                    readOnly: true,
                    addScrollToNoFixedColumns: true,
                  },
                  on: { delegateOnChangePage: _vm.changePage },
                })
              : _vm._e(),
            _c("portal", {
              attrs: { to: "partition" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return _c(
                      "div",
                      {},
                      [
                        _c(
                          "Button",
                          {
                            attrs: {
                              size: "small",
                              danger: "",
                              variant: "remove",
                              id: "btn-table-action-remove",
                              iconName: "icon-notify-close",
                              iconClass: "icon-notify-close",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openRemovePopup(row)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "MenuItemsSettings.partition_button_text"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  },
                },
              ]),
            }),
            _c("router-view", { on: { getList: _vm.submitFilter } }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }