var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select date-start" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "purchaseReportDatepicker",
                range: true,
                isLoading: _vm.$wait.is("getDateFilterSettings"),
                disabled: _vm.isPurchaseReportsLoading || _vm.isPageLoading,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.groupTypes,
              selectedItem: _vm.activeGroupType,
              isNotActiveDisabled: false,
              fieldKey: "name",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeGroupType },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            staticClass: "toolbar-filters__item",
            attrs: {
              id: "purchase-reports-store-filter",
              items: _vm.storeList,
              selected: _vm.storeFilter,
              label: _vm.$t("PurchaseReports.toolbar_Stores_Label"),
              unselectedText: _vm.$t(
                "PurchaseReports.toolbar_Stores_Unselected"
              ),
              popupHeader: _vm.$t("PurchaseReports.toolbar_Stores_PopupHeader"),
              allLabel: _vm.$t("PurchaseReports.toolbar_Stores_AllLabel"),
              buttonText: _vm.$t("PurchaseReports.toolbar_Stores_ButtonText"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckAll: _vm.onChangeStoreFilter,
              delegateCheckItem: _vm.onChangeStoreFilter,
              delegateSubmitItems: _vm.getPurchaseReportsWithParams,
            },
          }),
          _c("div", { staticClass: "toolbar__separator" }),
          _c(
            "Button",
            {
              staticClass: "supplier-switch",
              attrs: {
                variant: "icon",
                id: "btn-purchase-reports-supplier-switch",
                type: "button",
              },
              on: { click: _vm.onSwitchIncludeSupplier },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "icon",
                  class: _vm.includeSupplier ? "eye-open" : "eye-closed",
                },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href": _vm.includeSupplier
                        ? "#icon-login-eye"
                        : "#icon-login-eye-closed",
                    },
                  }),
                ]
              ),
              _vm._v(_vm._s(_vm.$t("PurchaseReports.toolbar_Suppliers"))),
            ]
          ),
          _vm.activeGroupType && _vm.includeSupplier
            ? _c("div", { staticClass: "toolbar__separator" })
            : _vm._e(),
          _vm.activeGroupType && _vm.includeSupplier
            ? _c("CustomCheckbox", {
                staticClass: "supplier-primary-checkbox",
                attrs: {
                  size: "large",
                  id: "checkbox-purchase-reports-supplier-primary",
                  label: _vm.$t("PurchaseReports.toolbar_SupplierPrimary"),
                },
                on: { change: _vm.onChangeSupplierPrimary },
                model: {
                  value: _vm.isSupplierPrimary,
                  callback: function ($$v) {
                    _vm.isSupplierPrimary = $$v
                  },
                  expression: "isSupplierPrimary",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            attrs: {
              componentName: "reports-purchase",
              isDisabled: _vm.isPageLoading,
              activeImportExports: ["export"],
              downloadUrl: "Report/purchase/export",
              downloadFileType: "xlsx",
              downloadParams: _vm.downloadParams,
              downloadFileName: _vm.downloadFileName,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "results" },
          [
            _vm.isPurchaseReportsLoading || _vm.isPageLoading
              ? _c("TableLoading")
              : _vm._e(),
            !_vm.isPurchaseReportsLoading &&
            !_vm.isPurchaseReportsEmpty &&
            !_vm.isPageLoading
              ? _c("TableView", {
                  attrs: {
                    fields: _vm.purchaseFields,
                    readOnly: true,
                    componentName: "ReportsPurchaseTable",
                    data: _vm.purchaseReports,
                  },
                })
              : _vm._e(),
            !_vm.isPurchaseReportsLoading && _vm.isPurchaseReportsEmpty
              ? _c("EmptyState", {
                  attrs: {
                    title: _vm.$t(
                      "PurchaseReports.ABCAnalysis_EmptyState_Title"
                    ),
                    description: _vm.$t(
                      "PurchaseReports.ABCAnalysis_EmptyState_Description"
                    ),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }