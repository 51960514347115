<template lang="pug">
Page
  template(slot="header-left-dropdown")
    pageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-left-search")
    Search.search(
      componentName="Stores",
      :isSuggestionLoading="false",
      :customSuggestionPlaceHolder="$t('Stores.search_Placeholder')"
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-right-content")

    Button(
      primary
      size="medium",
      id="btn-store-add",
      v-show="!isStoresEmpty && !isLoading",
      @click="itemNew"
      iconName="icon-global-add"
      iconClass="icon-global-add icon-left"
    )
      span {{ $t('Stores.addStoreButton') }}

  template(slot="toolbar-left")
    CustomMultiselect(
      id="stock-reports-recipe-cost-calculation-filter"
      :items="storeTypeList",
      :selected="selectedTypeList"
      :label="$t('Stores.form_StoreType')"
      :popupHeader="$t('Stores.filter_StoreTypeFilter')"
      :showAll="false"
      :buttonText="$t('Stores.filter_SubmitFilter')"
      type="radio"
      buttonIconLeft="btn-success"
      :updateDropDownLabelFromParent="updateDropDownLabelFromParent"
      @delegateCheckItem="onChangeStoreTypeFilter"
      @delegateCheckAll="onChangeStoreTypeFilter"
      @delegateSubmitItems="getFilteredStoreType"
    )
  template(slot="toolbar-right")
    ViewType.view-type(
      firstNodeIcon="icon-view-type-list"
      secondNodeIcon="icon-view-type-group"
      :firstSelected="viewTypeList"
      @firstNodeClick="listViewType(true)"
      @secondNodeClick="listViewType(false)"
    )

  template(slot="content-center")
    TableLoading(v-if="isLoading")

    // EMPTY PAGE
    EmptyState(
      v-if            ="isStoresEmpty && !isLoading"
      :title          ="$t('Stores.emptyState_Title')"
      :description    ="$t('Stores.emptyState_Description')"
      :buttonText     ="$t('Stores.emptyState_Button')"
      @emptyAddButton ="itemNew"
    )

    // LIST
    TableView(
      v-if="!isStoresEmpty && !isLoading && viewTypeList"
      :fields="fields"
      :page="Page"
      :actions="['edit', 'remove']"
      :data="storeListItem"
      :componentName="'StoresTable'"
      @delegateOnItemShow="showStore"
      @delegateOnItemEdit="editStore"
      @delegateOnItemDelete="deleteStore"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
    )
    portal(to='typeName')
      .store-type(slot-scope = 'row' :class = 'getNameOfType(row.type, 2)')
        span {{getNameOfType(row.type, 1)}}

    portal(to='hasTransit' v-if="viewTypeList")
      .has-transit(slot-scope = 'row')
        span {{getHasTransit(row.hasTransit)}}

    BoxView(
      v-if="!isStoresEmpty && !isLoading && !viewTypeList"
      :fields="fields"
      :actions="['edit', 'remove']"
      :page="Page"
      :data="storeListItem"
      :componentName="'StoresBox'"
      :extraClass="{forContent: 'm-auto box-flex-row'}"
      @delegateOnItemShow="showStore"
      @delegateOnItemEdit="editStore"
      @delegateOnItemRemove="deleteStore"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
    )
    router-view(@getList="getStoreListItems")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//
import getFields from './fields/store-fields'
import ViewType from '@/view/global/view-type'
import pageTabButtons from '@/view/global/page-tab-buttons'

export default {
  name: 'Stores',

  data () {
    return {
      fields: getFields(this.$t),
      viewTypeList: true,
      selectedTypeList: [],
      search: null,
      storeTypeList: [
        {
          name: this.$t('Stores.filter_AllStores'),
          id: 0
        },
        {
          name: this.$t('Stores.type_Store'),
          id: 1
        },
        {
          name: this.$t('Stores.type_MainProductionStore'),
          id: 2
        },
        {
          name: this.$t('Stores.type_CostCenter'),
          id: 3
        }
      ],
      storeListItem: [],
      updateDropDownLabelFromParent: true
    }
  },

  components: {
    pageTabButtons,
    ViewType,
  },

  async created () {
    await this.getStoreListItems()
  },

  computed: {
    ...mapGetters('Stores', [
      'isStoresEmpty',
      'storeList',
      'pageHeaderLinks',
      'Page'
    ]),

    isLoading () {
      return this.$wait.is(['getStoreList'])
    }
  },

  methods: {
    ...mapActions('Stores', [
      'getStoreList'
    ]),

    onChangeSearch (search) {
      if (search.length === 1) return
      this.search = search
      this.getStoreListItems()
    },

    itemNew () {
      this.$router.push({
        name: 'StoreNew'
      })
    },

    getFilteredStoreType () {
      if (this.selectedTypeList[0].id === 0) this.storeListItem = [...this.storeList]
      else this.storeListItem = this.storeList.filter(i => i.type === this.selectedTypeList[0].id)
    },

    onChangeStoreTypeFilter (event) {
      this.selectedTypeList = [event.item]
    },

    getNameOfType (type, isClass) {
      switch (type) {
        case 1: return isClass === 1 ? this.$t('Stores.type_Store') : 'typeStore'
        case 2: return isClass === 1 ? this.$t('Stores.type_MainProductionStore') : 'typeMainProduction'
        case 3: return isClass === 1 ? this.$t('Stores.type_CostCenter') : 'costCenter'
        default: break
      }
    },

    getHasTransit (transit) {
      if (transit) return this.$t('Stores.summary_HasTransit_Description')
      else return '-'
    },

    async getStoreListItems (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        name: this.search,
        pageSize: pageSize,
        pageNumber: pageNumber
      }
      await this.getStoreList(payload)
      this.storeListItem = [...this.storeList]
      this.selectedTypeList = [this.storeTypeList[0]]
      this.updateDropDownLabelFromParent = !this.updateDropDownLabelFromParent
    },

    changePage (page) {
      this.getStoreListItems(page)
    },
    changePageSize (pageSize) {
      this.getStoreListItems(1, pageSize)
    },

    showStore (store) {
      this.$router.push({
        name: 'Store',
        params: {
          id: store.id
        }
      })
    },

    listViewType (type) {
      this.viewTypeList = type
    },

    editStore (store) {
      this.$router.push({
        name: 'StoreEdit',
        params: {
          id: store.id
        }
      })
    },

    deleteStore (store) {
      this.$router.push({
        name: 'StoreRemove',
        params: {
          item: store,
          id: store.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

  :deep() .boxLink{
    cursor: pointer !important;
  }

  :deep() .box-header span {
    color: $color-success;
  }

  .toolbar{
    justify-content: space-between;
  }
  .store-type{
    width: 100%;
    text-transform: uppercase;
    font-size: $font-size-micro;
    text-align: center;
    padding: 5px 10px;
    border-radius: 4px;
  }
  .typeStore{
    background: rgba(($color-success), 0.2);
    color: $color-success

  }
  .typeMainProduction{
    background:  rgba(($color-rejection), 0.2);
    color: $color-rejection
  }
  .costCenter{
    background: rgba(($color-primary), 0.2);
    color: $color-primary
  }
</style>
