<template lang="pug">
Page

  template(slot="header-right-dropdown" v-if="hasTabPermission" )
    CustomDatepicker.m-right-15(
      :isLoading="isLoading"
      :disabled="isLoading || $wait.is('isLading')"
      v-model="date"
      :disabledStartDate="disabledDate"
      :range="false"
      pickerType="manuel"
    )

  template(slot="header-right-content" v-if="hasTabPermission" )
    Button.m-left-15(
      size="medium",
      primary 
      id="btn-exchange-new"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="itemNew"
    )
      span {{ $t('Exchange.addExchange') }}

  template(slot="toolbar-left")
    businessMultiselect(
      componentName="exchange-currency-filter"
      :label="$t('Exchange.tableColumn_Currency')"
      :popupHeader="$t('Exchange.select_Currency')"
      :multiselectItems="CurrencyList"
      @submitFilter="submitFilter"
      ref="exchangeCurrencyFilter"
    )
    CustomMultiselect.m-left-5(
      id="inventories-list-type-filter"
      :items="sourceTypeList"
      :selected="sourceType"
      :label="$t('Exchange.source')"
      :popupHeader="$t('Inventory.filter_Source')"
      :unselectedText="$t('Global.toolbar_Filters_Unselected_PlaceHolder')"
      :buttonText="$t('Global.apply')"
      :allLabel="$t('Global.Select_Default_Text')"
      buttonIconLeft="btn-success"
      @delegateCheckItem="changeSource"
      @delegateCheckAll="changeSource"
      @delegateSubmitItems="getExchanges"
      ref="sourceType"
    )

    Button.clear-filter-button.border-gray-1.p-0.width-auto.m-right-10(
      @click='clearFilter'
    )
      Icon.icon-clear-filter(name="icon-clear-filter")

  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    div(v-else)
      TableLoading(v-if="isLoading")

      EmptyState(
        v-if="!isLoading && !ExchangeList.length"
        :title="$t('Exchange.emptyState_Title')",
        :description="$t('Exchange.emptyState_Description')",
        :buttonText="$t('Exchange.emptyState_ButtonText')",
        @emptyAddButton="itemNew")

      TableView(
        v-if="ExchangeList.length && !isLoading"
        :fields="fields"
        :data="ExchangeList"
        :page="Page"
        :actions="['remove']"
        :componentName="'ExchangeListTable'"
        @delegateOnItemDelete="itemRemove"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
      )      

      portal(to="source")
        div(slot-scope="row")
          .control
            span {{ getSourceName(row) }}

      portal(to="rate")
        div(slot-scope="row")
          .control
            custom-number-input.rate-input(
              id="`exchange-rate"
              :name="`rate-${row.index}`"
              :data-vv-as="$t('Exchange.popup_FormField_Rate')"
              :placeholder="$t('Exchange.popup_FormField_Rate')"
              :error="veeErrors.has(`rate-${row.index}`)"
              :disabled="isWaitingExchangeUpdate"
              v-validate="'greater_than:0'"
              v-model="row.rate"
              @blur="updateRate(row)"
            )

            showValidateError(
              v-show="veeErrors.has(`rate-${row.index}`)"
              :errorName="veeErrors.first(`rate-${row.index}`)"
            )
    router-view(@getList="getExchanges")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import hasTabPermission from '@/mixins/hasTabPermission'
import NoPermission from '@/view/global/has-not-permission'
import moment from 'moment'
import getFields from './fields'
import BusinessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {
  name: 'Exchanges',

  mixins: [hasTabPermission],

  components: {
    NoPermission,
    BusinessMultiselect
  },

  data () {
    return {
      fields: getFields(this.$t),
      text: null,
      currencyFilter: [],
      sourceType: [],
      date: new Date(),
      disabledDate: {
        from: new Date()
      }
    }
  },

  computed: {
    ...mapGetters('Exchange', [
      'ExchangeList',
      'Page'
    ]),

    ...mapGetters('Global', [
      'CurrencyList'
    ]),

    sourceTypeList() {
      return [
        {
          name: this.$t('Exchange.source_0'),
          type: 'manual',
          id: 0
        },
        {
          name: this.$t('Exchange.source_1'),
          type: 'centralBank',
          id: 1
        }
      ]
    },

    isLoading () {
      return this.$wait.is(['getExchangeList'])
    },
    isWaitingExchangeUpdate() {
      return this.$wait.is(['updateExchange'])
    },
    disabledEndDate () {
      return {
        from: moment()._d
      }
    },
  },

  created () {
    if (!this.hasTabPermission) return
    this.getCurrencyList()
    this.sourceType = [...this.sourceTypeList]
    this.getExchanges()
  },

  methods: {
    ...mapActions('Exchange', [
      'getExchangeList',
      'updateExchange',
      'getExchange'
    ]),

    ...mapActions('Global', [
      'getCurrencyList'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    changeSource (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.sourceType = [...this.sourceTypeList]
        } else {
          this.sourceType.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.sourceType = []
        } else {
          this.sourceType.splice(this.sourceType.indexOf(event.item), 1)
        }
      }
    },

   submitFilter ({list, dontSubmit}) {
      if (dontSubmit) this.currencyFilter = list
      else {
        this.currencyFilter = list
        this.getExchanges()
      }
    },

    clearFilter () {
      this.$refs.exchangeCurrencyFilter.clearAllFilter(false)
    },

    changePage (page) {
      this.getExchanges(page)
    },
    changePageSize (pageSize) {
      this.getExchanges(1, pageSize)
    },

    itemNew () {
      this.$router.push({
        name: 'ExchangeNew'
      })
    },

    itemRemove (item) {
      this.$router.push({
        name: 'ExchangeRemove',
        params: {
          id: item.id
        }
      })
    },

    removeExchange (item) {
      this.$router.push({
        name: 'ExchangeRemove',
        params: {
          id: item.id
        }
      })
    },
    
    getSourceName (item) {
      return this.$t('Exchange.source_' + item.source)
    },

    async updateRate (item) {
      this.$validator.validateAll().then(async result => {
        if (!result) return
        else {
          await this.updateExchange({id: item.id, rate: item.rate}).then(res => {
          if (res) {
              const message = this.$t('Exchange.notification_Updated')
              this.notifyShow({ message })
            }
          })
        }
      })
    },

    getExchanges (page, pageSize = this.Page.size) {
      if (this.currencyFilter.length === this.CurrencyList.length) this.currencyFilter = []
      const payload = {
        pageSize: pageSize,
        pageNumber: page || 1,
        currencyDefinitonIdList: this.currencyFilter.length ? this.currencyFilter.map(i => i.id) : [],
        date: moment(this.date).format('YYYY-MM-DD'),
        source: this.sourceType.length === this.sourceTypeList.length ? null : this.sourceType[0].id
      }
      this.getExchangeList(payload)
    }
  },
  watch: {
    date (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getExchanges()
    }
  },
}
</script>

<style scoped lang="scss">
:deep() .Tableee-col.rate {
  width: 20% !important;
  .txt {
    text-align: right !important;
  }
}
</style>
