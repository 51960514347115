export default function getFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.tableColumn_Name_Short')
      },
      get title() {
        return $t('MenuItems.tableColumn_Name')
      }
    },
    {
      name: 'menuItemGroupName',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.tableColumn_Group_Short')
      },
      get title() {
        return $t('MenuItems.tableColumn_Group')
      }
    },
    {
      name: 'storeGroupName',
      is_visible: true,
      get short_title() {
        return $t('MatchedMenuItems.tableColumn_StoreGroup')
      },
      get title() {
        return $t('MatchedMenuItems.tableColumn_StoreGroup')
      }
    },
    {
      name: 'revenueCenterName',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.tableColumn_RVC_Short')
      },
      get title() {
        return $t('MenuItems.tableColumn_RVC')
      }
    },
    {
      name: 'price',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.tableColumn_Price_Short')
      },
      get title() {
        return $t('MenuItems.tableColumn_Price')
      }
    }
  ]
}
