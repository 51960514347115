<template lang="pug">
Page
  template(slot="header-title")
    span {{ $t('Despatches.pageHeader_Refund') }}
  template(slot="content-center")
    TableLoading(v-if="isLoading")
    .Result.m-bottom-15
      .Result-top-header-title
        .doc-number {{documentNumber}}
        span.m-left-5 {{$t('Despatches.document')}}
    .flexbox(v-if="!isLoading")
      TableView(
        :componentName="'despatchDocumentDetailStatic'"
        :fields="staticFields"
        :readOnly="true"
        :data="refundDetail"
      )
      TableView(
        :componentName="'despatchDocumentDetail'"
        :fields="fields"
        :actions="['edit']"
        :data="refundDetail"
        :addScrollToNoFixedColumns="true"
        @delegateOnItemEdit="editRefund"
      )
      portal(to="stockItem")
        div(slot-scope="row")
          span {{row.stockItem.name}}

      portal(to="unitName")
        div(slot-scope="row")
          span {{ findUnit(row.unitId).name }}
      portal(to="baseUnitName")
        div(slot-scope="row")
          span {{ findUnit(row.unitId).baseUnitName }}
    router-view(
      @getList="getRefundDetail"
    )

</template>

<script>

import getDespatchDocumentDetailFields from './fields/depatchDocumentDetail'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RefundDocumentDetail',

  data () {
    return {
      fields: getDespatchDocumentDetailFields(this.$t),
      refundDetail: [],
      staticData: []
    }
  },

  components: {
  },

  computed: {
    ...mapGetters('Units', ['findUnit']),

    isLoading () {
      return this.$wait.is(['get_REFUND'])
    },

    staticFields () {
      return [
        {
          name: 'stockItem',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Despatches.detail_TableColumn_StockItemName'),
          title: this.$t('Despatches.detail_TableColumn_StockItemName')
        }
      ]
    },
    documentNumber () {
      return this.$route.params.documentNumber
    }
  },
  async mounted () {
    await this.getRefundDetail(this.$route.params.id)
    await this.getTaxes()
  },
  methods: {
    ...mapActions('Refund', ['get_REFUND']),
    ...mapActions('Tax', ['getTaxes']),

    async getRefundDetail (refund) {
      await this.get_REFUND(refund).then(res => {
        if (res) {
          this.refundDetail = res.data.despatchDetailList
          this.staticData = res.data.despatchDetailList.map(i => i.stockItem.name)
        }
      })
    },
    editRefund (detail) {
      this.$router.push({
        name: 'RefundDocumentDetailEdit',
        params: {
          detailId: detail.id,
          despatchDetail: detail
        }
      })
    }
  }
}
</script>

<style lang="scss">

.Result{
  &-top-header-title{
    color: $color-primary;
    display: flex;
  }
}
.doc-number {
  font-weight: bold;
  text-decoration: underline;
}

.Tableee-scroll{
  width: 100%;
  overflow: auto;
}

</style>
