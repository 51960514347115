var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-right-content" }, [
        _c(
          "div",
          [
            _vm.hasPermissionToCreateInventory
              ? _c(
                  "Button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEmpty && !_vm.isLoading,
                        expression: "!isEmpty && !isLoading",
                      },
                    ],
                    attrs: {
                      primary: "",
                      size: "medium",
                      id: "btn-inventories-new",
                      type: "button",
                      iconName: "icon-global-add",
                      iconClass: "icon-global-add",
                    },
                    on: { click: _vm.popupOpen },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Inventories.pageHeader_CreateInventoryButton")
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.types,
              selectedItem: _vm.selectedType,
              useUpperCase: false,
              fieldKey: "name",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeType },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            staticClass: "inventory-toolbar-filters__item",
            attrs: {
              id: "inventory-store-filter",
              items: _vm.storeList,
              componentName: "store",
              selected: _vm.storeFilter,
              label: _vm.$t("Inventories.toolbar_Stores_Label"),
              unselectedText: _vm.$t("Inventories.toolbar_Stores_Unselected"),
              popupHeader: _vm.$t("Inventories.toolbar_Stores_PopupHeader"),
              allLabel: _vm.$t("Inventories.toolbar_Stores_AllLabel"),
              buttonText: _vm.$t("Inventories.toolbar_Stores_ButtonText"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckAll: _vm.onChangeStoreFilter,
              delegateCheckItem: _vm.onChangeStoreFilter,
              delegateSubmitItems: _vm.getInventoryList,
            },
          }),
          _c(
            "div",
            { staticClass: "closed-inventory-filter m-left-15" },
            [
              _c("CustomCheckbox", {
                attrs: {
                  id: "checkbox-inventories-include-closed",
                  checked: _vm.showClosed,
                  label: _vm.$t("Inventories.filter_includeClosed"),
                },
                on: { change: _vm.changeShowClosedStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ViewType", {
            staticClass: "view-type",
            attrs: {
              firstNodeIcon: "icon-view-type-list",
              secondNodeIcon: "icon-view-type-group",
              firstSelected: _vm.viewTypeList,
            },
            on: {
              firstNodeClick: function ($event) {
                return _vm.listViewType(true)
              },
              secondNodeClick: function ($event) {
                return _vm.listViewType(false)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.isEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Inventories.emptyState_Title"),
                  description: _vm.$t("Inventories.emptyState_Description"),
                  buttonText: _vm.$t("Inventories.emptyState_Button"),
                },
                on: { emptyAddButton: _vm.popupOpen },
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && _vm.viewTypeList
            ? _c("TableView", {
                attrs: {
                  fields: _vm.fields,
                  data: _vm.inventories_List,
                  page: _vm.inventoriesListPage,
                  usePortalAction: true,
                  readOnly: true,
                  componentName: "InventoriesTable",
                },
                on: {
                  delegateOnItemShow: _vm.gotoDetail,
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && _vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "status" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          {
                            staticClass: "status-type",
                            class: _vm.getInventoryStatusName(row.status, 2),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getInventoryStatusName(row.status, 1)
                                )
                              ),
                            ]),
                          ]
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  388778320
                ),
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && _vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "type" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c("div", { staticClass: "inventory-type" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                row.type === 1
                                  ? _vm.$t("Inventories.inventoryTypePeriodic")
                                  : _vm.$t(
                                      "Inventories.inventoryTypeIntermediate"
                                    )
                              )
                            ),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  1348315102
                ),
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && _vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "inventoryClosingMethod" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c("div", {}, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                row.inventoryClosingMethod === 1
                                  ? _vm.$t("Stores.popupForm_resetUncounted")
                                  : _vm.$t("Stores.popupForm_handOverUncounted")
                              )
                            ),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  166444405
                ),
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && _vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "actionPortal" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          {},
                          [
                            row.status !== 4 &&
                            row.status !== 3 &&
                            !(row.status == 2 && !row.isLastClosed)
                              ? _c("ActionButton", {
                                  attrs: {
                                    id: "inventories-list-action-button",
                                    data:
                                      row.status === 1
                                        ? _vm.hasPermissionToCloseInventory
                                          ? ["remove", "customAction"]
                                          : ["remove"]
                                        : ["edit"],
                                    item: row,
                                    customActionIconClass:
                                      "icon-global-passive-recipe",
                                    customActionIconName:
                                      "icon-global-passive-recipe",
                                    customActionName: _vm.$t(
                                      "Inventories.closeInventory_CloseButton"
                                    ),
                                  },
                                  on: {
                                    onItemDelete: function ($event) {
                                      return _vm.remove(row)
                                    },
                                    onItemEdit: function ($event) {
                                      return _vm.editPopupOpen(row)
                                    },
                                    onItemCustomAction: function ($event) {
                                      return _vm.close(row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  3227559924
                ),
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && !_vm.viewTypeList
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.fields,
                  data: _vm.inventories_List,
                  extraClass: { forContent: "box-flex-row" },
                  page: _vm.inventoriesListPage,
                  componentName: "InventoryBoxView",
                  usePortalAction: true,
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemShow: _vm.gotoDetail,
                },
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && !_vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "status" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          {
                            staticClass: "status-type",
                            class: _vm.getInventoryStatusName(row.status, 2),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getInventoryStatusName(row.status, 1)
                                )
                              ),
                            ]),
                          ]
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  388778320
                ),
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && !_vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "type" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c("div", { staticClass: "inventory-type" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                row.type === 1
                                  ? _vm.$t("Inventories.inventoryTypePeriodic")
                                  : _vm.$t(
                                      "Inventories.inventoryTypeIntermediate"
                                    )
                              )
                            ),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  1348315102
                ),
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && !_vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "inventoryClosingMethod" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c("div", {}, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                row.inventoryClosingMethod === 1
                                  ? _vm.$t("Stores.popupForm_resetUncounted")
                                  : _vm.$t("Stores.popupForm_handOverUncounted")
                              )
                            ),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  166444405
                ),
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && !_vm.viewTypeList
            ? _c("portal", {
                attrs: { to: "actionPortal-Box" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _c(
                          "div",
                          {},
                          [
                            row.status !== 4 &&
                            row.status !== 3 &&
                            !(row.status == 2 && !row.isLastClosed) &&
                            !_vm.viewTypeList
                              ? _c("ActionButton", {
                                  attrs: {
                                    id: "inventories-list-action-button",
                                    data:
                                      row.status === 1
                                        ? ["remove", "customAction"]
                                        : ["edit"],
                                    item: row,
                                    customActionIconClass:
                                      "icon-global-passive-recipe",
                                    customActionIconName:
                                      "icon-global-passive-recipe",
                                    customActionName: _vm.$t(
                                      "Inventories.closeInventory_CloseButton"
                                    ),
                                  },
                                  on: {
                                    onItemDelete: function ($event) {
                                      return _vm.remove(row)
                                    },
                                    onItemEdit: function ($event) {
                                      return _vm.editPopupOpen(row)
                                    },
                                    onItemCustomAction: function ($event) {
                                      return _vm.close(row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  234425072
                ),
              })
            : _vm._e(),
          _c("InventoryEditPopup", {
            attrs: {
              inventory: _vm.editPopup.inventory,
              status: _vm.editPopup.status,
            },
            on: {
              closePopup: _vm.editPopupClose,
              submit: _vm.onSubmitEditCountingForm,
            },
          }),
          _c(
            "Popup",
            {
              attrs: { status: _vm.popup.status },
              on: { buttonClosePopup: _vm.popupClose },
            },
            [
              _c("template", { slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("Inventories.popupAdd_Title"))),
              ]),
              _c("template", { slot: "detail" }, [
                _c(
                  "div",
                  { staticClass: "Popup-info-text" },
                  [
                    _c("Icon", { attrs: { name: "icon-tavsan" } }),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("Inventories.popupAdd_Info"))),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "content" }, [
                _c(
                  "form",
                  {
                    staticClass: "Form",
                    attrs: { id: "form-create-inventory" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmitForm.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "Form-item" },
                      [
                        _c("MultiselectRadio", {
                          attrs: {
                            data: [
                              {
                                text: _vm.$t("ListsInventory.form_type_1"),
                                value: 1,
                              },
                              {
                                text: _vm.$t("ListsInventory.form_type_2"),
                                value: 2,
                              },
                            ],
                          },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "Form-item required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Inventories.popupAdd_FormField_Date"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-item-select date-start" },
                            [
                              _c("CustomDatepicker", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                class: {
                                  "is-danger":
                                    _vm.veeErrors.has(`inventoryDate`),
                                },
                                attrs: {
                                  id: "dp-inventories-popup-date-start",
                                  name: "inventoryDate",
                                  "data-vv-as": _vm.$t(
                                    "Inventories.popupAdd_FormField_Date"
                                  ),
                                  pickerType: "manuel",
                                  inputIconRight: "icon-datepicker-big",
                                  inputIcon: "",
                                },
                                model: {
                                  value: _vm.form.inventoryDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "inventoryDate", $$v)
                                  },
                                  expression: "form.inventoryDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("despatchDate"),
                                expression: "veeErrors.has('despatchDate')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("despatchDate"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "Form-item required" }, [
                      _c(
                        "label",
                        {
                          staticClass: "Form-item-label",
                          attrs: {
                            id: `inventory-popup-form-label-store-name`,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Inventories.popupAdd_FormField_Store")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                            ],
                            ref: "secondaryInput",
                            attrs: {
                              "component-name": "inventory-popup-store-select",
                              name: "storeName",
                              "data-vv-as": _vm.$t(
                                "Despatches.detail_Popup_FormField_StoreName"
                              ),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("storeName"),
                              },
                              "on-search": _vm.getOptionsStore,
                              options: _vm.searchStoreList,
                              label: "name",
                              searchAll: true,
                              withDebounce: false,
                              placeholder: _vm.$t(
                                "Despatches.detail_Popup_FormField_StoreName_Placeholder"
                              ),
                              loading: _vm.isSearchStoreLists,
                            },
                            on: {
                              input: _vm.inputSelection,
                              resetOptions: _vm.resetOptions,
                            },
                            model: {
                              value: _vm.form.store,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "store", $$v)
                              },
                              expression: "form.store",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("storeName"),
                                expression: "veeErrors.has('storeName')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("storeName"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "Form-item required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Stores.popupForm_Field_inventoryClosingMethod"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                          _c("customSelectInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                            ],
                            attrs: {
                              name: "inventoryClosingMethod",
                              optionData: _vm.inventoryClosingMethods,
                              optionKey: "value",
                              optionIdKey: "value",
                              isValueNumber: true,
                              disabled:
                                _vm.currentStore?.type === 3 ||
                                _vm.currentStore
                                  ?.expirationDateControlIsEnabled,
                              "data-vv-as": _vm.$t(
                                "Stores.popupForm_Field_inventoryClosingMethod"
                              ),
                              error: _vm.veeErrors.has(
                                "inventoryClosingMethod"
                              ),
                              id: "select-inventory-closing-method",
                              defaultText: _vm.$t(
                                "Stores.popupForm_Field_inventoryClosingMethodSelect"
                              ),
                            },
                            model: {
                              value: _vm.form.inventoryClosingMethod,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "inventoryClosingMethod",
                                  $$v
                                )
                              },
                              expression: "form.inventoryClosingMethod",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "inventoryClosingMethod"
                                ),
                                expression:
                                  "veeErrors.has('inventoryClosingMethod')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first(
                                "inventoryClosingMethod"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.form.type === 2
                      ? _c("div", { staticClass: "Form-item" }, [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Inventories.popupAdd_FormField_List")
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c(
                                "custom-search",
                                {
                                  attrs: {
                                    "component-name": "inventory-form-list",
                                    name: "inventory-form-list",
                                    "data-vv-as": _vm.$t(
                                      "Inventories.popupAdd_FormField_List"
                                    ),
                                    "on-search": _vm.getOptionsList,
                                    options: _vm.selectOptionsList,
                                    label: "name",
                                    loading: _vm.isSearchSpotLists,
                                    searchAll: true,
                                    placeholder: _vm.$t(
                                      "Inventories.Form_List_Placeholder"
                                    ),
                                  },
                                  model: {
                                    value: _vm.form.list,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "list", $$v)
                                    },
                                    expression: "form.list",
                                  },
                                },
                                [
                                  _c("template", { slot: "no-options" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Inventories.Form_List_NoResults"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "selected-items" },
                            _vm._l(_vm.lists, function (item, index) {
                              return _c(
                                "Button",
                                {
                                  key: index,
                                  attrs: {
                                    size: "medium",
                                    seashell: "",
                                    id: `selected-stock-item-${index}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeListItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                  _c("Icon", {
                                    staticClass: "m-left-5",
                                    attrs: {
                                      name: "icon-popup-close",
                                      id: `selected-stock-item-delete-${index}`,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "Button",
                    {
                      attrs: {
                        primary: "",
                        size: "medium",
                        variant: "full",
                        id: "btn-inventory-popup-submit",
                        type: "submit",
                        form: "form-create-inventory",
                        disabled:
                          _vm.isCreating ||
                          (_vm.form.type === 2 && _vm.lists.length === 0),
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isCreating,
                              expression: "!isCreating",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("Inventories.popupAdd_CreateButton"))
                          ),
                        ]
                      ),
                      _c("Loading", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isCreating,
                            expression: "isCreating",
                          },
                        ],
                        attrs: { theme: "disable" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("router-view", { on: { getList: _vm.getInventoryList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }