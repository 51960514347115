<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "PageLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-content")
    Button(
      primary,
      size="medium",
      @click="createCustomNutritionFood",
      id="nutritional-values-create-new"
    ) {{ $t('NutritionalValues.createNewFood') }}

  template(slot="content-center")
    TableLoading(v-if="isFetchingList")
    TableView(
      v-if="!isFetchingList"
      :page="Page"
      :data="NutritionFoodValues"
      :fields="fields"
      :usePortalAction="true"
      :pointer="true"
      :readOnly="true"
      :componentName="'NutritionTable'"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @sort="sort"
      @submitFilter="submitFilter"
      @clearFilter="clearFilter"
      @changePopupFilterSearch="search => getOptions(search)"
    )
    portal(to="foodGroupName")
      div(slot-scope="row")
        span(@click="getNutritionDetail(row)") {{row.foodGroupName}}

    portal(to="nutritionFoodName")
      div(slot-scope="row")
        span(@click="getNutritionDetail(row)") {{row.nutritionFoodName}}

    portal(to="stockItemList")
      div(slot-scope="row")
        custom-search.form-item-select.m-top-10(
          component-name="stockItem-list"
          name="stockItemList",
          label="name",
          :searchAll="true"
          :placeholder="$t('NutritionalValues.search_stockItem')"
          :on-search="getStockListOptions"
          :customEmit="row"
          :options="selectOptionsStockList"
          :isCustomSituation="true"
          @input="selectStockItem"
          @afterSelected="actionAfterSelectStockItem(row)"
        )
        .selected-items.m-top-10.m-bottom-10
          Loading(v-if="isMatchingLoading")
          Button(
            seashell
            size="xsmall"
            v-for="(item,index) in row.stockItemList",
            :key="index",
            :id="`selected-despatch-list-item-${index}`"
            @click.stop="removeStockItem({item, row})"
          )
            span {{ item.name }}
            Icon.m-left-5(
              name="icon-popup-close"
              :id="`selected-despatch-list-item-delete-${index}`"
            )
    portal(to="actionPortal")
      div.popup-container.flexbox-custom(slot-scope="row")
        ActionButton(
          id="request-order-list-action-button"
          :data="row.isCustomFood ? ['edit', 'remove', 'copy'] : ['copy']"
          :item="row"
          @onItemDelete="deleteCustomNutrition(row)"
          @onItemEdit="editCustomNutrition(row)"
          @onItemCopy="copyNutrition(row)"
        )

    router-view

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import getFields from './fields/nutritions'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'NutritionalValues',
  data () {
    return {
      fields: getFields(this.$t),
      sortExpressionList: [],
      selectedFoodGroups: [],
      selectedNutritions: [],
      selectOptions: [],
      filteredNutritionList: [],
      matchedStockItems: [],
      selectOptionsStockList: [],
      selectedNutritionItem: null,
      selectedStockItems: [],
      stockItemsTag: [],
      isActionButtonVisible: false,
    }
  },

  async created () {
    await this.listFoodGroups()
    await this.getNutritionValues()
  },

  computed: {
    ...mapGetters('OptionalFields', ['PageLinks']),
    ...mapGetters('NutritionalValues', ['NutritionFoodValues', 'Page', 'FoodGroups']),

    isFetchingList () {
      return this.$wait.is('getAllNutritionalValues')
    },
    isMatchingLoading () {
      return this.$wait.is(['matchStockItemsWithNutritions', 'removeStockItemsFromNutritions'])
    }

  },
  methods: {
    ...mapActions('NutritionalValues', ['getAllNutritionalValues', 'getFoodGroups', 'matchStockItems']),
    ...mapActions('Stock', [
      'searchStockItem',
      'getNutritionList'
    ]),

    clearFilter (field) {
      this.nutritionFoodList = []
      this.selectedNutritions = []
      this[field.filter.selectedListName] = field.filter.selectedList
      this.getNutritionValues()
    },

    createCustomNutritionFood () {
      this.$router.push({
        name: 'NewNutrition'
      })
    },

    getNutritionDetail (item) {
      this.$router.push({
        name: 'DetailNutrition',
        params: {
          id: item.nutritionFoodId,
          isCustomFood: item.isCustomFood,
          nutritionFoodName: item.nutritionFoodName
        }
      })
    },

    editCustomNutrition (item) {
      this.$router.push({
        name: 'EditNutrition',
        params: {
          id: item.nutritionFoodId,
          nutritionFoodName: item.nutritionFoodName
        }
      })
    },

    deleteCustomNutrition (item) {
      this.$router.push({
        name: 'RemoveNutrition',
        params: {
          id: item.nutritionFoodId,
          nutritionFoodName: item.nutritionFoodName
        }
      })
    },

    copyNutrition (item) {
      this.$router.push({
        name: 'NutritionCopy',
        params: {
          id: item.nutritionFoodId,
          nutritionFoodName: item.nutritionFoodName,
          isCustomFood: item.isCustomFood
        }
      })
    },

    showActionButtons () {
      this.isActionButtonVisible = true
    },

    removeStockItem: vueWaitLoader(async function ({item, row}) {
      let remainingNutritionFoodIds = row.stockItemList.map(i => i.id)
      remainingNutritionFoodIds.splice(remainingNutritionFoodIds.indexOf(item.id), 1)
      const payload = {
        stockItemIds: remainingNutritionFoodIds,
        nutritionFoodId: row.nutritionFoodId
      }
      const res = await this.matchStockItems(payload)
      if (res) {
        row.stockItemList = res.data.stockItems
      }
    }, 'removeStockItemsFromNutritions'),

    async selectStockItem (item) {
      if (item.customEmit.stockItemList && item.customEmit.stockItemList.length) {
        this.selectedStockItems = item.customEmit.stockItemList.map(i => i.id)
        this.selectedStockItems.push(item.option.id)
      } else this.selectedStockItems.push(item.option.id)
    },

    actionAfterSelectStockItem: vueWaitLoader(async function (item) {
      this.selectedNutritionItem = item.nutritionFoodId
      const payload = {
        nutritionFoodId: this.selectedNutritionItem,
        stockItemIds: this.selectedStockItems
      }
      const res = await this.matchStockItems(payload)
      if (res) {
        item.stockItemList = res.data.stockItems
        this.selectedStockItems = []
      }
    }, 'matchStockItemsWithNutritions'),

    getStockListOptions (search) {
      this.selectOptionsStockList = []
      if (search.length > 1) {
        const params = {text: search}
        this.searchStockItem(params)
          .then(res => {
            if (res) {
              this.selectOptionsStockList = res.data.items
            }
          })
      }
    },

    async listFoodGroups () {
      const foodGroupFilterList = this.fields.find(i => i.name === 'foodGroupName')
      await this.getFoodGroups()
      foodGroupFilterList.filter.list = [...this.FoodGroups]
      foodGroupFilterList.filter.selectedList = [...this.FoodGroups]
      this.selectedFoodGroups = [...this.FoodGroups]
    },

    async getOptions (item) {
      if (item.field.filter.backendSearch) {
        const params = {
          name: item.search
        }
        if (item.search.length > 1) {
          const res = await this.getNutritionList({params})
          if (res) {
            item.field.filter.list = res.data.nutritionFoodList
            item.field.filter.selectedList = res.data.nutritionFoodList
            this.selectedNutritions = item.field.filter.selectedList
          }
        } else {
          item.field.filter.list = []
        }
      }
    },

    async submitFilter (field) {
      if (field.name === 'nutritionFoodName') this.selectedNutritions = field.filter.selectedList
      else this.selectedFoodGroups = field.filter.selectedList
      const payload = {
        pageNumber: 1,
        pageSize: this.Page.size,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null,
        foodGroupIds: this.selectedFoodGroups ? this.selectedFoodGroups.map(item => item.id) : null,
        nutritionFoodIds: this.selectedNutritions.length ? this.selectedNutritions.map(item => item.id) : null
      }
      await this.getAllNutritionalValues(payload)
    },

    async getNutritionValues (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        pageSize,
        pageNumber,
        sortExpressionList: this.sortExpressionList ? this.sortExpressionList : null
      }
      await this.getAllNutritionalValues(payload)
    },
    changePage (pageNumber) {
      this.getNutritionValues(pageNumber)
    },

    changePageSize (pageSize) {
      this.getNutritionValues(1, pageSize)
    },
    async sort ({ name, sortingObject }) {
      this.fields.forEach(field => {
        if (field.name !== name && 'sortingObject' in field) field.sortingObject.sortType = null
      })
      switch (sortingObject.sortType) {
        case null:
          sortingObject.sortType = 0
          this.sortExpressionList = [sortingObject]
          break
        case 0:
          sortingObject.sortType++
          this.sortExpressionList = [sortingObject]
          break
        case 1:
          sortingObject.sortType = null
          this.sortExpressionList = []
          break
      }
      await this.getNutritionValues()
    }
  }
}
</script>

<style lang="scss" scoped>

  .toolbar{
    display: flex;
    justify-content: flex-end;
  }

  .selected-items {
    display: flex;
    flex-wrap: wrap;
    Button {
      width: auto;
    }
  }
  .allergens{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: solid 1px $color-gray;
    background-color: $color-light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .boxContainer{
    display: flex;
    flex-wrap: wrap;
  }

  .allergen-title-box {
    width: 280px !important;
    margin-right: 20px;
    margin-top: 20px;
  }
</style>
