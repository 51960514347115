var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmpty && !_vm.isLoading,
                  expression: "!isEmpty && !isLoading",
                },
              ],
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-tax-new",
                iconName: "icon-global-add",
                iconClass: "icon-global-add icon-left",
              },
              on: { click: _vm.newTax },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Tax.headerNewButton")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.isEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Tax.emptyStateTitle"),
                  description: _vm.$t("Tax.emptyStateDescription"),
                  buttonText: _vm.$t("Tax.emptyStateNewButton"),
                },
                on: { emptyAddButton: _vm.newTax },
              })
            : _vm._e(),
          !_vm.isLoading
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.taxItemFields,
                  actions: ["edit"],
                  data: _vm.Taxes,
                  extraClass: { forContent: "box-tax" },
                  componentName: "TaxBox",
                },
                on: { delegateOnItemEdit: _vm.editTax },
              })
            : _vm._e(),
          _c("router-view", { on: { getList: _vm.getTaxes } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }