<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "requestHeaderLinks"
      translationGroup="Request"
    )
  template(slot="header-left-search")
    Search.search-box(
        componentName="requestedOrders",
        suggestionValue="name"
        :isSuggestionLoading="isSuggestionLoading"
        :suggestions="selectedStockItem"
        :customSuggestionPlaceHolder="$t('Request.search_Placeholder')"
        :customSuggestionTitle="$t('DirectOrders.orders_search_SuggestionTitle')"
        @onChangeSearchValue="onChangeSearch"
        @onChangeQueryItem="setStockItem"
        @clearSearch="clearSearch"
      )
  template(slot="header-right-dropdown")
    CustomDatepicker.m-right-15(
      v-model="date"
      :range="false"
      :useFilterReset="true"
      pickerType="manuel"
      @resetDateFilter="resetDateFilter"
    )
  template(slot="header-right-content")
    Button(
      id="btn-add-new-order-request-detail",
      primary,
      size="medium"
      @click="addNewDetail"
    )
      span {{$t('Global.addDetail')}}

  template(slot="toolbar-left")
    storeFilter(
      @submitFilter="getListWithStoreFilter"
      ref="requestedOrderStoreListFilter")

    itemGroupList(
      groupType="itemGroup"
      @submitFilter="getListWithGroupFilter"
      ref="requestedOrderGroupFilter"
    )

    Button.clear-filter-button.border-gray-1.p-0.width-auto(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")

  template(slot="toolbar-right")
    CustomMultiselect.toolbar-filters__item(
      id="order-request-supplier-select"
      :items="supplierList"
      :selected="selectedSupplier"
      type="radio"
      :showAll="false"
      :isDisabled="!selectedOrders.length"
      :label="$t('Request.supplierFilter')"
      :popupHeader="$t('Request.selectSupplier')"
      :unselectedText="$t('Request.toolbar_Supplier_Unselected')"
      :allLabel="$t('Request.toolbar_Stores_AllLabel')"
      :buttonText="$t('Request.toolbar_SupplierFilter')"
      @delegateCheckItem="onChangeSupplierFilter"
      @delegateSubmitItems="getPriceQuote(true)"
      buttonIconLeft="btn-success"
      ref="selectedSupplier")

    .popup-container(
      v-click-outside="hideReasonPopup"
    )
      Button.relative(
        id="btn-orders-passive-order",
        secondary
        size="small"
        @click="showPassiveReasonForm",
        :disabled="selectedOrders.length === 0"
        v-show="!isEmptyOrders")
        span {{ $t('Orders.makePassive') }}
      form.order-passive-description-right-MiniPopup(
        v-if="showReasonButtonPopup"
        id="order-description-reason-form"
        @submit.prevent ="makeOrderPassive"
      )
        label.order-description-MiniPopup-label.required(
          id="order-description-reason-label"
        ) {{ $t('Orders.Reason_Description_Input_Label') }}
        textarea.txt.order-description-MiniPopup-input(
          ref="reason"
          name="reason"
          autocomplete="off"
          v-validate="{required: true, max: 128}"
          v-model="reasonDescription"
          :class="{ 'is-danger': veeErrors.has(`reason`) }"
          id="order-description-reason-input"
        )
        Button.m-top-15.m-left-0(
          primary
          size="medium"
          type="submit"
          variant="full"
          id="order-description-reason-submit"
          :disabled="selectedOrders.length === 0 || isMakeRequestApprove || !isPriceQuoteActive"
        )
          span {{$t('Orders.makePassive')}}
          Loading(v-if="isMakeRequestApprove", theme="disable")

    Button.m-left-10(
      secondary
      size="small"
      id="btn-orders-get-price-quote"
      @click="getPriceQuote(false)",
      type="button"
      :disabled="selectedOrders.length === 0 || isFetchingPriceQuato"
      v-show="!isEmptyOrders")
      Loading(v-if="isFetchingPriceQuato", theme="disable")
      span {{ $t('Orders.getPriceQuoteButton') }}

    Button.m-left-10(
      primary
      size="small"
      id="btn-orders-create-order",
      @click="createRequests",
      :disabled="selectedOrders.length === 0 || isMakeRequestApprove || !isFormValidChecker"
      v-show="!isEmptyOrders")
      Icon(name="icon-btn-success")
      Loading(v-if="isMakeRequestApprove", theme="disable")
      span {{ $t('Orders.createOrdersButton') }}
  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    div(v-else)
      /// content
      template(v-if="isApprovedOrder")
        NestedTable(
          v-if="!isFetchingApprovedData"
          :fields="requestedApprovedOrderFields"
          :detailFields="requestedApprovedOrderDetailFields",
          :data="approvedRequestOrders"
          @showDetailAction="showDetail",
        )
        portal(to="maxApprovalLevel")
          div(slot-scope="header")
            ApprovalLevels(
              :maxApprovalLevel="header.row.maxApprovalLevel",
              :currentApprovalLevel="header.row.currentApprovalLevel",
              :approvalStatus="header.row.approvalStatus"
            )

        portal(to="approvalStatus")
          div(slot-scope="header")
            span {{ getApprovalStatus(header.row.approvalStatus) }}

      TableLoading(v-if="isFetchingList || isPageLoading")
      EmptyState(
      v-if="isEmptyOrders && !isFetchingList && !isPageLoading"
      :title="$t('Orders.emptyState_NotFound_Title')"
      :description="$t('Orders.emptyState_NotFound_Description')")
      template(v-if="!isEmptyOrders && !isFetchingList && !isPageLoading && !isApprovedOrder")
        .Orders
          TableView(
            :fields="requestedOrderField"
            :data="orders"
            :readOnly="true"
            :selectable="'checkbox'"
            :selectableAll="'checkbox'"
            :selectedRows="selectedOrders"
            @delegateOnItemSelect="toggleSelectOrder"
            uniqueIdentifier="id"
            @showAllSelectedItems="toggleAll"
            :isRowClickable="false"
          )
          portal(to="description")
            div(slot-scope="row")
              Button.show-MiniPopup.relative.width-max-content(
                v-if="row.description",
                id="order-approval-header-info-button"
                iconName="icon-global-info",
                :justIcon="true",
                class="btn-info"
              )
                .MiniPopup-right-primary.m-top-15.c-dark
                  span {{ row.description }}
          portal(to="requestQuantity")
            div(slot-scope="row")
              span {{ row.sourceType === 2 ? 0 : row.requestQuantity}}
              
          portal(to="quantity")
            div(slot-scope="row")
              custom-number-input(
                :id="`quantity-input-${row.index}`"
                :name="`quantity-${row.index}`"
                :data-vv-scope="`request-order-${row.id}`"
                v-model.number="row.quantity",
               
                :data-vv-as="$t('Orders.table_fieldQuantity')",
                v-validate="{required: true, greater_than: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), multiplyTwoField: row.price}",
                :disabled="isGetPriceQuote || isFetchingPriceQuato || Tenant.approvalRequestOrderPermission"
                type="number"
                step="any"
                :class="{ 'is-danger': veeErrors.has(`request-order-${row.id}.quantity-${row.index}`) }",
                @blur="calcPriceInfo(row)"
                @input="isFormValid()"
              )
          portal(to="requestDate")
            div(slot-scope="row")
              CustomDatepicker.custom-picker(
                :id="`request-form-rdate-${row.index}`"
                :name="`requestDate-${row.index}`",
                :ref="`requestDate${row.index}`"
                :data-vv-as="$t('Despatches.popup_DespatchDate') ",
                v-model="row.requestDate",
                :class="{ 'is-danger': veeErrors.has(`requestDate-${row.index}`) }",
                v-validate="'required'"
                pickerType="manuel",
                inputIconRight="icon-arrow-down"
                inputIcon="",
                :disabledStartDate="disabledDate"
                :disabled="isFetchingList || isPageLoading"
                :isCheckingPosition="false"
                @change="changeDate(row)"
              )
          portal(to="supplier")
            div(slot-scope="row")
              .select-box-list
                customSelect(
                  v-model="row.supplierId"
                  selectedEmitValue="id"
                  :optionData="supplierList"
                  optionTitle="name"
                  :defaultTitle="$t('Orders.detail_Popup_FormField_Supplier_Unselected')"
                  :componentName="`select-col-${row.index}`"
                  :itemData="row"
                  @inputSelected="changeSupplier"
                  :key="row.id"
                  :isDefaultTextActive="false"
                  :isSearchActive="true"
                )
          portal(to="price")
            div(slot-scope="row").tooltip-relative
              currency-input.txt.u-textRight.input-types(
                :id="`price-${row.index}`"
                :name="`price${row.index}`"
                v-model="row.price",
                :data-vv-scope="`request-order-${row.id}`"
                :data-vv-as="$t('Orders.table_fieldPrice')",
                v-validate="{required: true, greater_than: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), multiplyTwoField: row.quantity}",
                :disabled="isGetPriceQuote || isFetchingPriceQuato || row.priceType === 2"
                :class="{ 'is-danger': veeErrors.has(`request-order-${row.id}.price${row.index}`) }",
                @blur.native="calcPriceInfo(row)"
              )

              tooltip.small(
                v-if="row.priceType === 2"
                white,
                right,
                :text="$t('Despatches.priceTypeWarning')")

          portal(to="discount1")
            div(slot-scope="row").tooltip-relative
              input.txt.no-spinners.u-textRight(
                :id="`discount1-${row.index}`"
                :name="`discount1${row.index}`"
                :data-vv-scope="`request-order-${row.id}`"
                v-model.number="row.discount1"
                step="any"
                type="number"
                :disabled="isGetPriceQuote || isFetchingPriceQuato || row.priceType === 2"
                v-validate="'greater_than:-1|max_value:100'",
                @change="calcPriceInfo(row)"
                :class="{ 'is-danger': veeErrors.has(`request-order-${row.id}.discount1${row.index}`)}")

              Tooltip.small(
                v-if="row.priceType === 2"
                white,
                right,
                :text="$t('Despatches.priceTypeWarning')")

          portal(to="discount2")
            div(slot-scope="row").tooltip-relative
              input.txt.no-spinners.u-textRight(
                :id="`discount2-${row.index}`"
                :name="`discount2${row.index}`"
                :data-vv-scope="`request-order-${row.id}`"
                v-model.number="row.discount2"
                step="any"
                type="number"
                :disabled="isGetPriceQuote || isFetchingPriceQuato || row.priceType === 2"
                v-validate="'greater_than:-1|max_value:100'",
                @change="calcPriceInfo(row)"
                :class="{ 'is-danger': veeErrors.has(`request-order-${row.id}.discount2${row.index}`)}")

              Tooltip.small(
                v-if="row.priceType === 2"
                white,
                right,
                :text="$t('Despatches.priceTypeWarning')")

          portal(to="tax")
            div(slot-scope="row")
              .select-box-list
                customSelect(
                  v-model="row.taxId"
                  selectedEmitValue="id"
                  :optionData="Taxes"
                  optionTitle="name"
                  :defaultTitle="$t('Orders.detail_Popup_FormField_TaxId_Unselected')"
                  :componentName="`taxId-${row.index}`"
                  :itemData="row"
                  @inputSelected="selectCalcPrice"
                  :key="`tax-${row.id}`"
                  :isDefaultTextActive="false"
                )

          .Orders-toolbar
            .toolbar.footer.m-right-10
              .left
              .right
                Button.m-right-10(
                  size="medium",
                  primary
                  id="btn-orders-save-order"
                  @click="createRequests",
                  :disabled="selectedOrders.length === 0 || isMakeRequestApprove || !isFormValidChecker"
                  v-show="!isEmptyOrders")
                  Icon(name="icon-btn-success")
                  Loading(v-if="isMakeRequestApprove", theme="disable")
                  span {{ $t('Orders.createOrdersButton') }}
      TableFoot(
        v-if="!isEmptyOrders && !isFetchingList && !isPageLoading && !isApprovedOrder"
        :page="OrderPage"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
      )

    router-view(@getList="getFilteredRequestOrders")
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getObjectValuesByKey, debouncer, vueWaitLoader } from '@/utils/baseOperations'
import { getRequestOrderFields } from './fields/requestedOrders'
import { getRequestedApprovedOrders, getRequestedApprovedOrdersDetail } from './fields/approvedRequestedOrders'
import moment from 'moment'
import { startLoading, stopLoading } from '@/utils/loading'
import storeFilter from '@/view/global/business-filters/store.vue'
import itemGroupList from '@/view/global/business-filters/groups.vue'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import customSelect from '@/view/global/custom-select'
import TableFoot from '@/view/global/table/foot.vue'
import ApprovalLevels from '@/view/global/approval-levels'


export default {
  name: 'RequestedOrder',
  mixins: [hasTabPermission],
  data () {
    return {
      stockItem: {},
      selectedOrders: [],
      selectedStockItem: [],
      items: [],
      isPageReady: false,
      storeFilter: [],
      groupFilter: [],
      date: null,
      search: null,
      showReasonButtonPopup: false,
      reasonDescription: null,
      requestedApprovedOrderFields: getRequestedApprovedOrders(this.$t),
      requestedApprovedOrderDetailFields: getRequestedApprovedOrdersDetail(this.$t),
      requestedOrderField: getRequestOrderFields(this.$t),
      isMakeRequestActive: false,
      isPriceQuoteActive: true,
      selectedSupplier: [],
      disabledDate: {
        to: moment().hour(-1).minute(0)._d
      },
      validForm: false,
      isApprovedOrder: false
    }
  },
  components: {
    storeFilter,
    NoPermission,
    itemGroupList,
    customSelect,
    TableFoot,
    ApprovalLevels
  },
  computed: {
    ...mapGetters('Request', ['requestHeaderLinks']),

    ...mapGetters('Orders', ['orders', 'isEmptyOrders', 'OrderPage', 'approvedRequestOrders']),

    ...mapGetters('Tax', ['Taxes']),

    ...mapGetters('Auth', ['Tenant']),

    ...mapGetters('Supplier', {
      supplierList: 'ActiveMiniSuppliers'
    }),
    isSuggestionLoading () {
      return this.$wait.is(['Order/requested/all'])
    },
    isPageLoading () {
      return this.$wait.is('pageLoading')
    },
    isFetchingPriceQuato () {
      return this.$wait.is(['getPriceQuoteForRequest'])
    },
    isFetchingApprovedData () {
      return this.$wait.is(['getApprovedOrders'])
    },
    isFetchingList () {
      return this.$wait.is(['getRequestOrders'])
    },
    isMakeRequestApprove () {
      return this.$wait.is(['makeRequestApprove', 'makeOrderPassive'])
    },
    isGetPriceQuote () {
      return this.$wait.is(['getPriceQuoteWithSupplier'])
    },
    isFormValidChecker () {
      if (!this.isMakeRequestActive) return
      this.isFormValid()
      return this.validForm
    }
  },
  methods: {
    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapActions('Orders', [
      'getRequestOrders',
      'getPriceQuoteForRequest',
      'makeRequestApprove',
      'getPriceQuoteWithSupplier',
      'makeOrderRequestPassive',
      'getApprovedOrders',
      'getApprovedOrdersDetail'
    ]),

    ...mapMutations('Orders', ['UPDATE_R_ORDER_LIST', 'SORT_BY_DATE_ORDER_LIST', 'SET_APPROVED_ORDER_LIST', 'SHOW_APPROVED_ORDER_DETAIL']),

    ...mapActions('Supplier', ['getMiniSuppliersList']),

    ...mapActions('Tax', ['getTaxes']),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Auth', ['getTenant']),

    addNewDetail () {
      this.$router.push({
        name: 'OrderRequestDetailForm'
      })
    },

    resetDateFilter () {
      this.date = null
    },

    onChangeSupplierFilter (event) {
      this.selectedSupplier = [event.item]
    },

    getPriceQuote: vueWaitLoader(async function (useSupplierFilter) {
      const payload = {
        supplierId: useSupplierFilter ? this.selectedSupplier[0].id : null,
        requestDetailIdList: this.selectedOrders.map(_ => _.id)
      }
      const res = await this.getPriceQuoteForRequest(payload)
      if (useSupplierFilter) {
        this.selectedOrders.forEach(i => {
          i.supplierId = this.selectedSupplier[0].id
        })
      }
      this.isMakeRequestActive = true
      if (res.data.priceQuoteList.length === 0) return
      let fn = () => {
        if (res) {
          let { priceQuoteList } = res.data
          this.selectedOrders.map(rl => {
            const priceQuoteData = priceQuoteList.find(pl => pl.requestDetailId === rl.id)
            if (!priceQuoteData) return
            const detail = this.orders.find(item => item.id === rl.id)
            detail.priceType = priceQuoteData.priceType
            const payload = {
              supplierId: priceQuoteData ? priceQuoteData.supplierId : null,
              price: priceQuoteData ? priceQuoteData.unitPrice : 0,
              discount1: priceQuoteData ? priceQuoteData.discount1 : 0,
              discount2: priceQuoteData ? priceQuoteData.discount2 : 0,
              taxId: priceQuoteData ? priceQuoteData.taxId : null
            }
            this.UPDATE_R_ORDER_LIST({ detail, payload })
            this.calcPriceInfo(detail)
          })
        }
      }

      await startLoading(this.$store.dispatch, 'fetchingAll', async () => {
        await fn()
        stopLoading(this.$store.dispatch, 'fetchingAll')
      })
    }, 'getPriceQuoteRequestOrder'),

    async showDetail (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
        return
      }
      await this.getApprovedOrdersDetail(item.id).then(res => {
        if (res) {
          this.SHOW_APPROVED_ORDER_DETAIL({ headerId: item.id, data: res.data })
        }
      })
    },

    getApprovalStatus (status) {
      switch (status) {
        case 0:
          return '-'
        case 1:
          return this.$t('Request.table_Header_field_StatusWaitingForApproval')
        case 2:
          return this.$t('ApproveOperations.approval_Notification_message')
        case 3:
          return this.$t('Global.declined')
      }
    },

    resetStockItemFilter () {
      this.selectedStockItem = []
      this.getFilteredRequestOrders()
    },

    async isFormValid () {
      let checkState = []
      await Promise.all(this.selectedOrders.map(async item => {
        await this.$validator.validateAll('request-order-' + item.id).then(res => {
          checkState.push(res)
        })
      }))
      this.validForm = checkState.every(i => i === true)
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) {
        this.resetStockItemFilter()
        this.clearSearch()
      } else {
        this.searchStockItem({ Text: search, types: [1, 2] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },

    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.resetStockItemFilter()
    },

    clearSearch () {
      this.search = null
      this.stockItem = {}
    },

    clearFilter () {
      this.$refs.requestedOrderStoreListFilter.clearStoreFilter()
      this.$refs.requestedOrderGroupFilter.clearGroupFilter(false)
    },

    sortItemsByDate () {
      this.SORT_BY_DATE_ORDER_LIST()
    },

    getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.storeFilter = list
        this.getFilteredRequestOrders()
      }
    },
    getListWithGroupFilter ({list, dontSubmit}) {
      if (dontSubmit) this.groupFilter = list
      else {
        this.groupFilter = list
        this.getFilteredRequestOrders()
      }
    },
    changePage (page) {
      this.selectedItems = []
      this.getFilteredRequestOrders(page)
    },
    changePageSize (pageSize) {
      this.selectedItems = []
      this.getFilteredRequestOrders(1, pageSize)
    },
    async getApprovedOrderList (pageNumber = 1, pageSize = this.OrderPage.size) {
      this.isApprovedOrder = !this.isApprovedOrder
      const payload = {
        storeList: this.storeFilter ? this.storeFilter.map(s => s.id) : [],
        stockItemId: this.stockItem ? this.stockItem.id : null,
        pageNumber: pageNumber,
        pageSize: pageSize
      }
      const response = await this.getApprovedOrders(payload)
      return response
    },
    async getFilteredRequestOrders (pageNumber = 1, pageSize = this.OrderPage.size) {
      const payload = {
        storeList: this.storeFilter ? this.storeFilter.map(s => s.id) : [],
        stockItemId: this.stockItem ? this.stockItem.id : null,
        itemGroupIds: this.groupFilter ? this.groupFilter.map(item => item.id) : [],
        date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
        pageNumber: pageNumber,
        pageSize: pageSize
      }
      const response = await this.getRequestOrders(payload)
      this.sortItemsByDate()
      return response
    },

    selectCalcPrice (item, detail) {
      this.calcPriceInfo(detail)
    },

    async calcPriceInfo (detail) {
      const selectTax = await this.Taxes.find(t => t.id === detail.taxId)
      const totalAmount = detail.price * (1 - (detail.discount1 / 100)) * (1 - (detail.discount2 / 100)) * detail.quantity
      const taxAmount = selectTax ? (totalAmount * (selectTax.ratio / 100)) : 0
      const grossAmount = totalAmount + taxAmount
      const payload = {
        netAmount: totalAmount,
        grossAmount,
        taxAmount
      }
      this.UPDATE_R_ORDER_LIST({ detail, payload })
    },

    async changeSupplier (selected, detail) {
      if (this.$wait.is(['fetchingAll'])) return
      this.isMakeRequestActive = true
      const payload = {
        supplierId: selected.id,
        stockItemId: detail.stockItemId,
        unitId: detail.unitId,
        date: moment(detail.requestDate).format('YYYY-MM-DD')
      }
      const res = await this.getPriceQuoteWithSupplier(payload)
      if (res) {
        let { priceQuote } = res.data
        priceQuote ? this.isPriceQuoteActive = priceQuote.isActive : this.isPriceQuoteActive = true
        if (priceQuote && priceQuote.priceType) detail.priceType = priceQuote.priceType
        const payload = {
          price: priceQuote ? priceQuote.unitPrice : detail.price,
          discount1: priceQuote ? priceQuote.discount1 : detail.discount1,
          discount2: priceQuote ? priceQuote.discount2 : detail.discount2
        }
        this.UPDATE_R_ORDER_LIST({ detail, payload })
        this.calcPriceInfo(this.orders.find(el => el.id === detail.id))
        this.$validator.validateAll('request-order-' + detail.id)
      }
    },

    changeDate (detail) {
      const payload = {
        date: moment(detail.requestDate).format('YYYY-MM-DD')
      }
      this.UPDATE_R_ORDER_LIST({ detail, payload })
      this.sortItemsByDate()
    },

    toggleAll (isChecked) {
      this.isMakeRequestActive = false
      if (isChecked) this.selectedOrders = [...this.orders]
      else this.selectedOrders = []
    },

    toggleSelectOrder (order) {
      if (order.price && order.supplierId !== '-1') this.isMakeRequestActive = true
      else this.isMakeRequestActive = false
      const isChecked = !this.selectedOrders.some(item => item.id === order.id)
      if (isChecked) this.selectedOrders.push(order)
      else this.selectedOrders.splice(this.selectedOrders.findIndex(item => item.id === order.id), 1)
    },

    createRequests: vueWaitLoader(async function () {
      if (!this.validForm) return
      let selectedListItems = this.selectedOrders.map(i => this.orders.find(el => el.id === i.id))
      const approvedRequestList = selectedListItems.map(request => {
        return {
          ...getObjectValuesByKey(request, ['requestDetailId', 'quantity', 'price', 'discount1', 'discount2', 'taxId']),
          supplierId: request.supplierId,
          requestDate: moment(request.requestDate).format('YYYY-MM-DD'),
          netAmount: Number(request.netAmount.toFixed(3)),
          grossAmount: Number(request.grossAmount.toFixed(3)),
          taxAmount: Number(request.taxAmount.toFixed(3)),
          quantity: Number(request.quantity)
        }
      })
      const res = await this.makeRequestApprove(approvedRequestList)
      if (res) {
        const message = this.$t('Orders.create_success_message')
        this.notifyShow({ message })
        this.selectedOrders = []
        this.$validator.reset()
        this.isMakeRequestActive = false
        await this.getFilteredRequestOrders(this.OrderPage.number)
      }
    }, 'createRequests'),

    makeOrderPassive: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        const payload = {
          ids: this.selectedOrders.map(item => { return item.id }),
          statusReason: this.reasonDescription
        }
        await this.makeOrderRequestPassive(payload)
          .then(async res => {
            const message = this.$t('Orders.make_order_Passive')
            this.notifyShow({ message })
            this.hideReasonPopup()
            this.reasonDescription = null
            this.selectedOrders = []
            this.$validator.reset()
            this.isMakeRequestActive = false
            await this.getFilteredRequestOrders(this.OrderPage.number)
          })
      })
    }, 'makeOrderPassive'),

    showPassiveReasonForm () {
      this.showReasonButtonPopup = !this.showReasonButtonPopup
    },
    hideReasonPopup () {
      this.showReasonButtonPopup = false
    }
  },

  watch: {
    date (newVal, oldVal) {
      if (oldVal === newVal) return
      this.getFilteredRequestOrders(this.OrderPage.number)
    }
  },

  async mounted () {
    if (!this.hasTabPermission) return
    this.$wait.start('pageLoading')
    await this.getTenant({id: this.Tenant.id})
    await this.getTaxes()
    await this.getMiniSuppliersList()
    await this.getFilteredRequestOrders()
    this.$wait.end('pageLoading')
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";
.PageHeader {
  padding: 0;
}
.pageLoading {
    top: inherit;
    left: inherit;
  }
.PageContent {
  position: relative;
}

:deep() .toolbar {
  &.footer {
    position: absolute;
    right: 0;
    margin-top: 80px;
    background: none !important;
  }
  .order-passive-description-right-MiniPopup {
    @include MiniPopupMixin(
      $width: 296px,
      $margin: 45px 325px 0 0,
      $triangle-direction: 'right',
      $border-color: $color-light-blue,
      $padding-hor: 25px,
      $z-index: $z-index-xl,
    );
  }
}
.Orders {
  overflow-x: scroll;
  height: 100%;
  margin-bottom: 20px;
  position: static;
  background: $color-white;
  .custom-picker {
    .icon-arrow-down {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 16px;
      z-index: 2;
      color: $color-text-light;
    }
  }
  :deep() .Tableee-root {
    .Tableee-col {
      font-size: $font-size-small;
      &:nth-child(2) {
        padding-left: 0;
      }
      padding-right: 45px;
      &.quantity, &.requestDate, &.supplier, &.price, &.discount1, &.discount2 {
        padding-right: 0;
      }
      &.quantity, &.price {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type=number] {
          -moz-appearance: textfield;
        }
        .flexbox {
          place-content: flex-end;
        }
      }
      &.quantity, &.requestDate, &.supplier, &.price, &.tax {
        .flexbox {
          span {
            &::after {
              content: '*';
              color: $color-warning;
              margin-left: 4px;
              font-size: 18px;
            }
          }
        }
      }
    }
    .input-types {
      min-width: 90px;
    }
    .icon-arrow-down {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 16px;
      color: $color-text-light;
    }
  }
  :deep() .Tableee-head {
    .Tableee-col {
      border: none;
    }
  }
  :deep() .Tableee-body {
    .Tableee-col {
      height: 59px;
      border: none;
      z-index: $z-index-xs;
    }
  }
}

:deep() .select-dropdown-custom-options.upside {
  bottom: unset !important;
}

  :deep() .Tableee-head .Tableee-col.fixed {
    &:first-child{
      z-index: $z-index-md;
    }
    &:nth-child(2) {
      left: 80px;
      z-index: $z-index-md;
    }
    &:nth-child(3) {
      left: 150px;
      z-index: $z-index-md;
    }
    &:nth-child(4) {
      left: 270px;
      z-index: $z-index-md;
    }
  }

  :deep() .Tableee-body .Tableee-row td.fixed {
    &:nth-child(2) {
      left: 80px;
      z-index: $z-index-xs;
    }
    &:nth-child(3) {
      left: 150px;
      z-index: $z-index-xs;
    }
    &:nth-child(4) {
      left: 270px;
      z-index: $z-index-xs;
    }
  }
  :deep() .popup {
    z-index: $z-index-lg !important;
  }
  :deep() .Tableee-main.fixed {
    padding-top: unset !important;
  }
  :deep() .dropdown-handle{
    height: 100% !important;
  }
  :deep() .select-dropdown-custom-selected-button {
    margin: 5px !important;
  }

</style>
