export default function getFields($t) {
  return [
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_storeName')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_storeName')
      }
    },
    {
      name: 'storeParameter',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_storeParameter')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_storeParameter')
      }
    },
    {
      name: 'executionDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_executionDate')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_executionDate')
      }
    },
    {
      name: 'operation',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_operation')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_operation')
      }
    },
    {
      name: 'situation',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_situation')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_situation')
      }
    },
    {
      name: 'message',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_message')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_message')
      }
    },
    {
      name: 'dismissReason',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_dismissReason')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_dismissReason')
      }
    }
  ]
}
