<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-left-search")
    Search.search-box(
      componentName="stock-reports",
      suggestionValue="name"
      :isSuggestionLoading="isStockReportsLoading"
      :suggestions="suggestions"
      :customSuggestionPlaceHolder="$t('StockReports.search_Placeholder')"
      :customSuggestionTitle="$t('StockReports.search_SuggestionTitle')"
      @onChangeSearchValue="onChangeSearch"
      @onChangeQueryItem="setStockItem"
      @clearSearch="clearSearch"
    )
  template(slot="header-right-dropdown")
    .form-item-select.date-start.m-right-10
        CustomDatepicker#StockReportDatepicker(
          v-model="dates",
          :isLoading="$wait.is('getDateFilterSettings')"
          :range="true"
          :disabled="isStockReportsLoading || $wait.is('pageLoading')"
        )

  template(slot="overgroups-left")

    button.group-types-item(
        v-for="groupType in groupTypes"
        type="button"
        :id="`button-stock-reports-group-${groupType.value}`"
        :class="{ active: groupType.value === activeGroupType }"
        @click="onChangeGroupType(groupType)")
          | {{ groupType.name.toLocaleUpperCase($i18n.locale) }}

  template(slot="toolbar-left")
    CustomMultiselect.toolbar-filters__item(
      id="stock-reports-store-filter"
      :haveDefaultStore="true"
      :items="storeList"
      :selected="storeFilter"
      :label="$t('StockReports.toolbar_Stores_Label')"
      unselectedText=""
      :popupHeader="$t('StockReports.toolbar_Stores_PopupHeader')"
      :showAll="false"
      :buttonText="$t('StockReports.toolbar_Stores_ButtonText')"
      type="radio"
      buttonIconLeft="btn-success"
      @delegateCheckItem="onChangeStoreFilter"
      @delegateSubmitItems="getStockReportsWithFilters"
      @delegateSetDefaultStore="setDefaultStore"
    )

    businessMultiselect(
      componentName="transaction-types-filter"
      :label="$t('StockReports.tableColumn_TransactionType')"
      :popupHeader="$t('StockReports.select_TransactionType')"
      :multiselectItems="tTypeSelectItems"
      @submitFilter="submitFilter"
    )

  template(slot="toolbar-right")
    ImportExport(
      componentName="reports-Stock"
      ref="wrapper"
      :isDisabled="downloadButtonDisabled"
      :activeImportExports=['export']
      :downloadParams="downloadParams"
      :downloadUrl="downloadUrl"
      :downloadFileName="downloadFileName"
      downloadFileType="xlsx"
    ) 
  template(slot="content-center")
    .StockReportPageContent



      TableLoading(v-if="isStockReportsLoading")

      .infoStockReport(v-if="!isStockReportsLoading && !isStockReportsEmpty")
        span(v-html="$t('StockReports.result_HeaderInfo', { stockItemName: stockItem.name })")

      TableView(
      v-if="!isStockReportsLoading && !isStockReportsEmpty"
      :fields="stockFields"
      :readOnly="true"
      :componentName="'ReportsStocksTable'"
      :data="stockReports"
      :pointer="true"
      :page="page"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemShow="goDetail")

      EmptyState(
      v-if="shouldShowNotFound || !stockItem"
      :title="!stockItem ? $t('StockReports.emptyState_makeSelection_Title') : $t('StockReports.emptyState_NotFound_Title')"
      :description="!stockItem ? $t('StockReports.emptyState_makeSelection_Description') :$t('StockReports.emptyState_NotFound_Description')")

</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
//
import BusinessMultiselect from '@/view/global/business-filters/business-multiselect'
import getDetailedStockFields from './fields/stock-detailed'
import getDailyStockFields from './fields/stock-daily'
import getTotalStockFields from './fields/stock-total'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import ImportExport from '@/view/global/import-export'

export default {
  name: 'StockReports',

  components: {
    ImportExport,
    BusinessMultiselect,
  },

  data () {
    return {
      dates: [],
      stockFields: getDetailedStockFields(this.$t),
      search: '',
      suggestions: [],
      isSuggestionsLoading: false,
      stockItem: null,
      selectedStockItem: false,
      storeFilter: [],
      activeGroupType: 'detailed',
      groupTypes: [{
        name: this.$t('StockReports.groupTypes_Detailed'),
        value: 'detailed'
      }, {
        name: this.$t('StockReports.groupTypes_Daily'),
        value: 'daily'
      }, {
        name: this.$t('StockReports.groupTypes_Total'),
        value: 'total'
      }],
      tTypeSelectItems: [],
      selectedType: [],
      routeData: null
    }
  },

  async created () {
    this.$wait.start('pageLoading')
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_StockItemTransactionReport')
    await this.getTransactionTypes()
    this.TransactionTypeList.forEach(i => {
      this.tTypeSelectItems.push({id: i.filterType, name: i.filterTypeName})
    })
    await this.getMiniStoreList()
    await this.fetchUser()
      .then(res => {
        const defaultStore = this.storeList.filter(item => item.id === res.data.user.defaultStoreId)
        this.storeFilter = defaultStore
      })
    this.$wait.end('pageLoading')
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getStockReportsWithFilters()
    },
  },

  computed: {
    ...mapGetters('Reports', [
      'stockReports',
      'isStockReportsEmpty',
      'page',
      'TransactionTypeList'
    ]),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList'
    }),

    ...mapGetters('OnHand', [
      'pageHeaderLinks'
    ]),

    shouldShowNotFound () {
      return (
        !this.isStockReportsLoading &&
        !this.isSuggestionsLoading &&
        this.isStockReportsEmpty &&
        this.stockItem
      )
    },

    isSuggestionsEmpty () {
      return this.suggestions.length === 0
    },

    isStockReportsLoading () {
      return this.$wait.is(['getStockReports'])
    },

    downloadUrl () {
      return `Report/stockitemtransaction${this.activeGroupType}/export`
    },
    downloadParams () {
      const stockItem = this.stockItem ? this.stockItem.id : ''
      const storeId = this.storeFilter.length > 0 ? this.storeFilter[0].id : ''
      const params = {
        StartDate: this.dateFormat(this.dates[0]),
        EndDate: this.dateFormat(this.dates[1]),
        StockItemId: stockItem,
        StoreId: storeId
      }

      return params
    },

    downloadFileName () {
      const fileName = `${this.activeGroupType}-StockItemTransactionReport_${this.dateFormat(this.dates[0])}_${this.dateFormat(this.dates[1])}`
      return fileName
    },

    downloadButtonDisabled () {
      return this.storeFilter.length === 0 || !this.stockItem
    }
  },

  beforeDestroy () {
    this.resetStockReports()
  },

  methods: {
    ...mapActions('Reports', [
      'getStockReports',
      'resetStockReports',
      'downloadReports',
      'getTransactionTypes'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapActions('Stores', [
      'getMiniStoreList'
    ]),

    ...mapActions('Settings', [
      'fetchUser'
    ]),

    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    changePage (page) {
      this.getStockReportsWithFilters(page)
    },

    changePageSize (pageSize) {
      this.getStockReportsWithFilters(1, pageSize)
    },

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    setDefaultStore (event) {
      this.storeFilter = event
    },

    async goDetail (item) {
      if (!this.routeData) {
        if (item.recipeHeaderId) this.routeData = this.$router.resolve({name: 'Recipe', params: { recipeHeaderId: item.recipeHeaderId }})
        else {
          switch (item.transactionType) {
            case 1:
              this.routeData = this.$router.resolve({name: 'DespatchDocumentDetail', params: { id: item.despatchHeaderId, documentNumber: item.documentNumber }})
              break
            case 3:
              this.routeData = this.$router.resolve({name: 'Inventory', params: { id: item.inventoryHeaderId, status: item.inventoryStatus, inventoryDate: item.inventoryDate, storeName: item.storeName }})
              break
            case 4:
              this.routeData = this.$router.resolve({name: 'RefundDocumentDetail', params: { id: item.despatchHeaderId, documentNumber: item.documentNumber }})
              break
            case 5:
              this.routeData = this.$router.resolve({name: 'TransferDocumentDetail', params: { id: item.transferHeaderId, documentNumber: item.documentNumber }})
              break
            case 6:
              this.routeData = this.$router.resolve({name: 'TransferDocumentDetail', params: { id: item.transferHeaderId, documentNumber: item.documentNumber }})
              break
            case 7:
              this.routeData = this.$router.resolve({name: 'WasteDocumentDetail', params: { id: item.wasteHeaderId, documentNumber: item.documentNumber }})
              break
            case 8:
              this.routeData = this.$router.resolve({name: 'ProductionDocumentDetail', params: { id: item.productionHeaderId, documentNumber: item.documentNumber }})
              break
            case 9:
              this.routeData = this.$router.resolve({name: 'ProductionDocumentDetail', params: { id: item.productionHeaderId, documentNumber: item.documentNumber }})
              break
            case 10:
              this.routeData = this.$router.resolve({name: 'YieldDocumentDetail', params: { id: item.yieldHeaderId, documentNumber: item.documentNumber }})
              break
            case 11:
              this.routeData = this.$router.resolve({name: 'YieldDocumentDetail', params: { id: item.yieldHeaderId, documentNumber: item.documentNumber }})
              break
            default:
              return
          }
        }
        window.open(this.routeData.href, '_blank')
        this.routeData = null
      }
    },

    clearSearch () {
      this.search = ''
      this.stockItem = null
      this.suggestions = []
      this.resetStockReports()
    },

    onChangeStoreFilter (event) {
      this.storeFilter = [event.item]
    },

    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.selectedStockItem = true
      this.suggestions = []
      this.getStockReportsWithFilters()
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) this.clearSearch() 
      else {
        this.searchStockItem({ Text: search, types: [1, 2 , 3] })
          .then(res => {
            this.suggestions = res.data.items
          })
      }
    },
    
    getStockReportsWithFilters (to = 1, pageSize = this.page.size) {
      if (this.stockItem && this.storeFilter.length > 0) {
        const payload = {
          typeUrlEndPoint: this.activeGroupType,
          start: this.dateFormat(this.dates[0]),
          end: this.dateFormat(this.dates[1]),
          item: this.stockItem,
          storeId: this.storeFilter[0].id,
          to,
          pageSize,
          typeList: this.selectedType
        }
        this.getStockReports(payload)
      }
    },

    onChangeGroupType (groupType) {
      this.activeGroupType = groupType.value
      this.getStockReportsWithFilters()
      switch (groupType.value) {
        case 'daily':
          this.stockFields = getDailyStockFields(this.$t)
          break
        case 'total':
          this.stockFields = getTotalStockFields(this.$t)
          break
        case 'detailed':
          this.stockFields = getDetailedStockFields(this.$t)
          break

        default:
          break
      }
    },
    submitFilter (item) {
      this.selectedType = item.list.map(item => {
        return `typeList=${item.id}`
      })
      this.getStockReportsWithFilters()
    }
  }
}
</script>

<style lang="scss" scoped>
.PageHeader {
    .right {
      display: flex;

      .form-item-select {
        width: 200px;
        margin-left: 10px;
      }
    }
  }

  .PageHeaderExtra {
    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-gray;
  }

  .infoStockReport {
    color: $color-light;
    margin-bottom: 25px;
  }
  .group-types {

    &-item {
      padding: 8px 10px;
      display: inline-block;
      font-weight: $font-weight-bold;
      font-size: $font-size-micro;
      line-height: 1;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;
      user-select: none;
      background: $color-white;
      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        background: $color-success;
        color: #fff;
        border-color: transparent;
      }
    }

}
</style>
