<template lang="pug">
.Despatches(v-if="renderComponent")
    .single-head
        .single-head-col.checkbox
          CustomCheckbox(
            id="checkbox-despatch-header-select-all"
            :checked="!hideAllCheckbox ? isCheckedAll : false"
            label=""
            :disabled="hideAllCheckbox"
            @change="toggleAll"
          )
        .single-head-col.name(id="despatches-table-header-supplier-name")
          h6 {{ $t('Despatches.tableColumn_Header_SupplierName') }}
        .single-head-col.parent-store-name(id="despatches-table-header-parentStore-name" v-if="tenantMultiCurrencyIsActive || tenantMustHaveSingleStoreInSameDespatchActive")
          h6 {{ $t('Despatches.tableColumn_Header_ParentStoreName') }}
        .single-head-col.date(id="despatches-table-header-supplier-date")
          h6 {{ $t('Despatches.tableColumn_DespatchDate') }}
        .single-head-col.number(id="despatches-table-header-supplier-number")
          h6 {{ $t('Despatches.tableColumn_DespatchNumber') }}
        .single-head-col.net(id="despatches-table-header-supplier-net-amount")
          h6.u-textRight.full-width {{ $t('Despatches.tableColumn_NetTotalAmount') }}
        .single-head-col.tax(id="despatches-table-header-supplier-tax-amount")
          h6.u-textRight.full-width {{ $t('Despatches.tableColumn_TaxTotalAmount') }}
        .single-head-col.gross(id="despatches-table-header-supplier-gross-total-amount")
          h6.u-textRight.full-width {{ $t('Despatches.tableColumn_GrossTotalAmount') }}
    .Despatches-item(
    v-for="(despatch, index) in tableData",
    :class="{ 'isOpened': despatch.showDetail , 'small-size': true, 'border-blue': despatch.showDetail }")

      // HEADER
      //
      .Despatches-header(tabindex="0")
        .NestedTableHeaderLink(
            :id="`btn-despatches-open-header-${index}`"
            @click="showDetailAction(despatch)")
        .Despatches-header-col.checkbox(:id="`status-despatches-${index}`")
          StatusTooltip(
            :title="$t('Despatches.HeaderNotSaved')"
            icon="icon-row-status"
            v-if="despatch.status === 1"
          )

          CustomCheckbox(
            :id="`checkbox-despatch-header-${index}`"
            :checked="selected.indexOf(despatch) > -1"
            :class="{'hide-checkbox': despatch.status === 1}"
            label=""
            :disabled="firstSelectedSupplierId(despatch.supplier.id) && !isSelectedOneMoreThanSupplier"
            @change="isChecked => clickCheckbox(isChecked, despatch)"
          )

        // header info
        .Despatches-header-col.name.ellipsis(
          :id="`despatch-header-supplier-container-${index}`"
        )
          span.tooltip-relative.word-break-all(:id="`despatch-header-supplier-span-${index}`") {{ despatch.supplier.name }}
            Tooltip(
              dark,
              bottom,
              :id="`despatch-header-supplier-tooltip-${index}`"
              :text="getStoreGroupsCode(despatch.storeGroups)"
            )
        .Despatches-header-col.parent-store-name.ellipsis(
          :id="`despatch-header-parentStore-container-${index}`"
          v-if="tenantMultiCurrencyIsActive || tenantMustHaveSingleStoreInSameDespatchActive"
        )
          span.tooltip-relative.word-break-all(:id="`despatch-header-parentStore-span-${index}`") {{ despatch.parentStoreName }}
            Tooltip(
              dark,
              bottom,
              :id="`despatch-header-parentStore-tooltip-${index}`"
              :text="despatch.parentStoreName"
            )
        .Despatches-header-col.date(
          :id="`despatch-header-date-container-${index}`"
        )

          CustomDatepicker(
            v-if="despatch.status === 1"
            :name="`despatch-date-${index}`",
            :id="`dp-invoice-header-date-${index}`"
            :value="tableData[index].despatchDate"
            :class="{ 'is-danger': veeErrors.has(`despatch-date-${index}`) }"
            v-validate="'required'"
            pickerType="manuel",
            @change="e => sendHeader(tableData[index], {date: e, number: tableData[index].despatchNumber})",
            inputIconRight=""
            inputIcon="",
            :disabledStartDate="disableDate")
          span(v-else) {{ despatch.despatchDate | dateFormat }}

        .Despatches-header-col.number(:id="`despatch-header-number-container-${index}`")

          input.txt(
            v-if="despatch.status === 1"
            :id="`input-despatch-number-${index}`"
            :name="`despatch-number-${index}`"
            :class="{ 'is-danger': veeErrors.has(`despatch-number-${index}`) }"
            type="text"
            v-default-value="tableData[index].despatchNumber"
            v-validate="'required|max:32'"
            autocomplete="off"
            @change="e => sendHeader(tableData[index], {date: tableData[index].despatchDate, number: e.target.value})",
          )
          span(v-else) {{ despatch.despatchNumber }}
        .Despatches-header-col.net.ff-mono(:id="`net-sum-${index}`") 
          .u-textRight.full-width {{ getAmount(despatch, 'netTotalAmount') }}
        .Despatches-header-col.tax.ff-mono.u-textRight(:id="`tax-sum-${index}`") 
          .u-textRight.full-width {{ getAmount(despatch, 'taxTotalAmount') }}
        .Despatches-header-col.gross.ff-mono.u-textRight(:id="`gross-sum-${index}`") 
          .u-textRight.full-width {{ getAmount(despatch, 'grossTotalAmount') }}
       
        .Despatches-header-col.decompose
          Button.show-MiniPopup.relative.width-max-content(
            v-if="despatch.description",
            size="small"
            variant="info"
            :id="`despatch-header-info-button-${despatch.index}`"
            iconName="icon-global-info"
            :justIcon="true"
          )
            .MiniPopup-right-primary.m-top-15.c-dark.word-break-all
              span {{ despatch.description }}

        .Despatches-header-col.decompose
            Button.show-MiniPopup.relative.width-max-content(
              v-if="despatch.decomposedInvoiceNumber"
              size="small"
              variant="info"
              :id="`decomposed-invoice-number-info-button-${index}`"
              iconName="icon-request-description"
              :justIcon="true"
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ despatch.decomposedInvoiceNumber }}
        .Despatches-header-col.action
          Button(
            :justIcon="true"
            ghostsuccess
            variant="icon"
            iconName="icon-arrow-down"
            :id="`btn-despatches-open-header-${index}`"
            @click="showDetailAction(despatch)")
          ActionButton(
            id="price-quote-action-button"
            :data="checkActionStatus(despatch)"
            :item="despatch"
            :customActionName="$t('Despatches.makeTransfer')"
            @onItemEdit="editAction(despatch)"
            @onItemExport="showExportDespatch(despatch.id)"
            @onItemDelete="removeItemAction(despatch)"
            @onItemCustomAction="customAction(despatch)"
          )

      // DETAIL
      //
      Details(
        v-if="despatch.showDetail && dataType === 'Despatches'",
        :despatch="despatch"
        :isCanEdit="(((!despatch.supplier.isGlobal) || (despatch.supplier.isGlobal && RoleArea === 'global')))"
        @updateHeaderTotal="calculateHeaderTotal"
        :rowIndex="index"
      )

</template>

<script>
import Details from './despatches-detail'
import StatusTooltip from '@/view/global/alert-status-tooltip'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import { formatCurrency2Digits } from '@/utils/filters'

export default {
  name: 'DespatchesTable',

  components: {
    Details,
    StatusTooltip
  },
  props: {
    dataType: {
      default: 'Despatches',
      type: String
    },
    RoleArea: {
      type: String,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    showDetailAction: {
      type: Function,
      required: true
    },
    showExportDespatch: {
      type: Function,
      required: true
    },
    editAction: {
      type: Function,
      required: true
    },
    customAction: {
      type: Function,
      required: true
    },
    removeItemAction: {
      type: Function,
      required: true
    },
    isSelectedOneMoreThanSupplier: {
      type: Boolean,
      required: true
    },
    selected: {
      type: Array
    }
  },

  data () {
    return {
      disableDate: {
        from: new Date()
      },
      renderComponent: true,
      showCurrencySymbol: false
    }
  },

  mounted () {
    this.showCurrencySymbol = this.UserInfo.showCurrencySymbol
  },

  computed: {
    ...mapGetters('Settings', ['checkPermission', 'UserInfo','tenantMultiCurrencyIsActive','tenantMustHaveSingleStoreInSameDespatchActive']),

    isCheckedAll () {
      return this.selected.length === this.tableData.filter(despatch => despatch.status !== 1).length
    },
    hideAllCheckbox () {
      return this.tableData.filter(despatch => despatch.status !== 1).length === 0
    }
  },

  methods: {
    ...mapActions('Despatch', [
      'update_DESPATCH_HEADER',
      'checkDespatchNumber'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapMutations('Despatch', [
      'UPDATE_DESPATCH_ITEM'
    ]),

    getAmount (despatch, key) {
      let amount = formatCurrency2Digits(despatch[key])

      if (this.showCurrencySymbol && despatch[key]) {
        amount = `${despatch.currencyDefinitionSymbol}${amount}`
      }

      return amount
    },

    showActionBar (item) {
      item.showActions = !item.showActions
      this.forceRerender()
    },
    checkActionStatus (despatch) {
      if (despatch.status === 1) return ['export','edit', 'remove']
      else if (despatch.status !== 1 && this.checkPermission('TransferMenu')) return ['export', 'customAction']
      else return ['export']
    },
    closeActionBar (item) {
      item.showActions = false
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    calculateHeaderTotal (despatch) {
      let netTotalAmount = null
      let taxTotalAmount = null

      let copyDespatch = JSON.parse(JSON.stringify(despatch))
      despatch.despatchDetailList.forEach(detail => {
        if (detail.quantity) netTotalAmount += detail.discountedNetUnitPrice
        if (detail.quantity) taxTotalAmount += detail.taxAmount
      })

      copyDespatch.netTotalAmount = netTotalAmount
      copyDespatch.taxTotalAmount = taxTotalAmount
      if ((netTotalAmount !== null) && (taxTotalAmount !== null)) copyDespatch.grossTotalAmount = netTotalAmount + taxTotalAmount
      else { copyDespatch.grossTotalAmount = null }
      this.UPDATE_DESPATCH_ITEM(copyDespatch)
    },

    clickCheckbox (isChecked, despatch) {
      this.$emit('delegateCheckItem', { isChecked, despatch })
    },

    firstSelectedSupplierId (supplierId) {
      if (this.selected.length > 0 && !this.isCheckedAll) {
        return supplierId !== this.selected[0].supplier.id
      } else {
        return false
      }
    },

    toggleAll (isChecked) {
      this.$emit('delegateCheckAllItem', isChecked)
    },

    sendHeader (despatch, nextDespatch) {
      if (moment(despatch.despatchDate).format('YYYY-MM-DD') === moment(nextDespatch.date).format('YYYY-MM-DD') && despatch.despatchNumber === nextDespatch.number) return

      this.$validator.validateAll().then((result) => {
        if (!result) return
        const params = {
          id: despatch.id,
          despatchDate: moment(nextDespatch.date).format('YYYY-MM-DD'),
          despatchNumber: nextDespatch.number
        }
        this.update_DESPATCH_HEADER(params)
          .then(res => {
            if (res.status === 200) {
              const message = this.$t('Despatches.popup_Notificiation_Updated', {
                number: despatch.despatchNumber
              })
              console.warn(nextDespatch)

              this.UPDATE_DESPATCH_ITEM({...despatch, despatchNumber: nextDespatch.number, despatchDate: nextDespatch.date})
              this.notifyShow({ message })
            }
          })
      })
    },

    getStoreGroupsCode (storeGroupList) {
      if (storeGroupList.length === 0) return ''
      return storeGroupList.map(item => !item.code ? item.name : item.code).join(', ')
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";
  .Despatches {
    border: solid 1px $color-gray;
    border-radius: $border-radius;
    .btn-edit {
      color: $color-light;
      background-color: $color-gray;
    }

    .btn-remove {
      color: $color-warning;
      background-color: #fcedec;
    }

    .btn-switch {
      color: $color-success;
    }

    &-item.isOpened .btn-switch {
      transform: rotate(180deg);
    }

    &-item {
      line-height: 1;
      background: $color-white;
      &:nth-child(odd) {
        background-color: $color-snow;
      }
      &.border-blue{
        border: solid 1px $color-primary;
      }
      &:hover {
        &:not(.isOpened) {
          background: $color-ocean;
          border:1px solid $color-light-blue;
        }
      }
    }

    &-header {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      border-radius: inherit;

      .Despatches-item.isOpened & {
        background-color: $color-lighter-blue;
      }

      &-col {
        padding: 9px 12px;
        .checkbox {
          margin-left: 1%;
        }

        .txt {
          min-height: 43px;
        }

        .title {
          font-size: $font-size-micro;
          color: $color-success;
          margin-bottom: 6px;
        }

        .ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.name {
          width: 10%;
        }

        &.parent-store-name {
          width: 10%;
        }

        &.checkbox {
          margin-left: 15px;
        }

        &.date {
          width: 12%;

          :deep() .datepicker-ui {
            .input-field {
              min-width: unset;
              display: unset;
              padding-left: unset;
              padding-right: unset;
            }
            
            .txt {
              padding-left: unset;
              padding-right: unset;
            }

            .input {
              justify-content: center;
            }
          }
        }

        &.number {
          width: 16%;
          position: relative;
        }

        &.net {
          width: 10%;
        }

        &.tax {
          width: 10%;
        }

        &.gross {
          width: 10%;
        }

        &.decompose {
          width: 40px;
          padding: 4px
        }

        &.action {
          margin-left: auto;
          display: flex;

          .body {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    &-details {
      padding: 20px;
    }

    &-header .action {
      opacity: 1;
      transition: opacity $transition;
    }

    &-header:hover > .action,
    &-header:focus-within > .action {
      opacity: 1;
    }

  }

  .hide-checkbox {
    visibility: hidden;
  }

.single-head {
  background-color: $color-lighter-blue;
  display: flex;
  align-items: center;
  height: $finger-size-big;
  border-radius: $border-high-radius;

    .name {
      width: 10%;
    }

    .parent-store-name {
      width: 10%;
    }

    .date {
      width: 12%;
    }

    .number {
      width: 16%;
    }

    .net {
      width: 10%;
    }

    .tax {
      width: 10%;
    }

    .gross {
      width: 10%;
    }

  &-col {
    font-family: $font-family;
    font-size: $font-size-big;
    font-weight: bold;
    flex-shrink: 0;
    padding: 15px 12px;

    &.checkbox {
        margin-left: 15px;
    }

    h6 {
      font-size: $font-size-small;
      color: $color-success;
    }
  }
}

.tooltip-relative {
  cursor: pointer;
}

.Despatches-header-decompose{
  position: absolute;
  right: 5%;
  color: $color-success;
}
.net, .tax, .gross {
  display: flex;
}

.full-width {
  width: 100%;
}
</style>
