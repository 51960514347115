var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "stock-reports",
              suggestionValue: "name",
              isSuggestionLoading: _vm.isStockReportsLoading,
              suggestions: _vm.suggestions,
              customSuggestionPlaceHolder: _vm.$t(
                "StockReports.search_Placeholder"
              ),
              customSuggestionTitle: _vm.$t(
                "StockReports.search_SuggestionTitle"
              ),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.setStockItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select date-start m-right-10" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "StockReportDatepicker",
                isLoading: _vm.$wait.is("getDateFilterSettings"),
                range: true,
                disabled:
                  _vm.isStockReportsLoading || _vm.$wait.is("pageLoading"),
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "overgroups-left" },
        _vm._l(_vm.groupTypes, function (groupType) {
          return _c(
            "button",
            {
              staticClass: "group-types-item",
              class: { active: groupType.value === _vm.activeGroupType },
              attrs: {
                type: "button",
                id: `button-stock-reports-group-${groupType.value}`,
              },
              on: {
                click: function ($event) {
                  return _vm.onChangeGroupType(groupType)
                },
              },
            },
            [_vm._v(_vm._s(groupType.name.toLocaleUpperCase(_vm.$i18n.locale)))]
          )
        }),
        0
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            staticClass: "toolbar-filters__item",
            attrs: {
              id: "stock-reports-store-filter",
              haveDefaultStore: true,
              items: _vm.storeList,
              selected: _vm.storeFilter,
              label: _vm.$t("StockReports.toolbar_Stores_Label"),
              unselectedText: "",
              popupHeader: _vm.$t("StockReports.toolbar_Stores_PopupHeader"),
              showAll: false,
              buttonText: _vm.$t("StockReports.toolbar_Stores_ButtonText"),
              type: "radio",
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckItem: _vm.onChangeStoreFilter,
              delegateSubmitItems: _vm.getStockReportsWithFilters,
              delegateSetDefaultStore: _vm.setDefaultStore,
            },
          }),
          _c("businessMultiselect", {
            attrs: {
              componentName: "transaction-types-filter",
              label: _vm.$t("StockReports.tableColumn_TransactionType"),
              popupHeader: _vm.$t("StockReports.select_TransactionType"),
              multiselectItems: _vm.tTypeSelectItems,
            },
            on: { submitFilter: _vm.submitFilter },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            attrs: {
              componentName: "reports-Stock",
              isDisabled: _vm.downloadButtonDisabled,
              activeImportExports: ["export"],
              downloadParams: _vm.downloadParams,
              downloadUrl: _vm.downloadUrl,
              downloadFileName: _vm.downloadFileName,
              downloadFileType: "xlsx",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "StockReportPageContent" },
          [
            _vm.isStockReportsLoading ? _c("TableLoading") : _vm._e(),
            !_vm.isStockReportsLoading && !_vm.isStockReportsEmpty
              ? _c("div", { staticClass: "infoStockReport" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("StockReports.result_HeaderInfo", {
                          stockItemName: _vm.stockItem.name,
                        })
                      ),
                    },
                  }),
                ])
              : _vm._e(),
            !_vm.isStockReportsLoading && !_vm.isStockReportsEmpty
              ? _c("TableView", {
                  attrs: {
                    fields: _vm.stockFields,
                    readOnly: true,
                    componentName: "ReportsStocksTable",
                    data: _vm.stockReports,
                    pointer: true,
                    page: _vm.page,
                  },
                  on: {
                    delegateOnChangePage: _vm.changePage,
                    delegateOnChangePageSize: _vm.changePageSize,
                    delegateOnItemShow: _vm.goDetail,
                  },
                })
              : _vm._e(),
            _vm.shouldShowNotFound || !_vm.stockItem
              ? _c("EmptyState", {
                  attrs: {
                    title: !_vm.stockItem
                      ? _vm.$t("StockReports.emptyState_makeSelection_Title")
                      : _vm.$t("StockReports.emptyState_NotFound_Title"),
                    description: !_vm.stockItem
                      ? _vm.$t(
                          "StockReports.emptyState_makeSelection_Description"
                        )
                      : _vm.$t("StockReports.emptyState_NotFound_Description"),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }