<template lang="pug">
form.Auth-form.ForgotPassword-form.Form(
  @submit.stop.prevent="onSubmit")

    h1.Auth-form-title {{ $t('ForgotPassword.formHeaderTitle') }}

    .Form-item
      p.description {{ $t('ForgotPassword.formDescription') }}

    .Form-item
      label.Form-item-label {{ $t('ForgotPassword.formItemEmail') }}
      .control
        input.txt(
        id="input-forgot-password-email"
        name="email",
        :data-vv-as="$t('ForgotPassword.formItemEmail')",
        v-model="form.emailAddress",
        v-validate="'required|email|max:128'",
        :class="{ 'is-danger': veeErrors.has('email') }",
        type="text"
        :placeholder="$t('ForgotPassword.formItemEmailPlaceholder')")
      p.Form-item-help.is-danger(
      v-show="veeErrors.has('email')")
        | {{ veeErrors.first('email') }}

    .Form-item.x2
      Button(
      primary
      type="submit",
      size="medium"
      id="btn-forgot-password-button"
      variant="full"
      :disabled="!isFormReady || isLoading")
        span(v-show="isFormReady || !isLoading") {{ $t('ForgotPassword.formItemSubmit') }}
        Loading(theme="disable", v-show="isLoading")

    .Form-item.alert.x2(v-show="authAlert")
      p {{ errorStatusMessage ? $t(`ApiErrorCode.${errorStatusMessage}`) : $t('Login.formAlert') }}
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vee-validate'
import Notify from '@/view/global/notify'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      form: {
        emailAddress: null
      },
      authAlert: false,
      errorStatusMessage: null

    }
  },
  components: {
    Notify
  },
  computed: {
    isLoading () {
      return this.$wait.is(['ForgotPassword'])
    },
    ...mapFields({
      flagsEmail: 'email'
    }),
    isFormReady () {
      return this.flagsEmail.valid
    }
  },
  methods: {
    ...mapActions('Auth', [
      'ForgotPassword'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    async onSubmit () {
      const message = this.$t('ForgotPassword.successMessage')

      const result = await this.ForgotPassword({
        emailAddress: this.form.emailAddress.toLocaleLowerCase()
      })
      if (result) {
        this.notifyShow({ message })
      } else {
        this.errorStatusMessage = result
        this.authAlert = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.Form-item.alert {
  color: $color-warning;
  background-color: rgba($color-warning, 0.1);
  border-radius: $border-radius;
  border: 1px solid $color-warning;
  padding: 20px;
  margin-bottom: -20px;
}
</style>
