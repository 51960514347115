var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.PageLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-left-search" },
            [
              _c("Search", {
                staticClass: "search-box",
                attrs: {
                  componentName: "approveOperations",
                  suggestionValue: "name",
                  isSuggestionLoading: _vm.isSuggestionLoading,
                  suggestions: _vm.selectedStockItem,
                  customSuggestionPlaceHolder: _vm.$t(
                    "Request.search_Placeholder"
                  ),
                  customSuggestionTitle: _vm.$t(
                    "StockReports.search_SuggestionTitle"
                  ),
                },
                on: {
                  onChangeSearchValue: _vm.onChangeSearch,
                  onChangeQueryItem: _vm.setStockItem,
                  clearSearch: _vm.clearSearch,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _vm.isTransferPage || _vm.isApproveTransferPage
            ? _c("storeFilterComponent", {
                ref: "transferFromStoreFilter",
                attrs: { label: _vm.$t("ApproveOperations.fromStore") },
                on: { submitFilter: _vm.getListWithFromStoreFilter },
              })
            : _vm._e(),
          _c("storeFilterComponent", {
            ref: "transferToStoreFilter",
            attrs: {
              label:
                _vm.isTransferPage || _vm.isApproveTransferPage
                  ? _vm.$t("ApproveOperations.toStore")
                  : this.$t("Global.toolbar_Store_Label"),
              isDataAvailable: true,
            },
            on: { submitFilter: _vm.getListWithToStoreFilter },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                { staticClass: "TransferOperations" },
                [
                  _vm.isFetchingData || _vm.isPageLoading
                    ? _c("TableLoading")
                    : _vm._e(),
                  !_vm.isFetchingData && _vm.isFetchDataEmpty
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t(
                            "ApproveOperations.headerList_emptyState_NotFound_Title"
                          ),
                          description: _vm.$t(
                            "ApproveOperations.detailList_emptyState_NotFound_Description_Order"
                          ),
                        },
                      })
                    : _vm._e(),
                  !_vm.isFetchingData && !_vm.isFetchDataEmpty
                    ? _c(
                        "div",
                        [
                          _c("NestedTable", {
                            class: {
                              "transfer-table": _vm.isTransferPage,
                              "approval-order-table": _vm.isApproveOrderPage,
                              "order-table": _vm.isOrderPage,
                              "approval-transfer-table":
                                _vm.isApproveTransferPage,
                            },
                            attrs: {
                              fields: _vm.approvalOrderHeaderFields,
                              detailFields: _vm.approvalOrderDetailFields,
                              data: _vm.TransferApprovalList,
                              usePortalFooter:
                                _vm.isApproveOrderPage ||
                                _vm.isApproveTransferPage,
                              isDetailLoading: _vm.isFetchingDataDetail,
                            },
                            on: { showDetailAction: _vm.showDetail },
                          }),
                          _c("portal", {
                            attrs: { to: "footerPortal" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (rowData) {
                                    return _c(
                                      "div",
                                      { staticClass: "approval-buttons flex" },
                                      [
                                        _c(
                                          "Button",
                                          {
                                            attrs: {
                                              type: "button",
                                              size: "small",
                                              primary: "",
                                              disabled:
                                                _vm.userApprovalLevel !==
                                                rowData.authorizedUserLevel,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.approveSelectedOrder(
                                                  rowData
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "ApproveOperations.Button_Approve"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("miniPopupButton", {
                                          staticClass: "m-left-5",
                                          attrs: {
                                            data: rowData,
                                            buttonText: _vm.$t(
                                              "Transfer.Button_Reject"
                                            ),
                                            descText: _vm.$t(
                                              "Transfer.Reason_Description_Input_Label"
                                            ),
                                            isButtonDisable:
                                              _vm.userApprovalLevel !==
                                              rowData.authorizedUserLevel,
                                            agreeButtonText: _vm.$t(
                                              "Transfer.Detail_Col_Button_Agree"
                                            ),
                                          },
                                          on: {
                                            onSubmit: _vm.rejectSelectedOrder,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              4070037059
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "maxApprovalLevel" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (header) {
                                    return _c(
                                      "div",
                                      { staticClass: "approval-levels" },
                                      [
                                        header.row.approvalStatus > 0
                                          ? _c("ApprovalLevels", {
                                              attrs: {
                                                maxApprovalLevel:
                                                  header.row.maxApprovalLevel,
                                                currentApprovalLevel:
                                                  header.row
                                                    .currentApprovalLevel,
                                                approvalStatus:
                                                  header.row.approvalStatus,
                                                historyList:
                                                  _vm.historyListForOrders,
                                                isLoading: _vm.isHistoryLoading,
                                              },
                                              on: {
                                                showHistoryPopup: function (
                                                  $event
                                                ) {
                                                  return _vm.showHistoryPopup(
                                                    header.row
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3052400
                            ),
                          }),
                          !_vm.isApproveOrderPage && !_vm.isApproveTransferPage
                            ? _c("portal", {
                                attrs: { to: "headerCheckbox" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (header) {
                                        return _c(
                                          "div",
                                          {},
                                          [
                                            _c("CustomCheckbox", {
                                              attrs: {
                                                label: "",
                                                checked: _vm.isCheckedHeader(
                                                  header.row
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.toggleHeader(
                                                    header.row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3908928659
                                ),
                              })
                            : _vm._e(),
                          _c("portal", {
                            attrs: { to: "description" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (header) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        header.row.description
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass:
                                                  "show-MiniPopup relative width-max-content btn-info",
                                                attrs: {
                                                  id: `transfer-approval-header-info-button-${header.rowIndex}`,
                                                  iconName: "icon-global-info",
                                                  justIcon: true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "MiniPopup-right-primary m-top-15 c-dark",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          header.row.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              1977965723
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "info" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (header) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        header.row.info
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass:
                                                  "show-MiniPopup relative width-max-content btn-info",
                                                attrs: {
                                                  id: `transfer-approval-header-info2-button-${header.rowIndex}`,
                                                  iconName: "icon-global-info",
                                                  justIcon: true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "MiniPopup-right-primary m-top-15 c-dark",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(header.row.info)
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              290731657
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "description" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (detail) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        detail.row.description
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass:
                                                  "show-MiniPopup relative width-max-content btn-info",
                                                attrs: {
                                                  id: `transfer-approval-header-info-button-${detail.rowIndex}`,
                                                  iconName: "icon-global-info",
                                                  justIcon: true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "MiniPopup-right-primary m-top-15 c-dark",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          detail.row.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              202750299
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isFetchingData &&
                  !_vm.isFetchDataEmpty &&
                  !_vm.isApproveOrderPage &&
                  !_vm.isApproveTransferPage
                    ? _c("div", { staticClass: "approve-buttons" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: _vm.hideReasonPopup,
                                expression: "hideReasonPopup",
                              },
                            ],
                            staticClass: "popup-container",
                          },
                          [
                            _c(
                              "Button",
                              {
                                attrs: {
                                  type: "button",
                                  size: "medium",
                                  danger: "",
                                  disabled: _vm.selectedHeaders.length === 0,
                                  id: "transfer-approval-description-reason-show",
                                },
                                on: { click: _vm.showReasonPopup },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("ApproveOperations.Button_Reject")
                                  )
                                ),
                              ]
                            ),
                            _vm.showReasonButtonPopup
                              ? _c(
                                  "form",
                                  {
                                    staticClass:
                                      "transfer-approval-description-right-MiniPopup",
                                    attrs: {
                                      id: "transfer-approval-description-reason-form",
                                    },
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.rejectSelectedHeaders.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "transfer-approval-description-MiniPopup-label required",
                                        attrs: {
                                          id: "transfer-approval-description-reason-label",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "ApproveOperations.Reason_Description_Input_Label"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true, max: 512 },
                                          expression:
                                            "{required: true, max: 512}",
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.reasonDescription,
                                          expression: "reasonDescription",
                                        },
                                      ],
                                      ref: "reason",
                                      staticClass:
                                        "txt transfer-approval-description-MiniPopup-input",
                                      class: {
                                        "is-danger":
                                          _vm.veeErrors.has(`reason`),
                                      },
                                      attrs: {
                                        name: "reason",
                                        autocomplete: "off",
                                        id: "transfer-approval-description-reason-input",
                                      },
                                      domProps: {
                                        value: _vm.reasonDescription,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.reasonDescription =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                    _c(
                                      "Button",
                                      {
                                        staticClass: "m-top-15",
                                        attrs: {
                                          type: "submit",
                                          primary: "",
                                          size: "medium",
                                          id: "transfer-approval-description-reason-submit",
                                          disabled:
                                            _vm.selectedHeaders.length === 0 ||
                                            _vm.isLoading,
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "ApproveOperations.Detail_Col_Button_Agree"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isLoading,
                                                expression: "!isLoading",
                                              },
                                            ],
                                          },
                                          [
                                            _c("Loading", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isLoading,
                                                  expression: "isLoading",
                                                },
                                              ],
                                              attrs: { theme: "disable" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "popup-container m-left-10" },
                          [
                            _c(
                              "Button",
                              {
                                attrs: {
                                  disabled:
                                    _vm.selectedHeaders.length === 0 ||
                                    _vm.isLoading,
                                  type: "button",
                                  primary: "",
                                  size: "medium",
                                  id: "transfer-approval-description-reason-show",
                                },
                                on: { click: _vm.approveSelectedHeaders },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("ApproveOperations.Button_Approve")
                                  )
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isLoading,
                                        expression: "!isLoading",
                                      },
                                    ],
                                  },
                                  [
                                    _c("Loading", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isLoading,
                                          expression: "isLoading",
                                        },
                                      ],
                                      attrs: { theme: "disable" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.isApproveOrderPage || _vm.isApproveTransferPage
                    ? _c("TableFoot", {
                        attrs: { page: _vm.TransferPage },
                        on: {
                          delegateOnChangePage: _vm.changePage,
                          delegateOnChangePageSize: _vm.changePageSize,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }