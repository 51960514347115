var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "ScheduleJobDetail" },
          [
            _c(
              "div",
              { staticClass: "PageHeaderEkstra m-bottom-20" },
              [
                _vm.isLoadingHeader
                  ? _c("Loading")
                  : _c(
                      "div",
                      { staticClass: "Breadcrumb" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              id: "link-schedule-detail-breadcrumb",
                              to: { name: "ScheduledJob" },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ScheduledJob.breadcrumb_Schedule_Detail"
                                )
                              )
                            ),
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(">"))]),
                        _c(
                          "a",
                          {
                            staticClass: "isSelected",
                            attrs: {
                              href: "",
                              id: "link-recipe-breadcrumb-active",
                            },
                          },
                          [_vm._v(_vm._s(_vm.breadCrumbActivePath))]
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
            _vm.isLoadingDetail ? _c("TableLoading") : _vm._e(),
            !_vm.isEmpty && !_vm.isLoadingDetail
              ? _c("TableView", {
                  attrs: {
                    fields: _vm.fields,
                    data: _vm.details,
                    componentName: "ScheduledJobTable",
                    pointer: true,
                    actions: [""],
                    readOnly: true,
                    page: _vm.detailPage,
                  },
                  on: {
                    delegateOnChangePage: _vm.changePage,
                    delegateOnChangePageSize: _vm.changePageSize,
                  },
                })
              : _vm._e(),
            _vm.isEmpty && !_vm.isLoadingDetail
              ? _c("EmptyState", {
                  attrs: {
                    title: _vm.$t("ScheduledJob.emptyState_NotFound_Title"),
                    description: _vm.$t(
                      "ScheduledJob.emptyState_NotFound_Description"
                    ),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }