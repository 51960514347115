var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c("CustomDatepicker", {
            staticClass: "m-right-15",
            attrs: {
              id: "WasteDatepicker",
              disabled: _vm.isLoading,
              range: true,
            },
            model: {
              value: _vm.dates,
              callback: function ($$v) {
                _vm.dates = $$v
              },
              expression: "dates",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-waste-new",
                type: "button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.addWaste },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Waste.waste_form_title")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("StoreFilterComponent", {
            ref: "orderStoreFilter",
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c("BusinessMultiselect", {
            attrs: {
              componentName: "waste-groups-filter",
              label: _vm.$t("Waste.wasteGroups"),
              popupHeader: _vm.$t("Waste.select_WasteGroup"),
              multiselectItems: _vm.WasteGroupList,
            },
            on: { submitFilter: _vm.getListWithWasteGroupFilter },
          }),
          _c(
            "div",
            { staticClass: "show-drafts" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-wastes-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.isEmptyWasteList && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Waste.emptyTitle"),
                  description: _vm.$t("Waste.empty_description"),
                  buttonText: _vm.$t("Waste.waste_form_title"),
                },
                on: { emptyAddButton: _vm.addWaste },
              })
            : _vm._e(),
          _c(
            "div",
            [
              !_vm.isEmptyWasteList && !_vm.isLoading
                ? _c("NestedTable", {
                    attrs: {
                      tableKey: "waste",
                      data: _vm.Waste.list,
                      fields: _vm.wasteHeaderFields,
                      detailFields: _vm.wasteDetailFields,
                      actions: ["edit", "delete", "export"],
                      detailActions: ["edit", "delete"],
                      usePortalFooter: true,
                      isDetailLoading: _vm.$wait.is("Get_Waste_Header"),
                      isLoadingFinalize: _vm.$wait.is("wasteSaved"),
                      disableNewDetailButton: _vm.isOnlyListUsage,
                      page: _vm.Page,
                      selectable: "checkbox",
                      selectableAll: "checkbox",
                      selectedRows: _vm.selectedList,
                    },
                    on: {
                      delegateOnChangePage: _vm.changePage,
                      delegateOnChangePageSize: _vm.changePageSize,
                      detailChangeAction: _vm.updateDetailItem,
                      showExportPopup: _vm.showExportPopup,
                      showDetailAction: _vm.showWasteDetail,
                      deleteHeaderAction: _vm.DeleteWaste,
                      editHeaderAction: _vm.EditWasteHeader,
                      editDetailAction: _vm.WasteDetailEdit,
                      deleteDetailAction: _vm.wasteDetailRemove,
                      selectItem: _vm.onSelectWaste,
                      selectAllItems: _vm.onSelectWasteAll,
                    },
                  })
                : _vm._e(),
              _c("portal", {
                attrs: { to: "description" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (header) {
                      return _c(
                        "div",
                        {},
                        [
                          header.row.description
                            ? _c(
                                "Button",
                                {
                                  staticClass:
                                    "show-MiniPopup relative width-max-content btn-info",
                                  attrs: {
                                    size: "small",
                                    id: `wastes-header-info-button-${header.rowIndex}`,
                                    iconName: "icon-global-info",
                                    justIcon: true,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "MiniPopup-right-primary m-top-15 c-dark",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(header.row.description)),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
              _c("portal", {
                attrs: { to: "detailDescription" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (detail) {
                      return _c(
                        "div",
                        {},
                        [
                          detail.row.description
                            ? _c(
                                "Button",
                                {
                                  staticClass:
                                    "show-MiniPopup relative width-max-content btn-info",
                                  attrs: {
                                    size: "small",
                                    id: `wastes-detail-info-button`,
                                    iconName: "icon-global-info",
                                    justIcon: true,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "MiniPopup-right-primary m-top-15 c-dark",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(detail.row.description)),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
              _c("portal", {
                attrs: { to: "footerPortal" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (rowData) {
                      return _c(
                        "div",
                        { staticClass: "popup-container flexbox" },
                        [
                          _vm.isExpireDateControlEnabled(rowData)
                            ? _c(
                                "Button",
                                {
                                  staticClass: "m-right-20",
                                  attrs: {
                                    id: "btn-waste-expire-date-edit",
                                    type: "button",
                                    size: "small",
                                    secondary: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editExpireDate(rowData)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Transfer.editExpireDate"))
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          rowData.status === 1
                            ? _c(
                                "Button",
                                {
                                  staticClass: "m-right-20",
                                  attrs: {
                                    id: "btn-waste-add-new-data",
                                    type: "button",
                                    size: "small",
                                    secondary: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.wasteDetailNew(rowData)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("Waste.addNewStock"))),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          rowData.status === 1
                            ? _c(
                                "Button",
                                {
                                  staticClass: "m-right-20",
                                  attrs: {
                                    disabled: !rowData.detailList.length,
                                    id: "btn-waste-save-waste",
                                    type: "button",
                                    size: "small",
                                    secondary: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.wasteSaved(rowData)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("Waste.wasteSaved"))),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "Modal",
                {
                  attrs: {
                    isOpen: _vm.showExpireDateControl,
                    showCloseButton: false,
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "modal-header" }, [
                      _c("div", { staticClass: "modal-header-left" }, [
                        _c("div", { staticClass: "waste" }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.$t("Waste.editWasteExpireDates"))
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("template", { slot: "content" }, [
                    _c(
                      "div",
                      [
                        !_vm.Batch.list.length
                          ? _c("EmptyState", {
                              attrs: {
                                title: _vm.$t(
                                  "Despatches.emptyState_Title_Batch",
                                  { field: _vm.$t("Dictionary.Batch") }
                                ),
                                description: _vm.$t(
                                  "Global.emptyState_Description_Batch",
                                  { field: _vm.$t("Dictionary.Batch") }
                                ),
                              },
                            })
                          : _c("NestedTable", {
                              staticClass: "custom-nested-table",
                              attrs: {
                                fields: _vm.wasteBatchHeaderFields,
                                detailFields: _vm.wasteBatchDetailFields,
                                data: _vm.Batch.list,
                                usePortalFooter: true,
                                isActionButtonsVisible: true,
                                actions: ["export"],
                              },
                              on: {
                                showExportPopup: _vm.showBatchExportPopup,
                                showDetailAction: _vm.showBatchDetailAction,
                              },
                            }),
                        _c("portal", {
                          attrs: { to: "givenQuantity" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (detail) {
                                return _c("div", {}, [
                                  _c(
                                    "div",
                                    { staticClass: "control quantity-input" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              max: _vm.$getConst(
                                                "MAX_DIGITS_FOR_QUANTITY"
                                              ),
                                              greater_than: 0,
                                            },
                                            expression:
                                              "{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}",
                                          },
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: detail.row.givenQuantity,
                                            expression:
                                              "detail.row.givenQuantity",
                                          },
                                        ],
                                        staticClass: "txt no-spinners",
                                        class: {
                                          "is-danger": _vm.veeErrors.has(
                                            `refund${detail.rowIndex}.givenQuantity`
                                          ),
                                        },
                                        attrs: {
                                          name: "givenQuantity",
                                          "data-vv-as": _vm.$t(
                                            "Despatches.detail_TableColumn_Quantity"
                                          ),
                                          type: "number",
                                          step: "any",
                                        },
                                        domProps: {
                                          value: detail.row.givenQuantity,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.updateGivenBatchQuantity(
                                              $event,
                                              detail.row
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              detail.row,
                                              "givenQuantity",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              },
                            },
                          ]),
                        }),
                        _c("portal", {
                          attrs: { to: "unitCode" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (detail) {
                                return _c("div", {}, [
                                  _c("span", [
                                    _vm._v(_vm._s(detail.row.unitCode)),
                                  ]),
                                ])
                              },
                            },
                          ]),
                        }),
                        _c("portal", {
                          attrs: { to: "warehouseTime" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (detail) {
                                return _c("div", {}, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        detail.row.warehouseTime?.substr(
                                          0,
                                          5
                                        ) || "-"
                                      )
                                    ),
                                  ]),
                                ])
                              },
                            },
                          ]),
                        }),
                        _c("portal", {
                          attrs: { to: "shipmentTime" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (detail) {
                                return _c("div", {}, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        detail.row.shipmentTime?.substr(0, 5) ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ])
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticClass: "batch-footer" },
                      [
                        _c(
                          "Button",
                          {
                            attrs: {
                              id: "waste-batch-detail-save",
                              size: "small",
                              primary: "",
                              inline: "",
                            },
                            on: { click: _vm.closeModal },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("Global.Cancel"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
          _c("router-view", {
            on: {
              getList: _vm.getList,
              showDrafts: function ($event) {
                _vm.showDrafts = true
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }