<template lang="pug">
.Despatches

    .single-head
      .single-head-col.checkbox
        CustomCheckbox(
          id="checkbox-despatch-header-select-all"
          :checked="isCheckedAll && selectableList.length > 0"
          label=""
          @change="toggleAll"
          :disabled="selectableList.length === 0"
        )
      .single-head-col.name
        h6 {{ $t('Despatches.tableColumn_Header_SupplierName') }}

      .single-head-col.date
        h6 {{ $t('Despatches.tableHeader_RefundDate') }}
      .single-head-col.number
        h6 {{ $t('Despatches.tableHeader_RefundNumber') }}
      .single-head-col.net
        h6.u-textRight {{ $t('Despatches.tableColumn_NetTotalAmount') }}
      .single-head-col.tax
        h6.u-textRight {{ $t('Despatches.tableColumn_TaxTotalAmount') }}
      .single-head-col.gross
        h6.u-textRight {{ $t('Despatches.tableColumn_GrossTotalAmount') }}

    .Despatches-item(
    v-if="renderComponent"
    v-for="despatch in tableData",
    :class="{ 'isOpened': despatch.showDetail , 'small-size': true, 'border-blue': despatch.showDetail }")

      // HEADER
      //
      .Despatches-header(tabindex="0")
        .NestedTableHeaderLink(
            id="btn-despatches-open-header"
            @click="showDetailAction(despatch)")
        .Despatches-header-col
          StatusTooltip(
            :title="$t('Despatches.HeaderNotSaved')"
            icon="icon-row-status"
            v-if="despatch.status === 1"
          )
          StatusTooltip(
            :title="despatch.accountingMessage"
            icon="icon-row-status"
            v-if="despatch.accountingStatus === 3"
          )

          CustomCheckbox(
            :id="`checkbox-despatch-header-${despatch.id}`"
            :checked="selectedList.indexOf(despatch) > -1"
            label=""
            :disabled="firstSelectedSupplierId(despatch.supplier.id) && checkSameSupplier"
            @change="isChecked => clickCheckbox(isChecked, despatch)"
            :class="{'hide-checkbox': despatch.status === 1}"
          )

        .Despatches-header-col.name.ellipsis
          span.tooltip-relative  {{ despatch.supplier.name }}
            Tooltip(
              dark,
              bottom,
              :text="getStoreGroupsCode(despatch.storeGroups)"
            )

        .Despatches-header-col.date {{ despatch.despatchDate | dateFormat }}
        .Despatches-header-col.number {{ despatch.despatchNumber }}
        .Despatches-header-col.net.ff-mono.u-textRight
          span(v-if="showCurrencySymbol && despatch.netTotalAmount") {{despatch.currencyDefinitionSymbol}}
          .u-textRight {{ despatch.netTotalAmount | formatCurrency2Digits }}

        .Despatches-header-col.tax.ff-mono.u-textRight
          span(v-if="showCurrencySymbol && despatch.taxTotalAmount") {{despatch.currencyDefinitionSymbol}}
          .u-textRight {{ despatch.taxTotalAmount | formatCurrency2Digits }}

        .Despatches-header-col.gross.ff-mono.u-textRight
          span(v-if="showCurrencySymbol && despatch.grossTotalAmount") {{despatch.currencyDefinitionSymbol}}
          .u-textRight {{ despatch.grossTotalAmount | formatCurrency2Digits }}
        
        .Despatches-header-col
          Button.show-MiniPopup.relative.width-max-content(
            v-if="despatch.description",
            size="small"
            variant="info"
            iconName="icon-global-info"
            :justIcon="true")
            .MiniPopup-right-primary.m-top-15.c-dark
              span {{ despatch.description }}
          
        .Despatches-header-decompose.font-size-small {{ despatch.decomposedInvoiceNumber }}
        .Despatches-header-col.action
          Button(
            type="button"
            ghostsuccess
            :justIcon="true"
            variant="icon switch"
            iconName="icon-arrow-down"
            iconClass="icon-arrow-down"
            @click="showDetailAction(despatch)")
          ActionButton(
            id="refund-table-action-button"
            :data="despatch.status === 1 ? ['export', 'edit', 'remove'] : ['export'] "
            :item="despatch"
            @onItemExport="showExportPopup(despatch.id)"
            @onItemEdit="editAction(despatch)"
            @onItemDelete="removeItemAction(despatch)"
          )

      // DETAIL
      //

      RefundDetails(v-if="despatch.showDetail", :despatch="despatch" @editExpireDate="editExpireDate")

</template>

<script>
import RefundDetails from './refund-detail'
import StatusTooltip from '@/view/global/alert-status-tooltip'

import { mapGetters } from 'vuex'

export default {
  name: 'DespatchesTable',

  components: {
    RefundDetails,
    StatusTooltip
  },

  data () {
    return {
      renderComponent: true,
      showCurrencySymbol: false
    }
  },

  props: {
    dataType: {
      default: 'Despatches',
      type: String
    },
    tableData: {
      type: Array,
      required: true
    },
    showDetailAction: {
      type: Function,
      required: true
    },
    editAction: {
      type: Function,
      required: true
    },

    removeItemAction: {
      type: Function,
      required: true
    },

    selectedList: {
      type: Array,
      required: true
    },

    showExportPopup: {
      type: Function,
      required: true
    },

    checkSameSupplier: {
      type: Boolean,
      required: true
    }
  },

  mounted () {
    this.showCurrencySymbol = this.UserInfo.showCurrencySymbol
  },

  computed: {
    ...mapGetters('Settings', ['UserInfo']),

    selectableList () {
      return this.tableData.filter(item => (item.accountingStatus === 0 || item.accountingStatus === 3) && item.status !== 1)
    },

    isEmptySelectedList () {
      return this.selectedList.length === 0
    },

    isCheckedAll () {
      return this.selectedList.length === this.selectableList.length
    }
  },

  methods: {
    clickCheckbox (isChecked, despatch) {
      this.$emit('delegateCheckItem', { isChecked, despatch })
    },

    editExpireDate (despatch) {
      this.$emit('editExpireDate', despatch)
    },

    toggleAll (isChecked) {
      this.$emit('delegateCheckAllItem', isChecked)
    },

    getStoreGroupsCode (storeGroupList) {
      if (storeGroupList.length === 0) return ''
      return storeGroupList.map(item => !item.code ? item.name : item.code).join(', ')
    },

    firstSelectedSupplierId (supplierId) {
      return this.selectedList.length > 0 && !this.isCheckedAll ? supplierId !== this.selectedList[0].supplier.id : false
    },

    showActionBar (item) {
      item.showActions = !item.showActions
      this.forceRerender()
    },
    closeActionBar (item) {
      item.showActions = false
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";
.Despatches {
    border: solid 1px $color-gray;
    border-radius: $border-radius;

    &-item.isOpened .btn-switch {
      transform: rotate(180deg);
    }

    &-item {
      line-height: 1;
      background: $color-white;
      &:nth-child(odd) {
        background-color: $color-snow;
      }
      &.border-blue{
        border: solid 1px $color-primary;
      }
      &:hover {
        &:not(.isOpened) {
          background: $color-ocean;
          border:1px solid $color-light-blue;
        }
      }
    }

    &-header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 60px;

      .Despatches-item.isOpened & {
        background-color: $color-ocean;
      }

      &-col {
        padding: 14px 12px;
        margin-left: 1%;
        word-break: break-word;

        .title {
          font-size: $font-size-micro;
          color: $color-success;
          margin-bottom: 6px;
        }

        .ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.status {
          padding: 0;
          width: 15px;
          position: absolute;
          margin-left: 0!important;
          svg {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          div {
            display: none;
            z-index: 2;
            position: relative;
            left: 0;
            min-width: 150px;
            margin-top: 10px;
            justify-content: center;
            font-size: $font-size-small;
            transform: translateX(-50%) translateY(100%);
            background-color: white;
            box-shadow: 0 0 0 2px rgba($color-warning, 1);
            border-radius: $border-radius;
            padding: 10px;
            &::after, &::before {
              content: '';
              position: absolute;
              bottom: 100%;
              transform: translateX(calc(50% - 10px));
              pointer-events: none;
            }
            &::before {
              border: 8px solid;
              border-color: transparent transparent $color-warning transparent;
            }
          }

          svg:hover + div {
            display: flex;
          }
        }

        &.name {
          width: 11%;
        }

        &.integration-status {
          width: 10%;
        }

        &.checkbox {
          margin-left: 15px;
        }

        &.date {
          width: 11%;
        }

        &.number {
          width: 10%;
        }

        &.net {
          width: 11%;
        }

        &.tax {
          width: 11%;
        }

        &.gross {
          width: 11%;
        }

        &.action {
          margin-left: auto;
          display: flex;

          .body {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    &-header .action {
      opacity: 1;
      transition: opacity $transition;
    }

    &-header:hover > .action,
    &-header:focus-within > .action {
      opacity: 1;
    }
  }

  .hide-checkbox {
    visibility: hidden;
  }

.single-head {
  background-color: $color-lighter-blue;
  display: flex;
  align-items: center;
  height: $finger-size-big;
  border-radius: $border-high-radius;

    .name {
      width: 11%;
    }

    .integration-status {
      width: 10%;
    }

    .date {
      width: 11%;
    }

    .number {
      width: 10%;
    }

    .net {
      width: 11%;
    }

    .tax {
      width: 11%;
    }

    .gross {
      width: 11%;
    }

  &-col {
    font-family: $font-family;
    font-size: $font-size-big;
    font-weight: bold;
    flex-shrink: 0;
    padding: 14px 12px;
    margin-left: 1%;

    &.checkbox {
        margin-left: 15px;
    }

    h6 {
      font-size: $font-size-small;
      color: $color-success;
    }
  }
}

.tooltip-relative {
  cursor: pointer;
}

.Despatches-header-decompose{
  position: absolute;
  right: 10%;
  color: $color-success;
}
.net, .tax, .gross {
  display: flex;
}
</style>
