var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.requestHeaderLinks,
              translationGroup: "Request",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "requestsTransfer",
              suggestionValue: "name",
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.selectedStockItem,
              customSuggestionPlaceHolder: _vm.$t(
                "DirectOrders.searchPlaceholder"
              ),
              customSuggestionTitle: _vm.$t(
                "DirectOrders.orders_search_SuggestionTitle"
              ),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.setStockItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c("CustomDatepicker", {
            staticClass: "m-right-15",
            attrs: { range: false, useFilterReset: true, pickerType: "manuel" },
            on: { resetDateFilter: _vm.resetDateFilter },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-add-new-transfer-request-detail",
                success: "",
              },
              on: { click: _vm.addNewDetail },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Global.addDetail")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeFilter", {
            ref: "requestedTransferFromStoreList",
            attrs: { label: _vm.$t("RequestedTransfer.tableFieldSenderStore") },
            on: { submitFilter: _vm.getListWithFromStoreFilter },
          }),
          _c("BusinessMultiselect", {
            ref: "requestedTransferStoreListFilter",
            attrs: {
              componentName: "requested-transfer-to-store-filter",
              multiselectItems: _vm.sortedStoreList,
              label: _vm.$t("RequestedTransfer.tableFieldStore"),
              popupHeader: _vm.$t("Global.toolbar_Stores_PopupHeader"),
            },
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c("itemGroupList", {
            ref: "requestedTransferItemGroup",
            attrs: { groupType: "itemGroup" },
            on: { submitFilter: _vm.getListWithGroupFilter },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.hideReasonPopup,
                  expression: "hideReasonPopup",
                },
              ],
              staticClass: "popup-container",
            },
            [
              _c(
                "Button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEmptyTransferList,
                      expression: "!isEmptyTransferList",
                    },
                  ],
                  attrs: {
                    secondary: "",
                    size: "small",
                    variant: "full",
                    id: "btn-requests-passive-transfer",
                    disabled: _vm.selectedItems.length === 0,
                  },
                  on: { click: _vm.showPassiveReasonForm },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("Orders.makePassive")))])]
              ),
              _vm.showReasonButtonPopup
                ? _c(
                    "form",
                    {
                      staticClass: "order-passive-description-right-MiniPopup",
                      attrs: { id: "order-description-reason-form" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.makeTransfersPassive.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "order-description-MiniPopup-label required",
                          attrs: { id: "order-description-reason-label" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Orders.Reason_Description_Input_Label")
                            )
                          ),
                        ]
                      ),
                      _c("customTextareaInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true, max: 128 },
                            expression: "{required: true, max: 128}",
                          },
                        ],
                        attrs: {
                          id: "transfer-description-reason-input",
                          name: "reason",
                          "data-vv-as": _vm.$t("Orders.reasonDescription"),
                          error: _vm.veeErrors.has("reason"),
                        },
                        model: {
                          value: _vm.reasonDescription,
                          callback: function ($$v) {
                            _vm.reasonDescription = $$v
                          },
                          expression: "reasonDescription",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("reason"),
                            expression: "veeErrors.has('reason')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("reason") },
                      }),
                      _c(
                        "Button",
                        {
                          staticClass: "m-top-15 m-left-0",
                          attrs: {
                            primary: "",
                            size: "medium",
                            type: "submit",
                            id: "transfer-description-reason-submit",
                            disabled:
                              _vm.selectedItems.length === 0 ||
                              _vm.isMakingPassive,
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("Orders.makePassive"))),
                          ]),
                          _vm.isMakingPassive
                            ? _c("Loading", { attrs: { theme: "disable" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          !_vm.isEmptyTransferList
            ? _c(
                "Button",
                {
                  staticClass: "m-left-10",
                  attrs: {
                    primary: "",
                    id: "btn-turn-to-transfer",
                    type: "submit",
                    size: "small",
                    form: "form-approve-transfer",
                    iconName: "icon-btn-success",
                    disabled:
                      _vm.isFetchingList ||
                      !_vm.isFormValid ||
                      _vm.selectedItems.length === 0 ||
                      _vm.isActionSavedAction,
                    isLoading: _vm.isActionSavedAction,
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("RequestedTransfer.createTransferRequest"))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : [
                _vm.isFetchingList || _vm.isPageLoading
                  ? _c("TableLoading")
                  : _vm._e(),
                _vm.isEmptyTransferList &&
                !_vm.isFetchingList &&
                !_vm.isPageLoading
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "RequestedTransfer.emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "RequestedTransfer.emptyState_NotFound_Description"
                        ),
                      },
                    })
                  : _vm._e(),
                !_vm.isEmptyTransferList &&
                !_vm.isFetchingList &&
                !_vm.isPageLoading
                  ? _c(
                      "form",
                      {
                        staticClass: "RequestTransferForm",
                        attrs: { id: "form-approve-transfer" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createRequests.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row header fixed-header",
                            attrs: { id: "table-header-row-request-transfer" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col ch fixed-title",
                                attrs: {
                                  id: "table-header-col-request-transfer-select-all",
                                },
                              },
                              [
                                _c("CustomCheckbox", {
                                  attrs: {
                                    id: "checkbox-request-order-select-all",
                                    checked: _vm.isCheckedAll,
                                    label: "",
                                  },
                                  on: { change: _vm.toggleAll },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col required fixed-title",
                                attrs: {
                                  id: "table-header-col-request-transfer-sender-store",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "RequestedTransfer.tableFieldSenderStore"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col fixed-title",
                                attrs: {
                                  id: "table-header-col-request-transfer-store",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("RequestedTransfer.tableFieldStore")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col fixed-title",
                                attrs: {
                                  id: "table-header-col-request-transfer-stock-item",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "RequestedTransfer.tableFieldStockItem"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col required",
                                attrs: {
                                  id: "table-header-col-request-transfer-date",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("RequestedTransfer.tableFieldDate")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col u-textRight",
                                attrs: {
                                  id: "table-header-col-request-transfer-onhand",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("RequestedTransfer.tableFieldOnHand")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col",
                                attrs: {
                                  id: "table-header-col-request-transfer-unit",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("RequestedTransfer.tableFieldUnit")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col u-textRight",
                                attrs: {
                                  id: "table-header-col-request-transfer-unit",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "RequestedTransfer.tableFieldRQuantity"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col u-textRight required",
                                attrs: {
                                  id: "table-header-col-request-transfer-quantity",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "RequestedTransfer.tableFieldQuantity"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col u-textRight",
                                attrs: {
                                  id: "table-header-col-request-transfer-diff-quantity",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "RequestedTransfer.tableFieldDiffQuantity"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col u-textRight",
                                attrs: {
                                  id: "table-header-col-request-transfer-unit-price",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "RequestedTransfer.tableFieldUnitPrice"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col base-unit-quantity u-textRight",
                                attrs: {
                                  id: "table-header-col-request-transfer-base-unit-quantity",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "RequestedTransfer.tableFieldBaseUnitQuantity"
                                      )
                                    )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col m-left-20",
                                attrs: {
                                  id: "table-header-col-request-transfer-base-unit",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "RequestedTransfer.tableFieldBaseUnit"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col u-textRight",
                                attrs: {
                                  id: "table-header-col-request-transfer-total",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "RequestedTransfer.tableFieldTotal"
                                      )
                                    )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col u-textRight",
                                attrs: {
                                  id: "table-header-col-request-transfer-itemGroupName",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "RequestedTransfer.tableFielditemGroupName"
                                      )
                                    )
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "col" }),
                          ]
                        ),
                        _vm._l(_vm.items, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "row",
                              attrs: {
                                id: `table-body-row-request-transfer-${index}`,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col ch fixed" },
                                [
                                  _c("CustomCheckbox", {
                                    attrs: {
                                      id: `checkbox-requested-transfer-${index}`,
                                      label: "",
                                      checked:
                                        _vm.selectedItems.indexOf(item) > -1,
                                    },
                                    on: {
                                      change: (isChecked) =>
                                        _vm.toogleSelectItem(isChecked, item),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col fixed",
                                  attrs: {
                                    id: `from-store-name-requested-transfer-${index}`,
                                  },
                                },
                                [_vm._v(_vm._s(item.fromStoreName))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col fixed",
                                  attrs: {
                                    id: `store-name-requested-transfer-${index}`,
                                  },
                                },
                                [_vm._v(_vm._s(item.storeName))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col fixed",
                                  attrs: {
                                    id: `stock-name-requested-transfer-${index}`,
                                  },
                                },
                                [_vm._v(_vm._s(item.stockItemName))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col",
                                  attrs: {
                                    id: `date-picker-container-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c("CustomDatepicker", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required: true,
                                              date_format: "YYYY-MM-DD",
                                              before: _vm.dateLimit,
                                            },
                                            expression:
                                              "{ required: true, date_format:'YYYY-MM-DD', before: dateLimit }",
                                          },
                                        ],
                                        staticClass: "custom-picker",
                                        class: {
                                          "is-danger": _vm.veeErrors.has(
                                            `requestDate-${index}`
                                          ),
                                        },
                                        attrs: {
                                          id: `date-picker-requested-transfer-${index}`,
                                          name: `requestDate-${index}`,
                                          position: "left",
                                          pickerType: "manuel",
                                          inputIconRight: "icon-arrow-down",
                                          disabledStartDate: _vm.disabledDate,
                                          isCheckingPosition: false,
                                          inputIcon: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.getStockItemInfo(item)
                                          },
                                        },
                                        model: {
                                          value: _vm.items[index].requestDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.items[index],
                                              "requestDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "items[index].requestDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col u-textRight",
                                  attrs: {
                                    id: `onhand-requested-transfer-${index}`,
                                  },
                                },
                                [_vm._v(_vm._s(item.stockOnHandQuantity))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col",
                                  attrs: {
                                    id: `unit-name-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.findUnit(item.unitId)
                                        ? _vm.findUnit(item.unitId).name
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col u-textRight",
                                  attrs: {
                                    id: `quantity-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.sourceType === 2 ? 0 : item.quantity
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col u-textRight",
                                  attrs: {
                                    id: `transferred-quantity-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _c("custom-number-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          required: true,
                                          greater_than: -1,
                                          max: _vm.$getConst(
                                            "MAX_DIGITS_FOR_QUANTITY"
                                          ),
                                          multiplyTwoField: item.unitPrice,
                                        },
                                        expression:
                                          "{required: true, greater_than:-1, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), multiplyTwoField: item.unitPrice}",
                                      },
                                    ],
                                    class: {
                                      "is-danger": _vm.veeErrors.has(
                                        `quantity${item.id}`
                                      ),
                                    },
                                    attrs: {
                                      name: `quantity${item.id}`,
                                      id: `input-transfer-request-quantity-${index}`,
                                      disabled:
                                        _vm.Tenant
                                          .approvalRequestTransferPermission,
                                      "data-vv-as": _vm.$t(
                                        "RequestedTransfer.tableFieldQuantity"
                                      ),
                                    },
                                    model: {
                                      value:
                                        _vm.items[index].transferedQuantity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.items[index],
                                          "transferedQuantity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "items[index].transferedQuantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col u-textRight",
                                  attrs: {
                                    id: `quantity-some-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.transferedQuantity - item.quantity
                                      )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col u-textRight",
                                  attrs: {
                                    id: `unit-price-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(item.unitPrice)
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col base-unit-quantity u-textRight",
                                  attrs: {
                                    id: `quantity-2-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (item.baseQuantity / item.quantity) *
                                          item.transferedQuantity
                                      )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col m-left-20",
                                  attrs: {
                                    id: `base-unit-name-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.findUnit(item.baseUnitId)
                                        ? _vm.findUnit(item.baseUnitId).name
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col u-textRight",
                                  attrs: {
                                    id: `total-quantity-requested-transfer-${index}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          item.unitPrice *
                                            item.transferedQuantity
                                        )
                                      )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col u-textRight",
                                  attrs: {
                                    id: `total-quantity-requested-transfer-${index}`,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.itemGroupName))]
                              ),
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  item.description
                                    ? _c(
                                        "Button",
                                        {
                                          staticClass:
                                            "show-MiniPopup relative width-max-content btn-info",
                                          attrs: {
                                            id: "transfer-approval-header-info-button",
                                            iconName: "icon-global-info",
                                            justIcon: true,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "MiniPopup-right-primary m-top-15 c-dark",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.description)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
          _c("router-view", { on: { getList: _vm.getFilteredRequestOrders } }),
          !_vm.isEmptyTransferList && !_vm.isFetchingList && !_vm.isPageLoading
            ? _c("TableFoot", {
                attrs: { page: _vm.Page },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }