<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList="pageHeaderLinks"
      translationGroup="TabItems"
    )

    UBLUpload(
      v-show="showAlert"
      :data="finalData"
      :isLoading="waitUBLUpload"
      @closeUploadScreen="closeUploadScreen"
    )

  template(slot="header-left-search")
    Search.search-box(
      componentName="DespatchEInvoice",
      :isSuggestionLoading="false",
      :customSuggestionPlaceHolder="$t('EInvoice.search_Placeholder')"
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-right-dropdown")

    .form-item-select.right-date.m-left-5.m-right-5
      CustomDatepicker.eInvoice-datePicker(
        v-model="dates",
        :isLoading="$wait.is('getDateFilterSettings')"
        :disabled="isLoading || $wait.is('pageLoading')"
        :range="true")
  
  template(slot="header-right-content")

    Button(
      primary
      size="medium"
      :class="buttonSizeClass"
      id="e-invoice-transform-button"
      :isLoading="isWaitingForTransform && isLoading"
      :disabled="isWaitingForTransform && isLoading"
      @click="changeTransformStatus"
    )
      span {{ InvoiceTransformStatus ? $t('EInvoice.stopTransformDespatch') : $t('EInvoice.autoTransformDespatch') }}

  template(slot="toolbar-left")
    SupplierFilter.filters(
      @submitFilter="getListWithSupplierFilter"
      ref="despatchEInvoiceSupplierFilter"
    )
    storeFilter.filters(
      @submitFilter="getListWithStoreFilter"
      ref="despatchEInvoiceStoreFilter")

    businessMultiselect.filters(
      componentName="despatch-eInvoice-doc-type"
      :label="$t('EInvoice.filter_docType')"
      :popupHeader="$t('EInvoice.filter_docType_PopupHeader')"
      :multiselectItems="docTypes"
      @submitFilter="getListWithDocTypeFilter"
      ref="eInvoicesDocTypeFilter"
    )

    CustomMultiselect.dateTypeFilter(
      id="eInvoice-date-type-filter"
      :items="dateTypes"
      :selected="dateTypeList"
      :label="$t('EInvoice.filter_dateType')"
      :popupHeader="$t('EInvoice.filter_dateType')"
      :showAll="false"
      :buttonText="$t('EInvoice.filter_apply')"
      type="radio"
      buttonIconLeft="btn-success"
      @delegateCheckItem="onChangeDateTypes"
      @delegateSubmitItems="getEInvoiceListWithParams"
      ref="dateTypeList"
    )
    Button.clear-filter-button.border-gray-1.p-0.width-auto.m-left-5(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")
    .show-more.m-left-5
      .Form-item.dropdown-handle
        .select.dropdown-handle-button(
          @keydown.enter="toggleOtherOptions"
          @keydown.prevent.space="toggleOtherOptions"
          @click="toggleOtherOptions"
          v-click-outside="closeOtherOptions"
          :aria-expanded="showOtherOptions"
          tabindex="0"
        )
          .dropdown-handle-button-values {{ $t('Global.moreOptions') }}
          Icon(:class="{'open': showOtherOptions}" name="icon-down-arrow").dropdown-handle-button-icon.icon.icon-down-arrow
          

      .MiniPopup.left(
        v-if="showOtherOptions"
        v-shortkey="['esc']"
      )

        CustomCheckbox(
          id="checkbox-e-invoice-show-fails"
          :checked="showFailed"
          :label="$t('EInvoice.showFailedOnly')"
          @change="changeShowFailStatus")

        CustomCheckbox.show-passive-recipes(
          id="checkbox-eInvoices-show-passive"
          :checked="showPassive"
          :label="$t('EInvoice.showPassiveInvoices')"
          @change="showPassiveInvoices")

  template(slot="toolbar-right")
    ImportExport.m-right-15(
      ref="wrapper"
      componentName="EInvoicePage"
      :isDisabled="isLoading"
      :activeImportExports=['import']
      importUrl="RawEInvoice/import"
      importFileType="xml"
      :importIsEmitData="true"
      @fileUpload="uploadFile"
    )
    Button(
      primary,
      size="small",
      :disabled="!selectedList.length"
      @click="transformDespatch"
    )
      span {{ $t('EInvoice.transformDespatch') }}
  template(slot="content-center")

    TableLoading(v-if="isLoading || $wait.is('pageLoading') || isWaitingForTransform || isWaitingForActivationChanging")
    EmptyState(
      v-if="!isLoading && !EInvoiceList.length"
      :title="$t('EInvoice.eInvoiceList_not_found')")

    .table-content(v-else)
      TableView(
        uniqueIdentifier="id"
        :fields="fields"
        :actions="['overview', 'active']"
        :data="EInvoiceList"
        :detailFields="detailFields"
        :page="EInvoicePage"
        :detailData="invoiceDetailList"
        :selectable="'checkbox'"
        :selectableAll="'checkbox'"
        :selectedRows="selectedList"
        :componentName="'EInvoicePage'"
        :addScrollToNoFixedColumns="true"
        :hasDetailTable="true"
        @delegateOnItemShowCustom="showDetail"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemChangeActiveStatus="updateActivationState"
        @delegateOnItemOverview="showInvoiceTemplate"
        @overviewHeaderAction="showInvoiceTemplate"
        @showAllSelectedItems="selectAll"
        @delegateOnItemSelect="onSelect"
      )

      portal(to="orderNo")
        div(slot-scope="row" v-if="!isLoading")  
          .order-number-label(@click.stop="showOrderTable(row)" :class="!row.matchingSupplierId || !row.matchingStoreId || row.rawEInvoiceStatus === 3 ? 'disable-order-selection' : ''")
            span(v-if="row.orderNo") {{row.orderNo}}
            span(v-else) {{ $t('EInvoice.selectOrder') }}

      portal(to="despatchNo")
        div(slot-scope="row" v-if="!isLoading")  
          .order-number-label(@click.stop="showDespatchTable(row)" :class="!row.matchingSupplierId || !row.despatchNo || row.rawEInvoiceStatus === 3 ? 'disable-order-selection' : ''")
            span(v-if="row.despatchNo") {{row.despatchNo}}
            span(v-else) {{ $t('EInvoice.selectDespatch') }}

      portal(to="matchingStoreName")
        div(slot-scope="row" v-if="!isLoading")
          customSelectInput.custom-select(
            name="matchingStore",
            v-if="row.rawEInvoiceStatus === 2 || row.rawEInvoiceStatus === 1"
            :disabled="!row.isActive"
            @getOptions="getStoreOptions(row.matchingSupplierId)"
            :optionData="matchingStoreData"
            v-model="row.matchingStoreId",
            optionKey="id"
            optionIdKey="id"
            :existingData="row.matchingStoreName"
            :extraDataEmit="row"
            :selectedDataRequired="true"
            @change="updateMatchingStore"
            :isChangeValue="true"
            :hideDefault="false"
            optionIdName="eInvoice-matching-store-list"
          )
          span(v-else) {{row.matchingStoreName}}

      portal(to="supplierBranchName")
        div(slot-scope="row" v-if="!isLoading")
          customSelectInput.custom-select(
            name="matchingStore",
            v-if="row.rawEInvoiceStatus === 2 || row.rawEInvoiceStatus === 1"
            :disabled="!row.isActive"
            @getOptions="getBranchCodes(row.supplierTaxNo)"
            v-model="row.supplierBranchName",
            :value="row.supplierBranchName",
            :optionData="matchingBranchData"
            :existingData="row.supplierBranchName"
            optionKey="name"
            optionIdKey="name"
            :extraDataEmit="row"
            :selectedDataRequired="true"
            :isChangeValue="true"
            @change="updateSupplierBranch"
            :hideDefault="false"
            optionIdName="eInvoice-matching-supplier-list"
          )
          span(v-else) {{row.supplierBranchName}}

      portal(to="errorCode")
        div(slot-scope="row")
          Button.error-code.relative.width-max-content(
            :clearVariant="true"
            @mouseenter="showFailReason(row)"
            @mouseleave="hideFailReason(row)"
            :isLoading="isInvoiceTransforming"
            v-show="renderComponent && row.errorCode && row.rawEInvoiceStatus === 2"
          )
            span(v-if="!isInvoiceTransforming") {{ $t('EInvoice.hasError') }}
            .MiniPopup-left(v-show="row.isShowReason")
              span {{ $t('EInvoice.errorCode_' + row.errorCode) }}

          Button.waiting-label.relative.width-max-content(
            v-show="row.rawEInvoiceStatus === 1"
            :clearVariant="true"
          )
            span {{ $t('EInvoice.waiting') }}

          Button.success-label.relative.width-max-content(
            v-show="row.rawEInvoiceStatus === 3"
            :clearVariant="true"
          )
            span {{ $t('EInvoice.successful') }}

          Button.inProgress-label.relative.width-max-content(
            v-show="row.rawEInvoiceStatus === 4"
            :clearVariant="true"
          )
            span {{ $t('EInvoice.InProgress') }}

      portal(to="docType")
        div(slot-scope="row")
          span {{ getDocType(row.docType) }}

      Modal(:isOpen="showHTMLTemplate" @closeModal="closeModal")
        template(slot="content")
          Loading(v-if="invoiceHTMLIsLoading")
          iframe(v-else :srcdoc="InvoiceHTML" frameborder="0" scrolling="auto" width="100%"  class="iframe-invoice")
      router-view(@getList="getEInvoiceListWithParams")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import {getEInvoiceFields, getEInvoiceDetailFields} from './fields/eInvoice'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import SupplierFilter from '@/view/global/business-filters/supplier.vue'
import CustomSelect from '@/view/global/custom-select'
import StoreFilter from '@/view/global/business-filters/store.vue'
import UBLUpload from './components/ubl-upload-screen'
import ImportExport from '@/view/global/import-export'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'Invoice',

  components: {
    SupplierFilter,
    StoreFilter,
    businessMultiselect,
    CustomSelect,
    UBLUpload,
    ImportExport
  },

  data () {
    return {
      dates: [],
      supplierFilter: [],
      storeFilter: [],
      docTypeFilter: null,
      selectedList: [],
      accountingFilter: [],
      accountingList: [],
      invoiceTypes: [],
      fileData: [],
      showAlert: false,
      invoiceTypeFilter: [],
      invoiceDetailList: [],
      searchText: null,
      fields: getEInvoiceFields(this.$t),
      detailFields: getEInvoiceDetailFields(this.$t),
      showFailed: false,
      showPassive: false,
      renderComponent: true,
      showHTMLTemplate: false,
      isAllSelectedDocType: false,
      showOtherOptions: false,
      matchingUnits: [],
      matchingStockItems: [],
      rawEInvoiceId: null,
      supplierId: null,
      invoiceLineId: null,
      matchingStoreData: [],
      matchingBranchData: [],
      isInvoiceActive: false,
      finalData: [],
      dateTypeList: [],
      dateTypes: [
        {
          id: 0,
          name: this.$t('EInvoice.filter_invoiceDate')
        },
        {
          id: 1,
          name: this.$t('EInvoice.filter_lastTransactionDate')
        }
      ]
    }
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getEInvoiceListWithParams()
    }
  },

  async beforeMount () {
    this.$wait.start('pageLoading')
    this.dateTypeList = [this.dateTypes[0]]
    await this.getInvoiceTransformStatus()
    await this.initInitialValues()
    await this.getEInvoiceListWithParams()
    this.$wait.end('pageLoading')
  },

  async mounted () {
    this.$refs.dateTypeList.beforeSubmit()
  },

  computed: {

    ...mapGetters('EInvoice', [
      'EInvoicePage',
      'EInvoiceList',
      'pageHeaderLinks',
      'InvoiceHTML',
      'matchingStores',
      'InvoiceTransformStatus',
      'matchingBranches',
      'EInvoiceDetail'
    ]),
    ...mapGetters('Supplier', ['MiniSuppliers']),
    ...mapGetters('Stores', ['miniStoreList']),

    selectableHeaders () {
      return this.EInvoiceList.filter(i => i.isActive && i.rawEInvoiceStatus !== 3)
    },
    staticFields () {
      return [
        {
          name: 'errorCode',
          is_visible: true,
          type: 'portal',
          short_title: this.$t('EInvoice.status'),
          title: this.$t('EInvoice.status')
        },
        {
          name: 'docType',
          is_visible: true,
          type: 'portal',
          short_title: this.$t('EInvoice.docType'),
          title: this.$t('EInvoice.docType')
        },
        {
          name: 'supplierName',
          is_visible: true,
          type: 'supplier',
          short_title: this.$t('EInvoice.companyName'),
          title: this.$t('EInvoice.companyName')
        }
      ]
    },
    docTypes () {
      return [
        {
          name: this.$t('EInvoice.eInvoice'),
          id: 1,
          isActive: true
        },
        {
          name: this.$t('EInvoice.eArchive'),
          id: 2,
          isActive: true
        }
      ]
    },

    isAllSelectedSupplier () {
      return this.supplierFilter.length === this.MiniSuppliers.length
    },
    isAllSelectedStore () {
      return this.storeFilter.length === this.miniStoreList.length
    },

    isLoading () {
      return this.$wait.is(['getAllEInvoices', 'transformInvoiceToDespatch'])
    },
    isWaitingForTransform () {
      return this.$wait.is(['changeInvoiceTransformStatus'])
    },
    invoiceHTMLIsLoading () {
      return this.$wait.is(['getInvoiceTemplate'])
    },
    isDetailFetching () {
      return this.$wait.is(['getEInvoiceDetail'])
    },
    isInvoiceTransforming () {
      return this.$wait.is(['transformInvoiceToDespatch'])
    },
    isWaitingForActivationChanging () {
      return this.$wait.is('updateActivationStatus')
    },
    waitUBLUpload () {
      return this.$wait.is(['importUBL'])
    },
    buttonSizeClass () {
      return this.isWaitingForTransform ? 'isTransform' : ''
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('EInvoice', [
      'getEInvoiceList',
      'getInvoiceTemplate',
      'getEInvoiceDetail',
      'getStoreFromSupplier',
      'getUnitsFromSupplier',
      'getStockItemsFromSupplier',
      'updateCustomerBranch',
      'updateSupplierBranchData',
      'updateDespatchForEInvoice',
      'updateUnitCode',
      'updateStockItemCode',
      'getInvoiceTransformStatus',
      'updateEInvoiceTransformStatus',
      'changeEInvoiceActivationState',
      'transformSelectedInvoices',
      'getBranchCodesFromTaxNumber',
      'importUBL'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('EInvoice', [
      'HIDE_EINVOICE_DETAIL',
      'SHOW_EINVOICE_DETAIL',
      'UPDATE_EINVOICE_LIST'
    ]),

    showOrderTable (item) {
      if (!item.matchingSupplierId || !item.matchingStoreId || item.rawEInvoiceStatus === 3 ) return
      this.$router.push({
        name: 'EInvoiceSelectOrder',
        params: {
          id: item.id,
          storeId: item.matchingStoreId,
          supplierId: item.matchingSupplierId
        }
      })
    },

    showDespatchTable (item) {
      if (!item.despatchNo || item.rawEInvoiceStatus === 3 || !item.matchingSupplierId) return
      this.$router.push({
        name: 'EInvoiceSelectDespatch',
        params: {
          id: item.id,
          supplierId: item.matchingSupplierId,
          startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
          endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
          grossTotalAmount: item.grossTotalAmount,
          despatchHeaderIds: item.despatchHeaderIds
        }
      })
    },

    async uploadFile (file) {
      this.showAlert = true
      this.fileData = Object.values(file)
      this.fileData = this.fileData.slice(0, 10)
      this.finalData = this.fileData.map(item => ({
        name: item.name,
        code: null,
        file: item
      }))
      const matchUBLData = (files) => {
        const promises = files.map(async (i) => {
          i.code = await this.importUBL(i.file)
        })
        return Promise.all(promises)
      }
      matchUBLData(this.finalData)
    },

    closeUploadScreen () {
      this.showAlert = false
      this.finalData = []
      this.filelData = []
      this.getEInvoiceListWithParams()
    },

    onChangeDateTypes (event) {
      this.dateTypeList = [event.item]
    },

    onSelect (eInvoice) {
      const isChecked = !this.selectedList.some(item => item.id === eInvoice.id)
      if (isChecked) this.selectedList.push(eInvoice)
      else this.selectedList.splice(this.selectedList.findIndex(item => item.id === eInvoice.id), 1)
    },
    selectAll (isChecked) {
      if (isChecked) {
        this.selectedList = [...this.selectableHeaders]
      } else this.selectedList = []
    },

    updateActivationState: vueWaitLoader(async function (item) {
      if (item.isActive) this.isInvoiceActive = false
      else this.isInvoiceActive = true

      const payload = {
        id: item.id,
        state: this.isInvoiceActive
      }
      const res = await this.changeEInvoiceActivationState(payload)
      if (res) {
        const message = !this.isInvoiceActive ? item.invoiceNo + ' ' + this.$t('EInvoice.notification_passive') : item.invoiceNo + ' ' + this.$t('EInvoice.notification_active')
        this.notifyShow({ message })
        await this.getEInvoiceListWithParams()
      }
    }, 'updateActivationStatus'),

    changeTransformStatus: vueWaitLoader(async function () {
      await this.updateEInvoiceTransformStatus(!this.InvoiceTransformStatus)
      await this.getEInvoiceListWithParams()
      await this.getInvoiceTransformStatus()
    }, 'changeInvoiceTransformStatus'),

    transformDespatch: vueWaitLoader(async function () {
      const payload = {
        rawEInvoiceIds: this.selectedList.map(i => i.id)
      }
      await this.transformSelectedInvoices(payload)
      await this.getEInvoiceListWithParams()
    }, 'transformInvoiceToDespatch'),

    getHeaderDataForUpdate (item) {
      this.rawEInvoiceId = item.id
      this.supplierId = item.matchingSupplierId
    },

    getName (item) {
      return item.substr(0, 30).concat('...')
    },

    async updateMatchingStore (item) {
      const payload = {
        rawEInvoiceId: item.extraDataEmit.id,
        supplierId: item.extraDataEmit.matchingSupplierId,
        storeCode: item.selectedItem ? item.selectedItem.storeCode : null
      }
      const message = this.$t('EInvoice.matchingStore_successMessage')
      await this.updateCustomerBranch(payload).then(res => {
        if (res) {
          this.notifyShow({ message })
          this.getEInvoiceListWithParams()
        }
      })
    },

    async updateSupplierBranch (item) {
      const payload = {
        rawEInvoiceId: item.extraDataEmit.id,
        supplierId: item.selectedItem.id,
        supplierBranchCode: item.selectedItem ? item.selectedItem.supplierBranchCode : null,
        supplierBranchName: item.selectedItem.name
      }
      const message = this.$t('EInvoice.supplierBranch_successMessage')
      await this.updateSupplierBranchData(payload).then(res => {
        if (res) {
          this.notifyShow({ message })
        }
      })
    },

    async updateMatchingStockItem (item) {
      const payload = {
        rawEInvoiceId: item.extraDataEmit.row.rawEInvoiceId,
        rawEInvoiceLineId: item.extraDataEmit.row.id,
        supplierId: item.extraDataEmit.row.matchingSupplierId,
        itemCode: item.selectedItem.supplierStockItemCode
      }
      const message = this.$t('EInvoice.matchingStockItem_successMessage')
      await this.updateStockItemCode(payload).then(res => {
        if (res) {
          this.notifyShow({ message })
        }
      })
    },

    clearFilter () {
      this.$refs.despatchEInvoiceSupplierFilter.clearSupplierFilter()
      this.$refs.despatchEInvoiceStoreFilter.clearStoreFilter()
      this.$refs.eInvoicesDocTypeFilter.clearAllFilter(false)
    },

    onChangeSearch (search) {
      this.searchText = search
      if (this.searchText.length === 1) return
      this.getEInvoiceListWithParams()
    },

    getListWithSupplierFilter ({list, dontSubmit}) {
      if (dontSubmit) this.supplierFilter = list
      else {
        this.supplierFilter = list
        this.getEInvoiceListWithParams()
      }
    },

    getListWithDocTypeFilter ({list, dontSubmit}) {
      if (list.length === 2) this.isAllSelectedDocType = true
      if (dontSubmit) {
        list.forEach(i => {
          this.docTypeFilter = i.id
        })
      } else {
        list.forEach(i => {
          this.docTypeFilter = i.id
        })
        this.getEInvoiceListWithParams()
      }
    },

    getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.storeFilter = list
        this.getEInvoiceListWithParams()
      }
    },

    closeModal () {
      this.showHTMLTemplate = false
    },
    getDocType (docType) {
      return docType === 1 ? this.$t('Supplier.tableColumn_Invoice') : this.$t('Supplier.eArchive')
    },

    async showDetail (e) {
      if (e[0][1] === undefined || e[0][1].target.classList.value === 'input' || e[0][1].target.classList.value === 'indicator active bare' || e[0][1].target.classList.value === 'icon icon-custom-checkbox-tick' || e[0][1].target.classList.value === 'Tableee-col orderNo' || e[0][1].target.classList.value === 'indicator bare checked' || e[0][1].target.classList.value === '') return
      if (e[0][1].target.classList.contains('select' || 'custom-select' || 'input' || 'indicator' || 'bare' || 'active' || 'orderNo')) return
      await this.getEInvoiceDetail(e[0][0].id).then(res => {
        if (res) {
          this.invoiceDetailList = res.data.rawEInvoiceLineList
        }
      })
      this.$router.push({
        name: 'EInvoiceDetail',
        params: {
          id: e[0][0].id,
          header: e[0][0]
        }}
      )
    },

    showInvoiceTemplate (item) {
      const matchingList = this.EInvoiceList.find(i => i.id === item.id)
      this.showHTMLTemplate = true
      const payload = {
        ettn: item.ettn,
        storeGroupId: matchingList.storeGroupId,
        docType: 1
      }
      this.getInvoiceTemplate(payload)
    },

    async initInitialValues () {
      await this.getDateFilterSettings()
      this.dates = getDateFilterSetting('Date_Invoice')
      if(this.$route.params.startDate && this.$route.params.endDate) {
        this.dates[0] = this.$route.params.startDate
        this.dates[1] = this.$route.params.endDate
      }
    },

    async getMatchingUnitData (SupplierId) {
      const res = await this.getUnitsFromSupplier(SupplierId)
      if (res) {
        this.matchingUnits = res.data.unitlist
      }
    },

    async getStoreOptions (matchingSupplierId) {
      await this.getStoreFromSupplier(matchingSupplierId).then(res => {
        if (res) this.matchingStoreData = res.data.storelist
      })
    },

    async getBranchCodes (taxNumber) {
      this.matchingStoreData = []
      await this.getBranchCodesFromTaxNumber(taxNumber).then(res => {
        if (res) this.matchingBranchData = res.data.supplierList
      })
    },

    async getMatchingStockItemData (SupplierId) {
      const res = await this.getStockItemsFromSupplier(SupplierId)
      if (res) {
        this.matchingStockItems = res.data.stockItemlist
      }
    },

    showFailReason (item) {
      item.isShowReason = true
      this.forceRerender()
    },

    hideFailReason (item) {
      item.isShowReason = false
      this.forceRerender()
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    async changeShowFailStatus () {
      this.showFailed = !this.showFailed
      await this.getEInvoiceListWithParams()
    },

    async showPassiveInvoices () {
      this.showPassive = !this.showPassive
      this.getEInvoiceListWithParams()
    },

    getEInvoiceListWithParams: vueWaitLoader(async function (page = 1, pageSize = this.EInvoicePage.size) {
      await this.getEInvoiceList({
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        pageNumber: page || 1,
        pageSize,
        supplierIdList: this.isAllSelectedSupplier ? [] : this.supplierFilter.map(supplier => supplier.id),
        storeIdList: this.isAllSelectedStore ? [] : this.storeFilter.map(store => store.id),
        showFailed: this.showFailed,
        filterByInvoiceDate: this.dateTypeList[0].id === 0,
        showPassives: this.showPassive,
        docType: this.isAllSelectedDocType ? null : this.docTypeFilter,
        searchText: this.searchText
      })
    }, 'getAllEInvoices'),

    changePage (page) {
      this.getEInvoiceListWithParams(page)
    },

    changePageSize (pageSize) {
      this.getEInvoiceListWithParams(1, pageSize)
    },

    toggleOtherOptions () {
      this.showOtherOptions = !this.showOtherOptions
    },

    closeOtherOptions () {
      this.showOtherOptions = false
    },
  }
}
</script>

<style scoped lang="scss">

  @import "~@/stylesheet/config/mixin";
  :deep(.Modal) {
    z-index: 600 !important;
  }

  :deep(.Search-input-txt) {
    text-overflow: ellipsis !important;
  }

  :deep(.select) {
    height: 38px !important;
    padding-right: 30px !important;
  }

  :deep(.icon-global-passive-recipe) {
    margin-left: 5px !important;
  }
  :deep(.icon-global-active-recipe ) {
    margin-left: 5px !important;
  }
  .eInvoice-datePicker {
    z-index: $z-index-sm;
  }
  .filters {
    z-index: $z-index-sm;
  }
  .dateTypeFilter {
    z-index: $z-index-sm;
  }

  .MiniPopup-left[data-v-02fb76c5]::before {
    border-top: 1px solid $color-warning !important;
    border-left: 1px solid $color-warning !important;
  }
  .MiniPopup-left {
    @include MiniPopupMixin(
      $width: 150px,
      $triangle-direction: 'rightCenter',
      $padding-hor: 10px,
      $padding-ver: 10px,
    );
    height: auto;
    top: calc(50% - 25px);
    left: 80px;
    border-color: $color-warning;
    z-index: 999999999999;
  }

  .custom-select-box {
    width: 50%;
  }

  .active {
    display: flex;
  }

  .error-code{
    border-radius: 4px;
    background:  rgba(($color-rejection), 0.2);
    color: $color-rejection;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    padding: 5px 5px;
    width: 100%;
  }
  .success-label {
    @extend.error-code;
    background: rgba(($color-success), 0.2);
    color: $color-success;
  }
  .inProgress-label {
    @extend.error-code;
    background: rgba(($color-orange), 0.2);
    color: $color-orange;
  }
  .order-number-label {
    @extend.error-code;
    background: rgba(($color-success), 0.2);
    color: $color-success;
    cursor: pointer;
    font-weight: $font-weight-bold

  }
  .waiting-label {
    @extend.error-code;
    background: rgba(($color-primary), 0.2);
    color: $color-primary
  }

  :deep() .Tableee-body {
    width: 100% !important;
    overflow-x: scroll !important;
  }

  .isTransform {
    font-size: unset;
  }

  :deep() .Modal-Body-content {
    table {
      width: 200px !important;
      max-width: 300px !important;
      margin-left: 0 !important;
    }
    tbody {
      width: 200px !important;
    }
  }

  .invoice-template {
    position: fixed;
    width: 600px;
    left: 30%;
    height: 500px;
    background: white;
    z-index: 99999;
  }
  .iframe-invoice {
    height: calc(100% - 10px);
  }
  .custom-select {
    position: relative;
    padding-right: 5px !important;
    width: 150px !important;
  }
  :deep() .Tableee-scroll{
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  :deep() .Tableee-col {
    padding-left: 21px;
    padding-right: 21px;
  }
 :deep() .Tableee-head .Tableee-col.fixed {
    &:nth-child(2) {
      left: 50px;
      z-index: $z-index-xs;
    }
    &:nth-child(3) {
      left: 140px;
      z-index: $z-index-xs;
    }
    &:nth-child(4) {
      left: 230px;
      z-index: $z-index-xs;
    }
  }

  :deep() .Tableee-body .Tableee-row td.fixed {
    z-index: 1;
    &:nth-child(2) {
      left: 50px;
      z-index: $z-index-xxs;
    }
    &:nth-child(3) {
      left: 140px;
    }
    &:nth-child(4) {
      left: 230px;
    }
  }

  .disable-order-selection {
    background: rgba(230, 230, 230, 0.383);
    color: rgb(136, 135, 135);
  }
  :deep() .appTooltip {
    top: -20px !important;
    &::before {
      display: none !important;
    }
  }
  .show-more {
    position: relative;
    .dropdown-handle {
      display: flex;
      align-items: center;
      font-size: $font-size-micro;
      user-select: none;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;

      .disabled-button {
        pointer-events: none;
        opacity: 0.6;
      }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      position: relative;
      padding-right: 25px;
      height: 35px;
      color: inherit;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;

      &.select {
        display:flex;
        align-items: center;
      }
      &:hover {
        box-shadow: 0 0 0 1px $color-success-dark;
        transition: 0.5s ease-in-out;
      }

      &-icon.icon.icon-down-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 10px;
        transition: all 0.5s;
        color: $color-success;
        &.open {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &-values {
        line-height: 35px;
        font-weight: $font-weight-bold;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }
    }
  }
    .MiniPopup {
      z-index: $z-index-lg;
      width: max-content;
      top: 40px;
      border: 1px solid $color-gray;

      .label {
        justify-content: start;
        padding: 10px;
        &:hover{
          background-color: $color-gray;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }

  .dropdown-handle {
    display: flex;
    align-items: center;
    font-size: $font-size-micro;
    user-select: none;
    border: 1px solid $color-gray;
    border-radius: $border-high-radius;

    .disabled-button {
      pointer-events: none;
      opacity: 0.6;
    }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      color: $color-success;
      position: relative;
      padding-right: 25px;
      height: 35px;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;

      &.select {
        display:flex;
        align-items: center;
      }
      &:hover {
        box-shadow: 0 0 0 1px $color-success-dark;
        transition: 0.5s ease-in-out;
      }

      &-icon.icon.icon-down-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 10px;
        transition: all 0.5s;
        &.open {
          transform: translateY(-50%) rotate(180deg);
        }

      }

      &-values {
        line-height: 35px;
        font-weight: $font-weight-bold;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

    }
  }



</style>
