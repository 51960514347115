<template lang="pug">
Page
  template(slot="header-left-search")
    Search(
      componentName="Inventory",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch")
  template(slot="header-breadcrumb-left")
    .Breadcrumb.tooltip-relative
      router-link.c-light(
      id="link-inventory-breadcrumb"
      :to="{ name: 'Inventories' }")
        | {{ $t('Inventory.breadCrumb_Inventory') }}
      span {{ ">" }}
      a(href="" id="link-inventory-breadcrumb-active").isSelected {{ InventoryInfo.status === 1 ? getName(inventoryBreadCrumb) : inventoryBreadCrumb }}
      Tooltip(
        v-if="inventoryBreadCrumb.length > 50 && InventoryInfo.status === 1"
        white
        bottom
        :text="inventoryBreadCrumb"
      )
    span.inventory-type.c-light {{ inventoryHead.type == 1 ? $t('Inventories.inventoryTypePeriodic') : $t('Inventories.inventoryTypeIntermediate') }}

  template(slot="header-right-content")
    Button(
    v-if="hasPermissionToCloseInventory"
    size="medium"
    primary
    type="button"
    id="btn-inventory-close"
    @click="close(inventoryHead.id)")
      span {{ $t('Inventory.closeInventoryButton') }}


  template(slot="toolbar-left")
    router-link.tabLink#toggle-group-0(v-if="!isOnlyListUsage" :to="{name: 'Inventory', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_all') }}
    router-link.tabLink.isSelected#toggle-group-1(:to="{ name: 'Inventorylists', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_list') }}
    router-link.tabLink#toggle-group-2(v-if="!isOnlyListUsage" :to="{ name: 'InventoryRecipe', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_recipe') }}
    router-link.tabLink#toggle-group-3(v-if="!isOnlyListUsage" :to="{ name: 'InventoryShelf', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_shelf') }}
    router-link.tabLink#toggle-group-4(v-if="!isOnlyListUsage && inventory_Header.expirationDateControlIsEnabled" :to="{ name: 'InventoryExpireDate', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_expire_date') }}

    CustomMultiselect.m-left-5(
      id="inventories-list-type-filter"
      :items="listTypes"
      :selected="listTypeList"
      :label="$t('Inventory.listType')"
      :popupHeader="$t('Inventory.filter_listType')"
      :unselectedText="listTypeList.length > 0 ? listTypeList[0].name : $t('Global.toolbar_Filters_Unselected_PlaceHolder')"
      :showAll="false"
      :buttonText="$t('Global.apply')"
      type="radio"
      :isCustomRadioFilter="true"
      buttonIconLeft="btn-success"
      @delegateCheckItem="onChangeListTypes"
      @delegateSubmitItems="getInventoryListsFromType"
      ref="listTypeList"
    )

    CustomMultiselect.m-left-5.toolbar-filters__item(
    id="multiselect-lists-filter"
    :items="InventoryHeaderList"
    :showAll="false"
    :isDisabled="!enableListFilter"
    :selected="listFilter"
    :isCustomRadioFilter="true"
    :label="$t('Inventory.filterTitle_Inventory_List')"
    :unselectedText="listFilter.length > 0 ? listFilter[0].name : $t('Global.toolbar_Filters_Unselected_PlaceHolder')"
    :popupHeader="$t('Inventory.filterTitle_Inventory_List')"
    :buttonText="$t('Global.toolbar_Filters_ButtonText')"
    buttonIconLeft="btn-success"
    type="radio"
    @delegateCheckItem="onChangeFilter"
    @delegateSubmitItems="getLists")

  template(slot="toolbar-right" )
    ImportExport(
      ref="wrapper"
      componentName="Inventory-lists"
      :isDisabled="isFetchingData"
      :activeImportExports=['export','template']
      :templateUrl="downloadExcelUrl"
      :templateFileName="$t('Inventory.downloadTemplate')"
      :templateDisabled="!InventoryInfo.status === 1"
      templateFileType="xlsx"
      templateMethod = "GET"
      :downloadUrl="downloadUrl"
      :downloadDisabled="isStoreTypeCostCenter"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="inventoryBreadCrumb + '_by_list'"
    )

  template(slot="content-center")
    TableLoading(v-if="isFetchingLists || isFetchingData")
    
    .CustomNestedTable(v-else)
      div(
        v-for="(header,HeaderIndex) in lists",
        :key="HeaderIndex",
        :class="{'isOpened': header.showDetail}")

        .NestedDetail
          .NestedDetail-row.header(:class="isTypeRecipe ? 'custom-grid' : ''")
            .NestedDetail-row-col(v-show="!isTypeRecipe") {{ $t('Inventory.detailList_TableColumn_StockItemName') }}
            .NestedDetail-row-col(v-show="isTypeRecipe") {{ $t('Inventory.detailList_TableColumn_recipe') }}
            .NestedDetail-row-col {{ $t('Inventory.detailList_TableColumn_Unit') }}
            .NestedDetail-row-col {{ $t('Inventory.detailList_TableColumn_UnitQuantityInput') }}
            .NestedDetail-row-col(v-show="!isTypeRecipe") {{ $t('Inventory.detailList_TableColumn_BaseQuantityTotal') }}
            .NestedDetail-row-col(v-show="!isTypeRecipe") {{ $t('Inventory.detailList_TableColumn_ActualQuantityTotal') }}
          .NestedDetail-row.item.align-baseline(:class="isTypeRecipe ? 'custom-grid' : '' ", v-for="(detailItem, detailIndex) in header.inventoryItemList",
            :key="`${detailIndex}listDetail`"
            :id="`nested-table-detail-${detailIndex}`")
            .col(v-show="!isTypeRecipe")
              span {{ detailItem.stockItemName }}
            .col(v-show="isTypeRecipe")
              span {{ detailItem.recipeHeaderName }}
            .col.unit
              span(
                v-for="(unit, unitIndex) in detailItem.unitCountList",
                :key="`${unitIndex}-unitKey`"
              ) {{ unit.unitName}}
            .col
              .unitItem(
                v-for="(unit, unitIndex) in detailItem.unitCountList",
                :key="`${unitIndex}-unitKeyInput`")
                input.txt.no-spinners(
                  type="number",
                  :name="`${unitIndex}-lists-unit`",
                  v-model.number="unit.unitQuantity",
                  :disabled="header.source === 1 || isStoreTypeCostCenter",
                  :id="`input-inventory-lists-unit-${unitIndex}`"
                  v-validate="{max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
                  :data-vv-scope="`lists-unit-${detailIndex}-${unitIndex}`"
                  @change="onChangeDetailUnitCount({ detailItem , unit }, `lists-unit-${detailIndex}-${unitIndex}`)",
                  :class="{'is-danger': veeErrors.has(`lists-unit-${detailIndex}-${unitIndex}.${unitIndex}-lists-unit`), 'first-input' : unitIndex === 0 , 'last-input': detailItem.unitCountList.length - 1 === unitIndex }"
                )

            .col(v-show="!isTypeRecipe")
              .unitItem(
                v-for="(unit, unitIndex) in detailItem.unitCountList",
                :key="`${unitIndex}-unitKeyInput`")
                template(v-if="unit.inventoryCountId")
                  label(
                  :for="`${detailItem.stockItemId}-unitCount-${unit.unitId}`"
                  ) {{ actualQuantityIndividual(unit) }} {{ detailItem.baseUnitCode }}
                template(v-else)
                  span -
            .col(v-show="!isTypeRecipe")
                span {{ actualQuantityTotal(detailItem) }} {{ detailItem.baseUnitCode }}

        TableFoot(
          :page="inventoryListPage",
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize")

    ReqireStep(
      v-if="!listItemSelected"
      iconName="icon-require-list-item",
      :title="$t('Inventory.Not_Selected_List_Title')",
      :description="$t('Inventory.Not_Selected_List_Desc')"
    )

    router-view(
      @getList="getLists"
    )

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import ImportExport from '@/view/global/import-export'
import TableFoot from '@/view/global/table/foot.vue'
import ReqireStep from '@/view/global/require-step'

export default {
  name: 'InventoryOpenedStatusList',

  components: {
    ImportExport,
    TableFoot,
    ReqireStep
  },

  data () {
    return {
      lists: [],
      listFilter: [],
      searchList: [],
      text: '',
      listItemSelected: false,
      inventoryHead: {},
      isOnlyListUsage: false,
      listTypeList: [],
      enableListFilter: false,
      listTypes: [
        {
          id: 1,
          name: this.$t('Inventory.filter_listType_stockItem')
        },
        {
          id: 2,
          name: this.$t('Inventory.filter_listType_recipe')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('Inventories', [
      'inventoryListPage',
      'inventory_Header',
      'selectedList',
      'selectedListType'
    ]),
    ...mapGetters('ListsInventory', [
      'InventoryHeaderList'
    ]),
    ...mapGetters('Settings', [
      'permissions',
      'checkPermission'
    ]),

    hasPermissionToCloseInventory () {
      return this.checkPermission('CloseInventory')
    },

    isFetchingData () {
      return this.$wait.is('listFetching')
    },
    isTypeRecipe () {
      const asd = this.InventoryHeaderList.find(i => i.itemType === 2)
      return !!asd
    },
    isFetchingLists () {
      return this.$wait.is('getOpenInventoryLists')
    },
    inventoryBreadCrumb () {
      return this.inventoryHead.storeName + '-' + moment(this.inventoryHead.inventoryDate).format('DD/MM/YYYY')
    },
    InventoryInfo () {
      return {
        ...this.$route.params,
        status: parseInt(this.$route.params.status)
      }
    },
    downloadUrl () {
      return 'Inventory/inventorycountbylist/export'
    },
    downloadParams () {
      return {
        inventoryHeaderId: this.InventoryInfo.id,
        inventoryListHeaderIds: [...this.listFilter.map(_ => _.id)],
        text: this.search || ''
      }
    },
    downloadExcelUrl () {
      return 'Inventory/inventorycount/template/export'
    },
    isStoreTypeCostCenter () {
      return this.inventory_Header ? this.inventory_Header.storeType === 3 : false
    }
  },
  methods: {
    ...mapActions('Inventories', [
      'getOpenInventoryLists',
      'create_INVENTORY_UNIT_COUNT',
      'close_INVENTORY',
      'update_INVENTORY_UNIT_COUNT',
      'get_inventory_item',
      'sendRecipeCount'
    ]),
    ...mapActions('ListsInventory', ['getInventoryHeaderLists']),
    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('Inventories', [
      'UPDATE_LIST_TYPE_FILTER',
      'UPDATE_LIST_FILTER'
    ]),

    onChangeSearch (search) {
      this.text = search
      if (this.text.length === 1) return
      this.getLists()
    },

    async changePage (page) {
      await this.getLists(page)
    },

    async changePageSize (pageSize) {
      await this.getLists(1, pageSize)
    },

    onChangeListTypes (event) {
      this.listTypeList = [event.item]
      this.UPDATE_LIST_TYPE_FILTER(this.listTypeList)
    },

    async getLists (page, pageSize = this.inventoryListPage.size) {
      if (this.listFilter.length === 0) return
      const res = await this.getOpenInventoryLists({
        inventoryHeaderId: this.InventoryInfo.id,
        text: this.text,
        inventoryListHeaderIds: [...this.listFilter.map(_ => _.id)],
        pageNumber: page || 1,
        pageSize
      })
      this.listItemSelected = true
      this.lists = res.data.inventoryListHeaderList.map(item => {
        return {
          ...item,
          showDetail: false
        }
      })
    },

    close (id) {
      this.$validator.validateAll().then(result => {
        if (!result) return
        this.$router.push({
          name: 'InventoryListDetailClose',
          params: {
            id: id
          }
        })
      })
    },
    getName (name) {
      return (name && name.length > 50 ) ? name.substr(0, 50).concat('...') : name
    },
    onChangeFilter (event) {
      this.listFilter.shift()
      this.listFilter.push(event.item)
      this.UPDATE_LIST_FILTER(this.listFilter)
    },
    actualQuantityTotal (detail) {
      return detail.unitCountList.reduce((total, unitCountItem, _index) => {
        return total + this.calculateActualBaseQuantityOfUnit(unitCountItem) + detail.quantityByList
      }, 0)
    },
    actualQuantityIndividual (unitCountItem) {
      return this.calculateActualBaseQuantityOfUnit(unitCountItem)
    },
    async getInventoryListsFromType () {
      this.lists = []
      this.enableListFilter = true
      await this.getInventoryHeaderLists({
        storeIds: [this.inventoryHead.storeId],
        pageSize: 99999999,
        allSources: true,
        type: 1,
        itemType: this.listTypeList[0].id
      })
    },
    calculateActualBaseQuantityOfUnit (unitCountItem) {
      const baseQuantity = unitCountItem.baseQuantity
      const unitCount = parseFloat(unitCountItem.unitQuantity || 0)
      return baseQuantity * unitCount
    },
    onChangeDetailUnitCount (options, scope) {
      this.$validator.validateAll(scope).then(async result => {
        if (!result) return
        if (!this.isTypeRecipe) this.onChangeDetailUnitCountUpdate(options)
        else this.onChangeDetailRecipeUnitCount(options)
      })
    },
    onChangeDetailRecipeUnitCount (options) {
      const payload = {
        inventoryHeaderId: this.inventoryHead.id,
        recipeHeaderId: options.detailItem.recipeHeaderId,
        inventoryListHeaderId: options.unit.inventoryListHeaderId,
        actualQuantity: options.unit.unitQuantity,
        isOverwrite: true
      }
      this.sendRecipeCount(payload)
    },
    async onChangeDetailUnitCountUpdate (options) {
      const {
        unitId,
        inventoryCountId,
        inventoryListHeaderId
      } = options.unit
      const stockItemId = options.detailItem.stockItemId
      const quantity = options.unit.unitQuantity

      if (inventoryCountId) {
        // PUT
        const params = {
          stockItemId,
          unitId,
          inventoryListHeaderId,
          params: {
            id: inventoryCountId,
            unitQuantity: quantity || 0
          }
        }
        const res = await this.update_INVENTORY_UNIT_COUNT(params)
        const [list] = this.lists.filter(_ => _.inventoryListHeaderId === inventoryListHeaderId)
        const itemDetail = list.inventoryItemList.find(s => s.stockItemId === stockItemId)
        const itemUnit = itemDetail.unitCountList.find(u => u.inventoryCountId === inventoryCountId)
        Object.assign(itemUnit, {
          ...itemUnit,
          unitQuantity: res.data.unitQuantity
        })
      } else {
        // POST
        const params = {
          stockItemId,
          unitId,
          params: {
            inventoryHeaderId: this.inventoryHead.id,
            stockItemId: stockItemId,
            unitId: unitId,
            actualQuantity: quantity,
            inventoryListHeaderId: inventoryListHeaderId
          }
        }

        const [list] = this.lists.filter(_ => _.inventoryListHeaderId === inventoryListHeaderId)
        const itemDetail = list.inventoryItemList.find(s => s.stockItemId === stockItemId)
        const itemUnit = itemDetail.unitCountList.find(u => u.unitId === unitId)

        const res = await this.create_INVENTORY_UNIT_COUNT(params)

        Object.assign(itemUnit, {
          ...itemUnit,
          unitQuantity: res.data.unitQuantity,
          inventoryCountId: res.data.id
        })
      }
    }
  },

  async mounted () {
    this.$wait.start('listFetching')
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    const res = await this.get_inventory_item(this.InventoryInfo.id)
    if (!res) return
    this.inventoryHead = res.data.inventoryHeader
    this.listFilter = [...this.selectedList]
    this.listTypeList = [...this.selectedListType]
    if (this.listTypeList.length > 0) this.getInventoryListsFromType()
    if (this.listFilter.length > 0) this.getLists()
    this.$wait.end('listFetching')
  }
}
</script>

<style lang="scss" scoped>

.Search{
  position: relative;

  & .icon-search{
    width: 48px;
    color: $color-light;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  & .txt{
    padding-left: 40px;
    padding-right: 40px;
  }
}

.listLoading {
  top: initial;
}
.inventory-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: $font-size-small;
}
.Breadcrumb {
  display: flex;
  font-size: $font-size-small;

  span {
    margin-left: 10px;
    margin-right: 10px;
  }

  .isSelected {
    color: $color-primary;
    font-weight: $font-weight-bold;
    pointer-events: none;
  }
}
.toolbar {
  .left {
    display: flex;
    align-items: center;
    .toolbar-filters__item {
      margin-left: 20px;
    }
    .tabLink {
      padding: 11px 27px;
      font-weight: $font-weight-normal;
      margin-right: 10px;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      user-select: none;
      background: $color-white;
      &.isSelected {
        color: $color-primary;
        font-weight: $font-weight-bold;
        border-color: $color-primary;
      }
    }
  }
}

  .custom-grid {
    grid-template-columns: 1fr 250px repeat(1, 1fr)
  }
  :deep() .report-normal-button {
    box-shadow: 0px 0px 0px 1px $color-gray !important;
    &:hover {
      box-shadow: 0px 0px 0px 1px $color-success-dark !important;
    }
  }



</style>
