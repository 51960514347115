var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            staticClass: "z-index-xs",
            attrs: {
              selectedList: _vm.PageLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canBeAdded && !_vm.IsEmptyFieldsList,
                  expression: "canBeAdded && !IsEmptyFieldsList",
                },
              ],
              attrs: {
                primary: "",
                size: "medium",
                iconName: "icon-global-add",
              },
              on: { click: _vm.addNewOptionalFields },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("OptionalFields.newOptionalField"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.isFetchingList && _vm.IsEmptyFieldsList
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("OptionalFields.emptyState_Title"),
                  description: _vm.$t("OptionalFields.emptyState_Description"),
                  buttonText: _vm.$t("OptionalFields.newOptionalField"),
                },
                on: { emptyAddButton: _vm.addNewOptionalFields },
              })
            : _vm._e(),
          _vm.isFetchingList ? _c("TableLoading") : _vm._e(),
          !_vm.isFetchingList && !_vm.IsEmptyFieldsList
            ? _c("div", { staticClass: "list" }, [
                _c(
                  "div",
                  { staticClass: "custom-table" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row header",
                        attrs: { id: "OptionalFieldRowHeader" },
                      },
                      [
                        _c("div", { staticClass: "col" }, [
                          _vm._v(_vm._s(_vm.$t("OptionalFields.field_name"))),
                        ]),
                        _c("div", { staticClass: "col" }, [
                          _vm._v(
                            _vm._s(_vm.$t("OptionalFields.filed_type_short"))
                          ),
                        ]),
                        _c("div", { staticClass: "col" }, [
                          _vm._v(
                            _vm._s(_vm.$t("OptionalFields.field_module_name"))
                          ),
                        ]),
                        _c("div", { staticClass: "col" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("OptionalFields.field_validate_required")
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "col" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("OptionalFields.field_validate_unique")
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "col actions" }),
                      ]
                    ),
                    _vm._l(_vm.OptionalFields, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "row",
                          attrs: { id: `OptionalFieldRow${index}` },
                        },
                        [
                          _c("div", { staticClass: "col" }, [
                            _vm._v(_vm._s(item.columnName)),
                          ]),
                          _c("div", { staticClass: "col" }, [
                            _vm._v(_vm._s(_vm.customInput(item.columnType))),
                          ]),
                          _c("div", { staticClass: "col" }, [
                            _vm._v(_vm._s(_vm.getModuleName(item.tableType))),
                          ]),
                          _c("div", { staticClass: "col" }, [
                            item.columnType === 1 || item.columnType === 3
                              ? _c(
                                  "span",
                                  {
                                    class: item.isRequired
                                      ? "c-success"
                                      : "c-warning",
                                  },
                                  [
                                    _c("Icon", {
                                      attrs: {
                                        name: _vm.validateIcon(item.isRequired),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v("-")]),
                          ]),
                          _c("div", { staticClass: "col" }, [
                            item.columnType === 1
                              ? _c(
                                  "span",
                                  {
                                    class: item.isUnique
                                      ? "c-success"
                                      : "c-warning",
                                  },
                                  [
                                    _c("Icon", {
                                      attrs: {
                                        name: _vm.validateIcon(item.isUnique),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v("-")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col actions" },
                            [
                              _c("ActionButton", {
                                attrs: {
                                  id: "request-order-list-action-button",
                                  data: ["edit", "remove"],
                                  item: item,
                                },
                                on: {
                                  onItemDelete: function ($event) {
                                    return _vm.onItemDelete(item)
                                  },
                                  onItemEdit: function ($event) {
                                    return _vm.onItemEdit(item)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("router-view"),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("TableFoot", {
            attrs: { page: _vm.Page },
            on: {
              delegateOnChangePage: _vm.changePage,
              delegateOnChangePageSize: _vm.changePageSize,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }