var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search",
            attrs: {
              componentName: "Units",
              isSuggestionLoading: false,
              customSuggestionPlaceHolder: _vm.$t("Global.search_Placeholder"),
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-units-new",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.itemNew },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Units.addUnitButton")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isUnitsLoading ? _c("TableLoading") : _vm._e(),
          !_vm.isUnitsEmpty && !_vm.isUnitsLoading
            ? _c("TableView", {
                attrs: {
                  fields: _vm.fields,
                  page: _vm.Page,
                  actions: ["edit", "remove"],
                  data: _vm.paginatedUnitList,
                  componentName: "UnitTable",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemEdit: _vm.editUnit,
                  delegateOnItemDelete: _vm.removeUnit,
                },
              })
            : _vm._e(),
          _vm.isUnitsEmpty && !_vm.isUnitsLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Units.emptyState_Title"),
                  description: _vm.$t("Units.emptyState_Description"),
                  buttonText: _vm.$t("Units.emptyState_ButtonText"),
                },
                on: { emptyAddButton: _vm.itemNew },
              })
            : _vm._e(),
          _c("router-view", { on: { getList: _vm.getList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }