var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row",
      class: { "is-multiple": _vm.detail.unitCountList.length > 1 },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        {
          staticClass: "col largeCol",
          attrs: { id: `table-inventory-detail-body-col-0-${_vm.index}` },
        },
        [_vm._v(_vm._s(_vm.detail.itemGroupName))]
      ),
      _c(
        "div",
        {
          staticClass: "col largeCol",
          attrs: { id: `table-inventory-detail-body-col-1-${_vm.index}` },
        },
        [_vm._v(_vm._s(_vm.detail.stockItemName))]
      ),
      _c(
        "div",
        {
          staticClass: "col u-textRight",
          class: { "text-is-danger": _vm.detail.expectedQuantity < 0 },
          attrs: { id: `table-inventory-detail-body-col-2-${_vm.index}` },
        },
        [
          _vm._v(
            _vm._s(_vm.detail.expectedQuantity) +
              " " +
              _vm._s(_vm.detail.baseUnitCode)
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "col unitCountLabels u-textRight",
          attrs: { id: `table-inventory-detail-body-col-3-${_vm.index}` },
        },
        _vm._l(_vm.detail.unitCountList, function (unitCountItem) {
          return _c(
            "label",
            {
              staticClass: "unitCountLabels-item",
              attrs: {
                for: `${_vm.detail.stockItemId}-unitCount-${unitCountItem.unitId}`,
              },
            },
            [_vm._v(_vm._s(unitCountItem.unitName))]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "col unitCountValues",
          attrs: { id: `table-inventory-detail-body-col-4-${_vm.index}` },
        },
        _vm._l(_vm.detail.unitCountList, function (unitCountItem, index) {
          return _c("div", { staticClass: "unitCountValues-row" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.unitCounts[unitCountItem.unitId],
                  expression: "unitCounts[unitCountItem.unitId]",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    min_value: 0,
                    decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                    max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                  },
                  expression:
                    "{min_value:0, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                },
              ],
              staticClass: "txt u-textRight",
              class: {
                "is-danger": _vm.veeErrors.has(
                  `${_vm.detail.stockItemId}-unitCount-${unitCountItem.unitId}`
                ),
                "first-input": index === 0,
                "last-input": _vm.detail.unitCountList.length - 1 === index,
              },
              attrs: {
                name: `${_vm.detail.stockItemId}-unitCount-${unitCountItem.unitId}`,
                id: `input-inventory-unitCount-${index}`,
                autocomplete: "off",
                disabled: _vm.isStoreTypeCostCenter,
                type: "text",
              },
              domProps: { value: _vm.unitCounts[unitCountItem.unitId] },
              on: {
                change: function ($event) {
                  return _vm.onChangeUnitCount(unitCountItem.unitId)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.unitCounts,
                    unitCountItem.unitId,
                    $event.target.value
                  )
                },
              },
            }),
          ])
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "col unitCountValues",
          attrs: { id: `table-inventory-detail-body-col-5-${_vm.index}` },
        },
        _vm._l(_vm.detail.unitCountList, function (unitCountItem, index) {
          return _c("div", { staticClass: "unitCountValues-row is--text" }, [
            _vm.unitCounts[unitCountItem.unitId] > 0
              ? _c(
                  "div",
                  { staticClass: "unitCountValues-baseUnit u-textRight" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.actualQuantityIndividual(unitCountItem).toFixed(3)
                      ) +
                        " " +
                        _vm._s(_vm.detail.baseUnitCode)
                    ),
                  ]
                )
              : _vm._e(),
          ])
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "col u-textRight",
          attrs: { id: `table-inventory-detail-body-col-6-${_vm.index}` },
        },
        [
          _vm.hasActualQuantityValue
            ? [
                _vm._v(
                  _vm._s(_vm.actualQuantityTotal.toFixed(3)) +
                    " " +
                    _vm._s(_vm.detail.baseUnitCode)
                ),
              ]
            : [_vm._v("-")],
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "col u-textRight",
          attrs: { id: `table-inventory-detail-body-col-7-${_vm.index}` },
        },
        [
          _vm.hasActualQuantityValue
            ? [_vm._v(_vm._s(_vm.formattedActualQuantityDifference))]
            : [_vm._v("-")],
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "col u-textRight",
          attrs: { id: `table-inventory-detail-body-col-8-${_vm.index}` },
        },
        [
          _vm.hasActualQuantityValue && _vm.differenceQuantityPercent !== "!"
            ? [_vm._v("%" + _vm._s(_vm.differenceQuantityPercent))]
            : [_vm._v("-")],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }