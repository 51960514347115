<template lang="pug">
  .dashboard
    .reports(v-if="selectedType === 'incomeStats'")
      .col.col-ld.col-sd.col-tb.col-mb(v-for="(report, index) in reportBoxList")
        router-link(
          :id="`link-reports-summary-${report.routerName}`"
          :to="{ name: report.routerName }")
          ReportBox(
            :infoText= "report.infoText"
            :percent= "report.percent"
            :value= "report.value"
            :summary= "report.summary"
            :revertStatus="report.revertStatus"
            :rowId="index"
            :periodType="periodType"
          )

    .title.m-bottom-10(v-if="selectedType !== 'incomeStats'")
      .title-col {{ this.$t('Dashboard.forecastSales') }}
      .title-col {{ this.$t('Reports.realSales') }}

    .forecast(v-if="selectedType !== 'incomeStats'")
      .col.col-ld.col-sd.col-tb.col-mb(v-for="(forecastReport, index) in forecastBoxList")
        ReportBox(
          :infoText="forecastReport.infoText"
          :percent="forecastReport.percent"
          :value="forecastReport.value"
          :useSecondInfoText="forecastReport.useSecondInfoText"
          :secondInfoText="forecastReport.secondInfoText"
          :summary="null"
          :secondValue="forecastReport.secondValue"
          :rowId="index"
          :date="forecastReport.date"
          @click="goToForecastReports(forecastReport)"
        )

</template>

<script>
import ReportBox from './components/report-box'
import {mapGetters} from 'vuex'
export default {
  name: 'reports',
  components: {
    ReportBox
  },

  props: {
    periodType: {
      type: Number
    },
    selectedType: {
      type: String,
      default: 'incomeStats'
    },
    foreCastData: {
      type: Object
    }
  },

  computed: {
    ...mapGetters('Reports', [
      'profitSummary',
      'profitDifferencePercentage',
      'incomeSummary',
      'incomeDifferencePercentage',
      'purchaseSummary',
      'purchaseDifferencePercentage',
      'salesSummary',
      'salesDifferencePercentage',
      'productionSummary',
      'productionDifferencePercentage'
    ]),
    ...mapGetters('Dashboard', [
      'ForecastSummaryMonthly',
      'ForecastSummaryWeekly',
      'BestForecasts',
      'WorstForecasts',
      'Today',
      'LastWeek',
      'LastMonth',
      'NextWeek',
      'NextMonth'
    ]),
    reportBoxList () {
      return [
        {
          infoText: this.$t('Reports.summary_ProfitTitle'),
          percent: this.profitDifferencePercentage,
          value: this.profitSummary.currentTotalProfitAmount,
          summary: this.profitSummary.previousTotalProfitAmount,
          routerName: 'IncomeStats',
          revertStatus: false
        },
        {
          infoText: this.$t('Reports.summary_IncomeTitle'),
          percent: this.incomeDifferencePercentage,
          value: this.incomeSummary.currentTotalIncomeAmount,
          summary: this.incomeSummary.previousTotalIncomeAmount,
          routerName: 'IncomeStats',
          revertStatus: false
        },
        {
          infoText: this.$t('Reports.summary_PurchaseTitle'),
          percent: this.purchaseDifferencePercentage,
          value: this.purchaseSummary.currentTotalPurchaseAmount,
          summary: this.purchaseSummary.previousTotalPurchaseAmount,
          routerName: 'PurchaseReports',
          revertStatus: false
        },
        {
          infoText: this.$t('Reports.summary_salesTitle'),
          percent: this.salesDifferencePercentage,
          value: this.salesSummary.currentTotalSalesAmount,
          summary: this.salesSummary.previousTotalSalesAmount,
          routerName: 'SalesReports',
          revertStatus: false
        },
        {
          infoText: this.$t('Reports.summary_productionTitle'),
          percent: this.productionDifferencePercentage,
          value: this.productionSummary.currentTotalProductionAmount,
          summary: this.productionSummary.previousTotalProductionAmount,
          routerName: 'Production',
          revertStatus: false
        }
      ]
    },
    forecastBoxList () {
      return [
        {
          infoText: this.$t('Reports.summary_nextWeek'),
          percent: null,
          value: this.isForecastUsed ? this.ForecastSummaryWeekly.futureTotalIncomeAmountForecast : 0,
          summary: null,
          date: this.isForecastUsed ? [moment(this.Today).add(1, 'days'), this.NextWeek] : [],
          revertStatus: false
        },
        {
          infoText: this.$t('Reports.summary_nextMonth'),
          percent: null,
          value: this.isForecastUsed ? this.ForecastSummaryMonthly.futureTotalIncomeAmountForecast : 0,
          summary: null,
          date: this.isForecastUsed ? [moment(this.Today).add(1, 'days'), this.NextMonth] : [],
          revertStatus: false
        },
        {
          infoText: this.$t('Reports.summary_lastWeek'),
          useSecondInfoText: true,
          secondInfoText: this.$t('Reports.summary_forecast'),
          percent: this.isForecastUsed ? this.ForecastSummaryWeekly.differencePercentage : 0,
          value: this.isForecastUsed ? this.ForecastSummaryWeekly.previousTotalIncomeAmount : 0,
          secondValue: this.isForecastUsed ? this.ForecastSummaryWeekly.previousTotalIncomeAmountForecast : 0,
          summary: null,
          date: this.isForecastUsed ? [this.LastWeek, moment(this.Today).add(-1, 'days')] : [],
          revertStatus: false
        },
        {
          infoText: this.$t('Reports.summary_lastMonth'),
          secondInfoText: this.$t('Reports.summary_forecast'),
          useSecondInfoText: true,
          percent: this.isForecastUsed ? this.ForecastSummaryMonthly.differencePercentage : 0,
          value: this.isForecastUsed ? this.ForecastSummaryMonthly.previousTotalIncomeAmount : 0,
          secondValue: this.isForecastUsed ? this.ForecastSummaryMonthly.previousTotalIncomeAmountForecast : 0,
          summary: null,
          date: this.isForecastUsed ? [this.LastMonth, moment(this.Today).add(-1, 'days')] : [],
          revertStatus: false
        }
      ]
    },
    isForecastUsed() {
      if (this.ForecastSummaryMonthly === null && this.ForecastSummaryWeekly === null) return false
      else return true
    }
  },
  methods: {
    goToForecastReports(forecastReport) {
      if (!this.isForecastUsed) return
      this.$router.push({
        name: 'ForecastSales',
        params: {
          startDate: forecastReport.date[0], 
          endDate: forecastReport.date[1]
        }
      })
    }
  },
  mounted () {
    this.$emit('getSummary')
  }
}
</script>

<style scoped lang="scss">
  .reports {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .col {
    padding-left: 15px;
    @media (max-width: 600px) {
      &-mb {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    @media (min-width: 600px) {
      &-tb {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    @media (min-width: 1024px) {
      &-sd {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
        flex: 0 0 33%;
        max-width: 33%;
      }
    }
    @media (min-width: 1280px) {
      &-ld {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
  .forecast {
    @extend .reports;
    .col {
      @media (min-width: 1280px) {
        &-ld {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 20%;
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }
  }
  .title {
    display: flex;
    &-col {
      width: 50%;
      padding-left: 18px;
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
    }
  }
</style>
