<template lang="pug">
Page
  template(slot="header-breadcrumb-left")
    .PageHeaderEkstra#page-header-ekstra-inventory-lists-spot
      .left
        .Breadcrumb#breadcrumbs-inventory-lists-spot
          router-link.c-light(
          id="link-inventory-breadcrumb"
          :to="{ name: 'Inventories' }")
            | {{ $t('Inventory.breadCrumb_Inventory') }}
          span {{ ">" }}
          a(href="" id="link-inventory-breadcrumb-active").isSelected {{ inventoryBreadCrump }}
      span.inventory-type.c-light {{ inventory_Header?.type === 1 ? $t('Inventories.inventoryTypePeriodic') : $t('Inventories.inventoryTypeIntermediate') }}
  template(slot="header-right-content")
    Button(
    v-if="hasPermissionToCloseInventory"
    primary
    size="small"
    type="button"
    id="btn-inventory-close"
    iconName="icon-btn-success",
    iconClass="icon-btn-success"
    @click="close(inventory_Header)")
      span {{ $t('Inventory.closeSpotInventoryButton') }}

  template(slot="overgroups-left")
    router-link.tabLink.isSelected#toggle-group-0(v-if="!isOnlyListUsage" :to="{name: 'InventorySpotList', params: { id: $route.params.id, status: $route.params.status, storeName: $route.params.storeName, inventoryDate: $route.params.inventoryDate }}") {{ $t('Inventory.view_mode_spotList') }}
    router-link.tabLink#toggle-group-4(v-if="!isOnlyListUsage && inventory_Header?.expirationDateControlIsEnabled" :to="{ name: 'InventoryExpireDateSpotList', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_expire_date') }}

  template(slot="toolbar-left")
    CustomMultiselect.toolbar-filters__item(
      id="multiselect-lists-filter"
      :items="listsOptions"
      :selected="listFilter"
      :label="$t('Inventory.filterTitle_Inventory_List')"
      :unselectedText="$t('Global.toolbar_Filters_Unselected')"
      :popupHeader="$t('Inventory.filterTitle_Inventory_List')"
      :allLabel="$t('Global.toolbar_Filters_AllLabel')"
      :buttonText="$t('Global.toolbar_Filters_ButtonText')"
      buttonIconLeft="btn-success"
      @delegateCheckAll="onChangeFilter"
      @delegateCheckItem="onChangeFilter"
      @delegateSubmitItems="getLists"
    )

    ImportMassec(
      v-if="!isStoreTypeCostCenter"
      :inventoryInfo="InventoryInfo",
      page="spot-inventory"
    )

  template(slot="content-center")

    TableLoading.listLoading(v-if="isFetchingLists || isFetchingData")
    EmptyState(
    v-if="isEmpty && !isFetchingData && !isFetchingLists",
    :title="$t('Inventories.emptyStateSpotList_Title')")

    .InventoryContent
      .NestedTable(v-if="!isFetchingLists && !isFetchingData")#table-inventory-lists-spot
        .NestedTable-item(
          v-for="(header,HeaderIndex) in lists",
          :key="HeaderIndex",
          :class="{'isOpened': header.showDetail}"
          :id="`#table-item-header-inventory-lists-spot-${HeaderIndex}`"
        )
          .NestedTable-row(:id="`#table-item-header-row-inventory-lists-spot-${HeaderIndex}`")
            .NestedTableHeaderLink(
                @click="header.showDetail = !header.showDetail")
            .NestedTable-row-col(:id="`#table-item-name-inventory-lists-spot-${HeaderIndex}`") {{ header.inventoryListHeaderName }}
            .NestedTable-row-col.action(:id="`#table-item-action-inventory-lists-spot-${HeaderIndex}`")
              .body
                Button(
                size="small"
                variant="icon c-success btn-switch"
                :justIcon="true"
                :id="`btn-nested-table-open-header-${HeaderIndex}`"
                @click="header.showDetail = !header.showDetail",
                iconName="icon-arrow-down",
                iconClass="icon-arrow-down")
          .NestedDetail(v-show="header.showDetail" :id="`#table-item-details-inventory-lists-spot-${HeaderIndex}`")
            .NestedDetail-row.header
              .NestedDetail-row-col(:id="`#table-item-details-stock-item-name-inventory-lists-spot-${HeaderIndex}`") {{ $t('Inventory.detailList_TableColumn_StockItemName') }}
              .NestedDetail-row-col(:id="`#table-item-details-unit-inventory-lists-spot-${HeaderIndex}`") {{ $t('Inventory.detailList_TableColumn_Unit') }}
              .NestedDetail-row-col(:id="`#table-item-details-unit-quantity-inventory-lists-spot-${HeaderIndex}`") {{ $t('Inventory.detailList_TableColumn_UnitQuantityInput') }}
              .NestedDetail-row-col(:id="`#table-item-details-base-unit-quantity-inventory-lists-spot-${HeaderIndex}`") {{ $t('Inventory.detailList_TableColumn_BaseQuantityTotal') }}
              .NestedDetail-row-col(:id="`#table-item-details-quantity-total-inventory-lists-spot-${HeaderIndex}`") {{ $t('Inventory.detailList_TableColumn_ActualQuantityTotal') }}
            .NestedDetail-row.item(v-for="(detailItem, detailIndex) in header.inventoryItemList",
              :key="`${detailIndex}listDetail`")
              .col
                | {{ detailItem.stockItemName }}

              .col.unit
                span(
                  v-for="(unit, unitIndex) in detailItem.unitCountList",
                  :key="`${unitIndex}-unitKey`",
                ) {{ unit.unitName }}
              .col
                .unitItem(
                  v-for="(unit, unitIndex) in detailItem.unitCountList",
                  :key="`${unitIndex}-unitKeyInput`")
                  input.txt.no-spinners(
                    type="number",
                    name="units"
                    v-model.number="unit.unitQuantity",
                    :disabled="header.source === 1 || inventory_Header?.storeType === 3",
                    :id="`${detailItem.stockItemId}-unitCount-${unit.unitId}`"
                    @change="onChangeDetailUnitCount({ detailItem , unit }, `unit-${detailIndex}-${unitIndex}`)",
                    :data-vv-scope="`unit-${detailIndex}-${unitIndex}`"
                    v-validate="{max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                    :class="{'is-danger': veeErrors.has(`unit-${detailIndex}-${unitIndex}.units`), 'first-input' : unitIndex === 0 , 'last-input': detailItem.unitCountList.length - 1 === unitIndex}"
                  )
              .col
                .unitItem(
                  v-for="(unit, unitIndex) in detailItem.unitCountList",
                  :key="`${unitIndex}-unitKeyInput`")
                  template(v-if="unit.inventoryCountId")
                    label(
                    :for="`${detailItem.stockItemId}-unitCount-${unit.unitId}`"
                    ) {{ actualQuantityIndividual(unit) }} {{ detailItem.baseUnitCode }}
                  template(v-else)
                    span -
              .col
                  span {{ actualQuantityTotal(detailItem) }} {{ detailItem.baseUnitCode }}

    router-view(
      @getList="getLists",
      @successImportAction="importSuccesAction"
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

//
import ImportMassec from './components/import-massec'

export default {
  name: 'InventorySpotList',
  data () {
    return {
      lists: [],
      listsOptions: [],
      listFilter: [],
      text: null,
      inventory_Header: null
    }
  },
  computed: {
    ...mapGetters('Settings', ['checkPermission']),

    hasPermissionToCloseInventory () {
      return this.checkPermission('CloseInventory')
    },
    isOnlyListUsage () {
      return this.checkPermission('ListOnlyUsage')
    },
    isEmpty () {
      return this.lists.length === 0
    },
    isFetchingData () {
      return this.$wait.is('listFetching')
    },
    isFetchingLists () {
      return this.$wait.is('getOpenInventoryLists')
    },
    inventoryBreadCrump () {
      return this.InventoryInfo.storeName + '-' + moment(this.InventoryInfo.inventoryDate).format('DD/MM/YYYY')
    },
    InventoryInfo () {
      return {
        ...this.$route.params,
        status: parseInt(this.$route.params.status)
      }
    },
    isStoreTypeCostCenter () {
      return this.inventory_Header ? this.inventory_Header.storeType === 3 : false
    }
  },
  components: {
    ImportMassec
  },
  methods: {
    ...mapActions('Inventories', [
      'getOpenInventoryLists',
      'create_INVENTORY_UNIT_COUNT',
      'close_INVENTORY',
      'update_INVENTORY_UNIT_COUNT',
      'getSpotListHeaderInfo'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    onChangeSearch (search) {
      this.text = search
      if (this.text.length === 1) return
      this.getLists()
    },
    async getLists () {
      if (this.listFilter.length === 0) return
      const res = await this.getOpenInventoryLists({
        inventoryHeaderId: this.InventoryInfo.id,
        pageSize: 9999999,
        pageNumber: 1,
        inventoryListHeaderIds: [...this.listFilter.map(_ => _.id)]
      })
      this.lists = res.data.inventoryListHeaderList.map(item => {
        return {
          ...item,
          showDetail: false
        }
      })
      return res
    },

    close (inventoryHeader) {
      this.$validator.validateAll().then(result => {
        if (!result) return
        this.$router.push({
          name: 'InventorySpotListDetailClose',
          params: {
            id: inventoryHeader.id
          }
        })
        // if (!confirm(this.$t('Inventory.confirm_CloseInventory'))) return
        // this.close_INVENTORY(id).then(res => {
        //   if (res) {
        //     this.$router.push({
        //       name: 'Inventories'
        //     })
        //     const message = this.$t('Inventory.notification_InventoryClosed')
        //     this.notifyShow({ message })
        //   }
        // })
      })
    },

    async importSuccesAction () {
      await this.getHeaderInfo()
      await this.getLists()
    },

    async getHeaderInfo () {
      const res = await this.getSpotListHeaderInfo(this.InventoryInfo.id)
      if (!res) return
      this.listsOptions = await [...res.data.inventoryListHeaderList]
      this.listFilter = await [...this.listsOptions]
    },

    onChangeFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.listFilter = [...this.listsOptions]
        } else {
          this.listFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.listFilter = []
        } else {
          this.listFilter.splice(this.listFilter.indexOf(event.item), 1)
        }
      }
    },
    actualQuantityTotal (detail) {
      return detail.unitCountList.reduce((total, unitCountItem, _index) => {
        return total + this.calculateActualBaseQuantityOfUnit(unitCountItem) + detail.quantityByList
      }, 0)
    },
    actualQuantityIndividual (unitCountItem) {
      return this.calculateActualBaseQuantityOfUnit(unitCountItem)
    },
    calculateActualBaseQuantityOfUnit (unitCountItem) {
      const baseQuantity = unitCountItem.baseQuantity
      const unitCount = parseFloat(unitCountItem.unitQuantity || 0)
      return baseQuantity * unitCount
    },
    onChangeDetailUnitCount (options, scope) {
      this.$validator.validateAll(scope).then(async result => {
        if (!result) return
        this.onChangeDetailUnitCountUpdate(options)
      })
    },
    async onChangeDetailUnitCountUpdate (options) {
      const {
        unitId,
        inventoryCountId,
        inventoryListHeaderId
      } = options.unit
      const stockItemId = options.detailItem.stockItemId
      const quantity = options.unit.unitQuantity

      if (inventoryCountId) {
        // PUT
        const params = {
          stockItemId,
          unitId,
          inventoryListHeaderId,
          notCommit: true,
          params: {
            id: inventoryCountId,
            unitQuantity: quantity || 0
          }
        }
        const res = await this.update_INVENTORY_UNIT_COUNT(params)
        const [list] = this.lists.filter(_ => _.inventoryListHeaderId === inventoryListHeaderId)
        const itemDetail = list.inventoryItemList.find(s => s.stockItemId === stockItemId)
        const itemUnit = itemDetail.unitCountList.find(u => u.inventoryCountId === inventoryCountId)
        Object.assign(itemUnit, {
          ...itemUnit,
          unitQuantity: res.data.unitQuantity
        })
      } else {
        // POST
        const params = {
          stockItemId,
          unitId,
          notCommit: true,
          params: {
            inventoryHeaderId: this.inventory_Header.id,
            stockItemId: stockItemId,
            unitId: unitId,
            actualQuantity: quantity,
            inventoryListHeaderId: inventoryListHeaderId
          }
        }

        const [list] = this.lists.filter(_ => _.inventoryListHeaderId === inventoryListHeaderId)
        const itemDetail = list.inventoryItemList.find(s => s.stockItemId === stockItemId)
        const itemUnit = itemDetail.unitCountList.find(u => u.unitId === unitId)

        const res = await this.create_INVENTORY_UNIT_COUNT(params)

        Object.assign(itemUnit, {
          ...itemUnit,
          unitQuantity: res.data.unitQuantity,
          inventoryCountId: res.data.id
        })
      }
    }
  },
  async mounted () {
    this.$wait.start('listFetching')
    await this.getHeaderInfo()
    const lists = await this.getLists()
    this.inventory_Header = lists.data.inventoryHeader
    this.$wait.end('listFetching')
  }
}
</script>

<style lang="scss" scoped>

.inventory-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: $font-size-small;
}

.right-button{
  display: flex;
  align-items: center;
}

.listLoading {
  top: initial;
}
.Breadcrumb {
  display: flex;
  font-size: $font-size-small;

  span {
    margin-left: 10px;
    margin-right: 10px;
  }

  .isSelected {
    color: $color-primary;
    font-weight: $font-weight-bold;
    pointer-events: none;
  }
}
.toolbar {
  .left {
    display: flex;
    .toolbar-filters__item {
      margin-left: 20px;
    }
    .tabLink {
      border: 1px solid $color-gray;
      margin-right: 10px;
      border-radius: $border-radius;
      font-size: $font-size-micro;
      font-weight: bold;
      color: $color-light;
      height: 34px;
      line-height: 0;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      &.isSelected {
        background: $color-success;
        color: #fff;
        border: none;
      }
    }
  }
}

.InventoryContent {
  background-color: $color-white;
  border: $color-white;
}
// table

.NestedTable {
  &-item {
    border: 1px solid $color-gray;
    border-radius: $border-radius;
    margin-bottom: 10px;
    &.isOpened {
     .NestedTable-row {
      border-bottom: 1px solid $color-gray;
        background: $color-ocean;
        .btn-switch {
          transform: rotate(180deg);
        }
      }
    }
    &:hover {
      &:not(.isOpened) {
        background: $color-ocean;
        border:1px solid $color-light-blue;
      }
    }
  }
  &-row {
    position: relative;
    min-height: 59px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    &-col {
      word-break: break-all;
      width: 100%;
      &.action {
        display: none;
        width: 40px;
      }
    }
    &:hover {
      .action {
        display: block;
      }
    }
  }
  .NestedDetail {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    &-row {
      display: grid;
      grid-template-columns: 1fr 150px repeat(3, 1fr);
      padding: 20px;
      &.header {
        color: $color-success;
        font-size: $font-size-micro;
        font-weight: $font-weight-bold;
      }
      &:nth-child(2) {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
      &.item {
        border: 1px solid $color-gray;
        border-bottom-color: transparent; //
        min-height: 59px;
        align-items: center;
        &:focus-within {
          border: 2px dashed $color-primary;
          background: $color-ocean;
        }
        &:hover:not(:focus-within) {
          border: 1px solid $color-primary;
          background: $color-ocean;
          box-shadow: rgba($color-ocean, 0.28)
        }
        .col {
          font-size: $font-size-small;
          &.unit {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            span {
              margin-top: 15px;
            }
          }
          .unitItem {
            display: flex;
            span, label {
              min-height: 40px;
              display: flex;
              align-items: center;
            }
          }
          .txt {
            border-radius: 0;
            max-width: 77px;
            font-size: $font-size-small;
            &:focus {
              box-shadow: none;
            }
            &:not(.first-input):not(:focus) {
              border-top-color: transparent;
            }
            &.first-input {
              border-top-right-radius: $border-radius;
              border-top-left-radius: $border-radius;
            }
            &.last-input {
              border-bottom-right-radius: $border-radius;
              border-bottom-left-radius: $border-radius;
            }
          }
        }
        &:last-child {
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
          border: 1px solid $color-gray;
          &:focus-within {
            border: 2px dashed $color-primary;
          }
          &:hover:not(:focus-within) {
            border: 1px solid $color-primary;
            box-shadow: rgba($color-ocean, 0.28)
          }
        }
      }
    }
  }
}
.tabLink {
  padding: 11px 27px;
  font-weight: $font-weight-normal;
  margin-right: 10px;
  font-family: $font-family;
  color: $color-light;
  cursor: pointer;
  border: 1px solid $color-gray;
  border-radius: $border-radius;
  user-select: none;
  background: $color-white;
  &.isSelected {
    color: $color-primary;
    font-weight: $font-weight-bold;
    border-color: $color-primary;
  }
}
</style>
