<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")

    .form-item-select.date-start
      CustomDatepicker#WasteReportDatepicker(
        v-model="dates",
        :range="true"
        :isLoading="$wait.is('getDateFilterSettings')"
        :disabled="isWasteReportsLoading || isPageLoading"
      )
  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="groupTypes",
      :selectedItem="activeGroupType",
      @delegateChangeSelectedItem="onChangeGroupType",
      :isNotActiveDisabled="false",
      fieldKey="name"
    )
  template(slot="toolbar-left")
    CustomMultiselect.toolbar-filters__item(
    id="waste-reports-store-filter"
    :items="storeList"
    :selected="storeFilter"
    :label="$t('Global.toolbar_Store_Label')"
    :unselectedText="$t('Global.toolbar_Stores_AllLabel')"
    :popupHeader="$t('Global.toolbar_Stores_PopupHeader')"
    :allLabel="$t('Global.toolbar_Stores_AllLabel')"
    :buttonText="$t('Global.toolbar_Stores_ButtonText')"
    buttonIconLeft="btn-success"
    @delegateCheckAll="onChangeStoreFilter"
    @delegateCheckItem="onChangeStoreFilter"
    @delegateSubmitItems="getWasteReportsWithParams")

    .toolbar__separator

    Button.wasteGroup-switch(
    variant="icon switch"
    id="btn-waste-reports-wasteGroup-switch"
    type="button"
    @click="onSwitchIncludeWasteGroup")
      svg.icon(:class="includeWasteGroup ? 'eye-open' : 'eye-closed'")
        use(:xlink:href="includeWasteGroup ? '#icon-login-eye' : '#icon-login-eye-closed'")
      | {{ $t('WasteReports.toolbar_WasteGroup') }}

    .toolbar__separator(v-if="activeGroupType && includeWasteGroup")

    CustomCheckbox.wasteGroup-primary-checkbox(
    v-if="activeGroupType && includeWasteGroup"
    size="large"
    id="checkbox-waste-reports-wasteGroup-primary"
    :label="$t('WasteReports.toolbar_WasteGroupPrimary')"
    v-model="isWasteGroupPrimary"
    @change="onChangeWasteGroupPrimary")

  template(slot="toolbar-right")
    ImportExport(
      componentName="waste-reports"
      ref="wrapper"
      :isDisabled="isPageLoading"
      :activeImportExports=['export']
      :downloadParams="downloadParams"
      downloadUrl="Report/waste/export"
      :downloadFileName="downloadFileName"
      downloadFileType="xlsx"
    ) 
  template(slot="content-center")
    .wasteReports
      .results
        TableLoading(v-if="isWasteReportsLoading || isPageLoading")

        TableView(
        v-if="!isWasteReportsLoading && !isWasteReportsEmpty && !isPageLoading"
        :fields="wasteFields"
        :readOnly="true"
        :componentName="'WasteTable'"
        :data="wasteReports")

        EmptyState(
        v-if="!isWasteReportsLoading && isWasteReportsEmpty && !isPageLoading"
        :title="$t('WasteReports.Waste_EmptyState_Title')"
        :description="$t('WasteReports.Waste_EmptyState_Description')")
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import getWasteFields from './fields/waste-report'
//
import ImportExport from '@/view/global/import-export'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import getDateFilterSetting from '@/utils/getDateFilterSetting'

export default {
  name: 'WasteReports',

  components: {
    ToggleOverGroups,
    ImportExport
  },

  data () {
    return {
      dates: [],
      groupTypes: [{
        name: this.$t('WasteReports.groupTypes_OverGroup'),
        id: 1
      }, {
        name: this.$t('WasteReports.groupTypes_MajorGroup'),
        id: 2
      }, {
        name: this.$t('WasteReports.groupTypes_ItemGroup'),
        id: 3
      }],
      activeGroupType: null,
      storeFilter: [],
      includeWasteGroup: false,
      isWasteGroupPrimary: false
    }
  },

  async created () {
    this.$wait.start('pageLoading')
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_WasteReport')
    await this.getMiniStoreList().then(async () => {
      this.activeGroupType = this.groupTypes[0]
      this.storeFilter = await [...this.storeList]
      await this.getWasteReportsWithParams()
    })
    this.$wait.end('pageLoading')
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getWasteReportsWithParams()
    }
  },

  computed: {
    ...mapGetters('Reports', [
      'wasteReports',
      'isWasteReportsEmpty'
    ]),

    ...mapGetters('Waste', [
      'pageHeaderLinks'
    ]),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList'
    }),

    wasteFields () {
      const columns = {
        groupType: this.activeGroupType ? this.groupTypes.find(groupType => groupType.id === this.activeGroupType.id) : null,
        includeWasteGroup: this.includeWasteGroup,
        isWasteGroupPrimary: this.isWasteGroupPrimary
      }

      return getWasteFields(this.$t, columns)
    },

    isWasteReportsLoading () {
      return this.$wait.is(['getWasteReports'])
    },

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    downloadParams () {
      const params = {
        StartDate: this.dateFormat(this.dates[0]),
        EndDate: this.dateFormat(this.dates[1]),
        IncludeWasteGroup: this.includeWasteGroup,
        IsWasteGroupPrimary: this.isWasteGroupPrimary,
        GroupType: this.activeGroupType ? this.activeGroupType.id : null,
        StoreList: this.storeFilter.map(store => store.id)
      }
      return params
    },
    downloadFileName () {
      const fileName = `WasteReport_${this.dateFormat(this.dates[0])}_${this.dateFormat(this.dates[1])}`
      return fileName
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Reports', [
      'getWasteReports'
    ]),

    ...mapActions('Stores', [
      'getMiniStoreList'
    ]),

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    onChangeGroupType (groupType) {
      if (this.activeGroupType && this.activeGroupType.id === groupType.id) {
        this.activeGroupType = null
        if (!this.includeWasteGroup) {
          this.includeWasteGroup = true
          this.isWasteGroupPrimary = false
        }
      } else {
        this.activeGroupType = groupType
      }
      this.getWasteReportsWithParams()
    },

    onChangeStoreFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.storeFilter = [...this.storeList]
        } else {
          this.storeFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.storeFilter = []
        } else {
          this.storeFilter.splice(this.storeFilter.indexOf(event.item), 1)
        }
      }
    },

    onSwitchIncludeWasteGroup () {
      this.includeWasteGroup = !this.includeWasteGroup
      if (this.includeWasteGroup) {
        this.isWasteGroupPrimary = false
      } else if (!this.activeGroupType) {
        this.activeGroupType = this.groupTypes[0]
      }
      this.getWasteReportsWithParams()
    },

    onChangeWasteGroupPrimary () {
      this.getWasteReportsWithParams()
    },

    getWasteReportsWithParams () {
      this.getWasteReports({
        start: this.dateFormat(this.dates[0]),
        end: this.dateFormat(this.dates[1]),
        groupType: this.activeGroupType ? this.activeGroupType.id : null,
        IncludeWasteGroup: this.includeWasteGroup,
        IsWasteGroupPrimary: this.isWasteGroupPrimary,
        storeList: this.storeFilter
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .PageHeader {
    padding-bottom: 0;

    .tab {
      display: flex;

      a {
        margin-right: 20px;
        font-size: 1.8em;
        padding-bottom: $page-padding-bottom;
        border-bottom: 4px solid transparent;
        font-family: $font-family;
        font-weight: $font-weight-light;
        color: $color-dark;

        &.isSelected {
          pointer-events: none;
          color: $color-primary;
          border-bottom-color: $color-primary;
          font-weight: 500;
        }
      }
    }

    .right {
      display: flex;
      margin-bottom: 15px;

      .form-item-select {
        width: 200px;
        margin-left: 10px;
      }
    }
  }

  .PageHeaderExtra {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-gray;
  }

  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  .group-types {

    &-item {
      padding: 8px 10px;
      display: inline-block;
      font-weight: $font-weight-bold;
      font-size: $font-size-micro;
      line-height: 1;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;
      user-select: none;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        background: $color-success;
        color: #fff;
        border-color: transparent;
      }
    }
  }

  .wasteGroup-switch {
    width: auto;
    height: 27px;
    font-weight: $font-weight-normal;
    color: $color-light;

    .icon {
      width: 27px;
      height: 27px;
      padding: 7px 4.5px;
      margin-right: 10px;
      background: $color-seashell;
      color: $color-success;
      border: 1px solid;
      border-radius: $border-high-radius;

      &.eye-closed {
        padding: 5px 3px;
        background: transparent;
        color: $color-light;
      }
    }
  }

  .wasteGroup-primary-checkbox {
    font-weight: $font-weight-normal;
    animation: wasteGroup-primary-emerge $transition 1 forwards;
    white-space: nowrap;
    overflow: hidden;
  }

  .results {
    position: relative;
  }

  @keyframes wasteGroup-primary-emerge {
    from {
      max-width: 0;
      opacity: 0;
    }

    to {
      max-width: 200px;
      opacity: 1;
    }
  }
</style>
