var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select date-start" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "ABCReportDatepicker",
                range: true,
                disabled: _vm.isABCAnalysisLoading || _vm.isPageLoading,
                isLoading: _vm.$wait.is("getDateFilterSettings"),
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "toolbar-left" }, [
        _c(
          "div",
          { staticClass: "toolbar-filters" },
          [
            _c("CustomMultiselect", {
              staticClass: "toolbar-filters__item",
              attrs: {
                id: "abc-analysis-store-filter",
                items: _vm.storeList,
                selected: _vm.storeFilter,
                label: _vm.$t("ABCAnalysis.toolbar_Stores_Label"),
                unselectedText: _vm.$t("ABCAnalysis.toolbar_Stores_Unselected"),
                popupHeader: _vm.$t("ABCAnalysis.toolbar_Stores_PopupHeader"),
                allLabel: _vm.$t("ABCAnalysis.toolbar_Stores_AllLabel"),
                buttonText: _vm.$t("ABCAnalysis.toolbar_Stores_ButtonText"),
                buttonIconLeft: "btn-success",
              },
              on: {
                delegateCheckAll: _vm.onChangeStoreFilter,
                delegateCheckItem: _vm.onChangeStoreFilter,
                delegateSubmitItems: _vm.getABCAnalysisWithSelectedParams,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            attrs: {
              componentName: "reports-abc-analysis",
              isDisabled: _vm.isPageLoading,
              activeImportExports: ["export"],
              downloadUrl: "Report/abcanalysis/export",
              downloadFileType: "xlsx",
              downloadParams: _vm.downloadParams,
              downloadFileName: _vm.downloadFileName,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "ABCReports" }, [
          _c(
            "div",
            { staticClass: "results" },
            [
              _vm.isABCAnalysisLoading || _vm.isPageLoading
                ? _c("TableLoading")
                : _vm._e(),
              !_vm.isABCAnalysisLoading &&
              !_vm.isABCAnalysisEmpty &&
              !_vm.isPageLoading
                ? _c("TableView", {
                    attrs: {
                      fields: _vm.ABCFields,
                      page: _vm.abcPage,
                      componentName: "ReportsAbcTable",
                      readOnly: true,
                      data: _vm.ABCAnalysis,
                    },
                    on: {
                      delegateOnChangePage: _vm.changePage,
                      delegateOnChangePageSize: _vm.changePageSize,
                    },
                  })
                : _vm._e(),
              _c("portal", { attrs: { to: "headerCategory" } }, [
                _c("div", { staticClass: "category" }, [
                  _c("span", { staticClass: "category-text" }, [
                    _vm._v(
                      _vm._s(_vm.$t("ABCAnalysis.tableColumn_Category_Short"))
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "category-info" },
                    [
                      _c("Icon", {
                        staticClass: "show-MiniPopup category-info-icon",
                        attrs: { name: "icon-global-info-filled" },
                      }),
                      _c(
                        "ul",
                        { staticClass: "category-popup" },
                        _vm._l(_vm.popupInfoObject, function (item) {
                          return _c(
                            "li",
                            { staticClass: "category-popup-row" },
                            [
                              _c(
                                "span",
                                { staticClass: "category-popup-value" },
                                [_vm._v(_vm._s(item.value))]
                              ),
                              _c(
                                "span",
                                { staticClass: "category-popup-text" },
                                [_vm._v(_vm._s(item.text))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              !_vm.isABCAnalysisLoading && _vm.isABCAnalysisEmpty
                ? _c("EmptyState", {
                    attrs: {
                      title: _vm.$t("ABCAnalysis.emptyState_Title"),
                      description: _vm.$t("ABCAnalysis.emptyState_Description"),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }