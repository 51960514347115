<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
      matchedRoute="SuspendedSales"
    )
        template(slot="linkExtra", slot-scope="link")
          Icon.icon-suspended-status.m-right-5(
          v-if="suspendSalesExist && link.linkName === 'SuspendedSales'",
        name="icon-row-status")
  template(slot="header-left-search")
    Search.search-box.m-right-20(
      componentName="MenuItems",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch"
    )
  template(slot="header-right-dropdown")
    CustomDatepicker#suspend-sales-header-datepicker.m-right-15(
      v-model="dates",
      :isLoading="$wait.is('getDateFilterSettings')"
      :range="true"
    )
  template(slot="header-right-content")
    Button.m-right-5(
      primary
      size="medium"
    id="btn-suspened-sales-stop"
    type="button"
    @click="updateStatus"
    :disabled="isUpdateStatus || isLoading")
      Loading(v-if="isUpdateStatus", theme="disabled")
      template(v-else)
        Icon.m-right-10(name="icon-filled-right")
      span(v-if="isSuspendedActive") {{ $t('MenuItems.SuspendButtonStop') }}
      span(v-else) {{ $t('MenuItems.SuspendButtonStart') }}
  template(slot="toolbar-left")
    businessMultiselect(
      componentName="multiselect-storeGroup-filter"
      :label="$t('Global.toolbar_storeGroups_Label')"
      :popupHeader="$t('Global.toolbar_storeGroups_PopupHeader')"
      :multiselectItems="storeGroupListItems"
      ref="suspendSalesFilter"
      @submitFilter="updateStoreGroupFilterItems")

    CustomMultiselect.toolbar-filters__item(
      ref="suspendSalesRVCFilter"
      :withBadgedText="true",
      badgedTextField="storeGroupName"
      id="multiselect-rvc-filter"
      :items="filteredRvcItems"
      :selected="selectedRvcFilterItems"
      :label="$t('Global.toolbar_RVC_Label')"
      :unselectedText="$t('Global.toolbar_Filters_Unselected')"
      :popupHeader="$t('Global.toolbar_RVC_PopupHeader')"
      :allLabel="$t('Global.toolbar_Filters_AllLabel')"
      :buttonText="$t('Global.toolbar_Filters_ButtonText')"
      buttonIconLeft="btn-success"
      @delegateCheckAll="onChangeFilter"
      @delegateCheckItem="onChangeFilter"
      @delegateSubmitItems="updateRVCFilterItems")

    TransactionTypeFilter(
      ref="businessTransactionTypeFilter"
      @submitFilter="updateTransactionTypeFilter"
    )

    Button.clear-filter-button.border-gray-1.p-0.width-auto.m-right-10(
      @click='clearFilter')
      Icon.icon-clear-filter(name="icon-clear-filter")
    CustomCheckbox(
      :label="$t('MenuItems.Checkbox_Suspend_Sales_Show_Passives')"
      v-model="showPassiveItems")
    TotalViewList(
      :totalCountArray="totalCountData"
    )

  template(slot="toolbar-right")
    Button.m-right-5(
      primary
      size="small"
      id="btn-suspened-sales-with-stock"
      type="button"
      @click="onClickRetrySuspendedSalesButton(false)"
      :disabled="isCheckSuspendSales || isLoadingRetrySuspendedSales || isLoading || isSuspendTransactionInProgress")
      Loading(v-if="isLoadingRetrySuspendedSales", theme="disabled")
      template(v-else)
        span {{ $t('MenuItems.suspendedSalesDownStock') }}
    Button(
      primary 
      size="small"
      id="btn-suspened-sales-with-stock-filter"
      type="button"
      @click="onClickRetrySuspendedSalesButton(true)"
      :disabled="isCheckSuspendSales || isLoadingRetrySuspendedSales || isLoading || isSuspendTransactionInProgress")
      Loading(v-if="isLoadingRetrySuspendedSales", theme="disabled")
      template(v-else)
        span {{ $t('MenuItems.suspendedSalesDownStockWithFilter') }}

  template(slot="content-center")
    .SuspendSalesPageContent
      // LOADING          
      .toolbar.suspend-sales(v-if="isListingHeader ? !isEmptySuspendedSalesHeaderList : !isEmptySuspendedSalesDetailList")
        .toolbar-right
          .popup-container(
            v-click-outside="hideReasonPopup"
          )
            Button(
              primary
              size="small"
              :class="activationStatus"
              :disabled="$refs.table.selectedList.length === 0"
              :id="'suspend-sales-description-reason-show-' + activationStatus "
              @click="showReasonPopup") {{ $t('MenuItems.Button_Suspend_Sales_' + activationStatus) }}
            form.suspend-sales-description-right-MiniPopup(
              v-if="showReasonButtonPopup"
              id="suspend-sales-description-reason-form"
              @submit.prevent ="changeActivationStatus"
            )
              label.suspend-sales-description-MiniPopup-label.required(
              id="suspend-sales-description-reason-label"
              ) {{ $t('MenuItems.Reason_Description_Input_Label') }}
              textarea.txt.suspend-sales-description-MiniPopup-input(
                ref="reason"
                name="reason"
                autocomplete="off"
                v-validate="{required: true, max: 512}"
                v-model="reasonDescription"
                :class="{ 'is-danger': veeErrors.has(`reason`) }"
                id="suspend-sales-description-reason-input"
              )
              Button.m-top-15.m-left-0(
                primary
                size="small"
                type="submit"
                id="suspend-sales-description-reason-submit"
                :disabled="$refs.table.selectedList.length === 0 || isActivationStatusLoading"
              )
                span {{$t('MenuItems.Detail_Col_Button_Agree')}}
                Loading(theme="disable", v-show="isActivationStatusLoading")
      TableLoading(v-if="isLoading")
      router-view(
        @getList="getList"
        @changePage="changePage"
        @changePageSize="changePageSize"
        :isLoading="isLoading"
        ref="table")
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import StoreGroupFilter from '@/view/global/business-filters/storeGroup'
import RVCFilter from '@/view/global/business-filters/rvc'
import TransactionTypeFilter from '@/view/pages/MenuItems/components/transaction-type-filter'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {
  name: 'SuspendedSales',

  data () {
    return {
      showPassiveItems: false,
      transactionTypeFilter: [],
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ],
      search: '',
      showReasonButtonPopup: false,
      reasonDescription: null,
      selectedRvcFilterItems: [],
      filteredRvcItems: [],
      selectedStoreGroupItems: []
    }
  },
  components: {
    RVCFilter,
    StoreGroupFilter,
    TransactionTypeFilter,
    businessMultiselect
  },
  computed: {
    ...mapGetters('SuspendedSales', [
      'isSuspendedActive',
      'isEmptySuspendedSalesDetailList',
      'isEmptySuspendedSalesHeaderList',
      'totalSuspendedAmount',
      'Page'
    ]),

    ...mapGetters('MenuItems', ['pageHeaderLinks', 'RVCList']),

    ...mapGetters('Global', [
      'suspendSalesExist',
      'isSuspendTransactionInProgress'
    ]),

    ...mapGetters('StoreGroups', {
      storeGroupListItems: 'storeGroupList'
    }),

    isListingHeader () {
      return this.$route.name === 'SuspendSalesHeaders'
    },

    totalCountData () {
      return [
        {
          totalCountLabel: this.$t('MenuItems.toolbar_totalAmountSuspendSales'),
          totalCountValue: this.$options.filters.formatCurrency2Digits(this.totalSuspendedAmount),
          extraClass: 'with-bg'
        }
      ]
    },

    isLoading () {
      return this.$wait.is(['getSuspendedSalesDetailList', 'getSuspendedSalesHeaderList', 'updateActivationStatus', 'onClickRetrySuspendedSalesButton'])
    },
    isLoadingRetrySuspendedSales () {
      return this.$wait.is(['retrySuspendedSales'])
    },
    isUpdateStatus () {
      return this.$wait.is(['updateSuspendedStatus', 'updateStatus'])
    },
    isCheckSuspendSales () {
      return this.$wait.is(['checkSuspendSalesExist'])
    },
    isActivationStatusLoading () {
      return this.$wait.is(['changeActivationStatusForm'])
    },
    activationStatus () {
      return this.showPassiveItems ? 'active' : 'passive'
    }
  },

  methods: {
    ...mapActions('SuspendedSales', [
      'getSuspendedSalesHeaderList',
      'getSuspendedSalesDetailList',
      'getSuspendedStatus',
      'getTotalSuspendedAmount',
      'updateSuspendedStatus',
      'updateActivationStatus'
    ]),

    ...mapActions('MenuItems', [
      'retrySuspendedSales',
      'getRVCList'
    ]),

    ...mapActions('Global', [
      'checkSuspendSalesExist'
    ]),

    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),

    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapMutations('SuspendedSales', ['RESET']),

    onChangeFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.selectedRvcFilterItems = [...this.filteredRvcItems]
        } else {
          this.selectedRvcFilterItems.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.selectedRvcFilterItems = []
        } else {
          this.selectedRvcFilterItems.splice(this.selectedRvcFilterItems.indexOf(event.item), 1)
        }
      }
    },

    filterRvcItems () {
      this.filteredRvcItems = this.RVCList.list.filter(item => {
        return this.selectedStoreGroupItems.some(el => {
          return el.id === item.storeGroupId
        })
      })
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) return
      this.getList()
    },

    changeActivationStatus: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return

        const selectedList = this.$refs.table.selectedList
        const payload = {
          groupedItems: [],
          startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
          endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
          searchText: this.search,
          status: this.showPassiveItems, // Take bool of showing passive or active items. While it's active (showing active items) send false to do passive.
          storeGroupIds: this.selectedStoreGroupItems.map(store => store.id),
          revenueCenterIds: this.selectedRvcFilterItems.map(rvc => rvc.id),
          transactionTypes: this.transactionTypeFilter.map(type => type.id),
          reasonDescription: this.reasonDescription
        }

        if (this.isListingHeader) {
          payload.groupedItems = selectedList.map(item => { return { errorType: item.errorType } })
        } else {
          payload.groupedItems = selectedList.map(item => {
            return {
              errorType: this.$route.params.errorType,
              menuItemId: item.menuItemId
            }
          })
          payload.lastUpdateAt = selectedList.length === 1
            ? selectedList[0].lastUpdateAt
            : selectedList.reduce((cur, acc) => {
              return cur.lastUpdateAt > acc.lastUpdateAt ? cur.lastUpdateAt : acc.lastUpdateAt
            })
        }

        await this.updateActivationStatus(payload)
        this.hideReasonPopup()
        this.getList()
      })
    }, 'changeActivationStatusForm'),

    changePage (page) {
      this.getList(page)
    },

    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    getList (page = 1, pageSize = this.Page.size) {
      if (this.selectedStoreGroupItems.length === 0) return
      const params = {
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        showPassiveItems: this.showPassiveItems,
        storeGroupList: this.selectedStoreGroupItems.map(store => store.id),
        revenueCenterList: this.selectedRvcFilterItems.map(rvc => rvc.id),
        transactionStatusTypeParams: this.transactionTypeFilter.map(type => type.id),
        searchText: this.search
      }
      if (this.isListingHeader) this.getSuspendedSalesHeaderList(params)
      else {
        if (this.$route.params.errorType) {
          const errorType = Number(this.$route.params.errorType)
          this.getSuspendedSalesDetailList({ ...params, page, pageSize, errorType, $t: this.$t })
        }
      }
      if (this.$refs.table) this.$refs.table.selectedList = []
      // Clear Reason Input when page change, update, etc.
      this.reasonDescription = null
    },

    updateTransactionTypeFilter ({list, dontSubmit}) {
      this.transactionTypeFilter = list
      if (!dontSubmit) this.getList()
    },

    updateRVCFilterItems () {
      this.getList()
    },

    updateStoreGroupFilterItems ({list, dontSubmit}) {
      this.selectedStoreGroupItems = list
      this.filterRvcItems()
      this.selectedRvcFilterItems = [...this.filteredRvcItems]
      if (!dontSubmit) {
        this.$refs.suspendSalesRVCFilter.showAllSelectedLabel()
        this.getList()
      }
    },

    async clearFilter () {
      this.$refs.suspendSalesFilter.clearAllFilter()
      this.$refs.suspendSalesRVCFilter.showAllSelectedLabel()
      this.$refs.businessTransactionTypeFilter.clearStoreFilter(false)
    },

    onClickRetrySuspendedSalesButton: vueWaitLoader(async function (isFilterType) {
      if (isFilterType) {
        const payload = {
          startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
          endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
          storeGroupList: this.selectedStoreGroupItems.map(store => store.id),
          revenueCenterList: this.selectedRvcFilterItems.map(rvc => rvc.id),
          transactionStatusTypeParams: this.transactionTypeFilter.map(type => type.id),
          searchText: this.search,
          errorTypeList: this.$route.params.errorType ? [Number(this.$route.params.errorType)] : null
        }
        await this.retrySuspendedSales(payload)
      } else {
        // Need to send empty payload 'cause BE api send error not to do so
        const payload = {}
        await this.retrySuspendedSales(payload)
      }
      await this.getList()
      await this.checkSuspendSalesExist()
    }, 'onClickRetrySuspendedSalesButton'),

    updateStatus: vueWaitLoader(async function () {
      await this.updateSuspendedStatus(!this.isSuspendedActive)
    }, 'updateStatus'),

    showReasonPopup () {
      this.showReasonButtonPopup = !this.showReasonButtonPopup
      if (this.showReasonButtonPopup) this.$nextTick(() => this.$refs.reason.focus())
    },

    hideReasonPopup () {
      this.showReasonButtonPopup = false
    }
  },

  async mounted () {
    this.RESET()
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_SuspendTransaction')
    this.getSuspendedStatus()
    this.getTotalSuspendedAmount()
    await this.getRVCList({ pageSize: 1000 })
    await this.getStoreGroupList({ pageNumber: 1, pageSize: 9999})
    this.filteredRvcItems = [...this.RVCList.list]
    this.getList()
    if (this.$route.name === 'SuspendedSales') {
      this.$router.push({ name: 'SuspendSalesHeaders' })
    }
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getList()
    },
    showPassiveItems () {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";

  .PageHeader {
    .icon-suspended-status {
      align-self: center;
      margin-right: 5px;
    }
    .right {
      .top-button {
        .icon {
          margin-right: 10px;
        }
      }
    }
  }

  .toolbar-right {
    display: flex;
  }

  .suspend-sales-description-right-MiniPopup {
    @include MiniPopupMixin(
      $width: 296px,
      $margin: 30px -10px 0 0,
      $triangle-direction: 'right',
      $border-color: $color-light-blue,
      $padding-hor: 25px,
    );
  }

  .suspend-sales-description-MiniPopup,
  .Schedule-right-MiniPopup {
    &-input {
      width: 100%;
      margin-top: 10px;
    }

    &-label {
      max-width: 100%;
      color: $color-dark;
      font-size: $font-size-small;
    }
  }

  .popup-container {
    position: relative;
    width: max-content;
  }

  .toolbar.suspend-sales{
    margin: 0 0 10px 0;
    display: flex;
    justify-content: end;
    flex-wrap: nowrap;
    align-items: end;
  }

</style>
