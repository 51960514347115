<template lang="pug">
Page
  template(slot="header-breadcrumb-left")
    .Breadcrumb.tooltip-relative
      router-link.c-light(
      id="link-inventory-breadcrumb"
      :to="{ name: 'Inventories' }")
        | {{ $t('Inventory.breadCrumb_Inventory') }}
      span {{ ">" }}
      a(href="" id="link-inventory-breadcrumb-active").isSelected {{ InventoryInfo.status === 1 ? getName(inventoryBreadCrumb) : inventoryBreadCrumb }}
      Tooltip(
        v-if="inventoryBreadCrumb.length > 50 && InventoryInfo.status === 1"
        white
        bottom
        :text="inventoryBreadCrumb"
      )
    span.inventory-type.c-light {{ InventoryInfo.inventoryType == 1 ? $t('Inventories.inventoryTypePeriodic') : $t('Inventories.inventoryTypeIntermediate') }}
    
  template(slot="header-left-search")
    Search.search-box(
      componentName="Inventory",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch")    
  template(slot="header-right-dropdown")
    Button(
        v-if="hasPermissionToCloseInventory"
        size="medium"
        primary
        type="button"
        id="btn-inventory-close"
        @click="close"
      )
        span {{ $t('Inventory.closeInventoryButton') }}
  template(slot="header-right-content")
    Button(
      size="medium"
      secondary
      type="button"
      id="btn-add-stock-item"
      @click="addStockItem"
      v-if="!isStoreTypeCostCenter"
    )
      span {{ $t('Inventory.addStockItemButton') }}

  template(slot="toolbar-left")
    router-link.tabLink#toggle-group-0(v-if="!isOnlyListUsage" :to="{name: 'Inventory', params: { id: $route.params.id, status: $route.params.status, storeName: $route.params.storeName, inventoryDate: $route.params.inventoryDate }}") {{ $t('Inventory.view_mode_all') }}
    router-link.tabLink#toggle-group-1(:to="{ name: 'Inventorylists',  params: { id: $route.params.id, status: $route.params.status, storeName: $route.params.storeName, inventoryDate: $route.params.inventoryDate }}") {{ $t('Inventory.view_mode_list') }}
    router-link.tabLink.isSelected#toggle-group-2(v-if="!isOnlyListUsage" :to="{ name: 'InventoryRecipe', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_recipe') }}
    router-link.tabLink#toggle-group-3(v-if="!isOnlyListUsage" :to="{ name: 'InventoryShelf', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_shelf') }}
    router-link.tabLink#toggle-group-4(v-if="!isOnlyListUsage && inventory_Header.expirationDateControlIsEnabled" :to="{ name: 'InventoryExpireDate', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_expire_date') }}

  template(slot="toolbar-right")
    ImportExport(
      componentName="InventoryRecipe"
      ref="wrapper"
      :isDisabled="isLoading"
      :activeImportExports=['export','template']
      :templateUrl="downloadExcelUrl"
      :templateFileName="$t('Inventory.downloadTemplate')"
      :templateDisabled="!InventoryInfo.status === 1"
      templateFileType="xlsx"
      templateMethod = "GET"
      :downloadUrl="downloadUrl"
      :downloadDisabled="!(InventoryInfo.status === 1 && !isStoreTypeCostCenter)"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="inventoryBreadCrumb"
    )
  template(slot="content-center")

    TableLoading(v-if="isLoading && waitCountUpdate")

    template(v-if="!isEmpty && !isLoading")

      TableView(
        v-if="filteredItems.length > 0"
        :componentName="'inventoryRecipe'"
        :fields="fields"
        :data="filteredItems"
        :tableName="$t('Stocks.tableName')"
        :read-only="true"
      )
      portal(to="quantity")
        span(slot-scope="row")
          input.txt.no-spinners(
            name="quantity"
            :data-vv-scope="`recipe${row.index}`"
            :class="{ 'is-danger': veeErrors.has(`recipe${row.index}.quantity`) }"
            v-validate="{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: -1}"
            type="number"
            step="any"
            v-model.lazy="row.quantity"
            @change="onChangeUnitCount(row, `recipe${row.index}`)"
            :disabled="isStoreTypeCostCenter"
            )

    router-view(
      @getList="getInventory",
      @successImportAction="getInventory"
    )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//
import moment from 'moment'
import ImportExport from '@/view/global/import-export'
import matchingSearch from '@/utils/matchingSearch'
import getFields from './fields/inventory-recipe'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'Inventory',

  components: {
    ImportExport
  },

  data () {
    return {
      popup: {
        status: false
      },
      search: '',
      fields: getFields(this.$t),
      isOnlyListUsage: false
    }
  },
  async mounted () {
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    await this.getInventory()
  },

  computed: {
    ...mapGetters('Inventories', ['inventoryRecipeCountList', 'inventory_Header']),
    ...mapGetters('Settings', ['permissions', 'checkPermission']),

    hasPermissionToCloseInventory () {
      return this.checkPermission('CloseInventory')
    },

    InventoryInfo () {
      return {
        ...this.$route.params,
        status: parseInt(this.$route.params.status)
      }
    },

    filteredItems () {
      if (this.search.length >= 2 && !this.isEmpty) {
        return this.inventoryRecipeCountList.filter(item => matchingSearch(item.recipeName, this.search))
      } else {
        return this.inventoryRecipeCountList
      }
    },

    isEmpty () {
      return this.inventoryRecipeCountList.length === 0
    },

    isLoading () {
      return this.$wait.is(['get_RECIPE_INVENTORY'])
    },

    inventoryBreadCrumb () {
      return this.InventoryInfo.storeName + '-recipe-' + moment(this.InventoryInfo.inventoryDate).format('DD/MM/YYYY')
    },

    isCreating () {
      return (
        this.$wait.is(['create_RECIPE_DETAIL_STOCK']) ||
          this.$wait.is(['create_RECIPE_DETAIL_RECIPE'])
      )
    },
    downloadUrl () {
      return 'Inventory/inventoryrecipecount/export'
    },
    downloadExcelUrl () {
      return 'Inventory/inventorycount/template/export'
    },
    waitCountUpdate () {
      return this.$wait.is('updateRecipeCount')
    },
    downloadParams () {
      return {
        inventoryHeaderId: this.InventoryInfo.id,
        text: this.search || null,
        exportType: 1
      }
    },
    isStoreTypeCostCenter () {
      return this.inventory_Header ? this.inventory_Header.storeType === 3 : false
    }
  },

  methods: {
    ...mapActions('Inventories', [
      'get_RECIPE_INVENTORY',
      'sendRecipeCount'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    onChangeSearch (search) {
      this.search = search
    },

    getInventory () {
      const inventoryHeaderId = this.InventoryInfo.id
      this.get_RECIPE_INVENTORY({ inventoryHeaderId })
    },

    onChangeUnitCount: vueWaitLoader(async function (item, scope) {
      this.$validator.validateAll(scope)
        .then(res => {
          if (!res) return
          const payload = {
            inventoryHeaderId: this.$route.params.id,
            recipeHeaderId: item.recipeHeaderId,
            actualQuantity: +item.quantity,
            isOverwrite: true
          }
          this.sendRecipeCount(payload)
          setTimeout(() => this.getInventory(), 500)
        })
    }, 'updateRecipeCount'),

    addStockItem () {
      this.$router.push({
        name: 'InventoryAddRecipe',
        params: {
          id: this.$route.params.id
        }
      })
    },

    close () {
      this.$router.push({
        name: 'InventoryRecipeDetailClose',
        params: {
          id: this.$route.params.id
        }
      })
    },
    getName (name) {
      return (name && name.length > 50 ) ? name.substr(0, 50).concat('...') : name
    }
  }
}
</script>

<style lang="scss" scoped>
.Search {
  position: relative;
  & .icon-search {
    width: 48px;
    color: $color-light;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  & .txt {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.inventory-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: $font-size-small;
}

:deep() .report-normal-button {
  box-shadow: 0px 0px 0px 1px $color-gray !important;

  &:hover {
    box-shadow: 0px 0px 0px 1px $color-success-dark !important;
  }
}
</style>

<style lang="scss">

  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  .toolbar {
    .tabLink {
      padding: 11px 27px;
      font-weight: $font-weight-normal;
      margin-right: 10px;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      user-select: none;
      background: $color-white;
      &.isSelected {
        color: $color-primary;
        font-weight: $font-weight-bold;
        border-color: $color-primary;
      }
    }
  }

  .InventoryDetailList {

    &-baslik {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 5px;
        color: $color-primary;
      }
    }

    &-table {
      .row {
        display: flex;
        align-items: center;
        border-radius: $border-radius;
        border: 1px solid $color-gray;
      }
      .col {
        flex: 1;
        font-size: $font-size-small;
      }

      // head ------------------

      .head {
        font-size: $font-size-micro;
        font-weight: $font-weight-bold;
        color: $color-success;
        position: sticky;
        top: 0;

        .row {
          background-color: $color-lighter-blue;
        }
        .col {
          padding: 20px 15px;
        }
        .col.unitCount {
          flex: 2;
        }
      }

      // body ------------------

      .body {
        .row {
          font-size: $font-size-small;
          background: $color-snow;
          &:nth-child(odd) {
            background-color: $color-white;
          }
          &:focus-within {
            border: 2px dashed $color-primary;
            background: $color-ocean;
          }
          &:hover:not(:focus-within) {
            border: 1px solid $color-primary;
            background: $color-ocean;
            box-shadow: rgba($color-ocean, 0.28)
          }
        }

        .col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 15px !important;
          padding-right: 0px !important;
        }

        .action {
          display: flex;
          justify-content: flex-end;

          .sil {
            margin-left: 10px;
          }
        }
      }
    }
  }
  :deep() .Tableee-head {
    position: sticky !important;
    top: 0 !important;
  }
</style>
