<template lang="pug">
Page
  template(slot="header-title")
    span {{ $t('Despatches.pageHeader_Despatch') }}
  template(slot="content-center")
    TableLoading(v-if="isLoading")
    .Result.m-bottom-15
      .Result-top-header-title
        .doc-number {{documentNumber}}
        span.m-left-5 {{$t('Despatches.document')}}
    .flexbox(v-if="!isLoading")
      TableView(
        :componentName="'despatchDocumentDetail'"
        :fields="fields"
        :actions="['edit']"
        :data="despatchDetail"
        :addScrollToNoFixedColumns="true"
        @delegateOnItemEdit="editDespatch"
      )
      portal(to="stockItem")
        div(slot-scope="row")
          span {{row.stockItem.name}}

      portal(to="unitName")
        div(slot-scope="row")
          span {{ findUnit(row.unitId).name }}
      portal(to="baseUnitName")
        div(slot-scope="row")
          span {{ findUnit(row.unitId).baseUnitName }}

    router-view(
      @getList="getDespatchDetail"
    )

</template>

<script>

import getDespatchDocumentDetailFields from './fields/depatchDocumentDetail'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DespatchDocumentDetail',

  data () {
    return {
      fields: getDespatchDocumentDetailFields(this.$t),
      despatchDetail: [],
      staticData: []
    }
  },

  components: {
  },

  computed: {
    ...mapGetters('Units', ['findUnit']),

    isLoading () {
      return this.$wait.is(['get_DESPATCH', 'findUnit'])
    },

    documentNumber () {
      return this.$route.params.documentNumber
    }
  },
  async mounted () {
    await this.getDespatchDetail(this.$route.params.id)
    await this.getTaxes()
  },
  methods: {
    ...mapActions('Despatch', ['get_DESPATCH']),

    ...mapActions('Tax', ['getTaxes']),

    async getDespatchDetail (despatch) {
      await this.get_DESPATCH(despatch).then(res => {
        if (res) {
          this.despatchDetail = res.data.despatchDetailList
          this.staticData = res.data.despatchDetailList.map(i => i.stockItem.name)
        }
      })
    },
    editDespatch (detail) {
      this.$router.push({
        name: 'DespatchDocumentDetailEdit',
        params: {
          detailId: detail.id,
          despatchDetail: detail
        }
      })
    }
  }
}
</script>

<style lang="scss">

.Result{
  &-top-header-title{
    color: $color-primary;
    display: flex;
  }
}
.doc-number {
  font-weight: bold;
  text-decoration: underline;
}

.Tableee-scroll{
  width: 100%;
  overflow: auto;
}

</style>
