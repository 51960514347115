var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "toolbar-left" }, [
        _c("div", { staticClass: "storeGroupField dropdown" }, [
          _c("span", { staticClass: "dropdown-label" }, [
            _vm._v(_vm._s(_vm.$t("MenuItemsSettings.mergedStoreGroup"))),
          ]),
          _c(
            "div",
            { staticClass: "dropdown-select" },
            [
              _c("Icon", { attrs: { name: "icon-down-arrow" } }),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedStoreGroupId,
                      expression: "selectedStoreGroupId",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "select lg",
                  class: { "is-danger": _vm.veeErrors.has("storeGroupName") },
                  attrs: {
                    id: "select-menu-items-settings-storeGroup-name",
                    name: "storeGroupName",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedStoreGroupId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.getStoreGroupIntegrationInfo,
                    ],
                  },
                },
                [
                  _c(
                    "option",
                    {
                      attrs: {
                        id: "option-menu-items-settings-storeGroup-name-unselected",
                      },
                      domProps: { value: null },
                    },
                    [_vm._v(_vm._s(_vm.$t("Global.formField_Unselected")))]
                  ),
                  _vm._l(_vm.avaibleStoreGroupList, function (storeGroup) {
                    return _c(
                      "option",
                      {
                        key: storeGroup.id,
                        attrs: {
                          id: `option-menu-items-settings-storeGroupgroup-name-${storeGroup.name}`,
                        },
                        domProps: { value: storeGroup.id },
                      },
                      [_vm._v(_vm._s(storeGroup.name))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c(
            "Button",
            {
              attrs: {
                size: "medium",
                primary: "",
                disabled: _vm.isDisabledMatchedButton,
                isLoading: _vm.isMatchingItems,
              },
              on: { click: _vm.matchingSelectedItems },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("MenuItemsSettings.matchedButton"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "MenuItemsSettingsContent" }, [
          _vm.noData
            ? _c(
                "div",
                { staticClass: "nodata" },
                [
                  _c("EmptyState", {
                    attrs: {
                      title: _vm.emptyTitle,
                      description: _vm.emptyMessage,
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "settingsContent",
                  attrs: { id: "MenuItemsSettingsPageContent" },
                },
                [
                  _vm.newIntegrationInfo
                    ? _c(
                        "div",
                        {
                          staticClass: "integration",
                          attrs: { id: "MenuItemsSettingsPassviveIntegration" },
                        },
                        [
                          _c("div", { staticClass: "integration-name" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("MenuItemsSettings.passivePosSystem")
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "value c-dark" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.oldIntegrationInfo
                                      .integrationProductName
                                  )
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "toolbar-search m-bottom-15" },
                            [
                              _c("SearchInput", {
                                on: {
                                  onSearch: function ($event) {
                                    return _vm.getMenuItemList("old")
                                  },
                                },
                                model: {
                                  value: _vm.oldFieldSearch,
                                  callback: function ($$v) {
                                    _vm.oldFieldSearch = $$v
                                  },
                                  expression: "oldFieldSearch",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "toolbar-filters" },
                            [
                              _c("CustomMultiselect", {
                                staticClass: "toolbar-filters__item",
                                attrs: {
                                  id: "imported-menu-items-group-filter",
                                  items: _vm.oldIntegrationInfo.menuItemGroups,
                                  selected:
                                    _vm.oldIntegrationFilter.menuItemGroups,
                                  label: _vm.$t("Global.menuItemGroup"),
                                  unselectedText: _vm.$t(
                                    "Global.toolbar_Filters_Unselected"
                                  ),
                                  popupHeader: _vm.$t("Global.menuItemGroup"),
                                  allLabel: _vm.$t(
                                    "Global.toolbar_Filters_AllLabel"
                                  ),
                                  buttonText: _vm.$t(
                                    "Global.toolbar_Filters_ButtonText"
                                  ),
                                  buttonIconLeft: "btn-success",
                                },
                                on: {
                                  delegateCheckAll: (e) =>
                                    _vm.onChangeFilter(
                                      "old",
                                      "menuItemGroups",
                                      e
                                    ),
                                  delegateCheckItem: (e) =>
                                    _vm.onChangeFilter(
                                      "old",
                                      "menuItemGroups",
                                      e
                                    ),
                                  delegateSubmitItems: function ($event) {
                                    return _vm.getMenuItemList("old")
                                  },
                                },
                              }),
                              _c("CustomMultiselect", {
                                staticClass: "toolbar-filters__item",
                                attrs: {
                                  id: "imported-menu-items-group-filter",
                                  items: _vm.oldIntegrationInfo.revenueCenters,
                                  selected:
                                    _vm.oldIntegrationFilter.revenueCenters,
                                  label: _vm.$t("Global.toolbar_RVC_Label"),
                                  unselectedText: _vm.$t(
                                    "Global.toolbar_Filters_Unselected"
                                  ),
                                  popupHeader: _vm.$t(
                                    "Global.toolbar_RVC_Label"
                                  ),
                                  allLabel: _vm.$t(
                                    "Global.toolbar_Filters_AllLabel"
                                  ),
                                  buttonText: _vm.$t(
                                    "Global.toolbar_Filters_ButtonText"
                                  ),
                                  buttonIconLeft: "btn-success",
                                },
                                on: {
                                  delegateCheckAll: (e) =>
                                    _vm.onChangeFilter(
                                      "old",
                                      "revenueCenters",
                                      e
                                    ),
                                  delegateCheckItem: (e) =>
                                    _vm.onChangeFilter(
                                      "old",
                                      "revenueCenters",
                                      e
                                    ),
                                  delegateSubmitItems: function ($event) {
                                    return _vm.getMenuItemList("old")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isFetchingOldMenuItems
                            ? _c("TableLoading", { staticClass: "m-top-15" })
                            : _c("TableView", {
                                staticClass: "m-top-15",
                                attrs: {
                                  fields: _vm.oldIntegrationField,
                                  componentName: "MenuItemsSettingsTable",
                                  readOnly: true,
                                  pointer: true,
                                  data: _vm.oldMenuItemList,
                                  selectable: "checkbox",
                                  selectedRows: _vm.selectedMenuItems,
                                  page: _vm.oldListPagination,
                                },
                                on: {
                                  delegateOnItemSelect: _vm.onSelectMenuItem,
                                  delegateOnItemShow: _vm.onSelectMenuItem,
                                  delegateOnChangePage: (e) =>
                                    _vm.changePage("old", e),
                                  delegateOnChangePageSize: (e) =>
                                    _vm.changePageSize("old", e),
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "integration-transition" },
                    [_c("Icon", { attrs: { name: "icon-table-next" } })],
                    1
                  ),
                  _vm.oldIntegrationInfo
                    ? _c(
                        "div",
                        { staticClass: "integration" },
                        [
                          _c("div", { staticClass: "integration-name" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("MenuItemsSettings.activePosSystem")
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "value c-success" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.newIntegrationInfo
                                      .integrationProductName
                                  )
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "toolbar-search m-bottom-15" },
                            [
                              _c("SearchInput", {
                                on: {
                                  onSearch: function ($event) {
                                    return _vm.getMenuItemList("new")
                                  },
                                },
                                model: {
                                  value: _vm.newFieldSearch,
                                  callback: function ($$v) {
                                    _vm.newFieldSearch = $$v
                                  },
                                  expression: "newFieldSearch",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "toolbar-filters" },
                            [
                              _c("CustomMultiselect", {
                                staticClass: "toolbar-filters__item",
                                attrs: {
                                  id: "imported-menu-items-group-filter",
                                  items: _vm.newIntegrationInfo.menuItemGroups,
                                  selected:
                                    _vm.newIntegrationFilter.menuItemGroups,
                                  label: _vm.$t("Global.menuItemGroup"),
                                  unselectedText: _vm.$t(
                                    "Global.toolbar_Filters_Unselected"
                                  ),
                                  popupHeader: _vm.$t("Global.menuItemGroup"),
                                  allLabel: _vm.$t(
                                    "Global.toolbar_Filters_AllLabel"
                                  ),
                                  buttonText: _vm.$t(
                                    "Global.toolbar_Filters_ButtonText"
                                  ),
                                  buttonIconLeft: "btn-success",
                                },
                                on: {
                                  delegateCheckAll: (e) =>
                                    _vm.onChangeFilter(
                                      "new",
                                      "menuItemGroups",
                                      e
                                    ),
                                  delegateCheckItem: (e) =>
                                    _vm.onChangeFilter(
                                      "ewn",
                                      "menuItemGroups",
                                      e
                                    ),
                                  delegateSubmitItems: function ($event) {
                                    return _vm.getMenuItemList("new")
                                  },
                                },
                              }),
                              _c("CustomMultiselect", {
                                staticClass: "toolbar-filters__item",
                                attrs: {
                                  id: "imported-menu-items-group-filter",
                                  items: _vm.newIntegrationInfo.revenueCenters,
                                  selected:
                                    _vm.newIntegrationFilter.revenueCenters,
                                  label: _vm.$t("Global.toolbar_RVC_Label"),
                                  unselectedText: _vm.$t(
                                    "Global.toolbar_Filters_Unselected"
                                  ),
                                  popupHeader: _vm.$t(
                                    "Global.toolbar_RVC_Label"
                                  ),
                                  allLabel: _vm.$t(
                                    "Global.toolbar_Filters_AllLabel"
                                  ),
                                  buttonText: _vm.$t(
                                    "Global.toolbar_Filters_ButtonText"
                                  ),
                                  buttonIconLeft: "btn-success",
                                },
                                on: {
                                  delegateCheckAll: (e) =>
                                    _vm.onChangeFilter(
                                      "new",
                                      "revenueCenters",
                                      e
                                    ),
                                  delegateCheckItem: (e) =>
                                    _vm.onChangeFilter(
                                      "new",
                                      "revenueCenters",
                                      e
                                    ),
                                  delegateSubmitItems: function ($event) {
                                    return _vm.getMenuItemList("new")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isFetchingNewMenuItems
                            ? _c("TableLoading", { staticClass: "m-top-15" })
                            : _c("TableView", {
                                staticClass: "m-top-15",
                                attrs: {
                                  fields: _vm.newIntegrationField,
                                  componentName: "NewMenuItemSettingsTable",
                                  readOnly: true,
                                  pointer: true,
                                  data: _vm.newMenuItemList,
                                  page: _vm.newListPagination,
                                },
                                on: {
                                  delegateOnChangePage: (e) =>
                                    _vm.changePage("new", e),
                                  delegateOnChangePageSize: (e) =>
                                    _vm.changePageSize("new", e),
                                },
                              }),
                          _c("portal", {
                            attrs: { to: "destinationMenuItemId" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return _c(
                                      "div",
                                      { staticClass: "width-max-content" },
                                      [
                                        _c("CustomRadio", {
                                          attrs: {
                                            label: "",
                                            checked:
                                              !!_vm.selectedNewItem &&
                                              _vm.selectedNewItem.id === row.id,
                                            tabindex: row.index + 1,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.selectNewItem(row)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              854259102
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }