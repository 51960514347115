var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("Despatches.pageHeader_Refund")))]),
      ]),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _c("div", { staticClass: "Result m-bottom-15" }, [
            _c("div", { staticClass: "Result-top-header-title" }, [
              _c("div", { staticClass: "doc-number" }, [
                _vm._v(_vm._s(_vm.documentNumber)),
              ]),
              _c("span", { staticClass: "m-left-5" }, [
                _vm._v(_vm._s(_vm.$t("Despatches.document"))),
              ]),
            ]),
          ]),
          !_vm.isLoading
            ? _c(
                "div",
                { staticClass: "flexbox" },
                [
                  _c("TableView", {
                    attrs: {
                      componentName: "despatchDocumentDetailStatic",
                      fields: _vm.staticFields,
                      readOnly: true,
                      data: _vm.refundDetail,
                    },
                  }),
                  _c("TableView", {
                    attrs: {
                      componentName: "despatchDocumentDetail",
                      fields: _vm.fields,
                      actions: ["edit"],
                      data: _vm.refundDetail,
                      addScrollToNoFixedColumns: true,
                    },
                    on: { delegateOnItemEdit: _vm.editRefund },
                  }),
                  _c("portal", {
                    attrs: { to: "stockItem" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c("div", {}, [
                              _c("span", [_vm._v(_vm._s(row.stockItem.name))]),
                            ])
                          },
                        },
                      ],
                      null,
                      false,
                      777067582
                    ),
                  }),
                  _c("portal", {
                    attrs: { to: "unitName" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c("div", {}, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.findUnit(row.unitId).name)),
                              ]),
                            ])
                          },
                        },
                      ],
                      null,
                      false,
                      4280288994
                    ),
                  }),
                  _c("portal", {
                    attrs: { to: "baseUnitName" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c("div", {}, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.findUnit(row.unitId).baseUnitName)
                                ),
                              ]),
                            ])
                          },
                        },
                      ],
                      null,
                      false,
                      3278086833
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("router-view", { on: { getList: _vm.getRefundDetail } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }