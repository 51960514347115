<template lang="pug">
Page.price-quote
  template(slot="header-left-search")
    Search.search-box(
      componentName="PriceQuota",
      :isSuggestionLoading="isLoading",
      @onChangeSearchValue="onChangeSearch"
    )

  template(slot="header-right-content")
    Button(
    id="btn-price-quote-new"
    type="button"
    size="medium"
    primary
    v-if="!isEmpty"
    iconName="icon-global-add"
    iconClass="icon-global-add"
    @click="createNewPriceQuote")
      span {{ $t('PriceQuota.createPriceQuotaButton') }}

  template(slot="toolbar-left")
    .toolbar-filters
      SupplierFilter.multiselect-popup(@submitFilter="getListWithSupplierFilter"
      ref="supplierFilter")

      businessMultiselect.multiselect-popup(
        componentName="price-quote-group-filter"
        :multiselectItems="filterData.itemGroupList"
        :label="$t('PriceQuota.toolbar_FilterItemGroup')"
        :popupHeader="$t('PriceQuota.toolbar_FilterItemGroup_PopupHeader')"
        :unselectedText="$t('PriceQuota.toolbar_FilterStock_Unselected')"
        ref="businessQuoteGroupFilter"
        @submitFilter="submitFilteredGroup"
      )
      .custom-select.m-right-10
        .Form-item.dropdown-handle(v-click-outside="closeStockFilterPopup")
          span(ref='dropdownHandleLabel')
          .select.dropdown-handle-button(
            id="select-price-quote-stock-filter"
            @keydown.enter="toggleStockFilterPopup"
            @keydown.prevent.space="toggleStockFilterPopup"
            @click="toggleStockFilterPopup"
            :aria-expanded="showStockFilter"
            tabindex="0"
          )
            Icon.dropdown-handle-button-icon(:class="[showStockFilter ? 'open':'' ]" name="icon-down-arrow")
            .dropdown-handle-button-values
              | {{ $t('PriceQuota.toolbar_FilterStock') }}


          popup-filter(
            label="name"
            v-show="showStockFilter"
            :list="stockItemList"
            :selected-list="selectedStockItems"
            :popupPosition="{}"
            :block-scroll="false"
            :headerLabel="$t('PriceQuota.toolbar_FilterStock_PopupHeader')"
            ref="stockItemPopupFilter"
            :key="'name'"
            @changeSearch="search => changePopupFilterSearch(search)"
            @toggleAll="isSelectedAll => toggleAll(isSelectedAll)"
            @submitFilter="submitFilteredStockItem"
            @clearFilter="clearStockFilter"
            @onChangeCheckbox="({ item, isChecked }) => onChangePopupFilterCheckbox({ item, isChecked })"
          )

      Button.clear-filter-button.border-gray-1.p-0.width-auto.m-right-10(
        @click='clearFilter'
        )
          Icon.icon-clear-filter(name="icon-clear-filter")
      .show-more
        .Form-item.dropdown-handle
          .select.dropdown-handle-button(
            @keydown.enter="toggleOtherOptions"
            @keydown.prevent.space="toggleOtherOptions"
            @click="toggleOtherOptions"
            v-click-outside="closeOtherOptions"
            :aria-expanded="showOtherOptions"
            tabindex="0"
          )
            .dropdown-handle-button-values {{ $t('Global.moreOptions') }}
            Icon(:class="{'open': showOtherOptions}" name="icon-down-arrow").dropdown-handle-button-icon.icon.icon-down-arrow
            
    
        .MiniPopup.left(
          v-if="showOtherOptions"
          v-shortkey="['esc']"
        )

          CustomCheckbox(
            id="checkbox-sales-show-condiments"
            :checked="filters.onlyGlobalSuppliers"
            :label="$t('PriceQuota.Checkbox_Show_Only_Global_Suppliers')"
            @change="changeOnlyGlobalSuppliers")

          CustomCheckbox.show-passive-recipes(
            id="checkbox-price-quotes-show-passive"
            :checked="showPassive"
            :label="$t('PriceQuota.showPassivePriceQuotes')"
            @change="showPassivePriceQuotes")
          
          CustomCheckbox.show-passive-recipes(
            id="checkbox-price-quotes-show-with-base-currency"
            :checked="showWithBaseCurrency"
            :label="$t('PriceQuota.showWithBaseCurrency')"
            @change="showBaseCurrency")
  template(slot="toolbar-right")
    ImportExport.m-right-10(
      componentName="price-quote"
      ref="wrapper"
      :activeImportExports=["import",'export']
      importUrl="PriceQuote/import"
      @importFileSuccess="goImportedPriceQuotes"
      downloadUrl="PriceQuote/export"
      downloadFileType="xlsx"
      :downloadCustomPopup="true"
      :downloadFileName="excelFileName"
      @downloadClick="openExcelPopup"
      @toggleOtherOptions="closeImportExportPopup"
    )
    .download-excel-popup(:class="excelPopup ? 'show-excel-popup' : null" v-click-outside="closeExcelPopup")
      h2.download-excel-popup-header {{ $t('PriceQuota.Export_Excel_Field_Supplier') }}
      custom-search.download-excel-popup-search(
        tabIndex="1"
        name="supplier"
        ref="firstInput"
        v-model="item.supplier"
        :on-search="getOptions"
        :options="selectOptions"
        label="name"
        :placeholder="$t('PriceQuota.popup_FormField_SupplierName_Placeholder')"
      )
      Button.download-excel-popup-button(
        tabIndex="2"
        primary
        iconName="icon-btn-success"
        :disabled="!this.item.supplier || !this.excelPopup"
        @click="downloadExcel"
        id="excel-popup-submit-button"
      ) {{ $t('PriceQuota.Excel_Popup_Submit_Supplier_Button_Text') }}

    Button.generate-barcode.m-right-10(
      size="small"
      secondary
      v-click-outside="closeBarcodeOperationsPopup"
      id="change-price-quote-multiple-barcode-generation-button",
      :disabled="!selectedList.length"
      @click="toggleBarcodeOperations"
    )
      span {{ $t('Stocks.barcodeOperations') }}

      .barcode-selection-popup(:class="showBarcodeOperationsPopup ? 'show-barcode-operation-popup' : null" v-click-outside="() => closeBarcodeOperationsPopup")
        h2.barcode-selection-popup-header {{ $t('Stocks.multipleBarcode') }}
        .barcode-selection-popup-contents.m-top-10

          CustomCheckbox.change-price-type.m-top-5(
            id="price-quote-weighted-barcode-generation"
            :label="$t('Stocks.weightedBarcode')"
            :checked="isWeightedBarcode"
            @change="changeBarcodeType")
              
          showValidateError(
            v-show="isWeightedBarcode"
            :errorName="$t('Stocks.warning_weightedBarcode')"
          )

          Button.m-top-15(
            primary 
            size="small"
            id="price-quote-barcode-generation"
            :disabled="!selectedList.length"
            @click="generateBarcode"
          )
            span {{ $t('Stocks.toolbar_SubmitBarcode_ButtonText') }}
    Button.change-activation-status.m-right-10(
      size="small"
      secondary
      v-click-outside="closeActivationStatusPopup"
      id="change-multiple-price-type-button",
      :disabled="!selectedList.length"
      @click="changeActivationStatus"
    )
      span {{ $t('PriceQuota.toolbar_updateActivationStatus') }}

      .price-type-selection-popup(:class="showActivationStatusSelectionPopup ? 'show-price-type-popup' : null")
        h2.price-type-selection-popup-header {{ $t('PriceQuota.selectActivationStatus') }}
        .radio-button-contents.m-top-10

          CustomRadio.change-price-type.m-top-5(
            :id="`radio-price-quote-make-active`"
            :label="$t('PriceQuota.makeActive')"
            :checked="activationStatus"
            @change="changeActivationType(true)")

          CustomRadio.change-price-type.m-top-5(
            :id="`radio-price-quote-make-passive`"
            :label="$t('PriceQuota.makePassive')"
            :checked="!activationStatus"
            @change="changeActivationType(false)")

          Button.m-top-10(
            primary 
            size="small"
            id="change-price-type-activation-status",
            @click="updatePriceQuoteActivationStatus"
            :disabled="!selectedList.length"
          )
            span {{ $t('PriceQuota.toolbar_FilterSupplier_ButtonText') }}

    Button.price-type-selection(
      secondary
      size="small"
      v-click-outside="closePriceTypeSelectionPopup"
      id="change-multiple-price-type-button",
      :disabled="!selectedList.length"
      :isLoading="isPriceTypeUpdating"
      @click="openPriceTypeSelection"
    )
      span {{ $t('PriceQuota.toolbar_updatePriceType') }}

      .price-type-selection-popup(:class="showPriceTypeSelectionPopup ? 'show-price-type-popup' : null")
        h2.price-type-selection-popup-header {{ $t('PriceQuota.selectPriceType') }}
        .radio-button-contents.m-top-10

          CustomRadio.change-price-type.m-top-5(
            :id="`radio-price-quote-price-type-1`"
            :label="$t('PriceQuota.PriceType_Dynamic')"
            :checked="priceType === 1"
            @change="changePriceType(1)")

          CustomRadio.change-price-type.m-top-5(
            :id="`radio-price-quote-price-type-2`"
            :label="$t('PriceQuota.PriceType_Fixed')"
            :checked="priceType === 2"
            @change="changePriceType(2)")

          CustomRadio.change-price-type.m-top-5(
            :id="`radio-price-quote-price-type-3`"
            :label="$t('PriceQuota.PriceType_FixedOffer')"
            :checked="priceType === 3"
            @change="changePriceType(3)")

          Button.m-top-10(
            primary
            size="medium"
            id="change-multiple-price-type",
            :isLoading="isPriceTypeUpdating"
            @click="updatePriceTypes"
            :disabled="!selectedList.length"
          )
            span {{ $t('PriceQuota.toolbar_updatePriceType') }}
  template(slot="content-center")
    TableLoading(v-if="isLoading || isPageLoading")

    TableView( 
      v-if="!isLoading && !isEmpty && !isPageLoading"
      uniqueIdentifier="id"
      :fields="fields"
      :data="decoratedPriceQuoteList"
      :selectable="'checkbox'"
      :selectableAll="'checkbox'"
      :showCheckboxForPassives="true"
      :page="page"
      :actions="['edit']"
      :addScrollToNoFixedColumns="true"
      :selectedRows="selectedList"
      @showAllSelectedItems="selectAll"
      @delegateOnItemSelect="onSelect"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemEdit="editPriceQuote"
    )

    portal(to="isPreferred")
      div(slot-scope="row")
        Button.preferred-status(
          size="small"
          v-if="row.isActive"
          :iconName="row.isPreferred ? 'icon-vote-star-filled' : 'icon-vote-star-empty'"
          :clearVariant="true"
          @click="updatePriceQuotePreferrenceState(row)"
        )

    portal(to="priceType")
      div(slot-scope="row")
        Button.price-type(:id="`price-quote-price-type-text`" :class="getPriceTypeClass(row.priceType)") {{ getPriceType(row.priceType) }}

    EmptyState(
    v-if="!isLoading && isEmpty"
    :title="$t('PriceQuota.emptyState_Title')"
    :description="$t('PriceQuota.emptyState_Description')"
    :buttonText="$t('PriceQuota.emptyState_ButtonText')"
    @emptyAddButton="createNewPriceQuote")

    router-view(@getList="getPriceQuoteListWithParams")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TableHead from '@/view/global/table/head.vue'
import TableFoot from '@/view/global/table/foot.vue'
import getFields from './fields'
import SupplierFilter from '@/view/global/business-filters/supplier.vue'
import ImportExcel from '@/view/global/import-excel'
import DownloadExcel from '@/view/global/pure-download-button'
import downloadCSVMixin from '@/mixins/download-actions'
import matchSearch from '@/utils/matchingSearch'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'
import ImportExport from '@/view/global/import-export'
import popupFilter from '@/view/global/popup-filter'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'PriceQuote',
  mixins: [downloadCSVMixin],
  components: {
    TableHead,
    TableFoot,
    SupplierFilter,
    DownloadExcel,
    ImportExcel,
    businessMultiselect,
    ImportExport,
    popupFilter
  },

  async mounted () {
    this.fetchSupplierWhoHadPriceQuote()
      .then(res => {
        this.supplierListForDownload = res.data.supplierList
      })
    this.$wait.start('pageLoading')
    await this.getPageData()
    this.$wait.end('pageLoading')
  },

  data () {
    return {
      fields: getFields(this.$t),
      selectedList: [],
      showPriceTypeSelectionPopup: false,
      showActivationStatusSelectionPopup: false,
      item: {
        supplier: null
      },
      selectOptions: [],
      supplierListForDownload: [],
      excelPopup: false,
      filters: {
        supplier: [],
        itemGroup: [],
        stockItem: [],
        onlyGlobalSuppliers: false
      },
      search: '',
      priceType: 1,
      activationStatus: true,
      showPassive: false,
      isPreferred: false,
      showBarcodeOperationsPopup: false,
      showOtherOptions: false,
      isWeightedBarcode: false,
      stockItemList: [],
      selectedStockItems: [],
      showStockFilter: false,
      showWithBaseCurrency: false
    }
  },

  computed: {
    ...mapGetters('PriceQuote', [
      'priceQuoteList',
      'isEmpty',
      'filterData',
      'page'
    ]),
    ...mapGetters('Settings', [
      'RoleArea'
    ]),

    selectableHeaders () {
      return this.decoratedPriceQuoteList.filter(i => i)
    },

    staticFields () {
      return [
        {
          name: 'stockItemName',
          is_visible: true,
          short_title: this.$t('PriceQuota.tableColumn_StockItemName_Short'),
          title: this.$t('PriceQuota.tableColumn_StockItemName_Short')
        }
      ]
    },

    excelFileName () {
      return this.$t('PriceQuota.excel_file_name_supplier') + '_' + (this.item.supplier ? this.item.supplier.name : '')
    },

    userIsGlobal () {
      return this.RoleArea === 'global'
    },
    isPageLoading () {
      return this.$wait.is('pageLoading')
    },
    decoratedPriceQuoteList () {
      // TODO: Move this logic to PriceQuote model.
      return this.priceQuoteList.map(quote => ({
        ...quote,
        validityDateRange:
          quote.isValidityDateActive
            ? this.getFormatValidityDateRange(quote.startDate, quote.endDate)
            : '–'
      }))
    },

    isLoading () {
      return this.$wait.is(['getPriceQuoteList']) || this.$wait.is(['getFilterData'])
    },

    isPriceTypeUpdating () {
      return this.$wait.is(['updatePriceQuoteList'])
    }
  },

  methods: {
    ...mapActions('PriceQuote', [
      'getPriceQuoteList',
      'getFilterData',
      'fetchSupplierWhoHadPriceQuote',
      'updatePriceQuoteList',
      'updatePreferrenceState',
      'updatePriceQuoteListActivationStatus',
      'generateBarcodeList'
    ]),
    ...mapActions('Settings', [
      'setRoleArea'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    

    getPriceType (priceType) {
      if (priceType === 1) return this.$t('PriceQuota.PriceType_Dynamic')
      else if (priceType === 2) return this.$t('PriceQuota.PriceType_Fixed')
      else return this.$t('PriceQuota.PriceType_FixedOffer')
    },

    closeBarcodeOperationsPopup () {
      this.showBarcodeOperationsPopup = false
    },

    toggleBarcodeOperations () {
      this.showBarcodeOperationsPopup = !this.showBarcodeOperationsPopup
    },

    toggleOtherOptions () {
      this.showOtherOptions = !this.showOtherOptions
    },

    closeImportExportPopup () {
      this.showOtherOptions = false
      this.showBarcodeOperationsPopup = false
      this.showStockFilter = false
      this.showActivationStatusSelectionPopup = false
    },

    closeOtherOptions () {
      this.showOtherOptions = false
    },

    toggleStockFilterPopup () {
      this.showStockFilter = !this.showStockFilter
    },

    closeStockFilterPopup () {
      this.showStockFilter = false
    },

    changeBarcodeType () {
      this.isWeightedBarcode = !this.isWeightedBarcode
    },

    generateBarcode () {
      const payload = {
        barcodeType: this.isWeightedBarcode ? 1 : 2,
        priceQuoteIds: this.selectedList.map(i => i.id)
      }
      this.generateBarcodeList(payload).then(res => {
        if (res) {
          const message = this.$t('PriceQuota.notification_BarcodesGenerated')
          this.notifyShow({ message })
          this.selectedList = []
          this.isWeightedBarcode = false
          this.getPriceQuoteListWithParams(this.page.number)
        }
      })
    },

    changePopupFilterSearch (search) {
      if (search.length > 1) {
        this.searchStockItem({Text: search, types: [1, 2, 3]})
          .then(res => {
            this.stockItemList = res.data.items
          })
      } else {
        this.stockItemList = []
      }
    },

    toggleAll (isSelectedAll) {
      if (isSelectedAll) {
        this.selectedStockItems = []
      } else {
        this.selectedStockItems.forEach(item => this.onChangePopupFilterCheckbox({item, isChecked: true}))
      }
    },

    onChangePopupFilterCheckbox ({ item, isChecked }) {
      if (isChecked) {
        this.selectedStockItems.push(item)
      } else {
        this.selectedStockItems.splice(this.selectedStockItems.findIndex(i => i.id === item.id), 1)
      }
    },

    clearStockFilter () {
      this.stockItemList = []
      this.selectedStockItems = []
      this.getPageData()
    },

    submitFilteredStockItem: vueWaitLoader(async function () {
      this.showStockFilter = false
      await this.getPageData()
    }, 'submitFilterPopupFilter'),

    onSelect (item) {
      const isChecked = !this.selectedList.some(i => i.id === item.id)
      if (isChecked) this.selectedList.push(item)
      else this.selectedList.splice(this.selectedList.findIndex(i => i.id === item.id), 1)
    },
    selectAll (isChecked) {
      if (isChecked) {
        this.selectedList = [...this.selectableHeaders]
      } else this.selectedList = []
    },

    changePriceType (priceType) {
      this.priceType = priceType
    },

    changeActivationType (value) {
      this.activationStatus = value
    },

    getPriceTypeClass (priceType) {
      if (priceType === 1) return 'price-type-dynamic'
      else if (priceType === 2) return 'price-type-fixed'
      else return 'price-type-fixed-offer'
    },
    goImportedPriceQuotes (result) {
      const importedPriceQuotes = result.data.priceQuoteList

      this.$router.push({
        name: 'importedPriceQuotes',
        params: { importedPriceQuotes }
      })
    },

    focusFirstInput () {
      this.$nextTick(() => this.$refs.firstInput.$refs.search.focus())
    },

    updatePriceTypes () {
      const selectedPriceQuoteIds = this.selectedList.map(i => i.id)
      const payload = {
        priceQuoteIds: selectedPriceQuoteIds,
        priceType: this.priceType
      }
      this.updatePriceQuoteList(payload).then(res => {
        if (res) {
          const message = this.$t('PriceQuota.priceTypeSuccessMessage')
          this.notifyShow({ message })
          this.getPriceQuoteListWithParams()
          this.closePriceTypeSelectionPopup()
          this.selectedList = []
        }
      })
    },

    updatePriceQuotePreferrenceState (item) {
      this.isPreferred = !item.isPreferred
      const payload = {
        priceQuoteId: item.id,
        isPreferred: this.isPreferred
      }
      this.updatePreferrenceState(payload)
      this.getPriceQuoteListWithParams()
    },

    updatePriceQuoteActivationStatus () {
      const selectedPriceQuoteIds = this.selectedList.map(i => i.id)
      const payload = {
        priceQuoteIds: selectedPriceQuoteIds,
        isActive: this.activationStatus
      }
      this.updatePriceQuoteListActivationStatus(payload).then(res => {
        if (res) {
          let message
          this.activationStatus ? message = this.$t('PriceQuota.success_active') : message = this.$t('PriceQuota.success_passive')
          this.notifyShow({ message })
          this.getPriceQuoteListWithParams()
          this.closeActivationStatusPopup()
          this.selectedList = []
          this.activationStatus = true
        }
      })
    },

    openExcelPopup () {
      this.excelPopup = true
      this.selectOptions = []
      this.item.supplier = null
      this.focusFirstInput()
    },

    openPriceTypeSelection () {
      this.showPriceTypeSelectionPopup = !this.showPriceTypeSelectionPopup
    },

    changeActivationStatus () {
      this.showActivationStatusSelectionPopup = !this.showActivationStatusSelectionPopup
    },

    closeExcelPopup () {
      this.excelPopup = false
    },
    closePriceTypeSelectionPopup () {
      this.showPriceTypeSelectionPopup = false
    },
    closeActivationStatusPopup () {
      this.showActivationStatusSelectionPopup = false
    },
    getOptions (search) {
      this.selectOptions = this.supplierListForDownload.filter(item => matchSearch(item.name, search))
    },

    async getPageData () {
      await this.setRoleArea()
      await this.getFilterData()
      this.filters = await {
        supplier: [...this.filterData.supplierList],
        itemGroup: [...this.filterData.itemGroupList],
        stockItem: [...this.selectedStockItems]
      }
      this.getPriceQuoteListWithParams()
    },
    getStoreGroupsCode (row) {
      if (row.storeGroups.length === 0) return ''
      return row.storeGroups.map(item => !item.code ? item.name : item.code).join(', ')
    },

    changeOnlyGlobalSuppliers () {
      this.filters.onlyGlobalSuppliers = !this.filters.onlyGlobalSuppliers
      this.getPriceQuoteListWithParams()
    },

    showPassivePriceQuotes () {
      this.showPassive = !this.showPassive
      this.getPriceQuoteListWithParams()
    },

    showBaseCurrency () {
      this.showWithBaseCurrency = !this.showWithBaseCurrency
      this.getPriceQuoteListWithParams()
    },

    getFormatValidityDateRange (start, end) {
      const startDate = new Date(start).toLocaleDateString()
      const endDate = new Date(end).toLocaleDateString()
      return `${startDate} - ${endDate}`
    },

    createNewPriceQuote () {
      this.$router.push({
        name: 'NewPriceQuote',
        params: {
          SupplierIds: this.filters.supplier.map(s => s.id),
          ItemGroupIds: this.filters.itemGroup.map(i => i.id),
          StockItemIds: this.filters.stockItem.map(s => s.id),
          to: 1
        }
      })
    },

    editPriceQuote ({ id }) {
      this.$router.push({
        name: 'EditPriceQuote',
        params: {
          id,
          defaults: {
            SupplierIds: this.filters.supplier.map(s => s.id),
            ItemGroupIds: this.filters.itemGroup.map(i => i.id),
            StockItemIds: this.filters.stockItem.map(s => s.id),
            to: 1
          }
        }
      })
    },

    getListWithSupplierFilter ({list, dontSubmit}) {
      this.filters.supplier = list
      this.submitFilter(dontSubmit)
    },

    changePage (page) {
      this.getPriceQuoteListWithParams({ to: page })
    },

    changePageSize (pageSize) {
      this.getPriceQuoteListWithParams({ to: 1, pageSize })
    },

    getPriceQuoteListWithParams (params, pageSize = this.page.size, pageNumber = 1) {
      const defaults = {
        SupplierIds: this.filters.supplier.map(s => s.id),
        ItemGroupIds: this.filters.itemGroup.map(i => i.id),
        StockItemIds: this.filters.stockItem.map(s => s.id),
        Text: this.search ? this.search : null,
        onlyGlobalSuppliers: this.filters.onlyGlobalSuppliers,
        showPassives: this.showPassive,
        showWithBaseCurrency: this.showWithBaseCurrency,
        to: pageNumber,
        pageSize
      }

      this.getPriceQuoteList({
        ...defaults,
        ...params
      })
    },

    submitFilter (dontSubmit) {
      if (!dontSubmit) {
        this.getPriceQuoteListWithParams()
      }
    },

    submitFilteredGroup ({list, dontSubmit}) {
      this.filters.itemGroup = list
      this.submitFilter(dontSubmit)
    },

    clearSearch () {
      this.search = ''
    },

    clearFilter () {
      this.$refs.supplierFilter.clearSupplierFilter()
      this.$refs.businessQuoteGroupFilter.clearAllFilter(false)
      this.$refs.stockItemPopupFilter.search = null
      this.clearStockFilter()
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) {
        return
      }
      this.getPriceQuoteListWithParams()
    },
    async getPQList () {
      await this.getPriceQuoteListWithParams()
    },

    downloadExcel () {
      this.closeExcelPopup()
      const url = 'PriceQuote/export'
      const refName = 'wrapper'
      const params = {
        SupplierId: this.item.supplier.id
      }
      this.downloadExcelAction({url, refName, params, method: 'POST'})
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

  .toolbar {
    .right {
      display: flex;

      div {
        margin-left: 5px;
      }
    }
  }
  .show-global-suppliers {
    height: 33px;
    display: flex;
    align-items: center;
  }
  .price-type-selection {
    position: relative;
     &-popup {
      @include MiniPopupMixin(
        $width: 252px,
        $margin: 40px 0 0 0,
        $triangle-direction: 'right',
        $padding-hor: 15px,
        $padding-ver: 15px,
        $display: none
      );

      &.show-price-type-popup {
        display: flex;
        flex-direction: column;
        width: 100px;
      }

      &-header {
        color: $color-dark;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
      }
    }

  }
  .download-excel {
    position: relative;
    &-popup {
      z-index: $z-index-xxxl !important;
      @include MiniPopupMixin(
        $width: 252px,
        $margin: 30px 0 0 0,
        $padding-hor: 15px,
        $padding-ver: 15px,
        $display: none   
      );

      &.show-excel-popup {
        display: flex;
        flex-direction: column;
      }

      &-header {
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
      }

      &-search {
        margin-top: 12px;
        margin-bottom: 25px;

      }
    }
  }
  .price-type{
    width: 100%;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    padding: 5px 5px;
    border-radius: 4px;
    height: auto;
    &-dynamic {
      background: rgba(($color-success), 0.2);
      color: $color-success;
    }
    &-fixed-offer {
      background: rgba(($color-primary), 0.2);
      color: $color-primary;
    }
    &-fixed {
      background: rgba(($color-light), 0.2);
      color: $color-light;
    }
  }

  .Tableee-scroll{
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .radio-button-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  :deep()  .Tableee-head .Tableee-col {
    position: sticky !important;
    top: 0 !important;
  }

  :deep() .Tableee-head .Tableee-col.fixed {
    &:first-child{
      z-index: $z-index-sm;
    }
    &:nth-child(2) {
      left: 50px;
      z-index: $z-index-sm;
    }
    &:nth-child(3) {
      left: 120px;
      z-index: $z-index-sm;
    }
  }

  :deep() .Tableee-body .Tableee-col.selectable-col{
    z-index: $z-index-xs ;
  }

  :deep() .Tableee-body .Tableee-row td.fixed {
    &:nth-child(2) {
      left: 50px;
      z-index: $z-index-xs;
      text-align: center;
    }

    &:nth-child(3) {
      left: 120px;
      z-index: $z-index-xs;
    }
  }

  :deep() .icon-vote-star-empty {
    margin-right: 0 !important;
    width: 20px !important;
    color: $color-light;
    &:hover {
      color: $color-success !important;
    }
  }

  :deep() .icon-vote-star-filled {
    margin-right: 0 !important;
    width: 20px !important;
    color: $color-success !important;
    &:hover {
      color: $color-light !important;
    }
  }

  :deep() .multiselect-popup {
    z-index: $z-index-lg !important;
  }

  .barcode-selection {
    position: relative;
      &-popup {
      @include MiniPopupMixin(
        $width: 252px,
        $margin: 40px 0 0 0,
        $triangle-direction: 'right',
        $padding-hor: 15px,
        $padding-ver: 15px,
        $display: none
      );

      &.show-barcode-operation-popup {
        display: flex;
        flex-direction: column;
        width: 100px;
      }

      &-header {
        color: $color-dark;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
      }
      &-contents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .show-more {
    position: relative;
    .dropdown-handle {
      display: flex;
      align-items: center;
      font-size: $font-size-micro;
      user-select: none;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;

      .disabled-button {
        pointer-events: none;
        opacity: 0.6;
      }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      position: relative;
      padding-right: 25px;
      height: 35px;
      color: inherit;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;

      &.select {
        display:flex;
        align-items: center;
      }
      &:hover {
        box-shadow: 0 0 0 1px $color-success-dark;
        transition: 0.5s ease-in-out;
      }

      &-icon.icon.icon-down-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 10px;
        transition: all 0.5s;
        color: $color-success;
        &.open {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &-values {
        line-height: 35px;
        font-weight: $font-weight-bold;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }
    }
  }
    .MiniPopup {
      z-index: $z-index-lg;
      width: max-content;
      top: 40px;
      border: 1px solid $color-gray;

      .label {
        justify-content: start;
        padding: 10px;
        &:hover{
          background-color: $color-gray;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }
  
   .custom-select {
    position: relative;
  }

  .dropdown-handle {
    display: flex;
    align-items: center;
    font-size: $font-size-micro;
    user-select: none;
    border: 1px solid $color-gray;
    border-radius: $border-high-radius;

    .disabled-button {
      pointer-events: none;
      opacity: 0.6;
    }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      color: $color-success;
      position: relative;
      padding-right: 25px;
      height: 35px;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;

      &.select {
        display:flex;
        align-items: center;
      }
      &:hover {
        box-shadow: 0 0 0 1px $color-success-dark;
        transition: 0.5s ease-in-out;
      }

      &-icon.icon.icon-down-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 10px;
        transition: all 0.5s;
        &.open {
          transform: translateY(-50%) rotate(180deg);
        }

      }

      &-values {
        line-height: 35px;
        font-weight: $font-weight-bold;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

    }
  }
.Filter-popup::before {
  left: 10px !important;
}
</style>
