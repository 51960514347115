var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.suspendedSalesHeaderList.length === 0 && !_vm.isLoading
        ? _c("EmptyState", {
            attrs: {
              title: _vm.$t("MenuItems.SuspendSalesNoyFoundTitle"),
              description: _vm.$t("MenuItems.SuspendSalesNoyFoundDescription"),
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "ss" },
        _vm._l(_vm.suspendedSalesHeaderList, function (row) {
          return _vm.suspendedSalesHeaderList.length !== 0 && !_vm.isLoading
            ? _c("div", { staticClass: "ss-box" }, [
                _c("div", {
                  staticClass: "ss-box-clicker",
                  on: {
                    click: function ($event) {
                      return _vm.goToDetail(row.errorType)
                    },
                  },
                }),
                _c("div", { staticClass: "ss-content" }, [
                  _c("div", { staticClass: "ss-left" }, [
                    _c(
                      "div",
                      { staticClass: "ss-checkbox" },
                      [
                        _c("CustomCheckbox", {
                          staticClass: "checkbox",
                          attrs: {
                            checked: _vm.selectedList.some(
                              (item) => item.errorType === row.errorType
                            ),
                            label: "",
                          },
                          on: { change: (ic) => _vm.toggleCheckbox(ic, row) },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "ss-container" }, [
                      _c("div", { staticClass: "ss-head" }, [
                        _c(
                          "p",
                          { staticClass: "font-size-small c-light m-bottom-5" },
                          [_vm._v(_vm._s(_vm.$t("MenuItems.suspendErrorType")))]
                        ),
                        _c("p", { staticClass: "font-weight-600" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                `MenuItems.suspendErrorType${row.errorType}`
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "ss-body" }, [
                        _c(
                          "p",
                          { staticClass: "font-size-small c-light m-bottom-" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("MenuItems.suspendedSaleTableAmount")
                              )
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "c-warning ff-mono" }, [
                          _vm._v(
                            _vm._s(_vm._f("formatCurrency")(row.totalAmount))
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ss-right" },
                    [_c("Icon", { attrs: { name: "icon-arrow-down" } })],
                    1
                  ),
                ]),
              ])
            : _vm._e()
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }