export default function getFields($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('Stocks.tableColumn_StockItemName_Short')
      },
      get title() {
        return $t('Stocks.tableColumn_StockItemName')
      },
      is_sortable: true,
      sortingObject: {
        columnName: 'Name',
        sortType: null
      },
      filter: {
        showFilter: false,
        position: {},
        list: [],
        selectedList: [],
        selectedListName: 'selectedStockItems',
        is_visible: true,
        type: null,
        headerLabel: $t('OnHand.Filter_Stock_Item_Label'),
        backendSearch: 'stockItem'
      }
    },
    {
      name: 'itemGroupName',
      is_visible: true,
      get short_title() {
        return $t('Stocks.tableColumn_ItemGroupName_Short')
      },
      get title() {
        return $t('Stocks.tableColumn_ItemGroupName')
      },
      is_sortable: true,
      sortingObject: {
        columnName: 'ItemGroupName',
        sortType: null
      },
      filter: {
        showFilter: false,
        position: {},
        list: [],
        selectedList: [],
        selectedListName: 'selectedItemGroups',
        is_visible: true,
        type: null,
        headerLabel: $t('OnHand.Filter_Alt_Groups_Label')
      }
    },
    {
      name: 'itemNumber',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('Stocks.tableColumn_ItemNumber_Short')
      },
      get title() {
        return $t('Stocks.tableColumn_ItemNumber')
      },
      is_sortable: true,
      sortingObject: {
        columnName: 'ItemNumber',
        sortType: null
      }
    },
    {
      name: 'baseUnitName',
      is_visible: true,
      get short_title() {
        return $t('Stocks.tableColumn_BaseUnitName_Short')
      },
      get title() {
        return $t('Stocks.tableColumn_BaseUnitName')
      }
    },
    {
      name: 'taxName',
      is_visible: true,
      get short_title() {
        return $t('Stocks.tableColumn_TaxName_Short')
      },
      get title() {
        return $t('Stocks.tableColumn_TaxName')
      }
    },
    {
      name: 'purchaseStockItemNames',
      is_visible: true,
      show_mini_popup: 'showPurchaseStockItemPopup',
      mini_popup: {
        text: 'purchaseStockItemNamesForPopup',
        color: 'primary',
        direction: 'right'
      },
      get short_title() {
        return $t('Stocks.tableColumn_Purchase_Stock_Names')
      },
      get title() {
        return $t('Stocks.tableColumn_Purchase_Stock_Names')
      }
    },
    {
      name: 'approvalLevel',
      is_visible: true,
      get short_title() {
        return $t('Global.approvalLevel')
      },
      get title() {
        return $t('Global.approvalLevel')
      }
    },
    {
      name: 'lastPurchasePrice',
      is_visible: true,
      get short_title() {
        return $t('Stocks.tableColumn_lastPurchasePrice')
      },
      get title() {
        return $t('Stocks.tableColumn_lastPurchasePrice')
      }
    },
    {
      name: 'lastPurchaseDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Global.lastPurchaseDate')
      },
      get title() {
        return $t('Global.lastPurchaseDate')
      }
    },
  ]
}
