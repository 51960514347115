var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "Settings",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingData
            ? _c("TableLoading")
            : _c(
                "div",
                [
                  _c("TableView", {
                    staticClass: "m-top-15",
                    attrs: {
                      componentName: "exportSettings",
                      fields: _vm.documentSettingFields,
                      data: _vm.DocumentSettingList,
                      readOnly: true,
                    },
                  }),
                  _c("portal", {
                    attrs: { to: "module" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c("div", {}, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "DocumentSettings.TableField_" + row.key
                                  )
                                )
                              ),
                            ]),
                          ])
                        },
                      },
                    ]),
                  }),
                  _c("portal", {
                    attrs: { to: "showDrafts" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            { staticClass: "on-button" },
                            [
                              _c("CustomRadio", {
                                attrs: {
                                  checked: row.isChecked,
                                  disabled: _vm.isSettingData,
                                  label: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeDocumentSettings(row, true)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("portal", {
                    attrs: { to: "dontShowDrafts" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            { staticClass: "off-button" },
                            [
                              _c("CustomRadio", {
                                attrs: {
                                  checked: !row.isChecked,
                                  disabled: _vm.isSettingData,
                                  label: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeDocumentSettings(
                                      row,
                                      false
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }