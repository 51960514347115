<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    .form-item-select.date-start
      CustomDatepicker#purchaseReportDatepicker(
        v-model="dates",
        :range="true"
        :isLoading="$wait.is('getDateFilterSettings')"
        :disabled="isPurchaseReportsLoading || isPageLoading"
      )

  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="groupTypes",
      :selectedItem="activeGroupType",
      :isNotActiveDisabled="false",
      @delegateChangeSelectedItem="onChangeGroupType",
      fieldKey="name"
    )
  template(slot="toolbar-left")
    CustomMultiselect.toolbar-filters__item(
    id="purchase-reports-store-filter"
    :items="storeList"
    :selected="storeFilter"
    :label="$t('PurchaseReports.toolbar_Stores_Label')"
    :unselectedText="$t('PurchaseReports.toolbar_Stores_Unselected')"
    :popupHeader="$t('PurchaseReports.toolbar_Stores_PopupHeader')"
    :allLabel="$t('PurchaseReports.toolbar_Stores_AllLabel')"
    :buttonText="$t('PurchaseReports.toolbar_Stores_ButtonText')"
    buttonIconLeft="btn-success"
    @delegateCheckAll="onChangeStoreFilter"
    @delegateCheckItem="onChangeStoreFilter"
    @delegateSubmitItems="getPurchaseReportsWithParams")

    .toolbar__separator

    Button.supplier-switch(
      variant="icon"
      id="btn-purchase-reports-supplier-switch"
      type="button"
      @click="onSwitchIncludeSupplier"
    )
      svg.icon(:class="includeSupplier ? 'eye-open' : 'eye-closed'")
        use(:xlink:href="includeSupplier ? '#icon-login-eye' : '#icon-login-eye-closed'")
      | {{ $t('PurchaseReports.toolbar_Suppliers') }}

    .toolbar__separator(v-if="activeGroupType && includeSupplier")

    CustomCheckbox.supplier-primary-checkbox(
    v-if="activeGroupType && includeSupplier"
    size="large"
    id="checkbox-purchase-reports-supplier-primary"
    :label="$t('PurchaseReports.toolbar_SupplierPrimary')"
    v-model="isSupplierPrimary"
    @change="onChangeSupplierPrimary")  
  template(slot="toolbar-right")
    ImportExport(
      componentName="reports-purchase"
      ref="wrapper"
      :isDisabled="isPageLoading"
      :activeImportExports=['export']
      downloadUrl="Report/purchase/export"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="downloadFileName"
    )
  template(slot="content-center")
    .results
      TableLoading(v-if="isPurchaseReportsLoading || isPageLoading")

      TableView(
      v-if="!isPurchaseReportsLoading && !isPurchaseReportsEmpty && !isPageLoading"
      :fields="purchaseFields"
      :readOnly="true"
      :componentName="'ReportsPurchaseTable'"
      :data="purchaseReports")

      EmptyState(
      v-if="!isPurchaseReportsLoading && isPurchaseReportsEmpty"
      :title="$t('PurchaseReports.ABCAnalysis_EmptyState_Title')"
      :description="$t('PurchaseReports.ABCAnalysis_EmptyState_Description')")
</template>

<script>
import moment from 'moment'
import getPurchaseFields from './fields/purchase'
import { mapActions, mapGetters } from 'vuex'
//
import ImportExport from '@/view/global/import-export'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import getDateFilterSetting from '@/utils/getDateFilterSetting'

export default {
  name: 'PurchaseReports',

  components: {
    ImportExport,
    ToggleOverGroups
  },

  data () {
    return {
      pageHeaderLinks: [
        'PurchaseReports',
        'ABCAnalysis'
      ],
      // dateStart: moment().startOf('month').hour(0).minute(0)._d,
      // dateEnd: moment().hour(23).minute(59)._d,
      dates: [],
      groupTypes: [{
        name: this.$t('PurchaseReports.groupTypes_OverGroup'),
        id: 1
      }, {
        name: this.$t('PurchaseReports.groupTypes_MajorGroup'),
        id: 2
      }, {
        name: this.$t('PurchaseReports.groupTypes_ItemGroup'),
        id: 3
      }],
      activeGroupType: null,
      storeFilter: [],
      includeSupplier: false,
      isSupplierPrimary: false
    }
  },

  async mounted () {
    this.$wait.start('pageLoading')
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_DespatchReport')
    this.activeGroupType = this.groupTypes[0]
    await this.getMiniStoreList().then(() => {
      this.storeFilter = [...this.storeList]
      this.getPurchaseReportsWithParams()
    })
    this.$wait.end('pageLoading')
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getPurchaseReportsWithParams()
    }
  },

  computed: {
    ...mapGetters('Reports', [
      'purchaseReports',
      'isPurchaseReportsEmpty'
    ]),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList'
    }),

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    purchaseFields () {
      const columns = {
        groupType: this.activeGroupType ? this.groupTypes.find(groupType => groupType.id === this.activeGroupType.id) : null,
        includeSupplier: this.includeSupplier,
        isSupplierPrimary: this.isSupplierPrimary
      }

      return getPurchaseFields(this.$t, columns)
    },

    isPurchaseReportsLoading () {
      return this.$wait.is(['getPurchaseReports'])
    },

    // dateStartDisable() {
    //   return {
    //     from: moment(this.dateEnd)._d
    //   }
    // },

    // dateEndDisable() {
    //   return {
    //     to: moment(this.dateStart)._d
    //   }
    // },

    downloadParams () {
      const params = {
        StartDate: this.dateFormat(this.dates[0]),
        EndDate: this.dateFormat(this.dates[1]),
        IncludeSupplier: this.includeSupplier,
        IsSupplierPrimary: this.isSupplierPrimary,
        GroupType: this.activeGroupType ? this.activeGroupType.id : null,
        StoreList: this.storeFilter.map(store => store.id)
      }
      return params
    },
    downloadFileName () {
      const fileName = `PurchaseReport_${this.dateFormat(this.dates[0])}_${this.dateFormat(this.dates[1])}`
      return fileName
    }
  },

  methods: {
    ...mapActions('Reports', [
      'getPurchaseReports'
    ]),
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Stores', [
      'getMiniStoreList'
    ]),

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    onChangeGroupType (groupType) {
      if (this.activeGroupType && (this.activeGroupType.id === groupType.id)) {
        this.activeGroupType = null
        if (!this.includeSupplier) {
          this.includeSupplier = true
          this.isSupplierPrimary = false
        }
      } else {
        this.activeGroupType = groupType
      }
      this.getPurchaseReportsWithParams()
    },

    onChangeStoreFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.storeFilter = [...this.storeList]
        } else {
          this.storeFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.storeFilter = []
        } else {
          this.storeFilter.splice(this.storeFilter.indexOf(event.item), 1)
        }
      }
    },

    onSwitchIncludeSupplier () {
      this.includeSupplier = !this.includeSupplier
      if (this.includeSupplier) {
        this.isSupplierPrimary = false
      } else if (!this.activeGroupType) {
        this.activeGroupType = this.groupTypes[0]
      }
      this.getPurchaseReportsWithParams()
    },

    onChangeSupplierPrimary () {
      this.getPurchaseReportsWithParams()
    },

    getPurchaseReportsWithParams () {
      this.getPurchaseReports({
        start: this.dateFormat(this.dates[0]),
        end: this.dateFormat(this.dates[1]),
        groupType: this.activeGroupType ? this.activeGroupType.id : null,
        includeSupplier: this.includeSupplier,
        isSupplierPrimary: this.isSupplierPrimary,
        storeList: this.storeFilter
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .PageHeader {
    .right {
      display: flex;

      .form-item-select {
        width: 200px;
        margin-left: 10px;
      }
    }
  }

  .PageHeaderExtra {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-gray;
  }

  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  .group-types {

    &-item {
      padding: 8px 10px;
      display: inline-block;
      font-weight: $font-weight-bold;
      font-size: $font-size-micro;
      line-height: 1;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;
      user-select: none;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        background: $color-success;
        color: #fff;
        border-color: transparent;
      }
    }
  }

  .supplier-switch {
    width: auto;
    height: 27px;
    font-weight: $font-weight-normal;
    color: $color-light;

    .icon {
      width: 27px;
      height: 27px;
      padding: 7px 4.5px;
      margin-right: 10px;
      background: $color-seashell;
      color: $color-success;
      border: 1px solid;
      border-radius: $border-high-radius;

      &.eye-closed {
        padding: 5px 3px;
        background: transparent;
        color: $color-light;
      }
    }
  }

  .supplier-primary-checkbox {
    font-weight: $font-weight-normal;
    animation: supplier-primary-emerge $transition 1 forwards;
    white-space: nowrap;
    overflow: hidden;
  }

  .results {
    position: relative;
  }

  @keyframes supplier-primary-emerge {
    from {
      max-width: 0;
      opacity: 0;
    }

    to {
      max-width: 200px;
      opacity: 1;
    }
  }
</style>
