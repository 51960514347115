<template lang="pug">
Page
  template(slot="header-breadcrumb-left")
    .Breadcrumb.tooltip-relative
      router-link.c-light(
      id="link-inventory-breadcrumb"
      :to="{ name: 'Inventories' }")
        | {{ $t('Inventory.breadCrumb_Inventory') }}
      span {{ ">" }}
      a(href="" id="link-inventory-breadcrumb-active").isSelected {{ getName(inventoryBreadCrumb) }}
      Tooltip(
        v-if="inventoryBreadCrumb.length > 50"
        white
        bottom
        :text="inventoryBreadCrumb"
      )
    span.inventory-type.c-light {{ InventoryInfo.inventoryType == 1 ? $t('Inventories.inventoryTypePeriodic') : $t('Inventories.inventoryTypeIntermediate') }}
  template(v-if="inventoryHead && inventoryHead.status === 1" slot="header-left-search")
    Search.search-box(
      componentName="Inventory",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch")
  template(slot="header-right-content")
    Button(
    v-if="hasPermissionToCloseInventory"
    size="medium"
    primary
    type="button"
    id="btn-inventory-close"
    @click="close(inventoryHead.id)")
      span {{ $t('Inventory.closeInventoryButton') }}

  template(slot="toolbar-left" )
    router-link.tabLink#toggle-group-0(v-if="!isOnlyListUsage" :to="{name: 'Inventory', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_all') }}
    router-link.tabLink#toggle-group-1(:to="{ name: 'Inventorylists', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_list') }}
    router-link.tabLink#toggle-group-2(v-if="!isOnlyListUsage" :to="{ name: 'InventoryRecipe', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_recipe') }}
    router-link.tabLink.isSelected#toggle-group-3(v-if="!isOnlyListUsage" :to="{ name: 'InventoryShelf', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_shelf') }}
    router-link.tabLink#toggle-group-4(v-if="!isOnlyListUsage && inventory_Header.expirationDateControlIsEnabled" :to="{ name: 'InventoryExpireDate', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_expire_date') }}

    CustomMultiselect.toolbar-filters__item(
    id="multiselect-lists-filter"
    :items="shelfList"
    :showAll="true"
    :selected="selectedShelfList"
    :label="$t('Inventories.toolbar_shelf-list')"
    :unselectedText="$t('Global.toolbar_Filters_Unselected_PlaceHolder')"
    :popupHeader="$t('Inventories.toolbar_Shelf_PopupHeader')"
    :allLabel="$t('Inventories.toolbar_Stores_AllLabel')"
    :buttonText="$t('Inventories.toolbar_Stores_ButtonText')"
    buttonIconLeft="btn-success"
    :useCustomEmptyState="true"
    @delegateCheckAll="onChangeShelfFilter"
    @delegateCheckItem="onChangeShelfFilter"
    @delegateSubmitItems="getLists")

  template(slot="toolbar-right")
    ImportExport(
      v-if="inventoryHead && inventoryHead.status === 1" 
      componentName="InventoryShelfList"
      ref="wrapper"
      :isDisabled="isFetchingLists"
      :activeImportExports=['export']
      :downloadUrl="downloadUrl"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="inventoryBreadCrumb + '_by_shelfStorageList'"
    )
  template(slot="content-center")
    RequireStep(
      v-if="!selectedShelfList.length"
      iconName="icon-require-list-item",
      :title="$t('Inventory.Not_Selected_List_Title')",
      :description="$t('Inventory.Not_Selected_List_Desc')"
    )

    TableLoading(v-if="isFetchingLists")

    NestedTable(
      v-show="!isFetchingLists && !showEmptyState && InventoryShelfList.list.length && selectedShelfList.length"
      :data="InventoryShelfList.list",
      :fields="fields",
      :detailFields="detailFields",
      @showDetailAction="showDetail"
    )

    portal(to="baseUnitCode")
      div(slot-scope="detail").unitCode
        span(v-for="code in detail.row.unitCountList")
          span {{code.unitCode}}

    portal(to="unitQuantity")
      div(slot-scope="detail")
        span(v-for="(item, index) in detail.row.unitCountList")
          customNumberInput(
            name="quantityByList"
            id="quantityByList-value"
            v-validate="'greater_than:-1'"
            :data-vv-as="$t('Inventory.placeholder_quantityByList')"
            v-model="item.unitQuantity"
            :error="veeErrors.has('quantityByList')"
            @blur="sendQuantityCount(item)"
          )
    
    portal(to="baseQuantity")
      div(slot-scope="detail").unitCode
        span(v-for="(item, index) in detail.row.unitCountList")
          span {{ item.totalUnitQuantity }}

    portal(to="totalQuantity")
      div(slot-scope="detail")
        span {{ calculateTotalQuantity(detail.row) }}

    EmptyState(
      v-if="showEmptyState"
      :title="$t('Inventories.shelfList_headerList_emptyState_NotFound_Title')"
      :description="$t('Inventories.shelfList.detailList_emptyState_NotFound_Description_Order')")

  router-view(
    @getList="getLists"
  )

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import ImportExport from '@/view/global/import-export'
import TableFoot from '@/view/global/table/foot.vue'
import RequireStep from '@/view/global/require-step'

export default {
  name: 'InventoryOpenedStatusList',

  components: {
    ImportExport,
    TableFoot,
    RequireStep
  },

  data () {
    return {
      lists: [],
      listFilter: [],
      searchList: [],
      text: '',
      listItemSelected: false,
      inventoryHead: {},
      isOnlyListUsage: false,
      shelfList: [],
      selectedShelfList: [],
      inventoryPageList: [],
      showEmptyState: false
    }
  },
  computed: {
    ...mapGetters('Inventories', [
      'inventoryListPage',
      'inventory_Header',
      'selectedList',
      'selectedListType',
      'InventoryShelfPage',
      'InventoryShelfList'
    ]),
    ...mapGetters('ListsShelfStorageLocation', [
      'ShelfListFromStore'
    ]),
    ...mapGetters('ListsInventory', [
      'InventoryHeaderList'
    ]),
    ...mapGetters('Settings', [
      'permissions',
      'checkPermission'
    ]),

    hasPermissionToCloseInventory () {
      return this.checkPermission('CloseInventory')
    },
    
    isFetchingData () {
      return this.$wait.is('listFetching')
    },
    downloadParams () {
      return {
        inventoryHeaderId: this.InventoryInfo.id,
        shelfStorageLocationListHeaderIds: this.selectedShelfList.map(i => i.id),
        text: this.search || '',
        exportType: 0
      }
    },
    fields () {
      return [
        {
          name: 'shelfStorageLocationListHeaderName',
          is_visible: true,
          short_title: this.$t('Inventories.tableColumn_shelfStorageLocationListHeaderName'),
          title: this.$t('Inventories.tableColumn_shelfStorageLocationListHeaderName')
        },
        {
          name: 'orderNumber',
          is_visible: true,
          short_title: this.$t('Inventories.tableColumn_orderNumber'),
          title: this.$t('Inventories.tableColumn_orderNumber')
        }
      ]
    },

    detailFields () {
      return [
        {
          name: 'stockItemName',
          is_visible: true,
          short_title: this.$t('Inventory.detailList_TableColumn_StockItemName'),
          title: this.$t('Inventory.detailList_TableColumn_StockItemName')
        },
        {
          name: 'baseUnitCode',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Inventory.detailList_TableColumn_Unit'),
          title: this.$t('Inventory.detailList_TableColumn_Unit')
        },
        {
          name: 'unitQuantity',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Inventory.detailList_TableColumn_UnitQuantityInput'),
          title: this.$t('Inventory.detailList_TableColumn_UnitQuantityInput')
        },
        {
          name: 'baseQuantity',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Inventory.detailList_TableColumn_BaseQuantityTotal'),
          title: this.$t('Inventory.detailList_TableColumn_BaseQuantityTotal')
        },
        {
          name: 'totalQuantity',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Inventory.detailList_TableColumn_ActualQuantityTotal'),
          title: this.$t('Inventory.detailList_TableColumn_ActualQuantityTotal')
        }
      ]
    },
    
    isFetchingLists () {
      return this.$wait.is('getInventoryListByShelf')
    },
    inventoryBreadCrumb () {
      return this.inventoryHead.storeName + '-' + moment(this.inventoryHead.inventoryDate).format('DD/MM/YYYY')
    },
    InventoryInfo () {
      return {
        ...this.$route.params,
        status: parseInt(this.$route.params.status)
      }
    },
    downloadUrl () {
      return 'Inventory/inventorycountbyshelftstoragelocationlist/export'
    },
  
    downloadExcelUrl () {
      return 'Inventory/inventorycountbyshelftstoragelocationlist/export'
    },
    isStoreTypeCostCenter () {
      return this.inventory_Header ? this.inventory_Header.storeType === 3 : false
    }
  },
  methods: {
    ...mapActions('Inventories', [
      'getOpenInventoryLists',
      'inventoryShelfUnitCount',
      'close_INVENTORY',
      'update_INVENTORY_UNIT_COUNT',
      'get_inventory_item',
      'getInventoryListByShelf',
      'create_INVENTORY_UNIT_COUNT',
      'updateInventoryShelfUnitCount'
    ]),
    ...mapActions('ListsShelfStorageLocation', ['getAllShelvesFromStore']),
    ...mapActions('ListsInventory', ['getInventoryHeaderLists']),
    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('Inventories', [   
      'SHOW_DETAIL',
      'HIDE_DETAIL'
    ]),

    onChangeShelfFilter(event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.selectedShelfList = this.shelfList
        } else {
          this.selectedShelfList.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.selectedShelfList = []
        } else {
          this.selectedShelfList.splice(this.selectedShelfList.indexOf(event.item), 1)
        }
      }
    },

    calculateTotalQuantity(item) {
      const sad = (item.unitCountList.map(i => i.totalUnitQuantity))
      return sad.reduce((partialSum, a) => partialSum + a, 0);
    },

    async showDetail (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
        return
      }  
      this.SHOW_DETAIL(item)
    },

    async sendQuantityCount(item) {
      if (item.unitQuantity < 0) return
      if (item.inventoryCountId) this.updateInventoryShelfQuantityCount(item)
      else {
        const payload = {
        item, 
        params: {
          shelfStorageLocationListHeaderId: item.shelfStorageLocationListHeaderId,
          unitId: item.unitId,
          stockItemId: item.stockItemId,
          inventoryHeaderId: this.inventoryHead.id,
          actualQuantity: item.unitQuantity ? item.unitQuantity : 0
        }
      }
        await this.inventoryShelfUnitCount(payload).then(res => {
          const message = this.$t('Inventory.success_shelfCount')
          if (res) {
            this.notifyShow({message})
          }
        })
      }
      
    },

    calculateBaseQuantity(item, detail) {
      item.totalUnitQuantity = item.baseQuantity * item.unitQuantity
      detail.totalCount = (item.totalUnitQuantity + detail.totalCount)
      return item.totalUnitQuantity
    },

    async updateInventoryShelfQuantityCount(item) {
      const payload = {
        item,
        params: {
          id: item.inventoryCountId,
          unitQuantity: item.unitQuantity || 0
        }
      }
      await this.updateInventoryShelfUnitCount(payload).then(res => {
        const message = this.$t('Inventory.success_update_shelfCount')
        if (res) {
          this.notifyShow({message})
        }
      })
    },

    onChangeSearch (search) {
      this.text = search
      if (this.text.length === 1) return
      this.getLists()
    },

    async changePage (page) {
      await this.getLists(page)
    },

    async changePageSize (pageSize) {
      await this.getLists(1, pageSize)
    },

    async getShelfList() {
      const payload = {
        pageNumber: 1,
        pageSize: 9999999,
        storeId: this.inventoryHead.storeId,
        name: ''
      }
      await this.getAllShelvesFromStore(payload).then(res => {
        this.shelfList = [...res.data.shelfStorageLocationListHeaderList]
      })
    },

    onChangeListTypes (event) {
      this.listTypeList = [event.item]
      this.UPDATE_LIST_TYPE_FILTER(this.listTypeList)
    },

    async getLists (page, pageSize = this.inventoryListPage.size) {
      const payload = {
        pageSize: pageSize,
        pageNumber: page || 1,
        inventoryHeaderId: this.InventoryInfo.id,
        text: this.text,
        shelfStorageLocationListHeaderIds: this.selectedShelfList.map(i => i.id)
      }
      this.getInventoryListByShelf(payload).then(res => {
        if (res) {
          if (!this.InventoryShelfList) this.showEmptyState = true
        }
      })
    },

    close (id) {
      this.$validator.validateAll().then(result => {
        if (!result) return
        this.$router.push({
          name: 'InventoryListDetailClose',
          params: {
            id: id
          }
        })
      })
    },
    getName (name) {
      return (name && name.length > 50 ) ? name.substr(0, 50).concat('...') : name
    }
  },
  async mounted () {
    this.$wait.start('listFetching')
    this.InventoryShelfList.list = []
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    const res = await this.get_inventory_item(this.InventoryInfo.id)
    if (!res) return
    this.inventoryHead = res.data.inventoryHeader
    await this.getShelfList()
    this.$wait.end('listFetching')
  }
}
</script>

<style lang="scss" scoped>

  .Search{
    position: relative;

    & .icon-search{
      width: 48px;
      color: $color-light;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    & .txt{
      padding-left: 40px;
      padding-right: 40px;
    }
  }

.listLoading {
  top: initial;
}
.Breadcrumb {
  display: flex;
  font-size: $font-size-small;

  span {
    margin-left: 10px;
    margin-right: 10px;
  }

  .isSelected {
    color: $color-primary;
    font-weight: $font-weight-bold;
    pointer-events: none;
  }
}
.inventory-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: $font-size-small;
}
.toolbar {
  .left {
    display: flex;
    .toolbar-filters__item {
      margin-left: 20px;
    }
    .tabLink {
      padding: 11px 27px;
      font-weight: $font-weight-normal;
      margin-right: 10px;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      user-select: none;
      background: $color-white;
      &.isSelected {
        color: $color-primary;
        font-weight: $font-weight-bold;
        border-color: $color-primary;
      }
    }
  }
}

  :deep() .SubMenu{
    .right {
      display: flex;
      align-items: center;
    }
    .left {
      display: flex;
      align-items: flex-start;
    }
  }

  .custom-grid {
    grid-template-columns: 1fr 250px repeat(1, 1fr)
  }
// table
:deep() .NestedDetail-row {
  height: auto !important;
}
:deep() .custom-number {
  width: 60px !important;
  height: 20px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  .txt {
    min-height: 10px !important;
  }
}
.unitCode {
  display: flex;
  flex-direction: column;
  
  span {
    margin-top: 15px;
    &:nth-child(1) {
      margin-top: 5PX !important;
    }
  }
  
}
</style>
