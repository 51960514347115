export default function getFields($t) {
  return [
    {
      name: 'transactionTypeTranslation',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.suspendTransactionType')
      },
      get title() {
        return $t('MenuItems.suspendTransactionType')
      }
    },
    {
      name: 'menuItemName',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.suspendedSaleTableMenuItemsName')
      },
      get title() {
        return $t('MenuItems.suspendedSaleTableMenuItemsName')
      }
    },
    {
      name: 'storeGroupName',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.SuspendStoreGroupName')
      },
      get title() {
        return $t('MenuItems.SuspendStoreGroupName')
      }
    },
    {
      name: 'revenueCenterName',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.suspendedSaleTableRCName')
      },
      get title() {
        return $t('MenuItems.suspendedSaleTableRCName')
      }
    },
    {
      name: 'totalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('MenuItems.suspendedSaleTableAmount')
      },
      get title() {
        return $t('MenuItems.suspendedSaleTableAmount')
      }
    }
  ]
}
