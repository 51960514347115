var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search",
            attrs: {
              componentName: "Despatch",
              isSuggestionLoading: false,
              customSuggestionPlaceHolder: _vm.$t(
                "Despatches.search_Placeholder"
              ),
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select right-date m-right-10" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "DespatchDatepicker",
                isLoading: _vm.$wait.is("getDateFilterSettings"),
                disabled: _vm.isLoading || _vm.$wait.is("pageLoading"),
                range: true,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.despatch_IsEmpty
            ? _c(
                "Button",
                {
                  attrs: {
                    id: "btn-despatch-new",
                    type: "button",
                    primary: "",
                    size: "medium",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.despatchHeaderNew },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Despatches.addDespatchButton"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("SupplierFilter", {
            ref: "despatchSupplierFilter",
            on: { submitFilter: _vm.getListWithSupplierFilter },
          }),
          _vm.tenantMultiCurrencyIsActive ||
          _vm.tenantMustHaveSingleStoreInSameDespatchActive
            ? _c("storeFilter", {
                ref: "despatchStoreFilter",
                on: { submitFilter: _vm.getListWithStoreFilter },
              })
            : _vm._e(),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "show-drafts m-left-15" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-despatch-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          !_vm.despatch_IsEmpty
            ? _c(
                "div",
                { staticClass: "total" },
                [
                  _c("TotalViewList", {
                    attrs: { totalCountArray: _vm.totalCountData },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "small",
                id: "btn-despatch-selected-save",
                type: "button",
                iconName: "icon-btn-success",
                iconClass: "icon-btn-success",
                disabled:
                  _vm.isEmptySelectedDespatches ||
                  _vm.isSelectedOneMoreThanSupplier ||
                  _vm.isInvoiceButtonActive,
              },
              on: { click: _vm.finalizeSelectedDespatches },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.SaveSelectedDespatchesButton"))
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSelectedOneMoreThanSupplier,
                      expression: "isSelectedOneMoreThanSupplier",
                    },
                  ],
                  staticClass: "alert",
                },
                [
                  _vm._v(_vm._s(_vm.$t("Despatches.UmustSelectOneSupplier"))),
                  _c("div", { staticClass: "alert-arrow" }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading || _vm.$wait.is("pageLoading")
            ? _c("TableLoading")
            : _vm._e(),
          _vm.despatch_IsEmpty && !_vm.isLoading && !_vm.$wait.is("pageLoading")
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Despatches.emptyState_Title"),
                  description: _vm.$t("Despatches.emptyState_Description"),
                  buttonText: _vm.$t("Despatches.emptyState_Button"),
                },
                on: { emptyAddButton: _vm.despatchHeaderNew },
              })
            : _vm._e(),
          !_vm.despatch_IsEmpty &&
          !_vm.isLoading &&
          !_vm.$wait.is("pageLoading")
            ? _c("DespatchTable", {
                attrs: {
                  tableData: _vm.despatch_List,
                  showDetailAction: _vm.despatchDetail,
                  showExportDespatch: _vm.showExportDespatch,
                  editAction: _vm.despatchHeaderEdit,
                  customAction: _vm.convertToTransfer,
                  removeItemAction: _vm.despatchRemove,
                  selected: _vm.selectedDespatches,
                  RoleArea: _vm.RoleArea,
                  isSelectedOneMoreThanSupplier:
                    _vm.isSelectedOneMoreThanSupplier,
                },
                on: {
                  delegateCheckItem: _vm.handleCheckbox,
                  delegateCheckAllItem: _vm.toggleAll,
                },
              })
            : _vm._e(),
          _c("router-view", {
            on: {
              takePrint: _vm.showExportDespatch,
              takeInvoicePrint: _vm.showExportInvoice,
              showDrafts: function ($event) {
                _vm.showDrafts = true
              },
            },
          }),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("TableFoot", {
            attrs: { page: _vm.DespatchPage },
            on: {
              delegateOnChangePage: _vm.changePage,
              delegateOnChangePageSize: _vm.changePageSize,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }