var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c("CustomDatepicker", {
            attrs: {
              id: "TransferDatepicker",
              isLoading: _vm.$wait.is("getDateFilterSettings"),
              range: true,
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.dates,
              callback: function ($$v) {
                _vm.dates = $$v
              },
              expression: "dates",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmptyTransferList && !_vm.isLoading,
                  expression: "!isEmptyTransferList && !isLoading",
                },
              ],
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-transfer-new",
                type: "button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.addNewTransfer },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Transfer.addTransferButton")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            staticClass: "toolbar-filters__item",
            attrs: {
              id: "multiselect-sender-store-filter",
              items: _vm.fromStores,
              selected: _vm.selectedFromStores,
              label: _vm.$t("Transfer.senderStore"),
              popupHeader: _vm.$t("Transfer.senderStoreSelector"),
              unselectedText: _vm.$t("Global.toolbar_Filters_AllLabel"),
              allLabel: _vm.$t("Global.toolbar_Filters_AllLabel"),
              buttonText: _vm.$t("Global.toolbar_Filters_ButtonText"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckAll: _vm.fromStoreOnChangeFilter,
              delegateCheckItem: _vm.fromStoreOnChangeFilter,
              delegateSubmitItems: _vm.submitFilter,
            },
          }),
          _c("CustomMultiselect", {
            staticClass: "toolbar-filters__item",
            attrs: {
              id: "multiselect-reciever-store-filter",
              items: _vm.toStores,
              selected: _vm.selectedToStores,
              label: _vm.$t("Transfer.buyerStore"),
              popupHeader: _vm.$t("Transfer.buyerStoreSelector"),
              unselectedText: _vm.$t("Global.toolbar_Filters_AllLabel"),
              allLabel: _vm.$t("Global.toolbar_Filters_AllLabel"),
              buttonText: _vm.$t("Global.toolbar_Filters_ButtonText"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckAll: _vm.toStoreOnChangeFilter,
              delegateCheckItem: _vm.toStoreOnChangeFilter,
              delegateSubmitItems: _vm.submitFilter,
            },
          }),
          _c(
            "div",
            { staticClass: "show-drafts m-left-15" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-transfer-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c(
          "div",
          { staticClass: "TransferPageContent m-top-20" },
          [
            _vm.isLoading ? _c("TableLoading") : _vm._e(),
            _vm.isEmptyTransferList && !_vm.isLoading
              ? _c("EmptyState", {
                  attrs: {
                    title: _vm.$t("Transfer.emptyState_Title"),
                    description: _vm.$t("Transfer.emptyState_Description"),
                    buttonText: _vm.$t("Transfer.addTransferButton"),
                  },
                  on: { emptyAddButton: _vm.addNewTransfer },
                })
              : _vm._e(),
            !_vm.isEmptyTransferList && !_vm.isLoading
              ? _c(
                  "div",
                  [
                    _c("NestedTable", {
                      attrs: {
                        tableKey: "transfer",
                        fields: _vm.transferHeaderFields,
                        detailFields: _vm.transferDetailFields,
                        data: _vm.TransferList,
                        isDetailLoading: _vm.isDetailLoading,
                        actions: ["edit", "delete", "export"],
                        detailActions: ["edit", "delete"],
                        selectable: "checkbox",
                        selectableAll: "checkbox",
                        selectedRows: _vm.selectedList,
                        addButtonText: _vm.$t("Transfer.addNewStock"),
                        finalizeButtonText: _vm.$t("Transfer.transferSave"),
                        usePortalFooter: true,
                        isActionButtonsVisible: true,
                        isLoadingFinalize: _vm.isFinalize,
                      },
                      on: {
                        finalizeAction: _vm.transferSaved,
                        addDetailAction: _vm.transferDetailNew,
                        showDetailAction: _vm.showDetailAction,
                        deleteDetailAction: _vm.transferDetailRemove,
                        detailChangeAction: _vm.updateDetailItem,
                        showExportPopup: _vm.showExportPopup,
                        editHeaderAction: _vm.editAction,
                        deleteHeaderAction: _vm.removeItemAction,
                        selectItem: _vm.onSelectTransfer,
                        selectAllItems: _vm.onSelectTransferAll,
                        editDetailAction: _vm.transferDetailEdit,
                      },
                    }),
                    _c("portal", {
                      attrs: { to: "code" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (header) {
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  { staticClass: "code" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(header.row.code)),
                                    ]),
                                    header.row.despatchNumber
                                      ? _c(
                                          "Button",
                                          {
                                            staticClass: "tooltip-relative",
                                            attrs: {
                                              size: "small",
                                              iconName: "icon-menu-transfer",
                                              iconClass: "icon-menu-transfer",
                                              clearVariant: true,
                                              justIcon: true,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goToDespatchDetailDocument(
                                                  header.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("Tooltip", {
                                              staticClass: "small",
                                              attrs: {
                                                white: "",
                                                right: "",
                                                text: header.row.despatchNumber,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        324093816
                      ),
                    }),
                    _c("portal", {
                      attrs: { to: "info" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (header) {
                              return _c(
                                "div",
                                {},
                                [
                                  header.row.info
                                    ? _c(
                                        "Button",
                                        {
                                          staticClass:
                                            "show-MiniPopup relative width-max-content btn-info",
                                          attrs: {
                                            size: "small",
                                            id: `tranfer-header-info-button-${header.rowIndex}`,
                                            iconName: "icon-global-info",
                                            justIcon: true,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "MiniPopup-right-primary m-top-15 c-dark",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(header.row.info)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        1178848718
                      ),
                    }),
                    _c("portal", {
                      attrs: { to: "maxApprovalLevel" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (header) {
                              return _c(
                                "div",
                                { staticClass: "approval-levels" },
                                [
                                  header.row.approvalStatus > 0
                                    ? _c("ApprovalLevels", {
                                        attrs: {
                                          maxApprovalLevel:
                                            header.row.maxApprovalLevel,
                                          currentApprovalLevel:
                                            header.row.currentApprovalLevel,
                                          approvalStatus:
                                            header.row.approvalStatus,
                                          historyList:
                                            _vm.historyListForTransfer,
                                          isLoading: _vm.isHistoryLoading,
                                        },
                                        on: {
                                          showHistoryPopup: function ($event) {
                                            return _vm.showHistoryPopup(
                                              header.row
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        5521702
                      ),
                    }),
                    _c("portal", {
                      attrs: { to: "approvalStatus" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (header) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getApprovalStatus(
                                        header.row.approvalStatus
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        4057542353
                      ),
                    }),
                    _c("portal", {
                      attrs: { to: "description" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (detail) {
                              return _c(
                                "div",
                                {},
                                [
                                  detail.row.description
                                    ? _c(
                                        "Button",
                                        {
                                          staticClass:
                                            "show-MiniPopup relative width-max-content btn-info",
                                          attrs: {
                                            size: "small",
                                            id: `tranfer-detail-info-button-${detail.rowIndex}`,
                                            iconName: "icon-global-info",
                                            justIcon: true,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "MiniPopup-leftCenter-primary m-top-15 c-dark",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(detail.row.description)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        488671024
                      ),
                    }),
                    _c("portal", {
                      attrs: { to: "rejectionReason" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (header) {
                              return _c(
                                "div",
                                {},
                                [
                                  header.row.rejectionReason
                                    ? _c(
                                        "Button",
                                        {
                                          staticClass:
                                            "show-MiniPopup relative width-max-content btn-info rejection-info",
                                          attrs: {
                                            size: "small",
                                            id: `transfer-approval-header-info-button-${header.rowIndex}`,
                                            iconName: "icon-global-info",
                                            justIcon: true,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "MiniPopup-right-primary m-top-15 c-dark",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    header.row.rejectionReason
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        994554451
                      ),
                    }),
                    _c("portal", {
                      attrs: { to: "footerPortal" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (rowData) {
                              return _c(
                                "div",
                                { staticClass: "popup-container flexbox" },
                                [
                                  _vm.isExpireDateControlEnabled(rowData)
                                    ? _c(
                                        "Button",
                                        {
                                          staticClass: "m-right-20",
                                          attrs: {
                                            id: "btn-transfer-expire-date-edit",
                                            type: "button",
                                            size: "small",
                                            secondary: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editExpireDate(rowData)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Transfer.editExpireDate"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  rowData.status === 1 || rowData.status === 4
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "Button",
                                            {
                                              staticClass: "m-right-20",
                                              attrs: {
                                                id: "nested-detail-add-button",
                                                size: "small",
                                                inline: "",
                                                primary: "",
                                                iconName: "icon-global-add",
                                                iconClass: "icon-global-add",
                                                disabled:
                                                  (rowData.approvalStatus !==
                                                    0 &&
                                                    rowData.approvalStatus !==
                                                      3) ||
                                                  _vm.isOnlyListUsage,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.transferDetailNew(
                                                    rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Transfer.addNewStock"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.Tenant.approvalTransferPermission &&
                                  ((rowData.approvalStatus === 0 &&
                                    rowData.maxApprovalLevel > 0 &&
                                    rowData.detailList.length > 0) ||
                                    rowData.status === 4 ||
                                    (rowData.approvalStatus === 3 &&
                                      rowData.maxApprovalLevel > 0))
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "Button",
                                            {
                                              staticClass: "m-right-20",
                                              attrs: {
                                                id: "transfer-nested-detail-send-to-approve",
                                                inline: "",
                                                size: "small",
                                                primary: "",
                                                iconName: "icon-btn-success",
                                                iconClass: "icon-btn-success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sendToApprove(
                                                    rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "DirectOrders.sendToApprove"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  rowData.status === 3 &&
                                  _vm.checkStorePermission(rowData)
                                    ? _c(
                                        "div",
                                        [
                                          _c("miniPopupButton", {
                                            attrs: {
                                              data: rowData,
                                              buttonText: _vm.$t(
                                                "Transfer.Button_Reject"
                                              ),
                                              descText: _vm.$t(
                                                "Transfer.Reason_Description_Input_Label"
                                              ),
                                              agreeButtonText: _vm.$t(
                                                "Transfer.Detail_Col_Button_Agree"
                                              ),
                                              isDisable: _vm.isFinalize,
                                            },
                                            on: {
                                              onSubmit: _vm.transferReject,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  rowData.status === 3 &&
                                  _vm.checkStorePermission(rowData)
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "Button",
                                            {
                                              attrs: {
                                                id: "nested-detail-approve-transfer-button",
                                                size: "small",
                                                primary: "",
                                                inline: "",
                                                iconName: "icon-btn-success",
                                                iconClass: "icon-left",
                                                disabled: _vm.isFinalize,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.transferSaved(
                                                    rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Transfer.transferApprove"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (
                                    _vm.Tenant.approvalTransferPermission
                                      ? rowData.maxApprovalLevel > 0
                                        ? rowData.approvalStatus === 2 &&
                                          rowData.detailList.length > 0 &&
                                          (rowData.status === 1 ||
                                            rowData.status === 4)
                                        : rowData.detailList.length > 0 &&
                                          rowData.status === 1
                                      : (rowData.detailList.length > 0 &&
                                          rowData.status === 1 &&
                                          rowData.approvalStatus === 0) ||
                                        rowData.status === 4
                                  )
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "Button",
                                            {
                                              attrs: {
                                                id: "nested-detail-finalize-button",
                                                size: "small",
                                                primary: "",
                                                inline: "",
                                                iconName: "icon-btn-success",
                                                iconClass: "icon-left",
                                                disabled: _vm.isFinalize,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.transferSaved(
                                                    rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    rowData.status === 4
                                                      ? _vm.$t(
                                                          "Transfer.repeatTransferApprove"
                                                        )
                                                      : _vm.$t(
                                                          "Transfer.transferSave"
                                                        )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        3640017125
                      ),
                    }),
                    _c("TableFoot", {
                      attrs: { page: _vm.Page },
                      on: {
                        delegateOnChangePage: _vm.changePage,
                        delegateOnChangePageSize: _vm.changePageSize,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "Modal",
                  {
                    attrs: {
                      isOpen: _vm.showBatchDetail,
                      showCloseButton: false,
                    },
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c("div", { staticClass: "modal-header" }, [
                        _c("div", { staticClass: "modal-header-left" }, [
                          _c("div", { staticClass: "transfer" }, [
                            _c("div", { staticClass: "transfer-code" }, [
                              _vm._v(
                                _vm._s(_vm.$t("Transfer.transferNumber")) +
                                  " : " +
                                  _vm._s(_vm.transferHeader?.code)
                              ),
                            ]),
                            _c("div", { staticClass: "transfer-date" }, [
                              _vm._v(
                                _vm._s(_vm.$t("Transfer.transferDate")) +
                                  " : " +
                                  _vm._s(
                                    _vm.getFormattedDate(_vm.transferHeader)
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "modal-header-right" },
                          [
                            _c("ImportExport", {
                              ref: "wrapper",
                              staticClass: "ImportExport",
                              attrs: {
                                componentName: "TransferExpireModalExport",
                                activeImportExports: ["export"],
                                downloadCustomPopup: true,
                              },
                              on: {
                                downloadClick: _vm.showBatchHeaderExportPopup,
                              },
                            }),
                            _vm.BatchList.length
                              ? _c(
                                  "Button",
                                  {
                                    staticClass: "m-right-10",
                                    attrs: {
                                      secondary: "",
                                      size: "small",
                                      id: "transfer-batch-automatic-quantity",
                                    },
                                    on: { click: _vm.getBatchQuantity },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Transfer.autoExpireDate"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "template",
                      { slot: "content" },
                      [
                        !_vm.BatchList.length
                          ? _c("EmptyState", {
                              attrs: {
                                title: _vm.$t(
                                  "Despatches.emptyState_Title_Batch",
                                  { field: _vm.$t("Dictionary.Batch") }
                                ),
                                description: _vm.$t(
                                  "Global.emptyState_Description_Batch",
                                  { field: _vm.$t("Dictionary.Batch") }
                                ),
                              },
                            })
                          : _c("NestedTable", {
                              staticClass: "custom-nested-table",
                              attrs: {
                                fields: _vm.transferBatchHeaderFields,
                                detailFields: _vm.transferBatchDetailFields,
                                data: _vm.BatchList,
                                actions: ["export"],
                                usePortalFooter: true,
                              },
                              on: {
                                showExportPopup: _vm.showBatchExportPopup,
                                showDetailAction: _vm.showBatchDetailAction,
                              },
                            }),
                        _c("portal", {
                          attrs: { to: "givenQuantity" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (detail) {
                                return _c("div", {}, [
                                  _c(
                                    "div",
                                    { staticClass: "control quantity-input" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              max: _vm.$getConst(
                                                "MAX_DIGITS_FOR_QUANTITY"
                                              ),
                                              greater_than: 0,
                                            },
                                            expression:
                                              "{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}",
                                          },
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: detail.row.givenQuantity,
                                            expression:
                                              "detail.row.givenQuantity",
                                          },
                                        ],
                                        staticClass: "txt no-spinners",
                                        class: {
                                          "is-danger": _vm.veeErrors.has(
                                            `transfer${detail.rowIndex}.givenQuantity`
                                          ),
                                        },
                                        attrs: {
                                          name: "givenQuantity",
                                          "data-vv-as": _vm.$t(
                                            "Despatches.detail_TableColumn_Quantity"
                                          ),
                                          type: "number",
                                          step: "any",
                                        },
                                        domProps: {
                                          value: detail.row.givenQuantity,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.updateGivenBatchQuantity(
                                              $event,
                                              detail.row
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              detail.row,
                                              "givenQuantity",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              },
                            },
                          ]),
                        }),
                        _c("portal", {
                          attrs: { to: "unitCode" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (detail) {
                                return _c("div", {}, [
                                  _c("span", [
                                    _vm._v(_vm._s(detail.row.unitCode)),
                                  ]),
                                ])
                              },
                            },
                          ]),
                        }),
                        _c("portal", {
                          attrs: { to: "warehouseTime" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (detail) {
                                return _c("div", {}, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        detail.row.warehouseTime?.substr(
                                          0,
                                          5
                                        ) || "-"
                                      )
                                    ),
                                  ]),
                                ])
                              },
                            },
                          ]),
                        }),
                        _c("portal", {
                          attrs: { to: "shipmentTime" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (detail) {
                                return _c("div", {}, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        detail.row.shipmentTime?.substr(0, 5) ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ])
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "div",
                        { staticClass: "batch-footer" },
                        [
                          _c(
                            "Button",
                            {
                              staticClass: "m-right-10",
                              attrs: {
                                id: "transfer-batch-detail-cancel",
                                size: "small",
                                secondary: "",
                                inline: "",
                              },
                              on: { click: _vm.closeModal },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("Global.Cancel"))),
                              ]),
                            ]
                          ),
                          _c(
                            "Button",
                            {
                              attrs: {
                                id: "transfer-batch-detail-save",
                                size: "small",
                                primary: "",
                                inline: "",
                              },
                              on: { click: _vm.saveBatch },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("Global.Save"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("router-view", {
              on: {
                getList: _vm.getTransferListWithParams,
                showDrafts: function ($event) {
                  _vm.showDrafts = true
                },
              },
            }),
            _vm.isExportPopupVisible
              ? _c("exportPopup", {
                  ref: "exportPopupTemplate",
                  attrs: {
                    templateId: _vm.templateId,
                    templateType: _vm.templateType,
                  },
                })
              : _vm._e(),
            _vm.showBatchAlert
              ? _c("Alert", {
                  attrs: {
                    title: _vm.$t("Transfer.warning_batch"),
                    hasCancelButton: false,
                    description: _vm.$t("Despatches.description_batch_warning"),
                    okButton: _vm.$t("Global.okay"),
                    icon: "icon-global-notify",
                    loading: "alertForm",
                  },
                  on: {
                    delegateOnAlertOk: function ($event) {
                      _vm.showBatchAlert = false
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }