<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-left-search")
    .Search-input
      .Search-type-switcher(v-click-outside="hidePopup")
        Button.Search-type-switcher-button(
          size="small"
          id="btn-recipes-search-type-switcher"
          @click="showSearchTypePopup = !showSearchTypePopup")
          | {{ $t(`Recipes.search_CriteriaType_${searchType}`) }}
          Icon.Search-type-switcher-button-icon(name="icon-down-arrow")

        .MiniPopup.Search-type-switcher-popup.recipe-search-popup(
          v-if="showSearchTypePopup")
          h2.Search-type-switcher-popup-header {{ $t('Recipes.search_Criteria') }}
          .MiniPopup-tr.Search-type-switcher-popup-row(
            v-for="(types, index) in searchTypes"
            @click="e => switchSearchType(e, types)"
            @keyup.enter="e => switchSearchType(e, types)"
            :id="`search-type-switcher-button-${index}`"
            tabindex="0")
            .MiniPopup-td
              span.Search-type-switcher-check(
                :id="`search-type-switcher-text-${index}`"
                :class="{ active: types === searchType }")
                Icon(v-if="types === searchType"
                  name="icon-custom-checkbox-tick")
              | {{ $t(`Recipes.search_CriteriaType_${types}`) }}
          .MiniPopup-tr.Search-type-switcher-popup-row.alternative-checkbox.m-left-15(
            v-if="searchType === 'byStockItem'"
          )
            CustomCheckbox(
              id="search-type-show-alternative"
              v-model="showAlternative"
              @change="getRecipesByStockItem(queryStockItem)"
              :label="$t('Recipes.search_showAlternative')")

      .Search-input-container
        Icon.Search-input-icon-recipe(name="icon-search")

        Button.Search-input-close(
          variant="icon"
          id="btn-recipes-search-clear"
          v-if="search",
          iconName="icon-popup-close",
          iconClass="icon-popup-close",
          :justIcon="true",
          @click="clearSearch")
          Loading(v-if="isSuggestionLoading")
        input.txt.Search-input-txt(
          type="text"
          autocomplete="off"
          id="input-recipes-search-placeholder"
          v-model="search"
          ref="searchInput"
          :placeholder="$t('Recipes.search_Placeholder')")

      .Search-suggestion(v-if="!suggestionsIsEmpty")
        h5.Search-suggestion-title
          | {{ $t('Recipes.search_SuggestionTitle') }}
        Button.Search-suggestion-item(
          size="small"
          :id="`btn-recipes-search-suggestion-item-${index}`"
          v-for="(suggestion, index) in suggestions",
          :key="suggestion.id",
          @click="searchType === 'byStockItem' ? getRecipesByStockItem(suggestion) : getRecipesByRecipe(suggestion)" ,
          type="button")
          strong.name {{ suggestion.name }}
  template(slot="header-right-content")
    Button(
      primary
      size="medium"
      id="btn-recipes-new"
      type="button",
      iconName="icon-global-add",
      iconClass="icon-global-add",
      v-show="!isRecipesEmpty && !isRecipesLoading",
      @click="itemNew")
      span {{ $t('Recipes.addRecipeButton') }}

  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="typeList"
      :selectedItem="selectedRecipeType"
      @delegateChangeSelectedItem="changedRecipeType"
      :useUpperCase="true"
    )
  template(slot="toolbar-left")
    CustomMultiselect(
      v-show="searchType === 'byName'"
      id="stock-reports-recipe-cost-calculation-filter"
      :items="recipeCostCalculationList"
      :selected="recipeCostCalculationFilter"
      :label="$t('Recipes.toolbar_Cost_Calculation_Popup_Title')"
      :popupHeader="$t('Recipes.toolbar_Cost_Calculation_PopupHeader')"
      :showAll="false"
      :buttonText="$t('Recipes.toolbar_Cost_Calculation_ButtonText')"
      type="radio"
      :updateDropDownLabelFromParent="updateDropDownLabelFromParent"
      buttonIconLeft="btn-success"
      @delegateCheckItem="onChangeRecipeCostCalculationFilter"
      @delegateSubmitItems="getRecipeListWithParams"
    )
    RecipeGroupsFilter.m-left-10(
      :customSelectedData="selectedRecipeGroups"
      @submitFilter="getListWithSupplierFilter"
      ref="recipeGroupsSupplierFilter")

    Button.clear-filter-button.border-gray-1.p-0.width-auto.bgc-white.m-right-10(
      size="small"
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")
    
    div.checkbox-container
      CustomCheckbox.checkbox-show-ungrouped(
        id="checkbox-show-ungrouped"
        :label="$t('Recipes.Checkbox_Show_ungrouped')"
        v-model="showUnGroups",
      )
      CustomCheckbox.show-passive-recipes.m-left-10(
        id="checkbox-recipes-show-passive"
        :checked="showPassive"
        :label="$t('Recipes.showPassiveRecipes')"
        @change="showPassiveRecipes")

  template(slot="toolbar-right")
    ViewType.view-type(
      v-if="searchType === 'byName'"
      firstNodeIcon="icon-view-type-list"
      secondNodeIcon="icon-view-type-group"
      :firstSelected="viewTypeList"
      @firstNodeClick="listViewType(true)"
      @secondNodeClick="listViewType(false)"
    )

    Button.change-recipe-content.m-right-10(
      size="small"
      secondary
      v-if="searchType === 'byStockItem' && checkPermission('RecipeBulkContentEdit')"
      id="change-recipe-content",
      :disabled="!selectedRecipes.length"
      v-click-outside="closeRecipeContentPopup"
      @click="changeRecipeContent"
    )
      span {{ $t('Recipes.changeContent') }}

      .change-recipe-content-popup(:class="showRecipeContentChangePopup ? 'show-recipe-content-popup' : null")
        h2.change-recipe-content-popup-header {{ $t('Recipes.recipeContentChange') }}
        .change-recipe-content-popup-contents.m-top-10
          .change-recipe-content-popup-contents-stock-item
            label.Form-item-label {{ $t('Recipes.newStockItem') }}
            custom-search(
              component-name="recipe-change-content-stock-item"
              name="stockItem",
              v-model="newStockItem",
              ref="stockItem"
              id="change-recipe-content-search-stock-item"
              :on-search="getRecipeStockItemOptions",
              :options="newRecipeStockItemSelectOptions",
              label="name",
              :placeholder="$t('Recipes.searchStockItem')"
            )
          .change-recipe-content-popup-contents-factor
            label.Form-item-label {{ $t('Recipes.factor') }}
            customNumberInput.number-input(
              name="changedRecipeContentFactor"
              id="input-recipe-content-factor"
              placeholder="0"
              :error="veeErrors.has('changedRecipeContentFactor')"
              :data-vv-as="$t('Recipes.changedRecipeContentFactor')"
              v-model="changedRecipeFactor"
              v-validate="'greater_than:0'"
            )

          Button.m-top-15(
            size="full"
            primary
            id="submit-recipe-content-changes",
            :disabled="!this.changedRecipeFactor && !this.newStockItem"
            @click="updateSelectedRecipesContent"
          )
            span {{ $t('Global.apply') }}
      
    Button.change-recipe-content.m-right-10(
      size="small"
      secondary
      v-if="searchType === 'byStockItem' && checkPermission('RecipeBulkContentEdit')"
      id="delete-recipe-content",
      :disabled="!selectedRecipes.length"
      @click="deleteSelectedRecipeContent"
    )
      span {{ $t('Recipes.deleteContent') }}


  template(slot="content-center")
    .Recipes(:class="({'blur': apllyBlurToBackground})")

      TableLoading(v-if="isRecipesByStockItemLoading || isRecipesLoading || isRecipesByRecipeLoading")
      // EMPTY PAGE

      EmptyState(
        v-if="searchType === 'byName' && isRecipesEmpty && !isRecipesLoading",
        :title="$t('Recipes.emptyState_Title')",
        :description="$t('Recipes.emptyState_Description')",
        :buttonText="$t('Recipes.emptyState_ButtonText')",
        @emptyAddButton="itemNew")

      // LIST

      TableView(
        v-if="!isRecipesEmpty && !isRecipesLoading && viewTypeList && searchType === 'byName'"
        :fields="getRecipeFields"
        :actions="['edit', 'remove', 'copy', 'active']"
        :data="recipes_List"
        :page="Page"
        :componentName="'RecipesTable'"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemShow="goToRecipe"
        @delegateOnItemCopy="copyRecipe"
        @delegateOnItemEdit="editRecipe"
        @delegateOnItemChangeActiveStatus="changeActiveStatus"
        @delegateOnItemDelete="removeRecipe"
        @sort="sort")

      BoxView(
        v-if="!isRecipesEmpty && !isRecipesLoading && !viewTypeList  && searchType === 'byName'"
        :fields="fields"
        :actions="['copy', 'edit', 'remove', 'active']"
        :data="recipes_List"
        :page="Page"
        :extraClass="extraClass"
        :showPassiveBox="true"
        :componentName="'RecipesBox'"
        @delegateOnItemShow="goToRecipe"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @delegateOnItemCopy="copyRecipe"
        @delegateOnItemEdit="editRecipe"
        @delegateOnItemRemove="removeRecipe"
        @delegateOnItemChangeActiveStatus="changeActiveStatus"
      )
      portal(to="action-buttons")
        div(slot-scope="row" v-if="isRecipesByStockItemEmpty && !isRecipesByStockItemLoading")
          Button.c-light.m-right-15.show-MiniPopup.relative(
            size="small"
            :id="`box-list-RecipesBox-row-${row.index}-hover`"
            :iconName="'icon-global-show-allergen'"
            :iconClass="'icon-global-show-allergen border-warning-1'"
            @mouseenter="showAllergen(row)"
            @mouseleave="showAllergenLeave()"
            :justIcon="true"
            :clearVariant="true"
          )
            .MiniPopup-left-primary.m-top-15.c-dark.custom-mini-popup-table(v-if="isShowAllergen && viewTypeList")
              IconBox(
                :data = "recipeAllergenList"
                :isDataLoading = "isLoadingAlergenList"
              )
            .MiniPopup-center-primary.m-top-15.c-dark.custom-mini-popup(v-if="isShowAllergen && !viewTypeList")
              IconBox(
                :data = "recipeAllergenList"
                :isDataLoading = "isLoadingAlergenList"
              )
      portal(to="action-calorie")
        div(slot-scope="row" v-if="row.hasNutritionValues")
          Button.action-calorie-btn.c-light.show-MiniPopup.relative(
            size="small"
            :id="`box-list-RecipesBox-row-calorie-${row.index}-hover`"
            :iconName="'icon-calorie'"
            :iconClass="'icon-calorie'"
            @mouseenter="showTotalCalorie(row)"
            @mouseleave="showCalorieLeave()"
            :justIcon="true"
            :clearVariant="true"
          )
              .MiniPopup-left-primary.m-top-15.c-dark.custom-mini-popup-table.calorie-box(v-if="isShowCalorie && viewTypeList")
                Loading(v-if="isLoadingTotalCalorie")
                span(v-else) {{calorieList.totalAmount}} {{calorieList.nutritionFieldUnitName}}

              .MiniPopup-center-primary.m-top-15.c-dark.custom-mini-popup.calorie-box(v-if="isShowCalorie && !viewTypeList")
                span {{calorieList.totalAmount}} {{calorieList.nutritionFieldUnitName}}

      .Receteler-by-stock-item(v-if="searchType === 'byStockItem'")
        .Result
          .Result-top-header
            .Result-top-header-title(v-if="!isRecipesByStockItemEmpty && !isRecipesByStockItemLoading")
              span(v-html="$t('Recipes.result_Header', { stockItemName: queryStockItem.name })")

        div(v-if="!isRecipesByStockItemEmpty && !isRecipesByStockItemLoading")
          TableView(
            :fields="recipesByStockItemFields",
            :data="recipes_by_stock_item_List",
            :componentName="'RecipesByStockTable'"
            uniqueIdentifier="recipeDetailId"
            :readOnly="true",
            :selectable="checkPermission('RecipeBulkContentEdit') ? 'checkbox' : ''"
            :selectableAll="checkPermission('RecipeBulkContentEdit') ? 'checkbox' : ''"
            :selectedRows="selectedRecipes"
            :tableName="$t('Recipes.result_TableName')"
            :pointer="true"
            @delegateOnItemShow="goToRecipe"
            @delegateOnItemSelect="onSelectRecipe"
            @showAllSelectedItems="onSelectRecipeAll"
            )

          portal(to="headerType")
            .category.portal-header-type.row-alternative-stock
              span.category-text {{ $t('Recipe.tableColumn_Ingredients_Type') }}
              span.category-info
                Icon.show-MiniPopup.category-info-icon(name="icon-global-info-filled")
                ul.category-popup
                  li.category-popup-row(
                    v-for="item in popupInfoObject"
                  )
                    span.category-popup-value.stock-type(:class="`${item.name}`") {{ item.value }}
                    span.category-popup-text {{ item.text }}

          portal(to="isAlternative")
            div.row-alternative-stock(slot-scope="row")
              span.stock-type(:class="row.isAlternative ? 'alternative' : 'normal'") {{ row.isAlternative ? $t('Recipes.stock_type_alternative') : $t('Recipes.stock_type_normal') }}

        EmptyState(
          v-if="shouldShowEmptyStateForRecipeSearch",
          :title="$t('Recipes.emptyState_NotFound_Title')",
          :description="$t('Recipes.emptyState_NotFound_Description')",
          :buttonText="$t('Recipes.emptyState_ButtonText')",
          @emptyAddButton="itemNew")

      .Receteler-by-stock-item(v-if="searchType === 'byRecipe'")
        .Result
          .Result-top-header
            .Result-top-header-title(v-if="!isRecipesByRecipeEmpty && !isRecipesByRecipeLoading")
              span(v-html="$t('Recipes.result_Header_ByRecipe', { recipeName: queryRecipe.name })")

        div(v-if="!isRecipesByRecipeEmpty && !isRecipesByRecipeLoading")
          TableView(
            :fields="recipesByRecipeFields",
            :data="recipesByRecipe",
            :componentName="'RecipesByStockTable'"
            :readOnly="true",
            :tableName="$t('Recipes.result_TableName')"
            :pointer="true"
            @delegateOnItemShow="goToRecipe")

        EmptyState(
          v-if="shouldShowEmptyStateForRecipeSearch",
          :title="$t('Recipes.emptyState_NotFound_Title')",
          :description="$t('Recipes.emptyState_NotFound_Description')",
          :buttonText="$t('Recipes.emptyState_ButtonText')",
          @emptyAddButton="itemNew")

      // POPUPS

    router-view(
      @getList="getRecipeListWithParams"
    )

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import getFields from './fields/recipes-field'
//
import IconBox from '@/view/global/icon-box'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'
import RecipeGroupsFilter from '@/view/global/business-filters/recipe-groups.vue'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import ViewType from '@/view/global/view-type'
import { debouncer, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'Recipes',

  components: {
    RecipeGroupsFilter,
    ToggleOverGroups,
    ViewType,
    businessMultiselect,
    IconBox
  },
  data () {
    return {
      fields: getFields(this.$t, this.isPriceAvailable),
      search: '',
      viewTypeList: true,
      keepRecipeGroupFilter: false,
      updateDropDownLabelFromParent: true,
      recipeCostCalculationList: [],
      recipeCostCalculationFilter: [],
      searchTypes: ['byName', 'byRecipe', 'byStockItem'],
      searchType: 'byName',
      showSearchTypePopup: false,
      showUnGroups: true,
      suggestions: [],
      queryStockItem: null,
      queryRecipe: null,
      selectedRecipeType: {},
      recipeGroupsFilter: [],
      sortExpressionList: [],
      showAlternative: false,
      isShowAllergen: false,
      selectedRecipes: [],
      selectedStockItem: null,
      isShowCalorie: false,
      showRecipeContentChangePopup: false,
      newRecipeStockItemSelectOptions: [],
      newStockItem: null,
      changedRecipeFactor: null,
      typeList: [
        {
          name: this.$t('Recipes.Item_Type_All'),
          id: null
        },
        {
          name: this.$t('Recipes.Item_Type_Recipe'),
          id: 1
        },
        {
          name: this.$t('Recipes.Item_Type_Production'),
          id: 2
        }
      ],
      popupInfoObject: [
        {
          value: this.$t('Recipe.tableColumn_Ingredients_StockLabel'),
          text: this.$t('Recipe.tableColumn_Ingredients_StockLabel_Text'),
          name: 'normal'
        },
        {
          value: this.$t('Recipe.tableColumn_Ingredients_AlternativeLabel'),
          text: this.$t('Recipe.tableColumn_Ingredients_AlternativeLabel_Text'),
          name: 'alternative'
        }
      ],
      isPriceAvailable: false,
      isRecipeActive: false,
      showActivationPopup: false,
      showPassive: false
    }
  },

  computed: {
    ...mapGetters('Recipes', [
      'recipes_List',
      'recipes_IsEmpty',
      'recipes_by_stock_item_List',
      'recipes_by_stock_item_IsEmpty',
      'Page',
      'recipeAllergenList',
      'pageHeaderLinks',
      'calorieList',
      'recipesByRecipe',
      'selectedRecipeGroups'
    ]),

    ...mapGetters('Settings', ['pricePermission','hasPermissionToAllStores', 'checkPermission']),

    isSuggestionLoading () {
      return this.$wait.is(['searchStockItem'])
    },

    getRecipeFields () {
      return getFields(this.$t, this.isPriceAvailable)
    },

    apllyBlurToBackground () {
      return this.$route.name === 'RecipeCopy' || this.$route.name === 'RecipeRemove'
    },

    suggestionsIsEmpty () {
      return this.suggestions.length === 0
    },

    extraClass () {
      return {forContent: 'box-table', forBox: 'shadow-glow'}
    },

    isRecipesEmpty () {
      return this.recipes_IsEmpty
    },

    isRecipesByStockItemEmpty () {
      return this.recipes_by_stock_item_IsEmpty
    },

    isRecipesByRecipeEmpty () {
      return this.recipesByRecipe.length === 0
    },

    isRecipesLoading () {
      return this.$wait.is(['get_RECIPES_LIST']) || this.$wait.is(['creatingPage'])
    },

    isRecipesByStockItemLoading () {
      return this.$wait.is(['get_RECIPES_BY_STOCK_ITEM'])
    },

    isRecipesByRecipeLoading () {
      return this.$wait.is(['getRecipesWithRecipe'])
    },

    shouldShowEmptyStateForStockItemSearch () {
      return (
        this.searchType === 'byStockItem' &&
          this.isRecipesByStockItemEmpty &&
          !this.isRecipesByStockItemLoading &&
          this.queryStockItem !== null
      )
    },

    shouldShowEmptyStateForRecipeSearch () {
      return (
        this.searchType === 'byRecipe' &&
          this.isRecipesByRecipeEmpty &&
          !this.isRecipesByRecipeLoading &&
          this.queryRecipe !== null
      )
    },

    isLoadingAlergenList () {
      return this.$wait.is(['ShowAllergenBox'])
    },

    isLoadingTotalCalorie () {
      return this.$wait.is(['ShowCalorieBox'])
    },

    recipesByStockItemFields () {
      return [
        {
          name: 'isAlternative',
          headerPortal: 'headerType',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Recipes.tableColumn_stock_type'),
          title: this.$t('Recipes.tableColumn_stock_type')
        },
        {
          name: 'name',
          is_visible: true,
          short_title: this.$t('Recipes.tableColumn_Name_Short'),
          title: this.$t('Recipes.tableColumn_Name')
        },
        {
          name: 'quantity',
          is_visible: true,
          type: 'mono',
          short_title: this.$t('Recipes.tableColumn_Quantity_Short'),
          title: this.$t('Recipes.tableColumn_Quantity')
        },
        {
          name: 'unitName',
          is_visible: true,
          short_title: this.$t('Recipes.tableColumn_UnitName_Short'),
          title: this.$t('Recipes.tableColumn_UnitName')
        }
      ]
    },
    recipesByRecipeFields () {
      return [
        {
          name: 'name',
          is_visible: true,
          short_title: this.$t('Recipes.tableColumn_Name_Short'),
          title: this.$t('Recipes.tableColumn_Name')
        },
        {
          name: 'quantity',
          is_visible: true,
          type: 'mono',
          short_title: this.$t('Recipes.tableColumn_Quantity_Short'),
          title: this.$t('Recipes.tableColumn_Quantity')
        },
        {
          name: 'unitName',
          is_visible: true,
          short_title: this.$t('Recipes.tableColumn_UnitName_Short'),
          title: this.$t('Recipes.tableColumn_UnitName')
        }
      ]
    }
  },

  watch: {
    showUnGroups () {
      this.changedRecipeType()
    },
    search: debouncer(function (val) {
      if (this.searchType === 'byName') {
        if (val.length !== 1) this.getRecipeListWithParams()
      } else if (this.searchType === 'byStockItem') {
        if (val.length < 2) return
        this.searchStockItem({text: val, types: [1, 2, 3]})
          .then(res => {
            if (!res) return
            this.suggestions = res.data.items
          })
      } else {
        if (val.length < 2) return
        this.searchRecipeByName({name: val, type: 1})
          .then(res => {
            if (res.status === 200) {
              this.suggestions = res.data.recipeShortHeaderList
            }
          })
      }
    }, 500)

  },

  destroyed () {
    if (!this.keepRecipeGroupFilter) this.RESET_RECIPE_GROUP_FILTER()
  },

  async mounted () {
    // when used another lifecycles it made css bug
    this.$wait.start('creatingPage')
    this.pricePermission.find(i => {
      if (i.permission === 90) this.isPriceAvailable = true
    })
    const lastPurchasePriceType = {name: this.$t('Recipes.Last_Purchase_Price_Type'), id: '-1'}
    const defaultSettings = await this.getDefaultSettings()
    const recipeCostCalculationType = defaultSettings.data.settings.RecipeCostCalculationType
    await this.getMiniStoreList()
      .then(res => {
        if (!res) return
        this.recipeCostCalculationList = this.hasPermissionToAllStores ? [lastPurchasePriceType, ...res.data.items] : [...res.data.items]
        let selectedItem = this.recipeCostCalculationList.find(item => item.id === recipeCostCalculationType)
        this.recipeCostCalculationFilter.push(selectedItem || this.recipeCostCalculationList[0])
        this.updateDropDownLabelFromParent = !this.updateDropDownLabelFromParent
      })
    this.changedRecipeType(this.typeList[0])
    this.getRecipeListWithParams(this.Page.number)
    this.$wait.end('creatingPage')
  },

  beforeDestroy () {
    this.RESET_RECIPES()
    this.RESET_RECIPES_BY_STOCK_ITEM()
  },

  methods: {
    ...mapActions('Recipes', [
      'get_RECIPES_LIST',
      'get_RECIPES_BY_STOCK_ITEM',
      'getRecipeAllergens',
      'getTotalCalorie',
      'getRecipesWithRecipe',
      'searchRecipeByName',
      'changeActivationStatus',
      'updateRecipeContent',
      'deleteRecipeContent'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Stores', [
      'getMiniStoreList'
    ]),

    ...mapActions('Settings', [
      'setDefaultSettings',
      'getDefaultSettings'
    ]),

    ...mapMutations('Recipes', [
      'RESET_RECIPES',
      'RESET_RECIPES_BY_STOCK_ITEM',
      'CLEAR_RECIPES_ALLERGEN_LIST',
      'RESET_RECIPES_BY_RECIPE',
      'SET_RECIPE_GROUP_FILTER',
      'RESET_RECIPE_GROUP_FILTER'
    ]),


    updateSelectedRecipesContent () {
      const payload = {
        recipeDetailIds: this.selectedRecipes.map(i => i.recipeDetailId),
        stockItemId: this.selectedStockItem.id,
        newStockItemId: this.newStockItem ? this.newStockItem.id : null,
        factor: this.changedRecipeFactor
      }
      this.updateRecipeContent(payload).then(res => {
        if (res) {
          const message = this.$t('Recipes.notification_contentUpdatedSuccessfully')
          this.notifyShow({ message })
          this.closeRecipeContentPopup()
          this.selectedRecipes = []
          this.getRecipesByStockItem(this.selectedStockItem)
          this.search = null
        } 
      })
    },

    getRecipeStockItemOptions (search) {
      this.searchStockItem({text: search, types: [1, 2, 3]})
        .then(res => {
          this.newRecipeStockItemSelectOptions = res.data.items
        })
    },

    

    selectNewRecipeStokItem (stockItem) {
      this.newStockItem = stockItem
    },


    closeRecipeContentPopup () {
      this.showRecipeContentChangePopup = false
    },

    changeRecipeContent () {
      this.showRecipeContentChangePopup = !this.showRecipeContentChangePopup
    },

    deleteSelectedRecipeContent () {
      const payload = {
        recipeDetailIds: this.selectedRecipes.map(i => i.recipeDetailId),
        stockItemId: this.selectedStockItem.id
      }
      this.deleteRecipeContent(payload).then(res => {
        if (res) {
          const message = this.$t('Recipes.notification_contentDeletedSuccessfully')
          this.notifyShow({ message })
          this.selectedRecipes = []
          this.getRecipesByStockItem(this.selectedStockItem)
          this.search = null
        }
      })
    },

    onSelectRecipe (recipe) {
      const isChecked = !this.selectedRecipes.some(item => item.recipeDetailId === recipe.recipeDetailId)
      if (isChecked) this.selectedRecipes.push(recipe)
      else this.selectedRecipes.splice(this.selectedRecipes.findIndex(item => item.recipeDetailId === recipe.recipeDetailId), 1)
    },

    onSelectRecipeAll (isChecked) {
      if (isChecked) {
        this.selectedRecipes = [...this.recipes_by_stock_item_List]
      } else this.selectedRecipes = []
    },

    sort ({ name, sortingObject }) {
      this.fields.forEach(field => {
        if (field.name !== name && 'sortingObject' in field) field.sortingObject.sortType = null
      })
      switch (sortingObject.sortType) {
        case null:
          sortingObject.sortType = 0
          this.sortExpressionList = [sortingObject]
          break
        case 0:
          sortingObject.sortType++
          this.sortExpressionList = [sortingObject]
          break
        case 1:
          sortingObject.sortType = null
          this.sortExpressionList = []
          break
      }

      this.changedRecipeType()
    },

    async changeActiveStatus (item) {
      if (item.isActive) this.isRecipeActive = false
      else this.isRecipeActive = true
      const payload = {
        id: item.id,
        state: this.isRecipeActive
      }
      const res = await this.changeActivationStatus(payload)
      if (res) {
        const message = !this.isRecipeActive ? item.name + ' ' + this.$t('Recipes.notification_passive') : item.name + ' ' + this.$t('Recipes.notification_active')
        this.notifyShow({ message })
        this.close()
        await this.getRecipeListWithParams()
      }
    },

    showPassiveRecipes () {
      this.showPassive = !this.showPassive
      this.getRecipeListWithParams()
    },

    close () {
      this.showActivationPopup = false
    },

    showAllergen: vueWaitLoader(async function (item) {
      this.isShowAllergen = true
      const payload = {
        page: 1,
        pageSize: 999999,
        RecipeId: item.id
      }
      await this.getRecipeAllergens(payload)
    }, 'ShowAllergenBox'),

    showAllergenLeave () {
      this.isShowAllergen = false
    },

    listViewType (type) {
      this.viewTypeList = type
    },

    showTotalCalorie: vueWaitLoader(async function (item) {
      this.isShowCalorie = true
      const payload = {
        RecipeHeaderId: item.id,
        FetchCaloriesOnly: true
      }
      await this.getTotalCalorie(payload)
    }, 'ShowCalorieBox'),

    showCalorieLeave () {
      this.isShowCalorie = false
    },

    hidePopup () {
      if (this.showSearchTypePopup) this.showSearchTypePopup = !this.showSearchTypePopup
    },

    clearFilter () {
      this.$refs.recipeGroupsSupplierFilter.clearRecipeGroupsFilter(false)
    },

    getListWithSupplierFilter ({list, dontSubmit}) {
      if (dontSubmit) {
        this.recipeGroupsFilter = list
      }
      else {
        this.recipeGroupsFilter = list
        this.changedRecipeType()
        this.SET_RECIPE_GROUP_FILTER(list)
      }
    },

    onChangeRecipeCostCalculationFilter (event) {
      this.recipeCostCalculationFilter = [event.item]
    },

    async setRecipeCostCalculationType (id) {
      const payload = {
        settings: {
          RecipeCostCalculationType: id
        }
      }
      this.setDefaultSettings(payload)
    },

    changedRecipeType (item) {
      if (item) this.selectedRecipeType = item
      if (this.searchType === 'byName') {
        this.getRecipeListWithParams()
      } else if (this.searchType === 'byRecipe') {
        this.getRecipesByRecipe(this.queryRecipe)
      } else this.getRecipesByStockItem(this.queryStockItem)
    },

    clearSearch () {
      this.RESET_RECIPES()
      this.RESET_RECIPES_BY_STOCK_ITEM()
      this.RESET_RECIPES_BY_RECIPE()
      this.search = ''
      this.suggestions = []
      if (this.searchType === 'byName') {
        this.getRecipeListWithParams()
      } else if (this.searchType === 'byStockItem') {
        this.queryStockItem = null
      } else this.queryRecipe = null
    },

    switchSearchType (event, searchType) {
      this.searchType = searchType
      this.clearSearch()
      if (this.searchType === 'byName') {
        this.getRecipeListWithParams()
        this.showSearchTypePopup = false
      } else if (this.searchType === 'byRecipe') {
        this.showSearchTypePopup = false
      }
      this.clearSearch()
      this.$refs.searchInput.focus()
    },

    async getRecipeListWithParams (page, pageSize = this.Page.size) {
      this.get_RECIPES_LIST({
        name: this.search,
        type: this.selectedRecipeType.id,
        CostCalculationType: this.recipeCostCalculationFilter[0].id === '-1' ? 1 : 0,
        CostCalculationStoreId: this.recipeCostCalculationFilter[0].id === '-1' ? null : this.recipeCostCalculationFilter[0].id,
        RecipeGroupIds: this.recipeGroupsFilter.map(recipeGroup => recipeGroup.id),
        page: page,
        pageSize,
        showUnGroups: this.showUnGroups,
        sortExpressionList: this.sortExpressionList,
        showPassives: this.showPassive
      })
      if (!this.$wait.is(['creatingPage'])) this.setRecipeCostCalculationType(this.recipeCostCalculationFilter[0].id)
    },

    getRecipesByStockItem (stockItem) {
      this.selectedStockItem = stockItem
      if (!stockItem) return
      this.suggestions = []
      this.queryStockItem = stockItem
      const payload = {
        StockItemId: stockItem.id,
        Type: this.selectedRecipeType.id,
        ShowUnGroups: this.showUnGroups,
        showAlternatives: this.showAlternative,
        RecipeGroupIds: this.recipeGroupsFilter.map(recipeGroup => recipeGroup.id),
        ShowPassives: this.showPassive
      }
      this.get_RECIPES_BY_STOCK_ITEM(payload)
    },

    getRecipesByRecipe (recipe) {
      if (!recipe) return
      this.suggestions = []
      this.queryRecipe = recipe
      const payload = {
        RecipeHeaderId: recipe.id,
        Type: this.selectedRecipeType.id,
        ShowUnGroups: this.showUnGroups,
        showAlternatives: this.showAlternative,
        RecipeGroupIds: this.recipeGroupsFilter.map(recipeGroup => recipeGroup.id),
        ShowPassives: this.showPassive
      }
      this.getRecipesWithRecipe(payload)
    },

    goToRecipe (recipe) {
      this.keepRecipeGroupFilter = true
      this.$router.push({
        name: 'Recipe',
        params: {
          recipeHeaderId: recipe.id,
          hasNutritionValues: recipe.hasNutritionValues
        }
      })
    },

    itemNew () {
      this.keepRecipeGroupFilter = true
      this.$router.push({
        name: 'RecipeNew'
      })
    },

    editRecipe (item) {
      this.keepRecipeGroupFilter = true
      this.$router.push({
        name: 'RecipeEdit',
        params: { id: item.id }
      })
    },

    removeRecipe (item) {
      this.keepRecipeGroupFilter = true
      this.$router.push({
        name: 'RecipeRemove',
        params: {
          item,
          id: item.id
        }
      })
    },

    copyRecipe (item) {
      this.keepRecipeGroupFilter = true
      this.$router.push({
        name: 'RecipeCopy',
        params: {
          item,
          id: item.id
        }
      })
    },

    changePage (page) {
      this.getRecipeListWithParams(page)
    },
    changePageSize (pageSize) {
      this.getRecipeListWithParams(1, pageSize)
    }
  }
}
</script>

<style scoped lang="scss">

  @import "~@/stylesheet/config/mixin";
  :deep() .Tableee-row {
    cursor: pointer;
    background: white !important;
  }
  :deep(.Boxlist-actions)  {
    .action-calorie,.action-buttons {
      .btn-small {
        padding-left: 2.5px !important;
        padding-right: 2.5px !important;
      }
    }
  }

  :deep() .boxLink {
    cursor: pointer !important;
  }

  .Search {
    margin-bottom: 15px;
    .Search-input {
      background-color: $color-white;
    }
    .recipe-search-popup {
      top: 35px;
      right: initial;
    }
    &-input {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid $color-gray;
      border-radius: 4px;
      margin-left: 0 !important;

      &-icon-recipe{
        position: absolute;
        top: 50%;
        left: 10px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        color: #7b8187;
        pointer-events: none;
      }

      &-container {
        position: relative;
        flex: 1;
      }

      &-close {
        position: absolute;
        right: 0;
        top: 0;
        height: 42px;

        .icon {
          width: 24px;
          height: 24px;
        }

        &:hover {
          background-color: rgba($color-warning, 0.1);
        }
      }

      &-txt {
        min-height: 48px;
        padding-left: 40px;
        padding-right: 40px;
        border: none;
      }

      &-icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        color: $color-light;
        pointer-events: none;
      }
    }

    &-type-switcher {
      user-select: none;
      position: relative;
      margin: 0;
      &-button {
        padding: 5px 5px;
        font-size: $font-size-micro;
        white-space: pre;
        background: $color-gray;
        color: $color-light;
        border-radius: 4px;
        margin-left:5px;

        &-icon {
          width: 9px;
          height: 6px;
          margin-left: 10px;
        }
      }

      &-check {
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        vertical-align: text-top;
        margin-right: 10px;
        border: 1px solid $color-success;
        border-radius: 50%;
        background: $color-seashell;

        svg {
          width: 8.9px;
          height: 6.7px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &-popup {
        top: 133%;
        right: -71.5px;
        width: 256px;
        box-shadow: 0 2px 15px 0 rgba($color-primary, 0.28);
        z-index: 2;

        &-header {
          padding: 20px 10px 10px;
          font-size: $font-size-normal;
          font-weight: $font-weight-normal;
        }

        &-row {
          padding: 16px 10px;
          cursor: pointer;
        }
      }
    }

    &-suggestion {
      overflow-y: scroll;
      position: absolute;
      left: 6px;
      top: 100%;
      z-index: 1;
      margin-top: 10px;
      width: 100%;
      max-height: 300px;
      background-color: white;
      box-shadow: 0 10px 30px rgba(black, 0.1);
      border-radius: $border-radius;
      border: 1px solid $color-gray;

      &-title {
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 10px;
        color: $color-primary;
      }

      &-item {
        height: $finger-size;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        font-weight: $font-weight-normal;
        width: 100%;

        &:hover {
          background-color: $color-ocean;
        }

        & + & {
          border-top: 1px solid $color-gray;
        }

        .type {
          color: $color-text-light;
          margin-left: 10px;
        }
      }
    }
  }

  .custom-mini-popup{
    min-width: 350px;
    left: calc(50% - 175px);
  }

  .custom-mini-popup.calorie-box{
    min-width: 80px;
    left: calc(100% - 60px);
  }

  .allergen-box{
    min-width: 350px;
  }

  .calorie-box {
    width: 100px;
  }

  .custom-mini-popup-table{
    min-width: 350px;
    left: calc(50% - 17px);
  }
  .custom-mini-popup-table.calorie-box{
    min-width: 90px
  }

  .PageHeader {
    padding-bottom: 0;

    .tab {
      display: flex;

      a {
        margin-right: 20px;
        font-size: 1.8em;
        padding-bottom: $page-padding-bottom;
        border-bottom: 4px solid transparent;
        font-family: $font-family;
        font-weight: $font-weight-light;
        color: $color-dark;

        &.isSelected {
          pointer-events: none;
          color: $color-primary;
          border-bottom-color: $color-primary;
          font-weight: 500;
        }
      }
    }

    .right {
      margin-bottom: 15px;
    }
  }

  .Result {
    margin-top: 30px;

    &-top-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &-title {

        svg {
          margin-right: 5px;
          color: $color-primary;
        }
      }

      &-total {

        .title {
          font-size: $font-size-small;
          color: $color-success;
          margin-right: 20px;
          font-weight: $font-weight-bold;
        }

        .value {
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  .btn-copy {
    color: $color-light;
    background-color: $color-gray;
    margin-right: 10px;
    transition: all 0.2s ease;

    &:hover {
      color: $color-dark;
    }
  }

  .Search {
    margin-bottom: 5px;
  }

  .checkbox-container {
    display: flex;
  }
  .checkbox-show-ungrouped {
    align-self: center;
  }
  .row-alternative-stock {
    text-align: center;
    .stock-type {
      padding: 5px 7px;
      font-size: $font-size-small;
      border-radius: $border-radius;
      &.normal {
        color: $color-primary;
        border: 1px solid $color-primary;
        background-color: lighten($color-primary, 40%);
      }
      &.alternative {
        color: $color-orange;
        border: 1px solid $color-orange;
        background-color: lighten($color-orange, 45%);
      }
    }
  }
  $info-icon-size: 15px;
  .category {
    display: flex;

    &-info {
      position: relative;
      height: auto;

      &-icon {
        margin-left: 2.4px;
        width: $info-icon-size;
        height: $info-icon-size;
        color: $color-primary;
        cursor: pointer;
      }
    }

    &-popup {
      @include MiniPopupMixin(
        $display: none,
        $width: 186px,
        $max-width: 340px,
        $triangle-direction: 'left',
        $padding-hor: 0,
        $padding-ver: 0,
      );

      color: $color-dark;

      &-row {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
      }

      &-row:not(:last-child) {
        border-bottom: 1px solid $color-gray;
      }

      &-value {
        font-weight: $font-weight-bold;
      }

      &-text {
        margin-left: 11px;
        color: $color-dark;
        font-size: $font-size-normal;
      }
    }
  }
  .portal-detail-type,
  .portal-header-type {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .Tableee-col.action-calorie{
    width: 18px !important;
    height: 18px !important;
    text-align: center !important;
  }
  :deep() .icon-global-show-allergen {
    width: 18px !important;
    height: 18px !important;
  }
  .Tableee-col.action-buttons{
    width: 20px !important;
    text-align: center !important;
  }
  .Boxlist-actions {
    top: 10px !important;
  }
  .activate-button {
    margin-left: 15px;
    margin-top: 2px;
  }
  :deep() {
    .icon-calorie {
      width: 20px;
      height: 20px;
    }
  }

  .change-recipe-content {
    position: relative;
      &-popup {
        @include MiniPopupMixin(
          $width: 300px,
          $margin: 40px 0 0 0,
          $triangle-direction: 'right',
          $padding-hor: 15px,
          $padding-ver: 15px,
          $display: none
        );

        &.show-recipe-content-popup {
          display: flex;
          flex-direction: column;
          width: 100px;
        }

        &-header {
          color: $color-dark;
          font-size: $font-size-normal;
          font-weight: $font-weight-normal;
        }
        &-contents {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          color: $color-dark;
          &-stock-item {
            width: 100%;
          }
          &-factor {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .number-input {
              width: 150px;
            }
          }
        }
    }
  }

</style>
