var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { staticClass: "price-quote" },
    [
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "PriceQuota",
              isSuggestionLoading: _vm.isLoading,
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmpty
            ? _c(
                "Button",
                {
                  attrs: {
                    id: "btn-price-quote-new",
                    type: "button",
                    size: "medium",
                    primary: "",
                    iconName: "icon-global-add",
                    iconClass: "icon-global-add",
                  },
                  on: { click: _vm.createNewPriceQuote },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("PriceQuota.createPriceQuotaButton"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "toolbar-left" }, [
        _c(
          "div",
          { staticClass: "toolbar-filters" },
          [
            _c("SupplierFilter", {
              ref: "supplierFilter",
              staticClass: "multiselect-popup",
              on: { submitFilter: _vm.getListWithSupplierFilter },
            }),
            _c("businessMultiselect", {
              ref: "businessQuoteGroupFilter",
              staticClass: "multiselect-popup",
              attrs: {
                componentName: "price-quote-group-filter",
                multiselectItems: _vm.filterData.itemGroupList,
                label: _vm.$t("PriceQuota.toolbar_FilterItemGroup"),
                popupHeader: _vm.$t(
                  "PriceQuota.toolbar_FilterItemGroup_PopupHeader"
                ),
                unselectedText: _vm.$t(
                  "PriceQuota.toolbar_FilterStock_Unselected"
                ),
              },
              on: { submitFilter: _vm.submitFilteredGroup },
            }),
            _c("div", { staticClass: "custom-select m-right-10" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeStockFilterPopup,
                      expression: "closeStockFilterPopup",
                    },
                  ],
                  staticClass: "Form-item dropdown-handle",
                },
                [
                  _c("span", { ref: "dropdownHandleLabel" }),
                  _c(
                    "div",
                    {
                      staticClass: "select dropdown-handle-button",
                      attrs: {
                        id: "select-price-quote-stock-filter",
                        "aria-expanded": _vm.showStockFilter,
                        tabindex: "0",
                      },
                      on: {
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.toggleStockFilterPopup.apply(
                              null,
                              arguments
                            )
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            )
                              return null
                            $event.preventDefault()
                            return _vm.toggleStockFilterPopup.apply(
                              null,
                              arguments
                            )
                          },
                        ],
                        click: _vm.toggleStockFilterPopup,
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "dropdown-handle-button-icon",
                        class: [_vm.showStockFilter ? "open" : ""],
                        attrs: { name: "icon-down-arrow" },
                      }),
                      _c(
                        "div",
                        { staticClass: "dropdown-handle-button-values" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("PriceQuota.toolbar_FilterStock"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("popup-filter", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showStockFilter,
                        expression: "showStockFilter",
                      },
                    ],
                    key: "name",
                    ref: "stockItemPopupFilter",
                    attrs: {
                      label: "name",
                      list: _vm.stockItemList,
                      "selected-list": _vm.selectedStockItems,
                      popupPosition: {},
                      "block-scroll": false,
                      headerLabel: _vm.$t(
                        "PriceQuota.toolbar_FilterStock_PopupHeader"
                      ),
                    },
                    on: {
                      changeSearch: (search) =>
                        _vm.changePopupFilterSearch(search),
                      toggleAll: (isSelectedAll) =>
                        _vm.toggleAll(isSelectedAll),
                      submitFilter: _vm.submitFilteredStockItem,
                      clearFilter: _vm.clearStockFilter,
                      onChangeCheckbox: ({ item, isChecked }) =>
                        _vm.onChangePopupFilterCheckbox({ item, isChecked }),
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "Button",
              {
                staticClass:
                  "clear-filter-button border-gray-1 p-0 width-auto m-right-10",
                on: { click: _vm.clearFilter },
              },
              [
                _c("Icon", {
                  staticClass: "icon-clear-filter",
                  attrs: { name: "icon-clear-filter" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "show-more" }, [
              _c("div", { staticClass: "Form-item dropdown-handle" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.closeOtherOptions,
                        expression: "closeOtherOptions",
                      },
                    ],
                    staticClass: "select dropdown-handle-button",
                    attrs: {
                      "aria-expanded": _vm.showOtherOptions,
                      tabindex: "0",
                    },
                    on: {
                      keydown: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.toggleOtherOptions.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          $event.preventDefault()
                          return _vm.toggleOtherOptions.apply(null, arguments)
                        },
                      ],
                      click: _vm.toggleOtherOptions,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "dropdown-handle-button-values" },
                      [_vm._v(_vm._s(_vm.$t("Global.moreOptions")))]
                    ),
                    _c("Icon", {
                      staticClass:
                        "dropdown-handle-button-icon icon icon-down-arrow",
                      class: { open: _vm.showOtherOptions },
                      attrs: { name: "icon-down-arrow" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.showOtherOptions
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "shortkey",
                          rawName: "v-shortkey",
                          value: ["esc"],
                          expression: "['esc']",
                        },
                      ],
                      staticClass: "MiniPopup left",
                    },
                    [
                      _c("CustomCheckbox", {
                        attrs: {
                          id: "checkbox-sales-show-condiments",
                          checked: _vm.filters.onlyGlobalSuppliers,
                          label: _vm.$t(
                            "PriceQuota.Checkbox_Show_Only_Global_Suppliers"
                          ),
                        },
                        on: { change: _vm.changeOnlyGlobalSuppliers },
                      }),
                      _c("CustomCheckbox", {
                        staticClass: "show-passive-recipes",
                        attrs: {
                          id: "checkbox-price-quotes-show-passive",
                          checked: _vm.showPassive,
                          label: _vm.$t("PriceQuota.showPassivePriceQuotes"),
                        },
                        on: { change: _vm.showPassivePriceQuotes },
                      }),
                      _c("CustomCheckbox", {
                        staticClass: "show-passive-recipes",
                        attrs: {
                          id: "checkbox-price-quotes-show-with-base-currency",
                          checked: _vm.showWithBaseCurrency,
                          label: _vm.$t("PriceQuota.showWithBaseCurrency"),
                        },
                        on: { change: _vm.showBaseCurrency },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            staticClass: "m-right-10",
            attrs: {
              componentName: "price-quote",
              activeImportExports: ["import", "export"],
              importUrl: "PriceQuote/import",
              downloadUrl: "PriceQuote/export",
              downloadFileType: "xlsx",
              downloadCustomPopup: true,
              downloadFileName: _vm.excelFileName,
            },
            on: {
              importFileSuccess: _vm.goImportedPriceQuotes,
              downloadClick: _vm.openExcelPopup,
              toggleOtherOptions: _vm.closeImportExportPopup,
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeExcelPopup,
                  expression: "closeExcelPopup",
                },
              ],
              staticClass: "download-excel-popup",
              class: _vm.excelPopup ? "show-excel-popup" : null,
            },
            [
              _c("h2", { staticClass: "download-excel-popup-header" }, [
                _vm._v(
                  _vm._s(_vm.$t("PriceQuota.Export_Excel_Field_Supplier"))
                ),
              ]),
              _c("custom-search", {
                ref: "firstInput",
                staticClass: "download-excel-popup-search",
                attrs: {
                  tabIndex: "1",
                  name: "supplier",
                  "on-search": _vm.getOptions,
                  options: _vm.selectOptions,
                  label: "name",
                  placeholder: _vm.$t(
                    "PriceQuota.popup_FormField_SupplierName_Placeholder"
                  ),
                },
                model: {
                  value: _vm.item.supplier,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "supplier", $$v)
                  },
                  expression: "item.supplier",
                },
              }),
              _c(
                "Button",
                {
                  staticClass: "download-excel-popup-button",
                  attrs: {
                    tabIndex: "2",
                    primary: "",
                    iconName: "icon-btn-success",
                    disabled: !this.item.supplier || !this.excelPopup,
                    id: "excel-popup-submit-button",
                  },
                  on: { click: _vm.downloadExcel },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "PriceQuota.Excel_Popup_Submit_Supplier_Button_Text"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeBarcodeOperationsPopup,
                  expression: "closeBarcodeOperationsPopup",
                },
              ],
              staticClass: "generate-barcode m-right-10",
              attrs: {
                size: "small",
                secondary: "",
                id: "change-price-quote-multiple-barcode-generation-button",
                disabled: !_vm.selectedList.length,
              },
              on: { click: _vm.toggleBarcodeOperations },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("Stocks.barcodeOperations")))]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: () => _vm.closeBarcodeOperationsPopup,
                      expression: "() => closeBarcodeOperationsPopup",
                    },
                  ],
                  staticClass: "barcode-selection-popup",
                  class: _vm.showBarcodeOperationsPopup
                    ? "show-barcode-operation-popup"
                    : null,
                },
                [
                  _c("h2", { staticClass: "barcode-selection-popup-header" }, [
                    _vm._v(_vm._s(_vm.$t("Stocks.multipleBarcode"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "barcode-selection-popup-contents m-top-10",
                    },
                    [
                      _c("CustomCheckbox", {
                        staticClass: "change-price-type m-top-5",
                        attrs: {
                          id: "price-quote-weighted-barcode-generation",
                          label: _vm.$t("Stocks.weightedBarcode"),
                          checked: _vm.isWeightedBarcode,
                        },
                        on: { change: _vm.changeBarcodeType },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isWeightedBarcode,
                            expression: "isWeightedBarcode",
                          },
                        ],
                        attrs: {
                          errorName: _vm.$t("Stocks.warning_weightedBarcode"),
                        },
                      }),
                      _c(
                        "Button",
                        {
                          staticClass: "m-top-15",
                          attrs: {
                            primary: "",
                            size: "small",
                            id: "price-quote-barcode-generation",
                            disabled: !_vm.selectedList.length,
                          },
                          on: { click: _vm.generateBarcode },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Stocks.toolbar_SubmitBarcode_ButtonText"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeActivationStatusPopup,
                  expression: "closeActivationStatusPopup",
                },
              ],
              staticClass: "change-activation-status m-right-10",
              attrs: {
                size: "small",
                secondary: "",
                id: "change-multiple-price-type-button",
                disabled: !_vm.selectedList.length,
              },
              on: { click: _vm.changeActivationStatus },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("PriceQuota.toolbar_updateActivationStatus"))
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "price-type-selection-popup",
                  class: _vm.showActivationStatusSelectionPopup
                    ? "show-price-type-popup"
                    : null,
                },
                [
                  _c(
                    "h2",
                    { staticClass: "price-type-selection-popup-header" },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("PriceQuota.selectActivationStatus"))
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "radio-button-contents m-top-10" },
                    [
                      _c("CustomRadio", {
                        staticClass: "change-price-type m-top-5",
                        attrs: {
                          id: `radio-price-quote-make-active`,
                          label: _vm.$t("PriceQuota.makeActive"),
                          checked: _vm.activationStatus,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeActivationType(true)
                          },
                        },
                      }),
                      _c("CustomRadio", {
                        staticClass: "change-price-type m-top-5",
                        attrs: {
                          id: `radio-price-quote-make-passive`,
                          label: _vm.$t("PriceQuota.makePassive"),
                          checked: !_vm.activationStatus,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeActivationType(false)
                          },
                        },
                      }),
                      _c(
                        "Button",
                        {
                          staticClass: "m-top-10",
                          attrs: {
                            primary: "",
                            size: "small",
                            id: "change-price-type-activation-status",
                            disabled: !_vm.selectedList.length,
                          },
                          on: { click: _vm.updatePriceQuoteActivationStatus },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "PriceQuota.toolbar_FilterSupplier_ButtonText"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closePriceTypeSelectionPopup,
                  expression: "closePriceTypeSelectionPopup",
                },
              ],
              staticClass: "price-type-selection",
              attrs: {
                secondary: "",
                size: "small",
                id: "change-multiple-price-type-button",
                disabled: !_vm.selectedList.length,
                isLoading: _vm.isPriceTypeUpdating,
              },
              on: { click: _vm.openPriceTypeSelection },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("PriceQuota.toolbar_updatePriceType"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "price-type-selection-popup",
                  class: _vm.showPriceTypeSelectionPopup
                    ? "show-price-type-popup"
                    : null,
                },
                [
                  _c(
                    "h2",
                    { staticClass: "price-type-selection-popup-header" },
                    [_vm._v(_vm._s(_vm.$t("PriceQuota.selectPriceType")))]
                  ),
                  _c(
                    "div",
                    { staticClass: "radio-button-contents m-top-10" },
                    [
                      _c("CustomRadio", {
                        staticClass: "change-price-type m-top-5",
                        attrs: {
                          id: `radio-price-quote-price-type-1`,
                          label: _vm.$t("PriceQuota.PriceType_Dynamic"),
                          checked: _vm.priceType === 1,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePriceType(1)
                          },
                        },
                      }),
                      _c("CustomRadio", {
                        staticClass: "change-price-type m-top-5",
                        attrs: {
                          id: `radio-price-quote-price-type-2`,
                          label: _vm.$t("PriceQuota.PriceType_Fixed"),
                          checked: _vm.priceType === 2,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePriceType(2)
                          },
                        },
                      }),
                      _c("CustomRadio", {
                        staticClass: "change-price-type m-top-5",
                        attrs: {
                          id: `radio-price-quote-price-type-3`,
                          label: _vm.$t("PriceQuota.PriceType_FixedOffer"),
                          checked: _vm.priceType === 3,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePriceType(3)
                          },
                        },
                      }),
                      _c(
                        "Button",
                        {
                          staticClass: "m-top-10",
                          attrs: {
                            primary: "",
                            size: "medium",
                            id: "change-multiple-price-type",
                            isLoading: _vm.isPriceTypeUpdating,
                            disabled: !_vm.selectedList.length,
                          },
                          on: { click: _vm.updatePriceTypes },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("PriceQuota.toolbar_updatePriceType")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading || _vm.isPageLoading ? _c("TableLoading") : _vm._e(),
          !_vm.isLoading && !_vm.isEmpty && !_vm.isPageLoading
            ? _c("TableView", {
                attrs: {
                  uniqueIdentifier: "id",
                  fields: _vm.fields,
                  data: _vm.decoratedPriceQuoteList,
                  selectable: "checkbox",
                  selectableAll: "checkbox",
                  showCheckboxForPassives: true,
                  page: _vm.page,
                  actions: ["edit"],
                  addScrollToNoFixedColumns: true,
                  selectedRows: _vm.selectedList,
                },
                on: {
                  showAllSelectedItems: _vm.selectAll,
                  delegateOnItemSelect: _vm.onSelect,
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemEdit: _vm.editPriceQuote,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "isPreferred" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.isActive
                        ? _c("Button", {
                            staticClass: "preferred-status",
                            attrs: {
                              size: "small",
                              iconName: row.isPreferred
                                ? "icon-vote-star-filled"
                                : "icon-vote-star-empty",
                              clearVariant: true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updatePriceQuotePreferrenceState(row)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "priceType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "Button",
                        {
                          staticClass: "price-type",
                          class: _vm.getPriceTypeClass(row.priceType),
                          attrs: { id: `price-quote-price-type-text` },
                        },
                        [_vm._v(_vm._s(_vm.getPriceType(row.priceType)))]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          !_vm.isLoading && _vm.isEmpty
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("PriceQuota.emptyState_Title"),
                  description: _vm.$t("PriceQuota.emptyState_Description"),
                  buttonText: _vm.$t("PriceQuota.emptyState_ButtonText"),
                },
                on: { emptyAddButton: _vm.createNewPriceQuote },
              })
            : _vm._e(),
          _c("router-view", {
            on: { getList: _vm.getPriceQuoteListWithParams },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }