<template lang="pug">
.Despatches(:id="`invoice-details-${headerIndex}`")
    .Despatches-single-table-header(:id="`invoice-details-header-${headerIndex}`")
      h6.title.u-textRight(:id="`invoice-${headerIndex}-detail-head-0`") {{ $t('Despatches.tableColumn_DespatchDate') }}
      h6.title.u-textRight(:id="`invoice-${headerIndex}-detail-head-1`") {{ $t('Despatches.tableColumn_DespatchNumber') }}
      h6.title.u-textRight(:id="`invoice-${headerIndex}-detail-head-2`") {{ $t('Despatches.tableColumn_NetTotalAmount') }}
      h6.title.u-textRight(:id="`invoice-${headerIndex}-detail-head-3`") {{ $t('Despatches.tableColumn_TaxTotalAmount') }}
      h6.title.u-textRight(:id="`invoice-${headerIndex}-detail-head-4`") {{ $t('Despatches.tableColumn_GrossTotalAmount') }}

    .Despatches-item(
      v-for="(despatch, index) in tableData",
      :id="`invoice-${headerIndex}-detail-item-${index}`"
      :class="{ 'isOpened': despatch.showDetail , 'small-size': true }")

      .Despatches-header(:id="`invoice-${headerIndex}-detail-item-date-${index}`")

        .Despatches-header-col.inv-date.cell(:id="`invoice-${headerIndex}-detail-item-date-buffer-${index}`")
          .body.u-textRight(:id="`invoice-${headerIndex}-detail-item-date-text-${index}`") {{ $options.filters.dateFormat(despatch.despatchDate) || '-' }}

        .Despatches-header-col.inv-number.cell(:id="`invoice-${headerIndex}-detail-item-inv-number-${index}`")
          .body.u-textRight(:id="`invoice-${headerIndex}-detail-item-inv-number-text-${index}`")    {{ despatch.despatchNumber || '-' }}

        .Despatches-header-col.net.cell(:id="`invoice-${headerIndex}-detail-item-net-${index}`")
          .body.ff-mono.u-textRight(:id="`invoice-${headerIndex}-detail-item-net-text-${index}`") {{ despatch.netTotalAmount | formatCurrency }}

        .Despatches-header-col.tax.cell(:id="`invoice-${headerIndex}-detail-item-tax-${index}`")
          .body.ff-mono.u-textRight(:id="`invoice-${headerIndex}-detail-item-tax-text-${index}`") {{ despatch.taxTotalAmount | formatCurrency }}

        .Despatches-header-col.gross.cell(:id="`invoice-${headerIndex}-detail-item-gross-${index}`")
          .body.ff-mono.u-textRight(:id="`invoice-${headerIndex}-detail-item-gross-text-${index}`") {{ despatch.grossTotalAmount | formatCurrency }}

        .Despatches-header-col.description
          Button.show-MiniPopup.relative.width-max-content(
            v-if="despatch.description",
            size="small"
            variant="info"
            :id="`despatch-header-info-button-${index}`"
            iconName="icon-global-info"
            :justIcon="true")
            .MiniPopup-right-primary.m-top-15.c-dark
              span {{ despatch.description }}

        .Despatches-header-col.action(:id="`invoice-${headerIndex}-detail-item-action-${index}`")
          .body(:id="`invoice-${headerIndex}-detail-item-action-body-${index}`")
            Button.btn-detail-switch(
            variant="icon switch"
            :id="`btn-despatches-open-header-${despatch.id}`"
            iconName="icon-arrow-down"
            iconClass="icon-arrow-down"
            @click="showDespatchDetail(despatch)")


      .Despatches-details(v-if="despatch.showDetail" :id="`invoice-${headerIndex}-despatch-detail-item-${index}`")
        .table-scroll
          table(:id="`invoice-${headerIndex}-despatch-detail-item-table-${index}`")
            thead.Despatches-details-head(:id="`invoice-${headerIndex}-despatch-detail-item-table-head-${index}`")
              tr.Despatches-details-head-row(:id="`invoice-${headerIndex}-despatch-detail-item-table-row${index}`")
                th.Despatches-details-head-col.stockItemName(:id="`invoice-${headerIndex}-despatch-detail-item-stock-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-stock-text-${index}`") {{ $t('Despatches.detail_TableColumn_StockItemName') }}
                th.Despatches-details-head-col.quantity.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-quantity-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-quantity-text-${index}`") {{ $t('Despatches.detail_TableColumn_Quantity') }}
                th.Despatches-details-head-col.unitCode.u-textCenter(:id="`invoice-${headerIndex}-despatch-detail-item-unit-code-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-unit-code-text-${index}`") {{ $t('Despatches.detail_TableColumn_UnitCode') }}
                th.Despatches-details-head-col.netUnitPrice.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-net-unit-price-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-net-unit-price-text-${index}`") {{ $t('Despatches.detail_TableColumn_NetUnitPrice') }}
                th.Despatches-details-head-col.baseQuantity.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-base-quantity-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-base-quantity-text-${index}`") {{ $t('Despatches.detail_TableColumn_BaseQuantity') }}
                th.Despatches-details-head-col.baseUnit(:id="`invoice-${headerIndex}-despatch-detail-item-base-unit-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-base-unit-text-${index}`") {{ $t('Despatches.detail_TableColumn_BaseUnit') }}
                th.Despatches-details-head-col.basePrice.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-base-price-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-base-price-text-${index}`") {{ $t('Despatches.detail_TableColumn_BasePrice') }}
                th.Despatches-details-head-col.discount1.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-discount-1-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-discount-1-text-${index}`") {{ $t('Despatches.detail_TableColumn_Discount1') }}
                th.Despatches-details-head-col.discount2.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-discount-2-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-discount-2-text${index}`") {{ $t('Despatches.detail_TableColumn_Discount2') }}
                th.Despatches-details-head-col.discountedUnitPrice.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-discounted-unit-price-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-discounted-unit-price-text-${index}`") {{ $t('Despatches.detail_TableColumn_DiscountedUnitPrice') }}
                th.Despatches-details-head-col.discountedBaseUnitPrice.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-discounted-base-unit-price-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-discounted-base-unit-price-text-${index}`") {{ $t('Despatches.detail_TableColumn_DiscountedBaseUnitPrice') }}
                th.Despatches-details-head-col.taxRatio.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-tax-ratio-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-tax-ratio-text-${index}`") {{ $t('Despatches.detail_TableColumn_TaxRatio') }}
                  th.Despatches-details-head-col.discountedNetUnitPrice.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-discounted-net-unit-price-${index}`")
                    h6(:id="`invoice-${headerIndex}-despatch-detail-item-discounted-net-unit-price-text-${index}`") {{ $t('Despatches.detail_TableColumn_DiscountedNetUnitPrice') }}
                th.Despatches-details-head-col.netAmount.u-textRight(:id="`invoice-${headerIndex}-despatch-detail-item-net-amount-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-net-amount-text-${index}`") {{ $t('Despatches.detail_TableColumn_NetAmount') }}
                th.Despatches-details-head-col.storeName(:id="`invoice-${headerIndex}-despatch-detail-item-store-${index}`")
                  h6(:id="`invoice-${headerIndex}-despatch-detail-item-store-text-${index}`") {{ $t('Despatches.detail_TableColumn_StoreName') }}

            tbody.Despatches-details-body
              tr.Despatches-details-body-row(
                v-for="(detail, despatchIndex) in despatch.despatchDetailList"
                tabindex="0")

                td.Despatches-details-body-col.stockItemName(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-stock-item-${despatchIndex}`")
                  | {{ detail.stockItem.name }}
                td.Despatches-details-body-col.quantity.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-quantity-${despatchIndex}`")
                  | {{ detail.quantity }}
                td.Despatches-details-body-col.baseUnitCode.u-textCenter(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-base-unit-code-${despatchIndex}`")
                  | {{ findUnit(detail.unitId).name }}
                td.Despatches-details-body-col.netUnitPrice.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-net-unit-price-${despatchIndex}`") {{ detail.unitPrice | formatCurrency }}
                td.Despatches-details-body-col.baseQuantity.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-base-quantity-${despatchIndex}`")
                  | {{ detail.baseQuantity }}
                td.Despatches-details-body-col.baseUnitCode(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-base-unit-code-${despatchIndex}`")
                  | {{ detail.stockItem.baseUnitCode }}
                td.Despatches-details-body-col.basePrice.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-base-price-${despatchIndex}`") {{ detail.netBaseUnitPrice | formatCurrency }}
                td.Despatches-details-body-col.discount1.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-discount-1-${despatchIndex}`")
                  | % {{ detail.discount1 | percentFixed }}
                td.Despatches-details-body-col.discount2.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-discount-2-${despatchIndex}`")
                  | % {{ detail.discount2 | percentFixed }}
                td.Despatches-details-body-col.discountedUnitPrice.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-discounted-unit-price-${despatchIndex}`") {{ detail.discountedUnitPrice | formatCurrency }}
                td.Despatches-details-body-col.DiscountedBaseUnitPrice.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-discounted-base-unit-prie-${despatchIndex}`") {{ detail.discountedBaseUnitPrice | formatCurrency }}
                td.Despatches-details-body-col.taxRatio.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-tax-ratio-${despatchIndex}`")
                  | % {{ detail.taxRatio | percentFixed }}
                td.Despatches-details-body-col.discountedNetUnitPrice.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-discounted-net-unit-price-${despatchIndex}`") {{ detail.discountedNetUnitPrice | formatCurrency }}
                td.Despatches-details-body-col.netAmount.ff-mono.u-textRight(:id="`invoice-${headerIndex}-despatch-${index}-detail-col-net-amount-${despatchIndex}`") {{ detail.netAmount | formatCurrency }}
                td.Despatches-details-body-col.storeName {{ detail.storeName }}

</template>

<script>
import Details from './despatches-detail'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'InvoiceDetails',

  components: {
    Details
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    headerIndex: {
      type: Number || String,
      default: ''
    }
  },
  created () {

  },
  computed: {
    ...mapGetters('Units', [
      'findUnit'
    ])
  },
  methods: {
    ...mapMutations('Despatch', [
      'SHOW_DESPATCH_IN_INVOICE_DETAIL',
      'HIDE_DESPATCH_IN_INVOICE_DETAIL'
    ]),

    ...mapActions('Despatch', [
      'get_DESPATCH'
    ]),

    showDespatchDetail (despatch) {
      if (despatch.showDetail) {
        this.HIDE_DESPATCH_IN_INVOICE_DETAIL(despatch)
      } else {
        this.get_DESPATCH(despatch.id).then(res => {
          if (res.status === 200) {
            this.SHOW_DESPATCH_IN_INVOICE_DETAIL(res.data)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .Despatches {
    background: $color-lighter-blue;
    .btn-detail-switch {
      color: $color-success;
    }

    &-item.isOpened .btn-detail-switch {
      transform: rotate(180deg);
    }

    &-item {
      border-top: 1px solid $color-gray;
      border-radius: $border-radius;
      line-height: 1;
      & + & {
        margin-top: 10px;
      }
    }

    &-header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;

      .Despatches-item.isOpened & {
        background-color: $color-ocean;
      }
      &-row {
        padding: 0;
        display: flex;
        width: 100%;
        align-items: center;
        // svg {
        //   flex-shrink: 0;
        //   margin-right: 7px;
        //   width: 21px;
        //   height: 21px;
        // }
      }
      &-col {
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        .title {
          font-size: $font-size-micro;
          color: $color-success;
          margin-bottom: 6px;
        }

        .body.ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.status {
          padding: 0;
          width: 15px;
          position: absolute;
          // svg {
          //   position: absolute;
          //   left: 0;
          //   top: 50%;
          //   transform: translate(-50%, -50%);
          // }

          div {
            display: none;
            z-index: 2;
            position: relative;
            left: 0;
            min-width: 150px;
            margin-top: 10px;
            justify-content: center;
            font-size: $font-size-small;
            transform: translateX(-50%) translateY(100%);
            background-color: white;
            box-shadow: 0 0 0 2px rgba($color-warning, 1);
            border-radius: $border-radius;
            padding: 10px;
            &::after, &::before {
              content: '';
              position: absolute;
              bottom: 100%;
              transform: translateX(calc(50% - 10px));
              pointer-events: none;
            }
            &::before {
              border: 8px solid;
              border-color: transparent transparent $color-warning transparent;
            }
          }

          // svg:hover + div {
          //   display: flex;
          // }
        }

        &.name {
          font-weight: $font-weight-bold;
          margin-left: 15px;
        }

        &.cell {
          width: 16%;
          flex-shrink: 0;
        }

        &.description {
          margin-left: 30px;
        }
      }
    }

    &-details {
      padding: 20px;
    }

    &-header .action {
      opacity: 0;
      transition: opacity $transition;
    }

    &-header:hover > .action,
    &-header:focus-within > .action {
      opacity: 1;
    }

    &-details {
      border-top: 1px solid $color-gray;
      padding: 20px;

      &-head,
      &-body {
        &-row {
          cursor: ew-resize;
        }
        &-col {
          background: $color-lighter-blue;
          &:first-child {
            position: sticky;
            left: -1px;
            z-index: $z-index-xs;
          }
        }
      }

      &-head {
        &-col {
          padding: 7px 15px;
          h6 {
            font-size: $font-size-micro;
            color: $color-success;
            white-space: nowrap;
            margin-bottom: 6px;
          }
        }
      }

      &-body {
        margin-top: 10px;
        margin-bottom: 20px;

        &-row {
          border-top: 1px solid $color-gray;
          border-radius: $border-radius;
          line-height: 1;
          align-items: center;

          & + & {
            margin-top: 10px;
          }
        }

        &-col {
          padding: 15px 15px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &.action {
            justify-content: flex-end;
            opacity: 0;
            transition: opacity $transition;
            .body {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      &-foot {
        display: flex;
        justify-content: flex-end;

        .btn {
          margin-left: 20px;
        }
      }

      &-body-row:focus-within > .action,
      &-body-row:hover > .action {
        opacity: 1;
      }
    }
  }

  .table-scroll {
    overflow-y: scroll;
    margin-bottom: 20px;
  }

  .Despatches-single-table-header {
    display: flex;

    h6 {
      font-size: $font-size-micro;
      color: $color-success;
      width: 16%;
      padding: 15px 15px 7px 15px;
      text-align: center;
    }
  }
</style>
