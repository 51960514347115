var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.InventoryInfo.status === 1,
              expression: "InventoryInfo.status === 1",
            },
          ],
          slot: "header-left-search",
        },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: { componentName: "Inventory", isSuggestionLoading: false },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-breadcrumb-left" }, [
        _c(
          "div",
          { staticClass: "Breadcrumb tooltip-relative" },
          [
            _c(
              "router-link",
              {
                staticClass: "c-light",
                attrs: {
                  id: "link-inventory-breadcrumb",
                  to: { name: "Inventories" },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Inventory.breadCrumb_Inventory")))]
            ),
            _c("span", [_vm._v(_vm._s(">"))]),
            _c(
              "a",
              {
                staticClass: "isSelected",
                attrs: { href: "", id: "link-inventory-breadcrumb-active" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.InventoryInfo.status === 1
                      ? _vm.getName(_vm.inventoryBreadCrumb)
                      : _vm.inventoryBreadCrumb
                  )
                ),
              ]
            ),
            _vm.inventoryBreadCrumb.length > 50 &&
            _vm.InventoryInfo.status === 1
              ? _c("Tooltip", {
                  attrs: {
                    white: "",
                    bottom: "",
                    text: _vm.inventoryBreadCrumb,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("span", { staticClass: "inventory-type c-light" }, [
          _vm._v(
            _vm._s(
              _vm.inventory_Header?.type == 1
                ? _vm.$t("Inventories.inventoryTypePeriodic")
                : _vm.$t("Inventories.inventoryTypeIntermediate")
            )
          ),
        ]),
      ]),
      _vm.InventoryInfo.status === 1
        ? _c(
            "template",
            { slot: "header-right-dropdown" },
            [
              _vm.hasPermissionToCloseInventory
                ? _c(
                    "Button",
                    {
                      attrs: {
                        size: "medium",
                        primary: "",
                        type: "button",
                        id: "btn-inventory-close",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.close(_vm.inventory_Detail)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("Inventory.closeInventoryButton"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.InventoryInfo.status === 1
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              !_vm.isStoreTypeCostCenter
                ? _c(
                    "Button",
                    {
                      attrs: {
                        disabled: _vm.isOnlyListUsage,
                        size: "medium",
                        secondary: "",
                        type: "button",
                        id: "btn-add-stock-item",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addStockItem(_vm.inventory_Detail)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("Inventory.addStockItemButton"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.InventoryInfo.status == 1
        ? _c(
            "template",
            { slot: "toolbar-left" },
            [
              !_vm.isOnlyListUsage
                ? _c(
                    "router-link",
                    {
                      staticClass: "tabLink isSelected",
                      attrs: {
                        id: "toggle-group-0",
                        to: {
                          name: "Inventory",
                          params: {
                            id: _vm.$route.params.id,
                            status: _vm.$route.params.status,
                            storeName: _vm.$route.params.storeName,
                            inventoryDate: _vm.$route.params.inventoryDate,
                          },
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_all")))]
                  )
                : _vm._e(),
              _c(
                "router-link",
                {
                  staticClass: "tabLink",
                  attrs: {
                    id: "toggle-group-1",
                    to: {
                      name: "Inventorylists",
                      params: {
                        id: _vm.$route.params.id,
                        status: _vm.$route.params.status,
                        storeName: _vm.$route.params.storeName,
                        inventoryDate: _vm.$route.params.inventoryDate,
                      },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_list")))]
              ),
              !_vm.isOnlyListUsage
                ? _c(
                    "router-link",
                    {
                      staticClass: "tabLink",
                      attrs: {
                        id: "toggle-group-2",
                        to: {
                          name: "InventoryRecipe",
                          params: { id: _vm.$route.params.id },
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_recipe")))]
                  )
                : _vm._e(),
              !_vm.isOnlyListUsage
                ? _c(
                    "router-link",
                    {
                      staticClass: "tabLink",
                      attrs: {
                        id: "toggle-group-3",
                        to: {
                          name: "InventoryShelf",
                          params: { id: _vm.$route.params.id },
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_shelf")))]
                  )
                : _vm._e(),
              !_vm.isOnlyListUsage &&
              _vm.inventory_Header?.expirationDateControlIsEnabled
                ? _c(
                    "router-link",
                    {
                      staticClass: "tabLink",
                      attrs: {
                        id: "toggle-group-4",
                        to: {
                          name: "InventoryExpireDate",
                          params: { id: _vm.$route.params.id },
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_expire_date")))]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.InventoryInfo.status == 1
        ? _c(
            "template",
            { slot: "toolbar-right" },
            [
              _c("ImportExport", {
                ref: "wrapper",
                attrs: {
                  componentName: "Inventory",
                  isDisabled: _vm.isLoading,
                  activeImportExports: ["export", "template"],
                  templateUrl: _vm.downloadExcelUrl,
                  templateFileName: _vm.$t("Inventory.downloadTemplate"),
                  templateDisabled: !_vm.InventoryInfo.status === 1,
                  templateFileType: "xlsx",
                  templateMethod: "GET",
                  downloadUrl: _vm.downloadUrl,
                  downloadDisabled: !(
                    _vm.InventoryInfo.status === 1 && !_vm.isStoreTypeCostCenter
                  ),
                  downloadFileType: "xlsx",
                  downloadParams: _vm.downloadParams,
                  downloadFileName: _vm.inventoryBreadCrumb,
                  massecInventoryInfo: _vm.InventoryInfo,
                  massecPage: "inventory",
                },
              }),
              !_vm.isStoreTypeCostCenter
                ? _c("ImportMassec", {
                    staticClass: "m-left-10",
                    attrs: {
                      inventoryInfo: _vm.InventoryInfo,
                      page: "inventory",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.InventoryInfo.status !== 1
        ? _c(
            "template",
            { slot: "no-content" },
            [
              _c("ClosedInventory", {
                on: {
                  itemSorting: _vm.sortItem,
                  includeWasteInConsumption: _vm.includeWasteInConsumption,
                },
              }),
            ],
            1
          )
        : _c(
            "template",
            { slot: "content-center" },
            [
              _vm.isLoading ? _c("TableLoading") : _vm._e(),
              !_vm.isEmpty && !_vm.isLoading
                ? [
                    _vm.inventory_Detail.list.length > 0
                      ? _c(
                          "div",
                          { staticClass: "InventoryDetailList" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "InventoryDetailList-table",
                                attrs: { id: "table-inventory-detail" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "head",
                                    attrs: {
                                      id: "table-inventory-detail-head",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        attrs: {
                                          id: "table-inventory-detail-head-row",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col largeCol sortable-title",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-0",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "Inventory.detailList_TableColumn_ItemGroupName"
                                                  )
                                                )
                                            ),
                                            _c("ToggleSorting", {
                                              staticClass: "p-left-5",
                                              attrs: {
                                                item: _vm.sortingItems[0],
                                              },
                                              on: { sort: _vm.sort },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col largeCol sortable-title",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-1",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Inventory.detailList_TableColumn_StockItemName"
                                                )
                                              )
                                            ),
                                            _c("ToggleSorting", {
                                              staticClass: "p-left-5",
                                              attrs: {
                                                item: _vm.sortingItems[1],
                                              },
                                              on: { sort: _vm.sort },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col u-textRight",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-1",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Inventory.detailList_TableColumn_ExpectedQuantity"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col u-textRight",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-2",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Inventory.detailList_TableColumn_Unit"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col u-textRight",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-3",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Inventory.detailList_TableColumn_UnitQuantityInput"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col u-textRight",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-4",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Inventory.detailList_TableColumn_UnitQuantity"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col u-textRight",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-5",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Inventory.detailList_TableColumn_ActualQuantityTotal"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col u-textRight",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-6",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Inventory.detailList_TableColumn_ActualQuantityDifference"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col u-textRight",
                                            attrs: {
                                              id: "table-inventory-detail-head-col-7",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Inventory.detailList_TableColumn_ActualQuantityDifferenceRatio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "body",
                                    attrs: {
                                      id: "table-inventory-detail-body",
                                    },
                                  },
                                  _vm._l(
                                    _vm.inventory_Detail.list,
                                    function (detail, index) {
                                      return _vm.inventory_Detail.list.length >
                                        0
                                        ? _c("row", {
                                            key: detail.stockItemName,
                                            attrs: {
                                              id: `table-inventory-detail-body-row-${index}`,
                                              detail: detail,
                                              index: index,
                                            },
                                            on: {
                                              changeUnitCount:
                                                _vm.onChangeDetailUnitCount,
                                            },
                                          })
                                        : _vm._e()
                                    }
                                  ),
                                  1
                                ),
                              ]
                            ),
                            _c("TableFoot", {
                              attrs: { page: _vm.inventoryPage },
                              on: {
                                delegateOnChangePage: _vm.changePage,
                                delegateOnChangePageSize: _vm.changePageSize,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c("router-view", {
                on: {
                  getList: _vm.getInventory,
                  successImportAction: _vm.getInventory,
                },
              }),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }