var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-left-dropdown" }, [_c("div")]),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingData && _vm.isFetchingNutrition
            ? _c("TableLoading")
            : [
                _vm._l(_vm.integrationProducts, function (integration, index) {
                  return _c(
                    "div",
                    [
                      _vm.renderComponent &&
                      !_vm.isFetchingData &&
                      !_vm.isFetchingNutrition
                        ? _c("IntegrationItems", {
                            attrs: {
                              list: integration.list,
                              integrationTitle:
                                integration.title ||
                                _vm.$t("Integration.nutritionalValues"),
                            },
                            on: {
                              activateNutrition: _vm.changeWarningPopupStatus,
                              activateProduct: _vm.toggleProductPermission,
                              deActivateIBar: _vm.deActivateIBar,
                              openActionPopup: _vm.openPopup,
                              closeActionPopup: _vm.closeActionPopup,
                              activateModal: _vm.activateModal,
                              closeModal: _vm.closeModal,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "nutrition-container" },
                  [
                    _vm.showChangingWarning
                      ? _c("Alert", {
                          attrs: {
                            title: _vm.$t(
                              "Integration.Warning_changingNutritionData"
                            ),
                            description: _vm.$t(
                              "Integration.description_changingNutritionData"
                            ),
                            okButton: _vm.$t("Global.change"),
                            icon: "icon-global-notify",
                          },
                          on: {
                            delegateOnAlertOk: _vm.showWarning,
                            delegateOnAlertCancel: _vm.closePopup,
                          },
                        })
                      : _vm._e(),
                    _vm.showPassiveWarning
                      ? _c("Alert", {
                          attrs: {
                            title: _vm.$t(
                              "Integration.Warning_passiveNutritionData"
                            ),
                            description: _vm.$t(
                              "Integration.description_passiveNutritionData"
                            ),
                            okButton: _vm.$t("Global.passive"),
                            icon: "icon-global-notify",
                          },
                          on: {
                            delegateOnAlertOk: _vm.showWarning,
                            delegateOnAlertCancel: _vm.closePopup,
                          },
                        })
                      : _vm._e(),
                    _vm.showLastWarning
                      ? _c("Alert", {
                          staticClass: "last-alert",
                          attrs: {
                            title: _vm.$t("Integration.Warning"),
                            description: _vm.$t(
                              "Integration.warning_DeleteAll"
                            ),
                            okButton: _vm.$t("Global.deleteAlert_DeleteButton"),
                            icon: "icon-global-notify",
                          },
                          on: {
                            delegateOnAlertOk: _vm.updateNutritionSettings,
                            delegateOnAlertCancel: _vm.closePopup,
                          },
                        })
                      : _vm._e(),
                    _vm.showFirstSelectWarning
                      ? _c("Alert", {
                          staticClass: "first-alert",
                          attrs: {
                            title: _vm.$t("Integration.Warning"),
                            description: _vm.$t("Integration.selectDataSet"),
                            okButton: _vm.$t("Global.alert_OkButton"),
                            icon: "icon-global-notify",
                          },
                          on: {
                            delegateOnAlertOk: _vm.updateNutritionSettings,
                            delegateOnAlertCancel: _vm.closePopup,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }