<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-left-search")
    Search.search-box(
      componentName="orders",
      :isSuggestionLoading="isSuggestionLoading"
      :suggestions="selectedStockItem"
      :customSuggestionPlaceHolder="$t('Orders.orders_search_Placeholder')"
      :customSuggestionTitle="$t('Orders.orders_search_SuggestionTitle')"
      @onChangeSearchValue="onChangeSearch"
      @onChangeQueryItem="setStockItem"
      @clearSearch="clearSearch"
    )
  template(slot="header-right-dropdown")
    .right(v-if="hasTabPermission")
      .form-item-select.date-start(
        id="date-start-request-order-list"
      )
        CustomDatepicker(
          v-model="dates",
          :isLoading="$wait.is('getDateFilterSettings')"
          id="custom-datepicker-request-order-list"
          :range="true")

  template(slot="toolbar-left" v-if="hasTabPermission")
    storeFilter(
      @submitFilter="getListWithStoreFilter"
      ref="orderedStoreFilter")

    supplierFilter(
      @submitFilter="getListWithSupplierFilter"
      ref="orderedSupplierFilter")

    Button.clear-filter-button.border-gray-1.p-0.width-auto.m-right-10(
    @click='clearFilter'
    )
      Icon.icon-clear-filter(name="icon-clear-filter")

    CustomCheckbox(
      :label="$t('Orders.showClosedOrdered')"
      :checked="showClosedOrdered"
      @change="toggleShowClosedOrdered")

  template(slot="toolbar-right" v-if="hasTabPermission")
    ImportExport.m-right-15(
      componentName="Despatch-Ordered"
      ref="wrapper"
      :isDisabled="isFetchingPageData"
      :activeImportExports=['export']
      downloadUrl="Order/orderdetail/export"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="downloadFileName"
    )
    Button.m-right-15(
        secondary,
        size="small"
        id="btn-orders-transform-despatch"
        :disabled="selectedClosedHeaders.length === 0",
        @click.stop="() => showReasonPopUp = true"
      )
      span.font-weight-w-normal.font-size-small {{ showClosedOrdered ? $t('Orders.openedOrdered') : $t('Orders.closedOrdered') }}
      form.StateReason-center-MiniPopup(
          v-click-outside="() => {showReasonPopUp = false, stateReason = ''}"
          v-if="showReasonPopUp"
          id="state-ordered-reason"
          @submit.prevent ="updateStateOrder"
        )
          label.StateReason-center-MiniPopup-label.required {{ $t('Orders.popupAdd_FormField_Dissmis_Reason_Label') }}
          textarea.txt.StateReason-center-MiniPopup-textarea(
            name="reason"
            v-model.trim="stateReason"
            v-validate="'required|max:128'"
            autocomplete="off"
            :class="{ 'is-danger': veeErrors.has('reason') }"
          )
          Button.m-top-10(
            primary
            size="medium"
            :form="`state-ordered-reason`"
            type="submit"
            :disabled="!stateReason || isLoadingFinalize"
          )
            span {{$t('Orders.Detail_Col_Button_Agree')}}
            Loading(theme="disable", v-show="isLoadingFinalize")
    Button(
      primary,
      size="small"
      id="btn-orders-transform-despatch"
      :disabled="selectedHeaders.length === 0 || isLoadingFinalize",
      @click="transfromDespatch"
    )
      span.font-weight-w-normal.font-size-small {{ $t('Orders.transformToDespatch') }}
      Loading(theme="disable", v-show="isLoadingFinalize")  

  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
      // TOOLBAR
    template(v-else)

          
      TableLoading.sticky(v-if="isFetchingPageData || isFetchingOrderList")
      EmptyState(
      v-if="isEmptyApproveOrderList && !isFetchingPageData && !isFetchingOrderList"
      :title="$t('Orders.orders_emptyState_NotFound_Title')"
      :description="$t('Orders.orders_emptyState_NotFound_Description')")

      NestedTable(
        v-if="!isEmptyApproveOrderList",
        :status-icon="false",
        ref="ordersNestedTable"
        :fields="ordersHeaderFields",
        :detailFields="ordersDetailFields",
        :actions="['export']",
        :page="Page"
        :data="approveOrders",
        v-model="selectedOrderDetails"
        :addButtonText="$t('Global.addNewStock')",
        @changeRowData="changeOrderType",
        :canAddNewDetail="false"
        @addDetailAction="addNewDetail",
        @editHeaderAction="editAction",
        @editDetailAction="editDetailAction",
        :isFinalizeAction="false",
        :isDetailLoading="$wait.is('getOrderDetail')"
        @showExportPopup="showExportPopup"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
        @showDetailAction="showOrderDetail")

      portal(to="single-action-portal-start")
        TotalPreview(
          slot-scope="header"
          v-if="header.row.totalUnitQuantityList.length > 0"
          :rowIndex="header.rowIndex"
          :list="header.row.totalUnitQuantityList"
        )

      portal(to="checkboxAll")
        CustomCheckbox(
          label=""
          :checked="isSelectedAll"
          @change="toggleAll"
          :disabled="!selectedSupplierId"
        )

      portal(to="headerCheckbox")
        div(slot-scope="header")
          CustomCheckbox(
            :label="''"
            :checked="isCheckedHeader(header.row)"
            :disabled="makeDisableCheckbox(header.row.id) ? true : selectedSupplierId ? header.row.supplierId !== selectedSupplierId : false"
            @change="toggleHeader(header.row)")

      portal(to="detailCheckbox")
        div(slot-scope="detail")
          CustomCheckbox(
            label=""
            :disabled="makeDisableCheckbox(detail.row.headerId) ? true : selectedSupplierId ? detail.row.supplierId !== selectedSupplierId : false"
            :checked="isCheckedDetail(detail.row.id)"
            @change="toggleDetail(detail.row)")

      portal(to="orderDate")
        div(slot-scope="header")
          CustomDatepicker(
            :name="`date-${header.rowIndex}`"
            :id="`date-input-table-row-${header.rowIndex}`"
            :value="header.row.orderDate"
            :class="{ 'is-danger': veeErrors.has(`date-${header.rowIndex}`) }"
            :disabledStartDate="disableDate"
            pickerType="manuel",
            @change="e => changeOrderDate(header, e)",
            inputIconRight=""
            :disabled="(header.row.status === 2)"
            inputIcon="")

      portal(to="description")
        div(slot-scope="header")
          Button.show-MiniPopup.relative.width-max-content(
            v-if="header.row.description",
            size="medium"
            variant="info"
            :id="`production-header-info-button-${header.rowIndex}`"
            iconName="icon-global-info",
            :justIcon="true",
          )
            .MiniPopup-right-primary.m-top-15.c-dark
              span {{ header.row.description }}

      portal(to="status")
        div(slot-scope="header")
          StatusTooltip(
            :title="header.row.accountingMessage"
            icon="icon-row-status"
            v-if="header.row.accountingStatus === 3"
          )

      portal(to="headerClosedCheckbox")
        div(slot-scope="header")
          CustomCheckbox(
            :label="''"
            :checked="isCheckedClosedHeader(header.row)"
            @change="toggleClosedHeader(header.row)")

      portal(to="detailDescription")
        div(slot-scope="detail")
          Button.show-MiniPopup.relative.width-max-content(
            v-if="detail.row.description",
            size="medium"
            variant="info"
            :id="`ordered-despatch-detail-info-button-${detail.rowIndex}`"
            iconName="icon-global-info",
            :justIcon="true",
          )
            .MiniPopup-leftCenter-primary.m-top-15.c-dark
              span {{ detail.row.description }}

    router-view(@getOrdersHeaderAll="getOrdersHeaderAll", @showOrderWithDetail="showOrderWithDetail")

    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )
</template>

<script>
// mixin
import alertbox from '@/mixins/alertbox'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getOrdersHeaderFields, getOrdersDetailFields } from './fields/orderedDespatch'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import TableFoot from '@/view/global/table/foot.vue'
import supplierFilter from '@/view/global/business-filters/supplier.vue'
import storeFilter from '@/view/global/business-filters/store.vue'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import StatusTooltip from '@/view/global/alert-status-tooltip'
import TotalPreview from '@/view/pages/Requests/components/total-preview'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import exportPopup from '@/view/global/export-popup'
import ImportExport from '@/view/global/import-export'
import settingsExportPopup from '@/mixins/settingsExportPopup'

export default {
  name: 'OrderedDespatch',
  mixins: [
    alertbox(),
    hasTabPermission,
    settingsExportPopup
  ],
  data () {
    return {
      selectedOrderDetails: [],
      dates: [],
      storeFilter: [],
      stockItem: null,
      search: '',
      selectedStockItem: [],
      supplierFilter: [],
      selectedHeaders: [],
      selectedDetails: [],
      selectedClosedHeaders: [],
      showClosedOrdered: false,
      showReasonPopUp: false,
      stateReason: '',
      hasOrderEdit: false,

      disableDate: {
        to: new Date()
      },
      isExportPopupVisible: false,
      templateId: null,
      templateType: 0
    }
  },
  components: {
    TableFoot,
    supplierFilter,
    storeFilter,
    NoPermission,
    StatusTooltip,
    TotalPreview,
    exportPopup,
    ImportExport
  },
  computed: {
    ...mapGetters('Request', ['requestHeaderLinks']),

    ...mapGetters('Despatch', ['pageHeaderLinks']),

    ...mapGetters('Orders', ['approveOrders', 'Page', 'isEmptyApproveOrderList']),

    ...mapGetters('Settings', ['allPermissionList', 'roleId', 'permissions']),

    ...mapGetters('OrderIntegrationStatus', ['OrderIntegrationStatusList']),

    isEmptySelectedHeaders () {
      return this.selectedHeaders.length === 0
    },
    isEmptySelectedDetaild () {
      return this.selectedDetails.length === 0
    },

    selectedSupplierId () {
      if (this.isEmptySelectedHeaders && this.isEmptySelectedDetaild) {
        return null
      } else {
        return !this.isEmptySelectedHeaders ? this.selectedHeaders[0].supplierId : this.selectedDetails[0].supplierId
      }
    },

    ordersHeaderFields () {
      return getOrdersHeaderFields(this.$t, !this.showClosedOrdered)
    },
    ordersDetailFields () {
      return getOrdersDetailFields(this.$t, !this.showClosedOrdered)
    },
    isFetchingStoreList () {
      return this.$wait.is(['getMiniStoreList'])
    },
    isFetchingPageData () {
      return this.$wait.is(['pageLoading'])
    },
    isFetchingOrderList () {
      return this.$wait.is('fetchingOrderList')
    },
    isSuggestionLoading () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    isSuggestionResultEmpty () {
      return this.selectedStockItem.length === 0
    },

    resendLoading () {
      return this.$wait.is('resendLoading')
    },

    isLoadingFinalize () {
      return this.$wait.is(['updateStateOrder'])
    },

    downloadParams () {
      const params = {
        StartDate: this.dateFormat(this.dates[0]),
        EndDate: this.dateFormat(this.dates[1]),
        storeIdList: this.storeFilter.map(store => store.id),
        supplierIdList: this.supplierFilter.map(supplier => supplier.id),
        stockItemId: this.stockItem ? this.stockItem.id : null
      }
      return params
    },
    downloadFileName () {
      const fileName = `Orders_Report_${this.dateFormat(this.dateStart)}_${this.dateFormat(this.dateEnd)}`
      return fileName
    },

    isSelectedAll () {
      return this.selectedHeaders.length ? this.selectedHeaders.length === this.selectableHeaders.length : false
    },

    selectableHeaders () {
      return this.selectedHeaders.length || this.selectedDetails.length
        ? this.approveOrders.filter(header => this.selectedHeaders.length ? header.supplierId === this.selectedHeaders[0].supplierId : this.selectedDetails[0].supplierId === header.supplierId)
        : []
    },

    selectableDetails () {
      return this.selectableHeaders.map(header => header.detailList).flat()
    }
  },
  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Orders', ['getOrdersHeader', 'getOrderDetail', 'setOrderStatus', 'orderResend', 'updateApprovedOrder', 'updateOrderedDespatchState']),

    ...mapActions('Stock', ['searchStockItem']),

    ...mapActions('Settings', ['fetchPermissionRoleList']),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('OrderIntegrationStatus', ['getOrderIntegrationStatusList']),

    ...mapMutations('Orders', ['HIDE_DETAIL', 'SHOW_DETAIL']),

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    toggleAll () {
      if (this.isSelectedAll) {
        this.selectedHeaders = []
        this.selectedDetails = []
      } else {
        this.selectedHeaders = [...this.selectableHeaders]
        this.selectedDetails = [...this.selectableDetails]
      }
    },

    hasOrderEditPermission () {
      this.permissions.find(i => {
        if (i.permission === 211) this.hasOrderEdit = true
      })
    },

    async toggleShowClosedOrdered () {
      this.$wait.start('pageLoading')
      this.showClosedOrdered = !this.showClosedOrdered
      this.selectedClosedHeaders = []
      await this.getOrdersHeaderAll(this.Page.number)
      this.$wait.end('pageLoading')
    },

    isSelectedAllDetails (header) {
      return header.detailList.length === this.selectedDetails.filter(detail => detail.headerId === header.id).length
    },

    isCheckedHeader (header) {
      return this.selectedHeaders.some(item => item.id === header.id) || (this.isSelectedAllDetails(header) && header.showDetail)
    },

    isCheckedClosedHeader (header) {
      return this.selectedClosedHeaders.some(item => item.id === header.id)
    },

    toggleClosedHeader (header) {
      const isChecked = this.isCheckedClosedHeader(header)
      const isSelected = this.selectedClosedHeaders.some(h => h.id === header.id)
      if (!isChecked) {
        if (isSelected) return
        this.selectedClosedHeaders.push(header)
      } else {
        const headerIndex = this.selectedClosedHeaders.findIndex(h => h.id === header.id)
        this.selectedClosedHeaders.splice(headerIndex, 1)
      }
    },

    toggleHeader (header) {
      const isChecked = this.isCheckedHeader(header)
      const isSelected = this.selectedHeaders.some(h => h.id === header.id)
      if (!isChecked) {
        if (isSelected) return
        this.selectedHeaders.push(header)
        header.detailList.map(detail => {
          if (!this.isCheckedDetail(detail.id)) this.selectedDetails.push(detail)
        })
      } else {
        const headerIndex = this.selectedHeaders.findIndex(h => h.id === header.id)
        this.selectedHeaders.splice(headerIndex, 1)
        header.detailList.map(detail => this.selectedDetails.splice(this.selectedDetails.findIndex(d => d.id === detail.id), 1))
      }
    },

    isCheckedDetail (detailId) {
      return this.selectedDetails.some(item => item.id === detailId)
    },

    toggleDetail (detail) {
      const isChecked = this.isCheckedDetail(detail.id)
      const headerData = this.approveOrders.find(header => header.id === detail.headerId)
      if (!isChecked) this.selectedDetails.push(detail)
      else {
        const index = this.selectedDetails.findIndex(d => d.id === detail.id)
        const headerIndex = this.selectedHeaders.findIndex(h => h.id === detail.headerId)
        this.selectedDetails.splice(index, 1)
        this.selectedHeaders.splice(headerIndex, 1)
      }
      if (this.isSelectedAllDetails(headerData)) this.selectedHeaders.push(headerData)
    },

    makeDisableCheckbox (headerId) {
      const header = this.approveOrders.find(h => h.id === headerId)
      if (this.hasOrderEdit && (header.integrationStatusIsClosureStatus === null || header.integrationStatusIsClosureStatus === false)) return true
      if (!header.supplierIsZeroPriceAvailable) return false
      else return header.supplierIsZeroPriceAvailable
    },

    async requestOrderResend (order) {
      this.$wait.start('resendLoading')
      const res = await this.orderResend({orderId: order.id})
      if (res) await this.clearSearch()
      this.$wait.end('resendLoading')
    },

    changeOrderDate (item, date) {
      const dontUpdate = !date || !item.row.orderDate || date === item.row.orderDate

      if (dontUpdate) return
      const payload = {
        ...item.row,
        orderDate: date
      }
      this.updateApprovedOrder(payload)
        .then(res => {
          if (res.status !== 200) return
          const message = this.$t('Global.successUpdateMessage', { field: this.$t('Orders.moduleName') })
          this.notifyShow({ message })
        })
    },

    async changeOrderType (item) {
      const selectedStatusItem = this.OrderIntegrationStatusList.find(s => s.id === item.integrationStatusId)
      if (selectedStatusItem && selectedStatusItem.isClosureStatus) {
        this.alert({
          title: this.$t('Orders.closeConfirmMessageTitle'),
          message: this.$t('Orders.closeConfirmMessage'),
          cancel: {
            text: this.$t('Orders.deleteAlert_CancelButton'),
            action: () => {
              this.$refs.ordersNestedTable.makeForms()
            }
          },
          confirm: {
            text: this.$t('Orders.confirmButtonText'),
            action: async (close) => {
              const res = await this.changeOrderStatus(item)
              if (res) {
                close()
              }
            }
          }
        })
      } else {
        await this.changeOrderStatus(item)
      }
    },
    async changeOrderStatus (item) {
      const res = await this.setOrderStatus({
        orderId: item.id,
        integrationStatusId: item.integrationStatusId
      })
      if (res) {
        const message = this.$t('Global.successUpdateMessage', { field: this.$t('Orders.moduleName') })
        await this.notifyShow({ message })
        await this.getOrdersHeaderAll(this.Page.number)
      } else {
        this.$refs.ordersNestedTable.makeForms()
      }
      return res
    },

    async changePage (page) {
      await this.getOrdersHeaderAll(page)
    },

    async changePageSize (pageSize) {
      await this.getOrdersHeaderAll(1, pageSize)
    },
  
    showOrderDetail (header) {
      const item = this.approveOrders.find(i => i.id === header.id)
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
        return
      }
      this.getOrderDetail(item)
        .then(() => {
          if (!this.selectedHeaders.some(h => h.id === item.id)) return
          item.detailList.forEach(d => !this.isCheckedDetail(d.id) ? this.selectedDetails.push(d) : false)
        })
    },

    async getListWithSupplierFilter ({list, dontSubmit}) {
      if (dontSubmit) this.supplierFilter = list
      else {
        this.$wait.start('fetchingOrderList')
        this.supplierFilter = list
        await this.getOrdersHeaderAll()
        this.$wait.end('fetchingOrderList')
      }
    },

    async getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.$wait.start('fetchingOrderList')
        this.storeFilter = list
        await this.getOrdersHeaderAll()
        this.$wait.end('fetchingOrderList')
      }
    },

    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.selectedStockItem = []
      this.getOrdersHeaderAll()
    },

    clearSearch () {
      this.search = ''
      this.stockItem = []
      this.selectedStockItem = []
      this.getOrdersHeaderAll()
    },

    clearFilter () {
      this.$refs.orderedStoreFilter.clearStoreFilter()
      this.$refs.orderedSupplierFilter.clearSupplierFilter(false)
    },

    async getOrdersHeaderAll (page = 1, pageSize = this.Page.size) {
      this.selectedHeaders = []
      this.selectedDetails = []
      const payload = {
        storeIdList: this.storeFilter ? this.storeFilter.map(s => s.id) : [],
        supplierIdList: this.supplierFilter ? this.supplierFilter.map(t => t.id) : [],
        stockItemId: this.stockItem ? this.stockItem.id : null,
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        state: this.showClosedOrdered ? 2 : 1,
        filterType: 2,
        pageNumber: page,
        pageSize
      }
      const res = await this.getOrdersHeader(payload)
      return res
    },

    onChangeSearch (search) {
      this.search = search
      if (!this.search) {
        return 
      }
      if (this.search.length >= 2) {
        this.searchStockItem({ Text: this.search, types: [1, 2] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },

    transfromDespatch () {
      this.$router.push({
        name: 'DespatchHeaderNew',
        query: {
          withDetail: true,
          ordered: true,
          selectedHeaders: this.selectedHeaders,
          selectedDetails: this.selectedDetails
        }
      })
    },
    editAction (item) {
      this.$router.push({
        name: 'OrdersEdit',
        params: {
          id: item.id,
          item
        }
      })
    },
    addNewDetail (order) {
      this.$router.push({
        name: 'orderAddStock',
        params: {
          id: order.id
        }
      })
    },
    editDetailAction (item) {
      this.$router.push({
        name: 'OrderDetailEdit',
        params: {
          id: item.headerId,
          detailId: item.id
        }
      })
    },

    updateStateOrder: vueWaitLoader(async function () {
      const params = {
        orderIds: this.selectedClosedHeaders.map(item => item.id),
        state: this.showClosedOrdered ? 1 : 2,
        stateReason: this.stateReason
      }
      await this.updateOrderedDespatchState(params).then(async res => {
        if (!res) return

        this.showReasonPopUp = false
        await this.getOrdersHeaderAll()
        this.selectedClosedHeaders = []
        this.stateReason = ''
      })
    }, 'updateStateOrder'),

    async showOrderWithDetail (item) {
      const header = await this.getOrdersHeaderAll()
      if (header) {
        this.showOrderDetail(item)
      }
    },

    showExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 6, isFinalizeAction)
    }
  },


  async beforeMount () {
    if (!this.hasTabPermission) return

    this.$wait.start('pageLoading')
    await this.hasOrderEditPermission()
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_OrderDespatch')
    await this.getOrderIntegrationStatusList()
    await this.fetchPermissionRoleList(this.roleId)
    await this.getOrdersHeaderAll()
    this.$wait.end('pageLoading')
  },

  watch: {
    async dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.$wait.start('fetchingOrderList')
      await this.getOrdersHeaderAll()
      this.$wait.end('fetchingOrderList')
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";
  .StateReason-center-MiniPopup {
    @include MiniPopupMixin(
      $width: 300px,
      $margin: 30px 0 0 0,
      $padding-hor: 20px,
      $padding-ver: 20px,
      $triangle-direction: 'right',
    );

  }

  .StateReason-center-MiniPopup{

    &-textarea {
      width: 100%;
      margin-top: 10px;
      min-height: 75px;
    }

    &-label {
      max-width: 100%;
      margin-top: 10px;
      margin-left: 0px;
      color: $color-light;
      font-size: $font-size-small;
      font-weight: $font-weight-semi-bold;
    }
  }

.PageHeader {
  padding: 0;
  .right {
    align-self: center;
    display: flex;
    .form-item-select {
      min-width: 200px;
      flex-shrink: 0;
    }
  }
}
.pageLoading {
  top: inherit;
}

:deep() .status-title{
  width:500px;
}

.sticky {
  z-index: $z-index-md;
  position: sticky !important;
  left: 50%;
  top: 50%;
}

:deep() .NestedTable-row{
  height: auto !important;
  grid-template-columns: 40px 2fr 1fr 2fr 2fr repeat(7, 1fr) !important;
}
:deep() .NestedTable-head {
  grid-template-columns: 40px 2fr 1fr 2fr 2fr repeat(7, 1fr) !important;
}
:deep() .NestedTable-item{
  height: auto !important;
}
</style>
