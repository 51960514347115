var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "Settings",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.headerFields,
              useUpperCase: false,
              selectedItem: _vm.selectedHeader,
              selectedKey: "headerValue",
            },
            on: { delegateChangeSelectedItem: _vm.changeHeader },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.details.length
            ? _c("TableLoading")
            : _c("section", { staticClass: "settings-date" }, [
                _c("div", { staticClass: "date-filter" }, [
                  _c("div", { staticClass: "date-filter-header" }, [
                    _c("div", { staticClass: "date-filter-header-columns" }),
                    _c("div", { staticClass: "date-filter-header-columns" }, [
                      _c(
                        "span",
                        { staticClass: "date-filter-header-columns-title" },
                        [_vm._v(_vm._s(_vm.$t("DateFilter.Type_Field_Daily")))]
                      ),
                    ]),
                    _c("div", { staticClass: "date-filter-header-columns" }, [
                      _c(
                        "span",
                        { staticClass: "date-filter-header-columns-title" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("DateFilter.Type_Field_Yesterday"))
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "date-filter-header-columns" }, [
                      _c(
                        "span",
                        { staticClass: "date-filter-header-columns-title" },
                        [_vm._v(_vm._s(_vm.$t("DateFilter.Type_Field_Today")))]
                      ),
                    ]),
                    _c("div", { staticClass: "date-filter-header-columns" }, [
                      _c(
                        "span",
                        { staticClass: "date-filter-header-columns-title" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("DateFilter.Type_Field_ThisWeek"))
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "date-filter-header-columns" }, [
                      _c(
                        "span",
                        { staticClass: "date-filter-header-columns-title" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("DateFilter.Type_Field_LastWeek"))
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "date-filter-header-columns" }, [
                      _c(
                        "span",
                        { staticClass: "date-filter-header-columns-title" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("DateFilter.Type_Field_ThisMonthAll"))
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "date-filter-header-columns" }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "date-filter-body" },
                    _vm._l(_vm.filteredDetails, function (detail, detailIndex) {
                      return _c(
                        "div",
                        { staticClass: "date-filter-body-row" },
                        [
                          _c(
                            "div",
                            { staticClass: "date-filter-body-row-columns" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "date-filter-body-row-columns-title",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "DateFilter.Detail_Field_" + detail.key
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._l(_vm.types, function (dataType) {
                            return dataType.value < 220
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "date-filter-body-row-columns",
                                  },
                                  [
                                    _c("CustomRadio", {
                                      attrs: {
                                        checked:
                                          (detail.value < 100 &&
                                            dataType.value < 100) ||
                                          detail.value === dataType.value,
                                        label: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeDetail(
                                            detail,
                                            dataType
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "form",
                                      {
                                        attrs: { "data-vv-scope": detail.key },
                                      },
                                      [
                                        detail.value < 100 &&
                                        dataType.value < 100
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "default-value",
                                                  rawName: "v-default-value",
                                                  value: detail.value,
                                                  expression: "detail.value",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value:
                                                    "required|integer|min_value:-6|max_value:6|not_in:0",
                                                  expression:
                                                    "'required|integer|min_value:-6|max_value:6|not_in:0'",
                                                },
                                              ],
                                              staticClass:
                                                "txt date-filter-input no-spinners",
                                              class: {
                                                "is-danger": _vm.veeErrors.has(
                                                  detail.key + ".input"
                                                ),
                                              },
                                              attrs: {
                                                disabled: !(
                                                  detail.value < 100 &&
                                                  dataType.value < 100
                                                ),
                                                name: "input",
                                                type: "number",
                                              },
                                              on: {
                                                input: (e) =>
                                                  _vm.changeInput(
                                                    e,
                                                    detail,
                                                    dataType
                                                  ),
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e()
                          }),
                          _c(
                            "div",
                            { staticClass: "date-filter-body-row-columns" },
                            [
                              _c("CustomRadio", {
                                attrs: {
                                  checked: detail.value >= 220,
                                  disabled: true,
                                  label: "",
                                },
                              }),
                              _c("customSelectOption", {
                                ref: "customSelect",
                                refInFor: true,
                                staticClass: "m-left-10",
                                attrs: {
                                  optionData: _vm.monthTypes,
                                  detailData: detail,
                                },
                                on: {
                                  inputSelected: _vm.changeDetailWitDropdown,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "date-filter-body-row-columns" },
                            [
                              _c(
                                "Button",
                                {
                                  staticClass:
                                    "date-filter-body-row-columns-remove",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setDefaultValue(detail)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "DateFilter.Button_Set_Defaul_Type"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
              ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }