var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }),
      _c("div", { staticClass: "col-6" }, [
        _vm._v("User.........."),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.user,
              expression: "form.user",
            },
          ],
          attrs: { id: "userInput", type: "text" },
          domProps: { value: _vm.form.user },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "user", $event.target.value)
            },
          },
        }),
        _c("br"),
        _vm._v("            Message..."),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.message,
              expression: "form.message",
            },
          ],
          attrs: { id: "messageInput", type: "text" },
          domProps: { value: _vm.form.message },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "message", $event.target.value)
            },
          },
        }),
        _c("input", {
          attrs: { id: "sendButton", type: "button", value: "Send Message" },
          on: { click: _vm.sendMessage },
        }),
      ]),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "ul",
          { attrs: { id: "messagesList" } },
          _vm._l(_vm.messageList, function (log) {
            return log.user && log.message
              ? _c("li", [
                  _vm._v(_vm._s(log.user + " diyor ki: " + log.message)),
                ])
              : _vm._e()
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [_c("hr")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }