var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "toolbar-right" }),
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "MenuItemsSettings" }, [
          _c(
            "div",
            { staticClass: "toolbar" },
            [
              _c(
                "div",
                { staticClass: "flexbox align-flex-end" },
                [
                  _c(
                    "div",
                    [
                      _c("p", { staticClass: "font-size-small m-bottom-15" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "MenuItemsSettings.basedStoreGroupSelectLabelText"
                            )
                          )
                        ),
                      ]),
                      _c("CustomMultiselect", {
                        staticClass: "m-bottom-15",
                        attrs: {
                          id: "custom-multiselect-match-copy-base-store",
                          items: _vm.storeGroupList,
                          selected: _vm.baseSelectedStoreGroup,
                          "popup-header": _vm.$t(
                            "MenuItemsSettings.basedStoreGroupSelect"
                          ),
                          "show-all": false,
                          "show-footer": false,
                          useCustomEmptyState: true,
                          customEmptyState: _vm.baseSelectedStoreGroup.length
                            ? _vm.baseSelectedStoreGroup[0].name
                            : _vm.$t(
                                "MenuItemsSettings.storeGroupSelectPlaceholderText"
                              ),
                          type: "radio",
                          buttonIconLeft: "btn-success",
                        },
                        on: {
                          delegateCheckItem: _vm.changeBaseSelectedStoreGroup,
                        },
                      }),
                      _c("CustomMultiselect", {
                        attrs: {
                          id: "custom-multiselect-match-copy-base-rvc",
                          items: _vm.baseRvcGroupList,
                          selected: _vm.baseSelectedRvcGroup,
                          "popup-header": _vm.$t(
                            "MenuItemsSettings.baseRvcGroupSelect"
                          ),
                          "show-all": false,
                          "show-footer": false,
                          type: "radio",
                          useCustomEmptyState: true,
                          customEmptyState: _vm.baseSelectedRvcGroup.length
                            ? _vm.baseSelectedRvcGroup[0].name
                            : _vm.$t(
                                "MenuItemsSettings.baseRvcGroupSelectPlaceholderText"
                              ),
                          buttonIconLeft: "btn-success",
                        },
                        on: {
                          delegateCheckItem: _vm.changeBaseSelectedRvcGroup,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [_c("Icon", { attrs: { name: "icon-rightwards" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("p", { staticClass: "font-size-small m-bottom-15" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "MenuItemsSettings.releatedStoreGroupSelectLabelText"
                            )
                          )
                        ),
                      ]),
                      _c("CustomMultiselect", {
                        staticClass: "m-bottom-15",
                        attrs: {
                          id: "custom-multiselect-match-copy-base-store",
                          items: _vm.releatedStoreGroupList,
                          selected: _vm.relatedSelectedStoreGroup,
                          "popup-header": _vm.$t(
                            "MenuItemsSettings.releatedStoreGroupSelect"
                          ),
                          showAll: false,
                          "show-footer": false,
                          type: "radio",
                          useCustomEmptyState: true,
                          customEmptyState: _vm.relatedSelectedStoreGroup.length
                            ? _vm.relatedSelectedStoreGroup[0].name
                            : _vm.$t(
                                "MenuItemsSettings.storeGroupSelectPlaceholderText"
                              ),
                          buttonIconLeft: "btn-success",
                        },
                        on: {
                          delegateCheckItem:
                            _vm.changeReleatedSelectedStoreGroup,
                        },
                      }),
                      _c("CustomMultiselect", {
                        attrs: {
                          id: "custom-multiselect-match-copy-releated-rvc",
                          items: _vm.releatedRvcGroupList,
                          selected: _vm.releatedSelectedRvcGroup,
                          "popup-header": _vm.$t(
                            "MenuItemsSettings.releatedRvcGroupSelect"
                          ),
                          "show-all": false,
                          "show-footer": false,
                          type: "radio",
                          buttonIconLeft: "btn-success",
                          useCustomEmptyState: true,
                          customEmptyState: _vm.releatedSelectedRvcGroup.length
                            ? _vm.releatedSelectedRvcGroup[0].name
                            : _vm.$t(
                                "MenuItemsSettings.releatedRvcGroupSelectPlaceholderText"
                              ),
                        },
                        on: {
                          delegateCheckItem: _vm.changeReleatedSelectedRvcGroup,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "Button",
                    {
                      attrs: {
                        primary: "",
                        size: "medium",
                        variant: "full",
                        disabled: _vm.isCopyButtonDisabled,
                        isLoading: _vm.isLoading,
                      },
                      on: { click: _vm.submitMatchedCopy },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("MenuItemsSettings.matchedCopyButton"))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("router-view", { on: { defualtSet: _vm.defaultSelectedSet } }),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }