<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "requestHeaderLinks"
      translationGroup="Request"
    )
  template(slot="header-left-search")
    Search.search-box(
      componentName="requestLists",
      suggestionValue="name"
      :isSuggestionLoading="isSuggestionLoading"
      :suggestions="selectedStockItem"
      :customSuggestionPlaceHolder="$t('Request.search_Placeholder')"
      :customSuggestionTitle="$t('StockReports.search_SuggestionTitle')"
      @onChangeSearchValue="onChangeSearch"
      @onChangeQueryItem="setStockItem"
      @clearSearch="clearSearch"
    )
  template(slot="header-right-dropdown" v-if="hasTabPermission")
    .form-item-select.date-start.m-right-10.m-left-10
      CustomDatepicker#RequestDatepicker(
        v-model="dates",
        :isLoading="$wait.is('getDateFilterSettings')"
        :range="true"
        :disabled="isFetchingList || isPageLoading"
      )
  template(slot="header-right-content" v-if="hasTabPermission")
    Button(
      primary
      size="medium"
      id="btn-request-header-ass"
      type="button"
      @click="newHeader"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      v-if="checkPermission('RequestOrder') || checkPermission('RequestTransfer')"
    )
      span {{ $t('Request.add_Request') }}

  template(slot="toolbar-left")
    businessMultiselect(
      componentName="requests"
      :label="$t('Request.toolbar_Stores_Label')"
      :popupHeader="$t('Request.toolbar_Stores_PopupHeader')"
      :multiselectItems="storeList"
      @submitFilter="getFilteredRequests"
      ref="requestsStoreFilter"
    )

    Button.clear-filter-button.border-gray-1.p-0.width-auto(
        @click='clearFilter'
        )
          Icon.icon-clear-filter(name="icon-clear-filter")

    .show-drafts.m-left-15
      CustomCheckbox.popup-item-check(
      id="checkbox-requests-show-drafts"
      :checked="showDrafts"
      :label="$t('Global.showDrafts')"
      @change="changeShowDraftsStatus")
  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    .RequestsContent(v-else)
      TableLoading(v-if="isFetchingList || isPageLoading")
      EmptyState(
        v-if="isRequestListEmpy && !isFetchingList && !isPageLoading",
        :title="$t('Request.emptyState_Title')",
        :description="$t('Request.emptyState_Description')",
        :buttonText="$t('Request.add_Request')",
        @emptyAddButton="newHeader")

      div(v-if="!isRequestListEmpy && !isFetchingList && !isPageLoading")
        NestedTable(
          :fields="requestHeaderFields",
          :detailFields="requestDetailFields",
          :data="requestList",
          :isDetailLoading="isDetailLoading"
          :actions="['delete', 'edit', 'export']",
          :detailActions="['edit','delete']"
          @editHeaderAction="editItemAction",
          @deleteHeaderAction="removeItemAction",
          :addButtonText="$t('Request.detail_NewStockItemButton')",
          :finalizeButtonText="$t('Request.detail_SaveButton')",
          @finalizeAction="requestSaved",
          :isLoadingFinalize="$wait.is('finalize')"
          :disableNewDetailButton="isOnlyListUsage"
          @addDetailAction="requestDetailNew",
          @showDetailAction="showRequestDetail",
          @deleteDetailAction="requestDetailRemove",
          :extraButtonTexts="[`${$t('Request.detail_ConvertTransfer')}`, `${$t('Request.detail_ConvertOrders')}`]",
          @detailChangeAction="updateDetailItems"
          @showExportPopup="showExportPopup"
          @editDetailAction="requestDetailEdit")
        portal(to="reasons")
          div.flexbox(slot-scope="header")
            .reason-container.m-left-10(
              v-if="header.row.description"
            )
              Button.show-MiniPopup.relative.width-max-content(
                size="small"
                variant="info"
                :id="`request-description-status-header-info-button-${header.rowIndex}`"
                iconName="icon-global-info",
                :justIcon="true",
              )
                .MiniPopup-right-primary.m-top-15.c-dark
                  span {{ header.row.description }}
            .reason-container.m-left-10(
              v-if="header.row.rejectionReason",
            )
              Button.show-MiniPopup.relative.width-max-content(
                size="small"
                variant="info"
                :id="`request-rejection-status-header-info-button-${header.rowIndex}`"
                iconName="icon-rejection-status",
                :justIcon="true",
                class="rejection-info"
              )
                .MiniPopup-right-primary.m-top-15.c-dark
                  span {{ header.row.rejectionReason }}

        portal(to="wmsStatus")
          div(slot-scope="header")
            Button.wms-warning(
              v-if="header.row.wmsStatus === 3",
              :id="`request-header-resend-button-${header.rowIndex}`"
              iconName="icon-global-warning",
              class="icon-global-warning"
              :justIcon="true",
              size="medium"
              @click.stop="toggleErrorPopup(header.row)"
            )           

            .MiniPopup.right(
              v-if="header.row.showAccountingStatus"
              v-shortkey="['esc']"
              v-click-outside="() => closeErrorPopup(header.row)"
            )
              .resendButton-Popup              
                .resendButton-Popup-content(v-if="header.row.wmsStatus === 3")
                  span {{ header.row.wmsMessage }}
                  Button.m-left-10(id="request-header-resend-data-wms" iconName="icon-global-refresh" iconClass="icon-global-refresh" secondary size="small" :disabled="isLoadingWMSReset" :isLoading="isLoadingWMSReset" @click="resendRequestForWMS(header.row)")
                    span {{ $t('Orders.Resend_Button_Text') }}

        portal(to="description")
          div(slot-scope="detail")
            Button.show-MiniPopup.relative.width-max-content(
              v-if="detail.row.description",
              size="small"
              variant="info"
              :id="`transfer-approval-header-info-button-${detail.rowIndex}`"
              iconName="icon-global-info",
              :justIcon="true",
            )
              .MiniPopup-leftCenter-primary.m-top-15.c-dark
                span {{ detail.row.description }}
        TableFoot(
          v-if="!isRequestListEmpy && !isFetchingList && !isPageLoading"
          :page="Page"
          @delegateOnChangePage="changePage"
          @delegateOnChangePageSize="changePageSize"
        )

    router-view(
      @getRequestList="getRequestList"
      @showDrafts="showDrafts = true"
    )
    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getRequestHeaderFields } from './fields/requests'
import moment from 'moment'
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'
import settingsExportPopup from '@/mixins/settingsExportPopup'
//
import TableFoot from '@/view/global/table/foot.vue'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import exportPopup from '@/view/global/export-popup'
import businessMultiselect from '@/view/global/business-filters/business-multiselect'

export default {
  name: 'Requests',
  mixins: [hasTabPermission, settingsExportPopup],
  data () {
    return {
      dates: [],
      stockItem: null,
      search: '',
      selectedStockItem: [],
      requestHeaderFields: getRequestHeaderFields(this.$t, this.$getConst),
      storeFilter: [],
      showDrafts: true,
      isOnlyListUsage: false
    }
  },

  components: {
    TableFoot,
    NoPermission,
    exportPopup,
    businessMultiselect,
  },
  computed: {
    ...mapGetters('Request', [
      'requestHeaderLinks',
      'requestDetailFields',
      'requestList',
      'isRequestListEmpy',
      'Page'
    ]),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList'
    }),

    ...mapGetters('Settings', [
      'checkPermission',
      'checkPricePermission',
      'permissions'
    ]),

    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    isHeaderCreating () {
      return this.$wait.is(['createRequestHeader'])
    },

    isLoadingWMSReset () {
      return this.$wait.is(['resetWMSStatus'])
    },

    isFetchingStoreList () {
      return this.$wait.is(['getMiniStoreList'])
    },
    isFetchingList () {
      return this.$wait.is(['getRequestHeaderAll'])
    },
    isPageLoading () {
      return this.$wait.is('pageLoading')
    },
    isSuggestionLoading () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    isSuggestionResultEmpty () {
      return this.selectedStockItem.length === 0
    },
    isDetailLoading () {
      return this.$wait.is(['getRequestDetail'])
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Request', [
      'getRequestHeaderAll',
      'getRequestDetail',
      'makeRequestSaved',
      'makeRequestFinalizeTransfer',
      'makeRequestFinalizeOrder',
      'updateRequestHeaderDetail',
      'showRequestDetailAction',
      'resetWMSStatus',
      'getRequestHeaderByItem'
    ]),

    ...mapActions('DocumentSettings', ['getDocumentFilterSettings']),

    ...mapActions({
      getStores: 'Stores/getMiniStoreList'
    }),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapMutations('Request', [
      'HIDE_DETAIL',
      'RESET_REQUEST',
      'UPDATE_REQUESTS_ITEM'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Group', ['getItemGroupAll']),

    async getRequestList (page = 1, pageSize = this.Page.size) {
      const payload = {
        storeIdList: this.storeFilter ? this.storeFilter.map(s => s.id) : [],
        stockItemId: this.stockItem ? this.stockItem.id : null,
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        pageNumber: page,
        pageSize,
        showDrafts: this.showDrafts
      }
      return this.getRequestHeaderAll(payload)
    },

    async resendRequestForWMS (item) {
      const res = await this.resetWMSStatus({requestId: item.id})
      if (res) await this.clearSearch()
    },

    toggleErrorPopup (item) {
      item.showAccountingStatus = !item.showAccountingStatus
    },

    closeErrorPopup (item) {
      item.showAccountingStatus = false
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_Request') this.showDrafts = i.isChecked
      })
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getRequestList()
    },

    getFilteredRequests ({ list, dontSubmit }) {
      this.storeFilter = list
      if (!dontSubmit) {
        this.getRequestList()
      }
    },

    async newHeader () {
      this.$router.push({
        name: 'RequestHeaderForm'
      })
    },

    requestDetailNew (request) {
      this.$router.push({
        name: 'RequestDetailNew',
        params: {
          id: request.id,
          code: request.code,
          type: request.requestType,
        }
      })
    },
    editItemAction (item) {
      this.$router.push({
        name: 'RequestHeaderUpdate',
        params: {
          id: item.id,
          item: item
        }
      })
    },
    removeItemAction (item) {
      this.$router.push({
        name: 'RequestHeaderRemove',
        params: {
          id: item.id,
          item: item
        }
      })
    },

    requestSaved: vueWaitLoader(async function (request) {
      const res = await this.makeRequestSaved(request.id)
      if (res) {
        const message = this.$t('Request.requestSavedSuccessMessage')
        this.notifyShow({ message })
        this.getRequestList()
        this.showExportPopup(request, true)
      }
    }, 'finalize'),

    async goToTransferPage (request) {
      const res = await this.makeRequestFinalizeTransfer(request.id)
      if (res) {
        const message = this.$t('Request.requestSavedSuccessMessageForTransfer')
        this.notifyShow({ message })
        this.$router.push({
          name: 'RequestsTransfer'
        })
      }
    },

    async goToOrderPage (request) {
      const res = await this.makeRequestFinalizeOrder(request.id)
      if (res) {
        const message = this.$t('Request.requestSavedSuccessMessageForOrder')
        this.notifyShow({ message })
        this.$router.push({
          name: 'RequestsOrder'
        })
      }
    },

    requestDetailRemove (detail) {
      const request = this.requestList.find(item => item.id === detail.headerId)
      this.$router.push({
        name: 'RequestDetailRemove',
        params: {
          id: request.id,
          detailId: detail.id,
          item: detail
        }
      })
    },
    requestDetailEdit (detail) {
      const request = this.requestList.find(item => item.id === detail.headerId)
      this.$router.push({
        name: 'RequestDetailUpdate',
        params: {
          id: request.id,
          code: request.code,
          detailId: detail.id,
          item: detail
        }
      })
    },

    async updateDetailItems (items) {
      if (!items.requestDate) return
      const payload = getObjectValuesByKey(items, ['id', 'quantity', 'requestDate', 'description','unitPrice'])
      const message = this.$t('Request.request_update_message')
      const res = await this.updateRequestHeaderDetail(payload)
      if (res) {
        await this.getRequestDetail(items.headerId).then(res => {
          if (res) {
            this.showRequestDetailAction({ requestHeaderId: items.headerId, data: res.data , permission: this.checkPricePermission('RequestMenu') })
          }
        })
        await this.getRequestHeaderByItem(items.headerId)
        this.notifyShow({ message })
      }
    },
    onChangeSearch (search) {
      if (!search || search.length < 2) this.clearSearch()
      else {
        this.searchStockItem({ Text: search, types: [1, 2] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },
    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.selectedStockItem = []
      this.getRequestList()
    },
    clearSearch () {
      this.search = ''
      this.stockItem = []
      this.selectedStockItem = []
      this.getRequestList()
    },

    clearFilter () {
      this.$refs.requestsStoreFilter.clearAllFilter(false)
    },

    async showRequestDetail (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
        return
      }
      await this.getRequestDetail(item.id).then(res => {
        if (res) {
          this.showRequestDetailAction({ requestHeaderId: item.id, data: res.data, permission: this.checkPricePermission('RequestMenu') })
        }
      })
    },
    changePage (page) {
      this.getRequestList(page)
    },
    changePageSize (pageSize) {
      this.getRequestList(1, pageSize)
    },

    showExportPopup (row, isFinalizeAction = false) {
      const tempType = row.requestType === 3 ? 10 : 11
      const routeName = row.requestType === 3 ? 'TransferRequest' : 'OrderRequest'
      this.exportFrame(row.id, tempType, isFinalizeAction, routeName)
    },

    showComingRequestDetail () {
      const rawMaterialRequests = this.$route.params
      if (Object.keys(rawMaterialRequests).length !== 0) {
        const requestItem = {id: rawMaterialRequests.id}
        this.showRequestDetail(requestItem)
      }
    }
  },
  async mounted () {
    if (!this.hasTabPermission) return
    this.$wait.start('pageLoading')
    await this.setShowDraftStatus()
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_Request')
    await this.getStores()
    await this.getItemGroupAll()
    await this.getRequestList()
    this.showComingRequestDetail()
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    this.$wait.end('pageLoading')
  },
  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getRequestList()
    }
  },

  destroyed () {
    this.RESET_REQUEST()
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

.resendButton-Popup {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  box-shadow: $color-warning 1px 1px 1px 2px;
  &-item{
    margin: 5px;
  }
  &-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}

.MiniPopup {
  right: calc(26px);
  width: max-content;
}

  .PageHeader {
    padding: 0;
    .right {
      align-self: center;
      display: flex;
      .form-item-select {
        min-width: 200px;
        margin-right: 20px;
        flex-shrink: 0;
      }
    }
  }

  .flexbox {
    width: 50px;
  }

  :deep() .NestedTable-row-col.orderStatus {
    word-break: break-word !important;
  }

  .wms-warning {
    background: none;
  }

  :deep() .NestedDetail-row {
    &-col {
      &.fromStoreName, &.storeName, &.stockItemName, &.requestDate {
        min-width: 200px !important;
        word-break: break-word !important;
      }
    }
  }

</style>
