<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "requestHeaderLinks"
      translationGroup="Request"
    )
  template(slot="header-left-search")
    Search.search-box(
      v-if="hasTabPermission"
      componentName="requestedOrdersIndex",
      suggestionValue="name"
      :isSuggestionLoading="isSuggestionLoading"
      :suggestions="selectedStockItem"
      :customSuggestionPlaceHolder="$t('DirectOrders.searchPlaceholder')"
      :customSuggestionTitle="$t('DirectOrders.orders_search_SuggestionTitle')"
      @onChangeSearchValue="onChangeSearch"
      @onChangeQueryItem="setStockItem"
      @clearSearch="clearSearch")
        
  template(slot="header-right-dropdown" v-if="hasTabPermission")
    .form-item-select.date-start.m-right-10.m-left-10
      CustomDatepicker#DirectOrderDatepicker(
        v-model="dates",
        :isLoading="$wait.is('getDateFilterSettings')"
        :range="true"
        :disabled="isFetchingList"
      )

  template(slot="header-right-content" v-if="hasTabPermission")
    Button(
      id="btn-directOrder-header-create-header"
      primary
      size="medium"
      iconName="icon-global-add",
      iconClass="icon-global-add",
      v-if="!isEmptyDirectOrders",
      @click="newHeader"
    )
      span {{ $t('DirectOrders.add_DirectOrder') }}

  template(slot="toolbar-left")
    storeFilter(
      @submitFilter="getListWithStoreFilter"
      ref="requestsStoreFilter")
    Button.clear-filter-button.border-gray-1.p-0.width-auto(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")

    .show-drafts.m-left-15
      CustomCheckbox.popup-item-check(
      id="checkbox-direct-requests-show-drafts"
      :checked="showDrafts"
      :label="$t('Global.showDrafts')"
      @change="changeShowDraftsStatus")

  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    template(v-else)

      TableLoading(v-if="!isEmptyDirectOrders && isFetchingList")
      EmptyState(
      v-if="isEmptyDirectOrders && !isFetchingList",
      :title="$t('DirectOrders.emptyState_Title')",
      :description="$t('DirectOrders.emptyState_Description')",
      :buttonText="$t('DirectOrders.add_DirectOrder')",
      @emptyAddButton="newHeader")

      div(v-if="!isEmptyDirectOrders && !isFetchingList")
        NestedTable(
          :data="directOrders",
          :actions="['edit','delete', 'single-action-portal-start', 'export']",
          :detailActions="['edit','delete']",
          :fields="directOrdersHeaderFields",
          :isActionButtonsVisible="true"
          :detailFields="directOrdersDetailFields",
          @deleteHeaderAction="removeItemAction",
          @editHeaderAction="editHeader"
          @showDetailAction="showDetail",
          :disableFinalizeButton="disableFinalize"
          :isDetailLoading="$wait.is('fetchDirectOrderDetail')",
          :usePortalFooter="true"
          :usePortalAction="true"
          :readOnlyForDetail="true"
          @detailChangeAction="updateDetailItems",
          @showExportPopup="showExportPopup"
          @finalizeAction="itemSaved")
        
        portal(to="actionPortal")
          div.popup-container.flexbox-custom(slot-scope="detail")
            ActionButton(
              v-if="detail.row.status !== 2"
              id="request-order-list-action-button"
              :data="detail.row.approvalStatus === 3 && detail.row.headerType === 1 ? ['edit'] : ['edit','delete']",
              :item="detail.row"
              @onItemDelete="removeItemDetailAction(detail.row)"
              @onItemEdit="directOrdersDetailEdit(detail.row)"
            )

        portal(to="maxApprovalLevel")
          div(slot-scope="header")
            ApprovalLevels(
              v-if="header.row.approvalStatus > 0"
              :maxApprovalLevel="header.row.maxApprovalLevel",
              :currentApprovalLevel="header.row.currentApprovalLevel",
              :approvalStatus="header.row.approvalStatus"
              :historyList="historyListForOrders"
              :isLoading="isHistoryLoading"
              @showHistoryPopup="showHistoryPopup(header.row)"
            )

        portal(to="approvalStatus")
          div(slot-scope="header")
            span {{ getApprovalStatus(header.row.approvalStatus) }}

        portal(to="description")
          div(slot-scope="header")
            Button.show-MiniPopup.relative.width-max-conten(
              v-if="header.row.description",
              size="small"
              variant="info"
              :id="`orders-header-info-button-${header.rowIndex}`"
              iconName="icon-global-info"
              :justIcon="true",
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ header.row.description }}

        portal(to="description")
          div(slot-scope="detail")
            Button.show-MiniPopup.relative.width-max-content(
              v-if="detail.row.description"
              size="small"
              variant="info"
              :id="`orders-detail-info-button-${detail.rowIndex}`"
              iconName="icon-global-info"
              :justIcon="true"
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ detail.row.description }}

        portal(to="single-action-portal-start")
          div(slot-scope="header")
            TotalPreview(
              v-if="header.row.totalUnitQuantityList.length > 0"
              :rowIndex="header.rowIndex"
              :list="header.row.totalUnitQuantityList"
            )

        portal(to="footerPortal")
          div.popup-container.flexbox(slot-scope="rowData")
            Button.m-right-20(
              id="nested-detail-add-stock-items",
              inline,
              primary,
              size="small"
              v-if="rowData.status !== 2 && rowData.headerType !== 1"
              :disabled="(rowData.approvalStatus !== 0 && rowData.approvalStatus !== 3) || isOnlyListUsage"
              iconName="icon-global-add",
              iconClass="icon-global-add",
              @click="directOrdersDetailNew(rowData)")
                span {{ $t('DirectOrders.addStockItemButton') }}
            Button.m-right-20(
              id="nested-detail-finalize-order",
              v-if="!Tenant.approvalOrderPermission ? rowData.detailList.length && rowData.status !== 2 && rowData.headerType === 0 : (rowData.maxApprovalLevel > 0 ? rowData.approvalStatus === 2 : rowData.approvalStatus === 0) && rowData.detailList.length && rowData.status !== 2 && rowData.headerType === 0"
              inline,
              primary,
              size="small"
              :isLoading="isDetailFetching"
              iconName="icon-btn-success",
              iconClass="icon-btn-success",
              @click="itemSaved(rowData)")
                span {{ $t('DirectOrders.createDirectOrder') }}
            Button.m-right-20(
              size="small"
              id="nested-detail-send-to-approve",
              v-if="Tenant.approvalOrderPermission && (rowData.approvalStatus === 0 && rowData.maxApprovalLevel > 0 || (rowData.approvalStatus === 3 && rowData.maxApprovalLevel > 0))"
              inline,
              primary,
              iconName="icon-btn-success",
              iconClass="icon-btn-success",
              @click="sendToApprove(rowData)")
                span {{ $t('DirectOrders.sendToApprove') }}

            Button.m-right-20(
              id="nested-detail-send-to-approve",
              v-if="rowData.status !== 2 && rowData.approvalStatus === 2 && rowData.headerType === 1 && rowData.maxApprovalLevel > 0"
              inline,
              primary,
              size="small"
              iconName="icon-btn-success",
              iconClass="icon-btn-success",
              @click="completeOrder(rowData)")
                span {{ $t('DirectOrders.completeOrder') }}
      router-view(
        @getDirectOrdersList="getDirectOrdersList"
        @showDrafts="showDrafts = true"
      )
      exportPopup(
        v-if="isExportPopupVisible"
        :templateId="templateId"
        :templateType="templateType"
        ref="exportPopupTemplate"
      )
  template(slot="footer")
    TableFoot(
      v-if="!isEmptyDirectOrders && !isFetchingList",
      :page="Page"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
    )



</template>
<script>
import moment from 'moment'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import { getDirectOrdersHeaderFields, getDirectOrdersDetailFields } from './fields/directOrders'
import exportPopup from '@/view/global/export-popup'
//
import storeFilter from '@/view/global/business-filters/store.vue'
import TableFoot from '@/view/global/table/foot.vue'
import NoPermission from '@/view/global/has-not-permission'
import ApprovalLevels from '@/view/global/approval-levels'
import hasTabPermission from '@/mixins/hasTabPermission'
import TotalPreview from '@/view/pages/Requests/components/total-preview'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import settingsExportPopup from '@/mixins/settingsExportPopup'

export default {
  name: 'DirectOrders',
  mixins: [hasTabPermission, settingsExportPopup],
  data () {
    return {
      dates: [],
      search: null,
      selectedStockItem: [],
      storeFilter: [],
      directOrdersHeaderFields: getDirectOrdersHeaderFields(this.$t),
      directOrdersDetailFields: getDirectOrdersDetailFields(this.$t, this.$getConst),
      historyListForOrders: [],
      isOnlyListUsage: false,
      showDrafts: true
    }
  },
  components: {
    storeFilter,
    TableFoot,
    NoPermission,
    TotalPreview,
    exportPopup,
    ApprovalLevels
  },
  computed: {
    ...mapGetters('Request', [
      'requestHeaderLinks'
    ]),
    ...mapGetters('DirectOrders', [
      'isEmptyDirectOrders',
      'directOrders',
      'Page'
    ]),
    ...mapGetters('Settings', ['userApprovalLevel', 'permissions']),

    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    ...mapGetters('Auth', ['Tenant']),

    isSuggestionLoading () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    isDetailFetching () {
      return this.$wait.is('fetchDirectOrderDetail')
    },
    isHeaderCreating () {
      return this.$wait.is('createDirectOrderHeader')
    },

    isHistoryLoading () {
      return this.$wait.is('showHistory')
    },
    isFetchingList () {
      return this.$wait.is(['fetchDirectOrderHeaderAll', 'pageLoading'])
    }
  },
  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    ...mapActions('DirectOrders', [
      'fetchDirectOrderHeaderAll',
      'fetchDirectOrderDetail',
      'createDirectOrderHeader',
      'updateDirectOrderDetail',
      'makeDirectOrderFinalize',
      'fetchDirectOrderInfo',
      'sendDirectOrderToApprove',
      'completeApprovedOrder',
      'showHistory'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Auth', ['getTenant']),

    ...mapMutations('DirectOrders', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'UPDATE_DIRECT_REQUEST_ITEM'
    ]),

    ...mapActions('DocumentSettings', ['getDocumentFilterSettings']),

    disableFinalize (header) {
      let checkingForPrice = false
      if (header.detailList.length > 0) {
        checkingForPrice = header.detailList.every(item => {
          return item.isZeroPriceAvailableForSupplier === true
        })
      }
      if (checkingForPrice) return false
      else return header.priceQuoteTotalAmount <= 0
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getDirectOrdersList()
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_DirectRequest') this.showDrafts = i.isChecked
      })
    },

    async showHistoryPopup (header) {
      const payload = {
        headerId: header.id,
        approvalHistoryType: header.headerType
      }
      const res = await this.showHistory(payload)
      this.historyListForOrders = res.data.approvalHistoryList
      this.historyListForOrders.map(i => {
        i.isApproved === true ? i.isApproved = this.$t('Global.approved') : i.isApproved = this.$t('Global.declined')
        i.isOutOfOffice === true ? i.isOutOfOffice = i.isOutOfOffice = this.$t('Global.outOfOffice') : i.isOutOfOffice = this.$t('Global.working')
      })
    },

    async newHeader () {
      this.$router.push({
        name: 'DirectOrdersNew'
      })
      // const res = await this.createDirectOrderHeader()
      // if (res) {
      //   await this.getDirectOrdersList()
      //   this.$router.push({
      //     name: 'DirectOrdersDetailNew',
      //     params: {
      //       id: res.data.id,
      //       code: res.data.code
      //     }
      //   })
      // }
    },
    async editHeader (directOrder) {
      this.$router.push({
        name: 'DirectOrdersUpdate',
        params: {
          id: directOrder.id,
          headerType: directOrder.headerType
        }
      })
    },

    getApprovalStatus (status) {
      switch (status) {
        case 0:
          return '-'
        case 1:
          return this.$t('Request.table_Header_field_StatusWaitingForApproval')
        case 2:
          return this.$t('ApproveOperations.approval_Notification_message')
        case 3:
          return this.$t('Global.declined')
      }
    },

    directOrdersDetailEdit (item) {
      this.$router.push({
        name: 'DirectOrdersDetailEdit',
        params: {
          id: item.headerId,
          code: item.code,
          detailId: item.id,
          headerType: item.headerType
        }
      })
    },

    async sendToApprove (item) {
      const payload = {
        directRequestHeaderId: item.id,
        headerType: item.headerType
      }
      const res = await this.sendDirectOrderToApprove(payload)
      if (res) {
        const message = this.$t('Request.requestSentToApprove')
        this.notifyShow({ message })
        this.getDirectOrdersList()
      }
    },

    async completeOrder (item) {
      const payload = {
        id: item.id
      }
      const res = await this.completeApprovedOrder(payload)
      if (res) {
        const message = this.$t('Request.orderCompleted')
        this.notifyShow({ message })
        this.getDirectOrdersList()
      }
    },

    onChangeSearch (search) {
      if (!search || search.length < 2) {
        this.resetStockItemFilter()
        this.clearSearch()
      } else {
        this.searchStockItem({ text: search, types: [1, 2, 4] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },

    clearSearch () {
      this.search = null
      this.stockItem = {}
    },
    resetStockItemFilter () {
      this.selectedStockItem = []
      this.getDirectOrdersList()
    },
    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.resetStockItemFilter()
    },

    itemSaved: vueWaitLoader(async function (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
      }
      const res = await this.makeDirectOrderFinalize(item.id)
      if (res) {
        const message = this.$t('DirectOrders.SavedSuccessMessage')
        this.notifyShow({ message })
        this.showExportPopup(item, true)
        await this.getDirectOrdersList()
        await this.fetchDirectOrderDetail({headerId: item.id, headerType: item.headerType})
      }
    }, 'submitForm'),

    getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.storeFilter = list
        this.getDirectOrdersList()
      }
    },

    async directOrdersDetailNew (directOrder) {
      this.$router.push({
        name: 'DirectOrdersDetailNew',
        params: {
          id: directOrder.id,
          code: directOrder.code,
          headerType: directOrder.headerType
        }
      })
    },

    changePage (page) {
      this.getDirectOrdersList(page)
    },

    changePageSize (pageSize) {
      this.getDirectOrdersList(1, pageSize)
    },

    removeItemAction (item) {
      this.$router.push({
        name: 'DirectOrdersRemove',
        params: {
          id: item.id,
          code: item.code
        }
      })
    },
    removeItemDetailAction (item) {
      this.$router.push({
        name: 'DirectOrdersDetailRemove',
        params: {
          id: item.headerId,
          detailId: item.id,
          headerType: item.headerType
        }
      })
    },
    async showDetail (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
        return
      }
      await this.fetchDirectOrderDetail({headerId: item.id, headerType: item.headerType}).then(res => {
        if (res) {
          this.SHOW_DETAIL({ headerId: item.id, headerType: item.headerType, data: res.data })
        }
      })
    },
    getDirectOrdersList (page = 1, pageSize = this.Page.size) {
      const payload = {
        storeIdList: this.storeFilter ? this.storeFilter.map(s => s.id) : [],
        stockItemId: this.stockItem ? this.stockItem.id : null,
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        pageNumber: page,
        pageSize,
        showDrafts: this.showDrafts
      }
      return this.fetchDirectOrderHeaderAll(payload)
    },

    clearFilter () {
      this.$refs.requestsStoreFilter.clearStoreFilter(false)
    },

    async updateDetailItems (item) {
      const payload = {
        ...item,
        requestDate: moment(item.requestDate).format('YYYY-MM-DD')
      }
      await this.updateDirectOrderDetail(payload)
        .then(res => {
          if (res) {
            const message = this.$t('DirectOrders.updateSuccesMessage')
            this.notifyShow({ message })
            const requestHeader = this.directOrders.find(i => i.id === item.headerId)
            this.fetchDirectOrderDetail({headerId: requestHeader.id, headerType: requestHeader.headerType})
          }
        })
    },
    showExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 5, isFinalizeAction, 'DirectRequest')
    }
  },
  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getDirectOrdersList()
    }
  },
  async mounted () {
    if (!this.hasTabPermission) return
    this.$wait.start('pageLoading')
    await this.getTenant({id: this.Tenant.id})
    await this.setShowDraftStatus()
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_DirectRequest')
    await this.getDirectOrdersList()
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    this.$wait.end('pageLoading')
  }
}
</script>

<style lang="scss" scoped>

  .PageHeader {
    padding: 0;
    .right {
      display: flex;
      align-self: center;
      .form-item-select {
        min-width: 200px;
        margin-right: 20px;
        flex-shrink: 0;
      }
    }
  }
  :deep().NestedDetail-row{
    padding: 20px !important;
    &.list-item{
      height: auto !important;
    }
    &-col{
      word-break: break-word;
    }
  }
</style>
