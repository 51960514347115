var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-left-search" }, [
        _c("div", { staticClass: "Search-input" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.hidePopup,
                  expression: "hidePopup",
                },
              ],
              staticClass: "Search-type-switcher",
            },
            [
              _c(
                "Button",
                {
                  staticClass: "Search-type-switcher-button",
                  attrs: {
                    size: "small",
                    id: "btn-recipes-search-type-switcher",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showSearchTypePopup = !_vm.showSearchTypePopup
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`Recipes.search_CriteriaType_${_vm.searchType}`)
                    )
                  ),
                  _c("Icon", {
                    staticClass: "Search-type-switcher-button-icon",
                    attrs: { name: "icon-down-arrow" },
                  }),
                ],
                1
              ),
              _vm.showSearchTypePopup
                ? _c(
                    "div",
                    {
                      staticClass:
                        "MiniPopup Search-type-switcher-popup recipe-search-popup",
                    },
                    [
                      _c(
                        "h2",
                        { staticClass: "Search-type-switcher-popup-header" },
                        [_vm._v(_vm._s(_vm.$t("Recipes.search_Criteria")))]
                      ),
                      _vm._l(_vm.searchTypes, function (types, index) {
                        return _c(
                          "div",
                          {
                            staticClass:
                              "MiniPopup-tr Search-type-switcher-popup-row",
                            attrs: {
                              id: `search-type-switcher-button-${index}`,
                              tabindex: "0",
                            },
                            on: {
                              click: (e) => _vm.switchSearchType(e, types),
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return ((e) =>
                                  _vm.switchSearchType(e, types)).apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "MiniPopup-td" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "Search-type-switcher-check",
                                  class: { active: types === _vm.searchType },
                                  attrs: {
                                    id: `search-type-switcher-text-${index}`,
                                  },
                                },
                                [
                                  types === _vm.searchType
                                    ? _c("Icon", {
                                        attrs: {
                                          name: "icon-custom-checkbox-tick",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(
                                _vm._s(
                                  _vm.$t(`Recipes.search_CriteriaType_${types}`)
                                )
                              ),
                            ]),
                          ]
                        )
                      }),
                      _vm.searchType === "byStockItem"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "MiniPopup-tr Search-type-switcher-popup-row alternative-checkbox m-left-15",
                            },
                            [
                              _c("CustomCheckbox", {
                                attrs: {
                                  id: "search-type-show-alternative",
                                  label: _vm.$t(
                                    "Recipes.search_showAlternative"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getRecipesByStockItem(
                                      _vm.queryStockItem
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.showAlternative,
                                  callback: function ($$v) {
                                    _vm.showAlternative = $$v
                                  },
                                  expression: "showAlternative",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "Search-input-container" },
            [
              _c("Icon", {
                staticClass: "Search-input-icon-recipe",
                attrs: { name: "icon-search" },
              }),
              _vm.search
                ? _c(
                    "Button",
                    {
                      staticClass: "Search-input-close",
                      attrs: {
                        variant: "icon",
                        id: "btn-recipes-search-clear",
                        iconName: "icon-popup-close",
                        iconClass: "icon-popup-close",
                        justIcon: true,
                      },
                      on: { click: _vm.clearSearch },
                    },
                    [_vm.isSuggestionLoading ? _c("Loading") : _vm._e()],
                    1
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                ref: "searchInput",
                staticClass: "txt Search-input-txt",
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  id: "input-recipes-search-placeholder",
                  placeholder: _vm.$t("Recipes.search_Placeholder"),
                },
                domProps: { value: _vm.search },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.search = $event.target.value
                  },
                },
              }),
            ],
            1
          ),
          !_vm.suggestionsIsEmpty
            ? _c(
                "div",
                { staticClass: "Search-suggestion" },
                [
                  _c("h5", { staticClass: "Search-suggestion-title" }, [
                    _vm._v(_vm._s(_vm.$t("Recipes.search_SuggestionTitle"))),
                  ]),
                  _vm._l(_vm.suggestions, function (suggestion, index) {
                    return _c(
                      "Button",
                      {
                        key: suggestion.id,
                        staticClass: "Search-suggestion-item",
                        attrs: {
                          size: "small",
                          id: `btn-recipes-search-suggestion-item-${index}`,
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.searchType === "byStockItem"
                              ? _vm.getRecipesByStockItem(suggestion)
                              : _vm.getRecipesByRecipe(suggestion)
                          },
                        },
                      },
                      [
                        _c("strong", { staticClass: "name" }, [
                          _vm._v(_vm._s(suggestion.name)),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isRecipesEmpty && !_vm.isRecipesLoading,
                  expression: "!isRecipesEmpty && !isRecipesLoading",
                },
              ],
              attrs: {
                primary: "",
                size: "medium",
                id: "btn-recipes-new",
                type: "button",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.itemNew },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Recipes.addRecipeButton")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.typeList,
              selectedItem: _vm.selectedRecipeType,
              useUpperCase: true,
            },
            on: { delegateChangeSelectedItem: _vm.changedRecipeType },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchType === "byName",
                expression: "searchType === 'byName'",
              },
            ],
            attrs: {
              id: "stock-reports-recipe-cost-calculation-filter",
              items: _vm.recipeCostCalculationList,
              selected: _vm.recipeCostCalculationFilter,
              label: _vm.$t("Recipes.toolbar_Cost_Calculation_Popup_Title"),
              popupHeader: _vm.$t(
                "Recipes.toolbar_Cost_Calculation_PopupHeader"
              ),
              showAll: false,
              buttonText: _vm.$t("Recipes.toolbar_Cost_Calculation_ButtonText"),
              type: "radio",
              updateDropDownLabelFromParent: _vm.updateDropDownLabelFromParent,
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckItem: _vm.onChangeRecipeCostCalculationFilter,
              delegateSubmitItems: _vm.getRecipeListWithParams,
            },
          }),
          _c("RecipeGroupsFilter", {
            ref: "recipeGroupsSupplierFilter",
            staticClass: "m-left-10",
            attrs: { customSelectedData: _vm.selectedRecipeGroups },
            on: { submitFilter: _vm.getListWithSupplierFilter },
          }),
          _c(
            "Button",
            {
              staticClass:
                "clear-filter-button border-gray-1 p-0 width-auto bgc-white m-right-10",
              attrs: { size: "small" },
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "checkbox-container" },
            [
              _c("CustomCheckbox", {
                staticClass: "checkbox-show-ungrouped",
                attrs: {
                  id: "checkbox-show-ungrouped",
                  label: _vm.$t("Recipes.Checkbox_Show_ungrouped"),
                },
                model: {
                  value: _vm.showUnGroups,
                  callback: function ($$v) {
                    _vm.showUnGroups = $$v
                  },
                  expression: "showUnGroups",
                },
              }),
              _c("CustomCheckbox", {
                staticClass: "show-passive-recipes m-left-10",
                attrs: {
                  id: "checkbox-recipes-show-passive",
                  checked: _vm.showPassive,
                  label: _vm.$t("Recipes.showPassiveRecipes"),
                },
                on: { change: _vm.showPassiveRecipes },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _vm.searchType === "byName"
            ? _c("ViewType", {
                staticClass: "view-type",
                attrs: {
                  firstNodeIcon: "icon-view-type-list",
                  secondNodeIcon: "icon-view-type-group",
                  firstSelected: _vm.viewTypeList,
                },
                on: {
                  firstNodeClick: function ($event) {
                    return _vm.listViewType(true)
                  },
                  secondNodeClick: function ($event) {
                    return _vm.listViewType(false)
                  },
                },
              })
            : _vm._e(),
          _vm.searchType === "byStockItem" &&
          _vm.checkPermission("RecipeBulkContentEdit")
            ? _c(
                "Button",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeRecipeContentPopup,
                      expression: "closeRecipeContentPopup",
                    },
                  ],
                  staticClass: "change-recipe-content m-right-10",
                  attrs: {
                    size: "small",
                    secondary: "",
                    id: "change-recipe-content",
                    disabled: !_vm.selectedRecipes.length,
                  },
                  on: { click: _vm.changeRecipeContent },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("Recipes.changeContent")))]),
                  _c(
                    "div",
                    {
                      staticClass: "change-recipe-content-popup",
                      class: _vm.showRecipeContentChangePopup
                        ? "show-recipe-content-popup"
                        : null,
                    },
                    [
                      _c(
                        "h2",
                        { staticClass: "change-recipe-content-popup-header" },
                        [_vm._v(_vm._s(_vm.$t("Recipes.recipeContentChange")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "change-recipe-content-popup-contents m-top-10",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "change-recipe-content-popup-contents-stock-item",
                            },
                            [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("Recipes.newStockItem"))),
                              ]),
                              _c("custom-search", {
                                ref: "stockItem",
                                attrs: {
                                  "component-name":
                                    "recipe-change-content-stock-item",
                                  name: "stockItem",
                                  id: "change-recipe-content-search-stock-item",
                                  "on-search": _vm.getRecipeStockItemOptions,
                                  options: _vm.newRecipeStockItemSelectOptions,
                                  label: "name",
                                  placeholder: _vm.$t(
                                    "Recipes.searchStockItem"
                                  ),
                                },
                                model: {
                                  value: _vm.newStockItem,
                                  callback: function ($$v) {
                                    _vm.newStockItem = $$v
                                  },
                                  expression: "newStockItem",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "change-recipe-content-popup-contents-factor",
                            },
                            [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("Recipes.factor"))),
                              ]),
                              _c("customNumberInput", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "greater_than:0",
                                    expression: "'greater_than:0'",
                                  },
                                ],
                                staticClass: "number-input",
                                attrs: {
                                  name: "changedRecipeContentFactor",
                                  id: "input-recipe-content-factor",
                                  placeholder: "0",
                                  error: _vm.veeErrors.has(
                                    "changedRecipeContentFactor"
                                  ),
                                  "data-vv-as": _vm.$t(
                                    "Recipes.changedRecipeContentFactor"
                                  ),
                                },
                                model: {
                                  value: _vm.changedRecipeFactor,
                                  callback: function ($$v) {
                                    _vm.changedRecipeFactor = $$v
                                  },
                                  expression: "changedRecipeFactor",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "Button",
                            {
                              staticClass: "m-top-15",
                              attrs: {
                                size: "full",
                                primary: "",
                                id: "submit-recipe-content-changes",
                                disabled:
                                  !this.changedRecipeFactor &&
                                  !this.newStockItem,
                              },
                              on: { click: _vm.updateSelectedRecipesContent },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("Global.apply"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.searchType === "byStockItem" &&
          _vm.checkPermission("RecipeBulkContentEdit")
            ? _c(
                "Button",
                {
                  staticClass: "change-recipe-content m-right-10",
                  attrs: {
                    size: "small",
                    secondary: "",
                    id: "delete-recipe-content",
                    disabled: !_vm.selectedRecipes.length,
                  },
                  on: { click: _vm.deleteSelectedRecipeContent },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("Recipes.deleteContent")))])]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _c(
            "div",
            {
              staticClass: "Recipes",
              class: { blur: _vm.apllyBlurToBackground },
            },
            [
              _vm.isRecipesByStockItemLoading ||
              _vm.isRecipesLoading ||
              _vm.isRecipesByRecipeLoading
                ? _c("TableLoading")
                : _vm._e(),
              _vm.searchType === "byName" &&
              _vm.isRecipesEmpty &&
              !_vm.isRecipesLoading
                ? _c("EmptyState", {
                    attrs: {
                      title: _vm.$t("Recipes.emptyState_Title"),
                      description: _vm.$t("Recipes.emptyState_Description"),
                      buttonText: _vm.$t("Recipes.emptyState_ButtonText"),
                    },
                    on: { emptyAddButton: _vm.itemNew },
                  })
                : _vm._e(),
              !_vm.isRecipesEmpty &&
              !_vm.isRecipesLoading &&
              _vm.viewTypeList &&
              _vm.searchType === "byName"
                ? _c("TableView", {
                    attrs: {
                      fields: _vm.getRecipeFields,
                      actions: ["edit", "remove", "copy", "active"],
                      data: _vm.recipes_List,
                      page: _vm.Page,
                      componentName: "RecipesTable",
                    },
                    on: {
                      delegateOnChangePage: _vm.changePage,
                      delegateOnChangePageSize: _vm.changePageSize,
                      delegateOnItemShow: _vm.goToRecipe,
                      delegateOnItemCopy: _vm.copyRecipe,
                      delegateOnItemEdit: _vm.editRecipe,
                      delegateOnItemChangeActiveStatus: _vm.changeActiveStatus,
                      delegateOnItemDelete: _vm.removeRecipe,
                      sort: _vm.sort,
                    },
                  })
                : _vm._e(),
              !_vm.isRecipesEmpty &&
              !_vm.isRecipesLoading &&
              !_vm.viewTypeList &&
              _vm.searchType === "byName"
                ? _c("BoxView", {
                    attrs: {
                      fields: _vm.fields,
                      actions: ["copy", "edit", "remove", "active"],
                      data: _vm.recipes_List,
                      page: _vm.Page,
                      extraClass: _vm.extraClass,
                      showPassiveBox: true,
                      componentName: "RecipesBox",
                    },
                    on: {
                      delegateOnItemShow: _vm.goToRecipe,
                      delegateOnChangePage: _vm.changePage,
                      delegateOnChangePageSize: _vm.changePageSize,
                      delegateOnItemCopy: _vm.copyRecipe,
                      delegateOnItemEdit: _vm.editRecipe,
                      delegateOnItemRemove: _vm.removeRecipe,
                      delegateOnItemChangeActiveStatus: _vm.changeActiveStatus,
                    },
                  })
                : _vm._e(),
              _c("portal", {
                attrs: { to: "action-buttons" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return _vm.isRecipesByStockItemEmpty &&
                          !_vm.isRecipesByStockItemLoading
                          ? _c(
                              "div",
                              {},
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass:
                                      "c-light m-right-15 show-MiniPopup relative",
                                    attrs: {
                                      size: "small",
                                      id: `box-list-RecipesBox-row-${row.index}-hover`,
                                      iconName: "icon-global-show-allergen",
                                      iconClass:
                                        "icon-global-show-allergen border-warning-1",
                                      justIcon: true,
                                      clearVariant: true,
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.showAllergen(row)
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.showAllergenLeave()
                                      },
                                    },
                                  },
                                  [
                                    _vm.isShowAllergen && _vm.viewTypeList
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "MiniPopup-left-primary m-top-15 c-dark custom-mini-popup-table",
                                          },
                                          [
                                            _c("IconBox", {
                                              attrs: {
                                                data: _vm.recipeAllergenList,
                                                isDataLoading:
                                                  _vm.isLoadingAlergenList,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isShowAllergen && !_vm.viewTypeList
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "MiniPopup-center-primary m-top-15 c-dark custom-mini-popup",
                                          },
                                          [
                                            _c("IconBox", {
                                              attrs: {
                                                data: _vm.recipeAllergenList,
                                                isDataLoading:
                                                  _vm.isLoadingAlergenList,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("portal", {
                attrs: { to: "action-calorie" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return row.hasNutritionValues
                          ? _c(
                              "div",
                              {},
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass:
                                      "action-calorie-btn c-light show-MiniPopup relative",
                                    attrs: {
                                      size: "small",
                                      id: `box-list-RecipesBox-row-calorie-${row.index}-hover`,
                                      iconName: "icon-calorie",
                                      iconClass: "icon-calorie",
                                      justIcon: true,
                                      clearVariant: true,
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.showTotalCalorie(row)
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.showCalorieLeave()
                                      },
                                    },
                                  },
                                  [
                                    _vm.isShowCalorie && _vm.viewTypeList
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "MiniPopup-left-primary m-top-15 c-dark custom-mini-popup-table calorie-box",
                                          },
                                          [
                                            _vm.isLoadingTotalCalorie
                                              ? _c("Loading")
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.calorieList
                                                        .totalAmount
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.calorieList
                                                          .nutritionFieldUnitName
                                                      )
                                                  ),
                                                ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isShowCalorie && !_vm.viewTypeList
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "MiniPopup-center-primary m-top-15 c-dark custom-mini-popup calorie-box",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.calorieList.totalAmount
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.calorieList
                                                      .nutritionFieldUnitName
                                                  )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm.searchType === "byStockItem"
                ? _c(
                    "div",
                    { staticClass: "Receteler-by-stock-item" },
                    [
                      _c("div", { staticClass: "Result" }, [
                        _c("div", { staticClass: "Result-top-header" }, [
                          !_vm.isRecipesByStockItemEmpty &&
                          !_vm.isRecipesByStockItemLoading
                            ? _c(
                                "div",
                                { staticClass: "Result-top-header-title" },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("Recipes.result_Header", {
                                          stockItemName:
                                            _vm.queryStockItem.name,
                                        })
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      !_vm.isRecipesByStockItemEmpty &&
                      !_vm.isRecipesByStockItemLoading
                        ? _c(
                            "div",
                            [
                              _c("TableView", {
                                attrs: {
                                  fields: _vm.recipesByStockItemFields,
                                  data: _vm.recipes_by_stock_item_List,
                                  componentName: "RecipesByStockTable",
                                  uniqueIdentifier: "recipeDetailId",
                                  readOnly: true,
                                  selectable: _vm.checkPermission(
                                    "RecipeBulkContentEdit"
                                  )
                                    ? "checkbox"
                                    : "",
                                  selectableAll: _vm.checkPermission(
                                    "RecipeBulkContentEdit"
                                  )
                                    ? "checkbox"
                                    : "",
                                  selectedRows: _vm.selectedRecipes,
                                  tableName: _vm.$t("Recipes.result_TableName"),
                                  pointer: true,
                                },
                                on: {
                                  delegateOnItemShow: _vm.goToRecipe,
                                  delegateOnItemSelect: _vm.onSelectRecipe,
                                  showAllSelectedItems: _vm.onSelectRecipeAll,
                                },
                              }),
                              _c("portal", { attrs: { to: "headerType" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "category portal-header-type row-alternative-stock",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "category-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "Recipe.tableColumn_Ingredients_Type"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "category-info" },
                                      [
                                        _c("Icon", {
                                          staticClass:
                                            "show-MiniPopup category-info-icon",
                                          attrs: {
                                            name: "icon-global-info-filled",
                                          },
                                        }),
                                        _c(
                                          "ul",
                                          { staticClass: "category-popup" },
                                          _vm._l(
                                            _vm.popupInfoObject,
                                            function (item) {
                                              return _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "category-popup-row",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "category-popup-value stock-type",
                                                      class: `${item.name}`,
                                                    },
                                                    [_vm._v(_vm._s(item.value))]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "category-popup-text",
                                                    },
                                                    [_vm._v(_vm._s(item.text))]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _c("portal", {
                                attrs: { to: "isAlternative" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row-alternative-stock",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "stock-type",
                                                class: row.isAlternative
                                                  ? "alternative"
                                                  : "normal",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    row.isAlternative
                                                      ? _vm.$t(
                                                          "Recipes.stock_type_alternative"
                                                        )
                                                      : _vm.$t(
                                                          "Recipes.stock_type_normal"
                                                        )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  132254948
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.shouldShowEmptyStateForRecipeSearch
                        ? _c("EmptyState", {
                            attrs: {
                              title: _vm.$t(
                                "Recipes.emptyState_NotFound_Title"
                              ),
                              description: _vm.$t(
                                "Recipes.emptyState_NotFound_Description"
                              ),
                              buttonText: _vm.$t(
                                "Recipes.emptyState_ButtonText"
                              ),
                            },
                            on: { emptyAddButton: _vm.itemNew },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.searchType === "byRecipe"
                ? _c(
                    "div",
                    { staticClass: "Receteler-by-stock-item" },
                    [
                      _c("div", { staticClass: "Result" }, [
                        _c("div", { staticClass: "Result-top-header" }, [
                          !_vm.isRecipesByRecipeEmpty &&
                          !_vm.isRecipesByRecipeLoading
                            ? _c(
                                "div",
                                { staticClass: "Result-top-header-title" },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "Recipes.result_Header_ByRecipe",
                                          { recipeName: _vm.queryRecipe.name }
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      !_vm.isRecipesByRecipeEmpty &&
                      !_vm.isRecipesByRecipeLoading
                        ? _c(
                            "div",
                            [
                              _c("TableView", {
                                attrs: {
                                  fields: _vm.recipesByRecipeFields,
                                  data: _vm.recipesByRecipe,
                                  componentName: "RecipesByStockTable",
                                  readOnly: true,
                                  tableName: _vm.$t("Recipes.result_TableName"),
                                  pointer: true,
                                },
                                on: { delegateOnItemShow: _vm.goToRecipe },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.shouldShowEmptyStateForRecipeSearch
                        ? _c("EmptyState", {
                            attrs: {
                              title: _vm.$t(
                                "Recipes.emptyState_NotFound_Title"
                              ),
                              description: _vm.$t(
                                "Recipes.emptyState_NotFound_Description"
                              ),
                              buttonText: _vm.$t(
                                "Recipes.emptyState_ButtonText"
                              ),
                            },
                            on: { emptyAddButton: _vm.itemNew },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("router-view", { on: { getList: _vm.getRecipeListWithParams } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }