export function getHeaderFields($t) {
  return [
    {
      name: 'scheduleTypeName',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Header_Col_scheduleTypeName')
      },
      get title() {
        return $t('ScheduledJob.Header_Col_scheduleTypeName')
      }
    },
    {
      name: 'supplier',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Header_Col_supplier')
      },
      get title() {
        return $t('ScheduledJob.Header_Col_supplier')
      }
    },
    {
      name: 'period',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Header_Col_period')
      },
      get title() {
        return $t('ScheduledJob.Header_Col_period')
      }
    },
    {
      name: 'operation',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Header_Col_operation')
      },
      get title() {
        return $t('ScheduledJob.Header_Col_operation')
      }
    },
    {
      name: 'nextScheduledDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Header_Col_nextScheduledDate')
      },
      get title() {
        return $t('ScheduledJob.Header_Col_nextScheduledDate')
      }
    },
    {
      name: 'lastExecutionDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Header_Col_lastExecutionDate')
      },
      get title() {
        return $t('ScheduledJob.Header_Col_lastExecutionDate')
      }
    },
    {
      name: 'isActive',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Header_Col_isActive')
      },
      get title() {
        return $t('ScheduledJob.Header_Col_isActive')
      }
    }
  ]
}

export function getDetailFields($t) {
  return [
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_storeName')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_storeName')
      }
    },
    {
      name: 'operation',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_operation')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_operation')
      }
    },
    {
      name: 'executionDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_executionDate')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_executionDate')
      }
    },
    {
      name: 'message',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_message')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_message')
      }
    },
    {
      name: 'situation',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('ScheduledJob.Detail_Col_situation')
      },
      get title() {
        return $t('ScheduledJob.Detail_Col_situation')
      }
    }
  ]
}
