var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _vm.hasTabPermission
        ? _c("template", { slot: "header-right-dropdown" }, [
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("CustomDatepicker", {
                  staticClass: "m-right-10",
                  attrs: {
                    id: "YieldDatepicker",
                    range: true,
                    disabled: _vm.isFetchingData,
                  },
                  model: {
                    value: _vm.dates,
                    callback: function ($$v) {
                      _vm.dates = $$v
                    },
                    expression: "dates",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    iconName: "icon-global-add",
                    id: "add-yield-header-button",
                  },
                  on: { click: _vm.addNewYieldHeader },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("Yield.add_yield_header")))])]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeFilterComponent", {
            ref: "yieldStoreFilter",
            staticClass: "store-filter",
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "show-drafts m-left-15" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-yield-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                { staticClass: "Yield" },
                [
                  _vm.isFetchingData ? _c("TableLoading") : _vm._e(),
                  !_vm.isFetchingData && _vm.isFetchDataEmpty
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t(
                            "Yield.inventories_emptyState_NotFound_Title"
                          ),
                          description: _vm.$t(
                            "Yield.inventories_emptyState_NotFound_Description"
                          ),
                          buttonText: _vm.$t("Yield.add_new_header"),
                        },
                        on: { emptyAddButton: _vm.addNewYieldHeader },
                      })
                    : _vm._e(),
                  !_vm.isFetchingData && !_vm.isFetchDataEmpty
                    ? _c(
                        "div",
                        [
                          _c("NestedTable", {
                            attrs: {
                              tableKey: "yield",
                              fields: _vm.yieldHeaderFields,
                              detailFields: _vm.yieldDetailFields,
                              data: _vm.Yields,
                              isDetailLoading: _vm.isDetailLoading,
                              actions: ["edit", "delete", "customAction"],
                              detailActions: ["edit", "delete"],
                              addButtonText: _vm.$t(
                                "Yield.detail_NewStockItemButton"
                              ),
                              finalizeButtonText: _vm.$t(
                                "Yield.yieldFinalizeButton"
                              ),
                              isLoadingFinalize: _vm.$wait.is("yieldSaved"),
                              disableNewDetailButton: _vm.isOnlyListUsage,
                              useHeaderPortalAction: true,
                              usePortalFooter: true,
                              selectable: "checkbox",
                              selectableAll: "checkbox",
                              selectedRows: _vm.selectedList,
                            },
                            on: {
                              finalizeAction: _vm.yieldSaved,
                              editHeaderAction: _vm.editYieldHeader,
                              deleteHeaderAction: _vm.removeItemAction,
                              addDetailAction: _vm.addNewYieldDetail,
                              showDetailAction: _vm.showYieldDetail,
                              editDetailAction: _vm.yieldDetailEdit,
                              selectItem: _vm.onSelectYield,
                              selectAllItems: _vm.onSelectYieldAll,
                              deleteDetailAction: _vm.yieldDetailRemove,
                            },
                          }),
                          _c("portal", {
                            attrs: { to: "actionPortal" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (rows) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "popup-container flexbox",
                                      },
                                      [
                                        _c("ActionButton", {
                                          attrs: {
                                            data: rows.row
                                              .expirationDateControlIsEnabled
                                              ? [
                                                  "edit",
                                                  "delete",
                                                  "customAction",
                                                ]
                                              : ["edit", "delete"],
                                            item: rows.row,
                                            customActionName: _vm.$t(
                                              "Transfer.editExpirationDateShort"
                                            ),
                                            customActionIconName:
                                              "icon-global-edit",
                                            customActionIconClass:
                                              "icon-global-edit",
                                          },
                                          on: {
                                            onItemCustomAction: function (
                                              $event
                                            ) {
                                              return _vm.editCustomYieldHeader(
                                                rows.row
                                              )
                                            },
                                            onItemDelete: function ($event) {
                                              return _vm.removeItemAction(
                                                rows.row
                                              )
                                            },
                                            onItemEdit: function ($event) {
                                              return _vm.editYieldHeader(
                                                rows.row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3584339709
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "footerPortal" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (rowData) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "popup-container flexbox",
                                      },
                                      [
                                        _vm.isExpireDateControlEnabled(
                                          rowData
                                        ) && rowData.status === 1
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass: "m-right-20",
                                                attrs: {
                                                  disabled:
                                                    !rowData.detailList.length,
                                                  id: "btn-yield-expire-date-edit",
                                                  type: "button",
                                                  size: "small",
                                                  secondary: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editExpireDate(
                                                      rowData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Transfer.editExpireDate"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          [
                                            rowData.status === 1
                                              ? _c(
                                                  "Button",
                                                  {
                                                    staticClass: "m-right-20",
                                                    attrs: {
                                                      id: "nested-detail-yield-add-button",
                                                      size: "small",
                                                      inline: "",
                                                      primary: "",
                                                      iconName:
                                                        "icon-global-add",
                                                      iconClass:
                                                        "icon-global-add",
                                                      disabled:
                                                        _vm.isOnlyListUsage,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addNewYieldDetail(
                                                          rowData
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Global.addNewStock"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            rowData.status === 1
                                              ? _c(
                                                  "Button",
                                                  {
                                                    staticClass: "m-right-20",
                                                    attrs: {
                                                      id: "yield-nested-detail-finalize",
                                                      inline: "",
                                                      size: "small",
                                                      primary: "",
                                                      iconName:
                                                        "icon-btn-success",
                                                      iconClass:
                                                        "icon-btn-success",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.yieldSaved(
                                                          rowData
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Global.Save")
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3528976414
                            ),
                          }),
                          _c("TableFoot", {
                            attrs: { page: _vm.Page },
                            on: {
                              delegateOnChangePage: _vm.changePage,
                              delegateOnChangePageSize: _vm.changePageSize,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c(
                        "Modal",
                        {
                          attrs: {
                            isOpen: _vm.showBatchHeader,
                            showCloseButton: false,
                          },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("div", { staticClass: "modal-header" }, [
                              _c("div", { staticClass: "modal-header-left" }, [
                                _c("div", { staticClass: "transfer" }, [
                                  _c("div", { staticClass: "transfer-code" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Yield.documentNumber")) +
                                        ": " +
                                        _vm._s(_vm.yieldHeader?.code)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "transfer-date" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Yield.documentDate")) +
                                        ": " +
                                        _vm._s(
                                          _vm.getFormattedDate(_vm.yieldHeader)
                                        )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "template",
                            { slot: "content" },
                            [
                              _c("NestedTable", {
                                staticClass: "custom-nested-table",
                                attrs: {
                                  fields: _vm.yieldBatchHeaderFields,
                                  detailFields: _vm.yieldBatchDetailFields,
                                  data: _vm.BatchHeaderList,
                                  readOnly: true,
                                },
                                on: {
                                  showDetailAction:
                                    _vm.showBatchHeaderDetailAction,
                                },
                              }),
                              _c("portal", {
                                attrs: { to: "givenQuantity" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (detail) {
                                      return _c("div", {}, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "control quantity-input",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    max: _vm.$getConst(
                                                      "MAX_DIGITS_FOR_QUANTITY"
                                                    ),
                                                    greater_than: 0,
                                                  },
                                                  expression:
                                                    "{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}",
                                                },
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    detail.row.givenQuantity,
                                                  expression:
                                                    "detail.row.givenQuantity",
                                                },
                                              ],
                                              staticClass: "txt no-spinners",
                                              class: {
                                                "is-danger": _vm.veeErrors.has(
                                                  `transfer${detail.rowIndex}.givenQuantity`
                                                ),
                                              },
                                              attrs: {
                                                name: "givenQuantity",
                                                "data-vv-as": _vm.$t(
                                                  "Despatches.detail_TableColumn_Quantity"
                                                ),
                                                type: "number",
                                                step: "any",
                                              },
                                              domProps: {
                                                value: detail.row.givenQuantity,
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.updateGivenBatchQuantity(
                                                    $event,
                                                    detail.row
                                                  )
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    detail.row,
                                                    "givenQuantity",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ])
                                    },
                                  },
                                ]),
                              }),
                              _c("portal", {
                                attrs: { to: "warehouseTime" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (detail) {
                                      return _c("div", {}, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              detail.row.warehouseTime?.substr(
                                                0,
                                                5
                                              ) || "-"
                                            )
                                          ),
                                        ]),
                                      ])
                                    },
                                  },
                                ]),
                              }),
                              _c("portal", {
                                attrs: { to: "shipmentTime" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (detail) {
                                      return _c("div", {}, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              detail.row.shipmentTime?.substr(
                                                0,
                                                5
                                              ) || "-"
                                            )
                                          ),
                                        ]),
                                      ])
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("template", { slot: "footer" }, [
                            _c(
                              "div",
                              { staticClass: "batch-footer" },
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass: "m-right-10",
                                    attrs: {
                                      id: "transfer-batch-detail-cancel",
                                      size: "small",
                                      secondary: "",
                                      inline: "",
                                    },
                                    on: { click: _vm.closeModal },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Global.Cancel"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "Button",
                                  {
                                    attrs: {
                                      id: "transfer-batch-detail-save",
                                      size: "small",
                                      primary: "",
                                      inline: "",
                                    },
                                    on: { click: _vm.saveBatchHeader },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Global.Save"))),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c("router-view", {
            on: {
              getYieldHeaderList: _vm.getYieldList,
              showDrafts: function ($event) {
                _vm.showDrafts = true
              },
            },
          }),
          _c("ExpireDate", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showExpireDateEdit,
                expression: "showExpireDateEdit",
              },
            ],
            attrs: { data: _vm.BatchList, yieldHeader: _vm.yieldHeader },
            on: {
              editExpireDate: _vm.editExpireDate,
              getUpdatedBatch: _vm.editExpireDate,
              closeModal: _vm.closeExpireDateScreen,
            },
          }),
          _vm.showBatchAlert
            ? _c("Alert", {
                attrs: {
                  title: _vm.$t("Transfer.warning_batch"),
                  hasCancelButton: false,
                  description: _vm.$t("Despatches.description_batch_warning"),
                  okButton: _vm.$t("Global.okay"),
                  icon: "icon-global-notify",
                  loading: "alertForm",
                },
                on: {
                  delegateOnAlertOk: function ($event) {
                    _vm.showBatchAlert = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }