var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c("CustomDatepicker", {
            attrs: {
              id: "ScheduledjobDatepicker",
              range: true,
              disabled: _vm.isFetchingList,
            },
            model: {
              value: _vm.dates,
              callback: function ($$v) {
                _vm.dates = $$v
              },
              expression: "dates",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isEmptyList
            ? _c(
                "Button",
                {
                  attrs: {
                    size: "medium",
                    primary: "",
                    id: "btn-schedule-new",
                    type: "button",
                    iconName: "icon-global-add",
                    iconClass: "icon-left icon-global-add",
                  },
                  on: { click: _vm.addScheduledJob },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("ScheduledJob.emptyStateNewButton"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeFilterComponent", {
            staticClass: "store-filter",
            on: { submitFilter: _vm.getListWithStore },
          }),
          _c("CustomCheckbox", {
            staticClass: "checkbox",
            attrs: {
              id: "checkbox-schedule-show-passive",
              label: _vm.$t("ScheduledJob.Filter_Show_Passives"),
              checked: _vm.showPassives,
            },
            on: { change: _vm.onChangeShowPassives },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList ? _c("TableLoading") : _vm._e(),
          !_vm.isFetchingList && !_vm.isEmptyList
            ? _c(
                "div",
                { attrs: { id: "table" } },
                [
                  _c("NestedTable", {
                    attrs: {
                      fields: _vm.headerFields,
                      statusTooltipText: _vm.$t(
                        "ScheduledJob.StatusTooltipText"
                      ),
                      detailFields: _vm.detailFields,
                      isDetailLoading: _vm.isDetailLoading,
                      actions: ["inspect"],
                      readOnlyForDetail: true,
                      readOnly: true,
                      detailActions: [""],
                      data: _vm.data,
                      usePortalFooter: true,
                      dontUseScroll: true,
                      page: _vm.Page,
                    },
                    on: {
                      showDetailAction: _vm.showDetailAction,
                      inspectHeaderAction: _vm.onInspect,
                      delegateOnChangePage: _vm.changePage,
                      delegateOnChangePageSize: _vm.changePageSize,
                    },
                  }),
                  _c("portal", {
                    attrs: { to: "isActive" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (header) {
                            return header.row.canBeActivated
                              ? _c(
                                  "div",
                                  { staticClass: "switch-button" },
                                  [
                                    _c("SwitchButton", {
                                      attrs: {
                                        active: header.row.isActive,
                                        id: `btn-schedule-job-switch-is-active-${header.rowIndex}`,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onChangeSwitchButton(
                                            header.row
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e()
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("portal", {
                    attrs: { to: "situation" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (detail) {
                            return _c(
                              "div",
                              { staticClass: "popup-container" },
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass: "situation-button",
                                    attrs: {
                                      primary: "",
                                      size: "small",
                                      iconName: "icon-btn-success",
                                      disabled: _vm.isDetailLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDismissPopup(detail.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "ScheduledJob.Detail_Col_Button_Complete"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                detail.row.showPopup
                                  ? _c(
                                      "form",
                                      {
                                        staticClass:
                                          "Schedule-center-MiniPopup",
                                        attrs: {
                                          "data-vv-scope": detail.row.id,
                                          id: `form-schedule-dismiss-reason-${detail.rowIndex}`,
                                        },
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return _vm.dismissAllDetail(
                                              detail.row,
                                              (_vm.isAll = false)
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "Schedule-center-MiniPopup-label required",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "ScheduledJob.popupAdd_FormField_Dissmis_Reason_Label"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: detail.row.reason,
                                              expression: "detail.row.reason",
                                              modifiers: { trim: true },
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required|max:512",
                                              expression: "'required|max:512'",
                                            },
                                          ],
                                          staticClass:
                                            "txt Schedule-center-MiniPopup-input",
                                          class: {
                                            "is-danger": _vm.veeErrors.has(
                                              `${detail.row.id}.reason`
                                            ),
                                          },
                                          attrs: {
                                            name: "reason",
                                            autocomplete: "off",
                                          },
                                          domProps: {
                                            value: detail.row.reason,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                detail.row,
                                                "reason",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                        _c(
                                          "Button",
                                          {
                                            staticClass: "m-top-10",
                                            attrs: {
                                              primary: "",
                                              size: "medium",
                                              form: `form-schedule-dismiss-reason-${detail.rowIndex}`,
                                              type: "submit",
                                              disabled:
                                                !detail.row.reason ||
                                                _vm.isDismissAllDetailLoading,
                                              isLoading:
                                                _vm.isDismissAllDetailLoading,
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "ScheduledJob.Detail_Col_Button_Agree"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      2170317088
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("portal", {
            attrs: { to: "footerPortal" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (rowData) {
                    return rowData.detailList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "popup-container" },
                          [
                            _c(
                              "Button",
                              {
                                attrs: {
                                  primary: "",
                                  size: "medium",
                                  iconName: "icon-btn-success",
                                  disabled: _vm.isDetailLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showDismissPopup(rowData)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "ScheduledJob.Detail_Col_Button_Complete_All"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            rowData.showPopup
                              ? _c(
                                  "form",
                                  {
                                    staticClass: "Schedule-right-MiniPopup",
                                    attrs: {
                                      "data-vv-scope": rowData.id,
                                      id: "form-schedule-dismiss-reason-all",
                                    },
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.dismissAllDetail(rowData)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "Schedule-right-MiniPopup-label required",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "ScheduledJob.popupAdd_FormField_Dissmis_Reason_Label"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: rowData.reason,
                                          expression: "rowData.reason",
                                          modifiers: { trim: true },
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|max:512",
                                          expression: "'required|max:512'",
                                        },
                                      ],
                                      staticClass:
                                        "txt Schedule-right-MiniPopup-input",
                                      class: {
                                        "is-danger": _vm.veeErrors.has(
                                          `${rowData.id}.reason`
                                        ),
                                      },
                                      attrs: {
                                        name: "reason",
                                        autocomplete: "off",
                                      },
                                      domProps: { value: rowData.reason },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            rowData,
                                            "reason",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c(
                                      "Button",
                                      {
                                        staticClass: "m-top-10",
                                        attrs: {
                                          primary: "",
                                          size: "medium",
                                          form: "form-schedule-dismiss-reason-all",
                                          type: "submit",
                                          disabled: !rowData.reason,
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "ScheduledJob.Detail_Col_Button_Agree_All"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e()
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm.isEmptyList && !_vm.isFetchingList
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("ScheduledJob.emptyStateTitle"),
                  description: _vm.$t("ScheduledJob.emptyStateDescription"),
                  buttonText: _vm.$t("ScheduledJob.emptyStateNewButton"),
                },
                on: { emptyAddButton: _vm.addScheduledJob },
              })
            : _vm._e(),
          _c("router-view", { on: { getList: _vm.getList } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }