var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "Auth-form Tenant-form Form",
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "auth-step" }, [
        _c("div", { staticClass: "auth-step-item" }, [
          _c("h5", [_vm._v("1")]),
          _c("p", [_vm._v(_vm._s(_vm.$t("Register.stepTitle")))]),
        ]),
        _c("div", { staticClass: "auth-step-item active" }, [
          _c("h5", [_vm._v("2")]),
          _c("p", [_vm._v(_vm._s(_vm.$t("Tenant.stepTitle")))]),
        ]),
      ]),
      _c("h1", { staticClass: "Auth-form-title" }, [
        _vm._v(_vm._s(_vm.$t("Tenant.formHeaderTitle"))),
      ]),
      _c("div", { staticClass: "Form-item name required" }, [
        _c("label", { staticClass: "Form-item-label" }, [
          _vm._v(_vm._s(_vm.$t("Tenant.formFieldTenantName"))),
        ]),
        _c(
          "div",
          { staticClass: "control" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:2|max:128|verify_name",
                  expression: "'required|min:2|max:128|verify_name'",
                },
              ],
              staticClass: "txt txt-right-icon",
              class: { "is-danger": _vm.veeErrors.has("name") },
              attrs: {
                id: "input-auth-tenant-name",
                name: "name",
                autocomplete: "off",
                "data-vv-as": _vm.$t("Tenant.formFieldTenantName"),
                "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                type: "text",
              },
              domProps: { value: _vm.form.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "name", $event.target.value)
                },
              },
            }),
            _c(
              "Button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPendingName,
                    expression: "isPendingName",
                  },
                ],
                attrs: {
                  id: "btn-auth-tenant-loading",
                  type: "button",
                  tabindex: "-1",
                  variant: "icon loading",
                },
              },
              [_c("Loading", { attrs: { theme: "disable" } })],
              1
            ),
            _c("Button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isValidateName,
                  expression: "isValidateName",
                },
              ],
              attrs: {
                id: "btn-auth-tenant-check",
                type: "button",
                variant: "icon check",
                tabindex: "-1",
                iconName: "icon-check",
                iconClass: "icon-check",
              },
            }),
          ],
          1
        ),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.veeErrors.has("name"),
                expression: "veeErrors.has('name')",
              },
            ],
            staticClass: "Form-item-help is-danger",
          },
          [_vm._v(_vm._s(_vm.veeErrors.first("name")))]
        ),
      ]),
      _c("div", { staticClass: "Form-item" }, [
        _c("label", { staticClass: "Form-item-label" }, [
          _vm._v(_vm._s(_vm.$t("Tenant.formFieldAddress"))),
        ]),
        _c("div", { staticClass: "control" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.address,
                expression: "form.address",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "max:512",
                expression: "'max:512'",
              },
            ],
            staticClass: "txt",
            class: { "is-danger": _vm.veeErrors.has("address") },
            attrs: {
              id: "input-auth-tenant-address",
              name: "address",
              rows: "2",
              "data-vv-as": _vm.$t("Tenant.formFieldAddress"),
              autocomplete: "address",
            },
            domProps: { value: _vm.form.address },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "address", $event.target.value)
              },
            },
          }),
        ]),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.veeErrors.has("address"),
                expression: "veeErrors.has('address')",
              },
            ],
            staticClass: "Form-item-help is-danger",
          },
          [_vm._v(_vm._s(_vm.veeErrors.first("address")))]
        ),
      ]),
      _c("div", { staticClass: "Form-item" }, [
        _c("label", { staticClass: "Form-item-label" }, [
          _vm._v(_vm._s(_vm.$t("Tenant.formFieldPhone"))),
        ]),
        _c("div", { staticClass: "control" }, [
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "max:50",
                expression: "'max:50'",
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.tel,
                expression: "form.tel",
              },
            ],
            staticClass: "txt",
            attrs: {
              id: "input-auth-tenant-tel",
              name: "tel",
              "data-vv-as": _vm.$t("Tenant.formFieldPhone"),
              autocomplete: "tel",
            },
            domProps: { value: _vm.form.tel },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "tel", $event.target.value)
              },
            },
          }),
        ]),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.veeErrors.has("tel"),
                expression: "veeErrors.has('tel')",
              },
            ],
            staticClass: "Form-item-help is-danger",
          },
          [_vm._v(_vm._s(_vm.veeErrors.first("tel")))]
        ),
      ]),
      _c("div", { staticClass: "Form-item required" }, [
        _c("label", { staticClass: "Form-item-label" }, [
          _vm._v(_vm._s(_vm.$t("Tenant.formFieldCurrency"))),
        ]),
        _c(
          "div",
          { staticClass: "control form-item-select" },
          [
            _c("Icon", { attrs: { name: "icon-down-arrow" } }),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.currency,
                    expression: "form.currency",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "select",
                class: { "is-danger": _vm.veeErrors.has("currency") },
                attrs: {
                  id: "select-auth-tenant-currency",
                  name: "currency",
                  "data-vv-as": _vm.$t("Tenant.formFieldCurrency"),
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "currency",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c(
                  "option",
                  {
                    attrs: {
                      value: "-1",
                      id: "option-tenant-currency-unselected",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Tenant.formFieldCurrencySelect")))]
                ),
                _vm._l(_vm.CurrencyList, function (currency) {
                  return _c(
                    "option",
                    {
                      key: currency.id,
                      attrs: { id: `option-tenant-${currency.id}` },
                      domProps: { value: currency.id },
                    },
                    [_vm._v(_vm._s(currency.isoCode))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.veeErrors.has("currency"),
                expression: "veeErrors.has('currency')",
              },
            ],
            staticClass: "Form-item-help is-danger",
          },
          [_vm._v(_vm._s(_vm.veeErrors.first("currency")))]
        ),
      ]),
      _c(
        "div",
        { staticClass: "Form-item x2" },
        [
          _c(
            "Button",
            {
              class:
                _vm.isFormReady && !_vm.isLoading ? "btn-success" : "btn-gray",
              attrs: {
                id: "btn-auth-tenant-submit",
                type: "submit",
                primary: "",
                size: "medium",
                disabled: !_vm.isFormReady && _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.$t("Tenant.formFieldSubmit")))]
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "Form-item Auth-form-link x2" }, [
        _c(
          "p",
          [
            _vm._v(_vm._s(_vm.$t("Tenant.linkText")) + "\n"),
            _c(
              "router-link",
              {
                attrs: { id: "link-auth-tenant-login", to: { name: "Login" } },
              },
              [_vm._v(_vm._s(_vm.$t("Tenant.linkUrl")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }