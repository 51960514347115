<template lang="pug">
div
  div(v-if="confirmSuccess")
    h1.success-message {{ $t('Register.confirmSuccess') }}

    p.m-bottom-20 {{ $t('Register.redirectLogin') }}

    p.m-bottom-20 {{ $t('httpErrors.countdownText') }} {{timer}}

  div(v-else)
    span.fail-message {{ $t('Register.confirmFailed') }}

</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'ConfirmEmail',
  data () {
    return {
      timer: 5,
      timeout: null,
      confirmSuccess: false
    }
  },

  methods: {
    ...mapActions('Auth', [
      'confirmEmail'
    ]),

    countdown () {
      if (this.timer === 0) {
        this.$router.push({
          name: 'Login'
        })
      }
      if (this.timer > 0) {
        this.timeout = setTimeout(() => {
          this.timer--
          this.countdown()
        }, 1000)
      }
    }
  },
  beforeMount () {
    const payload = {
      emailAddress: this.$route.query.email.replace(/ /g, '+'),
      confirmationToken: this.$route.query.token
    }
    this.confirmEmail(payload).then(res => {
      if (res) {
        this.confirmSuccess = true
        this.countdown()
      }
    })
  }
}
</script>

<style lang="scss" scoped>

  .success-message {
    color: $color-sidebar-dark;
  }
  .fail-message {
    color: $color-rejection;
    font-size: $font-size-bigger;
    width: 80%;
  }
</style>
