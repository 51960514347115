<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )

  template(slot="toolbar-left")
    .item.x2
        Icon(name="icon-down-arrow")
        select.mounth-select.ff-sf-ui(
          name="months",
          v-model="selectedDate.month",
          @change="getData"
          id="payment-calendar-select-month")
          option(
            v-for="month in calendar.months",
            :key="month.index"
            :value="month.index") {{ month.name }}
    .item.x2
      Icon(name="icon-down-arrow")
      select.years-select.ff-sf-ui(
        name="years",
        v-model="selectedDate.year",
        @change="getData"
        id="payment-calendar-select-year")
        option(
          v-for="year in calendar.years",
          :key="year"
          :value="year") {{ year }}
    .item
      span.title {{ $t('PaymentCalendar.firstDayOfWeek') }}
      .select-field
        Icon(name="icon-down-arrow")
        select.firstDayOfWeek-select(
          name="firstDayOfWeek",
          v-model="selectedDate.firstDayOfWeek",
          @change="getData"
          id="payment-calendar-select-firstDayOfWeek")
          option(
            v-for="day in daysOption",
            :key="day.index"
            :value="day.index") {{ day.name }}  
  template(slot="toolbar-right")
    .right-item
      span.text {{ $t('PaymentCalendar.monthlyTotal') }}
      span.value.ff-mono {{ PaymentCalendar.monthlyTotal | formatCurrency2Digits }}
  template(slot="content-center")
    TableLoading(v-if="isLoading")
    .Calendar(v-if="!isLoading")
      .weeks
        .week.colorfull.text  {{ $t('PaymentCalendar.weekTitle') }}
        .week.color-green.ff-sf-ui(
            v-for="week in calendar.weeksOfMonth",
            :key="`weektext${week.index}`"
          ) {{ week.name }}&nbsp;.
      .days
        .day.title(v-for="day in calendar.days",
          :key="day.dayNumber") {{ day.name }}
        .day(
          v-for="(day,dayIndex) in calendar.daysOfMonth",
          :key="`day${dayIndex}`"
          :class="{'today': day.date === today }")
          .item
            span.dayNumber.ff-sf-ui(
              :class="[{'disabled': !day.isActive}]"
            ) {{ day.day }}
            span.dailTotal.ff-sf-pro(
            v-if="getInDayData(day.date) !== undefined && getInDayData(day.date).items && isInCurrentYears(day.date)")
              | {{ getInDayData(day.date).total | formatCurrency2Digits }}
          .item(
            v-for="(supplier, supplierIndex) in getInDayData(day.date) ? getInDayData(day.date).items : {}"
            :key="supplierIndex"
            v-if="supplierIndex < 3 && isInCurrentYears(day.date)")
            span.supplierName.ff-sf-pro {{ supplier.supplierName }}
            span.price.ff-mono {{ supplier.amount | formatCurrency2Digits }}
          .footer(v-if="isInCurrentYears(day.date)")
            a.show-more.ff-sf-pro(
            v-if="getInDayData(day.date) !== undefined && getInDayData(day.date).items",
            @click="showDetail(dayIndex)") ...
            .popupOver(v-if="showedDetailIndex === dayIndex")
              ul.list.custom-scrollbar
                li.supplier(
                  v-for="(supplier, index) in getInDayData(day.date) ? getInDayData(day.date).items : {}"
                  :key="index")
                  span.name.ff-sf-pro {{ supplier.supplierName }}
                  span.payment.ff-mono {{ supplier.amount | formatCurrency2Digits }}
      .weeks
        .week.colorfull.value {{ $t('PaymentCalendar.weeklyTotals') }}
        .week.ff-mono.color-gray(
          v-for="(week,index) in calendar.weeksOfMonth"
          :key="`weekvalue${index}`"
        ) {{ getWeekData(week) | formatCurrency2Digits }}

</template>
<script>
import Calendar from '@/utils/calendar'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PaymentCalendar',
  data () {
    return {
      selectedDate: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        date: new Date().getDate(),
        firstDayOfWeek: 1
      },
      daysOption: [],
      today: new Date().toDateString(),
      showedDetailIndex: -1
    }
  },
  computed: {
    ...mapGetters('i18n', ['langDefault']),
    ...mapGetters('PaymentCalendar', ['PaymentCalendar']),
    ...mapGetters('Payments', ['pageHeaderLinks']),

    isLoading () {
      return this.$wait.is(['getCalendarData'])
    },
    calendar () {
      return new Calendar(this.selectedDate, this.langDefault)
    }
  },
  methods: {
    ...mapActions('PaymentCalendar', ['getCalendarData']),
    async getData () {
      await this.getCalendarData({
        Year: this.selectedDate.year,
        Month: this.selectedDate.month + 1,
        FirstDay: this.selectedDate.firstDayOfWeek
      })
    },
    getInDayData (date) {
      if (this.PaymentCalendar.days) {
        let days = Object.keys(this.PaymentCalendar.days)
        let currentDay = days.find(item =>
          new Date(item).toDateString() === date
        )
        return this.PaymentCalendar.days[currentDay] || []
      }
    },
    isInCurrentYears (day) {
      return new Date(day).getFullYear() === this.selectedDate.year
    },
    showDetail (index) {
      this.showedDetailIndex = this.showedDetailIndex === index ? -1 : index
    },
    getWeekData (week) {
      if (this.PaymentCalendar.weeklyTotals) {
        let weekNums = Object.keys(this.PaymentCalendar.weeklyTotals)
        let num = weekNums.find(item => parseInt(item) === parseInt(week.index))
        return num ? this.PaymentCalendar.weeklyTotals[week.index] : ''
      }
    }
  },
  async mounted () {
    this.daysOption = [
      {
        name: this.selectedDate.firstDayOfWeek === 1 ? this.calendar.days[0].name : this.calendar.days[1].name,
        index: 1
      },
      {
        name: this.selectedDate.firstDayOfWeek === 0 ? this.calendar.days[0].name : this.calendar.days[6].name,
        index: 0
      }
    ]
    await this.getData()
    window.addEventListener('click', (e) => {
      const showMoreEl = e.target.closest('.show-more')
      if (!showMoreEl) this.showedDetailIndex = -1
    })
  }
}
</script>

<style lang="scss" scoped>

.item {
  position: relative;
  display: flex;
  align-items: center;
  svg {
    position: absolute;
  }
  select {
    appearance: none;
  }
  &.x2 {
    select {
      border: 0;
      border-right: 1px solid $color-gray;
      height: 71px;
      line-height: 71px;
      background: transparent;
      padding-left: 20px;
      padding-right: 40px;
      border-radius: 0;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: $color-text-dark;
    }
    svg {
      color: $color-success;
      right: 20px;
      width: 12px;
      height: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .title {
    font-size: 12px;
    font-weight: 600;
    color: $color-text-light;
    padding-left: 20px;
    padding-right: 10px;
  }
  .select-field {
    position: relative;
    svg {
      right: 10px;
      width: 9px;
      height: 6px;
      color: $color-text-light;
      top: 50%;
      transform: translateY(-55%);
    }
  }
  .firstDayOfWeek-select {
    height: 35px;
    border: 1px solid $color-gray;
    padding-left: 10px;
    padding-right: 20px;
    background: transparent;
    font-size: 12px;
    font-weight: bold;
    color: $color-text-light;
  }
}

.right-item {        
  padding-left: 20px;
  padding-right: 20px;
  .text {
    font-size: 12px;
    font-weight: bold;
    color: $color-success;
    margin-right: 10px;
  }
  .value {
    font-weight: bold;
    color: $color-text-dark;
  }
}

  .Calendar {
    margin-top: 10px;
    border-left: 1px solid $color-gray;
    border-top: 1px solid $color-gray;
    border-radius: $border-high-radius;
    display: grid;
    grid-template-columns: 6% 7fr auto;
    .weeks {
      .week {
        display: flex;
        justify-content: center;
        font-weight: 500;
        height: 148px;
        border-right: 1px solid $color-gray;
        border-bottom: 1px solid $color-gray;
        background: $color-white;
        &.color-green {
          color: $color-success;
          padding-top: 25px;
          padding-bottom: 25px;
          font-size: 16px;
        }
        &.color-gray {
          color: $color-text-dark;
          align-items: center;
          justify-content: flex-end;
          padding-left: 10px;
          padding-right: 10px;
        }
        &.colorfull {
          height: 45px;
          background: $color-seashell;
          font-size: 14px;
          font-weight: bold;
          color: $color-success;
          padding-left: 10px;
          padding-right: 10px;
          text-transform: uppercase;
          align-items: center;
          width: 100%;
          margin: 0;
        }
        &.text {
          border-top-left-radius: 6px;
        }
        &.value {
          border-top-right-radius: 6px;
        }
      }
    }
    .days {
      display: grid;
      grid-template-columns: repeat(auto-fill, 14.285%);
      grid-template-rows: 45px repeat(auto-fill, 148px);
      .day {
        display: grid;
        grid-template-columns: repeat(1, 100%);
        grid-template-rows: 37px repeat(3, 30px) 20px;
        border-right: 1px solid $color-gray;
        border-bottom: 1px solid $color-gray;
        position: relative;
        background: $color-white;
        &.title {
          font-size: 14px;
          font-weight: bold;
          color: $color-success;
          padding-left: 24px;
          padding-right: 24px;
          text-transform: uppercase;
          align-items: center;
          &:not(:nth-child(n+7)) {
            border-right: 0;
          }
        }
        &.today {
          background: $color-seashell;
        }
        .item {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 10px;
          padding-right: 10px;
          width: 100%;
          &:first-child {
            height: 37px;
          }
          &:not(:first-child){
            border-top: 1px solid $color-gray;
          }
          .dayNumber {
            font-size: 1em;
            font-weight: 500;
            color: $color-text-dark;
            &.disabled {
              color: $color-text-light;
            }
          }
          .dailTotal {
            font-size: 0.7em;
            color: $color-success;
            font-weight: bold;
          }
          .supplierName {
            font-size: 11px;
            color: $color-success;
            font-weight: 500;
            width: 40%;
            top: 30%;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 10px;
          }
          .price {
            font-size: 11px;
            color: $color-text-dark;
            font-weight: 500;
            width: 60%;
            flex: 1;
            text-align: right;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .footer {
          width: 100%;
          height: 20px;
          position: absolute;
          bottom: 2px;
          display: block;
        }
        .show-more {
          font-size: 21px;
          color: $color-success;
          font-weight: 500;
          display: inline-block;
          width: 100%;
          text-align: center;
          cursor: pointer;
          border-top: 1px solid $color-gray;
          padding: 0;
          line-height: 10px;
          height: 20px;
        }
        .popupOver {
          display: block;
          position: absolute;
          z-index: 1;
          font-size: $font-size-small;
          box-shadow: 0 0 10px rgba(black, 0.2);
          background-color: white;
          border: 1px solid $color-primary;
          border-radius: $border-radius;
          min-width: 230px;
          top: calc(100% + 5px);
          left: 50%;
          transform: translateX(-50%);
          .list {
            max-height: 270px; // (49 * 6) - 24px (for scroll) supplier
            overflow-y: auto;
          }
          &::before,
          &::after {
            content: '';
            position: absolute;
            bottom: 100%;
            transform: translateX(-50%);
            pointer-events: none;
            left: 50%;
          }
          &::before {
            border: 8px solid;
            border-color: transparent transparent $color-primary transparent;
          }

          &::after {
            border: 7px solid;
            border-color: transparent transparent white transparent;
          }
          .supplier {
            min-height: 49px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 10px;
            &:not(:last-child) {
              border-bottom: 1px solid $color-gray;
            }
            .name {
              font-size: 12px;
              color: $color-success;
              font-weight: 500;
              width: 50%;
              margin-right: 10px;
            }
            .payment {
              font-size: 12px;
              color: $color-text-dark;
              font-weight: 500;
              width: auto;
              flex: 1;
              text-align: right;
            }
          }
        }
      }
    }
  }
</style>
