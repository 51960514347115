<template lang="pug">
Page
  template(slot="header-title")
    span {{ $t('NutritionalValues.breadcrumb_NutritionalValues') }}
  template(slot="header-breadcrumb-left")
    .Breadcrumb.m-top-5
      router-link.m-right-5(
        id="link-recipe-breadcrumb"
        :to="{ name: 'NutritionalValues' }"
      ) {{ $t('NutritionalValues.breadcrumb_NutritionalValues') }}
      span.nutrition-name {{ ' > ' + selectedNutrition}}

  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList="PageLinks"
      translationGroup="TabItems"
      matchedRoute="NutritionalValues"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingList")

    TableView(
      v-else
      :data="NutritionDetails"
      :fields="fields"
      :readOnly="true"
      :componentName="'NutritionDetailTable'"
    )

    portal(to="value")
      div(slot-scope="row")
        div(v-if="!isCustom") {{row.value}}
        div(v-else)
          customNumberInput(
            name="nutritionValue"
            id="nutrition-detail-value"
            v-validate="'greater_than:-1'"
            :data-vv-as="$t('NutritionalValues.placeholder_unitValue')"
            :placeholder="$t('NutritionalValues.placeholder_unitValue')"
            v-model="row.value"
            :error="veeErrors.has('nutritionValue')"
            @blur="updateCustomValue(row)"
          )

          showValidateError(
            v-show="veeErrors.has('nutritionValue')"
            :errorName="veeErrors.first('nutritionValue')"
          )

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'NutritionDetail',

  data () {
    return {
      isCustom: false,
      selectedNutrition: ''
    }
  },

  computed: {
    ...mapGetters('OptionalFields', ['PageLinks']),
    ...mapGetters('NutritionalValues', ['NutritionFoodValues', 'NutritionDetails', 'Page']),

    isFetchingList () {
      return this.$wait.is('showNutritionDetail')
    },

    fields () {
      return [
        {
          name: 'name',
          is_visible: true,
          short_title: this.$t('Dictionary.Unit'),
          title: this.$t('Dictionary.Unit')
        },
        {
          name: 'code',
          is_visible: false
        },
        {
          name: 'value',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('Dictionary.Value'),
          title: this.$t('Dictionary.Value')
        }

      ]
    }
  },
  async created () {
    this.isCustom = this.$route.params.isCustomFood
    this.selectedNutrition = this.$route.params.nutritionFoodName
    await this.getDetails()
  },
  methods: {
    ...mapActions('NutritionalValues', ['showNutritionDetail', 'updateCustomNutritionDetail']),

    getDetails: vueWaitLoader(async function () {
      const payload = {
        nutritionFoodId: this.$route.params.id,
        isCustomFood: this.$route.params.isCustomFood
      }
      this.showNutritionDetail(payload)
    }, 'getAllDetails'),

    async updateCustomValue (item) {
      const payload = {
        nutritionFoodId: this.$route.params.id,
        code: item.code,
        value: item.value
      }
      await this.updateCustomNutritionDetail(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

  .nutrition-name {
    color: $color-primary
  }

</style>
