<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )

  template(slot="header-left-search")
    Search.search-box(
      componentName="MatchedMenuItems",
      :isSuggestionLoading="isLoading",
      @onChangeSearchValue="onChangeSearch")
  template(slot="toolbar-left")
    StoreGroupFilter(@submitFilter="updateStoreGroupFilterItems")
    CustomMultiselect.m-right-10(
      id="custom-multiselect-match-copy-base-store"
      :items="rvcList"
      :selected="filters.rvc"
      :updateDropDownLabelFromParent="updateLabel.rvc"
      :popup-header="$t('MenuItemsSettings.Revenue_Center')"
      :allLabel="$t('Global.toolbar_Filters_AllLabel')"
      :label="$t('MenuItemsSettings.Revenue_Center_Label')"
      :buttonText="$t('MenuItemsSettings.Revenue_Center_Button_Text')"
      buttonIconLeft="btn-success"
      :unselectedText="$t('MenuItemsSettings.Revenue_Center_PlaceHolder')"
      @delegateCheckAll="onChangeFilter($event, 'rvc')"
      @delegateCheckItem="onChangeFilter($event, 'rvc')"
      @delegateSubmitItems="submitFilter")
    CustomMultiselect(
      id="custom-multiselect-match-copy-base-store"
      :items="rvcGroupList"
      :selected="filters.rvcGroup"
      :updateDropDownLabelFromParent="updateLabel.rvcGroup"
      :popup-header="$t('MenuItemsSettings.Group_Popup_Header')"
      :allLabel="$t('Global.toolbar_Filters_AllLabel')"
      :label="$t('MenuItemsSettings.Group_Label')"
      :buttonText="$t('MenuItemsSettings.Group_Button_Text')"
      buttonIconLeft="btn-success"
      :unselectedText="$t('MenuItemsSettings.Group_Center_PlaceHolder')"
      @delegateCheckAll="onChangeFilter($event, 'rvcGroup')"
      @delegateCheckItem="onChangeFilter($event, 'rvcGroup')"
      @delegateSubmitItems="submitFilter")
  template(slot="content-center")
    .MenuItemsSettings
      // Loading
      TableLoading(v-if="isLoading")

      EmptyState(
      v-if="isEmptyMatchedList && !isLoading"
      :title="$t('MenuItemsSettings.emptyStateTitle')"
      :description="$t('MenuItemsSettings.emptyStateDescription')"
      )

      TableView(
      v-if="!isEmptyMatchedList"
      :fields="fields"
      :data="tableData"
      :componentName="'MatchedListTable'"
      :page="matchedListPagination"
      :readOnly="true"
      :addScrollToNoFixedColumns="true"
      @delegateOnChangePage="changePage")

      portal(to="partition")
            div(slot-scope="row")
              Button(
                size="small"
                danger
                variant="remove"
                id="btn-table-action-remove"
                iconName="icon-notify-close"
                iconClass="icon-notify-close"
              @click="openRemovePopup(row)")
                span {{ $t('MenuItemsSettings.partition_button_text') }}

      router-view(@getList="submitFilter")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//
import getFields from './fields/matched'
import StoreGroupFilter from '@/view/global/business-filters/storeGroup'

export default {
  name: 'MenuItemsSettings',

  data () {
    return {
      fields: getFields(this.$t),
      filters: {
        selectedStoreGroup: [],
        rvc: [],
        rvcGroup: []
      },
      rvcList: [],
      rvcGroupList: [],
      updateLabel: {
        rvc: false,
        rvcGroup: false
      },
      tableData: [],
      search: ''
    }
  },

  components: {
    StoreGroupFilter
  },

  computed: {
    ...mapGetters('MenuItemsSettings', ['pageHeaderLinks', 'matchedListPagination']),

    isLoading () {
      return this.$wait.is(['getMappingAll', 'getStoreGroupRevenueCentersList', 'getMenuItemsGroup'])
    },

    isEmptyMatchedList () {
      return !this.tableData.length
    }
  },

  methods: {
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('MenuItemsSettings', ['getStoreGroupRevenueCentersList', 'getMenuItemsGroup', 'getMappingAll']),

    changeFilterLabel (filterName) {
      this.updateLabel[filterName] = !this.updateLabel[filterName]
    },

    changePage (pageNumber) {
      this.submitFilter(pageNumber)
    },

    openRemovePopup (event) {
      this.$router.push({
        name: 'MatchRemoveAlert',
        params: {
          id: event.id,
          item: event
        }
      })
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) {
        return
      }
      this.submitFilter()
    },

    async updateStoreGroupFilterItems ({ list }) {
      this.filters.selectedStoreGroup = list
      const payload = {
        pageSize: 1000,
        storeGroupIds: this.getIds(this.filters.selectedStoreGroup)
      }
      await this.getRvcList(payload)
      await this.getRvcGroupList(payload)
      this.submitFilter()
    },

    async getRvcList (payload) {
      await this.getStoreGroupRevenueCentersList(payload)
        .then((res) => {
          this.rvcList = res.data.revenueCenterList
          this.filters.rvc = [...this.rvcList]
          this.changeFilterLabel('rvc')
        })
    },

    async getRvcGroupList (payload) {
      await this.getMenuItemsGroup(payload)
        .then((res) => {
          this.rvcGroupList = res.data.menuItemGroupList
          this.filters.rvcGroup = [...this.rvcGroupList]
          this.changeFilterLabel('rvcGroup')
        })
    },

    getIds (list) {
      return list.map(item => item.id)
    },

    submitFilter (page = 1) {
      const payload = {
        storeGroupIds: this.getIds(this.filters.selectedStoreGroup),
        revenueCenterIds: this.getIds(this.filters.rvc),
        menuItemGroupIds: this.getIds(this.filters.rvcGroup),
        text: this.search,
        pageNumber: page
      }
      this.getMappingAll(payload).then((res) => {
        this.tableData = res.data.mappingList
      })
    },

    onChangeFilter (event, filterType) {
      if (event.isChecked) {
        if (event.item.all) {
          this.filters[filterType] = filterType === 'rvc' ? [...this.rvcList] : [...this.rvcGroupList]
        } else {
          this.filters[filterType].push(event.item)
        }
      } else {
        if (event.item.all) {
          this.filters[filterType] = []
        } else {
          this.filters[filterType].splice(this.filters[filterType].indexOf(event.item), 1)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
