var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "httpError" }, [
    _c(
      "div",
      { staticClass: "httpError-text" },
      [
        _c("h2", { staticClass: "m-bottom-20" }, [
          _vm._v(_vm._s(_vm.$t("httpErrors.title" + _vm.httpCode))),
          _c("span", { staticClass: "m-left-10" }, [
            _vm._v(_vm._s(_vm.httpCode)),
          ]),
        ]),
        _c("p", { staticClass: "m-bottom-20" }, [
          _vm._v(_vm._s(_vm.$t("httpErrors.description" + _vm.httpCode))),
        ]),
        _c("p", { staticClass: "m-bottom-20" }, [
          _vm._v(_vm._s(_vm.$t("httpErrors.countdownText"))),
          _c("span", [_vm._v(_vm._s(_vm.timer))]),
        ]),
        _c(
          "Button",
          {
            staticClass: "btn btn-success m-top-10",
            attrs: { id: "backHomeLink" },
            on: { click: _vm.redirectOldPage },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("httpErrors.buttonText" + _vm.httpCode))),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "httpError-cloud" },
      [_c("Icon", { attrs: { name: "icon-http-error" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }