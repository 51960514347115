var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
          _c("UBLUpload", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAlert,
                expression: "showAlert",
              },
            ],
            attrs: { data: _vm.finalData, isLoading: _vm.waitUBLUpload },
            on: { closeUploadScreen: _vm.closeUploadScreen },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "DespatchEInvoice",
              isSuggestionLoading: false,
              customSuggestionPlaceHolder: _vm.$t(
                "EInvoice.search_Placeholder"
              ),
            },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select right-date m-left-5 m-right-5" },
          [
            _c("CustomDatepicker", {
              staticClass: "eInvoice-datePicker",
              attrs: {
                isLoading: _vm.$wait.is("getDateFilterSettings"),
                disabled: _vm.isLoading || _vm.$wait.is("pageLoading"),
                range: true,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              class: _vm.buttonSizeClass,
              attrs: {
                primary: "",
                size: "medium",
                id: "e-invoice-transform-button",
                isLoading: _vm.isWaitingForTransform && _vm.isLoading,
                disabled: _vm.isWaitingForTransform && _vm.isLoading,
              },
              on: { click: _vm.changeTransformStatus },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.InvoiceTransformStatus
                      ? _vm.$t("EInvoice.stopTransformDespatch")
                      : _vm.$t("EInvoice.autoTransformDespatch")
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("SupplierFilter", {
            ref: "despatchEInvoiceSupplierFilter",
            staticClass: "filters",
            on: { submitFilter: _vm.getListWithSupplierFilter },
          }),
          _c("storeFilter", {
            ref: "despatchEInvoiceStoreFilter",
            staticClass: "filters",
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c("businessMultiselect", {
            ref: "eInvoicesDocTypeFilter",
            staticClass: "filters",
            attrs: {
              componentName: "despatch-eInvoice-doc-type",
              label: _vm.$t("EInvoice.filter_docType"),
              popupHeader: _vm.$t("EInvoice.filter_docType_PopupHeader"),
              multiselectItems: _vm.docTypes,
            },
            on: { submitFilter: _vm.getListWithDocTypeFilter },
          }),
          _c("CustomMultiselect", {
            ref: "dateTypeList",
            staticClass: "dateTypeFilter",
            attrs: {
              id: "eInvoice-date-type-filter",
              items: _vm.dateTypes,
              selected: _vm.dateTypeList,
              label: _vm.$t("EInvoice.filter_dateType"),
              popupHeader: _vm.$t("EInvoice.filter_dateType"),
              showAll: false,
              buttonText: _vm.$t("EInvoice.filter_apply"),
              type: "radio",
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckItem: _vm.onChangeDateTypes,
              delegateSubmitItems: _vm.getEInvoiceListWithParams,
            },
          }),
          _c(
            "Button",
            {
              staticClass:
                "clear-filter-button border-gray-1 p-0 width-auto m-left-5",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "show-more m-left-5" }, [
            _c("div", { staticClass: "Form-item dropdown-handle" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeOtherOptions,
                      expression: "closeOtherOptions",
                    },
                  ],
                  staticClass: "select dropdown-handle-button",
                  attrs: {
                    "aria-expanded": _vm.showOtherOptions,
                    tabindex: "0",
                  },
                  on: {
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.toggleOtherOptions.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar",
                          ])
                        )
                          return null
                        $event.preventDefault()
                        return _vm.toggleOtherOptions.apply(null, arguments)
                      },
                    ],
                    click: _vm.toggleOtherOptions,
                  },
                },
                [
                  _c("div", { staticClass: "dropdown-handle-button-values" }, [
                    _vm._v(_vm._s(_vm.$t("Global.moreOptions"))),
                  ]),
                  _c("Icon", {
                    staticClass:
                      "dropdown-handle-button-icon icon icon-down-arrow",
                    class: { open: _vm.showOtherOptions },
                    attrs: { name: "icon-down-arrow" },
                  }),
                ],
                1
              ),
            ]),
            _vm.showOtherOptions
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "shortkey",
                        rawName: "v-shortkey",
                        value: ["esc"],
                        expression: "['esc']",
                      },
                    ],
                    staticClass: "MiniPopup left",
                  },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        id: "checkbox-e-invoice-show-fails",
                        checked: _vm.showFailed,
                        label: _vm.$t("EInvoice.showFailedOnly"),
                      },
                      on: { change: _vm.changeShowFailStatus },
                    }),
                    _c("CustomCheckbox", {
                      staticClass: "show-passive-recipes",
                      attrs: {
                        id: "checkbox-eInvoices-show-passive",
                        checked: _vm.showPassive,
                        label: _vm.$t("EInvoice.showPassiveInvoices"),
                      },
                      on: { change: _vm.showPassiveInvoices },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            staticClass: "m-right-15",
            attrs: {
              componentName: "EInvoicePage",
              isDisabled: _vm.isLoading,
              activeImportExports: ["import"],
              importUrl: "RawEInvoice/import",
              importFileType: "xml",
              importIsEmitData: true,
            },
            on: { fileUpload: _vm.uploadFile },
          }),
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "small",
                disabled: !_vm.selectedList.length,
              },
              on: { click: _vm.transformDespatch },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.transformDespatch")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ||
          _vm.$wait.is("pageLoading") ||
          _vm.isWaitingForTransform ||
          _vm.isWaitingForActivationChanging
            ? _c("TableLoading")
            : _vm._e(),
          !_vm.isLoading && !_vm.EInvoiceList.length
            ? _c("EmptyState", {
                attrs: { title: _vm.$t("EInvoice.eInvoiceList_not_found") },
              })
            : _c(
                "div",
                { staticClass: "table-content" },
                [
                  _c("TableView", {
                    attrs: {
                      uniqueIdentifier: "id",
                      fields: _vm.fields,
                      actions: ["overview", "active"],
                      data: _vm.EInvoiceList,
                      detailFields: _vm.detailFields,
                      page: _vm.EInvoicePage,
                      detailData: _vm.invoiceDetailList,
                      selectable: "checkbox",
                      selectableAll: "checkbox",
                      selectedRows: _vm.selectedList,
                      componentName: "EInvoicePage",
                      addScrollToNoFixedColumns: true,
                      hasDetailTable: true,
                    },
                    on: {
                      delegateOnItemShowCustom: _vm.showDetail,
                      delegateOnChangePage: _vm.changePage,
                      delegateOnChangePageSize: _vm.changePageSize,
                      delegateOnItemChangeActiveStatus:
                        _vm.updateActivationState,
                      delegateOnItemOverview: _vm.showInvoiceTemplate,
                      overviewHeaderAction: _vm.showInvoiceTemplate,
                      showAllSelectedItems: _vm.selectAll,
                      delegateOnItemSelect: _vm.onSelect,
                    },
                  }),
                  _c("portal", {
                    attrs: { to: "orderNo" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return !_vm.isLoading
                              ? _c("div", {}, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "order-number-label",
                                      class:
                                        !row.matchingSupplierId ||
                                        !row.matchingStoreId ||
                                        row.rawEInvoiceStatus === 3
                                          ? "disable-order-selection"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showOrderTable(row)
                                        },
                                      },
                                    },
                                    [
                                      row.orderNo
                                        ? _c("span", [
                                            _vm._v(_vm._s(row.orderNo)),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("EInvoice.selectOrder")
                                              )
                                            ),
                                          ]),
                                    ]
                                  ),
                                ])
                              : _vm._e()
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("portal", {
                    attrs: { to: "despatchNo" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return !_vm.isLoading
                              ? _c("div", {}, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "order-number-label",
                                      class:
                                        !row.matchingSupplierId ||
                                        !row.despatchNo ||
                                        row.rawEInvoiceStatus === 3
                                          ? "disable-order-selection"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showDespatchTable(row)
                                        },
                                      },
                                    },
                                    [
                                      row.despatchNo
                                        ? _c("span", [
                                            _vm._v(_vm._s(row.despatchNo)),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "EInvoice.selectDespatch"
                                                )
                                              )
                                            ),
                                          ]),
                                    ]
                                  ),
                                ])
                              : _vm._e()
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("portal", {
                    attrs: { to: "matchingStoreName" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return !_vm.isLoading
                              ? _c(
                                  "div",
                                  {},
                                  [
                                    row.rawEInvoiceStatus === 2 ||
                                    row.rawEInvoiceStatus === 1
                                      ? _c("customSelectInput", {
                                          staticClass: "custom-select",
                                          attrs: {
                                            name: "matchingStore",
                                            disabled: !row.isActive,
                                            optionData: _vm.matchingStoreData,
                                            optionKey: "id",
                                            optionIdKey: "id",
                                            existingData: row.matchingStoreName,
                                            extraDataEmit: row,
                                            selectedDataRequired: true,
                                            isChangeValue: true,
                                            hideDefault: false,
                                            optionIdName:
                                              "eInvoice-matching-store-list",
                                          },
                                          on: {
                                            getOptions: function ($event) {
                                              return _vm.getStoreOptions(
                                                row.matchingSupplierId
                                              )
                                            },
                                            change: _vm.updateMatchingStore,
                                          },
                                          model: {
                                            value: row.matchingStoreId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "matchingStoreId",
                                                $$v
                                              )
                                            },
                                            expression: "row.matchingStoreId",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(row.matchingStoreName)),
                                        ]),
                                  ],
                                  1
                                )
                              : _vm._e()
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("portal", {
                    attrs: { to: "supplierBranchName" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return !_vm.isLoading
                              ? _c(
                                  "div",
                                  {},
                                  [
                                    row.rawEInvoiceStatus === 2 ||
                                    row.rawEInvoiceStatus === 1
                                      ? _c("customSelectInput", {
                                          staticClass: "custom-select",
                                          attrs: {
                                            name: "matchingStore",
                                            disabled: !row.isActive,
                                            value: row.supplierBranchName,
                                            optionData: _vm.matchingBranchData,
                                            existingData:
                                              row.supplierBranchName,
                                            optionKey: "name",
                                            optionIdKey: "name",
                                            extraDataEmit: row,
                                            selectedDataRequired: true,
                                            isChangeValue: true,
                                            hideDefault: false,
                                            optionIdName:
                                              "eInvoice-matching-supplier-list",
                                          },
                                          on: {
                                            getOptions: function ($event) {
                                              return _vm.getBranchCodes(
                                                row.supplierTaxNo
                                              )
                                            },
                                            change: _vm.updateSupplierBranch,
                                          },
                                          model: {
                                            value: row.supplierBranchName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "supplierBranchName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "row.supplierBranchName",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(row.supplierBranchName)
                                          ),
                                        ]),
                                  ],
                                  1
                                )
                              : _vm._e()
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("portal", {
                    attrs: { to: "errorCode" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "Button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.renderComponent &&
                                        row.errorCode &&
                                        row.rawEInvoiceStatus === 2,
                                      expression:
                                        "renderComponent && row.errorCode && row.rawEInvoiceStatus === 2",
                                    },
                                  ],
                                  staticClass:
                                    "error-code relative width-max-content",
                                  attrs: {
                                    clearVariant: true,
                                    isLoading: _vm.isInvoiceTransforming,
                                  },
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.showFailReason(row)
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.hideFailReason(row)
                                    },
                                  },
                                },
                                [
                                  !_vm.isInvoiceTransforming
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("EInvoice.hasError"))
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row.isShowReason,
                                          expression: "row.isShowReason",
                                        },
                                      ],
                                      staticClass: "MiniPopup-left",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "EInvoice.errorCode_" +
                                                row.errorCode
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "Button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row.rawEInvoiceStatus === 1,
                                      expression: "row.rawEInvoiceStatus === 1",
                                    },
                                  ],
                                  staticClass:
                                    "waiting-label relative width-max-content",
                                  attrs: { clearVariant: true },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("EInvoice.waiting"))),
                                  ]),
                                ]
                              ),
                              _c(
                                "Button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row.rawEInvoiceStatus === 3,
                                      expression: "row.rawEInvoiceStatus === 3",
                                    },
                                  ],
                                  staticClass:
                                    "success-label relative width-max-content",
                                  attrs: { clearVariant: true },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("EInvoice.successful"))
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "Button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row.rawEInvoiceStatus === 4,
                                      expression: "row.rawEInvoiceStatus === 4",
                                    },
                                  ],
                                  staticClass:
                                    "inProgress-label relative width-max-content",
                                  attrs: { clearVariant: true },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("EInvoice.InProgress"))
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("portal", {
                    attrs: { to: "docType" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c("div", {}, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.getDocType(row.docType))),
                            ]),
                          ])
                        },
                      },
                    ]),
                  }),
                  _c(
                    "Modal",
                    {
                      attrs: { isOpen: _vm.showHTMLTemplate },
                      on: { closeModal: _vm.closeModal },
                    },
                    [
                      _c(
                        "template",
                        { slot: "content" },
                        [
                          _vm.invoiceHTMLIsLoading
                            ? _c("Loading")
                            : _c("iframe", {
                                staticClass: "iframe-invoice",
                                attrs: {
                                  srcdoc: _vm.InvoiceHTML,
                                  frameborder: "0",
                                  scrolling: "auto",
                                  width: "100%",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("router-view", {
                    on: { getList: _vm.getEInvoiceListWithParams },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }