<template lang="pug">
Page
  template(slot="header-left-search")
    Search.search(
      componentName="StoreGroup",
      :isSuggestionLoading="false",
      :customSuggestionPlaceHolder="$t('StoreGroups.search_Placeholder')"
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-left-dropdown")
    pageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    Button(
      primary
      size="medium"
      id="btn-store-groups-add",
      v-show="!isStoreGroupsEmpty && !isLoading"
      @click="itemNew"
      iconName="icon-global-add",
      iconClass="icon-global-add",
    )
      span(id="btn-store-groups-add-title") {{ $t('StoreGroups.addStoreGroupButton') }}
  
  template(slot="toolbar-right")
    ViewType.view-type(
      firstNodeIcon="icon-view-type-list"
      secondNodeIcon="icon-view-type-group"
      :firstSelected="viewTypeList"
      @firstNodeClick="listViewType(true)"
      @secondNodeClick="listViewType(false)"
    )

  template(slot="content-center")

    TableLoading(v-if="isLoading")

    // EMPTY PAGE
    EmptyState(
      v-if="isStoreGroupsEmpty && !isLoading",
      :title="$t('StoreGroups.emptyState_Title')",
      :description="$t('StoreGroups.emptyState_Description')",
      :buttonText="$t('StoreGroups.emptyState_Button')",
      @emptyAddButton="itemNew"
    )
    // LIST
    TableView(
      v-if="!isStoreGroupsEmpty && !isLoading && viewTypeList"
      :page="Page"
      :fields="storeGroupItemFields"
      :actions="['edit', 'remove']"
      :data="storeGroupList"
      :componentName="'StoreGroupsTable'"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemEdit="itemEdit"
      @delegateOnItemDelete="itemRemove"
    )
    BoxView(
      v-if="!isStoreGroupsEmpty && !isLoading && !viewTypeList"
      :fields="storeGroupItemFields"
      :actions="['edit', 'remove']"
      :data="storeGroupList"
      :page="Page"
      :extraClass="{forContent: 'm-auto'}"
      :componentName="'StoreGroupsBox'"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnItemEdit="itemEdit"
      @delegateOnItemRemove="itemRemove"
    )
    
    // POPUPS
    router-view

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//
import pageTabButtons from '@/view/global/page-tab-buttons'
import ViewType from '@/view/global/view-type'

export default {
  name: 'StoreGroups',

  created () {
    this.getStoreGroups()
  },

  components: {
    pageTabButtons,
    ViewType
  },

  data () {
    return {
      viewTypeList: true,
      search: null
    }
  },

  computed: {
    ...mapGetters('StoreGroups', [
      'storeGroupList',
      'isStoreGroupsEmpty',
      'Page'
    ]),

    ...mapGetters('Stores', ['pageHeaderLinks']),

    isLoading () {
      return this.$wait.is(['getStoreGroupList'])
    },

    storeGroupItemFields () {
      return [{
        name: 'name',
        is_visible: true,
        headerTitle: !this.viewTypeList ? true : false,
        short_title: this.viewTypeList ? this.$t('Hierarchy.popupForm_Field_Store_Group_Name') : '',
        title: this.viewTypeList ? this.$t('Hierarchy.popupForm_Field_Store_Group_Name') : ''
      }, {
        name: 'code',
        is_visible: true,
        short_title: this.$t('StoreGroups.popupForm_Field_Code'),
        title: this.$t('StoreGroups.popupForm_Field_Code')
      }]
    }
  },

  methods: {
    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),

    getStoreGroups (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        name: this.search,
        pageSize: pageSize,
        pageNumber: pageNumber
      }
      this.getStoreGroupList(payload)
    },

    changePage (page) {
      this.getStoreGroups(page)
    },
    changePageSize (pageSize) {
      this.getStoreGroups(1, pageSize)
    },

    onChangeSearch (search) {
      if (search.length === 1) return
      this.search = search
      this.getStoreGroups()
    },

    itemNew () {
      this.$router.push({
        name: 'StoreGroupNew'
      })
    },

    itemEdit (item) {
      this.$router.push({
        name: 'StoreGroupEdit',
        params: {
          id: item.id,
          item
        }
      })
    },

    itemRemove (item) {
      this.$router.push({
        name: 'StoreGroupRemove',
        params: {
          id: item.id,
          item
        }
      })
    },
    listViewType (type) {
      this.viewTypeList = type
    }
  }
}
</script>
