<template lang="pug">
Page
  template(slot="header-left-dropdown")
    div
  template(slot="content-center")
    .server-side-reporting-content
      TableLoading(v-if="loadIframe")
      iframe.server-side-reporting-tool(
      ref="reportIframe"
      id="reportIframe")

</template>

<script>
// import service from '@/utils/http'
import { mapGetters } from 'vuex'

export default {
  name: 'ReportingServerSide',

  data () {
    return {
      loadIframe: false
    }
  },

  computed: {
    ...mapGetters('Auth', ['Token']),

    reportId () {
      return this.$route.params.id
    },

    reportName () {
      return this.$route.params.name
    }
  },

  methods: {
    async loadReport () {
      this.loadIframe = true
      const reportIframe = document.getElementById('reportIframe')
      // const url = `${window.BACK_END_DOMAIN}/Reporting?reportid=${this.reportId}&css=${window.FRONT_END_DOMAIN}/static/css/customreport.css&Token=${this.Token.value}`
      const url = `${window.VUE_APP_REPORT_URL || process.env.VUE_APP_REPORT_URL}/api/Reporting?reportid=${this.reportId}&Token=${this.Token.value}`
      reportIframe.src = url
    }
  },

  async mounted () {
    await this.loadReport()
    this.$nextTick(() => {
      document.getElementById('reportIframe').addEventListener('load', e => {
        this.loadIframe = false
        const reportIframe = document.getElementById('reportIframe')
        reportIframe.style.height = reportIframe.contentWindow.parent.document.body.scrollHeight + 'px'
      })
    })
  }
}
</script>

<style lang="scss" scoped>

  .Page {
    padding-bottom: 0px !important;
  }
  .server-side-reporting-tool {
    position: relative;
    width: 100%;
  }

  .ReportHeader {
    margin-bottom: 4px !important;
  }
</style>
