<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    .form-item-select.date-start
      CustomDatepicker#IncomeStatsDatepicker(
        v-model="dates",
        :disabledEndDate="dateEndDisable"
        :range="true"
        :isLoading="$wait.is('getDateFilterSettings')"
        :disabled="isLoading || isPageLoading"
      )

    // PAGE CONTENT /////////////////////////////
  template(slot="toolbar-left")
    .toolbar-filters__item
      CustomMultiselect.toolbar-filters__item(
        id="reports-income-stats-storegroups-kind-types"
        :items="kindTypes"
        :selected="kindTypeList"
        type="radio"
        :showAll="false"
        :label="$t('IncomeStats.filter_kind_types_name')"
        :popupHeader="$t('IncomeStats.filter_kind_types')"
        :unselectedText="$t('IncomeStats.toolbar_Stores_Unselected')"
        :allLabel="$t('IncomeStats.toolbar_Stores_AllLabel')"
        :buttonText="$t('IncomeStats.toolbar_Stores_ButtonText')"
        @delegateCheckItem="onChangeKindTypes"
        @delegateSubmitItems="getIncomeStatsWithParams"
        buttonIconLeft="btn-success"
        ref="kindTypeList")

    .toolbar-filters__item
      CustomMultiselect.toolbar-filters__item(
        id="reports-income-stats-storegroups-filter"
        :items="storeGroupList"
        :selected="storeGroupFilter"
        :label="$t('StoreGroups.popupForm_Name')"
        :popupHeader="$t('StoreGroups.storeGroupFilterLabel')"
        :unselectedText="$t('IncomeStats.toolbar_Stores_Unselected')"
        :allLabel="$t('IncomeStats.toolbar_Stores_AllLabel')"
        :buttonText="$t('IncomeStats.toolbar_Stores_ButtonText')"
        buttonIconLeft="btn-success"
        @delegateCheckAll="onChangeStoreGroupFilter"
        @delegateCheckItem="onChangeStoreGroupFilter"
        @delegateSubmitItems="getIncomeStatsWithParams")

    .toolbar-filters__item
      RVCFilter(@submitFilter="updateRVCFilterItems")

    .toolbar-filters__item
      CustomMultiselect.toolbar-filters__item(
        id="custom-multiselect-income-stats-other-expenses"
        :items="OtherExpenseList"
        :label="$t('StoreGroups.showOtherCosts')"
        :selected="selectedExpenseList"
        :popup-header="$t('IncomeStats.expenseListSelect')"
        :show-all="false"
        :buttonText="$t('IncomeStats.toolbar_Stores_ButtonText')"
        type="radio"
        buttonIconLeft="btn-success"
        :unselectedText="$t('IncomeStats.expenseListSelectPlaceholderText')"
        @delegateSubmitItems="getIncomeStatsWithParams"
        @delegateCheckItem="onChangeExpenseList"
      )
    div
      Button(
        size="small"
        @click="getIncomeStatsWithParams(to = 1)"
        id="income-stats-get-income-stats-button",
        :isLoading="isLoading"
        :disabled="isLoading"
        primary
      )
        span  {{ $t('IncomeStats.getIncomeStats') }}
    

  template(slot="toolbar-right")
    totalCounts.m-right-15(
      v-if="IncomeStats.length > 0"
      :totalData="totals"
      :isLoading="isLoadingTotals"
      title="IncomeStats.Show_Total_Counts_Button_Title"
    )
    ImportExport(
      componentName="Reports-IncomeStats"
      ref="wrapper"
      :isDisabled="isPageLoading"
      :activeImportExports=['export']
      downloadUrl="IncomeStats/incomestats/export"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="downloadFileName"
    )
  template(slot="content-center")
    .IncomeStatsReport
      .results
        // LOADING

        TableLoading(v-if="isPageLoading")

        // EMPTY PAGE
        EmptyState(
        v-if="isEmpty && !isLoading && !isPageLoading || !isDataChecked"
        :title="isDataChecked ? $t('IncomeStats.emptyState_Title') : $t('IncomeStats.emptyState_makeSelection_Title')"
        :description="isDataChecked ? $t('IncomeStats.emptyState_Description') : $t('IncomeStats.emptyState_makeSelection_Description')")
        TableView(
          v-if="!isEmpty && !isPageLoading && !isLoading && isDataChecked"
          :page="Page"
          :fields="fields"
          :componentName="'ReportsIncomeTable'"
          :data="IncomeStats"
          :read-only="true",
          :useInfiniteScroll="true"
          :infoData="popupInfoObject"
          :isLoadingInfiniteScrollRequest="isLoading"
          @delegateOnChangePage="page => getIncomeStatsWithParams(page, true)"
          class="reports-income-table"
        )
        portal(to="name")
          span.portal-detail-type(slot-scope="row")
            span {{row.name}}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import getFields from './fields/income-stats'
import ImportExport from '@/view/global/import-export'
import RVCFilter from '@/view/global/business-filters/rvc'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import totalCounts from '@/view/global/total-counts'
import getDateFilterSetting from '@/utils/getDateFilterSetting'

export default {
  name: 'IncomeStats',

  components: {
    ImportExport,
    ToggleOverGroups,
    RVCFilter,
    totalCounts
  },

  data () {
    return {
      pageHeaderLinks: [
        'SalesReports',
        'IncomeStats',
        'ForecastSales'
      ],
      fields: getFields(this.$t),
      dates: [],
      RVCFilter: [],
      storeGroupFilter: [],
      kindTypeList: [],
      showTotalCountPopup: false,
      isDataChecked: false,
      selectedExpenseList: [],
      kindTypes: [
        {
          id: -1,
          name: this.$t('IncomeStats.filter_all')
        },
        {
          id: 1,
          name: this.$t('IncomeStats.filter_sales')
        },
        {
          id: 2,
          name: this.$t('IncomeStats.filter_complimentary')
        },
        {
          id: 3,
          name: this.$t('IncomeStats.filter_return')
        },
        {
          id: 4,
          name: this.$t('IncomeStats.filter_refundudMoney')
        }
      ],
      popupInfoObject: [
        {
          value: 'success',
          text: this.$t('IncomeStats.tableColumn_Color_Gift')
        },
        {
          value: 'warning',
          text: this.$t('IncomeStats.tableColumn_Color_Return')
        },
        {
          value: 'gray',
          text: this.$t('IncomeStats.tableColumn_Color_Passive')
        }
      ]
    }
  },

  beforeMount () {
    this.kindTypeList = [this.kindTypes[0]]
  },

  async mounted () {
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_IncomeStatsReport')
    this.getExpenseList()
    this.getStoreGroupList({pageNumber: 1, pageSize: 9999}).then(() => {
      this.storeGroupFilter = [...this.storeGroupList]
    })
    this.$refs.kindTypeList.beforeSubmit()
  },

  computed: {
    ...mapGetters('IncomeStats', [
      'IncomeStats',
      'isEmpty',
      'Page',
      'TotalInfo'
    ]),

    ...mapGetters('ExpenseList', [
      'OtherExpenseList'
    ]),

    ...mapGetters('StoreGroups', [
      'storeGroupList'
    ]),

    isLoading () {
      return this.$wait.is(['IncomeStats/getIncomeStats'])
    },
    isLoadingTotals () {
      return this.$wait.is('IncomeStats/getIncomeStatsTotal')
    },
    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    dateStartDisable () {
      return {
        to: moment()._d
      }
    },

    dateEndDisable () {
      return {
        from: moment()._d
      }
    },

    downloadParams () {
      return {
        pageNumber: this.Page.number,
        pageSize: this.Page.size,
        incomeStatsFilterType: this.kindTypeList[0].id,
        startDate: this.dateFormat(this.dates[0]),
        endDate: this.dateFormat(this.dates[1]),
        revenueCenterList: this.RVCFilter.map(item => item.id),
        storeGroupList: this.storeGroupFilter.map(item => item.id)
      }
    },
    downloadFileName () {
      const fileName = `IncomeStatsReport_${this.dateFormat(this.dates[0])}_${this.dateFormat(this.dates[1])}`
      return fileName
    },

    totals () {
      // types
      // 0 ==> currency
      // 1 ==> percent
      return [
        {
          key: this.$t('IncomeStats.header_total_counts_grossAmount'),
          value: this.TotalInfo.grossAmount,
          type: 0
        },
        {
          key: this.$t('IncomeStats.header_total_counts_discountAmount'),
          value: this.TotalInfo.discountAmount,
          type: 0
        },
        {
          key: this.$t('IncomeStats.header_total_counts_taxAmount'),
          value: this.TotalInfo.totalTaxAmount,
          type: 0
        },
        {
          key: this.$t('IncomeStats.header_total_counts_taxFreeAmount'),
          value: this.TotalInfo.totalTaxFreeAmount,
          type: 0
        },
        {
          key: this.$t('IncomeStats.header_total_counts_returnAmount'),
          value: this.TotalInfo.returnAmount,
          type: 0
        },
        {
          key: this.$t('IncomeStats.header_total_counts_complimentaryAmount'),
          value: this.TotalInfo.complimentaryAmount,
          type: 0
        },
        {
          key: this.$t('IncomeStats.header_total_counts_cost'),
          value: this.TotalInfo.cost,
          type: 0
        },
        {
          key: this.$t('IncomeStats.header_total_counts_profit'),
          value: this.TotalInfo.profit,
          type: 0
        },
        {
          key: this.$t('IncomeStats.header_total_counts_costPercentage'),
          value: this.TotalInfo.costPercentage,
          type: 1
        },
        {
          key: this.$t('IncomeStats.header_total_counts_profitPercentage'),
          value: this.TotalInfo.profitPercentage,
          type: 1
        },
        {
          key: this.$t('IncomeStats.header_total_counts_refundedMoney___'),
          value: this.TotalInfo.returnWithMoney || 0,
          type: 0
        }
      ]
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('IncomeStats', [
      'getIncomeStats',
      'getIncomeStatsTotal'
    ]),

    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),

    ...mapActions('ExpenseList', ['getAllExpenseList']),

    changeTotalCountsDropdownStatus () {
      this.showTotalCountPopup = !this.showTotalCountPopup
    },

    closeTotalCountsDropdownStatus () {
      this.showTotalCountPopup = false
    },

    async getExpenseList () {
      const payload = {
        pageNumber: 1,
        pageSize: 99999999,
        name: null
      }
      await this.getAllExpenseList(payload)
    },

    async getIncomeStatsWithParams (to = 1, infiniteScroll = false, pageNumber = 1, pageSize = this.Page.size) {
      const params = {
        to,
        infiniteScroll,
        incomeStatsFilterType: this.kindTypeList[0].id,
        startDate: this.dateFormat(this.dates[0]),
        endDate: this.dateFormat(this.dates[1]),
        revenueCenterList: this.RVCFilter.map(i => i.id),
        storeGroupList: this.storeGroupFilter.map(i => i.id),
        otherExpenseListHeaderId: this.selectedExpenseList.length ? this.selectedExpenseList[0].id : null,
        pageNumber,
        pageSize
      }
      this.isDataChecked = true
      await this.getIncomeStats(params)
    },

    onChangeExpenseList (event) {
      this.selectedExpenseList = [event.item]
    },

    dateFormat (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    updateRVCFilterItems ({ list, dontSubmit }) {
      this.RVCFilter = list
      if (!dontSubmit) this.getIncomeStatsWithParams()
    },

    onChangeStoreGroupFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.storeGroupFilter = [...this.storeGroupList]
        } else {
          this.storeGroupFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.storeGroupFilter = []
        } else {
          this.storeGroupFilter.splice(this.storeGroupFilter.indexOf(event.item), 1)
        }
      }
    },

    onChangeKindTypes (event) {
      this.kindTypeList = [event.item]
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";

  .download-csv {
    margin-right: 10px;
  }

  .PageHeaderExtra {
    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-gray;
  }

  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  .results {
    position: relative;
  }
  .total-counts {
    position: relative;

    &-button {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 20px;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      outline: none;
      cursor: pointer;

      &-title {
        color: $color-dark;
        font-size: $font-size-micro;
        font-weight: 600;
        white-space: nowrap;
      }

      svg {
        margin-left: 10px;
        color: $color-success;
      }
    }

    &-items {
      display: none;
      position: absolute;
      margin-top: 10px;
      z-index: 3;
      font-size: $font-size-small;
      box-shadow: 0 0 10px rgba(black, 0.2);
      background-color: white;
      border: 1px solid $color-primary;
      border-radius: $border-radius;
      right: 0;

      &::before {
      content: '';
      position: absolute;
      width: $triangle-size;
      height: $triangle-size;
      border: solid $color-primary;
      background-color: #fff;
      border-width: 1px 0 0 1px;
      border-top-left-radius: $border-radius;
      transform: rotate(45deg);
      top: -$triangle-size / 2 - 1.5px; // -1.5px borderdan geliyor
      right: 25px;
    }
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 33.33%;
        padding: 20px 10px;
        border-right: 1px solid $color-gray;
        border-bottom: 1px solid $color-gray;

        &-key {
          color: $color-success;
          font-weight: $font-weight-bold;
          margin-bottom: 5px;
        }
      }
    }

    .show-total-counts {
      display: flex;
      flex-wrap: wrap;
      min-width: 500px;
    }
  }

  :deep() .vue-portal-target{
    display: flex;
    align-items: center;
  }

  .reports-income-table :deep() .Tableee-main {
    min-height: 230px;
  }

  $info-icon-size: 15px;

  .category {
    display: flex;

    &-info {
      position: relative;
      height: auto;

      &-icon {
        margin-left: 2.4px;
        width: $info-icon-size;
        height: $info-icon-size;
        color: $color-primary;
        cursor: pointer;
      }
    }

    &-popup {
      @include MiniPopupMixin(
        $display: none,
        $width: 186px,
        $max-width: 340px,
        $triangle-direction: 'left',
        $padding-hor: 0,
        $padding-ver: 0,
      );

      color: $color-dark;

      &-row {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
      }

      &-row:not(:last-child) {
        border-bottom: 1px solid $color-gray;
      }

      &-value {
        font-size: $font-size-normal;
        min-width: 30px;
        min-height: 7px;
        border-radius: 3.5px;
      }

      &-text {
        margin-left: 11px;
        color: $color-dark;
        font-size: $font-size-normal;
      }
    }
  }

</style>
