var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InventoryClosedPage" },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "toolbar" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("CustomCheckbox", {
                  staticClass: "m-left-15",
                  attrs: {
                    id: `checkbox-calculate-waste-consumption`,
                    label: _vm.$t(
                      "Inventory.closed_detailList_waste-consumption"
                    ),
                    checked: _vm.includeWasteInConsumption,
                  },
                  on: { change: _vm.getInventoryWasteInConsumption },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right flexbox" },
              [
                _c("ImportExport", {
                  ref: "wrapper",
                  staticClass: "m-right-15",
                  attrs: {
                    componentName: "inventory-closed",
                    isDisabled: _vm.isLoading,
                    activeImportExports: ["export"],
                    downloadUrl: "Inventory/inventory/export",
                    downloadFileType: "xlsx",
                    downloadDisabled: _vm.downloadButtonDisabled,
                    downloadParams: _vm.downloadParams,
                    downloadFileName: _vm.inventoryBreadCrump,
                  },
                }),
                !_vm.isEmpty && !_vm.isLoading
                  ? _c("totalCounts", {
                      attrs: {
                        totalData: this.totals(),
                        isLoading: _vm.isLoading,
                        title: "Inventory.Show_Total_Counts_Button_Title",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isLoading ? _c("TableLoading") : _vm._e(),
      !_vm.isEmpty && !_vm.isLoading
        ? [
            _c("TableView", {
              attrs: {
                componentName: "InventoryClosedTable",
                fields: _vm.fields,
                data: _vm.inventory_Detail.list,
                readOnly: true,
              },
              on: { sort: _vm.sort },
            }),
            _c("portal", {
              attrs: { to: "itemGroupName" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (row) {
                      return _c("div", {}, [
                        _c("span", [_vm._v(_vm._s(row.itemGroupName))]),
                      ])
                    },
                  },
                ],
                null,
                false,
                2257238191
              ),
            }),
            _c("portal", {
              attrs: { to: "stockItemName" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (row) {
                      return _c("div", {}, [
                        _c("span", [_vm._v(_vm._s(row.stockItemName))]),
                      ])
                    },
                  },
                ],
                null,
                false,
                895539952
              ),
            }),
            _c("portal", {
              attrs: { to: "detail" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (row) {
                      return _c("div", {}, [
                        _c("span", { staticClass: "mono u-textRight" }, [
                          _vm._v(
                            "% " +
                              _vm._s(
                                _vm._f("percentFixed")(
                                  _vm.differenceQuantityRatio(
                                    row.differenceQuantity,
                                    row.consumptionQuantity
                                  )
                                )
                              )
                          ),
                        ]),
                      ])
                    },
                  },
                ],
                null,
                false,
                3589889262
              ),
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }