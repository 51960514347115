var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "Confirm",
      attrs: { isOpen: _vm.isOpenAgreementModal, showCloseButton: false },
    },
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("UserAgreement.title"))),
      ]),
      _c("template", { slot: "content" }, [
        _c("div", {
          staticClass: "Confirm-agreementText",
          domProps: { innerHTML: _vm._s(_vm.agreement.agreementText) },
        }),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "button", primary: "", size: "medium" },
              on: { click: _vm.confirmAgreementAction },
            },
            [_vm._v(_vm._s(_vm.$t("UserAgreement.confirmationButton")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }