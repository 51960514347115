var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-left-dropdown" }, [_c("div")]),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "HelpCenter",
              customSuggestionPlaceHolder: _vm.$t("Global.search_Placeholder"),
              customSuggestionTitle: _vm.$t("Global.search_SuggestionTitle"),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isPageLoading
            ? _c("TableLoading")
            : _c("div", { staticClass: "help-center" }, [
                _c("div", { staticClass: "help-center-container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "row-left" }, [
                      _c(
                        "div",
                        { staticClass: "page-list p-left-10" },
                        [
                          _c(
                            "Accordion",
                            _vm._l(_vm.listItems, function (mainCategory) {
                              return _c(
                                "div",
                                {
                                  key: mainCategory.id,
                                  staticClass: "accordion-list",
                                },
                                [
                                  mainCategory.helpSubCategoryList.length > 0
                                    ? _c(
                                        "AccordionItem",
                                        {
                                          staticClass: "m-top-15",
                                          attrs: {
                                            title: mainCategory.name,
                                            isActive:
                                              _vm.selectedCategory.id ===
                                              mainCategory.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "accordion-body" },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass: "list-accordion",
                                                },
                                                _vm._l(
                                                  mainCategory.helpSubCategoryList,
                                                  function (subCategory) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: subCategory.id,
                                                        staticClass:
                                                          "list-accordion-item",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getDocuments(
                                                              subCategory
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "data-link",
                                                            class: [
                                                              subCategory.id ===
                                                              _vm
                                                                .selectedSubCategory
                                                                .id
                                                                ? "active"
                                                                : "",
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                subCategory.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row-right" },
                      [
                        _vm.isDataFetching
                          ? _c("TableLoading")
                          : [
                              !_vm.isDataFetching && _vm.isEmptyDocumentList
                                ? _c("EmptyState", {
                                    attrs: {
                                      title: _vm.$t(
                                        "Global.helpCenter_emptyState_NotFound_Title"
                                      ),
                                      description: _vm.$t(
                                        "Global.helpCenter_emptyState_NotFound_Description"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              !_vm.isEmptyDocumentList
                                ? _c(
                                    "Accordion",
                                    _vm._l(
                                      _vm.allDocumentList,
                                      function (document) {
                                        return _c(
                                          "AccordionItem",
                                          {
                                            key: document.id,
                                            staticClass: "accordion-desc",
                                            attrs: { title: document.subject },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "accordion-body" },
                                              [
                                                _c("p", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      document.description
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }