<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="Settings"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingData")

    div(v-else="!isFetchingData")

      TableView.m-top-15(
        :componentName="'exportSettings'"
        :fields="documentSettingFields"
        :data="DocumentSettingList"
        :readOnly="true"
      )

      portal(to="module")
        div(slot-scope="row")
          span {{ $t('DocumentSettings.TableField_' + row.key) }}

      portal(to="showDrafts")
        .on-button(slot-scope="row")
          CustomRadio(
            :checked="row.isChecked"
            :disabled="isSettingData"
            label=""
            @change="changeDocumentSettings(row, true)"
          )

      portal(to="dontShowDrafts")
        .off-button(slot-scope="row")
          CustomRadio(
            :checked="!row.isChecked"
            :disabled="isSettingData"
            label=""
            @change="changeDocumentSettings(row, false)"
          )

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

import ToggleOverGroups from '@/view/global/toggle-over-groups'

export default {
  name: 'DocumentSettings',

  components: {
    ToggleOverGroups
  },

  computed: {
    ...mapGetters('Settings', ['pageHeaderLinks']),
    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    isFetchingData () {
      return this.$wait.is(['getDocumentFilterSettings'])
    },

    isSettingData () {
      return this.$wait.is(['changeDocumentSettings'])
    },

    documentSettingFields () {
      return [
        {
          name: 'module',
          is_visible: true,
          type: 'portal',
          short_title: this.$t('ExportSettings.table_header_module'),
          title: this.$t('ExportSettings.table_header_module')
        },
        {
          name: 'showDrafts',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('DocumentSettings.TableField_ShowDrafts'),
          title: this.$t('DocumentSettings.TableField_ShowDrafts')
        },
        {
          name: 'dontShowDrafts',
          type: 'portal',
          is_visible: true,
          short_title: this.$t('DocumentSettings.TableField_DontShowDrafts'),
          title: this.$t('DocumentSettings.TableField_DontShowDrafts')
        }
      ]
    }
  },

  methods: {
    ...mapActions('DocumentSettings', ['getDocumentFilterSettings']),
    ...mapActions('ExportSettings', ['setExportSettings']),

    ...mapMutations('DocumentSettings', ['SET_CHECKED_STATUS']),

    changeHeader (item) {
      this.selectedHeader = item
    },

    changeDocumentSettings: vueWaitLoader(async function (item, status) {
      if (item.isChecked === status) return
      this.SET_CHECKED_STATUS({item: item, checkStatus: status})
      await this.setDocumentSetting(item)
    }, 'changeDocumentSettings'),

    async setDocumentSetting (item) {
      const settingData = this.DocumentSettingList.find(i => i.key === item.key)
      const payload = {
        settings: {
          [settingData.key]: settingData.isChecked ? 'True' : 'False'
        }
      }
      await this.setExportSettings(payload)
    }
  },

  async mounted () {
    await this.getDocumentFilterSettings()
  }
}
</script>
