var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select date-start" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "ForecastSalesDatepicker",
                range: true,
                disabled: _vm.isPageLoading,
                isLoading: _vm.isGettingDateFilterSettings,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "toolbar-left" }, [
        _c(
          "div",
          { staticClass: "toolbar-filters__item" },
          [
            _c("CustomMultiselect", {
              staticClass: "toolbar-filters__item",
              attrs: {
                id: "reports-income-stats-storegroups-filter",
                items: _vm.storeGroupList,
                selected: _vm.storeGroupFilter,
                label: _vm.$t("StoreGroups.popupForm_Name"),
                popupHeader: _vm.$t("StoreGroups.storeGroupFilterLabel"),
                unselectedText: _vm.$t("IncomeStats.toolbar_Stores_Unselected"),
                allLabel: _vm.$t("IncomeStats.toolbar_Stores_AllLabel"),
                buttonText: _vm.$t("IncomeStats.toolbar_Stores_ButtonText"),
                buttonIconLeft: "btn-success",
              },
              on: {
                delegateCheckAll: _vm.onChangeStoreGroupFilter,
                delegateCheckItem: _vm.onChangeStoreGroupFilter,
                delegateSubmitItems: _vm.getForecasts,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "toolbar-filters__item" },
          [_c("RVCFilter", { on: { submitFilter: _vm.updateRVCFilterItems } })],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            attrs: {
              componentName: "reports-forecast-sales",
              isDisabled: _vm.isPageLoading,
              activeImportExports: ["export"],
              downloadUrl: "IncomeStats/Forecast/export",
              downloadFileType: "xlsx",
              downloadParams: _vm.downloadParams,
              downloadFileName: _vm.downloadFileName,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "IncomeStatsReport" }, [
          _c(
            "div",
            { staticClass: "results" },
            [
              _vm.isPageLoading ? _c("TableLoading") : _vm._e(),
              !_vm.ForecastSales.length
                ? _c("EmptyState", {
                    attrs: {
                      title: _vm.$t("IncomeStats.forecastEmptyState_Title"),
                      description: _vm.$t("IncomeStats.emptyState_Description"),
                    },
                  })
                : _vm._e(),
              _vm.ForecastSales.length
                ? _c("TableView", {
                    staticClass: "reports-forecast-table",
                    attrs: {
                      page: _vm.ForecastPage,
                      fields: _vm.fields,
                      componentName: "ReportsForecastTable",
                      data: _vm.ForecastSales,
                      "read-only": true,
                      useInfiniteScroll: true,
                      isLoadingInfiniteScrollRequest: _vm.isPageLoading,
                    },
                    on: { delegateOnChangePage: (page) => _vm.getForecasts },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }