var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c("CustomDatepicker", {
            staticClass: "m-right-15",
            attrs: {
              name: "integrationLog",
              range: true,
              id: "custom-datepicker-integration-logs",
            },
            model: {
              value: _vm.dates,
              callback: function ($$v) {
                _vm.dates = $$v
              },
              expression: "dates",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: { primary: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.getList(_vm.Page.number, _vm.Page.size)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("IntegrationLogs.getLogList")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.IntegrationLogs.length && !_vm.isLoading
            ? _c("TableView", {
                attrs: {
                  data: _vm.IntegrationLogs,
                  fields: _vm.fields,
                  page: _vm.Page,
                  readOnly: true,
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                },
              })
            : _vm._e(),
          !_vm.IntegrationLogs.length && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.mountedState
                    ? _vm.$t("IntegrationLogs.makeSelection")
                    : _vm.$t("IntegrationLogs.logList_empty_title"),
                  description: _vm.mountedState
                    ? _vm.$t("IntegrationLogs.selectDate_and_continue")
                    : _vm.$t("IntegrationLogs.logList_empty_description"),
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "processName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("IntegrationLogs.processDespatch"))),
                    ]),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }