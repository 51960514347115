var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
            scopedSlots: _vm._u([
              {
                key: "linkExtra",
                fn: function (link) {
                  return [
                    _vm.suspendSalesExist && link.linkName === "SuspendedSales"
                      ? _c("Icon", {
                          staticClass: "icon-suspended-status m-right-5",
                          attrs: { name: "icon-row-status" },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: { componentName: "RevenueCenters" },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              staticClass: "top-button m-right-10",
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-revenue-centers-add",
                type: "button",
              },
              on: { click: _vm.onClickCreateRevenueCenterButton },
            },
            [
              _c("Icon", {
                staticClass: "m-right-5",
                attrs: { name: "icon-global-add" },
              }),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("RevenueCenters.addNewRevenueCenterButton"))
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ViewType", {
            attrs: {
              firstNodeIcon: "icon-view-type-list",
              secondNodeIcon: "icon-view-type-group",
              firstSelected: _vm.viewTypeList,
            },
            on: {
              firstNodeClick: function ($event) {
                return _vm.listViewType(true)
              },
              secondNodeClick: function ($event) {
                return _vm.listViewType(false)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.isEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("RevenueCenters.emptyStateTitle"),
                  description: _vm.$t("RevenueCenters.emptyStateDescription"),
                  buttonText: _vm.$t("RevenueCenters.emptyStateNewButton"),
                },
                on: { emptyAddButton: _vm.onClickCreateRevenueCenterButton },
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && _vm.viewTypeList
            ? _c("TableView", {
                attrs: {
                  fields: _vm.fields,
                  actions: ["edit"],
                  data: _vm.revenueCenterList.list,
                  page: _vm.rvcListPagination,
                  componentName: "RevenueCentersTable",
                  addScrollToNoFixedColumns: true,
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemEdit: _vm.onClickEditRevenueCenterButton,
                  sort: _vm.sort,
                },
              })
            : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading && !_vm.viewTypeList
            ? _c("BoxView", {
                attrs: {
                  fields: _vm.fields,
                  actions: ["edit"],
                  data: _vm.revenueCenterList.list,
                  page: _vm.rvcListPagination,
                  showPassiveBox: true,
                  componentName: "RevenueCentersBox",
                },
                on: {
                  delegateOnChangePage: _vm.changePage,
                  delegateOnChangePageSize: _vm.changePageSize,
                  delegateOnItemEdit: _vm.onClickEditRevenueCenterButton,
                },
              })
            : _vm._e(),
          _c("portal", {
            attrs: { to: "box-header-left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isTypeSimpra(row.integrationProductName)
                        ? _c("Icon", {
                            staticClass: "portal-icon",
                            attrs: { name: "simpra-logo-dark" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("router-view", { on: { submit: _vm.getRVCListWithParams } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }