<template lang="pug">
Page
  template(slot="header-left-dropdown")
    pageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-content")
    Button(
      size="medium"
      primary
      id="btn-supplier-groups-new"
      type="button"
      @click="itemNew"
      v-show="!isEmptySupplierGroups && !isLoading")
        svg.icon.icon-left.icon-global-add
          use(xlink:href="#icon-global-add")
        span {{ $t('SupplierGroups.headerNewButton') }}

  template(slot="content-center")
    .SupplierGropsPageContent

      TableLoading(v-if="isLoading")
      EmptyState(
      v-if="isEmptySupplierGroups && !isLoading",
      :title="$t('SupplierGroups.emptyState_Title')",
      :description="$t('SupplierGroups.emptyState_Description')",
      :buttonText="$t('SupplierGroups.emptyState_Button')",
      @emptyAddButton="itemNew")

      // LIST

      BoxView(
        v-if="!isEmptySupplierGroups && !isLoading"
        :fields="fields"
        :actions="['edit', 'remove']"
        :data="supplierGroups"
        :componentName="'SupplierGroupList'"
        @delegateOnItemEdit="itemEdit"
        @delegateOnItemRemove="itemRemove"
      )
    router-view
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//

export default {
  name: 'SupplierGroups',

  computed: {
    ...mapGetters('Supplier', ['pageHeaderLinks']),
    ...mapGetters('SupplierGroups', [
      'supplierGroups',
      'isEmptySupplierGroups'
    ]),
    isLoading () {
      return this.$wait.is(['SupplierGrops/getSupplierGroups'])
    },
    fields () {
      return [
        {
          name: 'name',
          is_visible: true,
          headerTitle: true
        },
        {
          name: 'taxNumber',
          is_visible: true,
          short_title: this.$t('SupplierGroups.taxNumber'),
          title: this.$t('SupplierGroups.taxNumber')
        }
      ]
    }
  },
  methods: {
    ...mapActions('SupplierGroups', ['getSupplierGroups']),
    itemNew () {
      this.$router.push({
        name: 'NewSupplierGrops'
      })
    },
    itemEdit (group) {
      this.$router.push({
        name: 'EditSupplierGrops',
        params: {
          id: group.id
        }
      })
    },
    itemRemove (group) {
      this.$router.push({
        name: 'SupplierGroupRemove',
        params: {
          id: group.id
        }
      })
    }
  },
  async mounted () {
    await this.getSupplierGroups()
  }
}
</script>
