<template lang="pug">
Page
  template(slot="header-breadcrumb-left")
    .Breadcrumb.m-top-5
      span {{selectedHeader.name}}
  template(slot="header-left-dropdown")
    pageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-left-search")
    Search.search(
      v-if="selectedType.id === null"
      componentName="Supplier",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch")
  template(slot="header-right-content")

    Button(
      size="medium"
      primary
      id="btn-supplier-new"
      @click ="itemNew"
      v-show="!isEmpty && !isLoading"
      iconName="icon-global-add",
      iconClass="icon-global-add icon-left"
    )
      span {{ $t('Supplier.headerNewButton') }}


  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="typeList"
      :class="!selectedHeader.isChecked ? 'disabled-tabs' : ''"
      :selectedItem="selectedType"
      @delegateChangeSelectedItem="changeTab"
      :useUpperCase="true"
    )

  template(v-if="selectedType.id === null" slot="toolbar-left")
    div
      CustomCheckbox.show-passive-suppliers.m-left-5(
        id="checkbox-supplier-show-passive"
        :checked="showPassive"
        :label="$t('Supplier.showPassives')"
        @change="showPassiveSuppliers")

  template(slot="content-center")

    TableLoading(v-if="isLoading")

    // EMPTY PAGE
    EmptyState(
      v-if              ="isEmpty && !isLoading"
      :title            ="$t('Supplier.emptyStateTitle')",
      :description      ="$t('Supplier.emptyStateDescription')",
      :buttonText       ="$t('Supplier.emptyStateNewButton')",
      @emptyAddButton   ="itemNew"
    )

    // LIST

    //- TableView(
    //- v-if="!isEmpty && !isLoading"
    //- :fields="fields"
    //- :data="Suppliers"
    //- :page="Page"
    //- :footNote="$t('Supplier.tableFootNote', { totalpage: Page.total })"
    //- :pointer="true"
    //- @delegateOnItemEdit="itemEdit"
    //- @delegateOnItemDelete="itemDelete"
    //- @delegateOnItemShow="itemShow"
    //- @delegateOnChangePage="changePage")

    .content()
      .store-code(v-if="selectedType.id === 1")

        TableView(
          
          :fields="storeCodeFields"
          :data="storeCodes"
          :readOnly="true"
        )

        portal(to="storeCode")
          div(slot-scope="row")
            customTextInput(
              id="supplier-store-code-input"
              name="storeCode"
              v-model="row.storeCode"
              :placeholder="$t('Stores.storeCode')"
              @input="getStoreCodeText"
              @blur="updateStoreCode(row)"
            )
      .store-code(v-if="selectedType.id === 2")

        TableView(
          :fields="unitCodeFields"
          :data="unitCodes"
          :readOnly="true"
        )

        portal(to="unitCode")
          div(slot-scope="row")
            customTextInput(
              id="supplier-unit-code-input"
              name="unitCode"
              v-model="row.unitCode"
              :placeholder="$t('Stores.unitCode')"
              @input="getUnitCodeText"
              @blur="updateUnitCode(row)"
            )
        
      div(v-if="selectedType.id === 3")
        Loading(v-if="isLoadingConfig")
        .invoice-config(v-else)
          CustomCheckbox.show-passive-suppliers(
            id="checkbox-supplier-show-passive"
            v-model="isServiceSupplier"
            :label="$t('Supplier.isServiceSupplier')"
            @change="updateEInvoiceConfiguration"
          )

          CustomCheckbox.show-passive-suppliers.m-top-15(
            id="checkbox-supplier-show-passive"
            v-model="isEInvoiceStockItemEasyMatchingEnabled"
            :label="$t('Supplier.isEInvoiceStockItemEasyMatchingEnabled')"
            @change="updateEInvoiceConfiguration"
          )
          
          CustomCheckbox.show-passive-suppliers.m-top-15(
            id="checkbox-supplier-show-passive"
            v-model="isTaxIncludedInInvoices"
            :label="$t('Supplier.isTaxIncludedInInvoices')"
            @change="updateEInvoiceConfiguration"
          )
       

    .Tableee-container(v-if="!isEmpty && !isLoading && !isSuppliersLoading && selectedType.id === null")

      .Tableee-main
        table.Tableee-root
          //- Table main head
          thead.Tableee-head
            tr.Tableee-row
              th.Tableee-col
              th.Tableee-col.fixed-width {{ $t('Supplier.tableColumn_Name_Short') }}
              th.Tableee-col.fixed-width {{ $t('Supplier.tableColumn_Currency') }}
              th.Tableee-col.u-textRight {{ $t('Supplier.tableColumn_EmailAddress_Short') }}
              th.Tableee-col.mono {{ $t('Supplier.tableColumn_PhoneNumber_Short') }}
              th.Tableee-col.mono {{ $t('Supplier.tableColumn_ContactName_Short') }}
              th.Tableee-col.mono {{ $t('Supplier.isEInvoicePayer') }}
              th.Tableee-col.mono

          tbody.Tableee-body
            tr.Tableee-row(:class="!supplier.isActive ? 'row-TableViewPassiveItemsNoBorder' : ''" v-for="supplier, index in Suppliers")
              td.Tableee-col
                CustomRadio(
                  :id="`radio-despatch-supplier`"
                  v-if="supplier.isActive"
                  :label="''"
                  :checked="supplier.isChecked"
                  @change="selectHeader(supplier)"
                )

              td.Tableee-col.tooltip-relative {{ getName(supplier.name) }}
                Tooltip(
                  v-if="supplier.name.length > 30"
                  white,
                  bottom,
                  :text="supplier.name"
                )
              td.Tableee-col {{ supplier.currencyIsoCode }}
              td.Tableee-col.u-textRight {{ supplier.emailAddress }}
              td.Tableee-col.mono {{ supplier.phoneNumber }}
              td.Tableee-col.mono {{ supplier.contactName }}
              td.Tableee-col.mono
                ShowBox(:control = "supplier.isEInvoicePayer")

              td.Tableee-col.action

                ActionButton(
                  v-if="supplier.isGlobal === thisUserisGlobal"
                  :data="['edit', 'remove']"
                  :item="supplier"
                  @onItemDelete="itemDelete(supplier)"
                  @onItemEdit="itemEdit(supplier)"
                )
  template(slot="footer")
    TableFoot(
      v-show="selectedType.id === null"
      :page="suppliersListPagination"
      @delegateOnChangePageSize="changePageSize"
      @delegateOnChangePage="getList"
    )

    // POPUPS
    router-view

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import getFields from './fields'
import ShowBox from './components/show-box'
//
import TableHead from '@/view/global/table/head.vue'
import TableFoot from '@/view/global/table/foot.vue'
import ToggleOverGroups from '@/view/global/toggle-over-groups'

export default {
  name: 'Supplier',

  components: {
    TableHead,
    TableFoot,
    ToggleOverGroups,
    ShowBox
  },

  data () {
    return {
      fields: getFields(this.$t),
      searchText: null,
      showPassive: false,
      typeList: [
        {
          name: this.$t('Supplier.SUPPLIERLIST'),
          type: 'supplierList',
          id: null
        },
        {
          name: this.$t('Supplier.STORECODE'),
          type: 'storeCode',
          id: 1
        },
        {
          name: this.$t('Supplier.UNITCODE'),
          type: 'unitCode',
          id: 2
        },
        {
          name: this.$t('Supplier.EInvoiceConfig'),
          type: 'eInvoice',
          id: 3
        }
      ],
      selectedType: {},
      selectOptions: [],
      selectedSupplier: {},
      storeCodes: [],
      unitCodes: [],
      selectedHeader: {},
      unitCodeText: null,
      storeCodeText: null,
      isServiceSupplier: false,
      isEInvoiceStockItemEasyMatchingEnabled: false,
      isTaxIncludedInInvoices: false
    }
  },

  async created () {
    await this.getList()
    await this.setRoleArea()
    this.selectedType = this.typeList[0]
  },

  computed: {
    ...mapGetters('Supplier', ['Suppliers', 'isEmpty', 'suppliersListPagination', 'pageHeaderLinks']),
    ...mapGetters('Settings', ['RoleArea']),
    
    isLoadingConfig () {
      return this.$wait.is('getSupplierEInvoiceConfig')
    },

    isLoading () {
      return this.$wait.is(['getSuppliers'])
    },

    isSuppliersLoading () {
      return this.$wait.is(['matchSuppliers'])
    },

    isCheckedHeader () {
      return this.selectedHeader.isChecked
    },

    storeCodeFields () {
      return [
        {
          name: 'storeName',
          is_visible: true,
          short_title: this.$t('Stores.type_Store'),
          title: this.$t('Stores.type_Store')
        },
        {
          name: 'storeCode',
          is_visible: true,
          type: 'portal',
          short_title: this.$t('Stores.storeCode'),
          title: this.$t('Stores.storeCode')
        }
      ]
    },

    unitCodeFields () {
      return [
        {
          name: 'unitName',
          is_visible: true,
          short_title: this.$t('Stores.unitName'),
          title: this.$t('Stores.unitName')
        },
        {
          name: 'unitCode',
          is_visible: true,
          type: 'portal',
          short_title: this.$t('Stores.unitCode'),
          title: this.$t('Stores.unitCode')
        }
      ]
    },

    thisUserisGlobal () {
      let result
      switch (this.RoleArea) {
        case 'local':
          result = false
          break

        case 'global':
          result = true
          break

        default:
          result = null
          break
      }
      return result
    },
    isTypeSupplierList () {
      return this.selectedType === 'supplierList'
    },
    isTypeUnitCode () {
      return this.selectedType === 'unitCode'
    },
    isTypeStoreCode () {
      return this.selectedType === 'storeCode'
    },
    isTypeEInvoiceConfig () {
      return this.selectedType === 'eInvoice'
    }
  },

  methods: {
    ...mapActions('Supplier', ['getSuppliers', 'setRoleArea', 'searchSupplier', 'getSupplierStoreCodes', 'updateStoreCodes', 'getSupplierUnitCodes', 'updateUnitCodes', 'getSupplierEInvoiceConfig', 'updateSupplierEInvoiceConfig']),
    ...mapActions('Settings', ['setRoleArea']),
    ...mapActions('Notify', ['notifyShow']),

    action (routeName, item) {
      this.$router.push({
        name: routeName,
        params: {
          id: item.id,
          item
        }
      })
    },

    getName (name) {
      return name.length > 30 ? name.substr(0, 30).concat('...') : name
    },

    async showPassiveSuppliers () {
      this.showPassive = !this.showPassive
      this.getList()
    },

    getStoreCodeText (item) {
      this.storeCodeText = item
    },

    selectHeader (item) {
      if (this.selectedHeader.isChecked) this.selectedHeader.isChecked = false
      this.selectedHeader = {}
      this.selectedHeader = item
      this.selectedHeader.isChecked = true
    },

    getUnitCodeText (item) {
      this.unitCodeText = item
    },

    async updateStoreCode(item) {
      this.storeCodes.find(i => {
        if (i.storeId === item.storeId) {
          if (i.storeCode !== item.storeCode) {
            const payload = {
              supplierId: item.supplierId,
              storeId: item.storeId,
              storeCode: this.storeCodeText
            }
            this.updateStoreCodes(payload).then(res => {
              const message = item.storeName + ' ' + this.$t('Supplier.message_store') + ' ' + item.storeCode + ' ' + this.$t('Supplier.message_updated')
              if (res) {
                this.notifyShow({ message })
              }
              this.getStoreCodes()
            })
          }
        }
      })
    },

    async updateUnitCode(item) {
      this.unitCodes.find(i => {
        if (i.unitId === item.unitId) {
          if (i.unitCode !== item.unitCode) {
            const payload = {
              supplierId: item.supplierId,
              unitId: item.unitId,
              unitCode: this.unitCodeText
            }
            this.updateUnitCodes(payload).then(res => {
              const message = item.unitName + ' ' + this.$t('Supplier.message_unit') + ' ' + item.unitCode + ' ' + this.$t('Supplier.message_updated')
              if (res) {
                this.notifyShow({ message })
              }
              this.getUnitCodes()
            })
          }
        }
      })
    },

    onChangeSearch (search) {
      this.searchText = search
      if (this.searchText.length === 1) return
      this.getList()
    },

    changeTab (item) {
      if (this.selectedHeader.isChecked) {
        if (item) this.selectedType = item
        if (this.selectedType.id === 1) this.getStoreCodes()
        if (this.selectedType.id === 2) this.getUnitCodes()
        if (this.selectedType.id === 3) this.getEInvoiceConfiguration()
      }
    },

    getStoreGroupsCode (index) {
      if (this.Suppliers[index].storeGroups.length === 0) return ''
      return this.Suppliers[index].storeGroups.map(item => !item.code ? item.name : item.code).join(', ')
    },

    itemNew () {
      this.$router.push({
        name: 'SupplierNew'
      })
    },
    itemEdit (item) {
      this.action('SupplierEdit', item)
    },
    itemDelete (item) {
      this.action('SupplierRemove', item)
    },
    itemShow (item) {
      this.action('SupplierView', item)
    },

    changePageSize (pageSize) {
      this.getList(1, pageSize)
    },

    async getStoreCodes () {
      const res = await this.getSupplierStoreCodes({id: this.selectedHeader.id})
      if (res) this.storeCodes = res.data.items
    },

    async getUnitCodes () {
      const res = await this.getSupplierUnitCodes({id: this.selectedHeader.id})
      if (res) this.unitCodes = res.data.items
    },

    async getEInvoiceConfiguration () {
      await this.getSupplierEInvoiceConfig({id: this.selectedHeader.id}).then(res => {
        if (res) {
          const config = res.data.supplierEInvoiceSettings
          this.isEInvoiceStockItemEasyMatchingEnabled = config.isEInvoiceStockItemEasyMatchingEnabled
          this.isServiceSupplier = config.isServiceSupplier
          this.isTaxIncludedInInvoices = config.isTaxIncludedInInvoices
        }
      })
    },

    updateEInvoiceConfiguration () {
      const payload = {
        id: this.selectedHeader.id,
        isServiceSupplier: this.isServiceSupplier,
        isEInvoiceStockItemEasyMatchingEnabled: this.isEInvoiceStockItemEasyMatchingEnabled,
        isTaxIncludedInInvoices: this.isTaxIncludedInInvoices
      }
      this.updateSupplierEInvoiceConfig(payload).then(res => {
        if (res) {
          const message = this.$t('Supplier.success_UpdateConfig')
          this.notifyShow({message})
          this.getEInvoiceConfiguration()
        }
      })
    },

    async getList (pageNumber = 1, pageSize = this.suppliersListPagination.size) {
      await this.getSuppliers({ text: this.searchText, pageNumber: pageNumber, pageSize, isActive: this.showPassive ? null : true })
    }
  }
}
</script>

<style lang="scss" scoped>

  .Tableee-main {
    overflow: auto;
  }

  .Search-input{
    position: relative;
    left: 5px;
  }

  .Breadcrumb {
    color: $color-primary;
    font-weight: $font-weight-bold;
    position: absolute !important;
  }
  .disabled-tabs {
    :deep() .OverGroups-item {
      &:nth-child(2) {
        cursor: unset;
        background: $color-gray;
      }
      &:nth-child(3) {
        cursor: unset;
        background: $color-gray;
      }
      &:nth-child(4) {
        cursor: unset;
        background: $color-gray;
      }
    }
  }
  .left {
    display: flex;
  }
  :deep() .row-TableViewPassiveItemsNoBorder td:last-child {
    border-right: 1px solid #80808021;
  }

  :deep() .appTooltip {
    .bottom {
      margin-left: 30px !important;
    }
    .text{
      max-width: max-content !important;
    }
  }

  .invoice-config {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    width: 100%;
    background: white;
    border-radius: 5px;
    height: 80vh;
  }


</style>
