<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="toolbar-left")
    .storeGroupField.dropdown
      span.dropdown-label {{ $t('MenuItemsSettings.mergedStoreGroup') }}
      .dropdown-select
        Icon(
          name="icon-down-arrow"
        )
        select.select.lg(
        id="select-menu-items-settings-storeGroup-name"
        name="storeGroupName",
        v-model="selectedStoreGroupId",
        @change="getStoreGroupIntegrationInfo",
        :class="{ 'is-danger': veeErrors.has('storeGroupName') }"
        v-validate="'required'")
          option(
            :value="null",
            id="option-menu-items-settings-storeGroup-name-unselected") {{ $t('Global.formField_Unselected') }}
          option(
            v-for="storeGroup in avaibleStoreGroupList"
            :key="storeGroup.id"
            :value="storeGroup.id"
            :id="`option-menu-items-settings-storeGroupgroup-name-${storeGroup.name}`"
          ) {{ storeGroup.name }}
  template(slot="toolbar-right")
    Button(
      size="medium",
      primary,
      :disabled="isDisabledMatchedButton",
      :isLoading="isMatchingItems",
      @click="matchingSelectedItems"
    )
      span {{ $t('MenuItemsSettings.matchedButton') }}
  template(slot="content-center")
    .MenuItemsSettingsContent
      .nodata(v-if="noData")
        EmptyState(
          :title="emptyTitle"
          :description="emptyMessage"
        )
      .settingsContent#MenuItemsSettingsPageContent(v-else)
        .integration#MenuItemsSettingsPassviveIntegration(v-if="newIntegrationInfo")
          .integration-name
            span.name {{ $t('MenuItemsSettings.passivePosSystem') }}
            span.value.c-dark  {{ oldIntegrationInfo.integrationProductName }}

          .toolbar-search.m-bottom-15

            SearchInput(
              v-model="oldFieldSearch",
              @onSearch="getMenuItemList('old')"
            )
          .toolbar-filters

            CustomMultiselect.toolbar-filters__item(
            id="imported-menu-items-group-filter"
            :items="oldIntegrationInfo.menuItemGroups"
            :selected="oldIntegrationFilter.menuItemGroups"
            :label="$t('Global.menuItemGroup')"
            :unselectedText="$t('Global.toolbar_Filters_Unselected')"
            :popupHeader="$t('Global.menuItemGroup')"
            :allLabel="$t('Global.toolbar_Filters_AllLabel')"
            :buttonText="$t('Global.toolbar_Filters_ButtonText')"
            buttonIconLeft="btn-success",
            @delegateCheckAll="e => onChangeFilter('old', 'menuItemGroups', e)"
            @delegateCheckItem="e => onChangeFilter('old', 'menuItemGroups', e)"
            @delegateSubmitItems="getMenuItemList('old')")

            CustomMultiselect.toolbar-filters__item(
            id="imported-menu-items-group-filter"
            :items="oldIntegrationInfo.revenueCenters"
            :selected="oldIntegrationFilter.revenueCenters"
            :label="$t('Global.toolbar_RVC_Label')"
            :unselectedText="$t('Global.toolbar_Filters_Unselected')"
            :popupHeader="$t('Global.toolbar_RVC_Label')"
            :allLabel="$t('Global.toolbar_Filters_AllLabel')"
            :buttonText="$t('Global.toolbar_Filters_ButtonText')"
            buttonIconLeft="btn-success",
            @delegateCheckAll="e => onChangeFilter('old', 'revenueCenters', e)"
            @delegateCheckItem="e => onChangeFilter('old', 'revenueCenters', e)"
            @delegateSubmitItems="getMenuItemList('old')")

                // LIST
          TableLoading.m-top-15(v-if="isFetchingOldMenuItems")
          TableView.m-top-15(
            v-else,
            :fields="oldIntegrationField"
            :componentName="'MenuItemsSettingsTable'"
            :readOnly="true"
            :pointer="true"
            :data="oldMenuItemList"
            :selectable="'checkbox'"
            :selectedRows="selectedMenuItems"
            :page="oldListPagination"
            @delegateOnItemSelect="onSelectMenuItem"
            @delegateOnItemShow="onSelectMenuItem"
            @delegateOnChangePage="e => changePage('old', e)"
            @delegateOnChangePageSize="e => changePageSize('old', e)"
          )
        .integration-transition
          Icon(name="icon-table-next")

        .integration(v-if="oldIntegrationInfo")
          .integration-name
            span.name {{ $t('MenuItemsSettings.activePosSystem') }}
            span.value.c-success  {{ newIntegrationInfo.integrationProductName }}

          .toolbar-search.m-bottom-15

            SearchInput(
              v-model="newFieldSearch",
              @onSearch="getMenuItemList('new')"
            )

          .toolbar-filters
            CustomMultiselect.toolbar-filters__item(
            id="imported-menu-items-group-filter"
            :items="newIntegrationInfo.menuItemGroups"
            :selected="newIntegrationFilter.menuItemGroups"
            :label="$t('Global.menuItemGroup')"
            :unselectedText="$t('Global.toolbar_Filters_Unselected')"
            :popupHeader="$t('Global.menuItemGroup')"
            :allLabel="$t('Global.toolbar_Filters_AllLabel')"
            :buttonText="$t('Global.toolbar_Filters_ButtonText')"
            buttonIconLeft="btn-success",
            @delegateCheckAll="e => onChangeFilter('new', 'menuItemGroups', e)"
            @delegateCheckItem="e => onChangeFilter('ewn', 'menuItemGroups', e)"
            @delegateSubmitItems="getMenuItemList('new')")

            CustomMultiselect.toolbar-filters__item(
            id="imported-menu-items-group-filter"
            :items="newIntegrationInfo.revenueCenters"
            :selected="newIntegrationFilter.revenueCenters"
            :label="$t('Global.toolbar_RVC_Label')"
            :unselectedText="$t('Global.toolbar_Filters_Unselected')"
            :popupHeader="$t('Global.toolbar_RVC_Label')"
            :allLabel="$t('Global.toolbar_Filters_AllLabel')"
            :buttonText="$t('Global.toolbar_Filters_ButtonText')"
            buttonIconLeft="btn-success",
            @delegateCheckAll="e => onChangeFilter('new', 'revenueCenters', e)"
            @delegateCheckItem="e => onChangeFilter('new', 'revenueCenters', e)"
            @delegateSubmitItems="getMenuItemList('new')")

            // LIST
          TableLoading.m-top-15(
            v-if="isFetchingNewMenuItems"
          )
          TableView.m-top-15(
            v-else,
            :fields="newIntegrationField",
            :componentName="'NewMenuItemSettingsTable'"
            :readOnly="true",
            :pointer="true",
            :data="newMenuItemList",
            :page="newListPagination",
            @delegateOnChangePage="e => changePage('new', e)"
            @delegateOnChangePageSize="e => changePageSize('new', e)"
          )

          portal(to="destinationMenuItemId")
            div.width-max-content(slot-scope="row")
              CustomRadio(
                label=""
                :checked="!!selectedNewItem && selectedNewItem.id === row.id"
                :tabindex="row.index + 1"
                @change="selectNewItem(row)"
              )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getNewMenuItemsField, getOldMenuItemsField } from './fields/menuItems'
//
import SearchInput from '@/view/global/search-input'

export default {
  name: 'MenuItemsSettings',

  data () {
    return {
      selectedStoreGroupId: null,
      newIntegrationInfo: null,
      oldIntegrationInfo: null,
      oldMenuItems: [],
      newMenuItems: [],
      selectedMenuItems: [],
      selectedNewItem: null,
      newIntegrationFilter: {
        menuItemGroups: [],
        revenueCenters: []
      },
      oldIntegrationFilter: {
        menuItemGroups: [],
        revenueCenters: []
      },
      oldFieldSearch: null,
      newFieldSearch: null,
      newIntegrationField: getNewMenuItemsField(this.$t),
      oldIntegrationField: getOldMenuItemsField(this.$t)
    }
  },

  components: {
    SearchInput
  },

  computed: {
    ...mapGetters('MenuItemsSettings', [
      'avaibleStoreGroupList',
      'newMenuItemList',
      'oldMenuItemList',
      'newListPagination',
      'oldListPagination',
      'pageHeaderLinks'
    ]),

    noData () {
      return this.checkEmpty(this.newIntegrationInfo) || this.checkEmpty(this.oldIntegrationInfo)
    },

    isDisabledMatchedButton () {
      return this.noData || this.selectedMenuItems.length === 0 || !this.selectedNewItem || this.isMatchingItems
    },

    emptyTitle () {
      if (!this.selectedStoreGroupId) {
        return this.$t('MenuItemsSettings.empty_state_title_unselected_store_group')
      } else {
        return this.$t('MenuItemsSettings.empty_state_title_unselected_no_matching_data')
      }
    },

    emptyMessage () {
      if (!this.selectedStoreGroupId) {
        return this.$t('MenuItemsSettings.empty_message_no_store_group')
      } else {
        if (this.checkEmpty(this.newIntegrationInfo)) {
          return this.$t('MenuItemsSettings.empty_message_no_new_integration_data')
        } else if (this.checkEmpty(this.oldIntegrationInfo)) {
          return this.$t('MenuItemsSettings.empty_message_no_old_integration_data')
        }
      }
    },

    isFetchingOldMenuItems () {
      return this.$wait.is('fetchingOldMenuItemList')
    },

    isFetchingNewMenuItems () {
      return this.$wait.is('fetchingNewMenuItemList')
    },

    isMatchingItems () {
      return this.$wait.is('matchedMenuItemsForIntegration')
    }
  },

  methods: {
    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('MenuItemsSettings', [
      'fetchAvabileStoreGroups',
      'fetchStoreGroupIntegrationInfo',
      'matchedMenuItemsForIntegration',
      'fetchMenuItemList'
    ]),

    checkEmpty (obj) {
      const checkedKeys = [
        'menuItemGroups',
        'revenueCenters'
      ]

      const isEmpty = !obj || checkedKeys.reduce((isEmpty, key) => {
        return obj[key].length === 0
      }, false)
      return isEmpty
    },

    isOldType (type) {
      return type === 'old'
    },

    async getStoreGroupIntegrationInfo () {
      if (!this.selectedStoreGroupId) return
      const params = {
        storeGroupId: this.selectedStoreGroupId
      }
      const res = await this.fetchStoreGroupIntegrationInfo(params)
      if (res) {
        const { data } = res
        this.newIntegrationInfo = data.newIntegrationInfo
        this.oldIntegrationInfo = data.oldIntegrationInfo
        this.newIntegrationFilter.menuItemGroups = [...data.newIntegrationInfo.menuItemGroups]
        this.newIntegrationFilter.revenueCenters = [...data.newIntegrationInfo.revenueCenters]
        this.oldIntegrationFilter.menuItemGroups = [...data.oldIntegrationInfo.menuItemGroups]
        this.oldIntegrationFilter.revenueCenters = [...data.oldIntegrationInfo.revenueCenters]
        this.getMenuItemList()
        this.getMenuItemList('old')
      }
    },

    onChangeOldFilterData (filterName, event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.oldIntegrationFilter[filterName] = [...this.oldIntegrationInfo[filterName]]
        } else {
          this.oldIntegrationFilter[filterName].push(event.item)
        }
      } else {
        if (event.item.all) {
          this.oldIntegrationFilter[filterName] = []
        } else {
          this.oldIntegrationFilter[filterName].splice(this.oldIntegrationFilter[filterName].indexOf(event.item), 1)
        }
      }
    },

    onChangeNewFilterData (filterName, event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.newIntegrationFilter[filterName] = [...this.newIntegrationInfo[filterName]]
        } else {
          this.newIntegrationFilter[filterName].push(event.item)
        }
      } else {
        if (event.item.all) {
          this.newIntegrationFilter[filterName] = []
        } else {
          this.newIntegrationFilter[filterName].splice(this.newIntegrationFilter[filterName].indexOf(event.item), 1)
        }
      }
    },

    onChangeFilter (type, filterName, event) {
      if (this.isOldType(type)) {
        this.onChangeOldFilterData(filterName, event)
      } else {
        this.onChangeNewFilterData(filterName, event)
      }
    },

    async getMenuItemList (type = 'new', page = 1, pageSize) {
      // type => New Integration Or Old Integration
      const loadingName = this.isOldType(type) ? 'fetchingOldMenuItemList' : 'fetchingNewMenuItemList'
      this.$wait.start(loadingName)
      const integration = this.isOldType(type) ? this.oldIntegrationInfo : this.newIntegrationInfo
      const filter = this.isOldType(type) ? this.oldIntegrationFilter : this.newIntegrationFilter
      const searchText = this.isOldType(type) ? this.oldFieldSearch : this.newFieldSearch

      let pageSizeType = pageSize
      if (!pageSizeType) pageSizeType = this.isOldType(type) ? this.oldListPagination.size : this.newListPagination.size

      const payload = {
        storeGroupId: this.selectedStoreGroupId,
        integrationProductId: integration.integrationProductId,
        menuItemGroups: filter.menuItemGroups.map(_ => _.id),
        revenueCenters: filter.revenueCenters.map(_ => _.id),
        searchText,
        pageNumber: page,
        pageSize: pageSizeType
      }

      await this.fetchMenuItemList({
        type,
        payload
      })
      this.$wait.end(loadingName)
    },

    onSelectMenuItem (item) {
      const index = this.selectedMenuItems.indexOf(item)
      if (index > -1) {
        this.selectedMenuItems.splice(index, 1)
      } else {
        this.selectedMenuItems.push(item)
      }
    },

    changePageSize (type, pageSize) {
      this.getMenuItemList(type, 1, pageSize)
    },

    changePage (type = 'new', page) {
      this.getMenuItemList(type, page)
    },

    selectNewItem (row) {
      this.selectedNewItem = { ...row }
    },

    async matchingSelectedItems () {
      const payload = {
        sourceMenuItems: this.selectedMenuItems.map(_ => _.id),
        destinationMenuItemId: this.selectedNewItem.id
      }
      const res = await this.matchedMenuItemsForIntegration(payload)
      if (res) {
        const message = this.$t('MenuItemsSettings.success_matched_message')
        this.notifyShow({ message })
        this.getStoreGroupIntegrationInfo()
        this.selectedNewItem = null
        this.selectedMenuItems = []
      }
    }
  },

  mounted () {
    this.fetchAvabileStoreGroups()
  }
}
</script>

<style lang="scss" scoped>

.storeGroupField .dropdown-select {
  .icon-down-arrow {
    color: $color-success;
  }
}

.MenuItemsSettingsContent {
  margin-top: 26px;

  .settingsContent {
    display: grid;
    grid-template-columns: 1fr 67px 1fr;
    .integration {
      &-transition {
        color: $color-success;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        height: 48px;
        .icon {
          width: 22px;
        }
      }
      &-name {
        padding: 15px;
        background: $color-ocean;
        border-radius: $border-radius;
        font-size: $font-size-small;
        margin-bottom: 16px;
        .name {
          color: $color-text-opacity;
          font-weight: normal;
        }
        .value {
          font-weight: 500;
          margin-left: 2px;
        }
      }
    }
  }
}

</style>
