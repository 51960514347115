var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.requestHeaderLinks,
              translationGroup: "Request",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "requestLists",
              suggestionValue: "name",
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.selectedStockItem,
              customSuggestionPlaceHolder: _vm.$t("Request.search_Placeholder"),
              customSuggestionTitle: _vm.$t(
                "StockReports.search_SuggestionTitle"
              ),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.setStockItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c("template", { slot: "header-right-dropdown" }, [
            _c(
              "div",
              {
                staticClass: "form-item-select date-start m-right-10 m-left-10",
              },
              [
                _c("CustomDatepicker", {
                  attrs: {
                    id: "RequestDatepicker",
                    isLoading: _vm.$wait.is("getDateFilterSettings"),
                    range: true,
                    disabled: _vm.isFetchingList || _vm.isPageLoading,
                  },
                  model: {
                    value: _vm.dates,
                    callback: function ($$v) {
                      _vm.dates = $$v
                    },
                    expression: "dates",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              _vm.checkPermission("RequestOrder") ||
              _vm.checkPermission("RequestTransfer")
                ? _c(
                    "Button",
                    {
                      attrs: {
                        primary: "",
                        size: "medium",
                        id: "btn-request-header-ass",
                        type: "button",
                        iconName: "icon-global-add",
                        iconClass: "icon-global-add",
                      },
                      on: { click: _vm.newHeader },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("Request.add_Request"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("businessMultiselect", {
            ref: "requestsStoreFilter",
            attrs: {
              componentName: "requests",
              label: _vm.$t("Request.toolbar_Stores_Label"),
              popupHeader: _vm.$t("Request.toolbar_Stores_PopupHeader"),
              multiselectItems: _vm.storeList,
            },
            on: { submitFilter: _vm.getFilteredRequests },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "show-drafts m-left-15" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-requests-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                { staticClass: "RequestsContent" },
                [
                  _vm.isFetchingList || _vm.isPageLoading
                    ? _c("TableLoading")
                    : _vm._e(),
                  _vm.isRequestListEmpy &&
                  !_vm.isFetchingList &&
                  !_vm.isPageLoading
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t("Request.emptyState_Title"),
                          description: _vm.$t("Request.emptyState_Description"),
                          buttonText: _vm.$t("Request.add_Request"),
                        },
                        on: { emptyAddButton: _vm.newHeader },
                      })
                    : _vm._e(),
                  !_vm.isRequestListEmpy &&
                  !_vm.isFetchingList &&
                  !_vm.isPageLoading
                    ? _c(
                        "div",
                        [
                          _c("NestedTable", {
                            attrs: {
                              fields: _vm.requestHeaderFields,
                              detailFields: _vm.requestDetailFields,
                              data: _vm.requestList,
                              isDetailLoading: _vm.isDetailLoading,
                              actions: ["delete", "edit", "export"],
                              detailActions: ["edit", "delete"],
                              addButtonText: _vm.$t(
                                "Request.detail_NewStockItemButton"
                              ),
                              finalizeButtonText: _vm.$t(
                                "Request.detail_SaveButton"
                              ),
                              isLoadingFinalize: _vm.$wait.is("finalize"),
                              disableNewDetailButton: _vm.isOnlyListUsage,
                              extraButtonTexts: [
                                `${_vm.$t("Request.detail_ConvertTransfer")}`,
                                `${_vm.$t("Request.detail_ConvertOrders")}`,
                              ],
                            },
                            on: {
                              editHeaderAction: _vm.editItemAction,
                              deleteHeaderAction: _vm.removeItemAction,
                              finalizeAction: _vm.requestSaved,
                              addDetailAction: _vm.requestDetailNew,
                              showDetailAction: _vm.showRequestDetail,
                              deleteDetailAction: _vm.requestDetailRemove,
                              detailChangeAction: _vm.updateDetailItems,
                              showExportPopup: _vm.showExportPopup,
                              editDetailAction: _vm.requestDetailEdit,
                            },
                          }),
                          _c("portal", {
                            attrs: { to: "reasons" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (header) {
                                    return _c(
                                      "div",
                                      { staticClass: "flexbox" },
                                      [
                                        header.row.description
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "reason-container m-left-10",
                                              },
                                              [
                                                _c(
                                                  "Button",
                                                  {
                                                    staticClass:
                                                      "show-MiniPopup relative width-max-content",
                                                    attrs: {
                                                      size: "small",
                                                      variant: "info",
                                                      id: `request-description-status-header-info-button-${header.rowIndex}`,
                                                      iconName:
                                                        "icon-global-info",
                                                      justIcon: true,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "MiniPopup-right-primary m-top-15 c-dark",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              header.row
                                                                .description
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        header.row.rejectionReason
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "reason-container m-left-10",
                                              },
                                              [
                                                _c(
                                                  "Button",
                                                  {
                                                    staticClass:
                                                      "show-MiniPopup relative width-max-content rejection-info",
                                                    attrs: {
                                                      size: "small",
                                                      variant: "info",
                                                      id: `request-rejection-status-header-info-button-${header.rowIndex}`,
                                                      iconName:
                                                        "icon-rejection-status",
                                                      justIcon: true,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "MiniPopup-right-primary m-top-15 c-dark",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              header.row
                                                                .rejectionReason
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              1014579356
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "wmsStatus" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (header) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        header.row.wmsStatus === 3
                                          ? _c("Button", {
                                              staticClass:
                                                "wms-warning icon-global-warning",
                                              attrs: {
                                                id: `request-header-resend-button-${header.rowIndex}`,
                                                iconName: "icon-global-warning",
                                                justIcon: true,
                                                size: "medium",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.toggleErrorPopup(
                                                    header.row
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        header.row.showAccountingStatus
                                          ? _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "shortkey",
                                                    rawName: "v-shortkey",
                                                    value: ["esc"],
                                                    expression: "['esc']",
                                                  },
                                                  {
                                                    name: "click-outside",
                                                    rawName: "v-click-outside",
                                                    value: () =>
                                                      _vm.closeErrorPopup(
                                                        header.row
                                                      ),
                                                    expression:
                                                      "() => closeErrorPopup(header.row)",
                                                  },
                                                ],
                                                staticClass: "MiniPopup right",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "resendButton-Popup",
                                                  },
                                                  [
                                                    header.row.wmsStatus === 3
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "resendButton-Popup-content",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  header.row
                                                                    .wmsMessage
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "Button",
                                                              {
                                                                staticClass:
                                                                  "m-left-10",
                                                                attrs: {
                                                                  id: "request-header-resend-data-wms",
                                                                  iconName:
                                                                    "icon-global-refresh",
                                                                  iconClass:
                                                                    "icon-global-refresh",
                                                                  secondary: "",
                                                                  size: "small",
                                                                  disabled:
                                                                    _vm.isLoadingWMSReset,
                                                                  isLoading:
                                                                    _vm.isLoadingWMSReset,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.resendRequestForWMS(
                                                                        header.row
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Orders.Resend_Button_Text"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3500139752
                            ),
                          }),
                          _c("portal", {
                            attrs: { to: "description" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (detail) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        detail.row.description
                                          ? _c(
                                              "Button",
                                              {
                                                staticClass:
                                                  "show-MiniPopup relative width-max-content",
                                                attrs: {
                                                  size: "small",
                                                  variant: "info",
                                                  id: `transfer-approval-header-info-button-${detail.rowIndex}`,
                                                  iconName: "icon-global-info",
                                                  justIcon: true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "MiniPopup-leftCenter-primary m-top-15 c-dark",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          detail.row.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              1302879571
                            ),
                          }),
                          !_vm.isRequestListEmpy &&
                          !_vm.isFetchingList &&
                          !_vm.isPageLoading
                            ? _c("TableFoot", {
                                attrs: { page: _vm.Page },
                                on: {
                                  delegateOnChangePage: _vm.changePage,
                                  delegateOnChangePageSize: _vm.changePageSize,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
          _c("router-view", {
            on: {
              getRequestList: _vm.getRequestList,
              showDrafts: function ($event) {
                _vm.showDrafts = true
              },
            },
          }),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }