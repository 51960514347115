<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "PageLinks"
      translationGroup="TabItems"
    )

  template(slot="header-left-search" v-if="hasTabPermission")
    Search.search-box(
        componentName="approveOperations",
        suggestionValue="name"
        :isSuggestionLoading="isSuggestionLoading"
        :suggestions="selectedStockItem"
        :customSuggestionPlaceHolder="$t('Request.search_Placeholder')"
        :customSuggestionTitle="$t('StockReports.search_SuggestionTitle')"
        @onChangeSearchValue="onChangeSearch"
        @onChangeQueryItem="setStockItem"
        @clearSearch="clearSearch"
      )
  template(slot="toolbar-left")
    storeFilterComponent(
      v-if="isTransferPage || isApproveTransferPage"
      :label="$t('ApproveOperations.fromStore')"
      @submitFilter="getListWithFromStoreFilter"
      ref="transferFromStoreFilter")

    storeFilterComponent(
      :label="isTransferPage || isApproveTransferPage ? $t('ApproveOperations.toStore') : this.$t('Global.toolbar_Store_Label')"
      @submitFilter="getListWithToStoreFilter"
      :isDataAvailable="true"
      ref="transferToStoreFilter")

    Button.clear-filter-button.border-gray-1.p-0.width-auto(@click='clearFilter')
      Icon.icon-clear-filter(name="icon-clear-filter")
  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    .TransferOperations(v-else)
      // LOADING
      TableLoading(v-if="isFetchingData || isPageLoading")

      // EMPTY PAGE
      EmptyState(
        v-if="!isFetchingData && isFetchDataEmpty"
        :title="$t('ApproveOperations.headerList_emptyState_NotFound_Title')"
        :description="$t('ApproveOperations.detailList_emptyState_NotFound_Description_Order')")

      div(v-if="!isFetchingData && !isFetchDataEmpty")
        NestedTable(
          :fields="approvalOrderHeaderFields"
          :detailFields="approvalOrderDetailFields",
          @showDetailAction="showDetail",
          :data="TransferApprovalList",
          :usePortalFooter="isApproveOrderPage || isApproveTransferPage"
          :class="{'transfer-table' : isTransferPage , 'approval-order-table' : isApproveOrderPage, 'order-table' : isOrderPage, 'approval-transfer-table' : isApproveTransferPage}"
          :isDetailLoading="isFetchingDataDetail")

        portal(to="footerPortal")
          .approval-buttons.flex(slot-scope="rowData")
            Button(
              type="button"
              size="small"
              primary,
              :disabled="userApprovalLevel !== rowData.authorizedUserLevel"
              @click="approveSelectedOrder(rowData)"
            )
              span {{$t('ApproveOperations.Button_Approve')}}

            miniPopupButton.m-left-5(
              :data="rowData"
              :buttonText="$t('Transfer.Button_Reject')"
              :descText="$t('Transfer.Reason_Description_Input_Label')"
              :isButtonDisable="userApprovalLevel !== rowData.authorizedUserLevel"
              :agreeButtonText="$t('Transfer.Detail_Col_Button_Agree')"
              @onSubmit="rejectSelectedOrder"
            )

        portal(to="maxApprovalLevel")
          .approval-levels(slot-scope="header")
            ApprovalLevels(
              v-if="header.row.approvalStatus > 0"
              :maxApprovalLevel="header.row.maxApprovalLevel",
              :currentApprovalLevel="header.row.currentApprovalLevel",
              :approvalStatus="header.row.approvalStatus"
              :historyList="historyListForOrders"
              :isLoading="isHistoryLoading"
              @showHistoryPopup="showHistoryPopup(header.row)"
            )

        portal(to="headerCheckbox" v-if="!isApproveOrderPage && !isApproveTransferPage")
          div(slot-scope="header")
            CustomCheckbox(
              :label="''"
              :checked="isCheckedHeader(header.row)"
              @change="toggleHeader(header.row)")

        portal(to="description")
          div(slot-scope="header")
            Button.show-MiniPopup.relative.width-max-content(
              v-if="header.row.description",
              :id="`transfer-approval-header-info-button-${header.rowIndex}`"
              iconName="icon-global-info",
              :justIcon="true",
              class="btn-info"
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ header.row.description }}
        
        portal(to="info")
          div(slot-scope="header")
            Button.show-MiniPopup.relative.width-max-content(
              v-if="header.row.info",
              :id="`transfer-approval-header-info2-button-${header.rowIndex}`"
              iconName="icon-global-info",
              :justIcon="true",
              class="btn-info"
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ header.row.info }}

        portal(to="description")
          div(slot-scope="detail")
            Button.show-MiniPopup.relative.width-max-content(
              v-if="detail.row.description",
              :id="`transfer-approval-header-info-button-${detail.rowIndex}`"
              iconName="icon-global-info",
              :justIcon="true",
              class="btn-info"
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ detail.row.description }}
      .approve-buttons(v-if="!isFetchingData && !isFetchDataEmpty && !isApproveOrderPage && !isApproveTransferPage")
        .popup-container(
          v-click-outside="hideReasonPopup"
        )
          Button(
            type="button"
            size="medium"
            danger
            :disabled="selectedHeaders.length === 0"
            id="transfer-approval-description-reason-show"
            @click="showReasonPopup") {{ $t('ApproveOperations.Button_Reject') }}
          form.transfer-approval-description-right-MiniPopup(
            v-if="showReasonButtonPopup"
            id="transfer-approval-description-reason-form"
            @submit.prevent ="rejectSelectedHeaders"
          )
            label.transfer-approval-description-MiniPopup-label.required(
              id="transfer-approval-description-reason-label"
            ) {{ $t('ApproveOperations.Reason_Description_Input_Label') }}
            textarea.txt.transfer-approval-description-MiniPopup-input(
              ref="reason"
              name="reason"
              autocomplete="off"
              v-validate="{required: true, max: 512}"
              v-model="reasonDescription"
              :class="{ 'is-danger': veeErrors.has(`reason`) }"
              id="transfer-approval-description-reason-input"
            )
            Button.m-top-15(
              type="submit"
              primary
              size="medium"
              id="transfer-approval-description-reason-submit"
              :disabled="selectedHeaders.length === 0 || isLoading"
            )
              span {{$t('ApproveOperations.Detail_Col_Button_Agree')}}
              span(v-show="!isLoading")
                Loading(theme="disable", v-show="isLoading")
        .popup-container.m-left-10
          Button(
            :disabled="selectedHeaders.length === 0 || isLoading"
            type="button"
            primary
            size="medium"
            id="transfer-approval-description-reason-show"
            @click="approveSelectedHeaders") {{ $t('ApproveOperations.Button_Approve') }}
              span(v-show="!isLoading")
                Loading(theme="disable", v-show="isLoading")
      TableFoot(
        v-if="isApproveOrderPage || isApproveTransferPage"
        :page="TransferPage"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
      )
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

//import { Fields.ApprovalHeaderFields, Fields.ApprovalDetailFields } from './fields/index'
import * as Fields from './fields/index'
import storeFilterComponent from '@/view/global/business-filters/store'
import NoPermission from '@/view/global/has-not-permission'
import miniPopupButton from '@/view/global/mini-popup-button'
import hasTabPermission from '@/mixins/hasTabPermission'
import ApprovalLevels from '@/view/global/approval-levels'
import TableFoot from '@/view/global/table/foot'
export default {
  name: 'approveOperations',
  mixins: [hasTabPermission],
  data () {
    return {
      approvalOrderHeaderFields: [],
      approvalOrderDetailFields: [],
      fromStoreList: [],
      toStoreList: [],
      stockItemId: null,
      selectedHeaders: [],
      selectedStockItem: [],
      reasonDescription: null,
      stockItem: null,
      search: '',
      showReasonButtonPopup: false,
      routePage: null,
      historyListForOrders: []
    }
  },
  components: {
    storeFilterComponent,
    NoPermission,
    ApprovalLevels,
    TableFoot,
    miniPopupButton,
  },
  async mounted () {
    if (!this.hasTabPermission) return
    this.routePage = this.$route.name
    this.setFields()
    this.$wait.start('pageLoading')
    await this.getStores()
    await this.getFilteredList()
    this.$wait.end('pageLoading')
  },
  computed: {
    ...mapGetters('ApproveOperations', [
      'PageLinks',
      'isFetchDataEmpty',
      'TransferApprovalList',
      'isFetchDataEmpty',
      'TransferPage'
    ]),

    ...mapGetters('Settings', [
      'userApprovalLevel'
    ]),

    isFetchingData () {
      return this.$wait.is(['getAllApprovalOrder', 'getAllApprovalTransfer', 'getApprovalOrderList', 'getApprovalTransferList'])
    },

    isHistoryLoading () {
      return this.$wait.is(['showHistory'])
    },

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    isFetchingDataDetail () {
      return this.$wait.is(['getApproveRequestDetail'])
    },

    isSuggestionLoading () {
      return this.$wait.is(['Stock/searchStockItem'])
    },

    isSuggestionResultEmpty () {
      return this.selectedStockItem.length === 0
    },

    isTransferPage () {
      // Check if page is transfer page. Otherwise it is order page
      return this.routePage === 'ApproveOperationsTransfer'
    },

    isOrderPage () {
      return this.routePage === 'ApproveOperationsOrder'
    },

    isApproveOrderPage () {
      return this.routePage === 'ApproveOperationsApproveOrder'
    },

    isApproveTransferPage () {
      return this.routePage === 'ApproveOperationsApproveTransfer'
    },

    isLoading () {
      return this.$wait.is(['approveForm', 'rejectForm'])
    }
  },
  methods: {
    ...mapActions('ApproveOperations', [
      'getAllApprovalOrder',
      'getAllApprovalTransfer',
      'approveOrder',
      'approveTransfer',
      'rejectOrder',
      'rejectTransfer',
      'getApproveRequestDetail',
      'getApprovalOrderList',
      'getApprovalOrderDetail',
      'approveSelectedOrderHeader',
      'rejectSelectedOrderHeader',
      'getApprovalTransferList',
      'getApprovalTransferDetail',
      'rejectSelectedTransferHeader',
      'approveSelectedTransferHeader',
      'showHistory'
    ]),
    ...mapActions({
      getStores: 'Stores/getMiniStoreList'
    }),
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('Stock', [
      'searchStockItem'
    ]),
    ...mapMutations('ApproveOperations', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'SHOW_APPROVE_ORDER_DETAIL',
      'SHOW_APPROVE_TRANSFER_DETAIL'
    ]),
    setFields () {
      this.approvalOrderHeaderFields = Fields.ApprovalHeaderFields(this.$t, !this.isTransferPage, this.isApproveOrderPage, this.isApproveTransferPage)
      this.approvalOrderDetailFields = Fields.ApprovalDetailFields(this.$t, !this.isTransferPage, this.isApproveOrderPage, this.isApproveTransferPage)
    },
    changePage (page) {
      this.getFilteredList(page)
    },
    changePageSize (pageSize) {
      this.getFilteredList(1, pageSize)
    },
    async getFilteredList (pageNumber = 1, pageSize = this.TransferPage.size) {
      this.selectedHeaders = []
      let payload = {}
      if (this.isTransferPage) {
        payload = {
          fromStoreList: this.fromStoreList ? this.fromStoreList.map(item => item.id) : [],
          toStoreList: this.toStoreList ? this.toStoreList.map(item => item.id) : [],
          stockItemId: this.stockItem ? this.stockItem.id : null,
          PageSize: 999999
        }
        await this.getAllApprovalTransfer(payload)
      } else if (this.isOrderPage) {
        payload = {
          storeList: this.toStoreList ? this.toStoreList.map(item => item.id) : [],
          stockItemId: this.stockItem ? this.stockItem.id : null,
          PageSize: 999999
        }
        await this.getAllApprovalOrder(payload)
      } else if (this.isApproveOrderPage) {
        payload = {
          storeList: this.toStoreList ? this.toStoreList.map(item => item.id) : [],
          stockItemId: this.stockItem ? this.stockItem.id : null,
          pageNumber: pageNumber,
          pageSize: pageSize
        }
        await this.getApprovalOrderList(payload)
      } else {
        payload = {
          fromStoreList: this.fromStoreList ? this.fromStoreList.map(item => item.id) : [],
          storeList: this.toStoreList ? this.toStoreList.map(item => item.id) : [],
          stockItemId: this.stockItem ? this.stockItem.id : null,
          pageNumber: pageNumber,
          pageSize: pageSize
        }
        await this.getApprovalTransferList(payload)
      }
    },
    async showDetail (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
      } else if (this.isApproveOrderPage) {
        const payload = {
          orderHeaderId: item.id,
          headerType: item.headerType
        }
        await this.getApprovalOrderDetail(payload).then(res => {
          if (res) {
            this.SHOW_APPROVE_ORDER_DETAIL({ orderHeaderId: item.id, headerType: item.headerType, data: res.data })
          }
        })
      } else if (this.isApproveTransferPage) {
        const payload = {
          transferHeaderId: item.id
        }
        await this.getApprovalTransferDetail(payload).then(res => {
          if (res) {
            this.SHOW_APPROVE_TRANSFER_DETAIL({ transferHeaderId: item.id, data: res.data })
          }
        })
      } else {
        await this.getApproveRequestDetail(item.id).then(res => {
          if (res) {
            this.SHOW_DETAIL({ transferApprovalHeaderId: item.id, data: res.data })
          }
        })
      }
    },
    async getListWithToStoreFilter ({list, dontSubmit}) {
      this.toStoreList = list
      if (!dontSubmit) await this.getFilteredList()
    },
    async getListWithFromStoreFilter ({list, dontSubmit}) {
      this.fromStoreList = list
      if (!dontSubmit) await this.getFilteredList()
    },
    isCheckedHeader (header) {
      return this.selectedHeaders.some(item => item.id === header.id)
    },
    toggleHeader (header) {
      const isChecked = this.isCheckedHeader(header)
      const isSelected = this.selectedHeaders.some(h => h.id === header.id)
      if (!isChecked) {
        if (isSelected) return
        this.selectedHeaders.push(header)
      } else {
        const headerIndex = this.selectedHeaders.findIndex(h => h.id === header.id)
        this.selectedHeaders.splice(headerIndex, 1)
      }
    },

    approveSelectedHeaders: vueWaitLoader(async function () {
      const payload = { ids: this.selectedHeaders.map(i => i.id) }
      let res = null
      if (this.isTransferPage) res = await this.approveTransfer(payload)
      else if (this.isOrderPage) res = await this.approveOrder(payload)
      if (!res) return
      const message = this.$t('ApproveOperations.approval_Notification_message')
      this.notifyShow({ message })
      this.getFilteredList()
    }, 'approveForm'),

    async approveSelectedOrder (item) {
      let payload = []
      let res = null
      if (this.isApproveOrderPage) {
        payload.push({id: item.id, headerType: item.headerType})
        res = await this.approveSelectedOrderHeader(payload)
      } else if (this.isApproveTransferPage) {
        payload.push(item.id)
        res = await this.approveSelectedTransferHeader(payload)
      }
      if (!res) return
      const message = this.$t('ApproveOperations.approval_Notification_message')
      this.notifyShow({ message })
      this.getFilteredList()
    },

    async showHistoryPopup (header) {
      const payload = {
        headerId: header.id,
        approvalHistoryType: header.headerType
      }
      const res = await this.showHistory(payload)
      this.historyListForOrders = res.data.approvalHistoryList
      this.historyListForOrders.map(i => {
        i.isApproved = i.isApproved === true ? this.$t('Global.approved') : this.$t('Global.declined')
        i.isOutOfOffice = i.isOutOfOffice === true ? this.$t('Global.outOfOffice') : this.$t('Global.working')
      })
    },

    async rejectSelectedOrder (item) {
      let payload = {}
      let res = null
      if (this.isApproveOrderPage) {
        payload = {
          approveOrderHeaderList: [{id: item.id, headerType: item.headerType}],
          rejectionReason: item.reasonDescription
        }
        res = await this.rejectSelectedOrderHeader(payload)
      } else if (this.isApproveTransferPage) {
        payload = {
          transferHeaderIds: [item.id],
          rejectionReason: item.reasonDescription
        }
        res = await this.rejectSelectedTransferHeader(payload)
      }
      if (!res) return
      const message = this.$t('ApproveOperations.rejection_Notification_message')
      this.notifyShow({ message })
      this.getFilteredList()
    },

    rejectSelectedHeaders: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        let res = null
        const payload = {
          ids: this.selectedHeaders.map(i => i.id),
          rejectionReason: this.reasonDescription
        }
        if (this.isTransferPage) res = await this.rejectTransfer(payload)
        else if (!this.isTransferPage) res = await this.rejectOrder(payload)
        if (!res) return
        const message = this.$t('ApproveOperations.rejection_Notification_message')
        this.notifyShow({ message })
        this.reasonDescription = null
        this.hideReasonPopup()
        await this.getFilteredList()
      })
    }, 'rejectForm'),

    onChangeSearch (search) {
      if (!search || search.length < 2) {
        this.clearSearch()
      } else {
        this.searchStockItem({ Text: search, types: [1, 2] })
          .then(res => {
            this.selectedStockItem = res.data.items
          })
      }
    },

    setStockItem (item) {
      this.stockItem = item
      this.search = item.name
      this.selectedStockItem = []
      this.getFilteredList()
    },
    clearSearch () {
      this.search = ''
      this.stockItem = []
      this.selectedStockItem = []
      this.getFilteredList()
    },
    clearFilter () {
      if (this.isTransferPage || this.isApproveTransferPage) this.$refs.transferFromStoreFilter.clearStoreFilter()
      this.$refs.transferToStoreFilter.clearStoreFilter(false)
    },
    showReasonPopup () {
      this.showReasonButtonPopup = !this.showReasonButtonPopup
    },
    hideReasonPopup () {
      this.showReasonButtonPopup = false
    }
  },
  watch: {
    $route (to) {
      this.routePage = to.name
      this.$nextTick(() => {
        this.$refs.transferToStoreFilter.clearStoreFilter()
        if (this.isTransferPage) this.$refs.transferFromStoreFilter.clearStoreFilter()
        this.getFilteredList()
        this.setFields()
      })
    }
  }
}
</script>

<style scoped lang="scss">

  @import "~@/stylesheet/config/mixin";
  :deep() .order-table .NestedTable-head {
    grid-template-columns: 40px repeat(4, 1fr) 40px 40px !important;
  }
  :deep() .order-table .NestedTable-row {
    grid-template-columns: 40px repeat(4, 1fr) 40px 40px !important;
  }

  :deep() .transfer-table .NestedTable-head {
    grid-template-columns: 40px repeat(4, 1fr) 40px 40px !important;
  }
  :deep() .transfer-table .NestedTable-row {
    grid-template-columns: 40px repeat(4, 1fr) 40px 40px !important;
  }
  :deep() .approval-transfer-table .NestedTable-head {
    grid-template-columns: repeat(8, 1fr) 50px 50px !important;
  }
  :deep() .approval-transfer-table .NestedTable-row {
    grid-template-columns: repeat(8, 1fr) 50px 50px !important;
  }
  .approve-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    .btn {
      &.passive {
        @include GhostButton($color: $color-warning, $amount: 30);
      }
      &:disabled {
        border: 0;
      }
    }
    .transfer-approval-description-right-MiniPopup {
      top: auto!important;
      right: 100px!important;
      @include MiniPopupMixin(
        $width: 296px,
        $margin: 30px -10px 0 0,
        $triangle-direction: 'right',
        $border-color: $color-light-blue,
        $padding-hor: 25px,
      );
    }
  }

  .flex {
    display: flex;
  }
</style>
