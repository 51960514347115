var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "orders",
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.selectedStockItem,
              customSuggestionPlaceHolder: _vm.$t(
                "Orders.orders_search_Placeholder"
              ),
              customSuggestionTitle: _vm.$t(
                "Orders.orders_search_SuggestionTitle"
              ),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.setStockItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _vm.hasTabPermission
          ? _c("div", { staticClass: "right" }, [
              _c(
                "div",
                {
                  staticClass: "form-item-select date-start",
                  attrs: { id: "date-start-request-order-list" },
                },
                [
                  _c("CustomDatepicker", {
                    attrs: {
                      isLoading: _vm.$wait.is("getDateFilterSettings"),
                      id: "custom-datepicker-request-order-list",
                      range: true,
                    },
                    model: {
                      value: _vm.dates,
                      callback: function ($$v) {
                        _vm.dates = $$v
                      },
                      expression: "dates",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "toolbar-left" },
            [
              _c("storeFilter", {
                ref: "orderedStoreFilter",
                on: { submitFilter: _vm.getListWithStoreFilter },
              }),
              _c("supplierFilter", {
                ref: "orderedSupplierFilter",
                on: { submitFilter: _vm.getListWithSupplierFilter },
              }),
              _c(
                "Button",
                {
                  staticClass:
                    "clear-filter-button border-gray-1 p-0 width-auto m-right-10",
                  on: { click: _vm.clearFilter },
                },
                [
                  _c("Icon", {
                    staticClass: "icon-clear-filter",
                    attrs: { name: "icon-clear-filter" },
                  }),
                ],
                1
              ),
              _c("CustomCheckbox", {
                attrs: {
                  label: _vm.$t("Orders.showClosedOrdered"),
                  checked: _vm.showClosedOrdered,
                },
                on: { change: _vm.toggleShowClosedOrdered },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "toolbar-right" },
            [
              _c("ImportExport", {
                ref: "wrapper",
                staticClass: "m-right-15",
                attrs: {
                  componentName: "Despatch-Ordered",
                  isDisabled: _vm.isFetchingPageData,
                  activeImportExports: ["export"],
                  downloadUrl: "Order/orderdetail/export",
                  downloadFileType: "xlsx",
                  downloadParams: _vm.downloadParams,
                  downloadFileName: _vm.downloadFileName,
                },
              }),
              _c(
                "Button",
                {
                  staticClass: "m-right-15",
                  attrs: {
                    secondary: "",
                    size: "small",
                    id: "btn-orders-transform-despatch",
                    disabled: _vm.selectedClosedHeaders.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return (() => (_vm.showReasonPopUp = true)).apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "font-weight-w-normal font-size-small" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.showClosedOrdered
                            ? _vm.$t("Orders.openedOrdered")
                            : _vm.$t("Orders.closedOrdered")
                        )
                      ),
                    ]
                  ),
                  _vm.showReasonPopUp
                    ? _c(
                        "form",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: () => {
                                ;(_vm.showReasonPopUp = false),
                                  (_vm.stateReason = "")
                              },
                              expression:
                                "() => {showReasonPopUp = false, stateReason = ''}",
                            },
                          ],
                          staticClass: "StateReason-center-MiniPopup",
                          attrs: { id: "state-ordered-reason" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.updateStateOrder.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "StateReason-center-MiniPopup-label required",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Orders.popupAdd_FormField_Dissmis_Reason_Label"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.stateReason,
                                expression: "stateReason",
                                modifiers: { trim: true },
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:128",
                                expression: "'required|max:128'",
                              },
                            ],
                            staticClass:
                              "txt StateReason-center-MiniPopup-textarea",
                            class: { "is-danger": _vm.veeErrors.has("reason") },
                            attrs: { name: "reason", autocomplete: "off" },
                            domProps: { value: _vm.stateReason },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.stateReason = $event.target.value.trim()
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c(
                            "Button",
                            {
                              staticClass: "m-top-10",
                              attrs: {
                                primary: "",
                                size: "medium",
                                form: `state-ordered-reason`,
                                type: "submit",
                                disabled:
                                  !_vm.stateReason || _vm.isLoadingFinalize,
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("Orders.Detail_Col_Button_Agree")
                                  )
                                ),
                              ]),
                              _c("Loading", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isLoadingFinalize,
                                    expression: "isLoadingFinalize",
                                  },
                                ],
                                attrs: { theme: "disable" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "small",
                    id: "btn-orders-transform-despatch",
                    disabled:
                      _vm.selectedHeaders.length === 0 || _vm.isLoadingFinalize,
                  },
                  on: { click: _vm.transfromDespatch },
                },
                [
                  _c(
                    "span",
                    { staticClass: "font-weight-w-normal font-size-small" },
                    [_vm._v(_vm._s(_vm.$t("Orders.transformToDespatch")))]
                  ),
                  _c("Loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoadingFinalize,
                        expression: "isLoadingFinalize",
                      },
                    ],
                    attrs: { theme: "disable" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : [
                _vm.isFetchingPageData || _vm.isFetchingOrderList
                  ? _c("TableLoading", { staticClass: "sticky" })
                  : _vm._e(),
                _vm.isEmptyApproveOrderList &&
                !_vm.isFetchingPageData &&
                !_vm.isFetchingOrderList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t(
                          "Orders.orders_emptyState_NotFound_Title"
                        ),
                        description: _vm.$t(
                          "Orders.orders_emptyState_NotFound_Description"
                        ),
                      },
                    })
                  : _vm._e(),
                !_vm.isEmptyApproveOrderList
                  ? _c("NestedTable", {
                      ref: "ordersNestedTable",
                      attrs: {
                        "status-icon": false,
                        fields: _vm.ordersHeaderFields,
                        detailFields: _vm.ordersDetailFields,
                        actions: ["export"],
                        page: _vm.Page,
                        data: _vm.approveOrders,
                        addButtonText: _vm.$t("Global.addNewStock"),
                        canAddNewDetail: false,
                        isFinalizeAction: false,
                        isDetailLoading: _vm.$wait.is("getOrderDetail"),
                      },
                      on: {
                        changeRowData: _vm.changeOrderType,
                        addDetailAction: _vm.addNewDetail,
                        editHeaderAction: _vm.editAction,
                        editDetailAction: _vm.editDetailAction,
                        showExportPopup: _vm.showExportPopup,
                        delegateOnChangePage: _vm.changePage,
                        delegateOnChangePageSize: _vm.changePageSize,
                        showDetailAction: _vm.showOrderDetail,
                      },
                      model: {
                        value: _vm.selectedOrderDetails,
                        callback: function ($$v) {
                          _vm.selectedOrderDetails = $$v
                        },
                        expression: "selectedOrderDetails",
                      },
                    })
                  : _vm._e(),
                _c("portal", {
                  attrs: { to: "single-action-portal-start" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (header) {
                          return header.row.totalUnitQuantityList.length > 0
                            ? _c("TotalPreview", {
                                attrs: {
                                  rowIndex: header.rowIndex,
                                  list: header.row.totalUnitQuantityList,
                                },
                              })
                            : _vm._e()
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c(
                  "portal",
                  { attrs: { to: "checkboxAll" } },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        label: "",
                        checked: _vm.isSelectedAll,
                        disabled: !_vm.selectedSupplierId,
                      },
                      on: { change: _vm.toggleAll },
                    }),
                  ],
                  1
                ),
                _c("portal", {
                  attrs: { to: "headerCheckbox" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (header) {
                        return _c(
                          "div",
                          {},
                          [
                            _c("CustomCheckbox", {
                              attrs: {
                                label: "",
                                checked: _vm.isCheckedHeader(header.row),
                                disabled: _vm.makeDisableCheckbox(header.row.id)
                                  ? true
                                  : _vm.selectedSupplierId
                                  ? header.row.supplierId !==
                                    _vm.selectedSupplierId
                                  : false,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.toggleHeader(header.row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("portal", {
                  attrs: { to: "detailCheckbox" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (detail) {
                        return _c(
                          "div",
                          {},
                          [
                            _c("CustomCheckbox", {
                              attrs: {
                                label: "",
                                disabled: _vm.makeDisableCheckbox(
                                  detail.row.headerId
                                )
                                  ? true
                                  : _vm.selectedSupplierId
                                  ? detail.row.supplierId !==
                                    _vm.selectedSupplierId
                                  : false,
                                checked: _vm.isCheckedDetail(detail.row.id),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.toggleDetail(detail.row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("portal", {
                  attrs: { to: "orderDate" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (header) {
                        return _c(
                          "div",
                          {},
                          [
                            _c("CustomDatepicker", {
                              class: {
                                "is-danger": _vm.veeErrors.has(
                                  `date-${header.rowIndex}`
                                ),
                              },
                              attrs: {
                                name: `date-${header.rowIndex}`,
                                id: `date-input-table-row-${header.rowIndex}`,
                                value: header.row.orderDate,
                                disabledStartDate: _vm.disableDate,
                                pickerType: "manuel",
                                inputIconRight: "",
                                disabled: header.row.status === 2,
                                inputIcon: "",
                              },
                              on: {
                                change: (e) => _vm.changeOrderDate(header, e),
                              },
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("portal", {
                  attrs: { to: "description" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (header) {
                        return _c(
                          "div",
                          {},
                          [
                            header.row.description
                              ? _c(
                                  "Button",
                                  {
                                    staticClass:
                                      "show-MiniPopup relative width-max-content",
                                    attrs: {
                                      size: "medium",
                                      variant: "info",
                                      id: `production-header-info-button-${header.rowIndex}`,
                                      iconName: "icon-global-info",
                                      justIcon: true,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "MiniPopup-right-primary m-top-15 c-dark",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(header.row.description)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("portal", {
                  attrs: { to: "status" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (header) {
                        return _c(
                          "div",
                          {},
                          [
                            header.row.accountingStatus === 3
                              ? _c("StatusTooltip", {
                                  attrs: {
                                    title: header.row.accountingMessage,
                                    icon: "icon-row-status",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("portal", {
                  attrs: { to: "headerClosedCheckbox" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (header) {
                        return _c(
                          "div",
                          {},
                          [
                            _c("CustomCheckbox", {
                              attrs: {
                                label: "",
                                checked: _vm.isCheckedClosedHeader(header.row),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.toggleClosedHeader(header.row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("portal", {
                  attrs: { to: "detailDescription" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (detail) {
                        return _c(
                          "div",
                          {},
                          [
                            detail.row.description
                              ? _c(
                                  "Button",
                                  {
                                    staticClass:
                                      "show-MiniPopup relative width-max-content",
                                    attrs: {
                                      size: "medium",
                                      variant: "info",
                                      id: `ordered-despatch-detail-info-button-${detail.rowIndex}`,
                                      iconName: "icon-global-info",
                                      justIcon: true,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "MiniPopup-leftCenter-primary m-top-15 c-dark",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(detail.row.description)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
              ],
          _c("router-view", {
            on: {
              getOrdersHeaderAll: _vm.getOrdersHeaderAll,
              showOrderWithDetail: _vm.showOrderWithDetail,
            },
          }),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }