<template lang="pug">
Page
  template(slot="header-left-search")
    Search.search(
      componentName="Units",
      :isSuggestionLoading="false",
      :customSuggestionPlaceHolder="$t('Global.search_Placeholder')"
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-right-content")

    Button(
      size="medium",
      primary 
      id="btn-units-new"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="itemNew"
    )
      span {{ $t('Units.addUnitButton') }}

  template(slot="content-center")

    TableLoading(v-if="isUnitsLoading")
    // LIST
    TableView(
      v-if="!isUnitsEmpty && !isUnitsLoading"
      :fields="fields"
      :page="Page"
      :actions="['edit', 'remove']"
      :data="paginatedUnitList"
      componentName="UnitTable"
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize"
      
      @delegateOnItemEdit="editUnit"
      @delegateOnItemDelete="removeUnit"
    )

    EmptyState(
      v-if="isUnitsEmpty && !isUnitsLoading",
      :title="$t('Units.emptyState_Title')",
      :description="$t('Units.emptyState_Description')",
      :buttonText="$t('Units.emptyState_ButtonText')",
      @emptyAddButton="itemNew")


    router-view(@getList="getList")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import getFields from './fields/unit-fields'

export default {
  name: 'Units',

  data () {
    return {
      fields: getFields(this.$t),
      search: null
    }
  },

  computed: {
    ...mapGetters('Units', [
      'paginatedUnitList',
      'Page'
    ]),

    isUnitsEmpty () {
      return this.paginatedUnitList.length === 0
    },

    isUnitsLoading () {
      return this.$wait.is(['Units/getPaginatedUnitList'])
    },

    unitContainerClass () {
      return unit => ({
        'Unit bgc-gray': unit.type === 1,
        'Unit bgc-light-primary': unit.isRecipeUnit
      })
    },

  },

  mounted () {
    this.getUnits()
  },

  methods: {
    ...mapActions('Units', [
      'getPaginatedUnitList',
      'getUnitList'
    ]),

    itemNew () {
      this.$router.push({
        name: 'UnitNew'
      })
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) return
      this.getUnits()
    },

    async changePage (page) {
      this.getUnits(page)
    },

    changePageSize (pageSize) {
      this.getUnits(1, pageSize)
    },

    async getUnits (pageNumber = 1, pageSize = this.Page.size) {
      const payload = {
        name: this.search,
        pageSize: pageSize,
        pageNumber: pageNumber
      }
      await this.getPaginatedUnitList(payload)
    },

    async getList () {
      await this.getUnitList()
      await this.getUnits()
    },

    editUnit (item) {
      this.$router.push({
        name: 'UnitEdit',
        params: {
          id: item.id,
          isPageRefreshed: true
        }
      })
    },

    removeUnit (item) {
      this.$router.push({
        name: 'UnitRemove',
        params: {
          item,
          id: item.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

  :deep() .Unit {
    margin-left: -20px;
    height: 75px;
    width: 100%;
    position: absolute;
    top: 0;
  }
</style>
