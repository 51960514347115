<template lang="pug">
form.Auth-form.Tenant-form.Form(
  @submit.stop.prevent="submit")

    .auth-step
      .auth-step-item
        h5 1
        p {{ $t('Register.stepTitle') }}
      .auth-step-item.active
        h5 2
        p {{ $t('Tenant.stepTitle') }}

    h1.Auth-form-title {{ $t('Tenant.formHeaderTitle') }}

    .Form-item.name.required
      label.Form-item-label
        | {{ $t('Tenant.formFieldTenantName') }}
      .control
        input.txt.txt-right-icon(
        id="input-auth-tenant-name"
        name="name",
        autocomplete="off",
        :data-vv-as="$t('Tenant.formFieldTenantName')",
        v-model="form.name",
        v-validate="'required|min:2|max:128|verify_name'",
        :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
        :class="{ 'is-danger': veeErrors.has('name') }",
        type="text")
        //
        Button(
        id="btn-auth-tenant-loading"
        type="button",
        tabindex="-1",
        variant="icon loading"
        v-show="isPendingName")
          Loading(theme="disable")
        Button(
        id="btn-auth-tenant-check"
        type="button",
        variant="icon check"
        tabindex="-1",
        iconName="icon-check"
        iconClass="icon-check"
        v-show="isValidateName")

        //
      p.Form-item-help.is-danger(
      v-show="veeErrors.has('name')")
        | {{ veeErrors.first('name') }}

    .Form-item
      label.Form-item-label
        | {{ $t('Tenant.formFieldAddress') }}
      .control
        textarea.txt(
        id="input-auth-tenant-address"
        name="address",
        rows="2"
        :data-vv-as="$t('Tenant.formFieldAddress')",
        v-model="form.address",
        v-validate="'max:512'",
        :class="{ 'is-danger': veeErrors.has('address') }",
        autocomplete="address")
      p.Form-item-help.is-danger(
      v-show="veeErrors.has('address')")
        | {{ veeErrors.first('address') }}

    .Form-item
      label.Form-item-label
        | {{ $t('Tenant.formFieldPhone') }}
      .control
        input.txt(
        id="input-auth-tenant-tel"
        name="tel",
        v-validate="'max:50'",
        :data-vv-as="$t('Tenant.formFieldPhone')",
        v-model="form.tel",
        autocomplete="tel"
        )

      p.Form-item-help.is-danger(
        v-show="veeErrors.has('tel')")
          | {{ veeErrors.first('tel') }}

    .Form-item.required
      label.Form-item-label
        | {{ $t('Tenant.formFieldCurrency') }}
      .control.form-item-select
        Icon(name="icon-down-arrow")
        select.select(
        id="select-auth-tenant-currency"
        name="currency",
        v-model="form.currency"
        v-validate="'required'"
        :data-vv-as="$t('Tenant.formFieldCurrency')",
        :class="{ 'is-danger': veeErrors.has('currency') }")
          option(value="-1" id="option-tenant-currency-unselected")
            | {{ $t('Tenant.formFieldCurrencySelect') }}
          option(
          v-for="currency in CurrencyList"
          :key="currency.id"
          :value="currency.id"
          :id="`option-tenant-${currency.id}`"
          ) {{ currency.isoCode }}
      p.Form-item-help.is-danger(
      v-show="veeErrors.has('currency')"
      ) {{ veeErrors.first('currency') }}

    .Form-item.x2
      Button(
      id="btn-auth-tenant-submit"
      type="submit"
      primary
      size="medium"
      :class="isFormReady && !isLoading ? 'btn-success' : 'btn-gray'"
      :disabled="!isFormReady && isLoading")
        span(v-show="!isLoading")
          | {{ $t('Tenant.formFieldSubmit') }}
        Loading(theme="disable", v-show="isLoading")
    .Form-item.Auth-form-link.x2
      p
        | {{ $t('Tenant.linkText') }}
        |
        router-link(
        id="link-auth-tenant-login"
        :to="{  name: 'Login' }")
          | {{ $t('Tenant.linkUrl') }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields, Validator } from 'vee-validate'
import HTTP from '@/utils/http'

export default {
  name: 'Tenant',

  data () {
    return {
      form: {
        name: '',
        address: '',
        tel: '',
        currency: ''
      }
    }
  },

  mounted () {
    this.getCurrencyList()
  },

  computed: {
    ...mapFields({
      flagName: 'name',
      flagAddress: 'address',
      flagTel: 'tel',
      flagCurrency: 'currency'
    }),

    ...mapGetters('Global', [
      'CurrencyList',
      'currency'
    ]),

    newTenant () {
      return {
        name: this.form.name,
        address: this.form.address,
        phoneNumber: this.form.tel,
        baseCurrencyId: this.form.currency
      }
    },

    isPendingName () {
      return this.flagName.pending
    },

    isValidateName () {
      return this.flagName.valid && !this.isPendingName
    },

    isFormReady () {
      return !this.veeErrors.any() && this.flagName.valid
    },

    isLoading () {
      return this.$wait.is(['Auth/createTenant'])
    }
  },

  methods: {
    ...mapActions('Auth', [
      'createTenant'
    ]),

    ...mapActions('Global', [
      'getCurrencyList'
    ]),

    submit () {
      this.$validator.validateAll().then(result => {
        if (!result) return

        this.createTenant(this.newTenant).then(res => {
          this.$router.push({
            name: 'Dashboard'
          })
        })
      })
    }
  },

  created () {
    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Name: value
          }
          HTTP.get('Tenant/check/name', { params })
            .then(res => {
              resolve({
                valid: res.data.isAvailable
              })
            })
            .catch(e => {
              resolve({
                valid: false
              })
            })
        })
      }
    }

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => 'Bu organizasyon adı daha önce kullanılmış.'
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)
  }
}
</script>

<style lang="scss">
</style>
