<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="Settings"
    )
  template(slot="content-center")
    router-view

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Settings',
  computed: {
    ...mapGetters('Settings', [
      'checkPermission',
      'pageHeaderLinks'
    ])
  }
}
</script>

<style lang="scss">

  .TabMenu {
    display: flex;
    margin-bottom: 30px;

    &-link {
      padding: 10px 12px;
      margin-right: 10px;
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      line-height: 1;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-high-radius;
      user-select: none;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;
      }

      &.router-link-active {
        background: $color-success;
        color: #fff;
        border-color: transparent;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .Form-row {
    display: flex;

    & + & {
      margin-top: 20px;
    }
  }

  .Form-col {
    flex: 1;

    & + & {
      margin-left: 20px;
    }

    &.not-label {
      // label height + label bottom margin
      padding-top: ($font-size-normal * $font-line-height) + 5;
    }
  }
</style>
