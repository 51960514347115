var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-breadcrumb-left" }, [
        _c(
          "div",
          { staticClass: "Breadcrumb tooltip-relative" },
          [
            _c(
              "router-link",
              {
                staticClass: "c-light",
                attrs: {
                  id: "link-inventory-breadcrumb",
                  to: { name: "Inventories" },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Inventory.breadCrumb_Inventory")))]
            ),
            _c("span", [_vm._v(_vm._s(">"))]),
            _c(
              "a",
              {
                staticClass: "isSelected",
                attrs: { href: "", id: "link-inventory-breadcrumb-active" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.InventoryInfo.status === 1
                      ? _vm.getName(_vm.inventoryBreadCrumb)
                      : _vm.inventoryBreadCrumb
                  )
                ),
              ]
            ),
            _vm.inventoryBreadCrumb.length > 50 &&
            _vm.InventoryInfo.status === 1
              ? _c("Tooltip", {
                  attrs: {
                    white: "",
                    bottom: "",
                    text: _vm.inventoryBreadCrumb,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("span", { staticClass: "inventory-type c-light" }, [
          _vm._v(
            _vm._s(
              _vm.InventoryInfo.inventoryType == 1
                ? _vm.$t("Inventories.inventoryTypePeriodic")
                : _vm.$t("Inventories.inventoryTypeIntermediate")
            )
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: { componentName: "Inventory", isSuggestionLoading: false },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _vm.hasPermissionToCloseInventory
            ? _c(
                "Button",
                {
                  attrs: {
                    size: "medium",
                    primary: "",
                    type: "button",
                    id: "btn-inventory-close",
                  },
                  on: { click: _vm.close },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Inventory.closeInventoryButton"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          !_vm.isStoreTypeCostCenter
            ? _c(
                "Button",
                {
                  attrs: {
                    size: "medium",
                    secondary: "",
                    type: "button",
                    id: "btn-add-stock-item",
                  },
                  on: { click: _vm.addStockItem },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Inventory.addStockItemButton"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          !_vm.isOnlyListUsage
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink",
                  attrs: {
                    id: "toggle-group-0",
                    to: {
                      name: "Inventory",
                      params: {
                        id: _vm.$route.params.id,
                        status: _vm.$route.params.status,
                        storeName: _vm.$route.params.storeName,
                        inventoryDate: _vm.$route.params.inventoryDate,
                      },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_all")))]
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              staticClass: "tabLink",
              attrs: {
                id: "toggle-group-1",
                to: {
                  name: "Inventorylists",
                  params: {
                    id: _vm.$route.params.id,
                    status: _vm.$route.params.status,
                    storeName: _vm.$route.params.storeName,
                    inventoryDate: _vm.$route.params.inventoryDate,
                  },
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_list")))]
          ),
          !_vm.isOnlyListUsage
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink isSelected",
                  attrs: {
                    id: "toggle-group-2",
                    to: {
                      name: "InventoryRecipe",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_recipe")))]
              )
            : _vm._e(),
          !_vm.isOnlyListUsage
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink",
                  attrs: {
                    id: "toggle-group-3",
                    to: {
                      name: "InventoryShelf",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_shelf")))]
              )
            : _vm._e(),
          !_vm.isOnlyListUsage &&
          _vm.inventory_Header.expirationDateControlIsEnabled
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink",
                  attrs: {
                    id: "toggle-group-4",
                    to: {
                      name: "InventoryExpireDate",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_expire_date")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("ImportExport", {
            ref: "wrapper",
            attrs: {
              componentName: "InventoryRecipe",
              isDisabled: _vm.isLoading,
              activeImportExports: ["export", "template"],
              templateUrl: _vm.downloadExcelUrl,
              templateFileName: _vm.$t("Inventory.downloadTemplate"),
              templateDisabled: !_vm.InventoryInfo.status === 1,
              templateFileType: "xlsx",
              templateMethod: "GET",
              downloadUrl: _vm.downloadUrl,
              downloadDisabled: !(
                _vm.InventoryInfo.status === 1 && !_vm.isStoreTypeCostCenter
              ),
              downloadFileType: "xlsx",
              downloadParams: _vm.downloadParams,
              downloadFileName: _vm.inventoryBreadCrumb,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading && _vm.waitCountUpdate ? _c("TableLoading") : _vm._e(),
          !_vm.isEmpty && !_vm.isLoading
            ? [
                _vm.filteredItems.length > 0
                  ? _c("TableView", {
                      attrs: {
                        componentName: "inventoryRecipe",
                        fields: _vm.fields,
                        data: _vm.filteredItems,
                        tableName: _vm.$t("Stocks.tableName"),
                        "read-only": true,
                      },
                    })
                  : _vm._e(),
                _c("portal", {
                  attrs: { to: "quantity" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (row) {
                          return _c("span", {}, [
                            _c("input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    max: _vm.$getConst(
                                      "MAX_DIGITS_FOR_QUANTITY"
                                    ),
                                    greater_than: -1,
                                  },
                                  expression:
                                    "{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: -1}",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model.lazy",
                                  value: row.quantity,
                                  expression: "row.quantity",
                                  modifiers: { lazy: true },
                                },
                              ],
                              staticClass: "txt no-spinners",
                              class: {
                                "is-danger": _vm.veeErrors.has(
                                  `recipe${row.index}.quantity`
                                ),
                              },
                              attrs: {
                                name: "quantity",
                                "data-vv-scope": `recipe${row.index}`,
                                type: "number",
                                step: "any",
                                disabled: _vm.isStoreTypeCostCenter,
                              },
                              domProps: { value: row.quantity },
                              on: {
                                change: [
                                  function ($event) {
                                    return _vm.$set(
                                      row,
                                      "quantity",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.onChangeUnitCount(
                                      row,
                                      `recipe${row.index}`
                                    )
                                  },
                                ],
                              },
                            }),
                          ])
                        },
                      },
                    ],
                    null,
                    false,
                    2016025372
                  ),
                }),
              ]
            : _vm._e(),
          _c("router-view", {
            on: {
              getList: _vm.getInventory,
              successImportAction: _vm.getInventory,
            },
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }