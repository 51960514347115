var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "supplier" }, [
    _c("div", { staticClass: "supplier-top" }, [
      _c("div", { staticClass: "title" }, [
        _c("h4", { staticClass: "supplier-title" }, [
          _vm._v(_vm._s(_vm.$t("Dashboard.supplier_debt_info"))),
        ]),
      ]),
      _vm.Supplier.length > 6
        ? _c("div", { staticClass: "slide" }, [
            _c("span", {
              staticClass: "dot",
              class: { active: _vm.fromPage === 0 },
              on: {
                click: function ($event) {
                  return _vm.changeData(0, 6)
                },
              },
            }),
            _c("span", {
              staticClass: "dot",
              class: { active: _vm.fromPage === 6 },
              on: {
                click: function ($event) {
                  return _vm.changeData(6, 12)
                },
              },
            }),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "supplier-main" }, [
      _c("table", { staticClass: "debt-table", attrs: { id: `debt-table` } }, [
        _c(
          "thead",
          { staticClass: "debt-table-head", attrs: { id: `debt-table-head` } },
          [
            _c(
              "tr",
              {
                staticClass: "table-row",
                attrs: { id: `debt-table-head-row` },
              },
              [
                _c(
                  "th",
                  { staticClass: "table-col", attrs: { id: `col-company` } },
                  [_vm._v(_vm._s(_vm.$t("Dashboard.company")))]
                ),
                _c(
                  "th",
                  {
                    staticClass: "table-col",
                    attrs: { id: `col-title-purchase-amount` },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("Dashboard.despatchTitlePurchaseAmount"))
                    ),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "table-col",
                    attrs: { id: `col-title-payment-amount` },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("Dashboard.despatchTitlePaymentAmount"))
                    ),
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "table-col",
                    attrs: { id: `col-supplier-debt-amount` },
                  },
                  [_vm._v(_vm._s(_vm.$t("Dashboard.supplier_debt_amount")))]
                ),
              ]
            ),
          ]
        ),
        _c(
          "tbody",
          { staticClass: "debt-table-body" },
          _vm._l(_vm.selectedData, function (supp, index) {
            return _c(
              "tr",
              {
                staticClass: "table-row",
                attrs: { id: `debt-table-body-row` },
              },
              [
                _c(
                  "td",
                  {
                    staticClass: "table-col",
                    attrs: { id: `col-supplier-name-${index}` },
                  },
                  [_vm._v(_vm._s(supp.supplierName))]
                ),
                _c(
                  "td",
                  {
                    staticClass: "table-col",
                    attrs: { id: `col-purchase-amount-${index}` },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency2Digits")(supp.purchaseAmount)
                      )
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "table-col debt-total",
                    attrs: { id: `col-payment-amount-${index}` },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency2Digits")(supp.paymentAmount)
                      )
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "table-col debt-left",
                    attrs: { id: `col-debt-amount-${index}` },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("formatCurrency2Digits")(supp.debtAmount))
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _c("tfoot", { staticClass: "debt-table-footer" }, [
          _c(
            "tr",
            {
              staticClass: "table-row",
              attrs: { id: `debt-table-footer-row` },
            },
            [
              _c(
                "td",
                {
                  staticClass: "table-col",
                  attrs: { id: `col-on-hand-total-title` },
                },
                [_vm._v(_vm._s(_vm.$t("Dashboard.onHandTotalTitle")))]
              ),
              _c(
                "td",
                {
                  staticClass: "table-col debt recieved",
                  attrs: { id: `col-debt-purchase-amount` },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatCurrency2Digits")(
                        _vm.Despatch.purchaseAmount
                      )
                    )
                  ),
                ]
              ),
              _c(
                "td",
                {
                  staticClass: "table-col debt total",
                  attrs: { id: `col-debt-payment-amount` },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatCurrency2Digits")(
                        _vm.Despatch.paymentAmount
                      )
                    )
                  ),
                ]
              ),
              _c(
                "td",
                {
                  staticClass: "table-col debt left",
                  attrs: { id: `col-debt-left-amount` },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatCurrency2Digits")(_vm.Despatch.debtAmount)
                    )
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }