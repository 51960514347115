<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )

  template(slot="header-left-search")
    Search.search-box(
      componentName="onhand",
      suggestionValue="name"
      :showSuggestionType="true"
      :isSuggestionLoading="isSuggestionLoading"
      :suggestions="suggestions"
      :customSuggestionPlaceHolder="$t('OnHand.search_Placeholder')"
      :customSuggestionTitle="$t('OnHand.search_SuggestionTitle')"
      @onChangeSearchValue="onChangeSearch"
      @onChangeQueryItem="onChangeQueryItem"
      @clearSearch="clearSearch"
    )
  template(slot="toolbar-left" v-if="!isPageLoading")
    storeFilterComponent(
      :label="$t('ApproveOperations.fromStore')"
      @submitFilter="getListWithFromStoreFilter"
      ref="transferFromStoreFilter")
  template(slot="content-center")
    .StockOnHand
      .content
        EmptyState.batch-empty-state(
          v-if="!stockItemsWithExpDate.length"
          :title="$t('OnHand.emptyPage_BatchTitle')"
          :description="$t('OnHand.emptyPage_BatchDescription')")
        TableView(
          v-else
          :data="stockItemsWithExpDate"
          :fields="batchListFields"
          :readOnly="true"
        )
        portal(to="daysLeft")
          div(slot-scope="row")
            .days(:class="getClassForDaysLeft(row.daysLeft)") {{row.daysLeft}}
      

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import getBatchFields from './fields/batch-fields'
import storeFilterComponent from '@/view/global/business-filters/store.vue'

export default {
  name: 'StockItemsExpirationDate',

  components: {
    storeFilterComponent
  },

  data () {
    return {
      batchListFields: getBatchFields(this.$t, true),
      search: '',
      queryItem: null,
      storeFilter: []
    }
  },

  mounted () {
    this.$wait.start('pageLoading')
    this.getPageData()
    this.$wait.end('pageLoading')
  },

  computed: {

    ...mapGetters('OnHand', [
      'suggestions',
      'suggestionsIsEmpty',
      'stocks',
      'Page',
      'pageHeaderLinks',
      'stockItemsWithExpDate'
    ]),

    ...mapGetters('Stores', {
      storeList: 'miniStoreList',
    }),

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    isSuggestionLoading () {
      return this.$wait.is('search_Suggestion')
    }
  },

  methods: {

    ...mapMutations('OnHand', [
      'remove_SUGGESTIONS',
      'remove_STOCKS'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapActions({
      search_Suggestion: 'OnHand/search_Suggestion',
      get_STOCKS_ONHAND: 'OnHand/get_STOCKS_ONHAND',
      getStockItemsWithExpDate: 'OnHand/getStockItemsWithExpDate',
    }),

    ...mapActions('Stores', [
      'getMiniStoreList',
    ]),

    async getListWithFromStoreFilter ({list, dontSubmit}) {
      this.storeFilter = list
      if (!dontSubmit) await this.getPageData()
    },

    getClassForDaysLeft (remainingDay) {
      if (remainingDay < 31) return remainingDay < 1 ? 'days-expired' : 'days-close'
    },

    clearSearch () {
      this.search = ''
      this.queryItem = null
      this.remove_STOCKS()
      this.getPageData()
    },

    onChangeQueryItem (item) {
      this.queryItem = item
      this.search = item.name
      this.getPageData()
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search && this.search.length >= 2) {
        this.search_Suggestion(this.search)
      }
      if (!this.search) {
        this.clearSearch()
      }
    },
   
    getPageData () {
      const payload = {
        storeIds: this.storeFilter.map(i => i.id),
        stockItemIds: this.queryItem ? [this.queryItem?.id] : []
      }
      this.getStockItemsWithExpDate(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.Tableee-body .Tableee-col) {
    position: relative !important;
  }
  .days {
    background: #FF5D39;
    padding: 2px 6px;
    border-radius: 2.43507px;
    color: white;
    width: fit-content;
    position: absolute;
    right: 20%;
    top: 30%;
    &-expired {
      background: #FF5D39;
    }
    &-close {
      background: rgba(255, 119, 13, 0.9);
    }
  }
</style>
