<template lang="pug">
Page
  template(slot="header-breadcrumb-left")
    .Breadcrumb.tooltip-relative
      router-link.c-light(
      id="link-inventory-breadcrumb-spot-list"
      :to="{ name: 'Inventories' }")
        | {{ $t('Inventory.breadCrumb_Inventory') }}
      span {{ ">" }}
      a(href="" id="link-inventory-breadcrumb-active-spot-list").isSelected {{ inventoryBreadCrumb }}
      Tooltip(
        v-if="inventoryBreadCrumb.length > 50"
        white
        bottom
        :text="inventoryBreadCrumb"
      )
    span.inventory-type.c-light {{ InventoryInfo.inventoryType == 1 ? $t('Inventories.inventoryTypePeriodic') : $t('Inventories.inventoryTypeIntermediate') }}
  template(v-if="inventoryHeader && inventoryHeader?.status === 1" slot="header-left-search")
    Search.search-box(
      componentName="Inventory",
      :isSuggestionLoading="false",
      @onChangeSearchValue="onChangeSearch")
  template(slot="header-right-content")
    Button.m-right-15(
      v-if="hasPermissionToCloseInventory"
      size="medium"
      primary
      type="button"
      id="btn-inventory-close-spot-list"
      @click="close(inventoryHeader.id)")
        span {{ $t('Inventory.closeInventoryButton') }}
  
    Button(
      :disabled="isOnlyListUsage"
      size="medium"
      secondary
      type="button"
      id="btn-add-stock-item-inventory-spot-list"
      @click="createBatch"
    )
      span {{ $t('Inventory.addStockItemButton') }}


  template(slot="overgroups-left")
    router-link.tabLink#toggle-group-0(v-if="!isOnlyListUsage" :to="{name: 'InventorySpotList', params: { id: $route.params.id, status: $route.params.status, storeName: $route.params.storeName, inventoryDate: $route.params.inventoryDate }}") {{ $t('Inventory.view_mode_spotList') }}
    router-link.tabLink.isSelected#toggle-group-4(v-if="!isOnlyListUsage" :to="{ name: 'InventoryExpireDateSpotList', params: { id: $route.params.id }}") {{ $t('Inventory.view_mode_expire_date') }}
  
  template(slot="toolbar-left")
    CustomMultiselect.toolbar-filters__item(
      id="multiselect-lists-filter-inventory-spot-list-batch"
      :items="listsOptions"
      :selected="listFilter"
      :label="$t('Inventory.filterTitle_Inventory_List')"
      :unselectedText="$t('Global.toolbar_Filters_Unselected')"
      :popupHeader="$t('Inventory.filterTitle_Inventory_List')"
      :allLabel="$t('Global.toolbar_Filters_AllLabel')"
      :buttonText="$t('Global.toolbar_Filters_ButtonText')"
      buttonIconLeft="btn-success"
      @delegateCheckAll="onChangeFilter"
      @delegateCheckItem="onChangeFilter"
      @delegateSubmitItems="getBatchList"
    )

  template(slot="content-center")

    TableLoading(v-if="isFetchingList")

    NestedTable(
      v-if="!isFetchingList && InventoryBatchList.length && renderComponent"
      :data="InventoryBatchList",
      :fields="fields",
      :page="InventoryBatchPage"
      @delegateOnChangePage="changePage",
      @delegateOnChangePageSize="changePageSize",
      :detailFields="detailFields",
      @showDetailAction="showDetail"
    )

    portal(to="count")
      div(slot-scope="detail")
        input.txt.no-spinners.quantity(
          name="count"
          :data-vv-as="$t('Inventory.count')"
          :class="{ 'is-danger': veeErrors.has(`refund${detail.rowIndex}.count`) }"
          v-validate="{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}"
          type="number"
          step="any"
          v-model="detail.row.count"
          @blur="updateActualQuantiy($event, detail.row)"
        )


    EmptyState.emptyState(
      v-if="!InventoryBatchList.length"
      :title="$t('Inventories.batchList_headerList_emptyState_NotFound_Title')"
      :description="$t('Inventories.detailList_Batch_emptyState_NotFound_Description')")

    router-view(@getList="getBatchList")

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import TableFoot from '@/view/global/table/foot.vue'
import { getFields, getDetailFields } from './fields/batch'

export default {
  name: 'InventoryBatchList',

  components: {
    TableFoot
  },

  data () {
    return {
      fields: getFields(this.$t),
      detailFields: getDetailFields(this.$t),
      search: '',
      isOnlyListUsage: false,
      inventoryHeader: null,
      renderComponent: true,
      lists: [],
      listsOptions: [],
      listFilter: []
    }
  },
  computed: {
    ...mapGetters('Inventories', [
      'InventoryBatchPage',
      'InventoryBatchList'
    ]),

    ...mapGetters('Settings', ['permissions', 'checkPermission']),

    hasPermissionToCloseInventory () {
      return this.checkPermission('CloseInventory')
    },

    isFetchingData () {
      return this.$wait.is('listFetching')
    },
    
    isFetchingList () {
      return this.$wait.is('getInventoryBatchList')
    },

    inventoryBreadCrumb () {
      return this.inventoryHeader?.storeName + '-' + moment(this.inventoryHeader?.inventoryDate).format('DD/MM/YYYY')
    },

    InventoryInfo () {
      return {
        ...this.$route.params,
        status: parseInt(this.$route.params.status)
      }
    }
  },

  methods: {
    ...mapActions('Inventories', [
      'getInventoryBatchList',
      'updateInventoryBatchCount',
      'getSpotListHeaderInfo'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('Inventories', [   
      'SHOW_BATCH_DETAIL',
      'HIDE_BATCH_DETAIL'
    ]),

    async getHeaderInfo () {
      const res = await this.getSpotListHeaderInfo(this.InventoryInfo.id)
      if (!res) return
      this.listsOptions = await [...res.data.inventoryListHeaderList]
      this.listFilter = await [...this.listsOptions]
    },

    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) return
      this.getBatchList()
    },

    onChangeFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.listFilter = [...this.listsOptions]
        } else {
          this.listFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.listFilter = []
        } else {
          this.listFilter.splice(this.listFilter.indexOf(event.item), 1)
        }
      }
    },

    createBatch () {
      this.$router.push({
        name: 'InventorySpotListCreateBatch',
        params: {
          storeId: this.inventoryHeader.storeId
        }
      })
    },

    close () {
      const inventory = this.$route.params
      this.$router.push({
        name: 'InventoryClose',
        params: {
          id: inventory.id,
          status: inventory.status,
          storeName: inventory.storeName,
          inventoryDate: inventory.inventoryDate,
          inventoryClosingMethod: inventory.inventoryClosingMethod
        }
      })
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    async showDetail (item) {
      if (!item.showDetail) this.SHOW_BATCH_DETAIL(item)
      else this.HIDE_BATCH_DETAIL(item)
      this.forceRerender()
    },

    async updateActualQuantiy (event, item) {
      const actualQuantity = Number(event.target.value)
      if (item.count === actualQuantity) return
      
      const payload = {
        inventoryHeaderId: this.$route.params.id,
        batchId: item.batchId,
        actualQuantity: actualQuantity
      }
      await this.updateInventoryBatchCount(payload).then(res => {
        if (res) {
          const message = this.$t('Inventory.bacthCountSuccess_Notification_message')
          this.notifyShow({ message })
        }
      })
    },

    getBatchList (page, pageSize = this.InventoryBatchPage.size) {
      const payload = {
        inventoryHeaderId: this.$route.params.id,
        pageSize: pageSize,
        text: this.search,
        pageNumber: page || 1,
        inventoryListHeaderIds: [...this.listFilter.map(_ => _.id)]
      }
      this.getInventoryBatchList(payload).then(res => {
        if (res) {
          this.inventoryHeader = res.data.inventoryHeader
        }
      })
    },

    async changePage (page) {
      await this.getBatchList(page)
    },

    async changePageSize (pageSize) {
      await this.getBatchList(1, pageSize)
    }
  },

  async mounted () {
    await this.$wait.start('listFetching')
    await this.getHeaderInfo()
    await this.getBatchList()
    await this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    
    const lists = await this.getBatchList()
    await this.$wait.end('listFetching')
  }
}
</script>

<style lang="scss" scoped>

  .Search{
    position: relative;

    & .icon-search{
      width: 48px;
      color: $color-light;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    & .txt{
      padding-left: 40px;
      padding-right: 40px;
    }
  }

.listLoading {
  top: initial;
}
.Breadcrumb {
  display: flex;
  font-size: $font-size-small;

  span {
    margin-left: 10px;
    margin-right: 10px;
  }

  .isSelected {
    color: $color-primary;
    font-weight: $font-weight-bold;
    pointer-events: none;
  }
}
.inventory-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: $font-size-small;
}
.toolbar {
  .left {
    display: flex;
    .toolbar-filters__item {
      margin-left: 20px;
    }
    .tabLink {
      padding: 11px 27px;
      font-weight: $font-weight-normal;
      margin-right: 10px;
      font-family: $font-family;
      color: $color-light;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-radius: $border-radius;
      user-select: none;
      background: $color-white;
      &.isSelected {
        color: $color-primary;
        font-weight: $font-weight-bold;
        border-color: $color-primary;
      }
    }
  }
}

  :deep() .SubMenu{
    .right {
      display: flex;
      align-items: center;
    }
    .left {
      display: flex;
      align-items: flex-start;
    }
  }
  .quantity {
    text-align: right;
  }
  .emptyState {
    min-height: 600px;
    height: unset;
  }
  :deep(.header-right-content) {
    display: flex !important;
  }
  .tabLink {
    padding: 11px 27px;
    font-weight: $font-weight-normal;
    margin-right: 10px;
    font-family: $font-family;
    color: $color-light;
    cursor: pointer;
    border: 1px solid $color-gray;
    border-radius: $border-radius;
    user-select: none;
    background: $color-white;
    &.isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      border-color: $color-primary;
    }
  }

</style>
