export default function($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('Supplier.tableColumn_Name_Short')
      },
      get title() {
        return $t('Supplier.tableColumn_Name')
      }
    },
    {
      name: 'currencyIsoCode',
      is_visible: true,
      get short_title() {
        return $t('Supplier.tableColumn_Currency')
      },
      get title() {
        return $t('Supplier.tableColumn_Currency')
      }
    },
    {
      name: 'emailAddress',
      is_visible: true,
      get short_title() {
        return $t('Supplier.tableColumn_EmailAddress_Short')
      },
      get title() {
        return $t('Supplier.tableColumn_EmailAddress')
      }
    },
    {
      name: 'phoneNumber',
      is_visible: true,
      get short_title() {
        return $t('Supplier.tableColumn_PhoneNumber_Short')
      },
      get title() {
        return $t('Supplier.tableColumn_PhoneNumber')
      }
    },
    {
      name: 'contactName',
      is_visible: true,
      get short_title() {
        return $t('Supplier.tableColumn_ContactName_Short')
      },
      get title() {
        return $t('Supplier.tableColumn_ContactName')
      }
    },
    {
      name: 'isEInvoicePayer',
      is_visible: true,
      get short_title() {
        return $t('Supplier.tableColumn_Invoice')
      },
      get title() {
        return $t('Supplier.tableColumn_Invoice')
      }
    }
  ]
}
