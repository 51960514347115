var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search",
            attrs: { componentName: "Group", isSuggestionLoading: false },
            on: { onChangeSearchValue: _vm.onChangeSearch },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-left" },
        [
          _c(
            "div",
            { staticClass: "group-container" },
            [
              _c("OverGroupList", {
                attrs: {
                  data: _vm.overGroupList,
                  overGroup: _vm.selectedOverGroup,
                },
                on: {
                  changeSelect: function ($event) {
                    return _vm.changeOverGroup($event)
                  },
                },
              }),
            ],
            1
          ),
          _c("router-view", {
            on: {
              getOverGroupList: _vm.getAllOverGroupList,
              getMajorGroupList: _vm.getMajorGroupList,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-right" },
        [
          _vm.isLoading
            ? _c("TableLoading")
            : _c(
                "div",
                { staticClass: "major-container" },
                [
                  _c("MajorGroupList", {
                    attrs: {
                      data: _vm.filteredMajorGroupList,
                      overGroups: _vm.overGroupList,
                      overGroup: _vm.selectedOverGroup,
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }