<template lang="pug">
.container
  .row 
    p asdadasd
</template>
<script>
/* eslint-disable */
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
export default {
  async created() {
    const ls = JSON.parse(localStorage.getItem(window.VUE_APP_VUEX_NAME || process.env.VUE_APP_VUEX_NAME))

    const connection = new HubConnectionBuilder()
      .withUrl(`https://api-test-inventory.simprasuite.com/mainhub?token=${ls.Auth.Token.value}`, {
          headers: {"authorization": `Bearer ${ls.Auth.Token.value}`},
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Information)
      .build()

    await connection.start().catch(function(err) {
      return console.error(err.toString())
    })

    connection.on('GetInformation', (notification) => {
        console.log(notification);
    })
    //
  }
}
</script>
