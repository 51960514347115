var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Tableee-container" },
    [
      _c("div", { staticClass: "Despatches-details" }, [
        _c("div", { staticClass: "table-scroll" }, [
          _c("table", { attrs: { id: "despatches-table-detail" } }, [
            !_vm.despatch.detailIsEmpty
              ? _c(
                  "thead",
                  {
                    staticClass: "Despatches-details-head",
                    attrs: { id: "despatches-table-detail-header" },
                  },
                  [
                    _c(
                      "tr",
                      {
                        staticClass: "Despatches-details-head-row",
                        attrs: { id: "despatches-table-detail-row" },
                      },
                      [
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col stockItemName",
                            attrs: {
                              id: "despatches-table-detail-header-checkbox",
                            },
                          },
                          [
                            _c("CustomCheckbox", {
                              attrs: {
                                id: `checkbox-despatch-detail-select-all-${_vm.rowIndex}`,
                                label: "",
                                checked: _vm.isHeaderChecked(),
                              },
                              on: {
                                change: (isChecked) =>
                                  _vm.toggleHeader(isChecked),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col stockItemName u-textLeft",
                            attrs: {
                              id: "despatches-table-detail-header-stock-item-name",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_StockItemName"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col order-stockOnHand u-textLeft",
                            attrs: {
                              id: "despatches-table-detail-stockOnHand-quantity",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_StockOnHandQuantity"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col quantity u-textLeft",
                            attrs: {
                              id: "despatches-table-detail-header-quantity",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_Quantity"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col order-quantity u-textLeft",
                            attrs: {
                              id: "despatches-table-detail-header-order-quantity",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_OrderQuantity"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col unitCode u-textCenter",
                            attrs: {
                              id: "despatches-table-detail-header-unit-code",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_UnitCode"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col netUnitPrice u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-net-unit-price",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_NetUnitPrice"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col netAmount u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-net-amount",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_NetAmount"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col baseQuantity u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-base-quantity",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_BaseQuantity"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "Despatches-details-head-col baseUnit",
                            attrs: {
                              id: "despatches-table-detail-header-base-unit",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_BaseUnit"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col basePrice u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-base-price",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_BasePrice"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col discount1 u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-discount-1",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_Discount1"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col discount2 u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-discount-2",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_Discount2"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col discountedUnitPrice u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-discounted-unit-price",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_DiscountedUnitPrice"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col discountedBaseUnitPrice u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-discounted-base-unit-price",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_DiscountedBaseUnitPrice"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col taxRatio u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-tax-ratio",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_TaxRatio"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col discountedNetUnitPrice u-textRight",
                            attrs: {
                              id: "despatches-table-detail-header-discounted-net-unit-price",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_DiscountedNetUnitPrice"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col storeName",
                            attrs: {
                              id: "despatches-table-detail-header-tax-amount",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.$t("Despatches.detail_Tax_Amount"))
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col storeName",
                            attrs: {
                              id: "despatches-table-detail-header-gross-amount",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.$t("Despatches.detail_Gross_Amount"))
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "Despatches-details-head-col storeName",
                            attrs: {
                              id: "despatches-table-detail-header-store-name",
                            },
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_TableColumn_StoreName"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c("th", {
                          staticClass: "Despatches-details-head-col action",
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            !_vm.despatch.detailIsEmpty && _vm.renderComponent
              ? _c(
                  "tbody",
                  {
                    staticClass: "Despatches-details-body",
                    attrs: { id: "despatches-table-detail-body" },
                  },
                  _vm._l(
                    _vm.despatch.despatchDetailList,
                    function (detail, index) {
                      return _c(
                        "tr",
                        {
                          staticClass: "Despatches-details-body-row",
                          class: {
                            "focus-within-dotted-blue": detail.isFocused,
                          },
                          attrs: {
                            tabindex: "0",
                            "data-vv-scope": "detailundefined",
                          },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "Despatches-details-body-col",
                              attrs: {
                                id: `despatches-detail-checkbox-${_vm.rowIndex}-${index}`,
                              },
                            },
                            [
                              _c("CustomCheckbox", {
                                attrs: {
                                  id: `checkbox-despatch-header-select-detail-${_vm.rowIndex}-${index}`,
                                  label: "",
                                  checked: _vm.isDetailChecked(detail.id),
                                },
                                on: {
                                  change: (isChecked) =>
                                    _vm.toggleDetail(detail, isChecked),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col stockItemName",
                              attrs: {
                                id: `despatches-stock-item-${_vm.rowIndex}-name-${index}`,
                              },
                            },
                            [_vm._v(_vm._s(detail.stockItem.name))]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col order-stockOnHandQuantity ff-mono u-textRight",
                              attrs: {
                                id: `despatches-order-${_vm.rowIndex}-stockOnHandQuantity-${index}`,
                              },
                            },
                            [_vm._v(_vm._s(detail.stockOnHandQuantity || "-"))]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col relative",
                              attrs: {
                                id: `despatches-quantity-${_vm.rowIndex}-input-col-${index}`,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "control" },
                                [
                                  _c("customNumberInput", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value:
                                          _vm.checkPermission(
                                            "IgnoreQuantityDeviation"
                                          ) === false
                                            ? {
                                                min_value:
                                                  detail.minQuantityDeviationResult
                                                    ? detail.minQuantityDeviationResult
                                                    : -999999999999,
                                                max_value:
                                                  detail.maxQuantityDeviationResult
                                                    ? detail.maxQuantityDeviationResult
                                                    : 999999999999,
                                              }
                                            : {
                                                min_value: -999999999999,
                                                max_value: 99999999999,
                                              },
                                        expression:
                                          "checkPermission('IgnoreQuantityDeviation') === false ? {min_value: detail.minQuantityDeviationResult ? detail.minQuantityDeviationResult : -999999999999, max_value: detail.maxQuantityDeviationResult ? detail.maxQuantityDeviationResult : 999999999999} : {min_value: -999999999999, max_value: 99999999999}",
                                      },
                                    ],
                                    staticClass: "number-input",
                                    class: {
                                      "is-danger":
                                        detail.remainingOrderQuantity > 0 &&
                                        detail.remainingOrderQuantity >
                                          detail.quantity &&
                                        detail.showOrderPopup &&
                                        _vm.isCanEdit,
                                    },
                                    attrs: {
                                      id: `despatches-quantity-${_vm.rowIndex}-input-${index}`,
                                      name: "quantity",
                                      value: detail.quantity,
                                      "data-vv-as": _vm.$t(
                                        "Despatches.detail_TableColumn_Quantity"
                                      ),
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.mutateData(
                                          detail,
                                          "quantity",
                                          `detail${index}`
                                        )
                                      },
                                      input: _vm.checkQuantityValue,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.showNegativeQuantityValidation,
                                          expression:
                                            "showNegativeQuantityValidation",
                                        },
                                      ],
                                      staticClass: "unit-price-validation",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "Despatches.detail_TableColumn_Quantity_cannot_negative"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            detail.showQuantityValidation ||
                                            _vm.checkQuantityDeviationStatus(
                                              detail
                                            ) === false,
                                          expression:
                                            "detail.showQuantityValidation || checkQuantityDeviationStatus(detail) === false",
                                        },
                                      ],
                                      staticClass: "unit-price-validation",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "Despatches.deviationValidation"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("div", { staticClass: "m-left-5" }, [
                                        _vm._v(
                                          _vm._s(
                                            detail.minQuantityDeviationResult
                                          ) +
                                            " - " +
                                            _vm._s(
                                              detail.maxQuantityDeviationResult
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              detail.canIgnoreQuantity &&
                              detail.quantity !== null
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "order-status",
                                      attrs: {
                                        id: `despatches-order-status-${_vm.rowIndex}-input-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "order-status-content" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Despatches.popup_controlQuantityDeviation"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-status-content-close-order",
                                            },
                                            [
                                              _c(
                                                "Button",
                                                {
                                                  attrs: {
                                                    id: `radio-despatch-detail-${_vm.rowIndex}-order-is-complete2-${index}`,
                                                    type: "button",
                                                    size: "medium",
                                                    variant: "border-black",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.mutateData(
                                                        detail,
                                                        "isDeviationCompleted",
                                                        `detail${index}`,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Despatches.Order_Detail_Popup_No"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "Button",
                                                {
                                                  attrs: {
                                                    id: `radio-despatch-detail-${_vm.rowIndex}-order-is-complete1-${index}`,
                                                    type: "button",
                                                    size: "medium",
                                                    variant: "border-black",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.mutateData(
                                                        detail,
                                                        "isDeviationCompleted",
                                                        `detail${index}`,
                                                        detail.quantity
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Despatches.Order_Detail_Popup_Yes"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              detail.remainingOrderQuantity > 0 &&
                              detail.remainingOrderQuantity > detail.quantity &&
                              detail.showOrderPopup &&
                              _vm.isCanEdit
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "order-status",
                                      attrs: {
                                        id: `despatches-order-status-${_vm.rowIndex}-input-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "order-status-content" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Despatches.Order_Detail_Content_Message"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-status-content-close-order",
                                            },
                                            [
                                              _c(
                                                "Button",
                                                {
                                                  attrs: {
                                                    id: `radio-despatch-detail-${_vm.rowIndex}-order-is-complete2-${index}`,
                                                    type: "button",
                                                    size: "medium",
                                                    variant: "border-black",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.mutateData(
                                                        detail,
                                                        "isOrderCompleted",
                                                        `detail${index}`,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Despatches.Order_Detail_Popup_No"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "Button",
                                                {
                                                  attrs: {
                                                    id: `radio-despatch-detail-${_vm.rowIndex}-order-is-complete1-${index}`,
                                                    type: "button",
                                                    size: "medium",
                                                    variant: "border-black",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.mutateData(
                                                        detail,
                                                        "isOrderCompleted",
                                                        `detail${index}`,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Despatches.Order_Detail_Popup_Yes"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col order-quantity ff-mono u-textRight tooltip-relative",
                              attrs: {
                                id: `despatches-order-${_vm.rowIndex}-quantity-${index}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(detail.remainingOrderQuantity || "-")
                              ),
                              detail.remainingOrderQuantity > 0
                                ? _c("Tooltip", {
                                    attrs: {
                                      white: "",
                                      right: "",
                                      text: detail.orderNumber,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col baseUnitCode u-textCenter",
                              attrs: {
                                id: `despatches-base-unit-${_vm.rowIndex}-code-${index}`,
                              },
                            },
                            [_vm._v(_vm._s(_vm.findUnit(detail.unitId).name))]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col relative",
                              attrs: {
                                id: `despatch-detail-${_vm.rowIndex}-col-unitPrice-${index}`,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "control tooltip-relative currency",
                                },
                                [
                                  _vm.isCanEdit &&
                                  _vm.isPriceAvailable &&
                                  detail.orderDetailId
                                    ? _c("currency-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              _vm.checkPermission(
                                                "IgnorePriceDeviation"
                                              ) === false
                                                ? {
                                                    required: true,
                                                    min_value:
                                                      detail.minPriceDeviationResult
                                                        ? detail.minPriceDeviationResult
                                                        : 0,
                                                    max_value:
                                                      detail.maxPriceDeviationResult
                                                        ? detail.maxPriceDeviationResult
                                                        : 999999999999,
                                                    multiplyTwoField: [
                                                      detail.quantity,
                                                      detail.netAmount,
                                                    ],
                                                    required: true,
                                                  }
                                                : {
                                                    greater_than: 0,
                                                    required: true,
                                                    multiplyTwoField: [
                                                      detail.quantity,
                                                      detail.netAmount,
                                                    ],
                                                  },
                                            expression:
                                              "checkPermission('IgnorePriceDeviation') === false ? {required: true, min_value: detail.minPriceDeviationResult ? detail.minPriceDeviationResult : 0, max_value: detail.maxPriceDeviationResult ? detail.maxPriceDeviationResult : 999999999999,  multiplyTwoField: [detail.quantity, detail.netAmount], required: true} : {greater_than:0, required: true, multiplyTwoField: [detail.quantity, detail.netAmount]}",
                                          },
                                        ],
                                        staticClass:
                                          "txt netUnitPrice u-textRight ff-mono ff-mono u-textRight tooltip-relative",
                                        class: {
                                          "is-danger": _vm.veeErrors.has(
                                            `detail${index}.unitPrice`
                                          ),
                                        },
                                        attrs: {
                                          name: "unitPrice",
                                          id: `despatch-detail-${_vm.rowIndex}-unitPrice-${index}`,
                                          value: detail.unitPrice,
                                          disabled: detail.priceType === 2,
                                          "data-vv-as": _vm.$t(
                                            "Despatches.detail_TableColumn_NetUnitPrice"
                                          ),
                                          autocomplete: "off",
                                        },
                                        nativeOn: {
                                          blur: function ($event) {
                                            return _vm.mutateData(
                                              detail,
                                              "unitPrice",
                                              `detail${index}`
                                            )
                                          },
                                          focus: function ($event) {
                                            return _vm.SET_FOCUS(detail)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.showCurrencySymbol &&
                                  _vm.isCanEdit &&
                                  _vm.isPriceAvailable &&
                                  detail.orderDetailId
                                    ? _c(
                                        "div",
                                        { staticClass: "currency-symbol" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.despatch
                                                .currencyDefinitionSymbol
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isCanEdit &&
                                  _vm.isPriceAvailable &&
                                  !detail.orderDetailId
                                    ? _c("currency-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              _vm.checkPermission(
                                                "IgnorePriceDeviationWithoutOrder"
                                              ) === false
                                                ? {
                                                    required: true,
                                                    min_value:
                                                      detail.minPriceDeviationResult
                                                        ? detail.minPriceDeviationResult
                                                        : 0,
                                                    max_value:
                                                      detail.maxPriceDeviationResult
                                                        ? detail.maxPriceDeviationResult
                                                        : 999999999999,
                                                    multiplyTwoField: [
                                                      detail.quantity,
                                                      detail.netAmount,
                                                    ],
                                                    required: true,
                                                  }
                                                : {
                                                    greater_than: 0,
                                                    required: true,
                                                    multiplyTwoField: [
                                                      detail.quantity,
                                                      detail.netAmount,
                                                    ],
                                                  },
                                            expression:
                                              "checkPermission('IgnorePriceDeviationWithoutOrder') === false ? {required: true, min_value: detail.minPriceDeviationResult ? detail.minPriceDeviationResult : 0, max_value: detail.maxPriceDeviationResult ? detail.maxPriceDeviationResult : 999999999999,  multiplyTwoField: [detail.quantity, detail.netAmount], required: true} : {greater_than:0, required: true, multiplyTwoField: [detail.quantity, detail.netAmount]}",
                                          },
                                        ],
                                        staticClass:
                                          "txt netUnitPrice u-textRight ff-mono ff-mono u-textRight",
                                        class: {
                                          "is-danger": _vm.veeErrors.has(
                                            `detail${index}.unitPrice`
                                          ),
                                        },
                                        attrs: {
                                          name: "unitPrice",
                                          id: `despatch-detail-${_vm.rowIndex}-unitPrice-${index}`,
                                          value: detail.unitPrice,
                                          disabled: detail.priceType === 2,
                                          "data-vv-as": _vm.$t(
                                            "Despatches.detail_TableColumn_NetUnitPrice"
                                          ),
                                          autocomplete: "off",
                                        },
                                        nativeOn: {
                                          blur: function ($event) {
                                            return _vm.mutateData(
                                              detail,
                                              "unitPrice",
                                              `detail${index}`
                                            )
                                          },
                                          focus: function ($event) {
                                            return _vm.SET_FOCUS(detail)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.showCurrencySymbol &&
                                  _vm.isCanEdit &&
                                  _vm.isPriceAvailable &&
                                  !detail.orderDetailId
                                    ? _c(
                                        "div",
                                        { staticClass: "currency-symbol" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.despatch
                                                .currencyDefinitionSymbol
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  detail.priceType === 2
                                    ? _c("tooltip", {
                                        staticClass: "small",
                                        attrs: {
                                          white: "",
                                          right: "",
                                          text: _vm.$t(
                                            "Despatches.priceTypeWarning"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        detail.showPriceValidation ||
                                        _vm.checkPriceDeviationStatus(
                                          detail
                                        ) === false,
                                      expression:
                                        "detail.showPriceValidation || checkPriceDeviationStatus(detail) === false",
                                    },
                                  ],
                                  staticClass: "unit-price-validation",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Despatches.deviationValidation")
                                      )
                                    ),
                                  ]),
                                  _c("div", { staticClass: "m-left-5" }, [
                                    _vm._v(
                                      _vm._s(detail.minPriceDeviationResult) +
                                        " - " +
                                        _vm._s(detail.maxPriceDeviationResult)
                                    ),
                                  ]),
                                ]
                              ),
                              !_vm.isCanEdit || !_vm.isPriceAvailable
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "netUnitPrice ff-mono u-textRight tooltip-relative",
                                    },
                                    [
                                      _vm.showCurrencySymbol
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                detail.currencyDefinitionSymbol
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatCurrency2Digits")(
                                              detail.unitPrice
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              detail.canIgnorePrice
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "price-status",
                                      attrs: {
                                        id: `despatches-order-status-price-${_vm.rowIndex}-input-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "order-status-content" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Despatches.popup_controlPriceDeviation"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-status-content-close-order",
                                            },
                                            [
                                              _c(
                                                "Button",
                                                {
                                                  attrs: {
                                                    id: `radio-despatch-detail-${_vm.rowIndex}-order-is-complete2-${index}`,
                                                    type: "button",
                                                    size: "medium",
                                                    variant: "border-black",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.mutateData(
                                                        detail,
                                                        "isPriceDeviationCompleted",
                                                        `detail${index}`,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Despatches.Order_Detail_Popup_No"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "Button",
                                                {
                                                  attrs: {
                                                    id: `radio-despatch-detail-${_vm.rowIndex}-order-is-complete1-${index}`,
                                                    type: "button",
                                                    size: "medium",
                                                    variant: "border-black",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.mutateData(
                                                        detail,
                                                        "isPriceDeviationCompleted",
                                                        `detail${index}`,
                                                        detail.unitPrice
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Despatches.Order_Detail_Popup_Yes"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "Despatches-details-body-col",
                              attrs: {
                                id: `despatch-detail-${_vm.rowIndex}-col-netAmount-${index}`,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "control tooltip-relative currency",
                                },
                                [
                                  _vm.isCanEdit && _vm.isPriceAvailable
                                    ? _c("currency-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "greater_than:0|required",
                                            expression:
                                              "'greater_than:0|required'",
                                          },
                                        ],
                                        staticClass:
                                          "txt netAmount u-textRight ff-mono ff-mono u-textRight tooltip-relative",
                                        class: {
                                          "is-danger": _vm.veeErrors.has(
                                            `detail${index}.netAmount`
                                          ),
                                        },
                                        attrs: {
                                          name: "netAmount",
                                          id: `despatch-detail-${_vm.rowIndex}-netAmount-${index}`,
                                          value: detail.netAmount,
                                          disabled: detail.priceType === 2,
                                          autocomplete: "off",
                                        },
                                        nativeOn: {
                                          blur: function ($event) {
                                            return _vm.mutateData(
                                              detail,
                                              "netAmount",
                                              `detail${index}`
                                            )
                                          },
                                          focus: function ($event) {
                                            return _vm.SET_FOCUS(detail)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.showCurrencySymbol &&
                                  _vm.isCanEdit &&
                                  _vm.isPriceAvailable
                                    ? _c(
                                        "div",
                                        { staticClass: "currency-symbol" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              detail.currencyDefinitionSymbol
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.isCanEdit || !_vm.isPriceAvailable
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "netAmount ff-mono u-textRight",
                                        },
                                        [
                                          _vm.showCurrencySymbol
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    detail.currencyDefinitionSymbol
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  detail.priceType === 2
                                    ? _c("tooltip", {
                                        staticClass: "small",
                                        attrs: {
                                          white: "",
                                          right: "",
                                          text: _vm.$t(
                                            "Despatches.priceTypeWarning"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col baseQuantity ff-mono u-textRight",
                              attrs: {
                                id: `despatches-base-${_vm.rowIndex}-quantity-${index}`,
                              },
                            },
                            [_vm._v(_vm._s(detail.baseQuantity))]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col baseUnitCode",
                              attrs: {
                                id: `despatches-base-unit-code-${index}`,
                              },
                            },
                            [_vm._v(_vm._s(detail.stockItem.baseUnitCode))]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col basePrice ff-mono u-textRight",
                              attrs: {
                                id: `despatches-base-${_vm.rowIndex}-price-${index}`,
                              },
                            },
                            [
                              _vm.showCurrencySymbol
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(detail.currencyDefinitionSymbol)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency2Digits")(
                                      detail.netBaseUnitPrice
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "Despatches-details-body-col",
                              attrs: {
                                id: `despatches-detail-${_vm.rowIndex}-col-discount1-${index}`,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "control tooltip-relative" },
                                [
                                  _vm.isCanEdit
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              "greater_than:-1|max_value:100",
                                            expression:
                                              "'greater_than:-1|max_value:100'",
                                          },
                                        ],
                                        ref: `discount1-${index}`,
                                        refInFor: true,
                                        staticClass:
                                          "txt discount1 ff-mono u-textRight no-spinners",
                                        class: {
                                          "is-danger": _vm.veeErrors.has(
                                            `detail${index}.discount1`
                                          ),
                                        },
                                        attrs: {
                                          id: `despatches-detail-${_vm.rowIndex}-discount1-${index}`,
                                          name: "discount1",
                                          disabled:
                                            !_vm.isPriceAvailable ||
                                            detail.priceType === 2,
                                          type: "number",
                                          autocomplete: "off",
                                          step: "any",
                                        },
                                        domProps: { value: detail.discount1 },
                                        on: {
                                          change: function ($event) {
                                            return _vm.mutateData(
                                              detail,
                                              "discount1",
                                              `detail${index}`
                                            )
                                          },
                                          focus: function ($event) {
                                            return _vm.SET_FOCUS(detail)
                                          },
                                          blur: function ($event) {
                                            return _vm.SET_NOFOCUS(detail)
                                          },
                                        },
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "discount1 ff-mono u-textRight discounts",
                                        },
                                        [
                                          _vm._v(
                                            "%" +
                                              _vm._s(
                                                _vm._f("percentFixed")(
                                                  detail.discount1
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                  detail.priceType === 2
                                    ? _c("tooltip", {
                                        staticClass: "small",
                                        attrs: {
                                          white: "",
                                          right: "",
                                          text: _vm.$t(
                                            "Despatches.priceTypeWarning"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "Despatches-details-body-col",
                              attrs: {
                                id: `despatches-detail-${_vm.rowIndex}-col-discount2-${index}`,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "control tooltip-relative" },
                                [
                                  _vm.isCanEdit
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              "greater_than:-1|max_value:100",
                                            expression:
                                              "'greater_than:-1|max_value:100'",
                                          },
                                        ],
                                        staticClass:
                                          "discount2 txt no-spinners ff-mono u-textRight",
                                        class: {
                                          "is-danger": _vm.veeErrors.has(
                                            `detail${index}.discount2`
                                          ),
                                        },
                                        attrs: {
                                          id: `despatches-detail-${_vm.rowIndex}-discount2-${index}`,
                                          name: "discount2",
                                          disabled:
                                            !Number(detail.discount1) ||
                                            !_vm.isPriceAvailable ||
                                            detail.priceType === 2,
                                          autocomplete: "off",
                                          step: "any",
                                          type: "number",
                                        },
                                        domProps: { value: detail.discount2 },
                                        on: {
                                          change: function ($event) {
                                            return _vm.mutateData(
                                              detail,
                                              "discount2",
                                              `detail${index}`
                                            )
                                          },
                                          focus: function ($event) {
                                            return _vm.SET_FOCUS(detail)
                                          },
                                          blur: function ($event) {
                                            return _vm.SET_NOFOCUS(detail)
                                          },
                                        },
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "discount2 ff-mono u-textRight discounts",
                                        },
                                        [
                                          _vm._v(
                                            "%" +
                                              _vm._s(
                                                _vm._f("percentFixed")(
                                                  detail.discount2
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                  detail.priceType === 2
                                    ? _c("tooltip", {
                                        staticClass: "small",
                                        attrs: {
                                          white: "",
                                          right: "",
                                          text: _vm.$t(
                                            "Despatches.priceTypeWarning"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col discountedUnitPrice ff-mono u-textRight",
                              attrs: {
                                id: `despatches-discounted-${_vm.rowIndex}-unit-price-${index}`,
                              },
                            },
                            [
                              _vm.showCurrencySymbol
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(detail.currencyDefinitionSymbol)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency2Digits")(
                                      detail.discountedUnitPrice
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col DiscountedBaseUnitPrice ff-mono u-textRight",
                              attrs: {
                                id: `despatches-discounted-${_vm.rowIndex}-base-unit-price-${index}`,
                              },
                            },
                            [
                              _vm.showCurrencySymbol
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(detail.currencyDefinitionSymbol)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency2Digits")(
                                      detail.discountedBaseUnitPrice
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col taxRatio tooltip-relative",
                              attrs: {
                                id: `despatches-detail-${_vm.rowIndex}-col-tax-ratio-${index}`,
                              },
                            },
                            [
                              _vm.isCanEdit && _vm.isPriceAvailable
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "txt tax",
                                          class: {
                                            "is-danger": _vm.veeErrors.has(
                                              `detail${index}.taxId`
                                            ),
                                          },
                                          attrs: {
                                            name: "taxId",
                                            id: `despatches-${_vm.rowIndex}-tax-ratio-select-${index}`,
                                          },
                                          domProps: { value: detail.taxId },
                                          on: {
                                            change: function ($event) {
                                              return _vm.mutateData(
                                                detail,
                                                "taxId",
                                                `detail${index}`
                                              )
                                            },
                                            click: function ($event) {
                                              return _vm.SET_FOCUS(detail)
                                            },
                                            blur: function ($event) {
                                              return _vm.SET_NOFOCUS(detail)
                                            },
                                          },
                                        },
                                        _vm._l(_vm.Taxes, function (Tax) {
                                          return _c(
                                            "option",
                                            {
                                              key: Tax.id,
                                              attrs: {
                                                id: `option-despatch-${_vm.rowIndex}-popup-${Tax.id}`,
                                              },
                                              domProps: { value: Tax.id },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "%" +
                                                    Tax.ratio +
                                                    " - " +
                                                    Tax.name
                                                )
                                              ),
                                            ]
                                          )
                                        }),
                                        0
                                      ),
                                      _c("Icon", {
                                        staticClass: "icon-down-arrow",
                                        attrs: { name: "icon-down-arrow" },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "taxRatio ff-mono u-textRight",
                                    },
                                    [
                                      _vm._v(
                                        "%" +
                                          _vm._s(
                                            _vm._f("percentFixed")(
                                              detail.taxRatio
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col discountedNetUnitPrice ff-mono u-textRight",
                              attrs: {
                                id: `despatches-discounted-${_vm.rowIndex}-net-unit-price-${index}`,
                              },
                            },
                            [
                              _vm.showCurrencySymbol
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(detail.currencyDefinitionSymbol)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    detail.quantity
                                      ? _vm.$options.filters.formatCurrency2Digits(
                                          detail.discountedNetUnitPrice
                                        )
                                      : "0"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col netAmount ff-mono u-textRight",
                              attrs: {
                                id: `despatches-${_vm.rowIndex}-net-amount-${index}`,
                              },
                            },
                            [
                              _vm.showCurrencySymbol
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(detail.currencyDefinitionSymbol)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    detail.quantity
                                      ? _vm.$options.filters.formatCurrency2Digits(
                                          detail.taxAmount
                                        )
                                      : "0"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col netAmount ff-mono u-textRight",
                              attrs: {
                                id: `despatches-${_vm.rowIndex}-gross-amount${index}`,
                              },
                            },
                            [
                              _vm.showCurrencySymbol
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(detail.currencyDefinitionSymbol)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    detail.quantity
                                      ? _vm.$options.filters.formatCurrency2Digits(
                                          detail.grossAmount
                                        )
                                      : "0"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass:
                                "Despatches-details-body-col storeName",
                              attrs: {
                                id: `despatches-store-${_vm.rowIndex}-name-${index}`,
                              },
                            },
                            [_vm._v(_vm._s(detail.storeName))]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "Despatches-details-body-col action",
                              attrs: {
                                id: `despatches-detail-${_vm.rowIndex}-col-button-${index}`,
                              },
                            },
                            [
                              _c("ActionButton", {
                                attrs: {
                                  id: "despatches-detail-action-button",
                                  data: !_vm.isCanEdit
                                    ? ["edit"]
                                    : ["edit", "remove"],
                                  item: detail,
                                },
                                on: {
                                  onItemEdit: function ($event) {
                                    return _vm.despatchDetailEdit(detail)
                                  },
                                  onItemDelete: function ($event) {
                                    return _vm.despatchDetailRemove(detail)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "Despatches-details-foot" },
          [
            _c(
              "Button",
              {
                staticClass: "btn-inline",
                attrs: {
                  id: `btn-despatch-${_vm.rowIndex}-detail-expire-date-edit`,
                  type: "button",
                  size: "small",
                  secondary: "",
                  disabled: !_vm.selectedDetail.length,
                },
                on: {
                  click: function ($event) {
                    return _vm.editExpireDate(_vm.despatch)
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    attrs: {
                      id: `btn-despatch-${_vm.rowIndex}-detail-expire-date-settings`,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Despatches.expireDateSettings")))]
                ),
              ]
            ),
            _c(
              "Button",
              {
                staticClass: "btn-inline",
                attrs: {
                  id: `btn-despatch-${_vm.rowIndex}-detail-new`,
                  type: "button",
                  size: "small",
                  secondary: "",
                  disabled: _vm.selectedDetail.length === 0,
                },
                on: { click: _vm.despatchMultipleDetailEdit },
              },
              [
                _c(
                  "span",
                  {
                    attrs: {
                      id: `btn-despatch-${_vm.rowIndex}-detail-multiple-edit`,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Despatches.detail_multipleEdit")))]
                ),
              ]
            ),
            _c(
              "Button",
              {
                staticClass: "btn-inline m-right-10",
                attrs: {
                  id: `btn-despatch-${_vm.rowIndex}-detail-new`,
                  type: "button",
                  size: "small",
                  secondary: "",
                  disabled: _vm.isListSupplierCheck,
                  iconName: "icon-global-add",
                  iconClass: "icon-global-add",
                },
                on: {
                  click: function ($event) {
                    return _vm.despatchDetailNew(_vm.despatch)
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    attrs: {
                      id: `btn-despatch-${_vm.rowIndex}-detail-new-span`,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("Despatches.detail_NewStockItemButton"))
                    ),
                  ]
                ),
              ]
            ),
            _vm.despatch.status === 1
              ? _c(
                  "Button",
                  {
                    staticClass: "btn-inline m-right-10",
                    attrs: {
                      id: `btn-despatch-${_vm.rowIndex}-detail-save`,
                      type: "button",
                      size: "small",
                      primary: "",
                      iconName: "icon-btn-success",
                      iconClass: "icon-btn-success",
                      disabled:
                        _vm.despatch.despatchDetailList < 1 ||
                        !_vm.priceDeviationStatus ||
                        !_vm.quantityDeviationStatus ||
                        _vm.showNegativeQuantityValidation,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.despatchSaved(_vm.despatch)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          id: `btn-despatch-${_vm.rowIndex}-detail-save-span`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Despatches.detail_SaveDespatchButton"))
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("ExpireDate", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showExpireDateEdit,
            expression: "showExpireDateEdit",
          },
        ],
        attrs: { data: _vm.batchList, despatchHeader: _vm.despatchHeader },
        on: {
          editExpireDate: _vm.editExpireDate,
          getUpdatedBatch: _vm.editExpireDate,
          closeModal: _vm.closeExpireDateScreen,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }