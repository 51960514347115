var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "Auth-form Login-form Form",
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("h1", { staticClass: "Auth-form-title" }, [
        _vm._v(_vm._s(_vm.$t("Login.formHeaderTitle"))),
      ]),
      _c("div", { staticClass: "Form-item" }, [
        _c("label", { staticClass: "Form-item-label" }, [
          _vm._v(_vm._s(_vm.$t("Login.formFieldEmail"))),
        ]),
        _c("div", { staticClass: "control" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.email,
                expression: "form.email",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|email|max:128",
                expression: "'required|email|max:128'",
              },
            ],
            staticClass: "txt",
            class: { "is-danger": _vm.veeErrors.has("email") },
            attrs: {
              id: "input-auth-login-email",
              name: "email",
              "data-vv-as": _vm.$t("Login.formFieldEmail"),
              type: "text",
            },
            domProps: { value: _vm.form.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "email", $event.target.value)
              },
            },
          }),
        ]),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.veeErrors.has("email"),
                expression: "veeErrors.has('email')",
              },
            ],
            staticClass: "Form-item-help is-danger",
          },
          [_vm._v(_vm._s(_vm.veeErrors.first("email")))]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "Form-item password",
          class: { "is-show": _vm.isShowPassword },
        },
        [
          _c("label", { staticClass: "Form-item-label" }, [
            _vm._v(_vm._s(_vm.$t("Login.formFieldPassword"))),
          ]),
          _c(
            "div",
            { staticClass: "control" },
            [
              _vm.isPasswordType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6",
                        expression: "'required|min:6'",
                      },
                    ],
                    staticClass: "txt txt-right-icon",
                    class: { "is-danger": _vm.veeErrors.has("password") },
                    attrs: {
                      id: "input-auth-login-password",
                      name: "password",
                      "data-vv-as": _vm.$t("Login.formFieldPassword"),
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.form.password)
                        ? _vm._i(_vm.form.password, null) > -1
                        : _vm.form.password,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.form.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.form, "password", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.form,
                                "password",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.form, "password", $$c)
                        }
                      },
                    },
                  })
                : _vm.isPasswordType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6",
                        expression: "'required|min:6'",
                      },
                    ],
                    staticClass: "txt txt-right-icon",
                    class: { "is-danger": _vm.veeErrors.has("password") },
                    attrs: {
                      id: "input-auth-login-password",
                      name: "password",
                      "data-vv-as": _vm.$t("Login.formFieldPassword"),
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.form.password, null) },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.form, "password", null)
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6",
                        expression: "'required|min:6'",
                      },
                    ],
                    staticClass: "txt txt-right-icon",
                    class: { "is-danger": _vm.veeErrors.has("password") },
                    attrs: {
                      id: "input-auth-login-password",
                      name: "password",
                      "data-vv-as": _vm.$t("Login.formFieldPassword"),
                      type: _vm.isPasswordType,
                    },
                    domProps: { value: _vm.form.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "password", $event.target.value)
                      },
                    },
                  }),
              _c("Button", {
                attrs: {
                  id: "btn-auth-login-show-password",
                  type: "button",
                  variant: "icon switch",
                  iconName: _vm.isShowPassword
                    ? "icon-login-eye"
                    : "icon-login-eye-closed",
                  iconClass: _vm.isShowPassword
                    ? "icon-login-eye"
                    : "icon-login-eye-closed",
                },
                on: { click: _vm.showPassword },
              }),
            ],
            1
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.veeErrors.has("password"),
                  expression: "veeErrors.has('password')",
                },
              ],
              staticClass: "Form-item-help is-danger",
            },
            [_vm._v(_vm._s(_vm.veeErrors.first("password")))]
          ),
        ]
      ),
      _vm.isSupportUser
        ? _c("div", { staticClass: "Form-item tenantId" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(_vm._s(_vm.$t("Login.formFieldTenantId"))),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.tenantId,
                    expression: "form.tenantId",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "txt txt-right-icon",
                class: { "is-danger": _vm.veeErrors.has("tenantId") },
                attrs: {
                  id: "input-auth-login-tenantId",
                  name: "tenantId",
                  "data-vv-as": _vm.$t("Login.formFieldTenantId"),
                  type: "text",
                },
                domProps: { value: _vm.form.tenantId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "tenantId", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("tenantId"),
                    expression: "veeErrors.has('tenantId')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("tenantId")))]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.authAlert,
              expression: "authAlert",
            },
          ],
          staticClass: "Form-item alert x2",
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.errorStatusMessage
                  ? _vm.$t(`ApiErrorCode.${_vm.errorStatusMessage}`)
                  : _vm.$t("Login.formAlert")
              )
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "Form-item x2" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                type: "submit",
                variant: "full",
                id: "login-button",
                disabled: !_vm.isFormReady || _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.$t("Login.formFieldSubmit")))]
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }