<template lang="pug">
.supplier
  .supplier-top
    .title
      h4.supplier-title {{ $t('Dashboard.supplier_debt_info') }}
    .slide(v-if="Supplier.length > 6")
      span.dot(
      @click="changeData(0, 6)"
      :class="{'active': fromPage === 0}"
      )
      span.dot(
      @click="changeData(6, 12)"
      :class="{'active': fromPage === 6}"
      )
  .supplier-main
    table.debt-table(:id="`debt-table`")
      thead.debt-table-head(:id="`debt-table-head`")
        tr.table-row(:id="`debt-table-head-row`")
          th.table-col(:id="`col-company`") {{ $t('Dashboard.company') }}
          th.table-col(:id="`col-title-purchase-amount`") {{ $t('Dashboard.despatchTitlePurchaseAmount') }}
          th.table-col(:id="`col-title-payment-amount`") {{ $t('Dashboard.despatchTitlePaymentAmount') }}
          th.table-col(:id="`col-supplier-debt-amount`") {{ $t('Dashboard.supplier_debt_amount') }}
      tbody.debt-table-body
        tr.table-row(
          :id="`debt-table-body-row`"
          v-for="(supp, index) in selectedData")
          td.table-col(:id="`col-supplier-name-${index}`") {{  supp.supplierName  }}
          td.table-col(:id="`col-purchase-amount-${index}`") {{  supp.purchaseAmount | formatCurrency2Digits  }}
          td.table-col.debt-total(:id="`col-payment-amount-${index}`") {{  supp.paymentAmount | formatCurrency2Digits  }}
          td.table-col.debt-left(:id="`col-debt-amount-${index}`") {{  supp.debtAmount | formatCurrency2Digits  }}
      tfoot.debt-table-footer
        tr.table-row(:id="`debt-table-footer-row`")
          td.table-col(:id="`col-on-hand-total-title`") {{ $t('Dashboard.onHandTotalTitle') }}
          td.table-col.debt.recieved(:id="`col-debt-purchase-amount`") {{ Despatch.purchaseAmount | formatCurrency2Digits }}
          td.table-col.debt.total(:id="`col-debt-payment-amount`") {{ Despatch.paymentAmount | formatCurrency2Digits }}
          td.table-col.debt.left(:id="`col-debt-left-amount`") {{ Despatch.debtAmount | formatCurrency2Digits }}
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'supplier-debt',
  data () {
    return {
      selectData: {},
      fromPage: 0,
      toPage: 6
    }
  },
  computed: {
    ...mapGetters('Dashboard', [
      'Supplier',
      'Despatch'
    ]),
    selectedData () {
      this.selectData = this.Supplier
      return this.selectData.slice(this.fromPage, this.toPage)
    }
  },
  methods: {
    changeData (fromPage, toPage) {
      this.fromPage = fromPage
      this.toPage = toPage
    }
  }
}
</script>

<style scoped lang="scss">

.supplier {
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      .supplier-title {
        font-size: $font-size-big;
        color: $color-sidebar-dark;
      }
    }
    .slide {
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-light;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
        &.active {
          background-color: $color-success;
        }
      }
    }
  }
  &-main {
    margin-top: 24px;
    overflow: auto;
    .debt-table {
      .table-row {
        .table-col {
          font-size: $font-size-small;
          width: 25%;
          padding: 15px 0;
        }
      }
      &-head {
        .table-row {
          .table-col {
            color: $color-sidebar-dark;
            text-align: right;
            &:first-child {
              text-align: left;
            }
          }
        }
      }
      &-body {
        .table-row {
          border-bottom: 1px solid $color-gray;
          .table-col {
            width: 25%;
            font-size: $font-size-small;
            color: $color-op-gray;
            text-align: right;
            &:first-child {
              text-align: left;
            }
            &.debt-total {
              color: $color-success;
            }
            &.debt-left {
              color: $color-warning;
            }
          }
        }
      }
      &-footer {
        margin-top: 20px;
        .table-row {
          border-bottom: none;
          .table-col {
            padding: 20px 0;
            text-align: right;
            &:first-child {
              text-align: left;
            }
            &.debt {
              font-size: $font-size-big;
              font-weight: $font-weight-bold;
              @media (max-width: 768px) {
                font-size: $font-size-small;
              }
              &.recieved {
                color: $color-op-gray;
              }
              &.total {
                color: $color-success;
              }
              &.left {
                color: $color-warning;
              }
            }
          }
        }
      }
    }
  }
}
</style>
