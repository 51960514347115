var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderComponent
    ? _c(
        "div",
        { staticClass: "Despatches" },
        [
          _c("div", { staticClass: "single-head" }, [
            _c(
              "div",
              { staticClass: "single-head-col checkbox" },
              [
                _c("CustomCheckbox", {
                  attrs: {
                    id: "checkbox-despatch-header-select-all",
                    checked: !_vm.hideAllCheckbox ? _vm.isCheckedAll : false,
                    label: "",
                    disabled: _vm.hideAllCheckbox,
                  },
                  on: { change: _vm.toggleAll },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "single-head-col name",
                attrs: { id: "despatches-table-header-supplier-name" },
              },
              [
                _c("h6", [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_Header_SupplierName"))
                  ),
                ]),
              ]
            ),
            _vm.tenantMultiCurrencyIsActive ||
            _vm.tenantMustHaveSingleStoreInSameDespatchActive
              ? _c(
                  "div",
                  {
                    staticClass: "single-head-col parent-store-name",
                    attrs: { id: "despatches-table-header-parentStore-name" },
                  },
                  [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Despatches.tableColumn_Header_ParentStoreName"
                          )
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "single-head-col date",
                attrs: { id: "despatches-table-header-supplier-date" },
              },
              [
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("Despatches.tableColumn_DespatchDate"))),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "single-head-col number",
                attrs: { id: "despatches-table-header-supplier-number" },
              },
              [
                _c("h6", [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_DespatchNumber"))
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "single-head-col net",
                attrs: { id: "despatches-table-header-supplier-net-amount" },
              },
              [
                _c("h6", { staticClass: "u-textRight full-width" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_NetTotalAmount"))
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "single-head-col tax",
                attrs: { id: "despatches-table-header-supplier-tax-amount" },
              },
              [
                _c("h6", { staticClass: "u-textRight full-width" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_TaxTotalAmount"))
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "single-head-col gross",
                attrs: {
                  id: "despatches-table-header-supplier-gross-total-amount",
                },
              },
              [
                _c("h6", { staticClass: "u-textRight full-width" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.tableColumn_GrossTotalAmount"))
                  ),
                ]),
              ]
            ),
          ]),
          _vm._l(_vm.tableData, function (despatch, index) {
            return _c(
              "div",
              {
                staticClass: "Despatches-item",
                class: {
                  isOpened: despatch.showDetail,
                  "small-size": true,
                  "border-blue": despatch.showDetail,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "Despatches-header",
                    attrs: { tabindex: "0" },
                  },
                  [
                    _c("div", {
                      staticClass: "NestedTableHeaderLink",
                      attrs: { id: `btn-despatches-open-header-${index}` },
                      on: {
                        click: function ($event) {
                          return _vm.showDetailAction(despatch)
                        },
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "Despatches-header-col checkbox",
                        attrs: { id: `status-despatches-${index}` },
                      },
                      [
                        despatch.status === 1
                          ? _c("StatusTooltip", {
                              attrs: {
                                title: _vm.$t("Despatches.HeaderNotSaved"),
                                icon: "icon-row-status",
                              },
                            })
                          : _vm._e(),
                        _c("CustomCheckbox", {
                          class: { "hide-checkbox": despatch.status === 1 },
                          attrs: {
                            id: `checkbox-despatch-header-${index}`,
                            checked: _vm.selected.indexOf(despatch) > -1,
                            label: "",
                            disabled:
                              _vm.firstSelectedSupplierId(
                                despatch.supplier.id
                              ) && !_vm.isSelectedOneMoreThanSupplier,
                          },
                          on: {
                            change: (isChecked) =>
                              _vm.clickCheckbox(isChecked, despatch),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "Despatches-header-col name ellipsis",
                        attrs: {
                          id: `despatch-header-supplier-container-${index}`,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "tooltip-relative word-break-all",
                            attrs: {
                              id: `despatch-header-supplier-span-${index}`,
                            },
                          },
                          [
                            _vm._v(_vm._s(despatch.supplier.name)),
                            _c("Tooltip", {
                              attrs: {
                                dark: "",
                                bottom: "",
                                id: `despatch-header-supplier-tooltip-${index}`,
                                text: _vm.getStoreGroupsCode(
                                  despatch.storeGroups
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.tenantMultiCurrencyIsActive ||
                    _vm.tenantMustHaveSingleStoreInSameDespatchActive
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "Despatches-header-col parent-store-name ellipsis",
                            attrs: {
                              id: `despatch-header-parentStore-container-${index}`,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "tooltip-relative word-break-all",
                                attrs: {
                                  id: `despatch-header-parentStore-span-${index}`,
                                },
                              },
                              [
                                _vm._v(_vm._s(despatch.parentStoreName)),
                                _c("Tooltip", {
                                  attrs: {
                                    dark: "",
                                    bottom: "",
                                    id: `despatch-header-parentStore-tooltip-${index}`,
                                    text: despatch.parentStoreName,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "Despatches-header-col date",
                        attrs: {
                          id: `despatch-header-date-container-${index}`,
                        },
                      },
                      [
                        despatch.status === 1
                          ? _c("CustomDatepicker", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              class: {
                                "is-danger": _vm.veeErrors.has(
                                  `despatch-date-${index}`
                                ),
                              },
                              attrs: {
                                name: `despatch-date-${index}`,
                                id: `dp-invoice-header-date-${index}`,
                                value: _vm.tableData[index].despatchDate,
                                pickerType: "manuel",
                                inputIconRight: "",
                                inputIcon: "",
                                disabledStartDate: _vm.disableDate,
                              },
                              on: {
                                change: (e) =>
                                  _vm.sendHeader(_vm.tableData[index], {
                                    date: e,
                                    number: _vm.tableData[index].despatchNumber,
                                  }),
                              },
                            })
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(despatch.despatchDate)
                                )
                              ),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "Despatches-header-col number",
                        attrs: {
                          id: `despatch-header-number-container-${index}`,
                        },
                      },
                      [
                        despatch.status === 1
                          ? _c("input", {
                              directives: [
                                {
                                  name: "default-value",
                                  rawName: "v-default-value",
                                  value: _vm.tableData[index].despatchNumber,
                                  expression: "tableData[index].despatchNumber",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|max:32",
                                  expression: "'required|max:32'",
                                },
                              ],
                              staticClass: "txt",
                              class: {
                                "is-danger": _vm.veeErrors.has(
                                  `despatch-number-${index}`
                                ),
                              },
                              attrs: {
                                id: `input-despatch-number-${index}`,
                                name: `despatch-number-${index}`,
                                type: "text",
                                autocomplete: "off",
                              },
                              on: {
                                change: (e) =>
                                  _vm.sendHeader(_vm.tableData[index], {
                                    date: _vm.tableData[index].despatchDate,
                                    number: e.target.value,
                                  }),
                              },
                            })
                          : _c("span", [
                              _vm._v(_vm._s(despatch.despatchNumber)),
                            ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "Despatches-header-col net ff-mono",
                        attrs: { id: `net-sum-${index}` },
                      },
                      [
                        _c("div", { staticClass: "u-textRight full-width" }, [
                          _vm._v(
                            _vm._s(_vm.getAmount(despatch, "netTotalAmount"))
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Despatches-header-col tax ff-mono u-textRight",
                        attrs: { id: `tax-sum-${index}` },
                      },
                      [
                        _c("div", { staticClass: "u-textRight full-width" }, [
                          _vm._v(
                            _vm._s(_vm.getAmount(despatch, "taxTotalAmount"))
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Despatches-header-col gross ff-mono u-textRight",
                        attrs: { id: `gross-sum-${index}` },
                      },
                      [
                        _c("div", { staticClass: "u-textRight full-width" }, [
                          _vm._v(
                            _vm._s(_vm.getAmount(despatch, "grossTotalAmount"))
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "Despatches-header-col decompose" },
                      [
                        despatch.description
                          ? _c(
                              "Button",
                              {
                                staticClass:
                                  "show-MiniPopup relative width-max-content",
                                attrs: {
                                  size: "small",
                                  variant: "info",
                                  id: `despatch-header-info-button-${despatch.index}`,
                                  iconName: "icon-global-info",
                                  justIcon: true,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "MiniPopup-right-primary m-top-15 c-dark word-break-all",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(despatch.description)),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "Despatches-header-col decompose" },
                      [
                        despatch.decomposedInvoiceNumber
                          ? _c(
                              "Button",
                              {
                                staticClass:
                                  "show-MiniPopup relative width-max-content",
                                attrs: {
                                  size: "small",
                                  variant: "info",
                                  id: `decomposed-invoice-number-info-button-${index}`,
                                  iconName: "icon-request-description",
                                  justIcon: true,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "MiniPopup-right-primary m-top-15 c-dark",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(despatch.decomposedInvoiceNumber)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "Despatches-header-col action" },
                      [
                        _c("Button", {
                          attrs: {
                            justIcon: true,
                            ghostsuccess: "",
                            variant: "icon",
                            iconName: "icon-arrow-down",
                            id: `btn-despatches-open-header-${index}`,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showDetailAction(despatch)
                            },
                          },
                        }),
                        _c("ActionButton", {
                          attrs: {
                            id: "price-quote-action-button",
                            data: _vm.checkActionStatus(despatch),
                            item: despatch,
                            customActionName: _vm.$t("Despatches.makeTransfer"),
                          },
                          on: {
                            onItemEdit: function ($event) {
                              return _vm.editAction(despatch)
                            },
                            onItemExport: function ($event) {
                              return _vm.showExportDespatch(despatch.id)
                            },
                            onItemDelete: function ($event) {
                              return _vm.removeItemAction(despatch)
                            },
                            onItemCustomAction: function ($event) {
                              return _vm.customAction(despatch)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                despatch.showDetail && _vm.dataType === "Despatches"
                  ? _c("Details", {
                      attrs: {
                        despatch: despatch,
                        isCanEdit:
                          !despatch.supplier.isGlobal ||
                          (despatch.supplier.isGlobal &&
                            _vm.RoleArea === "global"),
                        rowIndex: index,
                      },
                      on: { updateHeaderTotal: _vm.calculateHeaderTotal },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }