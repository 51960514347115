<template lang="pug">
Page.production
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "PageLinks"
      translationGroup="TabItems"
    )

  template(slot="header-right-dropdown" v-if="hasTabPermission")
    CustomDatepicker#ProductionDatepicker.m-right-10(
      :isLoading="$wait.is('getDateFilterSettings')"
      v-model="dates",
      :range="true"
      :disabled="isFetchingData"
    )
  template(slot="header-right-content")
    Button(
    primary,
    size="medium",
    iconName="icon-global-add",
    v-if="!isEmptyProductionList",
    @click="addNewProduction"
    id="add-production-header-button")
      span {{ $t('Global.addData', { field: $t('Dictionary.Production') }) }}
  template(slot="toolbar-left")
    storeFilterComponent(
      @submitFilter="getListWithStoreFilter"
      ref="productionStoreFilter")

    Button.clear-filter-button.border-gray-1.p-0.width-auto(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")

    .show-drafts.m-left-15
      CustomCheckbox.popup-item-check(
      id="checkbox-production-show-drafts"
      :checked="showDrafts"
      :label="$t('Global.showDrafts')"
      @change="changeShowDraftsStatus")

  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    .Production(v-else)

      // LOADING
      TableLoading(v-if="isFetchingData")

      // EMPTY PAGE

      EmptyState(
      v-if="!isFetchingData && isEmptyProductionList",
      :title="$t('Global.emptyState_Title', { field: $t('Dictionary.Production') })",
      :description="$t('Global.emptyState_Description', { field: $t('Dictionary.Production') })",
      :buttonText="$t('Global.addData', { field: $t('Dictionary.Production') })",
      @emptyAddButton="addNewProduction")

      // LISTS - NESTED TABLE
      div(v-if="!isFetchingData && !isEmptyProductionList")
        NestedTable.production(
          tableKey="production",
          :fields="productionHeaderFields",
          :detailFields="productionDetailFields",
          @showDetailAction="showDetailAction",
          :isDetailLoading="isDetailLoading",
          :actions="['edit', 'delete', 'export']",
          :detailActions="['edit','delete']"
          :usePortalFooter="true"
          :selectable="'checkbox'"
          :selectableAll="'checkbox'"
          :selectedRows="selectedList"
          @editHeaderAction="editHeaderProduction"
          @deleteHeaderAction="removeHeader",
          @deleteDetailAction="removeDetail",
          @editDetailAction="editDetailItem",
          :isLoadingFinalize="isFinalizeLoading",
          @detailChangeAction="updateDetailItem"
          @showExportPopup="showExportPopup"
          @selectItem="onSelectProduction"
          @selectAllItems="onSelectProductionAll"
          :data="Productions")

        portal(to="description")
          div(slot-scope="header")
            Button.show-MiniPopup.relative.width-max-content(
              size="medium"
              variant="info"
              v-if="header.row.description",
              :id="`production-header-info-button-${header.rowIndex}`"
              iconName="icon-global-info",
              :justIcon="true",
            )
              .MiniPopup-right-primary.m-top-15.c-dark
                span {{ header.row.description }}

        portal(to="unitName")
          div(slot-scope="detail")
            span {{ findUnit(detail.row.unitId).name }}
        

        portal(to="footerPortal")
          div.popup-container.flexbox(slot-scope="rowData")
            Button.m-right-20(
              v-if="isExpireDateControlEnabled(rowData)"
              id="btn-production-expire-date-edit"
              type="button"
              size="small"
              secondary
              @click="editExpireDate(rowData)")
                span {{ $t('Transfer.editExpireDate') }}
        
            div
              Button.m-right-20(
                v-if="rowData.status === 1"
                id="nested-detail-production-add-button",
                size="small",
                inline,
                primary,
                iconName="icon-global-add",
                iconClass="icon-global-add",
                :disabled="isOnlyListUsage"
                @click="addNewDetail(rowData)")
                  span {{ $t('Global.addNewStock') }}

            div
              Button.m-right-20(
              v-if="rowData.status === 1"
              id="production-nested-detail-finalize",
              inline,
              size="small"
              primary,
              iconName="icon-btn-success",
              iconClass="icon-btn-success",
              @click="productionSaved(rowData)")
                span {{ $t('Global.Save') }}

          // pagination


   

    router-view(
      @getProductionsList="getProductionsList"
      @showDrafts="showDrafts = true"
    )

    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )
    ExpireDate(
      v-show="showExpireDateEdit"
      :data="BatchList"
      :productionHeader="productionHeader"
      @editExpireDate="editExpireDate"
      @getUpdatedBatch="editExpireDate"
      @closeModal="closeExpireDateScreen"
    )
  template(slot="footer")
    TableFoot(
      v-if="!isFetchingData"
      :page="Page",
      @delegateOnChangePage="changePage"
      @delegateOnChangePageSize="changePageSize")


</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'
import { getProductionsHeaderFields, getProductionsDetailFields } from '@/view/pages/Production/fields/production.js'
//
import storeFilterComponent from '@/view/global/business-filters/store'
import TableFoot from '@/view/global/table/foot'
import exportPopup from '@/view/global/export-popup'
import settingsExportPopup from '@/mixins/settingsExportPopup'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import NoPermission from '@/view/global/has-not-permission'
import hasTabPermission from '@/mixins/hasTabPermission'
import ExpireDate from './components/expire-date-table'

export default {
  name: 'Production',

  data () {
    return {
      productionHeaderFields: getProductionsHeaderFields(this.$t),
      productionDetailFields: getProductionsDetailFields(this.$t, this.$getConst),
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ],
      storeFilter: [],
      isOnlyListUsage: false,
      showDrafts: true,
      showExpireDateEdit: false,
      productionHeader: {},
      expireDateData: {},
      selectedList: []
    }
  },

  mixins: [settingsExportPopup, hasTabPermission],

  components: {
    storeFilterComponent,
    TableFoot,
    exportPopup,
    NoPermission,
    ExpireDate
  },
  computed: {
    //

    ...mapGetters('Production', [
      'PageLinks',
      'Productions',
      'Page',
      'isEmptyProductionList',
      'findProductionHeader',
      'BatchList'
    ]),

    ...mapGetters('Settings', [
      'permissions'
    ]),

    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    ...mapGetters('Units', ['findUnit']),

    // loadings

    isFetchingData () {
      return this.$wait.is('fetchProductionsList')
    },

    isDetailLoading () {
      return this.$wait.is('fetchProduct')
    },

    isFinalizeLoading () {
      return this.$wait.is(['finalize', 'productionSaved'])
    }
  },

  methods: {

    ...mapActions('Production', [
      'fetchProductionsList',
      'fetchProduct',
      'updateProductionDetail',
      'makeFinalizeProduction',
      'fetchProductDetailInfo',
      'getExpireDateData'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('Production', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'HIDE_OTHERS_DETAILS',
      'SET_EXPIRATION_DATE_CONTROL'
    ]),

    ...mapMutations('Global', ['TOGGLE_MENU_MINIMALIZE']),

    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('DocumentSettings', ['getDocumentFilterSettings']),

    ...mapActions('Stores', ['getStore']),

    isExpireDateControlEnabled (item) {
      const selectedsWithExpireDate = this.selectedList.filter(s => s.expirationDateControlIsEnabled && s.productionHeaderId === item.id)
      return selectedsWithExpireDate.length > 0
    },

    onSelectProduction (item) {
      if (item.isChecked) this.selectedList.push(item)
      else {
        this.selectedList = this.selectedList.filter(s => s.id !== item.id)
      }
    },

    onSelectProductionAll (detailData, isChecked) {
      if (isChecked) {
        detailData.forEach(detail => {
          this.selectedList.push(detail)
        })
      } else {
        detailData.forEach(detail => {
          const index = this.selectedList.findIndex(s => s.id === detail.id)
          this.selectedList.splice(index, 1)
        })
      }
    },

    async editExpireDate (production) {
      if (production) this.productionHeader = production
      const filteredList = this.selectedList.filter(s => s.productionHeaderId === production.id)
       const payload = {
        productionDetailIds: filteredList.map(i => i.id)
      }
      await this.getExpireDateData(payload)
      this.TOGGLE_MENU_MINIMALIZE(true)
      this.showExpireDateEdit = true
    },

    closeExpireDateScreen () {
      this.showExpireDateEdit = false
    },

    async getListWithStoreFilter ({list, dontSubmit}) {
      if (dontSubmit) this.storeFilter = list
      else {
        this.storeFilter = list
        await this.getProductionsList()
      }
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getProductionsList()
    },

    async changePage (page) {
      await this.getProductionsList(page)
    },

    async changePageSize (pageSize) {
      await this.getProductionsList(1, pageSize)
    },

    async getProductionsList (page = 1, pageSize = this.Page.size) {
      const res = await this.fetchProductionsList({
        storeIds: this.storeFilter.map(_ => _.id),
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        pageNumber: page,
        pageSize,
        showDrafts: this.showDrafts
      })
      return res
    },

    addNewProduction () {
      this.$router.push({
        name: 'NewProduction'
      })
    },

    editHeaderProduction (item) {
      this.$router.push({
        params: {
          id: item.id,
          item
        },
        name: 'EditProduction'
      })
    },

    removeHeader (item) {
      this.$router.push({
        params: {
          id: item.id,
          item
        },
        name: 'RemoveProduction'
      })
    },

    clearFilter () {
      this.$refs.productionStoreFilter.clearStoreFilter(false)
    },

    removeDetail (item) {
      this.$router.push({
        params: {
          id: item.productionHeaderId,
          detailId: item.id
        },
        name: 'RemoveProductionDetail'
      })
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_Production') this.showDrafts = i.isChecked
      })
    },

    async showDetailAction (item) {
      if (item.showDetail) {
        this.HIDE_DETAIL(item)
      } else {
        const res = await this.fetchProduct(item.id)
        this.checkExpireDateStatus(item)
        this.SHOW_DETAIL(res.data)
      }
    },

    checkExpireDateStatus (data) {
      this.getStore(data.storeId).then(res => {
        if (res) {
          this.SET_EXPIRATION_DATE_CONTROL({production: data, expirationDateControl: res.data.store.expirationDateControlIsEnabled})
        }
      })
    },

    addNewDetail (item) {
      const header = {
        id: item.id,
        code: item.code,
        storeId: item.storeId
      }
      this.$router.push({
        params: {
          header
        },
        name: 'NewProductionDetail'
      })
    },
    editDetailItem (item) {
      const production = this.findProductionHeader(item.productionHeaderId)
      const header = {
        id: item.productionHeaderId,
        code: production.code,
        storeId: production.storeId
      }
      this.$router.push({
        params: {
          id: item.id,
          header
        },
        name: 'EditProductionDetail'
      })
    },
    async updateDetailItem (item) {
      const payload = {
        quantity: item.quantity,
        productionDetailId: item.id
      }
      await this.updateProductionDetail(payload)
      await this.fetchProduct(item.productionHeaderId)
      const message = this.$t('Global.successDetailUpdateMessage', { field: this.$t('Dictionary.Production') })
      this.notifyShow({ message })
    },

    productionSaved: vueWaitLoader(async function (item) {
      const res = await this.makeFinalizeProduction(item.id)
      if (res) {
        this.getProductionsList()
        this.showExportPopup(item, true)
      }
    }, 'productionSaved'),

    showExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 7, isFinalizeAction)
    }
  },
  async mounted () {
    if (!this.hasTabPermission) return
    await this.setShowDraftStatus()
    await this.getDateFilterSettings()
    this.dates = getDateFilterSetting('Date_Production')
    await this.getProductionsList()
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
  },
  watch: {
    dates () {
      this.getProductionsList()
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep() .Header {
    z-index: $z-index-md !important;
  }
  :deep(.production .NestedDetail-row) {
    grid-template-columns: repeat(8, 1fr) 120px !important;
  }
</style>
