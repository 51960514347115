<template lang="pug">
form.Auth-form.Login-form.Form(
  @submit.stop.prevent="submit")

    h1.Auth-form-title {{ $t('Login.formHeaderTitle') }}

    .Form-item
      label.Form-item-label {{ $t('Login.formFieldEmail') }}
      .control
        input.txt(
        id="input-auth-login-email"
        name="email",
        :data-vv-as="$t('Login.formFieldEmail')",
        v-model="form.email",
        v-validate="'required|email|max:128'",
        :class="{ 'is-danger': veeErrors.has('email') }",
        type="text")
      p.Form-item-help.is-danger(
      v-show="veeErrors.has('email')")
        | {{ veeErrors.first('email') }}

    .Form-item.password(:class="{ 'is-show': isShowPassword }")
      label.Form-item-label {{ $t('Login.formFieldPassword') }}
      .control
        input.txt.txt-right-icon(
        id="input-auth-login-password"
        name="password",
        :data-vv-as="$t('Login.formFieldPassword')",
        v-model="form.password",
        v-validate="'required|min:6'",
        :class="{ 'is-danger': veeErrors.has('password') }",
        :type="isPasswordType")
        Button(
        id="btn-auth-login-show-password"
        type="button",
        variant="icon switch"
        :iconName="isShowPassword ? 'icon-login-eye' : 'icon-login-eye-closed'"
        :iconClass="isShowPassword ? 'icon-login-eye' : 'icon-login-eye-closed'"
        @click="showPassword")
      p.Form-item-help.is-danger(
      v-show="veeErrors.has('password')")
        | {{ veeErrors.first('password') }}

    .Form-item.tenantId(v-if="isSupportUser")
      label.Form-item-label {{ $t('Login.formFieldTenantId') }}
      .control
        input.txt.txt-right-icon(
        id="input-auth-login-tenantId"
        name="tenantId",
        :data-vv-as="$t('Login.formFieldTenantId')",
        v-model="form.tenantId",
        v-validate="'required'",
        :class="{ 'is-danger': veeErrors.has('tenantId') }",
        type="text")

      p.Form-item-help.is-danger(
      v-show="veeErrors.has('tenantId')")
        | {{ veeErrors.first('tenantId') }}


    .Form-item.alert.x2(v-show="authAlert")
      p {{ errorStatusMessage ? $t(`ApiErrorCode.${errorStatusMessage}`) : $t('Login.formAlert') }}

    .Form-item.x2
      Button(
      primary
      size="medium"
      type="submit",
      variant="full"
      id="login-button"
      :disabled="!isFormReady || isLoading")
        span(v-show="!isLoading") {{ $t('Login.formFieldSubmit') }}
        Loading(theme="disable", v-show="isLoading")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vee-validate'
import { isProd, isStaging } from '@/utils/helper'

export default {
  name: 'Login',

  data () {
    return {
      authAlert: false,
      typePassword: false,
      errorStatusMessage: null,
      form: {
        email: '',
        password: '',
        tenantId: this.$route.query.tenantId || ''
      }
    }
  },

  watch: {
    'form.email' () {
      this.authAlert = false
    },
    'form.password' () {
      this.authAlert = false
    },
    '$route.query' () {
      this.form.tenantId = this.$route.query.tenantId
    }
  },

  computed: {
    ...mapGetters('Auth', [
      'baseCurrencyId'
    ]),

    ...mapFields({
      flagsEmail: 'email',
      flagsPassword: 'password'
    }),

    isSupportUser () {
      return this.$route.name === 'SupportUser'
    },

    newUser () {
      let user = {
        EmailAddress: this.form.email,
        Password: this.form.password
      }
      if (this.isSupportUser) user.TenantId = this.form.tenantId
      return user
    },

    isFormReady () {
      return this.flagsEmail.valid &&
               this.flagsPassword.valid
    },

    isPasswordType () {
      return this.typePassword ? 'text' : 'password'
    },

    isShowPassword () {
      return this.typePassword
    },

    isLoading () {
      return this.$wait.is(['Auth/Login'])
    },

    isProd () {
      return isProd()
    },

    isStaging () {
      return isStaging()
    }
  },

  methods: {
    ...mapActions('Auth', [
      'Login'
    ]),

    ...mapActions('Global', [
      'getCurrencyList'
    ]),

    showPassword () {
      this.typePassword = !this.typePassword
    },

    async submit () {
      this.$validator.validateAll().then(async result => {
        if (!result) return
        let res = await this.Login(this.newUser)
        if (res.status === 200) {
          if (this.baseCurrencyId !== null) {
            await this.getCurrencyList()
            if (res.data.needsNewAgreementConfirmation && res.data.user.type === 3) { // user.type === 3 (Admin)
              this.$router.push({
                name: 'AgreeConfirm'
              })
            } else {
              this.$router.push({
                name: 'Dashboard'
              })
            }
          }
        } else {
          this.errorStatusMessage = res
          this.authAlert = true
        }
      })
    }
  }
}
</script>

<style lang="scss">

  .Login-form {
    .Form-item.alert {
      color: $color-warning;
      background-color: rgba($color-warning, 0.1);
      border-radius: $border-radius;
      border: 1px solid $color-warning;
      padding: 20px;
      margin-bottom: -20px;
    }
    .btn {
      height: $input-size;
    }
  }
</style>
