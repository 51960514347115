var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "Despatches",
      attrs: { id: `invoice-details-${_vm.headerIndex}` },
    },
    [
      _c(
        "div",
        {
          staticClass: "Despatches-single-table-header",
          attrs: { id: `invoice-details-header-${_vm.headerIndex}` },
        },
        [
          _c(
            "h6",
            {
              staticClass: "title u-textRight",
              attrs: { id: `invoice-${_vm.headerIndex}-detail-head-0` },
            },
            [_vm._v(_vm._s(_vm.$t("Despatches.tableColumn_DespatchDate")))]
          ),
          _c(
            "h6",
            {
              staticClass: "title u-textRight",
              attrs: { id: `invoice-${_vm.headerIndex}-detail-head-1` },
            },
            [_vm._v(_vm._s(_vm.$t("Despatches.tableColumn_DespatchNumber")))]
          ),
          _c(
            "h6",
            {
              staticClass: "title u-textRight",
              attrs: { id: `invoice-${_vm.headerIndex}-detail-head-2` },
            },
            [_vm._v(_vm._s(_vm.$t("Despatches.tableColumn_NetTotalAmount")))]
          ),
          _c(
            "h6",
            {
              staticClass: "title u-textRight",
              attrs: { id: `invoice-${_vm.headerIndex}-detail-head-3` },
            },
            [_vm._v(_vm._s(_vm.$t("Despatches.tableColumn_TaxTotalAmount")))]
          ),
          _c(
            "h6",
            {
              staticClass: "title u-textRight",
              attrs: { id: `invoice-${_vm.headerIndex}-detail-head-4` },
            },
            [_vm._v(_vm._s(_vm.$t("Despatches.tableColumn_GrossTotalAmount")))]
          ),
        ]
      ),
      _vm._l(_vm.tableData, function (despatch, index) {
        return _c(
          "div",
          {
            staticClass: "Despatches-item",
            class: { isOpened: despatch.showDetail, "small-size": true },
            attrs: { id: `invoice-${_vm.headerIndex}-detail-item-${index}` },
          },
          [
            _c(
              "div",
              {
                staticClass: "Despatches-header",
                attrs: {
                  id: `invoice-${_vm.headerIndex}-detail-item-date-${index}`,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "Despatches-header-col inv-date cell",
                    attrs: {
                      id: `invoice-${_vm.headerIndex}-detail-item-date-buffer-${index}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "body u-textRight",
                        attrs: {
                          id: `invoice-${_vm.headerIndex}-detail-item-date-text-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$options.filters.dateFormat(
                              despatch.despatchDate
                            ) || "-"
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Despatches-header-col inv-number cell",
                    attrs: {
                      id: `invoice-${_vm.headerIndex}-detail-item-inv-number-${index}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "body u-textRight",
                        attrs: {
                          id: `invoice-${_vm.headerIndex}-detail-item-inv-number-text-${index}`,
                        },
                      },
                      [_vm._v("   " + _vm._s(despatch.despatchNumber || "-"))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Despatches-header-col net cell",
                    attrs: {
                      id: `invoice-${_vm.headerIndex}-detail-item-net-${index}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "body ff-mono u-textRight",
                        attrs: {
                          id: `invoice-${_vm.headerIndex}-detail-item-net-text-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatCurrency")(despatch.netTotalAmount)
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Despatches-header-col tax cell",
                    attrs: {
                      id: `invoice-${_vm.headerIndex}-detail-item-tax-${index}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "body ff-mono u-textRight",
                        attrs: {
                          id: `invoice-${_vm.headerIndex}-detail-item-tax-text-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatCurrency")(despatch.taxTotalAmount)
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Despatches-header-col gross cell",
                    attrs: {
                      id: `invoice-${_vm.headerIndex}-detail-item-gross-${index}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "body ff-mono u-textRight",
                        attrs: {
                          id: `invoice-${_vm.headerIndex}-detail-item-gross-text-${index}`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatCurrency")(despatch.grossTotalAmount)
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "Despatches-header-col description" },
                  [
                    despatch.description
                      ? _c(
                          "Button",
                          {
                            staticClass:
                              "show-MiniPopup relative width-max-content",
                            attrs: {
                              size: "small",
                              variant: "info",
                              id: `despatch-header-info-button-${index}`,
                              iconName: "icon-global-info",
                              justIcon: true,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "MiniPopup-right-primary m-top-15 c-dark",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(despatch.description)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "Despatches-header-col action",
                    attrs: {
                      id: `invoice-${_vm.headerIndex}-detail-item-action-${index}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "body",
                        attrs: {
                          id: `invoice-${_vm.headerIndex}-detail-item-action-body-${index}`,
                        },
                      },
                      [
                        _c("Button", {
                          staticClass: "btn-detail-switch",
                          attrs: {
                            variant: "icon switch",
                            id: `btn-despatches-open-header-${despatch.id}`,
                            iconName: "icon-arrow-down",
                            iconClass: "icon-arrow-down",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showDespatchDetail(despatch)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            despatch.showDetail
              ? _c(
                  "div",
                  {
                    staticClass: "Despatches-details",
                    attrs: {
                      id: `invoice-${_vm.headerIndex}-despatch-detail-item-${index}`,
                    },
                  },
                  [
                    _c("div", { staticClass: "table-scroll" }, [
                      _c(
                        "table",
                        {
                          attrs: {
                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-table-${index}`,
                          },
                        },
                        [
                          _c(
                            "thead",
                            {
                              staticClass: "Despatches-details-head",
                              attrs: {
                                id: `invoice-${_vm.headerIndex}-despatch-detail-item-table-head-${index}`,
                              },
                            },
                            [
                              _c(
                                "tr",
                                {
                                  staticClass: "Despatches-details-head-row",
                                  attrs: {
                                    id: `invoice-${_vm.headerIndex}-despatch-detail-item-table-row${index}`,
                                  },
                                },
                                [
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col stockItemName",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-stock-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-stock-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_StockItemName"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col quantity u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-quantity-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-quantity-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_Quantity"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col unitCode u-textCenter",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-unit-code-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-unit-code-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_UnitCode"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col netUnitPrice u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-net-unit-price-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-net-unit-price-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_NetUnitPrice"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col baseQuantity u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-base-quantity-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-base-quantity-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_BaseQuantity"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col baseUnit",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-base-unit-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-base-unit-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_BaseUnit"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col basePrice u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-base-price-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-base-price-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_BasePrice"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col discount1 u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-discount-1-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-discount-1-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_Discount1"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col discount2 u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-discount-2-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-discount-2-text${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_Discount2"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col discountedUnitPrice u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-discounted-unit-price-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-discounted-unit-price-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_DiscountedUnitPrice"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col discountedBaseUnitPrice u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-discounted-base-unit-price-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-discounted-base-unit-price-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_DiscountedBaseUnitPrice"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col taxRatio u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-tax-ratio-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-tax-ratio-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_TaxRatio"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col discountedNetUnitPrice u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-discounted-net-unit-price-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-discounted-net-unit-price-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_DiscountedNetUnitPrice"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col netAmount u-textRight",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-net-amount-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-net-amount-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_NetAmount"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "Despatches-details-head-col storeName",
                                      attrs: {
                                        id: `invoice-${_vm.headerIndex}-despatch-detail-item-store-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          attrs: {
                                            id: `invoice-${_vm.headerIndex}-despatch-detail-item-store-text-${index}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Despatches.detail_TableColumn_StoreName"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "tbody",
                            { staticClass: "Despatches-details-body" },
                            _vm._l(
                              despatch.despatchDetailList,
                              function (detail, despatchIndex) {
                                return _c(
                                  "tr",
                                  {
                                    staticClass: "Despatches-details-body-row",
                                    attrs: { tabindex: "0" },
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col stockItemName",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-stock-item-${despatchIndex}`,
                                        },
                                      },
                                      [_vm._v(_vm._s(detail.stockItem.name))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col quantity ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-quantity-${despatchIndex}`,
                                        },
                                      },
                                      [_vm._v(_vm._s(detail.quantity))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col baseUnitCode u-textCenter",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-base-unit-code-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.findUnit(detail.unitId).name
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col netUnitPrice ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-net-unit-price-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              detail.unitPrice
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col baseQuantity ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-base-quantity-${despatchIndex}`,
                                        },
                                      },
                                      [_vm._v(_vm._s(detail.baseQuantity))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col baseUnitCode",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-base-unit-code-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(detail.stockItem.baseUnitCode)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col basePrice ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-base-price-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              detail.netBaseUnitPrice
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col discount1 ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-discount-1-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "% " +
                                            _vm._s(
                                              _vm._f("percentFixed")(
                                                detail.discount1
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col discount2 ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-discount-2-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "% " +
                                            _vm._s(
                                              _vm._f("percentFixed")(
                                                detail.discount2
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col discountedUnitPrice ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-discounted-unit-price-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              detail.discountedUnitPrice
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col DiscountedBaseUnitPrice ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-discounted-base-unit-prie-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              detail.discountedBaseUnitPrice
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col taxRatio ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-tax-ratio-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "% " +
                                            _vm._s(
                                              _vm._f("percentFixed")(
                                                detail.taxRatio
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col discountedNetUnitPrice ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-discounted-net-unit-price-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              detail.discountedNetUnitPrice
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col netAmount ff-mono u-textRight",
                                        attrs: {
                                          id: `invoice-${_vm.headerIndex}-despatch-${index}-detail-col-net-amount-${despatchIndex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              detail.netAmount
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "Despatches-details-body-col storeName",
                                      },
                                      [_vm._v(_vm._s(detail.storeName))]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }