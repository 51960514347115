var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select right-date m-right-10" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "RefundDatepicker",
                disabled: _vm.isLoading || _vm.$wait.is("pageLoading"),
                isLoading: _vm.$wait.is("getDateFilterSettings"),
                range: true,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                id: "btn-despatch-new",
                type: "button",
                primary: "",
                size: "medium",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: { click: _vm.addRefund },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Despatches.addRefundButton")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("SupplierFilter", {
            ref: "despatchRefundSupplierFilter",
            on: { submitFilter: _vm.getListWithSupplierFilter },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "show-drafts m-left-15" },
            [
              _c("CustomCheckbox", {
                staticClass: "popup-item-check",
                attrs: {
                  id: "checkbox-refund-show-drafts",
                  checked: _vm.showDrafts,
                  label: _vm.$t("Global.showDrafts"),
                },
                on: { change: _vm.changeShowDraftsStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c(
            "Button",
            {
              attrs: {
                id: "btn-despatch-selected-save",
                type: "button",
                primary: "",
                size: "small",
                iconName: "icon-btn-success",
                iconClass: "icon-btn-success",
                disabled: _vm.isSelectedEmpty || _vm.checkSameSupplier,
              },
              on: { click: _vm.finalizeSelectedDespatches },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.SaveSelectedDespatchesButton"))
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.checkSameSupplier,
                      expression: "checkSameSupplier",
                    },
                  ],
                  staticClass: "alert",
                },
                [
                  _vm._v(_vm._s(_vm.$t("Despatches.UmustSelectOneSupplier"))),
                  _c("div", { staticClass: "alert-arrow" }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          _vm.refund_IsEmpty && !_vm.isLoading
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Despatches.emptyRefundState_Title"),
                  description: _vm.$t(
                    "Despatches.emptyRefundState_Description"
                  ),
                  buttonText: _vm.$t(
                    "Despatches.refund_popup_FinishCreateButton"
                  ),
                },
                on: { emptyAddButton: _vm.addRefund },
              })
            : _vm._e(),
          !_vm.refund_IsEmpty && !_vm.isLoading
            ? _c("RefundTable", {
                attrs: {
                  tableData: _vm.refund_List,
                  showDetailAction: _vm.despatchDetail,
                  editAction: _vm.despatchHeaderEdit,
                  removeItemAction: _vm.despatchRemove,
                  selectedList: _vm.selectedList,
                  showExportPopup: _vm.showExportPopup,
                  checkSameSupplier: _vm.checkSameSupplier,
                },
                on: {
                  delegateCheckItem: _vm.handleCheckbox,
                  delegateCheckAllItem: _vm.toggleAll,
                  printPdf: _vm.showExportPopup,
                  editExpireDate: _vm.editExpireDate,
                },
              })
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "Modal",
                {
                  attrs: {
                    isOpen: _vm.showBatchDetail,
                    showCloseButton: false,
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "modal-header" }, [
                      _c("div", { staticClass: "modal-header-left" }, [
                        _c("div", { staticClass: "refund" }),
                      ]),
                    ]),
                  ]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c("NestedTable", {
                        staticClass: "custom-nested-table",
                        attrs: {
                          fields: _vm.refundBatchHeaderFields,
                          detailFields: _vm.refundBatchDetailFields,
                          data: _vm.batchList,
                          actions: ["export"],
                          usePortalFooter: true,
                          isActionButtonsVisible: true,
                        },
                        on: {
                          showDetailAction: _vm.showBatchDetailAction,
                          showExportPopup: _vm.showBatchDetailExportPopup,
                        },
                      }),
                      _c("portal", {
                        attrs: { to: "givenQuantity" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (detail) {
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  { staticClass: "control quantity-input" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            max: _vm.$getConst(
                                              "MAX_DIGITS_FOR_QUANTITY"
                                            ),
                                            greater_than: 0,
                                          },
                                          expression:
                                            "{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}",
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: detail.row.givenQuantity,
                                          expression:
                                            "detail.row.givenQuantity",
                                        },
                                      ],
                                      staticClass: "txt no-spinners",
                                      class: {
                                        "is-danger": _vm.veeErrors.has(
                                          `refund${detail.rowIndex}.givenQuantity`
                                        ),
                                      },
                                      attrs: {
                                        name: "givenQuantity",
                                        "data-vv-as": _vm.$t(
                                          "Despatches.detail_TableColumn_Quantity"
                                        ),
                                        type: "number",
                                        step: "any",
                                      },
                                      domProps: {
                                        value: detail.row.givenQuantity,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.updateGivenBatchQuantity(
                                            $event,
                                            detail.row
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            detail.row,
                                            "givenQuantity",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            },
                          },
                        ]),
                      }),
                      _c("portal", {
                        attrs: { to: "warehouseTime" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (detail) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      detail.row.warehouseTime?.substr(0, 5) ||
                                        "-"
                                    )
                                  ),
                                ]),
                              ])
                            },
                          },
                        ]),
                      }),
                      _c("portal", {
                        attrs: { to: "shipmentTime" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (detail) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      detail.row.shipmentTime?.substr(0, 5) ||
                                        "-"
                                    )
                                  ),
                                ]),
                              ])
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "div",
                      { staticClass: "batch-footer" },
                      [
                        _c(
                          "Button",
                          {
                            staticClass: "m-right-10",
                            attrs: {
                              id: "transfer-batch-detail-cancel",
                              size: "small",
                              secondary: "",
                              inline: "",
                            },
                            on: { click: _vm.closeModal },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("Global.Cancel"))),
                            ]),
                          ]
                        ),
                        _c(
                          "Button",
                          {
                            attrs: {
                              id: "transfer-batch-detail-save",
                              size: "small",
                              primary: "",
                              inline: "",
                            },
                            on: { click: _vm.checkBatchValidation },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Global.Save")))])]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c("router-view", {
            on: {
              getList: _vm.getRefundListWithParams,
              takePrint: _vm.showExportPopup,
              showDrafts: function ($event) {
                _vm.showDrafts = true
              },
            },
          }),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }