export default function getFields($t) {
  return [
    {
      name: 'supplierName',
      is_visible: true,
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_SupplierName')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_SupplierName')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_StockItemName')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_StockItemName')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_UnitName')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_UnitName')
      }
    },
    {
      name: 'priceType',
      is_visible: true,
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceType')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceType')
      }
    },

    {
      name: 'unitPrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_UnitPrice')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_UnitPrice')
      }
    },
    {
      name: 'newUnitPrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewUnitPrice')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewUnitPrice')
      }
    },
    {
      name: 'discount1',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_Discount1')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_Discount1')
      }
    },
    {
      name: 'newDiscount1',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewDiscount1')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewDiscount1')
      }
    },
    {
      name: 'discount2',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_Discount2')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_Discount2')
      }
    },
    {
      name: 'newDiscount2',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewDiscount2')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewDiscount2')
      }
    },
    {
      name: 'startDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_StartDate')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_StartDate')
      }
    },
    {
      name: 'newStartDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewStartDate')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewStartDate')
      }
    },
    {
      name: 'endDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_EndDate')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_EndDate')
      }
    },
    {
      name: 'newEndDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewEndDate')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewEndDate')
      }
    },
    {
      name: 'leadTime',
      is_visible: true,
      type: 'number',
      get short_title() {
        return $t('Global.leadTime')
      },
      get title() {
        return $t('Global.leadTime')
      }
    },
    {
      name: 'newLeadTime',
      is_visible: true,
      type: 'number',
      get short_title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewLeadTime')
      },
      get title() {
        return $t('PriceQuoteExcel.tableColumn_PriceQuoteExcel_NewLeadTime')
      }
    }
  ]
}
