<template lang="pug">
Page(@getInfo="getPageInfo")
  template(slot="header-title")
    .header-title-left
      .header-day-message
        DayMessage
      .header-custom-multiselect
        storeSelect(
          v-if="selectedType.id !== 1"
          :showSelectedName="true"
          :userDefaultStoreId="UserInfo.defaultStoreId"
          @submitFilter="submitFilter"
        )
        storeGroupSelect(
          v-if="selectedType.id === 1"
          @submitFilter="submitStoreGroupFilter"
          @setDefaultStoreGroup="setDefaultStoreGroup"
        )

  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="typeList"
      :selectedItem="selectedType"
      @delegateChangeSelectedItem="changeTab"
      :useUpperCase="true"
    )
  template(v-if="hasPermissionDashboard && selectedType.id === 0" slot="content-center")
      TableLoading(v-if="isLoading")
      .Dashboard(v-else-if="!isEmpty && !isLoading")
        .container-custom
          .col-full(v-if="selectedType.id === 0")
            .toolbar
              .tabs
                a.report-tab(
                id="option-store-period-type-1"
                :class="{isSelected: periodType === 1}"
                @click="periodFilter(1)"
                ) {{ $t('Dashboard.store_period_selection-weekly') }}
                a.report-tab(
                id="option-store-period-type-2"
                :class="{isSelected: periodType === 2}"
                @click="periodFilter(2)"
                ) {{ $t('Dashboard.store_period_selection-monthly') }}
              
              CustomCheckbox.show-with-other-expense(
                id="checkbox-recipes-show-passive"
                v-model="showWithOtherExpense"
                :label="$t('Dashboard.showWithOtherExpense')"
                @change="getPageData")
            
          .col-full
            Reports(:selectedType="selectedType.type" @getSummary="periodFilter(periodType)" :periodType="periodType")
        .col-half.p-right-15
          .dashboard-bottom
            SupplierDebt
        .col-half.p-left-15
          .dashboard-bottom
            Stock
  template(v-if="hasPermissionDashboard && selectedType.id === 1" slot="content-center")
    ForecastTable
    // Backend user endpointine bir koşul ekledikten sonra emptyState aktif hale gelecektir.
    // EmptyState.emptyState(v-else
      iconName="empty-state-forecasting"
      :title="$t('Dashboard.emptyState_salesForecasting_title')"
      :description="$t('Dashboard.emptyState_salesForecasting_description')"
      :buttonText="$t('Dashboard.emptyState_emptyState_salesForecasting_ButtonText')",
      :showIcon="false"
      @emptyAddButton="getPageInfo")

  template(v-if="hasPermissionRefrigerator && selectedType.id === 2" slot="content-center")
    Devices
  
  template(v-if="!hasPermissionDashboard" slot="content-center")
    EmptyState(
      :title="$t('Dashboard.No_Permission_Title')"
      :description="$t('Dashboard.No_Permission_Description')"
    )
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Reports from './reports'
import SupplierDebt from './supplier-debt'
import ForecastTable from './forecast-table'
import Stock from './stock'
import DayMessage from './components/dayMessage'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import storeSelect from './components/storeSelect'
import storeGroupSelect from './components/storeGroupSelect'
import Devices from './devices'

export default {
  name: 'Dashboard',

  components: {
    Reports,
    SupplierDebt,
    Stock,
    DayMessage,
    storeSelect,
    storeGroupSelect,
    ToggleOverGroups,
    ForecastTable,
    Devices
  },

  data () {
    return {
      storeIds: [],
      storeGroupId: null,
      periodType: 1,
      selectedType: {},
      showWithOtherExpense: false,
      salesForecasting: null
    }
  },

  async created () {
    this.$wait.start('isLoading')
    this.selectedType = this.typeList[0]
    if (!this.hasPermissionRefrigerator) this.typeList.pop()
    this.storeIds.push(this.UserInfo.defaultStoreId)
    this.getPageData()
  },

  async mounted () {
    await this.fetchIntegrationProductList()
    await this.fetchUser()
    this.salesForecasting = this.IntegrationProductList.find(i => i.name === 'Sales Forecasting')
    this.$wait.end('isLoading')
  },

  computed: {
    ...mapGetters('Dashboard', [
      'isEmpty',
      'SalesForecastData',
      'WeatherForecastData'
    ]),

    ...mapGetters('Settings', [
      'checkPermission'
    ]),

    ...mapGetters('Integration', ['IntegrationProductList']),  


    ...mapGetters('Settings', [
      'UserInfo'
    ]),

    ...mapGetters('Dashboard', ['ForecastSummaryMonthly', 'ForecastSummaryWeekly']),

    isForecastUsed() {
      if (this.ForecastSummaryMonthly === null && this.ForecastSummaryWeekly === null) return false
      else return true
    },

    typeList() {
      return [
        {
          name: this.$t('Dashboard.incomeInformation'),
          type: 'incomeStats',
          id: 0
        },
        {
          name: this.$t('Dashboard.forecastings'),
          type: 'salesForecasting',
          id: 1
        },
        {
          name: this.$t('Dashboard.devices'),
          type: 'devices',
          id: 2
        }
      ]
    },

    isTypeForecasting () {
      return this.selectedType === 'salesForecasting'
    },
    isTypeIncomeInformation () {
      return this.selectedType === 'incomeStats'
    },

    isLoading () {
      return this.$wait.is(['Dashboard/getData', 'setUserLang', 'getStoreList', 'fetchUser', 'isLoading'])
    },

    hasPermissionDashboard () {
      return this.checkPermission('DashboardMenu')
    },
    hasPermissionRefrigerator () {
      return this.checkPermission('RefrigeratorDashboard')
    }
  },

  methods: {
    ...mapActions('Dashboard', [
      'getData', 'getForecastData'
    ]),

    ...mapActions('Integration', ['fetchIntegrationProductList']),

    ...mapActions('Reports', [
      'getSummary'
    ]),
    ...mapActions('Settings', [
      'fetchUser'
    ]),

    getPageInfo () {
      this.$router.push({
        name: 'SalesForecasting',
        params: {
          IntegrationProductId: this.salesForecasting.id
        }
      })
    },

    async getPageData () {
      const params = {storeIds: this.storeIds, withOtherExpense: this.showWithOtherExpense}
      await this.getData(params)
    },

    getForecasts () {
      const params = {storeGroupId: this.storeGroupId}
      this.getForecastData(params)
    },

    submitFilter (item) {
      this.storeIds = []
      item.list.forEach(i => this.storeIds.push(i.id))
      this.getPageData()
    },

    setDefaultStoreGroup (item) {
      this.storeGroupId = item.id
      this.getForecasts()
    },

    submitStoreGroupFilter (item) {
      this.storeGroupId = item.id
      this.getForecasts()
    },

    changeTab (item) {
      this.selectedType = item
    },

    periodFilter (period) {
      this.periodType = period
      this.getSummary({storeIds: this.storeIds, periodType: this.periodType, withOtherExpense: this.showWithOtherExpense})
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/stylesheet/config/mixin";

  :deep(.toolbar) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Dashboard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .container-custom {
      width: 100%;
      padding: 20px 25px 40px 10px;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
      background-color: $color-white;
      border-radius: $border-higher-radius;
    }
    .col-full {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-half {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      @media (max-width: 992px) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .dashboard-bottom {
        margin-top: 30px;
        padding: 30px 33px 15px;
        background-color: $color-white;
        border-radius: $border-higher-radius;
        @media (max-width: 600px) {
          padding: 25px 10px;
        }
      }
    }
    .tabs {
      padding-left: 15px;
      .report-tab {
        color: $color-light;
        margin-right: 20px;
        cursor: pointer;
        font-size: $font-size-big;
        &.isSelected {
          color: $color-sidebar-dark;
          pointer-events: none;
          border-bottom: 2px solid $color-success;
        }
      }
    }
  }
  .header-title-left {
    display: flex;
    align-items: center;
    .header-custom-multiselect {
      min-width: 386px;
      border-left: 1px solid #ebf0f6;
      padding-left: 15px;
      margin-left: 30px;
      @media (max-width: 992px) {
        min-width: 210px;
      }
    }
  }
  .col-reports {
    width: 100%;
    .emptyState {
      border-radius: 10px;
    }
  }

  :deep() .Header .left .title {
    display: none;
  }
  .integration-sales-forecasting-content {
    margin-top: 3%;
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: $font-size-bigger;
      margin-top: 20px;
      font-weight: 420;
      color: #191818;
    }
    &-description {
      color: #91979C;
      font-weight: 420;
      font-size: $font-size-normal;
    }
    &-phone {
      font-weight: $font-weight-semi-bold;
      margin-top: 20px;
      font-size: $font-size-bigger;
      display: flex;
      align-items: center;
    }
  }

  .refrigerator {
    width: 100%;
    display: flex;
    &-left {
      width: 70%;
      height: 300px;
      background: blue;
      display: flex;
      flex-direction: column;
      &-top {
        width: 100%;
        height: 40%;
        background: yellow;

      }
      &-bottom {
        width: 100%;
        height: 60%;
        background: purple;
      }
    }
    &-right {
      width: 30%;
      height: 300px;
      background: black;
    }
  }
</style>