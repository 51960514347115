<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="Settings"
    )

  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="headerGroupItems"
      :useUpperCase="false"
      :selectedItem="selectedHeader"
      @delegateChangeSelectedItem="changeHeader"
      selectedKey="headerValue"
    )

  template(slot="content-center")
    TableLoading(v-if="isFetchingData")

    div(v-else="!isFetchingData")
      TableView.m-top-15(
        :componentName="'exportSettings'"
        :fields="headerFields"
        :data="filteredDetails"
        :readOnly="true"
      )

      portal(to="module")
        div(slot-scope="row")
          span {{ $t('ExportSettings.Detail_Field_' + row.key) }}

      portal(to="active")
        .on-button(slot-scope="row")
          CustomRadio(
            :checked="row.isChecked"
            :disabled="isSettingData"
            label=""
            @change="changeExportSettings(row, true)"
          )

      portal(to="disabled")
        .off-button(slot-scope="row")
          CustomRadio(
            :checked="!row.isChecked"
            :disabled="isSettingData"
            label=""
            @change="changeExportSettings(row, false)"
          )

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import getFields from './fields/index'
import getExportHeaders from './constant/get-export-settings-headers'
import { vueWaitLoader } from '@/utils/baseOperations'

import ToggleOverGroups from '@/view/global/toggle-over-groups'

export default {
  name: 'export-settings',

  components: {
    ToggleOverGroups
  },

  data () {
    return {
      headerFields: getFields(this.$t),
      headerList: getExportHeaders(),
      selectedHeader: null
    }
  },

  computed: {
    ...mapGetters('Settings', ['pageHeaderLinks']),
    ...mapGetters('ExportSettings', ['exportSettingList']),

    isFetchingData () {
      return this.$wait.is(['getExportFilterSettings'])
    },

    isSettingData () {
      return this.$wait.is(['changeExportSettings'])
    },

    headerGroupItems () {
      return Object.keys(this.headerList).map(headerValue => {
        return {
          name: this.$t('ExportSettings.Header_Field_' + headerValue),
          headerValue
        }
      })
    },

    filteredDetails () {
      if (!this.selectedHeader || this.selectedHeader.headerValue === 'All') return this.exportSettingList
      else return this.exportSettingList.filter(detail => this.headerList[this.selectedHeader.headerValue].some(h => h === detail.key))
    }
  },

  methods: {
    ...mapActions('ExportSettings', ['getExportFilterSettings', 'setExportSettings']),
    ...mapMutations('ExportSettings', ['SET_CHECKED_STATUS']),

    changeHeader (item) {
      this.selectedHeader = item
    },

    changeExportSettings: vueWaitLoader(async function (item, status) {
      if (item.isChecked === status) return
      this.SET_CHECKED_STATUS({item: item, checkStatus: status})
      await this.setExportSetting(item)
    }, 'changeExportSettings'),

    async setExportSetting (item) {
      const settingData = this.exportSettingList.find(i => i.key === item.key)
      const payload = {
        settings: {
          [settingData.key]: settingData.isChecked ? 'True' : 'False'
        }
      }
      await this.setExportSettings(payload)
    }
  },

  async mounted () {
    await this.getExportFilterSettings()
    this.changeHeader(this.headerGroupItems[0])
  }
}
</script>

<style scoped lang="scss">

</style>
