<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-content")
    Button(
    size="medium"
    primary
    id="btn-payment-type-add"
    type="button"
    v-show="!paymentType_IsEmpty && !isLoading"
    iconName="icon-global-add"
    iconClass="icon-global-add"
    @click="itemNew")
      span {{ $t('PaymentType.addPaymentTypeButtonLabel') }}

  template(slot="content-center")
    TableLoading(v-if="isLoading")
    EmptyState(
    v-if="paymentType_IsEmpty && !isLoading",
    :title="$t('PaymentType.emptyState_Title')",
    :description="$t('PaymentType.emptyState_Description')",
    :buttonText="$t('PaymentType.emptyState_Button')",
    @emptyAddButton="itemNew")

    .Boxlist(v-if="!paymentType_IsEmpty && !isLoading")
      .Boxlist-item(
      v-for="paymentType in paymentType_List")
        .Boxlist-actions
          ActionButton(
            :data="['edit', 'remove']"
            :item="paymentType"
            @onItemEdit="itemEdit(paymentType.id)"
            @onItemDelete="itemRemove(paymentType)"
          )
        .Boxlist-body
          h3.title {{ paymentType.name }}
    router-view
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PaymentType',

  created () {
    this.get_PAYMENTTYPE_LIST()
  },

  computed: {
    ...mapGetters('PaymentTypes', ['paymentType_List', 'paymentType_IsEmpty']),
    ...mapGetters('Payments', ['pageHeaderLinks']),

    isLoading () {
      return this.$wait.is(['get_PAYMENTTYPE_LIST'])
    }
  },

  methods: {
    ...mapActions('PaymentTypes', [
      'get_PAYMENTTYPE_LIST'
    ]),

    itemNew () {
      this.$router.push({
        name: 'PaymentTypeNew'
      })
    },

    itemEdit (itemId) {
      this.$router.push({
        name: 'PaymentTypeEdit',
        params: {
          id: itemId
        }
      })
    },

    itemRemove (item) {
      this.$router.push({
        name: 'PaymentTypeRemove',
        params: {
          id: item.id,
          item
        }
      })
    },
  }
}
</script>
