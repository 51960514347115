var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.requestHeaderLinks,
              translationGroup: "Request",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-left-search" },
        [
          _c("Search", {
            staticClass: "search-box",
            attrs: {
              componentName: "requestedOrders",
              suggestionValue: "name",
              isSuggestionLoading: _vm.isSuggestionLoading,
              suggestions: _vm.selectedStockItem,
              customSuggestionPlaceHolder: _vm.$t("Request.search_Placeholder"),
              customSuggestionTitle: _vm.$t(
                "DirectOrders.orders_search_SuggestionTitle"
              ),
            },
            on: {
              onChangeSearchValue: _vm.onChangeSearch,
              onChangeQueryItem: _vm.setStockItem,
              clearSearch: _vm.clearSearch,
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-dropdown" },
        [
          _c("CustomDatepicker", {
            staticClass: "m-right-15",
            attrs: { range: false, useFilterReset: true, pickerType: "manuel" },
            on: { resetDateFilter: _vm.resetDateFilter },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              attrs: {
                id: "btn-add-new-order-request-detail",
                primary: "",
                size: "medium",
              },
              on: { click: _vm.addNewDetail },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Global.addDetail")))])]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("storeFilter", {
            ref: "requestedOrderStoreListFilter",
            on: { submitFilter: _vm.getListWithStoreFilter },
          }),
          _c("itemGroupList", {
            ref: "requestedOrderGroupFilter",
            attrs: { groupType: "itemGroup" },
            on: { submitFilter: _vm.getListWithGroupFilter },
          }),
          _c(
            "Button",
            {
              staticClass: "clear-filter-button border-gray-1 p-0 width-auto",
              on: { click: _vm.clearFilter },
            },
            [
              _c("Icon", {
                staticClass: "icon-clear-filter",
                attrs: { name: "icon-clear-filter" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _c("CustomMultiselect", {
            ref: "selectedSupplier",
            staticClass: "toolbar-filters__item",
            attrs: {
              id: "order-request-supplier-select",
              items: _vm.supplierList,
              selected: _vm.selectedSupplier,
              type: "radio",
              showAll: false,
              isDisabled: !_vm.selectedOrders.length,
              label: _vm.$t("Request.supplierFilter"),
              popupHeader: _vm.$t("Request.selectSupplier"),
              unselectedText: _vm.$t("Request.toolbar_Supplier_Unselected"),
              allLabel: _vm.$t("Request.toolbar_Stores_AllLabel"),
              buttonText: _vm.$t("Request.toolbar_SupplierFilter"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckItem: _vm.onChangeSupplierFilter,
              delegateSubmitItems: function ($event) {
                return _vm.getPriceQuote(true)
              },
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.hideReasonPopup,
                  expression: "hideReasonPopup",
                },
              ],
              staticClass: "popup-container",
            },
            [
              _c(
                "Button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEmptyOrders,
                      expression: "!isEmptyOrders",
                    },
                  ],
                  staticClass: "relative",
                  attrs: {
                    id: "btn-orders-passive-order",
                    secondary: "",
                    size: "small",
                    disabled: _vm.selectedOrders.length === 0,
                  },
                  on: { click: _vm.showPassiveReasonForm },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("Orders.makePassive")))])]
              ),
              _vm.showReasonButtonPopup
                ? _c(
                    "form",
                    {
                      staticClass: "order-passive-description-right-MiniPopup",
                      attrs: { id: "order-description-reason-form" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.makeOrderPassive.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "order-description-MiniPopup-label required",
                          attrs: { id: "order-description-reason-label" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Orders.Reason_Description_Input_Label")
                            )
                          ),
                        ]
                      ),
                      _c("textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true, max: 128 },
                            expression: "{required: true, max: 128}",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reasonDescription,
                            expression: "reasonDescription",
                          },
                        ],
                        ref: "reason",
                        staticClass: "txt order-description-MiniPopup-input",
                        class: { "is-danger": _vm.veeErrors.has(`reason`) },
                        attrs: {
                          name: "reason",
                          autocomplete: "off",
                          id: "order-description-reason-input",
                        },
                        domProps: { value: _vm.reasonDescription },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.reasonDescription = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "Button",
                        {
                          staticClass: "m-top-15 m-left-0",
                          attrs: {
                            primary: "",
                            size: "medium",
                            type: "submit",
                            variant: "full",
                            id: "order-description-reason-submit",
                            disabled:
                              _vm.selectedOrders.length === 0 ||
                              _vm.isMakeRequestApprove ||
                              !_vm.isPriceQuoteActive,
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("Orders.makePassive"))),
                          ]),
                          _vm.isMakeRequestApprove
                            ? _c("Loading", { attrs: { theme: "disable" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmptyOrders,
                  expression: "!isEmptyOrders",
                },
              ],
              staticClass: "m-left-10",
              attrs: {
                secondary: "",
                size: "small",
                id: "btn-orders-get-price-quote",
                type: "button",
                disabled:
                  _vm.selectedOrders.length === 0 || _vm.isFetchingPriceQuato,
              },
              on: {
                click: function ($event) {
                  return _vm.getPriceQuote(false)
                },
              },
            },
            [
              _vm.isFetchingPriceQuato
                ? _c("Loading", { attrs: { theme: "disable" } })
                : _vm._e(),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("Orders.getPriceQuoteButton"))),
              ]),
            ],
            1
          ),
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmptyOrders,
                  expression: "!isEmptyOrders",
                },
              ],
              staticClass: "m-left-10",
              attrs: {
                primary: "",
                size: "small",
                id: "btn-orders-create-order",
                disabled:
                  _vm.selectedOrders.length === 0 ||
                  _vm.isMakeRequestApprove ||
                  !_vm.isFormValidChecker,
              },
              on: { click: _vm.createRequests },
            },
            [
              _c("Icon", { attrs: { name: "icon-btn-success" } }),
              _vm.isMakeRequestApprove
                ? _c("Loading", { attrs: { theme: "disable" } })
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.$t("Orders.createOrdersButton")))]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                [
                  _vm.isApprovedOrder
                    ? [
                        !_vm.isFetchingApprovedData
                          ? _c("NestedTable", {
                              attrs: {
                                fields: _vm.requestedApprovedOrderFields,
                                detailFields:
                                  _vm.requestedApprovedOrderDetailFields,
                                data: _vm.approvedRequestOrders,
                              },
                              on: { showDetailAction: _vm.showDetail },
                            })
                          : _vm._e(),
                        _c("portal", {
                          attrs: { to: "maxApprovalLevel" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (header) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c("ApprovalLevels", {
                                        attrs: {
                                          maxApprovalLevel:
                                            header.row.maxApprovalLevel,
                                          currentApprovalLevel:
                                            header.row.currentApprovalLevel,
                                          approvalStatus:
                                            header.row.approvalStatus,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1406434774
                          ),
                        }),
                        _c("portal", {
                          attrs: { to: "approvalStatus" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (header) {
                                  return _c("div", {}, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getApprovalStatus(
                                            header.row.approvalStatus
                                          )
                                        )
                                      ),
                                    ]),
                                  ])
                                },
                              },
                            ],
                            null,
                            false,
                            4057542353
                          ),
                        }),
                      ]
                    : _vm._e(),
                  _vm.isFetchingList || _vm.isPageLoading
                    ? _c("TableLoading")
                    : _vm._e(),
                  _vm.isEmptyOrders && !_vm.isFetchingList && !_vm.isPageLoading
                    ? _c("EmptyState", {
                        attrs: {
                          title: _vm.$t("Orders.emptyState_NotFound_Title"),
                          description: _vm.$t(
                            "Orders.emptyState_NotFound_Description"
                          ),
                        },
                      })
                    : _vm._e(),
                  !_vm.isEmptyOrders &&
                  !_vm.isFetchingList &&
                  !_vm.isPageLoading &&
                  !_vm.isApprovedOrder
                    ? [
                        _c(
                          "div",
                          { staticClass: "Orders" },
                          [
                            _c("TableView", {
                              attrs: {
                                fields: _vm.requestedOrderField,
                                data: _vm.orders,
                                readOnly: true,
                                selectable: "checkbox",
                                selectableAll: "checkbox",
                                selectedRows: _vm.selectedOrders,
                                uniqueIdentifier: "id",
                                isRowClickable: false,
                              },
                              on: {
                                delegateOnItemSelect: _vm.toggleSelectOrder,
                                showAllSelectedItems: _vm.toggleAll,
                              },
                            }),
                            _c("portal", {
                              attrs: { to: "description" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          row.description
                                            ? _c(
                                                "Button",
                                                {
                                                  staticClass:
                                                    "show-MiniPopup relative width-max-content btn-info",
                                                  attrs: {
                                                    id: "order-approval-header-info-button",
                                                    iconName:
                                                      "icon-global-info",
                                                    justIcon: true,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "MiniPopup-right-primary m-top-15 c-dark",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            row.description
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                696239873
                              ),
                            }),
                            _c("portal", {
                              attrs: { to: "requestQuantity" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c("div", {}, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              row.sourceType === 2
                                                ? 0
                                                : row.requestQuantity
                                            )
                                          ),
                                        ]),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                3285330227
                              ),
                            }),
                            _c("portal", {
                              attrs: { to: "quantity" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c("custom-number-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: {
                                                  required: true,
                                                  greater_than: 0,
                                                  max: _vm.$getConst(
                                                    "MAX_DIGITS_FOR_QUANTITY"
                                                  ),
                                                  multiplyTwoField: row.price,
                                                },
                                                expression:
                                                  "{required: true, greater_than: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), multiplyTwoField: row.price}",
                                              },
                                            ],
                                            class: {
                                              "is-danger": _vm.veeErrors.has(
                                                `request-order-${row.id}.quantity-${row.index}`
                                              ),
                                            },
                                            attrs: {
                                              id: `quantity-input-${row.index}`,
                                              name: `quantity-${row.index}`,
                                              "data-vv-scope": `request-order-${row.id}`,
                                              "data-vv-as": _vm.$t(
                                                "Orders.table_fieldQuantity"
                                              ),
                                              disabled:
                                                _vm.isGetPriceQuote ||
                                                _vm.isFetchingPriceQuato ||
                                                _vm.Tenant
                                                  .approvalRequestOrderPermission,
                                              type: "number",
                                              step: "any",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.calcPriceInfo(row)
                                              },
                                              input: function ($event) {
                                                return _vm.isFormValid()
                                              },
                                            },
                                            model: {
                                              value: row.quantity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "quantity",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "row.quantity",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                1718763083
                              ),
                            }),
                            _c("portal", {
                              attrs: { to: "requestDate" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c("CustomDatepicker", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            ref: `requestDate${row.index}`,
                                            staticClass: "custom-picker",
                                            class: {
                                              "is-danger": _vm.veeErrors.has(
                                                `requestDate-${row.index}`
                                              ),
                                            },
                                            attrs: {
                                              id: `request-form-rdate-${row.index}`,
                                              name: `requestDate-${row.index}`,
                                              "data-vv-as": _vm.$t(
                                                "Despatches.popup_DespatchDate"
                                              ),
                                              pickerType: "manuel",
                                              inputIconRight: "icon-arrow-down",
                                              inputIcon: "",
                                              disabledStartDate:
                                                _vm.disabledDate,
                                              disabled:
                                                _vm.isFetchingList ||
                                                _vm.isPageLoading,
                                              isCheckingPosition: false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeDate(row)
                                              },
                                            },
                                            model: {
                                              value: row.requestDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "requestDate",
                                                  $$v
                                                )
                                              },
                                              expression: "row.requestDate",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                1689215699
                              ),
                            }),
                            _c("portal", {
                              attrs: { to: "supplier" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c("div", {}, [
                                        _c(
                                          "div",
                                          { staticClass: "select-box-list" },
                                          [
                                            _c("customSelect", {
                                              key: row.id,
                                              attrs: {
                                                selectedEmitValue: "id",
                                                optionData: _vm.supplierList,
                                                optionTitle: "name",
                                                defaultTitle: _vm.$t(
                                                  "Orders.detail_Popup_FormField_Supplier_Unselected"
                                                ),
                                                componentName: `select-col-${row.index}`,
                                                itemData: row,
                                                isDefaultTextActive: false,
                                                isSearchActive: true,
                                              },
                                              on: {
                                                inputSelected:
                                                  _vm.changeSupplier,
                                              },
                                              model: {
                                                value: row.supplierId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "supplierId",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.supplierId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                1736732312
                              ),
                            }),
                            _c("portal", {
                              attrs: { to: "price" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c(
                                        "div",
                                        { staticClass: "tooltip-relative" },
                                        [
                                          _c("currency-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: {
                                                  required: true,
                                                  greater_than: 0,
                                                  max: _vm.$getConst(
                                                    "MAX_DIGITS_FOR_QUANTITY"
                                                  ),
                                                  multiplyTwoField:
                                                    row.quantity,
                                                },
                                                expression:
                                                  "{required: true, greater_than: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), multiplyTwoField: row.quantity}",
                                              },
                                            ],
                                            staticClass:
                                              "txt u-textRight input-types",
                                            class: {
                                              "is-danger": _vm.veeErrors.has(
                                                `request-order-${row.id}.price${row.index}`
                                              ),
                                            },
                                            attrs: {
                                              id: `price-${row.index}`,
                                              name: `price${row.index}`,
                                              "data-vv-scope": `request-order-${row.id}`,
                                              "data-vv-as": _vm.$t(
                                                "Orders.table_fieldPrice"
                                              ),
                                              disabled:
                                                _vm.isGetPriceQuote ||
                                                _vm.isFetchingPriceQuato ||
                                                row.priceType === 2,
                                            },
                                            nativeOn: {
                                              blur: function ($event) {
                                                return _vm.calcPriceInfo(row)
                                              },
                                            },
                                            model: {
                                              value: row.price,
                                              callback: function ($$v) {
                                                _vm.$set(row, "price", $$v)
                                              },
                                              expression: "row.price",
                                            },
                                          }),
                                          row.priceType === 2
                                            ? _c("tooltip", {
                                                staticClass: "small",
                                                attrs: {
                                                  white: "",
                                                  right: "",
                                                  text: _vm.$t(
                                                    "Despatches.priceTypeWarning"
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                2912059906
                              ),
                            }),
                            _c("portal", {
                              attrs: { to: "discount1" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c(
                                        "div",
                                        { staticClass: "tooltip-relative" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: row.discount1,
                                                expression: "row.discount1",
                                                modifiers: { number: true },
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value:
                                                  "greater_than:-1|max_value:100",
                                                expression:
                                                  "'greater_than:-1|max_value:100'",
                                              },
                                            ],
                                            staticClass:
                                              "txt no-spinners u-textRight",
                                            class: {
                                              "is-danger": _vm.veeErrors.has(
                                                `request-order-${row.id}.discount1${row.index}`
                                              ),
                                            },
                                            attrs: {
                                              id: `discount1-${row.index}`,
                                              name: `discount1${row.index}`,
                                              "data-vv-scope": `request-order-${row.id}`,
                                              step: "any",
                                              type: "number",
                                              disabled:
                                                _vm.isGetPriceQuote ||
                                                _vm.isFetchingPriceQuato ||
                                                row.priceType === 2,
                                            },
                                            domProps: { value: row.discount1 },
                                            on: {
                                              change: function ($event) {
                                                return _vm.calcPriceInfo(row)
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  row,
                                                  "discount1",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                          row.priceType === 2
                                            ? _c("Tooltip", {
                                                staticClass: "small",
                                                attrs: {
                                                  white: "",
                                                  right: "",
                                                  text: _vm.$t(
                                                    "Despatches.priceTypeWarning"
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                1719052032
                              ),
                            }),
                            _c("portal", {
                              attrs: { to: "discount2" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c(
                                        "div",
                                        { staticClass: "tooltip-relative" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: row.discount2,
                                                expression: "row.discount2",
                                                modifiers: { number: true },
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value:
                                                  "greater_than:-1|max_value:100",
                                                expression:
                                                  "'greater_than:-1|max_value:100'",
                                              },
                                            ],
                                            staticClass:
                                              "txt no-spinners u-textRight",
                                            class: {
                                              "is-danger": _vm.veeErrors.has(
                                                `request-order-${row.id}.discount2${row.index}`
                                              ),
                                            },
                                            attrs: {
                                              id: `discount2-${row.index}`,
                                              name: `discount2${row.index}`,
                                              "data-vv-scope": `request-order-${row.id}`,
                                              step: "any",
                                              type: "number",
                                              disabled:
                                                _vm.isGetPriceQuote ||
                                                _vm.isFetchingPriceQuato ||
                                                row.priceType === 2,
                                            },
                                            domProps: { value: row.discount2 },
                                            on: {
                                              change: function ($event) {
                                                return _vm.calcPriceInfo(row)
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  row,
                                                  "discount2",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                          row.priceType === 2
                                            ? _c("Tooltip", {
                                                staticClass: "small",
                                                attrs: {
                                                  white: "",
                                                  right: "",
                                                  text: _vm.$t(
                                                    "Despatches.priceTypeWarning"
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                448282179
                              ),
                            }),
                            _c("portal", {
                              attrs: { to: "tax" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return _c("div", {}, [
                                        _c(
                                          "div",
                                          { staticClass: "select-box-list" },
                                          [
                                            _c("customSelect", {
                                              key: `tax-${row.id}`,
                                              attrs: {
                                                selectedEmitValue: "id",
                                                optionData: _vm.Taxes,
                                                optionTitle: "name",
                                                defaultTitle: _vm.$t(
                                                  "Orders.detail_Popup_FormField_TaxId_Unselected"
                                                ),
                                                componentName: `taxId-${row.index}`,
                                                itemData: row,
                                                isDefaultTextActive: false,
                                              },
                                              on: {
                                                inputSelected:
                                                  _vm.selectCalcPrice,
                                              },
                                              model: {
                                                value: row.taxId,
                                                callback: function ($$v) {
                                                  _vm.$set(row, "taxId", $$v)
                                                },
                                                expression: "row.taxId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                523470813
                              ),
                            }),
                            _c("div", { staticClass: "Orders-toolbar" }, [
                              _c(
                                "div",
                                { staticClass: "toolbar footer m-right-10" },
                                [
                                  _c("div", { staticClass: "left" }),
                                  _c(
                                    "div",
                                    { staticClass: "right" },
                                    [
                                      _c(
                                        "Button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.isEmptyOrders,
                                              expression: "!isEmptyOrders",
                                            },
                                          ],
                                          staticClass: "m-right-10",
                                          attrs: {
                                            size: "medium",
                                            primary: "",
                                            id: "btn-orders-save-order",
                                            disabled:
                                              _vm.selectedOrders.length === 0 ||
                                              _vm.isMakeRequestApprove ||
                                              !_vm.isFormValidChecker,
                                          },
                                          on: { click: _vm.createRequests },
                                        },
                                        [
                                          _c("Icon", {
                                            attrs: { name: "icon-btn-success" },
                                          }),
                                          _vm.isMakeRequestApprove
                                            ? _c("Loading", {
                                                attrs: { theme: "disable" },
                                              })
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Orders.createOrdersButton"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  !_vm.isEmptyOrders &&
                  !_vm.isFetchingList &&
                  !_vm.isPageLoading &&
                  !_vm.isApprovedOrder
                    ? _c("TableFoot", {
                        attrs: { page: _vm.OrderPage },
                        on: {
                          delegateOnChangePage: _vm.changePage,
                          delegateOnChangePageSize: _vm.changePageSize,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
          _c("router-view", { on: { getList: _vm.getFilteredRequestOrders } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }