<template lang="pug">
Page
  template(slot="header-left-search" v-if="hasTabPermission")
    Search.search(
      componentName="LanguageTranslations",
      :isSuggestionLoading="false",
      :isInputDisable="!activateSearch"
      @onChangeSearchValue="onChangeSearch")

  template(slot="header-right-content" v-if="hasTabPermission")
    .default-lang 
      span {{ $t('Global.defaultLanguage') }}
      span {{ defaultLanguage.name }}
    
  template(slot="toolbar-left" v-if="hasTabPermission")
    .translation-filters
      CustomSelect.custom-select(
        id="language-translations-from-lang"
        componentName="select-from-language"
        :defaultTitle="$t('LanguageTranslations.fromLanguage')"
        :optionData="fromLanguageList"
        v-model="fromLanguage.id"
        selectedEmitValue="id"
        optionTitle="name"
        :isDefaultTextActive="false"
      )

      Button.toggle-language.m-left-15(iconName="icon-global-toggle" iconClass="icon-global-toggle" :class="!fromLanguage.id || !toLanguage.id || fromLanguage.id === defaultLanguage.id ? 'disabled' : ''" :justIcon="true" :disabled="!fromLanguage.id || !toLanguage.id || fromLanguage.id === defaultLanguage.id" @click="toggleLanguages")

      CustomSelect.custom-select.m-left-15(
        id="language-translations-to-lang"
        componentName="select-to-language"
        :defaultTitle="$t('LanguageTranslations.toLanguage')"
        :optionData="toLanguageList"
        v-model="toLanguage.id"
        selectedEmitValue="id"
        optionTitle="name"
        :isDisabled="!fromLanguage.id"
        :isDefaultTextActive="false"
      )

      CustomSelect.custom-select.m-left-15(
        id="language-translation-type"
        componentName="select-translation-type"
        :defaultTitle="$t('LanguageTranslations.selectTranslationGroup')"
        :optionData="translationTypes"
        v-model="selectedTranslationType"
        selectedEmitValue="id"
        optionTitle="name"
        :isDisabled="!toLanguage.id"
        :isDefaultTextActive="false"
        @input="selectTranslationType(selectedTranslationType)"
      )
      div(v-if="renderComponent")
        itemGroupList.item-group-filter.m-left-15(
          v-if="selectedGroupType"
          :groupType="selectedGroupType"
          @submitFilter="getListWithGroupFilter"
        )
        RecipeGroupsFilter.m-left-15(
          v-if="selectedTranslationType === 7"
          :customSelectedData="recipeGroupFilter"
          @submitFilter="getListWithGroupFilter"
          ref="recipeGroupsLanguageTranslationsFilter")

      Button.m-left-15.m-right-15(primary, size="small" :disabled="!selectedTranslationType" @click="getTranslations")
        span {{ $t('LanguageTranslations.translateLanguages') }}
  template(slot="toolbar-right" v-if="hasTabPermission")
    .translation-filters
      CustomCheckbox.checkbox-show-ungrouped.m-right-15(
        v-if="selectedTranslationType === 7"
        id="language-translations-recipe-checkbox-show-ungroup"
        :label="$t('Recipes.Checkbox_Show_ungrouped')"
        :checked="showAllRecipes"
        @change="showUngroupedRecipes"
      )
      CustomCheckbox(
        id="checkbox-language-translations-show-empty-data"
        :checked="showOnlyEmptyData"
        :label="$t('LanguageTranslations.showOnlyEmptyTranslations')"
        @change="showOnlyEmptyTranslations")

  template(slot="content-center")
    NoPermission(v-if="!hasTabPermission")
    div(v-else)
      EmptyState(
        v-if="!TranslationList.length",
        iconName="empty-state-translations"
        :title="$t('LanguageTranslations.emptyState_Title')",
        :description="$t('LanguageTranslations.emptyState_Description')",
      )
      TableView(
        v-else
        :componentName="'languageTranslationsTable'"
        :fields="fields"
        :page="Page"
        :readOnly="true"
        :data="TranslationList"
        @delegateOnChangePage="changePage"
        @delegateOnChangePageSize="changePageSize"
      )

      portal(to="fromValue")
        div(slot-scope="row")
          .loading-box(v-if="isWaitingTranslations || isWaitingTranslationUpdate")
          customTextInput(
            id="input-language-translations-from-value"
            v-else
            :value="row.fromValue"
            name="fromValue"
            :placeholder="row.isEnabled ? $t('LanguageTranslations.changeSettings_EnterTranslation') : $t('LanguageTranslations.cannotChangeValue')"
            :data-vv-as="$t('LanguageTranslations.fromValue')"
            :disabled="true"
          )
      
      portal(to="toValue")
        div(slot-scope="row")
          .loading-box(v-if="isWaitingTranslations || isWaitingTranslationUpdate")
          customTextInput(
            v-else
            id="input-language-translations-to-value"
            v-model="row.toValue"
            name="toValue"
            :disabled="!row.isEnabled"
            :placeholder="row.isEnabled ? $t('LanguageTranslations.toValue_Placeholder') : $t('LanguageTranslations.cannotChangeValue')"
            :data-vv-as="$t('LanguageTranslations.toValue')"
            @selectInput="getPreviousValue($event, row)"
            @blur="updateSelectedTranslation($event, row)"
          )
  
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import hasTabPermission from '@/mixins/hasTabPermission'
import NoPermission from '@/view/global/has-not-permission'
import BusinessMultiselect from '@/view/global/business-filters/business-multiselect'
import CustomSelect from '@/view/global/custom-select'
import itemGroupList from '@/view/global/business-filters/groups.vue'
import RecipeGroupsFilter from '@/view/global/business-filters/recipe-groups.vue'
import { vueWaitLoader } from '@/utils/baseOperations'


export default {
  name: 'Exchanges',

  mixins: [hasTabPermission],

  components: {
    NoPermission,
    BusinessMultiselect,
    CustomSelect,
    itemGroupList,
    RecipeGroupsFilter
  },

  data () {
    return {
      //fields: getFields(this.$t),
     defaultLanguage: null,
     toLanguage: {id: null, name: null},
     fromLanguage: {id: null, name: null},
     selectedTranslationType: null,
     selectedGroupType: null,
     renderComponent: true,
     itemGroupFilter: [],
     majorGroupFilter: [],
     overGroupFilter: [],
     recipeGroupFilter: [],
     previousValue: null,
     toLanguageField: null,
     fromLanguageField: null,
     showOnlyEmptyData: false,
     search: null,
     activateSearch: false,
     showAllRecipes: true
    }
  },

  computed: {
    ...mapGetters('LanguageTranslations', [
      'TranslationList',
      'Page'
    ]),

    ...mapGetters('Global', ['Languages']),

    ...mapGetters('Auth', ['Tenant']),


    languages () {
      return [...this.Languages] 
    },

    fromLanguageList () {
      return this.languages
    },

    toLanguageList () {
      return this.fromLanguage ? this.languages.filter(i => i.id !== this.fromLanguage.id && i.id !== this.defaultLanguage.id) : []
    },

    isWaitingTranslationUpdate () {
      return this.$wait.is(['updateTranslationList'])
    },

    isWaitingTranslations () {
      return this.$wait.is(['getAllTranslations'])
    },

    fields () {
      return [
        {
          name: 'defaultValue',
          //type: 'portal',
          is_visible: true,
          no_tooltip: true,
          short_title: this.defaultLanguage.name + ' ' + this.$t('LanguageTranslations.defaultLanguage'),
          title: this.defaultLanguage.name + ' ' + this.$t('LanguageTranslations.defaultLanguage')
        },
        {
          name: 'fromValue',
          type: 'portal',
          is_visible: true,
          no_tooltip: true,
          short_title: this.fromLanguageField,
          title: this.fromLanguageField,
        },
        {
          name: 'toValue',
          type: 'portal',
          is_visible: true,
          no_tooltip: true,
          short_title: this.toLanguageField,
          title: this.toLanguageField
        }
      ]
    },
    translationTypes () {
      return [
        {
          name: this.$t('LanguageTranslations.stockCards'),
          id: 1,
          filter: 'itemGroup'
        },
        {
          name: this.$t('LanguageTranslations.taxes'),
          id: 2,
          filter: null
        },
        {
          name: this.$t('LanguageTranslations.units'),
          id: 3,
          filter: null
        },
        {
          name: this.$t('LanguageTranslations.itemGroups'),
          id: 4,
          filter: 'majorGroup'
        },
        {
          name: this.$t('LanguageTranslations.majorGroups'),
          id: 5,
          filter: 'overGroup'
        },
        {
          name: this.$t('LanguageTranslations.overGroups'),
          id: 6,
          filter: null
        },
        {
          name: this.$t('LanguageTranslations.recipes'),
          id: 7,
          filter: 'recipeGroup'
        }
      ]
    },

  },

  created () {
    this.setDefaultLang()
  },

  beforeDestroy () {
    this.RESET_TRANSLATIONS()
  },

  methods: {
    ...mapActions('LanguageTranslations', [
      'getAllTranslations',
      'updateTranslation'
    ]),

    ...mapActions('Global', [
      'getCurrencyList'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Group', ['getItemGroupAll']),

    //Mutations

    ...mapMutations('LanguageTranslations', ['RESET_TRANSLATIONS']),

    getPreviousValue (value, item) {
      item.previousValue = item.toValue
    },
    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) return
      this.getTranslations()
    },

    showOnlyEmptyTranslations () {
      this.showOnlyEmptyData = !this.showOnlyEmptyData
      if (!this.activateSearch) return
      this.getTranslations()
    },

    showUngroupedRecipes () {
      this.showAllRecipes = !this.showAllRecipes
      if (!this.activateSearch) return
      this.getTranslations()
    },

    updateSelectedTranslation: vueWaitLoader(async function(text, item) {
      if (((text === '' || !text) && item.previousValue === null) || (text.length > 0 && text === item.previousValue) || !item.previousValue && (!text || text === '')) return
      const payload = {
        languageId: this.toLanguage.id,
        rowId: item.rowId,
        value: text === '' ? null : text,
        translationType: this.selectedTranslationType
      }
      await this.updateTranslation(payload).then(res => {
        if (res) {
          const message = this.$t('LanguageTranslations.approval_Notification_message')
          this.notifyShow({ message })
        }
      })
    }, 'updateTranslationList'),


    async getTranslations (event, pageNumber = 1, pageSize = this.Page.size) {
      this.activateSearch = true
      const payload = {
        pageSize,
        pageNumber: pageNumber,
        translationType: this.selectedTranslationType,
        fromLanguageId : this.fromLanguage.id,
        toLanguageId: this.toLanguage.id,
        name: this.search,
        showEmptyTranslations: this.showOnlyEmptyData,
        showUnGroups: this.selectedTranslationType === 7 ? this.showAllRecipes : null,
        itemGroupIds: this.selectedTranslationType === 1 ? this.itemGroupFilter.map(i => i.id) : null,
        overGroupIds: this.selectedTranslationType === 5 ? this.overGroupFilter.map(i => i.id) : null,
        majorGroupIds: this.selectedTranslationType === 4 ? this.majorGroupFilter.map(i => i.id) : null,
        recipeGroupIds: this.selectedTranslationType === 7 ? this.recipeGroupFilter.map(i => i.id) : null
      } 
      await this.setFromToLanguageName()
      await this.getAllTranslations(payload)
    },

    setFromToLanguageName () {
      this.fromLanguageField = this.languages.find(i => i.id === this.fromLanguage.id).name
      this.toLanguageField = this.languages.find(i => i.id === this.toLanguage.id).name
    },

    selectTranslationType (type) {
      if (type === 1) this.selectedGroupType = 'itemGroup'
      else if (type === 4) this.selectedGroupType = 'majorGroup'
      else if (type === 5) this.selectedGroupType = 'overGroup'
      else this.selectedGroupType = null
      this.forceRerender()
    },

    setDefaultLang () {
      this.defaultLanguage = this.Languages.find(i => i.id === this.Tenant.languageId)
    },

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    getListWithGroupFilter ({list, dontSubmit}) {
      if (this.selectedTranslationType === 1) this.itemGroupFilter = list
      if (this.selectedTranslationType === 4) this.majorGroupFilter = list
      if (this.selectedTranslationType === 5) this.overGroupFilter = list
      if (this.selectedTranslationType === 7) this.recipeGroupFilter = list
    },

    toggleLanguages () {
      const fromLanguage = this.fromLanguage
      const toLanguage = this.toLanguage
      this.fromLanguage = toLanguage
      this.toLanguage = fromLanguage
    },

    changePage (page) {
      this.getTranslations(null, page)
    },
    changePageSize (pageSize) {
      this.getTranslations(null, 1, pageSize)
    }
  },
  watch: {
    'fromLanguage.id' (newVal, oldVal) {
      if (this.fromLanguage.id === this.toLanguage.id) this.toLanguage.id = null
    }
  },
}
</script>

<style scoped lang="scss">
  .default-lang {
    padding: 10px 12px;
    border: 1px solid #EAEAEB;
    border-radius: 4px;
    text-transform: capitalize;
    background: #F7FBFF;
    font-weight: 600;
    font-size: $font-size-small;
    color: #868C92;
    :last-child {
      color: #0A1B21;
      margin-left: 4px;
    }
  }
  .custom-select {
    text-transform: capitalize;
    color: #393939;
  }
  :deep() .select-dropdown-custom {
    &-selected {
      border-radius: 4px !important;
      padding: 8px 10px !important;
      height: 40px !important;
      width: 160px !important;
      font-weight: 420 !important;
    }
    &-name {
      font-size: $font-size-small !important;
      font-weight: 420 !important;
      color: #393939 !important;
    }
  }

  :deep() .dropdown-handle-button {
    height: 39px !important;
    .dropdown-handle-button {
      &-right {
        color: #393939 !important;
        font-size: $font-size-small !important;
        font-weight: 420 !important;
        &-badge {
          font-weight: 420 !important;
        }
        .icon {
          right: -13px !important;
          top: 7px !important;
          width: 12px !important;
        }
      }
      &-values {
        color: #393939 !important;
        font-size: $font-size-small !important;
        font-weight: 420 !important;
      }
    }
  }

  :deep() .select-dropdown-custom .option-name {
    font-size: $font-size-small !important;
  }
  
  :deep() .icon-global-toggle {
    width: 17px !important;
    height: 20px !important;
  }
  .toggle-language {
    padding: 10px;
    border: 1px solid #EAEAEB;
    color: #86939E;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    &:hover {
      background: #F5F5F5;
      transition: 0.5s ease-in-out;
    }
  }
  :deep() .Tableee-col.defaultValue {
    word-break: break-all !important;
    white-space: pre-wrap !important;
  }
  :deep() .Tableee-col {
    max-width: 33% !important;
    width: 33% !important;
    border-left: none !important;
    border-right: none !important;
  }
  .loading-box {
    width: auto;
    height: 38px;
    border-radius: 4px;
    background: #F5F5F5;
  }
  .disabled {
    background: $color-background-gray;
    color: #D1D2D2;
  }
  :deep() .select-dropdown-custom-selected.disabled-button {
    .select-dropdown-custom-name.selected-name {
      color: #7b8187 !important;
    }
  }
  .translation-filters {
    width: 100%;
    display: flex;
    white-space: pre-wrap;
  }
  :deep() .toolbar {
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    &-right {
      padding-top: 5px !important;
    }
  }
</style>
