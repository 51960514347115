<template lang="pug">
.InventoryClosedPage
    .toolbar(v-if="!isLoading")
      .left
        CustomCheckbox.m-left-15(
          :id="`checkbox-calculate-waste-consumption`"
          :label="$t('Inventory.closed_detailList_waste-consumption')"
          :checked="includeWasteInConsumption",
          @change="getInventoryWasteInConsumption"
        )
      .right.flexbox
        ImportExport.m-right-15(
          componentName="inventory-closed"
          ref="wrapper"
          :isDisabled="isLoading"
          :activeImportExports=['export']
          downloadUrl="Inventory/inventory/export"
          downloadFileType="xlsx"
          :downloadDisabled="downloadButtonDisabled"
          :downloadParams="downloadParams"
          :downloadFileName="inventoryBreadCrump"
        )
        totalCounts(
          :totalData="this.totals()"
          :isLoading="isLoading"
          title="Inventory.Show_Total_Counts_Button_Title"
          v-if="!isEmpty && !isLoading"
        )
    //
    TableLoading(v-if="isLoading")
    // LIST
    template(v-if="!isEmpty && !isLoading")

      TableView(
        :componentName="'InventoryClosedTable'"
        :fields="fields"
        :data="inventory_Detail.list"
        :readOnly="true"
        @sort="sort"
      )

      portal(to="itemGroupName")
        div(slot-scope="row")
          span {{ row.itemGroupName }}
      portal(to="stockItemName")
        div(slot-scope="row")
          span {{ row.stockItemName }}
      portal(to="detail")
        div(slot-scope="row")
          span.mono.u-textRight % {{ differenceQuantityRatio(row.differenceQuantity,row.consumptionQuantity) | percentFixed  }}
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ImportExport from '@/view/global/import-export'
import totalCounts from '@/view/global/total-counts'
import getFields from './fields/inventories-closed'

export default {
  name: 'InventoryClosed',

  components: {
    ImportExport,
    totalCounts,
  },

  data () {
    return {
      fields: getFields(this.$t),
      sortExpressionList: [],
      includeWasteInConsumption: false
    }
  },

  computed: {
    ...mapGetters('Inventories', [
      'inventory_Detail',
      'inventory_IsEmpty',
      'inventory_Header'
    ]),

    downloadParams () {
      return {
        InventoryHeaderId: this.InventoryInfo.id,
        PageSize: 99999,
        PageNumber: 1,
        sortExpressionList: this.sortExpressionList,
        includeWasteInConsumption: this.includeWasteInConsumption
      }
    },

    downloadButtonDisabled () {
      return this.inventory_IsEmpty || !this.inventory_Detail || this.inventory_Detail.list.length === 0
    },

    InventoryInfo () {
      return {
        ...this.$route.params,
        status: parseInt(this.$route.params.status)
      }
    },

    inventoryBreadCrump () {
      return this.InventoryInfo.storeName + '-' + moment(this.InventoryInfo.inventoryDate).format('DD/MM/YYYY')
    },

    isEmpty () {
      return this.inventory_IsEmpty
    },

    isLoading () {
      return this.$wait.is(['get_INVENTORY'])
    },

  },

  methods: {
    totalCounter (item, column) {
      let totalDataCount = 0
      item.forEach(element => {
        totalDataCount = element[column] + totalDataCount
      })
      return totalDataCount
    },

    getInventoryWasteInConsumption () {
      this.includeWasteInConsumption = !this.includeWasteInConsumption
      this.$emit('includeWasteInConsumption', this.includeWasteInConsumption)
    },

    differenceQuantityRatio (differenceQuantity,consumptionQuantity) {
      const ratio = differenceQuantity / consumptionQuantity * 100
      if (isNaN(ratio) || ratio === -Infinity) {
        return 0
      }
      return parseFloat(ratio)
    },

    async sort (item) {
      switch (item.sortingObject.sortType) {
        case null:
          item.sortingObject.sortType = 0
          this.sortExpressionList.push(item.sortingObject)
          break
        case 0:
          item.sortingObject.sortType++
          break
        case 1:
          item.sortingObject.sortType = null
          this.sortExpressionList.splice(this.sortExpressionList.indexOf(item.sortingObject), 1)
          break
      }
      this.$emit('itemSorting', this.sortExpressionList)

    },

    totals () {
      const columns = [
        'beginAmount',
        'purchaseAmount',
        'returnAmount',
        'transferredAmountIncoming',
        'transferredAmount',
        'productionAmount',
        'consumedForProductionAmount',
        'potentialConsumptionAmount',
        'wasteCostAmount',
        'actualAmount',
        'expectedAmount',
        'consumptionAmount',
        'differenceAmount'
      ]
      // types
      // 0 ==> currency
      // 1 ==> percent
      return columns.map(column => {
        return {
          key: this.$t(`Inventory.detailList_TotalColumn_${column}`),
          value: this.totalCounter(this.inventory_Detail.list, column),
          type: 0
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .downloadField {
    margin-right: 10px;
  }

  :deep(.toolbar) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Breadcrumb {
    display: flex;
    font-size: $font-size-small;

    span {
      margin-left: 10px;
      margin-right: 10px;
    }

    .isSelected {
      color: $color-primary;
      font-weight: $font-weight-bold;
      pointer-events: none;
    }
  }

  :deep(.Tableee-head .Tableee-col) {
    &.fixed {
      position: sticky;
      left: 0;
      &:nth-child(2) {
        left: 100px;
        z-index: 2;
      }
    }
  }

  :deep(.Tableee-body .Tableee-col) {
    &.fixed {
      position: sticky;
      left: 0;
      &:nth-child(2) {
        left: 100px;
        z-index: 2;
      }
    }
    &.detail {
      text-align: right !important;
      font-size: $font-size-small;
      font-family: $font-family-mono;
    }
  }
</style>
