var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("pageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmptySupplierGroups && !_vm.isLoading,
                  expression: "!isEmptySupplierGroups && !isLoading",
                },
              ],
              attrs: {
                size: "medium",
                primary: "",
                id: "btn-supplier-groups-new",
                type: "button",
              },
              on: { click: _vm.itemNew },
            },
            [
              _c("svg", { staticClass: "icon icon-left icon-global-add" }, [
                _c("use", { attrs: { "xlink:href": "#icon-global-add" } }),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("SupplierGroups.headerNewButton"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _c(
            "div",
            { staticClass: "SupplierGropsPageContent" },
            [
              _vm.isLoading ? _c("TableLoading") : _vm._e(),
              _vm.isEmptySupplierGroups && !_vm.isLoading
                ? _c("EmptyState", {
                    attrs: {
                      title: _vm.$t("SupplierGroups.emptyState_Title"),
                      description: _vm.$t(
                        "SupplierGroups.emptyState_Description"
                      ),
                      buttonText: _vm.$t("SupplierGroups.emptyState_Button"),
                    },
                    on: { emptyAddButton: _vm.itemNew },
                  })
                : _vm._e(),
              !_vm.isEmptySupplierGroups && !_vm.isLoading
                ? _c("BoxView", {
                    attrs: {
                      fields: _vm.fields,
                      actions: ["edit", "remove"],
                      data: _vm.supplierGroups,
                      componentName: "SupplierGroupList",
                    },
                    on: {
                      delegateOnItemEdit: _vm.itemEdit,
                      delegateOnItemRemove: _vm.itemRemove,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("router-view"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }