<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
      template(slot="linkExtra", slot-scope="link")
        Icon.icon-suspended-status.m-right-5(
        v-if="suspendSalesExist && link.linkName === 'SuspendedSales'",
      name="icon-row-status")

  template(slot="header-left-search")
    Search.search-box(
    componentName="MatchedMenuItems",
    :isSuggestionLoading="isLoadingMatchedMenuItemsFilter",
    @onChangeSearchValue="onChangeSearch")

  template(slot="toolbar-left" v-show="shouldShowToolbar")
    RVCFilter(
      @submitFilter="updateRVCFilterItems"
      ref="rvcFilterItems"
    )

    storeGroup.m-right-10(
      @submitFilter="getFilteredStoreGroup"
      ref="storeGroupMatchedFilter"
    )

    businessMultiselect(
      componentName="imported-menu-items-group-filter"
      :label="$t('MenuItems.toolbar_FilterGroup')"
      :popupHeader="$t('MenuItems.toolbar_filterGroup_PopupHeader')"
      :multiselectItems="groupList.list"
      @submitFilter="getFilteredGroupList"
      ref="importedMenuItemsFilter"
    )

    Button.clear-filter-button.border-gray-1.p-0.width-auto.bgc-white.m-right-10(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")

    .toolbar-filters__separator

    TotalViewList(:totalCountArray="totalCountData")

    .toolbar-filters__separator

    .show-condiments
      CustomCheckbox.popup-item-check(
      id="checkbox-sales-show-condiments"
      :checked="showCondiments"
      :label="$t('MenuItems.show_Condiment_Label')"
      @change="changeShowCondiments")
  template(slot="toolbar-right" v-show="shouldShowToolbar")
    ImportExport.m-right-15(
      componentName="matched-menu-items"
      ref="wrapper"
      :isDisabled="isLoadingMatchedMenuItemsFilter"
      :activeImportExports=['export']
      downloadUrl="Pos/matchedmenuitem/export"
      downloadFileType="xlsx"
      :downloadParams="downloadParams"
      :downloadFileName="downloadFileName"
    )

    Button(
      id="btn-menu-items-remove-match"
      danger
      size="small"
      type="button"
      :disabled="selectedMenuItems.length === 0 || isRemovingItem"
      @click="onClickRemoveMatchedItems")
      span {{ $t('MenuItems.matchPopup_Delete_Button') }}
      Loading(theme="disable", v-show="isRemovingItem")

  template(slot="content-center")
    TableLoading(v-if="isLoadingMatchedMenuItemsFilter")

    // EMPTY PAGE
    .div(v-if="!isLoadingMatchedMenuItemsFilter")
      EmptyState(
      v-if="shouldShowEmptyState"
      :title="$t('MatchedMenuItems.emptyStateTitle')"
      :description="$t('MatchedMenuItems.emptyStateDescription')"
      :buttonText="$t('MatchedMenuItems.emptyStateNewButton')"
      @emptyAddButton="onClickImportMenuItems")

      EmptyState(
      v-if="shouldShowNotFound"
      :title="$t('MatchedMenuItems.notFoundTitle')"
      :description="$t('MatchedMenuItems.notFoundDescription')")

    // LIST

    TableView(
    v-if="!isMatchedMenuItemsEmpty && !isLoadingMatchedMenuItemsFilter"
    :fields="fields"
    :pointer="true"
    :componentName="'MatchedTable'"
    :data="matchedMenuItems.list"
    :selectable="'checkbox'"
    :selectableAll="'checkbox'"
    :selectedRows="selectedMenuItems"
    @showAllSelectedItems="onSelectMenuItemAll"
    :actions="['edit']"
    :addScrollToNoFixedColumns="true"
    @delegateOnItemEdit="openRematchPopup"
    @delegateOnItemSelect="onSelectMenuItem"
    @delegateOnItemShow="onSelectMenuItem"
    :page="pageMatchedItems"
    @delegateOnChangePage="changePage"
    @delegateOnChangePageSize="changePageSize")

    // POPUPS

    match-popup(
      v-if="isMatchPopupOpen"
      method="update"
      :data="selectedMenuItemDecorated"
      @close="closeMatchPopup"
      @submitForm="submitMatchPopup"
      @getList="getList"
      :showButtonRemoveMatchedMenuItem="true"
    )

    // SUBROUTES

    router-view

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import MatchPopup from './popup/match'
import getFields from './fields-matched'
import RVCFilter from '@/view/global/business-filters/rvc'
import storeGroup from '@/view/global/business-filters/storeGroup'
//
import businessMultiselect from '@/view/global/business-filters/business-multiselect'
import ImportExport from '@/view/global/import-export'

export default {
  name: 'MatchedMenuItems',

  components: {
    MatchPopup,
    RVCFilter,
    storeGroup,
    businessMultiselect,
    ImportExport
  },

  mounted () {
    this.initialData()
  },

  data () {
    return {
      fields: getFields(this.$t),
      selectedMenuItem: null,
      selectedMenuItems: [],
      filters: {
        rvc: [],
        group: []
      },
      storeGroupFilter: [],
      isMatchPopupOpen: false,
      showCondiments: true,
      search: null
    }
  },
  computed: {
    ...mapGetters('MenuItems', [
      'matchedMenuItems',
      'isMatchedMenuItemsEmpty',
      'groupList',
      'pageMatchedItems',
      'pageHeaderLinks'
    ]),

    ...mapGetters('StoreGroups', [
      'storeGroupList'
    ]),

    ...mapGetters('Global', [
      'suspendSalesExist'

    ]),

    totalCountData () {
      return [
        {
          totalCountLabel: this.$t('MatchedMenuItems.toolbar_totalMatchedMenuItemCount'),
          totalCountValue: this.pageMatchedItems.total,
          extraClass: 'with-bg'
        }
      ]
    },

    downloadParams () {
      const getAllId = list => list.map(item => item.id)
      const params = {
        revenueCenterIdList: getAllId(this.filters.rvc),
        menuItemGroupIdList: getAllId(this.filters.group),
        storeGroupIdList: getAllId(this.storeGroupFilter),
        showCondiments: this.showCondiments,
        text: this.search
      }
      return params
    },

    downloadFileName () {
      const fileName = 'MatchedReports'
      return fileName
    },

    isLoadingMatchedMenuItemsFilter () {
      return this.$wait.is(['filterMatchedMenuItems', 'fetchingPageData'])
    },

    isRemovingItem () {
      return this.$wait.is(['onClickRemoveMatchedItems'])
    },

    selectedMenuItemDecorated () {
      return [{
        id: this.selectedMenuItem.id,
        name: this.selectedMenuItem.menuItemName,
        group: this.selectedMenuItem.menuItemGroupName,
        rvc: this.selectedMenuItem.revenueCenterName,
        recipeHeaderId: this.selectedMenuItem.recipeHeaderId,
        stockItemId: this.selectedMenuItem.stockItemId
      }]
    },

    shouldShowToolbar () {
      return (
        !this.isMatchedMenuItemsEmpty ||
            this.filters.rvc.length > 0 ||
            this.filters.group.length > 0
      )
    },

    shouldShowEmptyState () {
      return (
        this.isMatchedMenuItemsEmpty &&
          !this.isLoadingMatchedMenuItemsFilter &&
          this.filters.rvc.length === 0 &&
          this.filters.group.length === 0
      )
    },

    shouldShowNotFound () {
      return this.shouldShowToolbar && this.isMatchedMenuItemsEmpty
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('MenuItems', [
      'getMatchedMenuItems',
      'filterMatchedMenuItems',
      'getGroupList',
      'deleteMatchedMenuItems'
    ]),

    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),

    changeShowCondiments () {
      this.showCondiments = !this.showCondiments
      this.getList()
    },

    async init () {
      await this.getGroupList().then(() => { this.filters.group = [...this.groupList.list] })
      this.getList()
    },

    changePage (page) {
      this.selectedMenuItems = []
      this.getList(page)
    },

    changePageSize (pageSize) {
      this.selectedMenuItems = []
      this.getList(1, pageSize)
    },

    closeMatchPopup () {
      this.isMatchPopupOpen = false
    },

    async initialData () {
      this.$wait.start('fetchingPageData')
      await this.init()
      this.$wait.end('fetchingPageData')
    },

    submitMatchPopup () {
      this.selectedMenuItem = null
    },

    openRematchPopup (item) {
      this.isMatchPopupOpen = true
      this.selectedMenuItem = item
    },

    onClickImportMenuItems () {
      this.$router.push({
        name: 'MenuItems'
      })
    },

    onClickRemoveMatchedItems: vueWaitLoader(async function () {
      let message = this.$t('MenuItems.matchPopup_Delete_Notification_Success', {
        name1: this.selectedMenuItems.map(item => item.menuItemName).join(', ')
      })
      await this.deleteMatchedMenuItems(this.selectedMenuItems)
      this.getList()
      this.notifyShow({ message })
    }, 'onClickRemoveMatchedItems'),

    onSelectMenuItem (item) {
      const index = this.selectedMenuItems.indexOf(item)
      if (index > -1) {
        this.selectedMenuItems.splice(index, 1)
      } else {
        this.selectedMenuItems.push(item)
      }
    },

    onSelectMenuItemAll (isChecked) {
      this.selectedMenuItems = []
      if (isChecked) this.selectedMenuItems.push(...this.matchedMenuItems.list)
    },

    getFilteredStoreGroup ({list, dontSubmit}) {
      this.storeGroupFilter = list
      if (!dontSubmit) this.getList()
    },

    getFilteredGroupList ({list, dontSubmit}) {
      this.filters.group = list
      if (!dontSubmit) this.getList()
    },

    updateRVCFilterItems ({ list, dontSubmit }) {
      this.filters.rvc = list
      if (!dontSubmit) this.getList()
    },

    getList (page = 1, pageSize = this.pageMatchedItems.size) {
      this.selectedMenuItems = []
      this.filterMatchedMenuItems({
        rvc: this.filters.rvc,
        group: this.filters.group,
        storeGroups: this.storeGroupFilter,
        showCondiments: this.showCondiments,
        text: !this.search || this.search.length === 1 ? null : this.search,
        page,
        pageSize
      })
    },
    onChangeSearch (search) {
      this.search = search
      if (this.search.length === 1) return
      this.getList()
    },

    clearFilter () {
      this.$refs.importedMenuItemsFilter.clearAllFilter()
      this.$refs.storeGroupMatchedFilter.clearStoreGroupFilter()
      this.$refs.rvcFilterItems.clearRVCFilter(false)
    }

  }
}
</script>

<style lang="scss" scoped>

  .footer {
    margin-top: 20px;

    &-right {
      float: right;
    }
  }

  .show-condiments {
    height: 33px;
    display: flex;
    align-items: center;
  }

  .btn {
    background: $color-warning;
    color: $color-white;
  }

</style>
