<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="header-right-dropdown")
    .form-item-select.right-date.m-right-10
      CustomDatepicker#RefundDatepicker(
        :disabled="isLoading || $wait.is('pageLoading')"
        :isLoading="$wait.is('getDateFilterSettings')"
        v-model="dates",
        :range="true")

  template(slot="header-right-content")
    Button(
    id="btn-despatch-new"
    type="button",
    primary
    size="medium"
    iconName="icon-global-add"
    iconClass="icon-global-add"
    @click="addRefund")
      span {{ $t('Despatches.addRefundButton') }}


  template(slot="toolbar-left")
    SupplierFilter(
      @submitFilter="getListWithSupplierFilter"
      ref="despatchRefundSupplierFilter")

    Button.clear-filter-button.border-gray-1.p-0.width-auto(
      @click='clearFilter'
      )
        Icon.icon-clear-filter(name="icon-clear-filter")
    .show-drafts.m-left-15
      CustomCheckbox.popup-item-check(
        id="checkbox-refund-show-drafts"
        :checked="showDrafts"
        :label="$t('Global.showDrafts')"
        @change="changeShowDraftsStatus")

  template(slot="toolbar-right")
    Button(
      id="btn-despatch-selected-save"
      type="button",
      primary
      size="small"
      iconName="icon-btn-success"
      iconClass="icon-btn-success"
      @click="finalizeSelectedDespatches",
      :disabled="isSelectedEmpty || checkSameSupplier")
        span {{ $t('Despatches.SaveSelectedDespatchesButton') }}
        .alert(v-show="checkSameSupplier") {{ $t('Despatches.UmustSelectOneSupplier') }}
          .alert-arrow
  template(slot="content-center")
    // LOADING
    TableLoading(v-if="isLoading")

    // EMPTY PAGE

    EmptyState(
    v-if="refund_IsEmpty && !isLoading",
    :title="$t('Despatches.emptyRefundState_Title')",
    :description="$t('Despatches.emptyRefundState_Description')"
    :buttonText="$t('Despatches.refund_popup_FinishCreateButton')",
    @emptyAddButton="addRefund")

    RefundTable(
      v-if="!refund_IsEmpty && !isLoading"
      :tableData ="refund_List",
      :showDetailAction = "despatchDetail",
      :editAction = "despatchHeaderEdit",
      :removeItemAction = "despatchRemove",
      :selectedList="selectedList"
      :showExportPopup="showExportPopup"
      :checkSameSupplier="checkSameSupplier"
      @delegateCheckItem="handleCheckbox"
      @delegateCheckAllItem="toggleAll"
      @printPdf="showExportPopup"
      @editExpireDate="editExpireDate"
    )

    // POPUPS

    div 
      Modal(:isOpen="showBatchDetail" :showCloseButton="false")
        template(slot="title")
          .modal-header
            .modal-header-left
              .refund


        template(slot="content")
          NestedTable.custom-nested-table(
            :fields="refundBatchHeaderFields"
            :detailFields="refundBatchDetailFields"
            :data="batchList"
            :actions="['export']"
            :usePortalFooter="true"
            :isActionButtonsVisible="true"
            @showDetailAction="showBatchDetailAction"
            @showExportPopup="showBatchDetailExportPopup"
          )

          portal(to="givenQuantity")
            div(slot-scope="detail")
              .control.quantity-input
                input.txt.no-spinners(
                  name="givenQuantity"
                  :data-vv-as="$t('Despatches.detail_TableColumn_Quantity')"
                  :class="{ 'is-danger': veeErrors.has(`refund${detail.rowIndex}.givenQuantity`) }"
                  v-validate="{max: $getConst('MAX_DIGITS_FOR_QUANTITY'), greater_than: 0}"
                  type="number"
                  step="any"
                  v-model="detail.row.givenQuantity"
                  @blur="updateGivenBatchQuantity($event, detail.row)"
                )


          portal(to="warehouseTime")
            div(slot-scope="detail")
              span {{ detail.row.warehouseTime?.substr(0,5) || '-' }}
          
          portal(to="shipmentTime")
            div(slot-scope="detail")
              span {{ detail.row.shipmentTime?.substr(0,5) || '-' }}


        template(slot="footer")
          .batch-footer
            Button.m-right-10(
              id="transfer-batch-detail-cancel"
              size="small"
              secondary
              inline
              @click="closeModal")
              span {{ $t('Global.Cancel') }}
            
            Button(
              id="transfer-batch-detail-save"
              size="small"
              primary
              inline
              @click="checkBatchValidation")
              span {{ $t('Global.Save') }}

    router-view(
      @getList="getRefundListWithParams"
      @takePrint="showExportPopup"
      @showDrafts="showDrafts = true"
    )

    exportPopup(
      v-if="isExportPopupVisible"
      :templateId="templateId"
      :templateType="templateType"
      ref="exportPopupTemplate"
    )

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import exportPopup from '@/view/global/export-popup'

//
import RefundTable from './refund-table'
import SupplierFilter from '@/view/global/business-filters/supplier.vue'
import moment from 'moment'
import getDateFilterSetting from '@/utils/getDateFilterSetting'
import settingsExportPopup from '@/mixins/settingsExportPopup'
import { getExpireDateFields, getExpireDateDetailFields } from './fields/refundExpireDate'

export default {
  name: 'Refund',

  components: {
    RefundTable,
    SupplierFilter,
    exportPopup
  },

  mixins: [settingsExportPopup],

  data () {
    return {
      selectedList: [],
      accountingFilter: [],
      supplierFilter: [],
      dates: [],
      isExportPopupVisible: false,
      templateId: null,
      templateType: 0,
      showDrafts: true,
      showBatchDetail: false,
      refundBatchHeaderFields: getExpireDateFields(this.$t),
      refundBatchDetailFields: getExpireDateDetailFields(this.$t),
      despatchDetailIdList: null
    }
  },

  async created () {
    this.$wait.start('createdStarted')
    await this.getDateFilterSettings()
    await this.setShowDraftStatus()
    this.dates = getDateFilterSetting('Date_Return')
    await this.getRefundListWithParams()
    this.getTaxes()
    this.$wait.end('createdStarted')
  },

  watch: {
    dates (newVal, oldVal) {
      if (oldVal.length === 0) return
      this.getRefundListWithParams()
    }
  },

  computed: {

    isLoading () {
      return this.$wait.is(['get_REFUND_LIST', 'createdStarted'])
    },

    isLoadingSendSelected () {
      return this.$wait.is(['postSelectedInvoices'])
    },
    ...mapGetters('Refund', [
      'refund_List',
      'refund_IsEmpty',
      'batchList'
    ]),

    ...mapGetters('Despatch', ['pageHeaderLinks']),

    ...mapGetters('DocumentSettings', ['DocumentSettingList']),

    isSelectedEmpty () {
      return this.selectedList.length === 0
    },

    selectableList () {
      return this.refund_List.filter(item => (item.accountingStatus === 0 || item.accountingStatus === 3) && item.status !== 1)
    },

    checkSameSupplier () {
      let selectedSupplierList = this.selectedList.filter(despatch => {
        return this.selectedList[0].supplier.id !== despatch.supplier.id
      })
      return selectedSupplierList.length > 0
    }
  },

  methods: {
    ...mapActions('DateFilterSettings', ['getDateFilterSettings']),

    ...mapActions('Refund', [
      'get_REFUND_LIST',
      'get_REFUND',
      'getRefundBatchDetails',
      'updateRefundBatchQuantity',
      'getExpireDateData'
    ]),

    ...mapActions('Despatch', [
      'postSelectedInvoices',
      'validateBatchQuantity'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Tax', [
      'getTaxes'
    ]),

    ...mapActions('DocumentSettings', [
      'getDocumentFilterSettings'
    ]),

    ...mapMutations('Refund', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'SHOW_BATCH_DETAIL',
      'HIDE_BATCH_DETAIL'
    ]),
    ...mapMutations('Global', [
      'TOGGLE_MENU_MINIMALIZE'
    ]),

    closeModal () {
      this.showBatchDetail = false
    },

    checkBatchValidation () {
      /* this.validateBatchQuantity({despatchDetailIds: this.despatchDetailIdList}).then(res => {
        console.log(res.data, 'resdata')
      }) */
      this.closeModal()
    },

    async updateGivenBatchQuantity (event, row) {  
      const payload = {
        id: row.id,
        despatchDetailId: row.despatchDetailId,
        quantity: Number(event.target.value)
      }
      await this.updateRefundBatchQuantity(payload).then(res => {
        if (res) {
          const message = this.$t('Transfer.updated_transferBatchQuantity')
          this.notifyShow({ message })
          this.getExpireDateData({despatchDetailIds: this.despatchDetailIdList})
          this.getRefundBatchDetails({despatchDetailId: row.despatchDetailId}).then(result => {
            if (result) this.SHOW_BATCH_DETAIL({batchList: result.data.batchList, id: row.despatchDetailId})
          })
        }
      })
    },

    editExpireDate (despatch) {
      this.TOGGLE_MENU_MINIMALIZE(true)
      this.showBatchDetail = true
      this.despatchDetailIdList = despatch.despatchDetailList.map(i => i.id)
      this.getExpireDateData({despatchDetailIds: this.despatchDetailIdList})

    },

    showBatchDetailExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 19, isFinalizeAction)
    },

    async showBatchDetailAction (item) {
      if (item.showDetail) {
        this.HIDE_BATCH_DETAIL(item)
      } else {
        await this.getRefundBatchDetails({despatchDetailId: item.id}).then(res => {
          if (res) {
            this.SHOW_BATCH_DETAIL({batchList: res.data.batchList, id: item.id})
          }
        })
      }
    },

    getListWithSupplierFilter ({list, dontSubmit}) {
      if (dontSubmit) this.supplierFilter = list
      else {
        this.supplierFilter = list
        this.getRefundListWithParams()
      }
    },

    async setShowDraftStatus () {
      await this.getDocumentFilterSettings()
      this.DocumentSettingList.find(i => {
        if (i.key === 'ShowDrafts_Return') this.showDrafts = i.isChecked
      })
    },

    async changeShowDraftsStatus () {
      this.showDrafts = !this.showDrafts
      await this.getRefundListWithParams()
    },

    resetSelectedList () {
      this.selectedList = []
    },

    clearFilter () {
      this.$refs.despatchRefundSupplierFilter.clearSupplierFilter(false)
    },

    addRefund: function () {
      this.$router.push({
        name: 'RefundNew'
      })
    },

    async despatchDetail (despatch) {
      if (despatch.showDetail) {
        this.HIDE_DETAIL(despatch)
        return
      }
      await this.get_REFUND(despatch.id).then(res => {
        if (res.status === 200) {
          this.SHOW_DETAIL(res.data)
        }
      })
    },

    despatchRemove (despatch) {
      this.$router.push({
        name: 'RefundRemove',
        params: {
          id: despatch.id,
          item: despatch
        }
      })
    },

    despatchHeaderEdit (despatch) {
      this.$router.push({
        name: 'RefundHeaderEdit',
        params: {
          id: despatch.id,
          despatchHeader: despatch
        }
      })
    },

    finalizeSelectedDespatches () {
      this.$router.push({
        name: 'MultipleRefundFinalize',
        params: {
          despatchesList: this.selectedList
        }
      })
      this.selectedList = []
    },

    handleCheckbox ({ isChecked, despatch }) {
      if (isChecked) {
        this.selectedList.push(despatch)
      } else {
        this.selectedList.splice(this.selectedList.indexOf(despatch), 1)
      }
    },

    toggleAll (isChecked) {
      if (isChecked) {
        this.selectedList = this.selectableList
      } else {
        this.selectedList = []
      }
    },

    async getRefundListWithParams (despatchHeaderData = null) {
      const params = {
        accountingStatusList: this.accountingFilter.map(accounting => accounting.value),
        supplierIds: this.supplierFilter.map(supplier => supplier.id),
        startDate: moment(this.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dates[1]).format('YYYY-MM-DD'),
        showDrafts: this.showDrafts
      }
      await this.get_REFUND_LIST(params)
      this.resetSelectedList()
      if (despatchHeaderData) this.despatchDetail(despatchHeaderData)
    },

    showExportPopup (Id, isFinalizeAction = false) {
      this.exportFrame(Id, 4, isFinalizeAction, 'Return')
    },
    showBatchExportPopup (row, isFinalizeAction = false) {
      this.exportFrame(row.id, 19, isFinalizeAction)
    }
  }
}
</script>

<style scoped lang="scss">

.toolbar {
  &-left {
    .refund-switch {
      width: auto;
      height: 33px;
      font-weight: $font-weight-normal;
      color: $color-light;
      .icon {
        width: 27px;
        height: 27px;
        padding: 7px 4.5px;
        margin-right: 10px;
        background: $color-seashell;
        color: $color-success;
        border: 1px solid;
        border-radius: $border-high-radius;

        &.eye-closed {
          padding: 5px 3px;
          background: transparent;
          color: $color-light;
        }
      }
    }
  }
  &-right {
    .btn {
    position: relative;
    .alert {
      position: absolute;
      font-size: $font-size-small;
      background-color: white;
      box-shadow: 0 0 0 2px rgba($color-warning, 1);
      border-radius: $border-radius;
      padding: 15px;
      width: 200px;
      top: 110%;
      z-index: 2;

      &-arrow {
        position: absolute;
        top: -18px;
        left: 45%;
        width: 15px;
        border: 8px solid;
        border-color: transparent transparent $color-warning transparent;
      }
    }
  }
  }
}
:deep(.Modal) {
  &-Body {
    z-index: $z-index-xxxl !important;
    &-content {
      width: 80vw !important;
    }
  }
  &-Overlay[data-v-b9dcd536] {
    z-index: $z-index-xxl !important;
  }
}
:deep(.Header) {
  z-index: 400 !important
}
.batch-footer {
  display: flex;
  justify-content: end;
}
:deep(.quantity-input .txt) {
  text-align: right !important;
}
</style>
