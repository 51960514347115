var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-breadcrumb-left" }, [
        _c(
          "div",
          {
            staticClass: "PageHeaderEkstra",
            attrs: { id: "page-header-ekstra-inventory-lists-spot" },
          },
          [
            _c("div", { staticClass: "left" }, [
              _c(
                "div",
                {
                  staticClass: "Breadcrumb",
                  attrs: { id: "breadcrumbs-inventory-lists-spot" },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "c-light",
                      attrs: {
                        id: "link-inventory-breadcrumb",
                        to: { name: "Inventories" },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Inventory.breadCrumb_Inventory")))]
                  ),
                  _c("span", [_vm._v(_vm._s(">"))]),
                  _c(
                    "a",
                    {
                      staticClass: "isSelected",
                      attrs: {
                        href: "",
                        id: "link-inventory-breadcrumb-active",
                      },
                    },
                    [_vm._v(_vm._s(_vm.inventoryBreadCrump))]
                  ),
                ],
                1
              ),
            ]),
            _c("span", { staticClass: "inventory-type c-light" }, [
              _vm._v(
                _vm._s(
                  _vm.inventory_Header?.type === 1
                    ? _vm.$t("Inventories.inventoryTypePeriodic")
                    : _vm.$t("Inventories.inventoryTypeIntermediate")
                )
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _vm.hasPermissionToCloseInventory
            ? _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "small",
                    type: "button",
                    id: "btn-inventory-close",
                    iconName: "icon-btn-success",
                    iconClass: "icon-btn-success",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.close(_vm.inventory_Header)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("Inventory.closeSpotInventoryButton"))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          !_vm.isOnlyListUsage
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink isSelected",
                  attrs: {
                    id: "toggle-group-0",
                    to: {
                      name: "InventorySpotList",
                      params: {
                        id: _vm.$route.params.id,
                        status: _vm.$route.params.status,
                        storeName: _vm.$route.params.storeName,
                        inventoryDate: _vm.$route.params.inventoryDate,
                      },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_spotList")))]
              )
            : _vm._e(),
          !_vm.isOnlyListUsage &&
          _vm.inventory_Header?.expirationDateControlIsEnabled
            ? _c(
                "router-link",
                {
                  staticClass: "tabLink",
                  attrs: {
                    id: "toggle-group-4",
                    to: {
                      name: "InventoryExpireDateSpotList",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Inventory.view_mode_expire_date")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomMultiselect", {
            staticClass: "toolbar-filters__item",
            attrs: {
              id: "multiselect-lists-filter",
              items: _vm.listsOptions,
              selected: _vm.listFilter,
              label: _vm.$t("Inventory.filterTitle_Inventory_List"),
              unselectedText: _vm.$t("Global.toolbar_Filters_Unselected"),
              popupHeader: _vm.$t("Inventory.filterTitle_Inventory_List"),
              allLabel: _vm.$t("Global.toolbar_Filters_AllLabel"),
              buttonText: _vm.$t("Global.toolbar_Filters_ButtonText"),
              buttonIconLeft: "btn-success",
            },
            on: {
              delegateCheckAll: _vm.onChangeFilter,
              delegateCheckItem: _vm.onChangeFilter,
              delegateSubmitItems: _vm.getLists,
            },
          }),
          !_vm.isStoreTypeCostCenter
            ? _c("ImportMassec", {
                attrs: {
                  inventoryInfo: _vm.InventoryInfo,
                  page: "spot-inventory",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingLists || _vm.isFetchingData
            ? _c("TableLoading", { staticClass: "listLoading" })
            : _vm._e(),
          _vm.isEmpty && !_vm.isFetchingData && !_vm.isFetchingLists
            ? _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Inventories.emptyStateSpotList_Title"),
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "InventoryContent" }, [
            !_vm.isFetchingLists && !_vm.isFetchingData
              ? _c(
                  "div",
                  {
                    staticClass: "NestedTable",
                    attrs: { id: "table-inventory-lists-spot" },
                  },
                  _vm._l(_vm.lists, function (header, HeaderIndex) {
                    return _c(
                      "div",
                      {
                        key: HeaderIndex,
                        staticClass: "NestedTable-item",
                        class: { isOpened: header.showDetail },
                        attrs: {
                          id: `#table-item-header-inventory-lists-spot-${HeaderIndex}`,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "NestedTable-row",
                            attrs: {
                              id: `#table-item-header-row-inventory-lists-spot-${HeaderIndex}`,
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "NestedTableHeaderLink",
                              on: {
                                click: function ($event) {
                                  header.showDetail = !header.showDetail
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "NestedTable-row-col",
                                attrs: {
                                  id: `#table-item-name-inventory-lists-spot-${HeaderIndex}`,
                                },
                              },
                              [_vm._v(_vm._s(header.inventoryListHeaderName))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "NestedTable-row-col action",
                                attrs: {
                                  id: `#table-item-action-inventory-lists-spot-${HeaderIndex}`,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "body" },
                                  [
                                    _c("Button", {
                                      attrs: {
                                        size: "small",
                                        variant: "icon c-success btn-switch",
                                        justIcon: true,
                                        id: `btn-nested-table-open-header-${HeaderIndex}`,
                                        iconName: "icon-arrow-down",
                                        iconClass: "icon-arrow-down",
                                      },
                                      on: {
                                        click: function ($event) {
                                          header.showDetail = !header.showDetail
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: header.showDetail,
                                expression: "header.showDetail",
                              },
                            ],
                            staticClass: "NestedDetail",
                            attrs: {
                              id: `#table-item-details-inventory-lists-spot-${HeaderIndex}`,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "NestedDetail-row header" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "NestedDetail-row-col",
                                    attrs: {
                                      id: `#table-item-details-stock-item-name-inventory-lists-spot-${HeaderIndex}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Inventory.detailList_TableColumn_StockItemName"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "NestedDetail-row-col",
                                    attrs: {
                                      id: `#table-item-details-unit-inventory-lists-spot-${HeaderIndex}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Inventory.detailList_TableColumn_Unit"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "NestedDetail-row-col",
                                    attrs: {
                                      id: `#table-item-details-unit-quantity-inventory-lists-spot-${HeaderIndex}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Inventory.detailList_TableColumn_UnitQuantityInput"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "NestedDetail-row-col",
                                    attrs: {
                                      id: `#table-item-details-base-unit-quantity-inventory-lists-spot-${HeaderIndex}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Inventory.detailList_TableColumn_BaseQuantityTotal"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "NestedDetail-row-col",
                                    attrs: {
                                      id: `#table-item-details-quantity-total-inventory-lists-spot-${HeaderIndex}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Inventory.detailList_TableColumn_ActualQuantityTotal"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._l(
                              header.inventoryItemList,
                              function (detailItem, detailIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: `${detailIndex}listDetail`,
                                    staticClass: "NestedDetail-row item",
                                  },
                                  [
                                    _c("div", { staticClass: "col" }, [
                                      _vm._v(_vm._s(detailItem.stockItemName)),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col unit" },
                                      _vm._l(
                                        detailItem.unitCountList,
                                        function (unit, unitIndex) {
                                          return _c(
                                            "span",
                                            { key: `${unitIndex}-unitKey` },
                                            [_vm._v(_vm._s(unit.unitName))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      _vm._l(
                                        detailItem.unitCountList,
                                        function (unit, unitIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: `${unitIndex}-unitKeyInput`,
                                              staticClass: "unitItem",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.number",
                                                    value: unit.unitQuantity,
                                                    expression:
                                                      "unit.unitQuantity",
                                                    modifiers: { number: true },
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: {
                                                      max: _vm.$getConst(
                                                        "MAX_DIGITS_FOR_QUANTITY"
                                                      ),
                                                    },
                                                    expression:
                                                      "{max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                                                  },
                                                ],
                                                staticClass: "txt no-spinners",
                                                class: {
                                                  "is-danger":
                                                    _vm.veeErrors.has(
                                                      `unit-${detailIndex}-${unitIndex}.units`
                                                    ),
                                                  "first-input":
                                                    unitIndex === 0,
                                                  "last-input":
                                                    detailItem.unitCountList
                                                      .length -
                                                      1 ===
                                                    unitIndex,
                                                },
                                                attrs: {
                                                  type: "number",
                                                  name: "units",
                                                  disabled:
                                                    header.source === 1 ||
                                                    _vm.inventory_Header
                                                      ?.storeType === 3,
                                                  id: `${detailItem.stockItemId}-unitCount-${unit.unitId}`,
                                                  "data-vv-scope": `unit-${detailIndex}-${unitIndex}`,
                                                },
                                                domProps: {
                                                  value: unit.unitQuantity,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onChangeDetailUnitCount(
                                                      { detailItem, unit },
                                                      `unit-${detailIndex}-${unitIndex}`
                                                    )
                                                  },
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      unit,
                                                      "unitQuantity",
                                                      _vm._n(
                                                        $event.target.value
                                                      )
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      _vm._l(
                                        detailItem.unitCountList,
                                        function (unit, unitIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: `${unitIndex}-unitKeyInput`,
                                              staticClass: "unitItem",
                                            },
                                            [
                                              unit.inventoryCountId
                                                ? [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: `${detailItem.stockItemId}-unitCount-${unit.unitId}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.actualQuantityIndividual(
                                                              unit
                                                            )
                                                          ) +
                                                            " " +
                                                            _vm._s(
                                                              detailItem.baseUnitCode
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : [_c("span", [_vm._v("-")])],
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c("div", { staticClass: "col" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.actualQuantityTotal(detailItem)
                                          ) +
                                            " " +
                                            _vm._s(detailItem.baseUnitCode)
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _c("router-view", {
            on: {
              getList: _vm.getLists,
              successImportAction: _vm.importSuccesAction,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }