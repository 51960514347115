var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "header-right-dropdown" }, [
        _c(
          "div",
          { staticClass: "form-item-select date-start" },
          [
            _c("CustomDatepicker", {
              attrs: {
                id: "IncomeStatsDatepicker",
                disabledEndDate: _vm.dateEndDisable,
                range: true,
                isLoading: _vm.$wait.is("getDateFilterSettings"),
                disabled: _vm.isLoading || _vm.isPageLoading,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "toolbar-left" }, [
        _c(
          "div",
          { staticClass: "toolbar-filters__item" },
          [
            _c("CustomMultiselect", {
              ref: "kindTypeList",
              staticClass: "toolbar-filters__item",
              attrs: {
                id: "reports-income-stats-storegroups-kind-types",
                items: _vm.kindTypes,
                selected: _vm.kindTypeList,
                type: "radio",
                showAll: false,
                label: _vm.$t("IncomeStats.filter_kind_types_name"),
                popupHeader: _vm.$t("IncomeStats.filter_kind_types"),
                unselectedText: _vm.$t("IncomeStats.toolbar_Stores_Unselected"),
                allLabel: _vm.$t("IncomeStats.toolbar_Stores_AllLabel"),
                buttonText: _vm.$t("IncomeStats.toolbar_Stores_ButtonText"),
                buttonIconLeft: "btn-success",
              },
              on: {
                delegateCheckItem: _vm.onChangeKindTypes,
                delegateSubmitItems: _vm.getIncomeStatsWithParams,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "toolbar-filters__item" },
          [
            _c("CustomMultiselect", {
              staticClass: "toolbar-filters__item",
              attrs: {
                id: "reports-income-stats-storegroups-filter",
                items: _vm.storeGroupList,
                selected: _vm.storeGroupFilter,
                label: _vm.$t("StoreGroups.popupForm_Name"),
                popupHeader: _vm.$t("StoreGroups.storeGroupFilterLabel"),
                unselectedText: _vm.$t("IncomeStats.toolbar_Stores_Unselected"),
                allLabel: _vm.$t("IncomeStats.toolbar_Stores_AllLabel"),
                buttonText: _vm.$t("IncomeStats.toolbar_Stores_ButtonText"),
                buttonIconLeft: "btn-success",
              },
              on: {
                delegateCheckAll: _vm.onChangeStoreGroupFilter,
                delegateCheckItem: _vm.onChangeStoreGroupFilter,
                delegateSubmitItems: _vm.getIncomeStatsWithParams,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "toolbar-filters__item" },
          [_c("RVCFilter", { on: { submitFilter: _vm.updateRVCFilterItems } })],
          1
        ),
        _c(
          "div",
          { staticClass: "toolbar-filters__item" },
          [
            _c("CustomMultiselect", {
              staticClass: "toolbar-filters__item",
              attrs: {
                id: "custom-multiselect-income-stats-other-expenses",
                items: _vm.OtherExpenseList,
                label: _vm.$t("StoreGroups.showOtherCosts"),
                selected: _vm.selectedExpenseList,
                "popup-header": _vm.$t("IncomeStats.expenseListSelect"),
                "show-all": false,
                buttonText: _vm.$t("IncomeStats.toolbar_Stores_ButtonText"),
                type: "radio",
                buttonIconLeft: "btn-success",
                unselectedText: _vm.$t(
                  "IncomeStats.expenseListSelectPlaceholderText"
                ),
              },
              on: {
                delegateSubmitItems: _vm.getIncomeStatsWithParams,
                delegateCheckItem: _vm.onChangeExpenseList,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "Button",
              {
                attrs: {
                  size: "small",
                  id: "income-stats-get-income-stats-button",
                  isLoading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  primary: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.getIncomeStatsWithParams((_vm.to = 1))
                  },
                },
              },
              [
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.$t("IncomeStats.getIncomeStats"))),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          _vm.IncomeStats.length > 0
            ? _c("totalCounts", {
                staticClass: "m-right-15",
                attrs: {
                  totalData: _vm.totals,
                  isLoading: _vm.isLoadingTotals,
                  title: "IncomeStats.Show_Total_Counts_Button_Title",
                },
              })
            : _vm._e(),
          _c("ImportExport", {
            ref: "wrapper",
            attrs: {
              componentName: "Reports-IncomeStats",
              isDisabled: _vm.isPageLoading,
              activeImportExports: ["export"],
              downloadUrl: "IncomeStats/incomestats/export",
              downloadFileType: "xlsx",
              downloadParams: _vm.downloadParams,
              downloadFileName: _vm.downloadFileName,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "IncomeStatsReport" }, [
          _c(
            "div",
            { staticClass: "results" },
            [
              _vm.isPageLoading ? _c("TableLoading") : _vm._e(),
              (_vm.isEmpty && !_vm.isLoading && !_vm.isPageLoading) ||
              !_vm.isDataChecked
                ? _c("EmptyState", {
                    attrs: {
                      title: _vm.isDataChecked
                        ? _vm.$t("IncomeStats.emptyState_Title")
                        : _vm.$t("IncomeStats.emptyState_makeSelection_Title"),
                      description: _vm.isDataChecked
                        ? _vm.$t("IncomeStats.emptyState_Description")
                        : _vm.$t(
                            "IncomeStats.emptyState_makeSelection_Description"
                          ),
                    },
                  })
                : _vm._e(),
              !_vm.isEmpty &&
              !_vm.isPageLoading &&
              !_vm.isLoading &&
              _vm.isDataChecked
                ? _c("TableView", {
                    staticClass: "reports-income-table",
                    attrs: {
                      page: _vm.Page,
                      fields: _vm.fields,
                      componentName: "ReportsIncomeTable",
                      data: _vm.IncomeStats,
                      "read-only": true,
                      useInfiniteScroll: true,
                      infoData: _vm.popupInfoObject,
                      isLoadingInfiniteScrollRequest: _vm.isLoading,
                    },
                    on: {
                      delegateOnChangePage: (page) =>
                        _vm.getIncomeStatsWithParams(page, true),
                    },
                  })
                : _vm._e(),
              _c("portal", {
                attrs: { to: "name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return _c("span", { staticClass: "portal-detail-type" }, [
                        _c("span", [_vm._v(_vm._s(row.name))]),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }