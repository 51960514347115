<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageTabButtons(
      :selectedList = "pageHeaderLinks"
      translationGroup="TabItems"
    )
  template(slot="toolbar-right")
  
  template(slot="content-center")
    .MenuItemsSettings
      .toolbar
        .flexbox.align-flex-end
          div
            p.font-size-small.m-bottom-15 {{ $t('MenuItemsSettings.basedStoreGroupSelectLabelText')}}
            CustomMultiselect.m-bottom-15(
                id="custom-multiselect-match-copy-base-store"
                :items="storeGroupList"
                :selected="baseSelectedStoreGroup"
                :popup-header="$t('MenuItemsSettings.basedStoreGroupSelect')"
                :show-all="false"
                :show-footer="false"
                :useCustomEmptyState="true"
                :customEmptyState="baseSelectedStoreGroup.length ? baseSelectedStoreGroup[0].name : $t('MenuItemsSettings.storeGroupSelectPlaceholderText')"
                type="radio"
                buttonIconLeft="btn-success"
                @delegateCheckItem="changeBaseSelectedStoreGroup"
            )
            CustomMultiselect(
                id="custom-multiselect-match-copy-base-rvc"
                :items="baseRvcGroupList"
                :selected="baseSelectedRvcGroup"
                :popup-header="$t('MenuItemsSettings.baseRvcGroupSelect')"
                :show-all="false"
                :show-footer="false"
                type="radio"
                :useCustomEmptyState="true"
                :customEmptyState="baseSelectedRvcGroup.length ? baseSelectedRvcGroup[0].name : $t('MenuItemsSettings.baseRvcGroupSelectPlaceholderText')"
                buttonIconLeft="btn-success"
                @delegateCheckItem="changeBaseSelectedRvcGroup"
            )
          div
            Icon(name="icon-rightwards")
          div
            p.font-size-small.m-bottom-15 {{ $t('MenuItemsSettings.releatedStoreGroupSelectLabelText')}}
            CustomMultiselect.m-bottom-15(
                id="custom-multiselect-match-copy-base-store"
                :items="releatedStoreGroupList"
                :selected="relatedSelectedStoreGroup"
                :popup-header="$t('MenuItemsSettings.releatedStoreGroupSelect')"
                :showAll="false"
                :show-footer="false"
                type="radio"
                :useCustomEmptyState="true"
                :customEmptyState="relatedSelectedStoreGroup.length ? relatedSelectedStoreGroup[0].name : $t('MenuItemsSettings.storeGroupSelectPlaceholderText')"
                buttonIconLeft="btn-success"
                @delegateCheckItem="changeReleatedSelectedStoreGroup"
            )
            CustomMultiselect(
                id="custom-multiselect-match-copy-releated-rvc"
                :items="releatedRvcGroupList"
                :selected="releatedSelectedRvcGroup"
                :popup-header="$t('MenuItemsSettings.releatedRvcGroupSelect')"
                :show-all="false"
                :show-footer="false"
                type="radio"
                buttonIconLeft="btn-success"
                :useCustomEmptyState="true"
                :customEmptyState="releatedSelectedRvcGroup.length ? releatedSelectedRvcGroup[0].name : $t('MenuItemsSettings.releatedRvcGroupSelectPlaceholderText')"
                @delegateCheckItem="changeReleatedSelectedRvcGroup"
            )

          Button(
            primary,
            size="medium",
            variant="full"
            :disabled="isCopyButtonDisabled",
            :isLoading="isLoading"
            @click="submitMatchedCopy"
          )
            span {{ $t('MenuItemsSettings.matchedCopyButton') }}
        router-view(@defualtSet='defaultSelectedSet')
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//

export default {
  name: 'MenuItemsSettings',

  data () {
    return {
      baseSelectedStoreGroup: [],
      relatedSelectedStoreGroup: [],
      baseSelectedRvcGroup: [],
      releatedSelectedRvcGroup: []
    }
  },

  async mounted () {
    await this.getStoreGroupList({pageNumber: 1, pageSize: 9999})
    this.defaultSelectedSet()
  },

  computed: {
    ...mapGetters('MenuItemsSettings', ['pageHeaderLinks']),
    ...mapGetters('StoreGroups', [
      'storeGroupList',
      'releatedStoreGroupList',
      'baseRvcGroupList',
      'releatedRvcGroupList'
    ]),

    isLoading () {
      return this.$wait.is['postMatchCopy']
    },

    isCopyButtonDisabled () {
      return this.baseSelectedStoreGroup.length === 0 ||
               this.relatedSelectedStoreGroup.length === 0 ||
               this.baseSelectedRvcGroup.length === 0 ||
               this.releatedSelectedRvcGroup.length === 0
    }
  },

  methods: {
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('StoreGroups', [
      'getStoreGroupList',
      'getReleatedGroupList',
      'getStoreRvcGroupList',
      'getReleatedRvcGroupList'
    ]),

    submitMatchedCopy () {
      this.$router.push({
        name: 'MatchCopyAlert',
        params: {
          id: this.baseSelectedStoreGroup[0].id,
          releatedId: this.relatedSelectedStoreGroup[0].id,
          baseName: this.baseSelectedStoreGroup[0].name,
          releatedName: this.relatedSelectedStoreGroup[0].name,
          baseRvcId: this.baseSelectedRvcGroup[0].id,
          releatedRvcId: this.releatedSelectedRvcGroup[0].id,
          baseRvcName: this.baseSelectedRvcGroup[0].name,
          releatedRvcName: this.releatedSelectedRvcGroup[0].name
        }
      })
    },

    changeBaseSelectedStoreGroup ({ item }) {
      this.baseSelectedStoreGroup = [ item ]
      this.baseSelectedRvcGroup = []
      this.relatedSelectedStoreGroup = []
      this.releatedSelectedRvcGroup = []
      this.getStoreRvcGroupList({storeGroupIds: [ this.baseSelectedStoreGroup[0].id ]})
    },

    changeBaseSelectedRvcGroup ({ item }) {
      this.baseSelectedRvcGroup = [ item ]
      this.relatedSelectedStoreGroup = []
      this.releatedSelectedRvcGroup = []
      this.getReleatedGroupList({storeGroupId: this.baseSelectedStoreGroup[0].id})
    },

    changeReleatedSelectedStoreGroup ({ item }) {
      this.relatedSelectedStoreGroup = [ item ]
      this.releatedSelectedRvcGroup = []
      this.getReleatedRvcGroupList({storeGroupIds: [ this.relatedSelectedStoreGroup[0].id ]})
    },

    changeReleatedSelectedRvcGroup ({ item }) {
      this.releatedSelectedRvcGroup = [ item ]
    },

    defaultSelectedSet () {
      this.relatedSelectedStoreGroup = []
      this.baseSelectedStoreGroup = []
      this.baseSelectedRvcGroup = []
      this.releatedSelectedRvcGroup = []
    }
  }
}
</script>

<style lang="scss" scoped>

.custom-multiselect {
    margin-right: 40px;
    width: 312px;
    :deep()  & .dropdown-handle{
      font-size: 14px;
      & .dropdown-handle-button{
          height: 50px;
          display: flex;
          align-items: center;
      }
    }

}

.icon-rightwards{
  color: $color-success;
  margin-right: 40px;
  margin-bottom: 15px;
}
</style>
