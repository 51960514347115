var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.pageHeaderLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "toolbar-left" }, [
        _c(
          "div",
          { staticClass: "item x2" },
          [
            _c("Icon", { attrs: { name: "icon-down-arrow" } }),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDate.month,
                    expression: "selectedDate.month",
                  },
                ],
                staticClass: "mounth-select ff-sf-ui",
                attrs: { name: "months", id: "payment-calendar-select-month" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.selectedDate,
                        "month",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.getData,
                  ],
                },
              },
              _vm._l(_vm.calendar.months, function (month) {
                return _c(
                  "option",
                  { key: month.index, domProps: { value: month.index } },
                  [_vm._v(_vm._s(month.name))]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item x2" },
          [
            _c("Icon", { attrs: { name: "icon-down-arrow" } }),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDate.year,
                    expression: "selectedDate.year",
                  },
                ],
                staticClass: "years-select ff-sf-ui",
                attrs: { name: "years", id: "payment-calendar-select-year" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.selectedDate,
                        "year",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.getData,
                  ],
                },
              },
              _vm._l(_vm.calendar.years, function (year) {
                return _c("option", { key: year, domProps: { value: year } }, [
                  _vm._v(_vm._s(year)),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _c("div", { staticClass: "item" }, [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("PaymentCalendar.firstDayOfWeek"))),
          ]),
          _c(
            "div",
            { staticClass: "select-field" },
            [
              _c("Icon", { attrs: { name: "icon-down-arrow" } }),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedDate.firstDayOfWeek,
                      expression: "selectedDate.firstDayOfWeek",
                    },
                  ],
                  staticClass: "firstDayOfWeek-select",
                  attrs: {
                    name: "firstDayOfWeek",
                    id: "payment-calendar-select-firstDayOfWeek",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.selectedDate,
                          "firstDayOfWeek",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.getData,
                    ],
                  },
                },
                _vm._l(_vm.daysOption, function (day) {
                  return _c(
                    "option",
                    { key: day.index, domProps: { value: day.index } },
                    [_vm._v(_vm._s(day.name) + "  ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("template", { slot: "toolbar-right" }, [
        _c("div", { staticClass: "right-item" }, [
          _c("span", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.$t("PaymentCalendar.monthlyTotal"))),
          ]),
          _c("span", { staticClass: "value ff-mono" }, [
            _vm._v(
              _vm._s(
                _vm._f("formatCurrency2Digits")(
                  _vm.PaymentCalendar.monthlyTotal
                )
              )
            ),
          ]),
        ]),
      ]),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isLoading ? _c("TableLoading") : _vm._e(),
          !_vm.isLoading
            ? _c("div", { staticClass: "Calendar" }, [
                _c(
                  "div",
                  { staticClass: "weeks" },
                  [
                    _c("div", { staticClass: "week colorfull text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("PaymentCalendar.weekTitle"))),
                    ]),
                    _vm._l(_vm.calendar.weeksOfMonth, function (week) {
                      return _c(
                        "div",
                        {
                          key: `weektext${week.index}`,
                          staticClass: "week color-green ff-sf-ui",
                        },
                        [_vm._v(_vm._s(week.name) + " .")]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "days" },
                  [
                    _vm._l(_vm.calendar.days, function (day) {
                      return _c(
                        "div",
                        { key: day.dayNumber, staticClass: "day title" },
                        [_vm._v(_vm._s(day.name))]
                      )
                    }),
                    _vm._l(_vm.calendar.daysOfMonth, function (day, dayIndex) {
                      return _c(
                        "div",
                        {
                          key: `day${dayIndex}`,
                          staticClass: "day",
                          class: { today: day.date === _vm.today },
                        },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "span",
                              {
                                staticClass: "dayNumber ff-sf-ui",
                                class: [{ disabled: !day.isActive }],
                              },
                              [_vm._v(_vm._s(day.day))]
                            ),
                            _vm.getInDayData(day.date) !== undefined &&
                            _vm.getInDayData(day.date).items &&
                            _vm.isInCurrentYears(day.date)
                              ? _c(
                                  "span",
                                  { staticClass: "dailTotal ff-sf-pro" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency2Digits")(
                                          _vm.getInDayData(day.date).total
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._l(
                            _vm.getInDayData(day.date)
                              ? _vm.getInDayData(day.date).items
                              : {},
                            function (supplier, supplierIndex) {
                              return supplierIndex < 3 &&
                                _vm.isInCurrentYears(day.date)
                                ? _c(
                                    "div",
                                    { key: supplierIndex, staticClass: "item" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "supplierName ff-sf-pro",
                                        },
                                        [_vm._v(_vm._s(supplier.supplierName))]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "price ff-mono" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatCurrency2Digits")(
                                                supplier.amount
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e()
                            }
                          ),
                          _vm.isInCurrentYears(day.date)
                            ? _c("div", { staticClass: "footer" }, [
                                _vm.getInDayData(day.date) !== undefined &&
                                _vm.getInDayData(day.date).items
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "show-more ff-sf-pro",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showDetail(dayIndex)
                                          },
                                        },
                                      },
                                      [_vm._v("...")]
                                    )
                                  : _vm._e(),
                                _vm.showedDetailIndex === dayIndex
                                  ? _c("div", { staticClass: "popupOver" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "list custom-scrollbar",
                                        },
                                        _vm._l(
                                          _vm.getInDayData(day.date)
                                            ? _vm.getInDayData(day.date).items
                                            : {},
                                          function (supplier, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "supplier",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "name ff-sf-pro",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        supplier.supplierName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "payment ff-mono",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatCurrency2Digits"
                                                        )(supplier.amount)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "weeks" },
                  [
                    _c("div", { staticClass: "week colorfull value" }, [
                      _vm._v(_vm._s(_vm.$t("PaymentCalendar.weeklyTotals"))),
                    ]),
                    _vm._l(_vm.calendar.weeksOfMonth, function (week, index) {
                      return _c(
                        "div",
                        {
                          key: `weekvalue${index}`,
                          staticClass: "week ff-mono color-gray",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency2Digits")(
                                _vm.getWeekData(week)
                              )
                            )
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }