export function getWasteHeaderFields($t) {
  return [
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Waste.table_store')
      },
      get title() {
        return $t('Waste.table_store')
      }
    },
    {
      name: 'wasteGroupName',
      is_visible: true,
      type: 'supplier',
      get short_title() {
        return $t('Waste.table_wasteGroup')
      },
      get title() {
        return $t('Waste.table_wasteGroup')
      }
    },
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Waste.table_docNo')
      },
      get title() {
        return $t('Waste.table_docNo')
      }
    },
    {
      type: 'date',
      name: 'wasteDate',
      is_visible: true,
      get short_title() {
        return $t('Waste.table_Date')
      },
      get title() {
        return $t('Waste.table_Date')
      }
    },
    {
      name: 'cost',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Waste.table_cost')
      },
      get title() {
        return $t('Waste.table_cost')
      }
    },
    {
      name: 'description',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('')
      },
      get title() {
        return $t('')
      }
    }
  ]
}

export function getWasteDetailFields($t, $getConst) {
  return [
    {
      type: 'fixed',
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('Waste.Waste')
      },
      get title() {
        return $t('Waste.Waste')
      },
      withDescription: true,
      getTypeText: function(type) {
        return {
          text: type === 2 ? $t('Waste.detail_TableColumn_TypeRecipe') : '',
          class: 'p-5 bgc-seashell c-success border-gray border-radius-4 font-size-micro-small font-weight-600 m-left-10'
        }
      }
    },
    {
      name: 'stockOnHandQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Waste.detail_TableColumn_StockOnHandQuantity')
      },
      get title() {
        return $t('Waste.detail_TableColumn_StockOnHandQuantity')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      editable: true,
      validationRules: item => {
        return {
          decimal: $getConst('DECIMAL_FOR_QUANTITY'), multiplyTwoField: item.unitPrice, max: $getConst('MAX_DIGITS_FOR_QUANTITY')
        }
      },
      is_visible: true,
      get short_title() {
        return $t('Waste.detail_TableColumn_Quantity')
      },
      get title() {
        return $t('Waste.detail_TableColumn_Quantity')
      }
    },
    {
      name: 'unitId',
      dataField: 'unit',
      type: 'select',
      is_visible: true,
      get short_title() {
        return $t('Waste.detail_TableColumn_UnitCode')
      },
      get title() {
        return $t('Waste.detail_TableColumn_UnitCode')
      }
    },
    {
      name: 'unitPrice',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Waste.detail_TableColumn_unitPrice')
      },
      get title() {
        return $t('Waste.detail_TableColumn_unitPrice')
      }
    },
    {
      type: 'mono',
      name: 'baseQuantity',
      is_visible: true,
      get short_title() {
        return $t('Waste.detail_TableColumn_BaseQuantity')
      },
      get title() {
        return $t('Waste.detail_TableColumn_BaseQuantity')
      }
    },
    {
      type: 'select',
      dataField: 'unit',
      name: 'baseUnitId',
      is_visible: true,
      get short_title() {
        return $t('Waste.detail_TableColumn_BaseUnit')
      },
      get title() {
        return $t('Waste.detail_TableColumn_BaseUnit')
      }
    },
    {
      name: 'totalCost',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Waste.detail_TableColumn_Price')
      },
      get title() {
        return $t('Waste.detail_TableColumn_Price')
      }
    },
    {
      name: 'detailDescription',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}
