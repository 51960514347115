var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageTabButtons", {
            attrs: {
              selectedList: _vm.PageLinks,
              translationGroup: "TabItems",
            },
          }),
        ],
        1
      ),
      _vm.hasTabPermission
        ? _c(
            "template",
            { slot: "header-right-content" },
            [
              !_vm.isEmptyPlaningList
                ? _c(
                    "Button",
                    {
                      attrs: {
                        primary: "",
                        size: "medium",
                        iconName: "icon-global-add",
                        id: "add-production-planing-header-button",
                      },
                      on: { click: _vm.addNewProductionPlaning },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.addData", {
                              field: _vm.$t(
                                "ProductionPlaning.ProductionPlaning"
                              ),
                            })
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("CustomCheckbox", {
            staticClass: "popup-item-check",
            attrs: {
              id: "checkbox-production-planning-show-drafts",
              checked: _vm.showDrafts,
              label: _vm.$t("Global.showDrafts"),
            },
            on: { change: _vm.changeShowDraftsStatus },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          !_vm.hasTabPermission
            ? _c("NoPermission")
            : _c(
                "div",
                { staticClass: "ProductionPlaning" },
                [
                  _vm.isFetchingData
                    ? _c("TableLoading")
                    : _c(
                        "div",
                        [
                          _vm.isEmptyPlaningList
                            ? _c("EmptyState", {
                                attrs: {
                                  title: _vm.$t("Global.emptyState_Title", {
                                    field: _vm.$t(
                                      "ProductionPlaning.ProductionPlaning"
                                    ),
                                  }),
                                  description: _vm.$t(
                                    "Global.emptyState_Description",
                                    {
                                      field: _vm.$t(
                                        "ProductionPlaning.ProductionPlaning"
                                      ),
                                    }
                                  ),
                                  buttonText: _vm.$t("Global.addData", {
                                    field: _vm.$t(
                                      "ProductionPlaning.ProductionPlaning"
                                    ),
                                  }),
                                },
                                on: {
                                  emptyAddButton: _vm.addNewProductionPlaning,
                                },
                              })
                            : _c("NestedTable", {
                                attrs: {
                                  fields: _vm.productionPlanningHeaderFields,
                                  detailFields:
                                    _vm.productionPlanningDetailFields,
                                  data: _vm.ProductionPlaning,
                                  addButtonText: _vm.$t(
                                    "Global.addProductionItem"
                                  ),
                                  finalizeButtonText: _vm.$t("Global.Save"),
                                  actions: ["edit", "delete", "export"],
                                  detailActions: ["edit", "delete"],
                                  isDetailLoading: _vm.isDetailFetching,
                                  disableNewDetailButton: _vm.isOnlyListUsage,
                                  page: _vm.Page,
                                },
                                on: {
                                  editHeaderAction:
                                    _vm.editHeaderProductionPlanning,
                                  addDetailAction: _vm.addNewDetail,
                                  editDetailAction: _vm.editDetailItem,
                                  showDetailAction: _vm.showDetailAction,
                                  detailChangeAction: _vm.updateDetailItem,
                                  deleteHeaderAction: _vm.removeHeader,
                                  deleteDetailAction: _vm.removeDetail,
                                  delegateOnChangePage: _vm.changePage,
                                  delegateOnChangePageSize: _vm.changePageSize,
                                  showExportPopup: _vm.showExportPopup,
                                  finalizeAction: _vm.productionPlanningSaved,
                                },
                              }),
                        ],
                        1
                      ),
                  _c("portal", {
                    attrs: { to: "description" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (header) {
                          return _c(
                            "div",
                            {},
                            [
                              header.row.description
                                ? _c(
                                    "Button",
                                    {
                                      staticClass:
                                        "show-MiniPopup relative width-max-content",
                                      attrs: {
                                        variant: "info",
                                        size: "small",
                                        id: `production-planning-header-info-button-${header.rowIndex}`,
                                        iconName: "icon-global-info",
                                        justIcon: true,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "MiniPopup-right-primary m-top-15 c-dark",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(header.row.description)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
          _c("router-view", {
            on: {
              getProductionPlaningList: _vm.getProductionPlaningList,
              showDrafts: function ($event) {
                _vm.showDrafts = true
              },
            },
          }),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }